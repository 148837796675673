import {
  INICIA_TITULARIDADE,
  SALVA_TITULARIDADE,
  REMOVE_TITULARIDADE,
  NOVO_TITULARIDADE,
  BUSCA_TITULARIDADE_EDICAO,
  INICIO_TITULARIDADE_SUCESSO,
  SALVA_TITULARIDADE_SUCESSO,
  REMOVE_TITULARIDADE_SUCESSO,
  BUSCA_TITULARIDADE_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_TITULARIDADE,
  LISTA_TITULARIDADE_SUCESSO,
  LIMPAR_TITULARIDADE,
  INICIO_CENARIO_TITULARIDADE_LOCAL,
  INICIO_CENARIO_TITULARIDADE_LOCAL_SUCESSO
} from 'constants/ActionTypes';
import { ValidaCampoObrigatorio } from 'luxFw/transformations/Validations';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaTitularidade = () => {
  return {
    type: INICIA_TITULARIDADE
  };
};

export const iniciaCenarioTitularidadePorLocalControlado = (name, object, value) => {
  var idLocal = value !== null ? value.id : 0;

  return {
    type: INICIO_CENARIO_TITULARIDADE_LOCAL,
    payload: { idLocal }
  };
}

export const inicioCenarioTitularidadePorLocalControladoSucesso = (titularidades, locaisControlados, idLocal) => {
  return {
    type: INICIO_CENARIO_TITULARIDADE_LOCAL_SUCESSO,
    payload: { titularidades, locaisControlados, idLocal }
  };
}

export const salvaTitularidade = (titularidade) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatorios(titularidade));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if(errosEncontrados.length === 0)
  {
    return {
      type: SALVA_TITULARIDADE,
      payload: titularidade      
    }
  }
  else 
  {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }      
    
    validacoes.push(exibeMensagem);

    return validacoes;
  }  

/*   return {
    type: SALVA_TITULARIDADE,
    payload: titularidade
  }; */
};

export const removeTitularidade = (id) => {
  return {
    type: REMOVE_TITULARIDADE,
    payload: id
  };
};

export const listaTitularidade = (modelo, numero, situacao) => {
  if (modelo == undefined) {
    modelo = "";
  }

  if (numero == undefined) {
    numero = "";
  }

  if (situacao == undefined) {
    situacao = "";
  }

  return {
    type: LISTA_TITULARIDADE,
    payload: { modelo, numero, situacao }
  };
};


/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoTitularidade = () => {
  return {
    type: NOVO_TITULARIDADE
  };
};

export const buscaTitularidadeEdicao = (id) => {
  return {
    type: BUSCA_TITULARIDADE_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioTitularidadeSucesso = (locaisControlados) => {
  return {
    type: INICIO_TITULARIDADE_SUCESSO,
    payload: { locaisControlados }
  }
};

export const salvaTitularidadeSucesso = (mensagem) => {
  return {
    type: SALVA_TITULARIDADE_SUCESSO,
    payload: mensagem
  }
};

export const removeTitularidadeSucesso = (mensagem) => {
  return {
    type: REMOVE_TITULARIDADE_SUCESSO,
    payload: mensagem
  }
};

export const buscaTitularidadeEdicaoSucesso = (titularidade) => {
  return {
    type: BUSCA_TITULARIDADE_EDICAO_SUCESSO,
    payload: titularidade
  };
};

export const listaTitularidadeSucesso = (titularidade) => {
  return {
    type: LISTA_TITULARIDADE_SUCESSO,
    payload: { titularidade }
  };
};

export const limparFiltroTitularidade = () => {
  return {
    type: LIMPAR_TITULARIDADE,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

function RetornosArrayValidacoesCamposObrigatorios(titularidade) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('descricaoGrupoTitularidade', titularidade.descricaoGrupoTitularidade));
  validacoes.push(ValidaCampoObrigatorio('descricaoUnidadeTitularidade', titularidade.descricaoUnidadeTitularidade));

  return validacoes;
}