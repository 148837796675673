import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_PONTO_ACESSO, INICIA_PONTO_ACESSO, BUSCA_PONTO_ACESSO_EDICAO, 
         REMOVE_PONTO_ACESSO, LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO, LISTA_AMBIENTES_POR_LOCAL, LISTA_PONTO_ACESSO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaPontoAcessoSucesso,
         removePontoAcessoSucesso, 
         inicioPontoAcessoSucesso, 
         iniciaPontoAcesso,
         buscaPontoAcessoEdicaoSucesso,
         listaLocaisControladosSucesso,
         listaAmbientesSucesso,
         listaPontoAcessoSucesso } from "actions/PontoAcesso";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getPontosAcesso = async () =>
    await requisicaoApi.get('/pontosAcesso/filtrar')
    .then(resposta => resposta)
    .catch(error => error);

    const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);    

    const getEquipamentos = async () =>
    await requisicaoApi.get('/equipamentos')
    .then(resposta => resposta)
    .catch(error => error);        

  const getPontoAcessoEdicao = async (id) =>
    await requisicaoApi.get('/pontosAcesso/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);  

  const getListaAmbientesPorLocal = async (local) => 
    await requisicaoApi.get('/ambientes/local/' + local)
    .then(resposta => resposta)
    .catch(error => error);      

    const getListaPontoAcesso = async (cliente, local, ambiente) => 
    await requisicaoApi.get('/pontosAcesso/filtrar?Cliente=' +  cliente + '&Local=' + local + '&Ambiente=' + ambiente)
    .then(resposta => resposta)
    .catch(error => error);      


/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaPontoAcessoRequest = async (pontoAcesso) => await requisicaoApi.post('/pontosAcesso', 
  {
    "id": pontoAcesso.id,
    "equipamentoCliente": {
        "idCliente": pontoAcesso.idCliente,
        "idEquipamento": pontoAcesso.idEquipamento,
        "formaComunicacao": pontoAcesso.formaComunicacao,
        "caminhoComunicServer": pontoAcesso.caminhoComunicServer,
        "id": 0
      },
      "idEquipamentoCliente": 0,
      "idAmbiente": pontoAcesso.idAmbiente,
      "descricaoPontoAcesso": pontoAcesso.descricaoPontoAcesso,
      "ativo": true,
      "ipEquipamento": pontoAcesso.ipEquipamento,
      "ehUzerPassPremium": pontoAcesso.ehUzerPassPremium,
      "tipoCheckin": pontoAcesso.tipoCheckin,
      "tipoPontoAcesso": pontoAcesso.tipoPontoAcesso,
      "coletaDigital": pontoAcesso.coletaDigital
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaPontoAcessoRequest = async (pontoAcesso) => await requisicaoApi.put('/pontosAcesso', 
  {
    "id": pontoAcesso.id,
    "equipamentoCliente": {
        "idCliente": pontoAcesso.idCliente,
        "idEquipamento": pontoAcesso.idEquipamento,
        "formaComunicacao": pontoAcesso.formaComunicacao,
        "caminhoComunicServer": pontoAcesso.caminhoComunicServer,
        "id": pontoAcesso.idEquipamentoCliente
      },
      "idEquipamentoCliente": pontoAcesso.idEquipamentoCliente,
      "idAmbiente": pontoAcesso.idAmbiente,
      "descricaoPontoAcesso": pontoAcesso.descricaoPontoAcesso,
      "ativo": true,
      "ipEquipamento": pontoAcesso.ipEquipamento,
      "ehUzerPassPremium": pontoAcesso.ehUzerPassPremium,
      "tipoCheckin": pontoAcesso.tipoCheckin,
      "tipoPontoAcesso": pontoAcesso.tipoPontoAcesso,
      "coletaDigital": pontoAcesso.coletaDigital
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deletePontoAcessoRequest = async (id) =>
  await requisicaoApi.delete('/pontosAcesso/' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaPontoAcesso() {
    try {
      const listagemGrid = yield call(getPontosAcesso);
  
      if(listagemGrid.data)
      {
        const clientes = yield call(getClientes);

        if(clientes.data) {
          const equipamentos = yield call(getEquipamentos);

          if(equipamentos.data) {
            const clienteFixo = localStorage.getItem('idClienteFixado');

            if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
            {
              const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
              yield put(inicioPontoAcessoSucesso(listagemGrid.data, clientes.data, equipamentos.data, locais.data));
            }
            else
            {
              yield put(inicioPontoAcessoSucesso(listagemGrid.data, clientes.data, equipamentos.data, null));
            }
          }
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar pontosAcesso'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaLocaisControladosPorCliente({ payload }) {
    try {
      const cliente = payload.idCliente;
      const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
  
      if(retorno.data)
      {
        yield put(listaLocaisControladosSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }

  function* fnListaAmbientesPorLocal({ payload }) {
    try {
      const local = payload.idLocal;
      const retorno = yield call(getListaAmbientesPorLocal, local);
  
      if(retorno.data)
      {
        yield put(listaAmbientesSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de ambientes do local: ' + local));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  function* fnListaPontoAcesso({ payload }) {
    try {
      const cliente = payload.cliente;
      const local = payload.local;
      const ambiente = payload.ambiente;
      const retorno = yield call(getListaPontoAcesso, cliente, local, ambiente);
  
      if(retorno.data)
      {
        yield put(listaPontoAcessoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de ponto de acesso'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  function* fnBuscaPontoAcessoEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getPontoAcessoEdicao, id);
  
      if(retorno.data)
      {
        const locais = yield call(getListaLocaisControladosPorCliente, retorno.data.equipamentoCliente.idCliente);

        if(locais.data) {
          const ambientes = yield call(getListaAmbientesPorLocal, retorno.data.ambiente.idLocalControlado);

          if(ambientes.data) {
            yield put(buscaPontoAcessoEdicaoSucesso(retorno.data, locais.data, ambientes.data));
          }
        }
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do pontoAcesso: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaPontoAcesso({ payload }) {
  try {

    const pontoAcesso = payload;
    var resultado = "";

      if(pontoAcesso.id === 0) {
        const retorno = yield call(adicionaPontoAcessoRequest, pontoAcesso);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaPontoAcessoRequest, pontoAcesso);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaPontoAcesso());
        yield put(salvaPontoAcessoSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemovePontoAcesso({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deletePontoAcessoRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaPontoAcesso());
        yield put(removePontoAcessoSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesPontoAcesso() {
  yield takeEvery(INICIA_PONTO_ACESSO, fnIniciaPontoAcesso);
}

export function* salvaPontoAcesso() {
  yield takeEvery(SALVA_PONTO_ACESSO, fnSalvaPontoAcesso);
}

export function* buscaPontoAcessoEdicao() {
  yield takeEvery(BUSCA_PONTO_ACESSO_EDICAO, fnBuscaPontoAcessoEdicao);
}

export function* removePontoAcesso() {
  yield takeEvery(REMOVE_PONTO_ACESSO, fnRemovePontoAcesso);
}

export function* listaLocaisControladosPorClientePontoAcesso() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO, fnListaLocaisControladosPorCliente)
}

export function* listaAmbientesPorLocal() {
  yield takeEvery(LISTA_AMBIENTES_POR_LOCAL, fnListaAmbientesPorLocal)
}

export function* listaPontoAcesso() {
  yield takeEvery(LISTA_PONTO_ACESSO, fnListaPontoAcesso)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesPontoAcesso), 
             fork(salvaPontoAcesso), 
             fork(buscaPontoAcessoEdicao),
             fork(removePontoAcesso),
             fork(listaLocaisControladosPorClientePontoAcesso),
             fork(listaAmbientesPorLocal),
             fork(listaPontoAcesso)]);
}