import {
  INICIA_LOCAL_CONTROLADO,
  SALVA_LOCAL_CONTROLADO,
  REMOVE_LOCAL_CONTROLADO,
  NOVO_LOCAL_CONTROLADO,
  BUSCA_LOCAL_CONTROLADO_EDICAO,
  INICIO_LOCAL_CONTROLADO_SUCESSO,
  SALVA_LOCAL_CONTROLADO_SUCESSO,
  REMOVE_LOCAL_CONTROLADO_SUCESSO,
  ADICIONA_RESPONSABILIDADE,
  ATUALIZA_RESPONSABILIDADE,
  REMOVE_RESPONSABILIDADE,
  BUSCA_LOCAL_CONTROLADO_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_LOCAL_CONTROLADO,
  LISTA_LOCAL_CONTROLADO_SUCESSO,
  LIMPAR_LOCAL_CONTROLADO,
  SETAR_IMAGEM_LOCAL_CONTROLADO,
  VALIDACAO_CAMPO,
  ALTERACAO_DATEPICKER,
  CLIQUE_CONTROLE_ABA_LOCAL,
  ALTERACAO_CHECK,
  LIMPA_VALIDACOES,
  ATUALIZAR_PERFIL_AUTORIZADO_LOCAL
} from 'constants/ActionTypes';
import moment from 'moment';
import { ValidaCampoObrigatorio } from 'luxFw/transformations/Validations';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaLocalControlado = () => {
  return {
    type: INICIA_LOCAL_CONTROLADO
  };
};

export const salvaLocalControlado = (localControlado) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatoriosSalvar(localControlado));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    var salvarLocal = {
      type: SALVA_LOCAL_CONTROLADO,
      payload: localControlado
    }

    validacoes.push(salvarLocal);
  }
  else {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);
  }

  return validacoes;
};

function RetornosArrayValidacoesCamposObrigatoriosSalvar(localControlado) {

  var validacoes = [];

  if (localControlado.possuiUnidade) {
    validacoes.push(ValidaCampoObrigatorio('descricaoRotuloGrupoUnidade', localControlado.descricaoRotuloGrupoUnidade));
    validacoes.push(ValidaCampoObrigatorio('descricaoRotuloUnidadeUnidade', localControlado.descricaoRotuloUnidadeUnidade));
  }

  if (localControlado.possuiCategoria) {
    validacoes.push(ValidaCampoObrigatorio('descricaoRotuloGrupoCategoria', localControlado.descricaoRotuloGrupoCategoria));
    validacoes.push(ValidaCampoObrigatorio('descricaoRotuloUnidadeCategoria', localControlado.descricaoRotuloUnidadeCategoria));
  }

  if (localControlado.possuiTitularidade) {
    validacoes.push(ValidaCampoObrigatorio('descricaoRotuloTitularidadePrincipal', localControlado.descricaoRotuloTitularidadePrincipal));
    validacoes.push(ValidaCampoObrigatorio('descricaoRotuloTitularidadeSecundario', localControlado.descricaoRotuloTitularidadeSecundario));
  }

  return validacoes;
}

export const removeLocalControlado = (id) => {
  return {
    type: REMOVE_LOCAL_CONTROLADO,
    payload: id
  };
};

export const onChangeCheckUnidades = (props, nomeCheck, value) => {

  if (!value && props.unidades.length > 0) {
    var teste = teste;

    return {
      type: SHOW_MESSAGE,
      payload: 'A operação não pode ser realizada pois existem Unidades cadastradas para esse Local'
    }
  }

  return {
    type: ALTERACAO_CHECK,
    payload: { nomeCheck: [nomeCheck], valor: value }
  }
};

export const onChangeCheckCategorias = (props, nomeCheck, value) => {

  if (!value && props.categorias.length > 0) {
    var teste = teste;

    return {
      type: SHOW_MESSAGE,
      payload: 'A operação não pode ser realizada pois existem Categorias cadastradas para esse Local'
    }
  }

  return {
    type: ALTERACAO_CHECK,
    payload: { nomeCheck: [nomeCheck], valor: value }
  }
};

export const onChangeCheckTitularidades = (props, nomeCheck, value) => {

  if (!value && props.titularidades.length > 0) {
    var teste = teste;

    return {
      type: SHOW_MESSAGE,
      payload: 'A operação não pode ser realizada pois existem Títulos cadastrados para esse Local'
    }
  }

  return {
    type: ALTERACAO_CHECK,
    payload: { nomeCheck: [nomeCheck], valor: value }
  }
};

export const listaLocalControlado = (nomeCliente, inicioControle, fimControle, nomeLocal, tipoLocal) => {

  if (nomeCliente == undefined) {
    nomeCliente = "";
  }

  if (inicioControle == undefined) {
    inicioControle = "";
  }

  if (fimControle == undefined) {
    fimControle = "";
  }

  if (nomeLocal == undefined) {
    nomeLocal = "";
  }

  if (tipoLocal == undefined) {
    tipoLocal = "";
  }

  return {
    type: LISTA_LOCAL_CONTROLADO,
    payload: { nomeCliente, inicioControle, fimControle, nomeLocal, tipoLocal }
  };
};

/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoLocalControlado = () => {
  return [{
    type: LIMPA_VALIDACOES,
  },
  {
    type: NOVO_LOCAL_CONTROLADO
  }]    
};

export const atualizaResponsabilidade = (e) => {
  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_RESPONSABILIDADE,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const atualizaPerfilAutorizado = (object, value) => {

  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZAR_PERFIL_AUTORIZADO_LOCAL,
    payload: { idAtualizacao, valor }
  }
}

export const removeResponsabilidade = (indice) => {
  return {
    type: REMOVE_RESPONSABILIDADE,
    payload: indice
  };
};

export const adicionaResponsabilidade = (tipo) => {
  return {
    type: ADICIONA_RESPONSABILIDADE,
    payload: tipo
  };
};

export const buscaLocalControladoEdicao = (id) => {
  return {
    type: BUSCA_LOCAL_CONTROLADO_EDICAO,
    payload: id
  };
};

export const setarImagemLocalControlado = (imagem) => {

  return {
    type: SETAR_IMAGEM_LOCAL_CONTROLADO,
    payload: { imagem }
  };
}

export const validaPeriodo = (name, date, compoeIntervalo, tipo, valorComparacao) => {

  var campo = name;
  var valor = date;
  var mensagem = "";
  var erro = false;
  var limpaPeriodo = false;
  let dataAtual = moment();

  if (compoeIntervalo && valorComparacao !== undefined && valorComparacao !== null) {
    if (tipo === 'start') {
      if (date.isAfter(valorComparacao, 'day')) {
        erro = true;
        mensagem = "Erro. Data superior a final";
      }
      else {
        limpaPeriodo = true;
      }
    }
    else if (tipo === 'end') {
      if (date.isBefore(valorComparacao, 'day')) {
        erro = true;
        mensagem = "Erro. Data inferior a inicial";
      }
      else {
        limpaPeriodo = true;
      }
    }
  }


  if (date !== null && date !== undefined && (name === 'dataFimControle' || name === 'dataEvento')) {
    if (dataAtual.isAfter(date, 'day')) {
      limpaPeriodo = false;
      erro = true;
      mensagem = "Erro. Data anterior a atual";
    }
  }

  if (limpaPeriodo) {
    return RetornosValidacoesPeriodoLimpas(name, date);
  }
  else {
    return [
      {
        type: ALTERACAO_DATEPICKER,
        payload: { name, date }
      },
      {
        type: VALIDACAO_CAMPO,
        payload: { erro: erro, propriedadeValidada: campo, mensagemErro: mensagem }
      },
    ]
  }
};

function RetornosValidacoesPeriodoLimpas(name, date) {
  return [
    {
      type: ALTERACAO_DATEPICKER,
      payload: { name, date }
    },
    {
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'dataInicioControle', mensagemErro: '' }
    },
    {
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'dataFimControle', mensagemErro: '' }
    },
  ]
}

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioLocalControladoSucesso = (locaisControlados, clientes, perfis) => {
  return {
    type: INICIO_LOCAL_CONTROLADO_SUCESSO,
    payload: { locaisControlados, clientes, perfis }
  }
};

export const onChangeAba = (indice, localControlado) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatorios(localControlado));

  if (localControlado.tipoLocalControlado === 'EVENTO' && (localControlado.dataEvento !== undefined && localControlado.dataEvento !== null && localControlado.dataEvento != '')) {
    validacoes.push(RetornoValidacaoDataEvento(indice, localControlado));
  }

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    var irParaAba = {
      type: CLIQUE_CONTROLE_ABA_LOCAL,
      payload: { indice: indice }
    }

    validacoes.push(irParaAba);
  }
  else {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);
  }

  return validacoes;
};

function RetornoValidacaoDataEvento(indice, localControlado) {

  var irParaParametrizacao = indice === 1;
  var erroLocalizado = false;
  var mensagem = '';

  if (irParaParametrizacao) {
/*     if (!moment(localControlado.dataEvento).isBetween(localControlado.dataInicioControle, localControlado.dataFimControle)) {
      erroLocalizado = true;
      mensagem = 'Data fora do período do evento';
    } */
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: erroLocalizado, propriedadeValidada: 'dataEvento', mensagemErro: mensagem }
  }

}

function RetornosArrayValidacoesCamposObrigatorios(localControlado) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('dataInicioControle', localControlado.dataInicioControle));
  validacoes.push(ValidaCampoObrigatorio('dataFimControle', localControlado.dataFimControle));
  validacoes.push(ValidaCampoObrigatorio('descricaoLocalControlado', localControlado.descricaoLocalControlado));

  if (localControlado.tipoLocalControlado === 'EVENTO') {
    validacoes.push(ValidaCampoObrigatorio('dataEvento', localControlado.dataEvento));
  }
  else {
    validacoes.push({
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'dataEvento', mensagemErro: '' }
    })
  }

  return validacoes;
}

export const salvaLocalControladoSucesso = (mensagem) => {
  return {
    type: SALVA_LOCAL_CONTROLADO_SUCESSO,
    payload: mensagem
  }
};

export const removeLocalControladoSucesso = (mensagem) => {
  return {
    type: REMOVE_LOCAL_CONTROLADO_SUCESSO,
    payload: mensagem
  }
};

export const buscaLocalControladoEdicaoSucesso = (localControlado) => {
  return {
    type: BUSCA_LOCAL_CONTROLADO_EDICAO_SUCESSO,
    payload: localControlado
  };
};

export const listaLocalControladoSucesso = (locais) => {
  return {
    type: LISTA_LOCAL_CONTROLADO_SUCESSO,
    payload: { locais }
  };
};

export const limparFiltroLocalControlado = () => {
  return {
    type: LIMPAR_LOCAL_CONTROLADO,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};