import {
    INICIA_ACESSO_PERFIL,
    SALVA_ACESSO_PERFIL,
    REMOVE_ACESSO_PERFIL,
    NOVO_ACESSO_PERFIL,
    BUSCA_ACESSO_PERFIL_EDICAO,
    INICIO_ACESSO_PERFIL_SUCESSO,
    SALVA_ACESSO_PERFIL_SUCESSO,
    REMOVE_ACESSO_PERFIL_SUCESSO,
    BUSCA_ACESSO_PERFIL_EDICAO_SUCESSO,
    LISTA_LOCAIS_PERFIS_POR_CLIENTE,
    LISTA_LOCAIS_PERFIS_SUCESSO, 
    LISTA_AMBIENTES_SUCESSO,
    LISTA_CONFIGURACAO_PONTO_PERFIL, 
    LISTA_CONFIGURACAO_PONTO_PERFIL_SUCESSO,
    ALTERACAO_CHECK_PONTO_ACESSO_PERFIL,  
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    LISTA_ACESSO_PERFIL,
    LISTA_ACESSO_PERFIL_SUCESSO,
    LIMPAR_ACESSO_PERFIL
  } from 'constants/ActionTypes';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaAcessoPerfil = () => {
    return {
      type: INICIA_ACESSO_PERFIL
    };
  };
  
  export const salvaAcessoPerfil = (acessoPerfil) => {

    var listaPontosAssociados = [];

    if(acessoPerfil.configuracaoPontoPerfil != null && acessoPerfil.configuracaoPontoPerfil.listaAmbientesVinculados != null)
    {
    for (var i = 0; i < acessoPerfil.configuracaoPontoPerfil.listaAmbientesVinculados.length; i++) {
      var listaPontos = acessoPerfil.configuracaoPontoPerfil.listaAmbientesVinculados[i].pontosAcessoVinculados;

      for (var j = 0; j < listaPontos.length; j++) {
          listaPontosAssociados.push({"idCriterio": 0, "idPontoAcesso":  listaPontos[j].idPontoAcesso, "id": 0, ativo: listaPontos[j].associado })
      }
    }
  }

    return {
      type: SALVA_ACESSO_PERFIL,
      payload: { acessoPerfil, listaPontosAssociados }
    };
  };
  
  export const removeAcessoPerfil = (id) => {
    return {
      type: REMOVE_ACESSO_PERFIL,
      payload: id
    };
  };


  export const listaAcessoPerfil = (perfil, ambiente, ponto) => {
    if(perfil == undefined){
      perfil = "";
    }
  
    if(ambiente == undefined){
      ambiente = "";
    }

    if(ponto == undefined){
      ponto = "";
    }
  
    return {
      type: LISTA_ACESSO_PERFIL,
      payload: {perfil, ambiente, ponto}
    };
  };

  /*#############################################################################################*/
  /* ------------------------ Actions Complementares da Funcionalidade --------------------------*/

  export const listaLocaisPerfisPorCliente = (name, object, value) => {
    var idCliente = value !== null ? value.id : 0;

    return {
      type: LISTA_LOCAIS_PERFIS_POR_CLIENTE,
      payload: { idCliente }
    }
  }

  export const listaConfiguracaoPontosPorPerfil = (idLocal, idPerfil) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_PERFIL,
      payload: { idLocal, idPerfil }
    }
  }
 
  export const onChangeCheckPontoAcesso = (object, value) => {
    var nomeCheck = [object.target.id].toString();
    var idPtoAcesso = nomeCheck.replace(/pontoAcesso_/g, "");

    return {
        type: ALTERACAO_CHECK_PONTO_ACESSO_PERFIL,
        payload: { idPontoAcessoAlterado: idPtoAcesso, valor: value }
    }   
}; 

  
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoAcessoPerfil = () => {
    return {
      type: NOVO_ACESSO_PERFIL
    };
  };
  
  export const buscaAcessoPerfilEdicao = (id) => {
    return {
      type: BUSCA_ACESSO_PERFIL_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  
  
  export const inicioAcessoPerfilSucesso = (acessosPerfil, clientes, locais, perfis) => {
    return {
      type: INICIO_ACESSO_PERFIL_SUCESSO,
      payload: { acessosPerfil, clientes, locais, perfis }
    }
  };
  
  export const salvaAcessoPerfilSucesso = (mensagem) => {
    return {
      type: SALVA_ACESSO_PERFIL_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removeAcessoPerfilSucesso = (mensagem) => {
    return {
      type: REMOVE_ACESSO_PERFIL_SUCESSO,
      payload: mensagem
    }
  };  
  
  export const buscaAcessoPerfilEdicaoSucesso = (equipamento) => {
    return {
      type: BUSCA_ACESSO_PERFIL_EDICAO_SUCESSO,
      payload: equipamento
    };
  }; 

  export const listaLocaisPerfisSucesso = (locais, perfis) => {
    return {
      type: LISTA_LOCAIS_PERFIS_SUCESSO,
      payload: { locais, perfis }
    };
  };

  export const listaConfiguracaoPontoPerfilSucesso = (configuracao) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_PERFIL_SUCESSO,
      payload: { configuracao }
    }
  }
  
  export const listaAmbientesSucesso = (ambientes) => {
    return {
      type: LISTA_AMBIENTES_SUCESSO,
      payload: { ambientes }
    };
  };  

  export const listaAcessoPerfilSucesso = (acessoPerfil) => {
    return {
      type: LISTA_ACESSO_PERFIL_SUCESSO,
      payload: { acessoPerfil }
    };
  };
  
  export const limparFiltroAcessoPerfil = () => {
    return {
      type: LIMPAR_ACESSO_PERFIL,
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 
  
  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };