import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import RoomIcon from '@material-ui/icons/Room';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CheckIcon from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import { NavLink } from 'react-router-dom';
import { UilExclamationTriangle } from '@iconscout/react-unicons'

export default class Convidados extends Component {


    render() {

        const { classes } = this.props;
        const portariaInicio = this.props.portariaInicio;
        const listaDeConvidados = portariaInicio.convidadosPessoaSelecionada;
        const ambientesDoAutorizante = portariaInicio.ambientesDoAutorizante;

        return (
            <Fragment>

                {
                    listaDeConvidados === null || listaDeConvidados === undefined || listaDeConvidados.length === 0 ?
                        (
                            <div style={{ width: '100%', textAlign: 'center', height: 240, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div>
                                    <img src={require("assets/images/semConvidados.PNG")} alt="Sem convidados" title="Sem convidados" />

                                </div>

                                <div style={{ textAlign: 'center', width: 355, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <span style={{ fontWeight: 400, fontSize: 14, color: 'rgba(72, 81, 84, 0,6)' }}>Não há convidados registrados para esta pessoa</span>
                                </div>

                                {ambientesDoAutorizante.length > 0 && (
                                    <NavLink onClick={() => { this.props.novaLiberacao(); }} style={{ textDecoration: 'none', color: '#000' }} className="prepend-icon" to={{ pathname: '/app/nlibera', state: [{ id: 1, name: 'teste', color: 'red' }] }}>
                                        <div>
                                            <div style={{ width: '100%', textAlign: 'center', cursor: 'pointer', color: '#05B59D', fontWeight: 400, fontSize: 14, textDecoration: 'underline' }}> Novo convite</div>
                                        </div>
                                    </NavLink>
                                )}



                            </div>
                        ) :
                        (
                            <TableContainer elevation={0} className={classes.padding} component={Paper}>
                                <Table style={{ width: '100%' }} className={classes.table} aria-label="simple table">
                                    <TableBody>

                                        {
                                            listaDeConvidados.map(convidado => {

                                                var imagemTratada = convidado.imagemAutorizado;
                                                imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

                                                return (
                                                    <div onClick={() => { this.props.selecionaLiberacao(convidado.idLiberacao); }}>
                                                        <NavLink style={{ textDecoration: 'none' }} className="prepend-icon" to={{ pathname: '/app/nlibera', state: [{ id: convidado.idLiberacao }] }}>
                                                            <TableRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }} key={convidado.idLiberacao}>
                                                                <TableCell align="left" style={{ width: 75, fontSize: 16, fontWeight: 400, borderBottom: '1px solid transparent' }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Avatar style={{ width: 35, height: 35, backgroundColor: '#FFEDDC' }}>
                                                                            <PersonIcon style={{ color: '#C55B20' }} />
                                                                        </Avatar>
                                                                        <Avatar style={{ width: 35, height: 35 }} alt={convidado.nomeAutorizado} src={`data:image/jpeg;base64,${imagemTratada}`} />
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell align="left" style={{ width: '30%', fontSize: 16, fontWeight: 400, borderBottom: '1px solid transparent' }}>
                                                                    <span style={{ float: 'left', width: '100%', fontSize: 16, fontWeight: 400, color: '#222224' }}>
                                                                        {convidado.nomeAutorizado}
                                                                    </span>
                                                                </TableCell>

                                                                <TableCell align="left" style={{ width: '35%', fontSize: 16, fontWeight: 400, borderBottom: '1px solid transparent' }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <RoomIcon />
                                                                        <span style={{ width: '100%', fontSize: 16, fontWeight: 400, color: '#222224' }}>
                                                                            {convidado.origem}
                                                                        </span>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell align="left" style={{ width: '25%', fontSize: 16, fontWeight: 400, borderBottom: '1px solid transparent' }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <LockOpenIcon />
                                                                        <span style={{ width: '100%', fontSize: 16, fontWeight: 400, color: '#222224' }}>
                                                                            {convidado.nomeAutorizante}
                                                                        </span>
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell align="right" style={{ width: '10%', fontSize: 16, fontWeight: 400, borderBottom: '1px solid transparent' }}>
                                                                    {
                                                                        convidado.emAlerta ? (
                                                                            <div style={{ display: 'flex' }}>
                                                                                <UilExclamationTriangle size={20} style={{ color: '#F2C94C' }} />
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ display: 'flex' }}>
                                                                                <CheckIcon style={{ color: '#16AA09' }} />
                                                                            </div>
                                                                        )
                                                                    }

                                                                </TableCell>
                                                            </TableRow>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                }


            </Fragment>
        )
    }

}