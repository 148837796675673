import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_TITULARIDADE, BUSCA_TITULARIDADE_EDICAO, REMOVE_TITULARIDADE, INICIA_TITULARIDADE, LISTA_TITULARIDADE, INICIO_CENARIO_TITULARIDADE_LOCAL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaTitularidadeSucesso,
         removeTitularidadeSucesso,
         iniciaTitularidade, 
         inicioTitularidadeSucesso, 
         buscaTitularidadeEdicaoSucesso,
         inicioCenarioTitularidadePorLocalControladoSucesso,
         listaTitularidadeSucesso } from "actions/Titularidade";
import { limpaValidacoes } from "actions/Global";      

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getTitularidades = async () =>
    await requisicaoApi.get('/titularidades')
    .then(resposta => resposta)
    .catch(error => error);

  const getLocaisControlados = async () =>
    await requisicaoApi.get('/locais')
    .then(resposta => resposta)
    .catch(error => error);

  const getTitularidadeLocal = async (id) =>
    await requisicaoApi.get('/titularidades/localControlado/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getTitularidadeEdicao = async (id) =>
    await requisicaoApi.get('/titularidades/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaTitularidade = async (modeloFiltro, numeroFiltro, situacaoFiltro) => 
    await requisicaoApi.get('/titularidades?Modelo=' +  modeloFiltro + '&Numero=' + numeroFiltro + '&Situacao=' + situacaoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaTitularidadeRequest = async (titularidade) => await requisicaoApi.post('/titularidades', 
  {
    "id": titularidade.id,
    "principal": titularidade.descricaoGrupoTitularidade,
    "secundario": titularidade.descricaoUnidadeTitularidade,
    "idLocalControlado": titularidade.idLocalControladoFixado,
    "ativo": true
  })
  .then(resposta => resposta)
  .catch(error => error);


  const atualizaTitularidadeRequest = async (titularidade) => await requisicaoApi.put('/titularidades', 
  {
    "id": titularidade.id,
    "principal": titularidade.descricaoGrupoTitularidade,
    "secundario": titularidade.descricaoUnidadeTitularidade,
    "idLocalControlado": titularidade.idLocalControladoFixado,
    "ativo": true
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteTitularidadeRequest = async (id) =>
  await requisicaoApi.delete('/titularidades?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------------- Functions --------------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaTitularidade() {
    try {
      const listagemLocais = yield call(getLocaisControlados);

      if(!listagemLocais.isAxiosError && listagemLocais.data) {

        if(listagemLocais.data.length === 1)
        {
          const local = listagemLocais.data[0].id;
          const listagemGrid = yield call(getTitularidadeLocal, local);

          if(listagemGrid.data) {
            yield put(inicioCenarioTitularidadePorLocalControladoSucesso(listagemGrid.data, listagemLocais.data, local));
            yield put(limpaValidacoes());
          }
        }
        else
        {
          yield put(inicioTitularidadeSucesso(listagemLocais.data));
        }
        
      }
/*       else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar titularidades'));
        }
      }        */     
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnCenarioTitularidadePorLocalControlado({ payload }) {
    try {
      const local = payload.idLocal;
      const listagemGrid = yield call(getTitularidadeLocal, local);

      if(!listagemGrid.isAxiosError && listagemGrid.data) {
        yield put(inicioCenarioTitularidadePorLocalControladoSucesso(listagemGrid.data, null, local));
      }
/*       else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar titularidades'));
        }
      }        */     
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaTitularidadeEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getTitularidadeEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaTitularidadeEdicaoSucesso(retorno.data));
        yield put(limpaValidacoes());
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do titularidade: ' + id));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaTitularidade({ payload }) {
    try {
      const modeloFiltro = payload.modelo;
      const numeroFiltro = payload.numero;
      const situacaoFiltro = payload.situacao;
      const retorno = yield call(getTitularidades);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(listaTitularidadeSucesso(retorno.data));
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de titularidades'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaTitularidade({ payload }) {
  try {

    const titularidade = payload;
    var resultado = "";
    var errosAgrupados = "";

      if(titularidade.id === 0) {
        const retorno = yield call(adicionaTitularidadeRequest, titularidade);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaTitularidadeRequest, titularidade);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        const listagemLocais = yield call(getLocaisControlados);
        const listagemGrid = yield call(getTitularidadeLocal, titularidade.idLocalControladoFixado);
        yield put(inicioCenarioTitularidadePorLocalControladoSucesso(listagemGrid.data, listagemLocais.data, titularidade.idLocalControladoFixado));
        ////yield put(iniciaTitularidade());
        yield put(salvaTitularidadeSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveTitularidade({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteTitularidadeRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaTitularidade());
        yield put(removeTitularidadeSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesTitularidade() {
  yield takeEvery(INICIA_TITULARIDADE, fnIniciaTitularidade);
}

export function* iniciaCenarioTitularidadePorLocalControlado() {
  yield takeEvery(INICIO_CENARIO_TITULARIDADE_LOCAL, fnCenarioTitularidadePorLocalControlado);
}

export function* salvaTitularidade() {
  yield takeEvery(SALVA_TITULARIDADE, fnSalvaTitularidade);
}

export function* buscaTitularidadeEdicao() {
  yield takeEvery(BUSCA_TITULARIDADE_EDICAO, fnBuscaTitularidadeEdicao);
}

export function* removeTitularidade() {
  yield takeEvery(REMOVE_TITULARIDADE, fnRemoveTitularidade);
}

export function* listaTitularidade() {
  yield takeEvery(LISTA_TITULARIDADE, fnListaTitularidade)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesTitularidade),
             fork(iniciaCenarioTitularidadePorLocalControlado),
             fork(salvaTitularidade), 
             fork(buscaTitularidadeEdicao),
             fork(removeTitularidade),
             fork(listaTitularidade)]);
}