import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_CLIENTE, INICIA_CLIENTE, CONSULTA_CEP, BUSCA_CLIENTE_EDICAO, REMOVE_CLIENTE, LISTA_CLIENTE } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import requisicaoApiCep from 'util/ApiCep';
import { userSignOut } from "actions/Auth";
import {
  showAuthMessage,
  exibirMensagemErro,
  salvaClienteSucesso,
  removeClienteSucesso,
  inicioClienteSucesso,
  iniciaCliente,
  consultaCepSucesso,
  consultaCepError,
  buscaClienteEdicaoSucesso,
  listaClienteSucesso
} from "actions/Cliente";
import { limpaValidacoes } from "actions/Global";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/

const getClientes = async () =>
  await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);

const getClienteEdicao = async (id) =>
  await requisicaoApi.get('/clientes/' + id)
    .then(resposta => resposta)
    .catch(error => error);

const getCep = async (cep) =>
  await requisicaoApiCep.get('/' + cep + '/json/')
    .then(resposta => resposta)
    .catch(error => error);

const getListaCliente = async (codigoClienteFiltro, cpfCnpjFiltro, nomeFiltro, tipoFiltro) =>
  await requisicaoApi.get('/clientes?Codigo=' + codigoClienteFiltro + '&CpfCnpj=' + cpfCnpjFiltro + '&Nome=' + nomeFiltro + '&TipoPessoa=' + tipoFiltro)
    .then(resposta => resposta)
    .catch(error => error);

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/

const adicionaClientePessoaFisicaRequest = async (cliente) => await requisicaoApi.post('/clientes',
  {
    "id": cliente.id,
    "idPessoa": 0,
    "tipoPessoa": "FISICA",
    "nomeRazaoSocial": cliente.nomeRazaoSocial,
    "tipoPessoa": cliente.tipoPessoa,
    "numeroCpfCnpj": cliente.numeroCpfCnpj,
    "dataNascimentoOuAbertura": cliente.dataNascimentoOuAbertura,
    "nomeMae": cliente.nomeMae,
    "sexo": cliente.sexo,
    "estadoCivil": cliente.estadoCivil,
    "imagem": cliente.imagem,
    "telefoneUm": cliente.telefoneUm,
    "telefoneDois": cliente.telefoneDois,
    "email": cliente.email,
    "site": cliente.site,
    "cep": cliente.cep,
    "logradouro": cliente.logradouro,
    "numero": cliente.numero,
    "complemento": cliente.complemento,
    "bairro": cliente.bairro,
    "uf": cliente.uf,
    "semNumero": cliente.semNumero,
    "nomeUf": cliente.nomeUf,
    "idMunicipio": cliente.idMunicipio,
    "codigoIbge": cliente.codigoIbge,
    "nomeMunicipio": cliente.nomeMunicipio,
    "pontoReferencia": cliente.pontoReferencia,
    "telefones": cliente.telefones,
    "emails": cliente.emails,
    "numeroLocaisFixos": cliente.numeroLocaisFixos,
    "numeroLocaisEventos": cliente.numeroLocaisEventos,
    "numeroLocaisFixosPontos": cliente.numeroLocaisFixosPontos,
    "numeroLocaisEventosPontos": cliente.numeroLocaisEventosPontos,
    "numeroLocaisFixosUsuarios": cliente.numeroLocaisFixosUsuarios,
    "ocultaDocumento": cliente.ocultaDocumento,
    "ocultaTelefone": cliente.ocultaTelefone,
    "obrigaValidacaoApp": cliente.obrigaValidacaoApp,
    "tipoControleVisibilidade": cliente.tipoControleVisibilidade,
    "enviaPulsoAbertura": cliente.enviaPulsoAbertura
  })
  .then(resposta => resposta)
  .catch(error => error);

const adicionaClientePessoaJuridicaRequest = async (cliente) => await requisicaoApi.post('/clientes',
  {
    "id": cliente.id,
    "idPessoa": 0,
    "tipoPessoa": "JURIDICA",
    "nomeRazaoSocial": cliente.nomeRazaoSocial,
    "tipoPessoa": cliente.tipoPessoa,
    "numeroCpfCnpj": cliente.numeroCpfCnpj,
    "nomeFantasia": cliente.nomeFanstasia,
    "inscricaoEstadual": cliente.inscricaoEstadual,
    "inscricaoMunicipal": cliente.inscricaoMunicipal,
    "nomeContato": cliente.nomeContato,
    "imagem": cliente.imagem,
    "telefoneUm": cliente.telefoneUm,
    "telefoneDois": cliente.telefoneDois,
    "email": cliente.email,
    "site": cliente.site,
    "cep": cliente.cep,
    "logradouro": cliente.logradouro,
    "numero": cliente.numero,
    "complemento": cliente.complemento,
    "bairro": cliente.bairro,
    "uf": cliente.uf,
    "nomeUf": cliente.nomeUf,
    "idMunicipio": cliente.idMunicipio,
    "semNumero": cliente.semNumero,
    "codigoIbge": cliente.codigoIbge,
    "nomeMunicipio": cliente.nomeMunicipio,
    "pontoReferencia": cliente.pontoReferencia,
    "isentoInscricaoEstadual": cliente.isentoInscricaoEstadual,
    "isentoInscricaoMunicipal": cliente.isentoInscricaoMunicipal,
    "telefones": cliente.telefones,
    "emails": cliente.emails,
    "numeroLocaisFixos": cliente.numeroLocaisFixos,
    "numeroLocaisEventos": cliente.numeroLocaisEventos,
    "numeroLocaisFixosPontos": cliente.numeroLocaisFixosPontos,
    "numeroLocaisEventosPontos": cliente.numeroLocaisEventosPontos,
    "obrigaValidacaoApp": cliente.obrigaValidacaoApp,
    "numeroLocaisFixosUsuarios": cliente.numeroLocaisFixosUsuarios,
    "ocultaDocumento": cliente.ocultaDocumento,
    "ocultaTelefone": cliente.ocultaTelefone,
    "tipoControleVisibilidade": cliente.tipoControleVisibilidade,
    "enviaPulsoAbertura": cliente.enviaPulsoAbertura
  })
  .then(resposta => resposta)
  .catch(error => error);

const atualizaClientePessoaFisicaRequest = async (cliente) => await requisicaoApi.put('/clientes',
  {
    "id": cliente.id,
    "idPessoa": 0,
    "tipoPessoa": "FISICA",
    "nomeRazaoSocial": cliente.nomeRazaoSocial,
    "tipoPessoa": cliente.tipoPessoa,
    "numeroCpfCnpj": cliente.numeroCpfCnpj,
    "dataNascimentoOuAbertura": cliente.dataNascimentoOuAbertura,
    "nomeMae": cliente.nomeMae,
    "sexo": cliente.sexo,
    "estadoCivil": cliente.estadoCivil,
    "imagem": cliente.imagem,
    "telefoneUm": cliente.telefoneUm,
    "telefoneDois": cliente.telefoneDois,
    "email": cliente.email,
    "site": cliente.site,
    "cep": cliente.cep,
    "logradouro": cliente.logradouro,
    "numero": cliente.numero,
    "complemento": cliente.complemento,
    "bairro": cliente.bairro,
    "uf": cliente.uf,
    "semNumero": cliente.semNumero,
    "nomeUf": cliente.nomeUf,
    "idMunicipio": cliente.idMunicipio,
    "codigoIbge": cliente.codigoIbge,
    "nomeMunicipio": cliente.nomeMunicipio,
    "pontoReferencia": cliente.pontoReferencia,
    "telefones": cliente.telefones,
    "emails": cliente.emails,
    "numeroLocaisFixos": cliente.numeroLocaisFixos,
    "numeroLocaisEventos": cliente.numeroLocaisEventos,
    "numeroLocaisFixosPontos": cliente.numeroLocaisFixosPontos,
    "numeroLocaisEventosPontos": cliente.numeroLocaisEventosPontos,
    "numeroLocaisFixosUsuarios": cliente.numeroLocaisFixosUsuarios,
    "ocultaDocumento": cliente.ocultaDocumento,
    "ocultaTelefone": cliente.ocultaTelefone,
    "obrigaValidacaoApp": cliente.obrigaValidacaoApp,
    "tipoControleVisibilidade": cliente.tipoControleVisibilidade,
    "enviaPulsoAbertura": cliente.enviaPulsoAbertura
  })
  .then(resposta => resposta)
  .catch(error => error);

const atualizaClientePessoaJuridicaRequest = async (cliente) => await requisicaoApi.put('/clientes',
  {
    "id": cliente.id,
    "idPessoa": 0,
    "tipoPessoa": "JURIDICA",
    "nomeRazaoSocial": cliente.nomeRazaoSocial,
    "tipoPessoa": cliente.tipoPessoa,
    "numeroCpfCnpj": cliente.numeroCpfCnpj,
    "nomeFantasia": cliente.nomeFanstasia,
    "inscricaoEstadual": cliente.inscricaoEstadual,
    "inscricaoMunicipal": cliente.inscricaoMunicipal,
    "nomeContato": cliente.nomeContato,
    "imagem": cliente.imagem,
    "telefoneUm": cliente.telefoneUm,
    "telefoneDois": cliente.telefoneDois,
    "email": cliente.email,
    "site": cliente.site,
    "cep": cliente.cep,
    "logradouro": cliente.logradouro,
    "numero": cliente.numero,
    "complemento": cliente.complemento,
    "bairro": cliente.bairro,
    "uf": cliente.uf,
    "semNumero": cliente.semNumero,
    "nomeUf": cliente.nomeUf,
    "idMunicipio": cliente.idMunicipio,
    "codigoIbge": cliente.codigoIbge,
    "nomeMunicipio": cliente.nomeMunicipio,
    "pontoReferencia": cliente.pontoReferencia,
    "isentoInscricaoEstadual": cliente.isentoInscricaoEstadual,
    "isentoInscricaoMunicipal": cliente.isentoInscricaoMunicipal,
    "telefones": cliente.telefones,
    "emails": cliente.emails,
    "numeroLocaisFixos": cliente.numeroLocaisFixos,
    "numeroLocaisEventos": cliente.numeroLocaisEventos,
    "numeroLocaisFixosPontos": cliente.numeroLocaisFixosPontos,
    "numeroLocaisEventosPontos": cliente.numeroLocaisEventosPontos,
    "numeroLocaisFixosUsuarios": cliente.numeroLocaisFixosUsuarios,
    "ocultaDocumento": cliente.ocultaDocumento,
    "ocultaTelefone": cliente.ocultaTelefone,
    "obrigaValidacaoApp": cliente.obrigaValidacaoApp,
    "tipoControleVisibilidade": cliente.tipoControleVisibilidade,
    "enviaPulsoAbertura": cliente.enviaPulsoAbertura
  })
  .then(resposta => resposta)
  .catch(error => error);

const deleteClienteRequest = async (id) =>
  await requisicaoApi.delete('/clientes?id=' + id)
    .then(resposta => resposta)
    .catch(error => error);

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/

function* fnIniciaClientes() {
  try {
    const retorno = yield call(getClientes);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(inicioClienteSucesso(retorno.data));
      yield put(limpaValidacoes());
    }
    else {
      if (retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
        yield put(userSignOut());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao listar clientes'));
      }

    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnConsultaCep({ payload }) {
  try {
    const cep = payload;
    const retorno = yield call(getCep, cep);

    if (!retorno.isAxiosError) {
      if (!retorno.data.erro) {
        yield put(consultaCepSucesso(retorno.data));
      }
      else {
        yield put(consultaCepError());
        yield put(showAuthMessage('Cep não encontrado: ' + cep));
      }
    }
    else {
      yield put(consultaCepError());
      yield put(showAuthMessage('Cep inválido: ' + cep));
    }

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnBuscaClienteEdicao({ payload }) {
  try {
    const id = payload;
    const retorno = yield call(getClienteEdicao, id);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(limpaValidacoes());
      yield put(buscaClienteEdicaoSucesso(retorno.data));
    }
    else {
      if (retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
        yield put(userSignOut());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do cliente: ' + id));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaCliente({ payload }) {
  try {
    const codigoClienteFiltro = payload.codigo;
    const cpfCnpjFiltro = payload.cpfCnpj;
    const nomeFiltro = payload.nome;
    const tipoFiltro = payload.tipoPessoa;
    const retorno = yield call(getListaCliente, codigoClienteFiltro, cpfCnpjFiltro, nomeFiltro, tipoFiltro);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(listaClienteSucesso(retorno.data));
    }
    else {
      if (retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
        yield put(userSignOut());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de cliente'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/


function* fnSalvaCliente({ payload }) {
  try {

    const cliente = payload;
    var resultado = "";

    if (payload.tipoPessoa === 'FISICA') {
      if (cliente.id === 0) {
        const retorno = yield call(adicionaClientePessoaFisicaRequest, cliente);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaClientePessoaFisicaRequest, cliente);
        resultado = retorno;
      }
    }
    else {
      if (cliente.id === 0) {
        const retorno = yield call(adicionaClientePessoaJuridicaRequest, cliente);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaClientePessoaJuridicaRequest, cliente);
        resultado = retorno;
      }
    }

    if (!resultado.isAxiosError && resultado.data) {
      if (resultado.data.sucesso) {
        yield put(iniciaCliente());
        yield put(salvaClienteSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else {
      if (resultado.response.data) {
        if (resultado.response.data.inconsistencias.length > 0) {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);

          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveCliente({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteClienteRequest, id);

    if (!retorno.isAxiosError && retorno.data) {
      if (retorno.data.sucesso) {
        yield put(iniciaCliente());
        yield put(removeClienteSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else {
      if (retorno.response.data.mensagem) {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/

export function* iniciaAcoesCliente() {
  yield takeEvery(INICIA_CLIENTE, fnIniciaClientes);
}

export function* consultarCep() {
  yield takeEvery(CONSULTA_CEP, fnConsultaCep);
}

export function* salvaCliente() {
  yield takeEvery(SALVA_CLIENTE, fnSalvaCliente);
}

export function* buscaClienteEdicao() {
  yield takeEvery(BUSCA_CLIENTE_EDICAO, fnBuscaClienteEdicao);
}

export function* removeCliente() {
  yield takeEvery(REMOVE_CLIENTE, fnRemoveCliente);
}

export function* listaCliente() {
  yield takeEvery(LISTA_CLIENTE, fnListaCliente)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/

export default function* rootSaga() {
  yield all([fork(iniciaAcoesCliente),
  fork(salvaCliente),
  fork(consultarCep),
  fork(buscaClienteEdicao),
  fork(removeCliente),
  fork(listaCliente)]);
}
