import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import CustomScrollbars from 'util/CustomScrollbars';
import Avatar from '@material-ui/core/Avatar';
import { RiExchangeFill } from "@react-icons/all-files/ri/RiExchangeFill";
import Drawer from '@material-ui/core/Drawer';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { alternaCliente, alternarCliente, onChangeFilterClienteAlternar, fechaDrawerAlteranacao } from 'actions/Inicio';

const StyledCard = withStyles({
  root: {
    padding: 8,
    backgroundColor: 'red',
    border: '1px solid #F4F4F7',
    boxSizing: 'border-box',
    borderRadius: '8px',
    height: 56
  },
  "&.MuiCardHeader-root": {
    padding: 8,
    backgroundColor: 'yellow'
  },
})(Card);

const styles = theme => ({
  input: {
    fontSize: 19,
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',

    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "gray",
      fontSize: 20,
      lineHeight: 24,
      fontWeight: 400,
      fontFamily: 'Montserrat, sans-serif'
    },
  },

  root: {
    "&.MuiCardHeader-root": {
      padding: 8,
      backgroundColor: 'red'
    },
  }

});

class SidenavContent extends Component {
  constructor() {
    super();
    this.state = { alternarCliente: false }
  }

/*   componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if (menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  } */

  handleTeste() {
  const { history } = this.props;
  const that = this;
  const pathname = `${history.location.pathname}`;// get current path

  const menuLi = document.getElementsByClassName('menu');
  for (let i = 0; i < menuLi.length; i++) {
    menuLi[i].onclick = function (event) {

      const parentLiEle = that.closest(this, 'li');
      if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
        event.stopPropagation();

        if (menuLi[i].classList.contains('open')) {
          menuLi[i].classList.remove('open', 'active');
        } else {
          menuLi[i].classList.add('open', 'active');
        }
      } else {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open');
          } else {
            if (menuLi[j].classList.contains('open')) {
              menuLi[j].classList.remove('open');
            } else {
              menuLi[j].classList.add('open');
            }
          }
        }
      }
    }
  }


  const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
  try {
    const activeNav = this.closest(activeLi, 'ul'); // select closest ul
    if (activeNav.classList.contains('sub-menu')) {
      this.closest(activeNav, 'li').classList.add('open');
    } else {
      this.closest(activeLi, 'li').classList.add('open');
    }
  } catch (error) {

  }
}

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  handleOlaTeste() {
    alert('Inicio');
    this.handleTeste();
    alert('Fim');
  }

  handleAlternarCliente = (val) => {
    this.setState({ alternarCliente: val });
  }

  ObtemMenuUsuario = (configuracao) => {
    var menusRetorno = [];
    var itensRetorno = [];
    var ehUzer = configuracao.uzerAcessoAmbienteCliente || configuracao.uzerAcessoPainelAdministrativo || configuracao.idPerfilSistema === 1;

    for (var indice in configuracao.menus) {
      var menu = configuracao.menus[indice];

      for (var indice in menu.itensDoMenu) {
        var item = menu.itensDoMenu[indice];
        var autorizado = configuracao.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.funcionalidadeAcaoUsuarioPortal.funcionalidadePortal.idItemMenu === item.id).length > 0;

        if (autorizado && menusRetorno.filter(x => x.id === item.idMenuSistema).length === 0) {
          menusRetorno.push(menu);
        }
      }
    }

    for (var indice in menusRetorno) {
      var menu = menusRetorno[indice];
      itensRetorno = [];

      for (var indice in menu.itensDoMenu) {
        var item = menu.itensDoMenu[indice];
        var autorizado = configuracao.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.funcionalidadeAcaoUsuarioPortal.funcionalidadePortal.idItemMenu === item.id).length > 0;

        if (autorizado) {

          if (!ehUzer && item.descricaoItem === 'Clientes') {
            item.descricaoItem = 'Meu negócio';
            item.rota = 'routes/clientes/indexClienteFixo'
          }

          itensRetorno.push(item);
        }
      }

      menu.itensDoMenu = itensRetorno;
    }

    this.handleTeste()
    return menusRetorno;
  }


  render() {

    const inicio = this.props.inicio;
    var configuracao = this.props.configuracao !== undefined ? this.props.configuracao : {};
    var adm = configuracao.uzerAcessoAmbienteCliente || configuracao.uzerAcessoPainelAdministrativo || configuracao.idPerfilSistema === 1 || configuracao.idUsuarioSistema === 1
    var ehMulti = configuracao.ehMulti;
    var menuRenderizar = this.ObtemMenuUsuario(configuracao);
    var imagemTratadaCliente = "";
    const listaClientes = adm ? inicio.listaResultadosPesquisaClientes : inicio.listaDeClientesDoUsuario;

    var listaClientesExibicaoParaAlternar = listaClientes.sort((a, b) => b - a).slice(0, 4);
    const clienteFixo = inicio.nomeClienteFixado !== "" && inicio.nomeClienteFixado !== undefined && inicio.nomeClienteFixado !== null;
    var imagemTratada = inicio.imgClienteFixado;
    imagemTratada = imagemTratada !== null && imagemTratada !== "" && imagemTratada !== undefined ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;
    const alternarCliente = inicio.alternarCliente;
    const { classes } = this.props;

    return (
      <CustomScrollbars className=" scrollbar">

        {
          (clienteFixo) && (

            imagemTratada !== null ?
              <NavLink to="/app/inicio">
                <Avatar variant='square' style={{ width: 200, height: 200, marginLeft: 'auto', marginRight: 'auto', marginTop: 15 }} src={`data:image/jpeg;base64,${imagemTratada}`} />
              </NavLink>
              :
              <NavLink to="/app/inicio">
                <Avatar variant='rounded' style={{ width: 250, height: 200 }} src={require("assets/images/no-image.jpg")} />
              </NavLink>
          )}


        <div style={{ color: '#222224', textAlign: 'center', width: '100%', paddingLeft: 10, paddingRight: 10, paddingTop: 5, fontWeight: 500, fontFamily: 'Montserrat, sans-serif' }}>{inicio.nomeClienteFixado}</div>

        {
          (adm || ehMulti) &&
          (
            <NavLink to="/app/inicio">
              <div onClick={() => this.props.alternaCliente()} style={{ textAlign: 'center', width: '100%', fontFamily: 'Montserrat, sans-serif' }}>
                {/* <a  style={{ cursor: 'pointer', color: '#05B59D', fontFamily: 'Montserrat, sans-serif', fontSize: '14px', fontWeight: 400 }}> */}alternar{/* </a> */}
              </div>
            </NavLink>
          )
        }

        <Drawer anchor="left" open={alternarCliente} onClose={() => this.props.fechaDrawerAlteranacao()}>

          {
            (clienteFixo) && (

              imagemTratada !== null ?

                <Avatar variant='square' style={{ width: 200, height: 200, marginLeft: 'auto', marginRight: 'auto', marginTop: 15 }} src={`data:image/jpeg;base64,${imagemTratada}`} />
                :
                <Avatar variant='rounded' style={{ width: 250, height: 200 }} src={require("assets/images/no-image.jpg")} />
            )
          }

          {
            <Fragment>
              <div style={{ color: '#222224', textAlign: 'center', width: '375px', fontWeight: 500, paddingLeft: 10, paddingRight: 10, paddingTop: 5, fontFamily: 'Montserrat, sans-serif' }}>{inicio.nomeClienteFixado}</div>

              <Divider style={{ marginBottom: 15, marginTop: 10 }} light />


              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <div style={{ color: '#3A3A3D', fontWeight: 400, fontSize: '16px', fontFamily: 'Montserrat, sans-serif', letterSpacing: '0.15px' }}>Selecione um cliente</div>
                <TextField
                  variant="filled"
                  placeholder="Pesquisar..."
                  value={this.props.textoFiltroClienteAlternar}
                  onChange={this.props.onChangeFilterClienteAlternar}
                  margin="normal"
                  fullWidth={true}
                  style={{ lineHeight: '24px', marginTop: '10px', marginBottom: '10px', backgroundColor: '#00000000' }}
                  InputProps={{
                    classes: {
                      input: classes.input
                    },
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />

                {
                  listaClientesExibicaoParaAlternar.map(n => {

                    imagemTratadaCliente = n.imagem;
                    imagemTratadaCliente = imagemTratadaCliente !== null && imagemTratadaCliente !== "" && imagemTratadaCliente !== undefined ? imagemTratadaCliente.substr(imagemTratadaCliente.indexOf(",") + 1) : null;

                    return (<Fragment key={`key${n.id}`}>
                      <div onClick={() => { this.props.alternarCliente(n.id); }} style={{ cursor: 'pointer' }}>
                        <Card style={{ border: '1px solid #F4F4F7', boxSizing: 'border-box', borderRadius: '8px', height: 56, marginBottom: 10 }}>
                          <CardHeader style={{ padding: 8 }}
                            avatar={
                              imagemTratadaCliente !== null ?
                                <Avatar style={{ width: 40, height: 40 }} src={`data:image/jpeg;base64,${imagemTratadaCliente}`} /> :
                                <Avatar style={{ width: 40, height: 40 }} src={require("assets/images/no-image.jpg")} />
                            }
                            action={
                              <ChevronRightIcon style={{ marginTop: 17, color: '#3A3A3D' }} />
                            }
                            title={n.nomeRazaoSocial}
                          ///subheader="September 14, 2016"
                          />
                        </Card>
                      </div>
                    </Fragment>);
                  })}
              </div>

            </Fragment>
          }


        </Drawer>

        <ul className="nav-menu">

          <li className="nav-header">
            <div onClick={() => { this.handleOlaTeste() }} >
            Principal
            </div>
          </li>

          {
            menuRenderizar.sort((a, b) => Number(a.ordem) - Number(b.ordem)).map(menu => {

              return (
                <li key={`key${menu.id}`} className="menu collapse-box ">

                  <Button>
                    <i className="zmdi zmdi-compass zmdi-hc-fw" />
                    <span style={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', fontSize: '14px' }} className="nav-text">
                      {menu.descricaoMenu}
                    </span>
                  </Button>

                  <ul className="sub-menu">

                    {
                      menu.itensDoMenu.sort((a, b) => Number(a.ordem) - Number(b.ordem)).map(item => {
                        return (
                          <li key={`key${item.id}`}>
                            <NavLink className="prepend-icon" to={`/app/${item.path}`}>
                              <span style={{ fontWeight: 500 }} className="nav-text text-transform-none">{item.descricaoItem}</span>
                            </NavLink>
                          </li>
                        )
                      })
                    }
                    
                  </ul>
                </li>
              )

            })
          }

        </ul>
      </CustomScrollbars>
    );
  }
}

SidenavContent.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = ({ inicio, auth, settings }) => {
  const { perfil } = auth;
  const { configuracao, patch } = settings
  return { inicio, perfil, configuracao };
};

export default connect(mapStateToProps, { alternaCliente, alternarCliente, onChangeFilterClienteAlternar, fechaDrawerAlteranacao })(withRouter(withStyles(styles)(SidenavContent)));
