import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FiUnlock } from "@react-icons/all-files/fi/FiUnlock";
import { NavLink } from 'react-router-dom';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: -60,
    width: '20%'
  },
})((props) => (
  <Menu
    fullWidth
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
          textDecoration: 'none',

          '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
        },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button fullWidth
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        style={{ height: '55.5px', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, fontSize: 16, textTransform: 'none', borderRadius: 25, padding: 0, boxShadow: 'none', textAlign: 'center', verticalAlign: 'middle', lineHeight: '37.5px' }}
      >
        Ações
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <NavLink style={{textDecoration: 'none', color: '#000'}} className="prepend-icon" to={{ pathname: '/app/nlibera', state: [{ id: 1, name: 'teste', color: 'red' }] }}>
          <StyledMenuItem fullWidth>
            <ListItemIcon>
              <FiUnlock size={20} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Nova liberação" />
          </StyledMenuItem>
        </NavLink>
        <StyledMenuItem fullWidth>
          <ListItemIcon>
            <FaEdit size={20} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Nova Observação" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}