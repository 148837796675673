import React, { Component, Fragment } from 'react';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Topo from './_topo'
import Listagens from './_listagens'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

export default class Conteudo extends Component {


    render() {

        var idAtendto = this.props.idAtendtoDCAnalise;

        return (
            <Fragment>
                <Topo {...this.props} />

                <Listagens {...this.props} />


                <Grid container spacing={3}>
                    <Grid item xs={6} style={{padding: 30}}>
                        <Button style={{ width: '100%', height: 45, padding: '14px 36px', borderRadius: 100 }} variant="outlined" onClick={() => { this.props.decidirDuploCheck(idAtendto, false); }}>
                            <span style={{ fontStyle: 'normal', color: '#828282', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>NEGAR</span>
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{padding: 30}}>
                    <Button style={{ width: '100%', height: 45, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" onClick={() => { this.props.decidirDuploCheck(idAtendto, true); }} >
                                        <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>AUTORIZAR</span>
                    </Button>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

}