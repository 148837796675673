import {
  INICIA_UNIDADE,
  SALVA_UNIDADE,
  REMOVE_UNIDADE,
  NOVO_UNIDADE,
  BUSCA_UNIDADE_EDICAO,
  INICIO_UNIDADE_SUCESSO,
  SALVA_UNIDADE_SUCESSO,
  REMOVE_UNIDADE_SUCESSO,
  NOVO_ESCANINHO,
  ATUALIZA_ESCANINHO,
  REMOVE_ESCANINHO,
  NOVA_GARAGEM,
  ATUALIZA_GARAGEM,
  REMOVE_GARAGEM,
  BUSCA_UNIDADE_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_UNIDADE,
  LISTA_UNIDADE_SUCESSO,
  LIMPAR_UNIDADE,
  INICIO_CENARIO_UNIDADE_LOCAL,
  INICIO_CENARIO_UNIDADE_LOCAL_SUCESSO
} from 'constants/ActionTypes';
import { ValidaCampoObrigatorio, ValidaCampoObrigatorioCombo } from 'luxFw/transformations/Validations';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaUnidade = () => {
  return {
    type: INICIA_UNIDADE
  };
};

export const iniciaCenarioUnidadePorLocalControlado = (name, object, value) => {
  var idLocal = value !== null ? value.id : 0;

  return {
    type: INICIO_CENARIO_UNIDADE_LOCAL,
    payload: { idLocal }
  };
}

export const inicioCenarioUnidadePorLocalControladoSucesso = (unidades, locaisControlados, idLocal) => {
  return {
    type: INICIO_CENARIO_UNIDADE_LOCAL_SUCESSO,
    payload: { unidades, locaisControlados, idLocal }
  };
}

export const salvaUnidade = (unidade) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatorios(unidade));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if(errosEncontrados.length === 0)
  {
    return {
      type: SALVA_UNIDADE,
      payload: unidade 
    }
  }
  else 
  {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }      
    
    validacoes.push(exibeMensagem);
    
    return validacoes;
  }  
};

export const removeUnidade = (id) => {
  return {
    type: REMOVE_UNIDADE,
    payload: id
  };
};

export const listaUnidade = (modelo, numero, situacao) => {
  if (modelo == undefined) {
    modelo = "";
  }

  if (numero == undefined) {
    numero = "";
  }

  if (situacao == undefined) {
    situacao = "";
  }

  return {
    type: LISTA_UNIDADE,
    payload: { modelo, numero, situacao }
  };
};


/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoUnidade = () => {
  return {
    type: NOVO_UNIDADE
  };
};

export const buscaUnidadeEdicao = (id) => {
  return {
    type: BUSCA_UNIDADE_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioUnidadeSucesso = (locaisControlados) => {
  return {
    type: INICIO_UNIDADE_SUCESSO,
    payload: { locaisControlados }
  }
};

export const salvaUnidadeSucesso = (mensagem) => {
  return {
    type: SALVA_UNIDADE_SUCESSO,
    payload: mensagem
  }
};

export const removeUnidadeSucesso = (mensagem) => {
  return {
    type: REMOVE_UNIDADE_SUCESSO,
    payload: mensagem
  }
};

export const buscaUnidadeEdicaoSucesso = (unidade) => {
  return {
    type: BUSCA_UNIDADE_EDICAO_SUCESSO,
    payload: unidade
  };
};

export const listaUnidadeSucesso = (unidade) => {
  return {
    type: LISTA_UNIDADE_SUCESSO,
    payload: { unidade }
  };
};

export const limparFiltroUnidade = () => {
  return {
    type: LIMPAR_UNIDADE,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/


export const novoEscaninho = () => {
  return {
    type: NOVO_ESCANINHO
  };
};

export const atualizaEscaninho = (e) => {
  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_ESCANINHO,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};    

export const removeEscaninho = (indice) => {
  return {
    type: REMOVE_ESCANINHO,
    payload: indice
  };
};  

export const novaGaragem = () => {
  return {
    type: NOVA_GARAGEM
  };
};

export const atualizaGaragem = (e) => {
  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_GARAGEM,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};    

export const removeGaragem = (indice) => {
  return {
    type: REMOVE_GARAGEM,
    payload: indice
  };
};  

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

function RetornosArrayValidacoesCamposObrigatorios(unidade) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('descricaoGrupo', unidade.descricaoGrupo));
  validacoes.push(ValidaCampoObrigatorio('descricaoUnidade', unidade.descricaoUnidade));
  validacoes.push(ValidaCampoObrigatorioCombo('situacaoUnidade', unidade.situacaoUnidade));
  validacoes.push(ValidaCampoObrigatorio('qtdeConvite', unidade.qtdeConvite));
  

  return validacoes;
}