import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import { Redirect, NavLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import SearchBox from 'components/SearchBoxPortaria/index';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import { setDrawerType } from 'actions/index';
import { AiFillAppstore } from "@react-icons/all-files/ai/AiFillAppstore";
import Drawer from '@material-ui/core/Drawer';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import { HiArrowCircleRight } from "@react-icons/all-files/hi/HiArrowCircleRight";
import { exibirPontosParaSelecao, confirmarSelecaoDePontos, ativaDesativaNotificacoes, exibeTelaSelecaoModo } from 'actions/Portaria';
import { atualizaModoPortaria, onChangeValueFiltroUnidadeGaragem, decidirDuploCheck, desocuparVagaGaragem, analisarDetalhesDC, OcultaMensagemPortaria, setarIdInterval, buscaSolicitacao, exibeLotacaoGaragem, aplicaAlteracoesGaragens } from 'actions/PortariaInicio';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import * as Unicons from "@iconscout/react-unicons";
import ConteudoDrawer from './conteudoDrawerDC/index';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import { Scrollbars } from 'react-custom-scrollbars';
import { SpinnerDotted } from 'spinners-react';

/*#################### Classes CSS do componente de mensagens ####################*/

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const styles = theme => ({

  toast: {
    '& .Toastify__toast--success': {
      backgroundColor: '#51a351'
    },
    '& .Toastify__progress-bar': {
      backgroundColor: '#1d1d1d54'
    },
  },

});

const NotificationItem = ({ notification }) => {
  const { icon, image, title, time } = notification;
  return (
    <li className="media">
      <Avatar
        alt={image}
        src={image}
        className=" mr-2"
      />
      <div className="media-body align-self-center">
        <p className="sub-heading mb-0">{title}</p>
        <Button size="small" className="jr-btn jr-btn-xs mb-0"><i
          className={`zmdi ${icon} zmdi-hc-fw`} /></Button> <span className="meta-date"><small>{time}</small></span>
      </div>
    </li>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#05B59D',
    '&$checked': {
      color: '#05B59D',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function ObtemImagemUsuario() {
  return localStorage.getItem('user_img');
}

function ObtemImagemCliente() {
  return localStorage.getItem('imgClienteFixado');
}

function ObtemNomeCliente() {
  return localStorage.getItem('nomeClienteFixado');
}

class Header extends React.Component {

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };

  setCollapsedDrawer = () => {
    this.props.setDrawerType(COLLAPSED_DRAWER);
  };

  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };
  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link onClick={this.setCollapsedDrawer.bind(this)} className="jr-list-link" to="/app/portaria">
            <i className="zmdi zmdi-calendar zmdi-hc-fw" />
            <span className="jr-list-text">{/* <IntlMessages id="sidebar.calendar.basic"/> */} Portaria</span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw" />
            <span className="jr-list-text text-center">{/* <IntlMessages id="sidebar.appModule.toDo"/> */} Auxílio Registro</span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw" />
            <span className="jr-list-text text-center">{/* <IntlMessages id="sidebar.appModule.mail"/> */} Outro Módulo</span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw" />
            <span className="jr-list-text text-center">{/* <IntlMessages id="sidebar.appModule.mail"/> */} Outro Módulo</span>
          </Link>
        </li>

      </ul>)
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      impedirReplicacaoMensagem: false,
      exibirOcupacaoGaragens: false,
      exibirOcupantes: false,
      idUnidadeExpandir: 0
    }
  }

  componentWillMount() {

    var idIntervalGerado = setInterval(() => {

      if (this.props.timerAtivado) {
        this.props.buscaSolicitacao(this.props.idPosicaoPortaria);
      }
      else {
        clearInterval();
      }

    }, 1000);

    this.props.setarIdInterval(idIntervalGerado);
  }


  componentDidUpdate() {

    if (this.props.timerAtivado) {
      this.props.buscaSolicitacao(this.props.idPosicaoPortaria);
    }

    if (this.props.exibeMensagemSucessoPortaria || this.props.exibeMensagemErroPortaria) {

      clearTimeout(() => {
        this.props.OcultaMensagemPortaria();
      });

      setTimeout(() => {

        this.props.OcultaMensagemPortaria();
      }, 100);

      if (this.state.impedirReplicacaoMensagem !== true) {
        this.setState({
          impedirReplicacaoMensagem: true
        });
      }
    }
  }

  handleexibeOcultaGaragens() {

    var exibir = !this.state.exibirOcupacaoGaragens;

    if (exibir) {
      this.props.exibeLotacaoGaragem();

      this.setState({
        idUnidadeExpandir: 0,
      });
    }

    this.setState({
      exibirOcupacaoGaragens: !this.state.exibirOcupacaoGaragens,
    });
  }

  handleexibeOcultaOcupantes(idUnidade) {
    this.setState({
      exibirOcupantes: !this.state.exibirOcupantes,
      idUnidadeExpandir: idUnidade
    });
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  render() {
    const { classes } = this.props;
    var imagemTratada = ObtemImagemUsuario();
    var imagemTratadaCliente = ObtemImagemCliente();
    var nomeCliente = ObtemNomeCliente();
    imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;
    imagemTratadaCliente = imagemTratadaCliente !== null && imagemTratadaCliente !== "" ? imagemTratadaCliente.substr(imagemTratadaCliente.indexOf(",") + 1) : null;
    var listaDeAmbientesPortariaCombo = [];
    var listaDeAmbientesPortaria = [];


    const { desocuparVagaGaragem, lotacoesGaragemDesocupar, navigationStyle, horizontalNavPosition, exibeNotificacoes, atendimentosFila, configuracao, emDecisaoDuploCheck, exibeAnaliseDuploCheck, selecionarModuloPortaria, ehGaragem, listaLotacaoGaragens, listaLotacaoGaragensFiltrada, carregandoGaragens } = this.props;

    var config = configuracao;
    var exibePontosParaSelecao = this.props.exibePontosParaSelecao;
    var numeroAtendimentos = atendimentosFila !== undefined ? atendimentosFila.length : 0;
    var contador = 0;

    var imagemTratadaPrimeiro = atendimentosFila.length > 0 ? atendimentosFila[0].atendimentoDuploCheck.pessoaFisica.caminhoFoto : '';
    imagemTratadaPrimeiro = imagemTratadaPrimeiro !== null && imagemTratadaPrimeiro !== "" ? imagemTratadaPrimeiro.substr(imagemTratadaPrimeiro.indexOf(",") + 1) : null;

    if (configuracao.uzerAcessoAmbienteCliente) {
      listaDeAmbientesPortariaCombo = this.props.listaDeAmbientesPortariaCombo;
      listaDeAmbientesPortaria = this.props.listaDeAmbientesPortaria;
    }
    else {
      for (var indice in this.props.listaDeAmbientesPortaria) {
        var ambientePortaria = this.props.listaDeAmbientesPortaria[indice];

        if (configuracao.listaPontosAcessoPortaria.filter(x => x.pontoAcesso.idAmbiente === ambientePortaria.id).length > 0) {
          listaDeAmbientesPortariaCombo.push(this.props.listaDeAmbientesPortariaCombo.filter(x => x.id === ambientePortaria.id)[0]);
          listaDeAmbientesPortaria.push(ambientePortaria);
        }
      }
    }

    var nomeAmbientePortariaExibir = listaDeAmbientesPortariaCombo.filter(x => x.id === this.props.idAmbientePortariaSelecionado)[0];
    var ambienteSelecionado = listaDeAmbientesPortaria !== null && listaDeAmbientesPortaria !== undefined && listaDeAmbientesPortaria.length > 0 ? listaDeAmbientesPortaria.filter(x => x.id === this.props.idAmbientePortariaSelecionado)[0] : {};
    var controlaGaragem = listaDeAmbientesPortaria !== null && listaDeAmbientesPortaria !== undefined && listaDeAmbientesPortaria.length > 0 && ambienteSelecionado !== undefined && ambienteSelecionado !== {} && ambienteSelecionado.controlaGaragem;

    var teste = controlaGaragem;

    return (
      <Fragment>

        <Drawer anchor="top" open={exibeAnaliseDuploCheck} /* onClose={() => { this.props.ativaTestes(); }} */ >
          <ConteudoDrawer {...this.props} />
        </Drawer>

        <Drawer anchor="right" open={this.state.exibirOcupacaoGaragens} onClose={() => this.handleexibeOcultaGaragens()}>
          <div style={{ width: '700px' }}>
            <div style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, height: 72, fontSize: 20, fontFamily: 'Montserrat, sans-serif', fontWeight: 500, letterSpacing: '0.15px' }} className="shadow border-2 mb-2" >
              Ocupação de Garagens em tempo real
            </div>

            <div style={{ paddingLeft: 22, paddingTop: 20, backgroundColor: '#FAFAFA' }}>


              <div style={{ clear: 'both', backgroundColor: '#FAFAFA', paddingRight: 20 }}>
                <Card className={`shadow border-0`} style={{ border: '1px solid #F4F4F7', borderRadius: '8px' }}>

                  {
                    carregandoGaragens ? (
                      <CardBody style={{ paddingBottom: 5, textAlign: 'center', height: 500 }}>

                        <div style={{ marginTop: 130, marginBottom: 50 }}>
                          <SpinnerDotted size={90} thickness={180} speed={90} color="rgba(5, 181, 157, 1)" />
                          <h1 style={{ fontFamily: 'Montserrat, sans-serif', color: 'gray', marginTop: 20 }}>Carregando informações de Garagens...</h1>
                        </div>

                      </CardBody>
                    ) : (
                      <Scrollbars
                        style={{ height: 667 }}>
                        <CardBody style={{ paddingBottom: 5 }}>


                          <TextField
                            variant="filled"
                            placeholder="Pesquise o nome da Unidade"
                            value={this.props.textoFiltroClienteAlternar}
                            onChange={this.props.onChangeValueFiltroUnidadeGaragem}
                            margin="normal"
                            fullWidth={true}
                            style={{ lineHeight: '24px', marginTop: '10px', marginBottom: '10px', backgroundColor: '#00000000' }}
                            InputProps={{
                              classes: {
                                input: classes.input
                              },
                              endAdornment: (
                                <InputAdornment>
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                          />

                          {
                            listaLotacaoGaragensFiltrada !== undefined && listaLotacaoGaragensFiltrada.map(lotgaragem => {

                              return (
                                <Fragment>
                                  <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                                    <div><span style={{ fontWeight: 600 }}>Unidade:</span> {lotgaragem.descricaoUnidade}</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div><span style={{ fontStyle: 'italic' }}>{lotgaragem.qtdeGaragem} vaga(s) de garagem </span></div>
                                      <div onClick={() => this.handleexibeOcultaOcupantes(lotgaragem.idUnidade)} style={{ paddingLeft: 20, color: '#05B59D', fontSize: '14px', cursor: 'pointer', textDecorationLine: 'underline' }}>
                                        {lotgaragem.ocupantes.length} Ocupada(s)
                                      </div>
                                    </div>

                                    {
                                      this.state.exibirOcupantes && this.state.idUnidadeExpandir === lotgaragem.idUnidade && (
                                        <div style={{ marginTop: 10, backgroundColor: '#effcfa', paddingTop: 13, paddingBottom: 9, paddingLeft: 8, paddingRight: 8, border: 'solid 1px #156a15' }}>

                                          {
                                            lotgaragem.ocupantes !== undefined && lotgaragem.ocupantes.length > 0 && lotgaragem.ocupantes.map(ocupante => {

                                              return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                  <div style={{ fontSize: 12 }}>{ocupante.nomePessoa} (entrou em {ocupante.dataHoraEntradaStr})</div>
{/*                                                   <div style={{ display: 'flex' }}>
                                                    <div>
                                                      <Unicons.UilCornerUpLeftAlt size={20} style={{ color: 'red', marginRight: 2 }} />
                                                    </div>
                                                    <div style={{ fontSize: 12, cursor: 'pointer' }}>Desocupar</div>
                                                  </div> */}

                                                </div>
                                              )
                                            })
                                          }

                                          {
                                            lotgaragem.ocupantes !== undefined && lotgaragem.ocupantes.length === 0 && (
                                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex' }}>
                                                  <div>
                                                    <Unicons.UilExclamationTriangle size={20} style={{ color: 'orange', marginRight: 2 }} />
                                                  </div>
                                                  <div style={{ fontSize: 12, cursor: 'pointer', paddingTop: 2 }}>Nenhuma garagem ocupada!</div>
                                                </div>

                                              </div>
                                            )
                                          }

                                        </div>
                                      )
                                    }

                                  </div>

                                  <Divider style={{ marginLeft: -25, marginRight: -25 }} />

                                </Fragment>
                              )

                            })
                          }

                        </CardBody>
                      </Scrollbars>
                    )
                  }



                </Card>
              </div>

{/*               {
                lotacoesGaragemDesocupar !== undefined && lotacoesGaragemDesocupar.length > 0 ? (
                  <Button onClick={() => this.props.aplicaAlteracoesGaragens(lotacoesGaragemDesocupar)} style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100, marginBottom: 10 }} variant="contained" >
                    <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Aplicar alterações</span>
                  </Button>
                ) :
                  (<Button disabled style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', padding: '14px 36px', borderRadius: 100, marginBottom: 10 }} variant="contained" >
                    <span style={{ fontStyle: 'normal', color: '#gray', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Aplicar alterações</span>
                  </Button>)
              } */}



            </div>



          </div>
        </Drawer>

        <AppBar
          className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
          <Toolbar className="app-toolbar" disableGutters={false}>

            {/* <h4 className="mb-0 mr-auto text-white">Uzer</h4> */}

            {navigationStyle === HORIZONTAL_NAVIGATION ?
              <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                <span className="jr-menu-icon">
                  <span className="menu-icon" />
                </span>
              </div>
              :
              <Fragment>
                <IconButton style={{ padding: -1 }} className="icon-btn size-50">
                  <Avatar
                    alt='...'
                    src={require("assets/images/uzerpass.png")}
                    className="size-50"
                  />
                </IconButton>
                <IconButton style={{ backgroundColor: 'transparent' }} className="icon-btn size-50">
                  <Avatar style={{ backgroundColor: 'transparent' }}
                    alt='...'
                    src={`data:image/jpeg;base64,${imagemTratadaCliente}`}
                    className="size-50"
                  />

                  {/*                   <Avatar
                    alt='...'
                    src={require("assets/images/goias.png")}
                    className="size-50"
                  /> */}
                </IconButton>
                <span style={{ paddingLeft: 10 }} className="jr-list-text text-left">{nomeCliente}</span>
                {
                  !this.props.selecionarModoTrabalho && !this.props.selecionarModuloPortaria && (
                    <div onClick={() => { this.props.atualizaModoPortaria(this.props.timerAtivado ? 'Visualizacao' : 'Trabalho', this.props.idAmbientePortariaSelecionado, this.props.idPosicaoPortaria, this.props.pontosSelecionadosPortaria); }} style={{ marginLeft: 10, cursor: 'pointer', padding: -1, backgroundColor: '#383838', height: '100%', display: 'flex', alignItems: 'center', width: 70, justifyContent: 'center' }} className="icon-btn ripple-effect">
                      {this.props.timerAtivado ?
                        <Unicons.UilPlayCircle size={40} style={{ color: '#05B59D' }} /> :
                        <Unicons.UilPauseCircle size={40} style={{ color: '#ffffff' }} />
                      }
                    </div>
                  )
                }

                {
                  !this.props.selecionarModuloPortaria ?
                    <div onClick={() => { this.props.exibeTelaSelecaoModo(); }} style={{ color: '#fff', marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer' }}>
                      <NavLink style={{ textDecoration: 'none' }} className="prepend-icon" to={{ pathname: '/app/inicio' }}>
                        <span style={{ color: '#fff', fontWeight: 400, fontSize: 16, fontFamily: 'Montserrat, sans-serif' }}>
                          {this.props.idAmbientePortariaSelecionado !== 0 ?
                            (<div>{nomeAmbientePortariaExibir.title} <FaAngleDown size={20} style={{ color: '#fff', marginLeft: 6, marginRight: 6 }} /></div>) :
                            (<div>Selecione o local de trabalho <FaAngleDown size={20} style={{ color: '#fff', marginLeft: 6, marginRight: 6 }} /></div>)}



                        </span>
                      </NavLink>
                    </div> :
                    <div style={{ color: '#fff', marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer' }}>
                      <span style={{ color: '#fff', fontWeight: 400, fontSize: 16, fontFamily: 'Montserrat, sans-serif' }}>

                      </span>
                    </div>
                }


              </Fragment>
            }

            {
              controlaGaragem && (
                <li className="list-inline-item app-tour">


                  <div style={{ cursor: 'pointer' }} onClick={() => this.handleexibeOcultaGaragens()}>
                    <span className="app-notification">
                      <IconButton className="icon-btn">
                        <i style={{ color: 'silver' }} className="zmdi zmdi-car" />
                      </IconButton>
                    </span>
                  </div>

                </li>
              )
            }


            <ul className="header-notifications list-inline">
              {!this.props.selecionarModuloPortaria &&
                <li className="list-inline-item">

                  <div style={{ cursor: 'pointer' }} onClick={() => { this.props.selecionaModulosPortaria(configuracao, 'Visualizacao', this.props.idAmbientePortariaSelecionado, this.props.idPosicaoPortaria, this.props.pontosSelecionadosPortaria, this.props.idIntervalGerado); }}>
                    <span className="app-notification">
                      <AiFillAppstore size={20} style={{ color: 'silver', marginLeft: 6, marginRight: 6 }} />
                    </span>
                  </div>
                </li>
              }

              <li className="list-inline-item app-tour">

                {
                  this.props.timerAtivado ?
                    (
                      <Dropdown
                        className="quick-menu"
                        isOpen={exibeNotificacoes && !emDecisaoDuploCheck}
                        toggle={this.onAppNotificationSelect.bind(this)}>

                        <DropdownToggle
                          className="d-inline-block"
                          tag="span"
                          data-toggle="dropdown">
                          <IconButton className="icon-btn">
                            <i style={{ color: 'silver' }} className="zmdi zmdi-notifications icon-alert animated infinite wobble" />
                          </IconButton>
                        </DropdownToggle>

                        <DropdownMenu style={{ backgroundColor: 'transparent', padding: 2, borderRadius: '16px 16px 16px 16px', width: 336, height: '100%' }}>

                          {

                            numeroAtendimentos > 0 && (
                              <Card style={{ marginBottom: 5, border: '1px solid #6FCF97', borderRadius: '16px 16px 16px 16px' }}>
                                <CardHeader style={{ height: 40, borderRadius: '16px 16px 0px 0px', color: 'rgba(255, 255, 255, 1)', backgroundColor: 'rgba(111, 207, 151, 1)' }}
                                  title={
                                    <Typography style={{ fontSize: 16, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                      <HiArrowCircleRight style={{ float: 'left', width: 24, height: 24, color: 'rgba(0, 0, 0, 0.38)', fontSize: 14 }} />
                                      <div style={{ float: 'left', paddingLeft: 5 }}>Solicitação de entrada</div>
                                      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.38)', width: 56, float: 'right', paddingTop: 3.5, fontSize: 12, fontWeight: 400, height: 24, textAlign: 'center', borderRadius: 12, color: 'rgba(255, 255, 255, 0.74)' }}>00:59</div>
                                    </Typography>
                                  } />
                                <CardBody style={{ borderRadius: '0px 0px 16px 16px', backgroundColor: '#fff', padding: 16 }}>
                                  <Grid container spacing={1}>
                                    <Grid item>

                                      <Avatar variant="rounded" style={{ width: 88, height: 88 }} src={`data:image/jpeg;base64,${imagemTratadaPrimeiro}`} />


                                    </Grid>
                                    <Grid item xs={12} sm container>
                                      <Grid item container spacing={1}>
                                        <Grid item>
                                          <Typography style={{ textAlign: 'left', fontWeight: 400, fontSize: 16, fontFamily: 'Roboto', color: 'rgba(0, 0, 0, 0.87)' }}>
                                            {atendimentosFila[0].atendimentoDuploCheck.pessoaFisica.nomePessoa}
                                          </Typography>
                                          {/*                                           <Typography style={{ textAlign: 'left', fontWeight: 400, fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}>
                                            Rec. facial
                                          </Typography> */}
                                          <Typography style={{ textAlign: 'left', fontWeight: 400, fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}>
                                            Ponto Acesso: {atendimentosFila[0].atendimentoDuploCheck.pontoAcesso.descricaoPontoAcesso}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid style={{ marginTop: 15 }} container spacing={1}>
                                    <Grid item>
                                      <Button style={{ width: 144, height: 45, padding: '14px 36px', borderRadius: 100 }} variant="outlined" onClick={() => { this.props.analisarDetalhesDC(atendimentosFila[0].atendimentoDuploCheck.id, imagemTratadaPrimeiro); }}>
                                        <span style={{ fontStyle: 'normal', color: '#828282', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>ANALISAR</span>
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Button style={{ width: 144, height: 45, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" onClick={() => { this.props.decidirDuploCheck(atendimentosFila[0].atendimentoDuploCheck.id, true); }} >
                                        <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>PERMITIR</span>
                                      </Button>
                                    </Grid>
                                  </Grid>

                                </CardBody>
                              </Card>
                            )}

                          {
                            numeroAtendimentos > 1 && (
                              <Card style={{ border: '1px solid #6FCF97', marginTop: 10, padding: 8, borderRadius: '16px 16px 16px 16px' }}>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <AvatarGroup variant="rounded">

                                      {

                                        atendimentosFila.map(atendto => {

                                          var imagemTratada = atendto.atendimentoDuploCheck.pessoaFisica.caminhoFoto;
                                          imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

                                          contador = contador + 1;

                                          return (contador > 1 && contador < 6) && (
                                            <Avatar style={{ width: 32, height: 32 }} variant="rounded" alt="Remy Sharp" src={`data:image/jpeg;base64,${imagemTratada}`} />
                                          )


                                        })
                                      }

                                    </AvatarGroup>
                                  </Grid>

                                  {((numeroAtendimentos - 5) > 0) && (<Grid item>
                                    <Typography style={{ paddingTop: 3, textAlign: 'left', fontWeight: 400, fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}>
                                      {`E mais ${numeroAtendimentos - 5} aguardando`}
                                    </Typography>
                                  </Grid>)}

                                </Grid>
                              </Card>
                            )
                          }

                          {
                            numeroAtendimentos === 0 && (
                              <Grid item>
                                <Typography style={{ paddingTop: 3, textAlign: 'left', fontWeight: 400, fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}>
                                  Nenhum atendimento pendente
                                </Typography>
                              </Grid>
                            )
                          }


                        </DropdownMenu>


                      </Dropdown>
                    ) :
                    (
                      <div style={{ cursor: 'pointer' }}>
                        <span className="app-notification">
                          <IconButton className="icon-btn">
                            <i style={{ color: 'silver' }} className="zmdi zmdi-notifications-off" />
                          </IconButton>
                        </span>
                      </div>
                    )
                }


              </li>
              <li className="d-inline-block d-lg-none list-inline-item">
                <Dropdown
                  className="quick-menu nav-searchbox"
                  isOpen={this.state.searchBox}
                  toggle={this.onSearchBoxSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn">
                      <i className="zmdi zmdi-search zmdi-hc-fw" />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right className="p-0">
                    <SearchBox styleName="search-dropdown" placeholder=""
                      onChange={this.updateSearchText.bind(this)}
                      value={this.state.searchText} />
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li style={{ marginLeft: 15 }} className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt='...'
                        src={`data:image/jpeg;base64,${imagemTratada}`}
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup {...this.props} />
                  </DropdownMenu>
                </Dropdown>
              </li>{/* } */}
            </ul>

            <ToastContainer className={classes.toast} autoClose={2500} />

          </Toolbar>
        </AppBar>
        {this.props.exibeMensagemSucessoPortaria && (<Redirect to={'/app/perfil'} />)}
      </Fragment >
    );
  }

}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = ({ settings, portaria, portariaInicio, inicio }) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition, configuracao, loginRecente } = settings;
  const { exibePontosParaSelecao } = portaria;
  const { imgClienteFixado } = inicio;
  const { listaDeAmbientesPortariaCombo, listaDeAmbientesPortaria, exibeNotificacoes, lotacoesGaragemDesocupar,
    emDecisaoDuploCheck, exibeAnaliseDuploCheck, atendimentosFila, timerAtivado,
    idAmbientePortariaSelecionado, selecionarModoTrabalho, idPosicaoPortaria,
    pontosSelecionadosPortaria, idAtendtoDCAnalise, imagemAnaliseDC, carregandoGaragens,
    mensagemAlertaPortaria, exibeMensagemSucessoPortaria, exibeMensagemErroPortaria, idIntervalGerado, ehGaragem, listaLotacaoGaragens, listaLotacaoGaragensFiltrada } = portariaInicio;
  return {
    drawerType, locale, navigationStyle, horizontalNavPosition, exibePontosParaSelecao, lotacoesGaragemDesocupar,
    exibeAnaliseDuploCheck, emDecisaoDuploCheck, atendimentosFila, listaDeAmbientesPortariaCombo,
    listaDeAmbientesPortaria, exibeNotificacoes, configuracao, timerAtivado, idAmbientePortariaSelecionado,
    selecionarModoTrabalho, idPosicaoPortaria, pontosSelecionadosPortaria, idAtendtoDCAnalise, carregandoGaragens,
    imgClienteFixado, imagemAnaliseDC, mensagemAlertaPortaria, exibeMensagemSucessoPortaria, exibeMensagemErroPortaria,
    idIntervalGerado, loginRecente, ehGaragem, listaLotacaoGaragens, listaLotacaoGaragensFiltrada
  }
};

export default withRouter(connect(mapStateToProps,
  {
    toggleCollapsedNav, buscaSolicitacao, setarIdInterval, atualizaModoPortaria, exibeTelaSelecaoModo, desocuparVagaGaragem,
    ativaDesativaNotificacoes, switchLanguage, setDrawerType, exibirPontosParaSelecao, confirmarSelecaoDePontos,
    decidirDuploCheck, analisarDetalhesDC, OcultaMensagemPortaria, exibeLotacaoGaragem, onChangeValueFiltroUnidadeGaragem, aplicaAlteracoesGaragens
  })(withStyles(styles)(Header)));