import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { formascomunicacaoEquipamentoCliente, tiposCheckin, tiposPontoAcesso } from '../app/constantes/Listas';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_PONTO_ACESSO,
    NOVO_PONTO_ACESSO,
    BUSCA_PONTO_ACESSO_EDICAO,
    CANCELA_EDICAO,
    INICIO_PONTO_ACESSO_SUCESSO,
    SALVA_PONTO_ACESSO_SUCESSO,
    REMOVE_PONTO_ACESSO_SUCESSO,
    LISTA_PONTO_ACESSO,
    LISTA_PONTO_ACESSO_SUCESSO,
    LIMPAR_PONTO_ACESSO,
    BUSCA_PONTO_ACESSO_EDICAO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO,
    LISTA_LOCAIS_SUCESSO_PONTO_ACESSO,
    LISTA_AMBIENTES_POR_LOCAL,
    LISTA_AMBIENTES_SUCESSO,    
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE    
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo PontoAcesso', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },        
      { titulo: 'Cliente', propriedadeApi: 'nomeRazaoSocialcliente', alinhamento: 'left' },  
      { titulo: 'Local Controlado',  propriedadeApi: 'descricaoLocalControlado', alinhamento: 'left' },  
      { titulo: 'Ambiente', propriedadeApi: 'descricaoAmbiente', alinhamento: 'left',  }, 
      { titulo: 'Ponto de Acesso', propriedadeApi: 'descricaoPontoAcesso', alinhamento: 'left' },  
      { titulo: 'Equipamento', propriedadeApi: 'equipamentoUtilizado', alinhamento: 'left' },
    ],    

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],

    // Campos do Formulario
    id: 0,
    idCliente: 0,
    idLocalControlado: 0,
    idEquipamento: 0,
    idEquipamentoCliente: 0,
    idAmbiente: 0,
    formaComunicacao: '',
    descricaoPontoAcesso: '',
    ipEquipamento: '',
    ativo: true,
    caminhoComunicServer: '',
    tipoCheckin: '',
    tipoPontoAcesso: '', 
    ehUzerPassPremium: false,
    coletaDigital: false,  
    nomeClienteFixado: null,
    idClienteFixado: 0,
    
    // Combos
    listaDeClientes: [],
    listaDeLocaisControlados: [],
    listaDeAmbientes: [],
    listaDeEquipamentos: [],
    listaDeFormasComunicacao: [],
    listaDeTiposCheckin: [],
    listaDeTiposDePontoAcesso: [],

     // Filtros
     nomeClienteFiltro: '',
     nomeLocalControladoFiltro: '',
     nomeAmbienteFiltro: '',
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_PONTO_ACESSO: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado')          
        }
      }   
      case NOVO_PONTO_ACESSO: {
        return {
          ...state,
          edicao: true,
          id: 0,
          idCliente: state.idClienteFixado !== 0 ? parseInt(state.idClienteFixado) : 0,
          idEquipamento: 0,
          idEquipamentoCliente: 0,
          idLocalControlado: 0,
          idAmbiente: 0,
          descricaoPontoAcesso: '',
          ipEquipamento: '',
          ativo: true,
          caminhoComunicServer: '',
          formaComunicacao: '',
          tipoPontoAcesso: '',
          tipoCheckin: '',
          ehUzerPassPremium: false,
          coletaDigital: false,
          notificaResponsavel: false,        
        }
      }       
      case BUSCA_PONTO_ACESSO_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false
        }
      }
      case INICIO_PONTO_ACESSO_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.pontosAcesso,
          listaDeLocaisControlados: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado") : [],
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
          listaDeEquipamentos: preparaListaParaCombo(retorno.equipamentos, "id", "nomeExibicao"),
          listaDeFormasComunicacao: preparaListaParaCombo(formascomunicacaoEquipamentoCliente, "id", "title"),
          listaDeTiposCheckin: preparaListaParaCombo(tiposCheckin, "id", "title"),
          listaDeTiposDePontoAcesso: preparaListaParaCombo(tiposPontoAcesso, "id", "title"),
        }
      }                     
      case SALVA_PONTO_ACESSO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_PONTO_ACESSO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_PONTO_ACESSO: {
        const retorno = action.payload;

        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_PONTO_ACESSO_SUCESSO: {
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listGrid: retorno.pontosAcesso,
        }
      }
      case LIMPAR_PONTO_ACESSO: {

        var retorno = action.payload;

        return {
          ...state,
          nomeClienteFiltro: '',
          nomeLocalControladoFiltro: '',
          nomeAmbienteFiltro: '',
        }
      }
      case BUSCA_PONTO_ACESSO_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.pontoAcesso.id,
          idEquipamentoCliente: retorno.pontoAcesso.equipamentoCliente.id,
          idCliente: retorno.pontoAcesso.equipamentoCliente.idCliente,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"), 
          idLocalControlado: retorno.pontoAcesso.ambiente.idLocalControlado,
          listaDeAmbientes: preparaListaParaCombo(retorno.ambientes, "id", "descricaoAmbiente"), 
          idAmbiente: retorno.pontoAcesso.idAmbiente,
          descricaoPontoAcesso: retorno.pontoAcesso.descricaoPontoAcesso,
          idEquipamento: retorno.pontoAcesso.equipamentoCliente.idEquipamento,
          caminhoComunicServer: retorno.pontoAcesso.equipamentoCliente.caminhoComunicServer,
          formaComunicacao: retorno.pontoAcesso.equipamentoCliente.formaComunicacao,
          coletaDigital: retorno.pontoAcesso.coletaDigital,
          ipEquipamento: retorno.pontoAcesso.ipEquipamento,
          tipoCheckin: retorno.pontoAcesso.tipoCheckin,
          tipoPontoAcesso: retorno.pontoAcesso.tipoPontoAcesso,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),             
        }
      }
      case LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO: {

        const retorno = action.payload;

        return {
          ...state,
          idCliente: retorno.idCliente,
          loader: true,          
        }
      }
      case LISTA_AMBIENTES_POR_LOCAL: {

        const retorno = action.payload;

        return {
          ...state,
          idLocalControlado: retorno.idLocal,
          loader: true,          
        }
      }      
      case LISTA_LOCAIS_SUCESSO_PONTO_ACESSO: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          idLocalControlado: null,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"), 
        }
      }      
      case LISTA_AMBIENTES_SUCESSO: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listaDeAmbientes: preparaListaParaCombo(retorno.ambientes, "id", "descricaoAmbiente"), 
        }
      }        
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  