import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { LuxFwTextBox, LuxFwPasswordTextBox, LuxFwComboBox, LuxFwMaskTextBox } from 'luxFw/palettes/Standart';
import { Scrollbars } from 'react-custom-scrollbars';


export default class Observacao extends Component {


    render() {

        const { classes } = this.props;

        return (
            <Fragment>

                <TableContainer elevation={0} className={classes.padding} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>

                            <Scrollbars
                                style={{ height: 300 }}>
                                <TableRow>
                                    <TableCell style={{ paddingLeft: 0, paddingTop: 0 }} colSpan="5">
                                        <div style={{ float: 'left', width: 600 }}>
                                            <LuxFwTextBox name="nomePontoAcesso" label="Observações" valor="Esta pe" tamanho="12" onChange={this.props.onChangeValue} />
                                        </div>

                                        <Button style={{ float: 'left', marginTop: 13, marginLeft: 8, marginRight: 8, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" >
                                            <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>INSERIR</span>
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={1}>
                                    <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                        <div style={{ textAlign: 'center', backgroundColor: '#F2F2F2', borderRadius: 8, width: 48 }}>
                                            <div style={{ fontWeight: 500, color: '#000000DE', fontSize: 20, fontFamily: 'Montserrat, sans-serif' }}>08</div>
                                            <div style={{ fontWeight: 400, color: '#000000DE', fontSize: 12 }}>jan</div>
                                            <div style={{ fontWeight: 400, color: '#000000DE', fontSize: 12 }}>2021</div>
                                        </div>
                                    </TableCell>
                                    <TableCell colspan={2} align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                        <div>
                                            <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#00000099' }}>Por Henrique freitas</div>
                                            <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Ficar de olho. Já se envolveu em brigas com os moradores e destratou os colaboradores.</div>
                                        </div>
                                    </TableCell>

                                </TableRow>

                                <TableRow key={2}>
                                    <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                        <div style={{ textAlign: 'center', backgroundColor: '#F2F2F2', borderRadius: 8, width: 48 }}>
                                            <div style={{ fontWeight: 500, color: '#000000DE', fontSize: 20, fontFamily: 'Montserrat, sans-serif' }}>08</div>
                                            <div style={{ fontWeight: 400, color: '#000000DE', fontSize: 12 }}>jan</div>
                                            <div style={{ fontWeight: 400, color: '#000000DE', fontSize: 12 }}>2021</div>
                                        </div>
                                    </TableCell>
                                    <TableCell colspan={2} align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                        <div>
                                            <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#00000099' }}>Por Henrique freitas</div>
                                            <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Ficar de olho. Já se envolveu em brigas com os moradores e destratou os colaboradores.</div>
                                        </div>
                                    </TableCell>

                                </TableRow>

                                <TableRow key={3}>
                                    <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                        <div style={{ textAlign: 'center', backgroundColor: '#F2F2F2', borderRadius: 8, width: 48 }}>
                                            <div style={{ fontWeight: 500, color: '#000000DE', fontSize: 20, fontFamily: 'Montserrat, sans-serif' }}>08</div>
                                            <div style={{ fontWeight: 400, color: '#000000DE', fontSize: 12 }}>jan</div>
                                            <div style={{ fontWeight: 400, color: '#000000DE', fontSize: 12 }}>2021</div>
                                        </div>
                                    </TableCell>
                                    <TableCell colspan={2} align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                        <div>
                                            <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#00000099' }}>Por Henrique freitas</div>
                                            <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Ficar de olho. Já se envolveu em brigas com os moradores e destratou os colaboradores.</div>
                                        </div>
                                    </TableCell>

                                </TableRow>
                            </Scrollbars>
                        </TableBody>
                    </Table>
                </TableContainer>

            </Fragment>
        )
    }

}