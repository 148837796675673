import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_ACESSO_IDADE, INICIA_ACESSO_IDADE, BUSCA_ACESSO_IDADE_EDICAO, REMOVE_ACESSO_IDADE,
         LISTA_CONFIGURACAO_PONTO_IDADE, LISTA_LOCAIS_POR_CLIENTE_IDADE, LISTA_ACESSO_IDADE } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaAcessoIdadeSucesso,
         removeAcessoIdadeSucesso, 
         inicioAcessoIdadeSucesso, 
         iniciaAcessoIdade,
         listaLocaisSucesso,
         buscaAcessoIdadeEdicaoSucesso,
         listaConfiguracaoPontoIdadeSucesso,
         listaAcessoIdadeSucesso } from "actions/AcessoIdade";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getAcessosIdade = async () =>
    await requisicaoApi.get('/acessosIdade')
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);   
    
    const getPerfils = async (cliente) =>
    await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);  

  const getAcessoIdadeEdicao = async (id) =>
    await requisicaoApi.get('/acessosIdade/' + id)
    .then(resposta => resposta)
    .catch(error => error);

  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);    
    
  const getlistaConfiguracaoPontoIdade = async (idLocal, idadeMinima, idadeMaxima) => 
    await requisicaoApi.get('/acessosIdade/vinculoPontoAcesso/' + idLocal + '/' + idadeMinima + '/' + idadeMaxima)
    .then(resposta => resposta)
    .catch(error => error);

    const getListaAcessoIdade = async (idadeFiltro, ambienteFiltro, pontoFiltro) => 
    await requisicaoApi.get('/acessosIdade?Idade=' +  idadeFiltro + '&Ambiente=' + ambienteFiltro + '&PontoAcesso=' + pontoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  
    
/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaAcessoIdadeRequest = async (acessoIdade, pontosAssociados) => await requisicaoApi.post('/acessosIdade', 
  {
    "id": acessoIdade.id,
      "idCriterio": 0,
      "criterioAcesso": {
        "tipoCriterioAcesso": "IDADE",
        "ativo": true,
        "minutosPadraoRetorno": 0,
        "pontosAcessoVinculados": pontosAssociados,
        "id": 0
      },
      "idPerfil": acessoIdade.idPerfil,
      "idCliente": acessoIdade.idCliente,
      "idadeMinima": acessoIdade.idadeMinima,
      "idadeMaxima": acessoIdade.idadeMaxima,
      "idPessoa": acessoIdade.idPessoa,
      "idLocalControlado": acessoIdade.idLocalControlado,
      "perfils": acessoIdade.perfils
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaAcessoIdadeRequest = async (acessoIdade, pontosAssociados) => await requisicaoApi.put('/acessosIdade', 
  {
    "id": acessoIdade.id,
      "idCriterio": 0,
      "criterioAcesso": {
        "tipoCriterioAcesso": "IDADE",
        "ativo": true,
        "minutosPadraoRetorno": 0,
        "pontosAcessoVinculados": pontosAssociados,
        "id": 0
      },
      "idPerfil": acessoIdade.idPerfil,
      "idCliente": acessoIdade.idCliente,
      "idadeMinima": acessoIdade.idadeMinima,
      "idadeMaxima": acessoIdade.idadeMaxima,
      "idPessoa": acessoIdade.idPessoa,
      "idLocalControlado": acessoIdade.idLocalControlado,
      "perfils": acessoIdade.perfils
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteAcessoIdadeRequest = async (id) =>
  await requisicaoApi.delete('/acessosIdade?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

function* fnIniciaAcessoIdade() {
  try {
    const listagemGrid = yield call(getAcessosIdade);

    if(listagemGrid.data)
    {
      const clientes = yield call(getClientes);
  
      if(clientes.data) {
        const clienteFixo = localStorage.getItem('idClienteFixado');

        if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
        {
          const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
          const perfis = yield call(getPerfils, parseInt(clienteFixo));

          yield put(inicioAcessoIdadeSucesso(listagemGrid.data, clientes.data, locais.data, perfis.data));
        }
        else
        {
          yield put(inicioAcessoIdadeSucesso(listagemGrid.data, clientes.data, null, null));
        }
      }
    }
    else {
      if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
      {
        yield put(userSignOut());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao listar acessosIdade'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaLocaisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
    const retornoPerfil = yield call(getPerfils, cliente);

    if(retorno.data)
    {
      yield put(listaLocaisSucesso(retorno.data, retornoPerfil.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
}

function* fnlistaConfiguracaoPontoIdade({ payload }) {
  try {
    const idLocal = payload.idLocal;
    const idadeMinima = payload.idadeMinima;
    const idadeMaxima = payload.idadeMaxima;
    const retorno = yield call(getlistaConfiguracaoPontoIdade, idLocal, idadeMinima, idadeMaxima);

    if(retorno.data)
    {
      yield put(listaConfiguracaoPontoIdadeSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso do local: ' + idLocal));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

  function* fnBuscaAcessoIdadeEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getAcessoIdadeEdicao, id);
  
      if(retorno.data)
      {
        yield put(buscaAcessoIdadeEdicaoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do acessoIdade: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaAcessoIdade({ payload }) {
  try {

    const acessoIdade = payload.acessoIdade;
    const pontosAssociados = payload.listaPontosAssociados;

    var resultado = "";

      if(acessoIdade.id === 0) {
        const retorno = yield call(adicionaAcessoIdadeRequest, acessoIdade, pontosAssociados);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaAcessoIdadeRequest, acessoIdade, pontosAssociados);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaAcessoIdade());
        yield put(salvaAcessoIdadeSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveAcessoIdade({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteAcessoIdadeRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaAcessoIdade());
        yield put(removeAcessoIdadeSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaAcessoIdade({ payload }) {
  try {
    const idadeFiltro = payload.idade;
    const ambienteFiltro = payload.ambiente;
    const pontoFiltro = payload.ponto;
    const retorno = yield call(getListaAcessoIdade, idadeFiltro, ambienteFiltro, pontoFiltro);

    if(retorno.data)
    {
      yield put(listaAcessoIdadeSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de acesso idade'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 


/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesAcessoIdade() {
  yield takeEvery(INICIA_ACESSO_IDADE, fnIniciaAcessoIdade);
}

export function* salvaAcessoIdade() {
  yield takeEvery(SALVA_ACESSO_IDADE, fnSalvaAcessoIdade);
}

export function* buscaAcessoIdadeEdicao() {
  yield takeEvery(BUSCA_ACESSO_IDADE_EDICAO, fnBuscaAcessoIdadeEdicao);
}

export function* removeAcessoIdade() {
  yield takeEvery(REMOVE_ACESSO_IDADE, fnRemoveAcessoIdade);
}

export function* listaLocaisPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_IDADE, fnListaLocaisPorCliente)
}

export function* listaConfiguracaoPontoIdade() {
  yield takeEvery(LISTA_CONFIGURACAO_PONTO_IDADE, fnlistaConfiguracaoPontoIdade)
}

export function* listaAcessoIdade() {
  yield takeEvery(LISTA_ACESSO_IDADE, fnListaAcessoIdade)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesAcessoIdade),
             fork(salvaAcessoIdade), 
             fork(buscaAcessoIdadeEdicao),
             fork(removeAcessoIdade),
             fork(listaLocaisPorCliente),
             fork(listaConfiguracaoPontoIdade),
             fork(listaAcessoIdade)
            ]);
}