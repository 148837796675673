import React from 'react';
import {connect} from 'react-redux'
import {userSignOut, userSignOutPortaria} from 'actions/Auth';

function ObtemNomeUsuario()
{
  return localStorage.getItem('user');  
}

function ObtemImagemUsuario()
{
  return localStorage.getItem('user_img');  
}

class UserInfoPopup extends React.Component {
  render() {

    const nomeUsuario = ObtemNomeUsuario();
    var imagemTratada = ObtemImagemUsuario();

    imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

    return (
      <div>
        <div className="user-profile">
          <img className="user-avatar border-0 size-40 rounded-circle"
               src={`data:image/jpeg;base64,${imagemTratada}`}
               alt="User"/>
          <div className="user-detail ml-2">
    <h4 className="user-name mb-0">{nomeUsuario}</h4>
            <small>Administrador</small>
          </div>
        </div>
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
          Informações Pessoais
        </span>
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
          Preferências
        </span>
        <span className="jr-link dropdown-item text-muted" onClick={() => {
          console.log("Try to logoput");
          this.props.idPosicaoPortaria !== undefined &&
          this.props.idPosicaoPortaria > 0 &&
          this.props.idAmbientePortariaSelecionado !== undefined &&
          this.props.idAmbientePortariaSelecionado > 0 ?
          this.props.userSignOutPortaria('Visualizacao', this.props.idAmbientePortariaSelecionado, this.props.idPosicaoPortaria, this.props.pontosSelecionadosPortaria, this.props.idIntervalGerado) :
          this.props.userSignOut()
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          Sair do Sistema
        </span>
      </div>
    );
  }
}

export default connect(null, {userSignOut, userSignOutPortaria})(UserInfoPopup);


