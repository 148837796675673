//Referencias => https://www.youtube.com/watch?v=r-8isv_TnVA

export const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  export const cnpjMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  export const telefoneMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2') 
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  } 

  export const telefoneMask2 = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '+$1 $2') 
      .replace(/(\d{2})(\d)/, '($1) $2') 
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  }  

  export const cepMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2') 
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  export const dateMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1')
  }

  export const horarioMask = value => {

      
    value = value.replace(/[^\dh:]/, "");
    value = value.replace(/^[^0-2]/, "");
    value = value.replace(/^([2-9])[4-9]/, "$1");
    value = value.replace(/^\d[:h]/, "");
    value = value.replace(/^([01][0-9])[^:h]/, "$1");
    value = value.replace(/^(2[0-3])[^:h]/, "$1");      
    value = value.replace(/^(\d{2}[:h])[^0-5]/, "$1");
    value = value.replace(/^(\d{2}h)./, "$1");      
    value = value.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
    value = value.replace(/^(\d{2}:\d[0-9])./, "$1");
    
   
    return value;
  }
  
  export const ipMask = value => {
    return value
      .replace(/[^0-9.]/g, '')
  }