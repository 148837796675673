import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_LOCALIDADE, INICIA_LOCALIDADE, BUSCA_LOCALIDADE_EDICAO, 
         REMOVE_LOCALIDADE, LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE, LISTA_LOCALIDADE, LISTA_PONTO_ACESSO_POR_LOCAL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaLocalidadeSucesso,
         removeLocalidadeSucesso, 
         inicioLocalidadeSucesso, 
         iniciaLocalidade,
         buscaLocalidadeEdicaoSucesso,
         listaLocaisControladosSucesso,
         listaLocalidadeSucesso,
         listaPontoAcessoSucesso } from "actions/Localidade";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getLocalidades = async () =>
    await requisicaoApi.get('/localidades')
    .then(resposta => resposta)
    .catch(error => error);

    const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);    

  const getLocalidadeEdicao = async (id) =>
    await requisicaoApi.get('/localidades/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaPontosAcessoPorLocalControlado = async (idLocalControlado) => 
    await requisicaoApi.get('/pontosAcesso/localControlado/' + idLocalControlado)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaLocalidade = async (cliente, local, localidade) => 
    await requisicaoApi.get('/localidades?Cliente=' +  cliente + '&Local=' + local + '&localidade=' + localidade)
    .then(resposta => resposta)
    .catch(error => error);      


/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaLocalidadeRequest = async (localidade) => await requisicaoApi.post('/localidades', 
  {
    "id": 0,
      "IdLocalControlado": localidade.idLocalControlado,
      "descricao": localidade.descricao,
      "observacao": localidade.observacao,
      "ativo": localidade.ativo,
      "departamentos": localidade.departamentos,
      "funcoesPessoa": localidade.funcoes,
      "gruposPessoa": localidade.grupos,
      "localidadesPontoAcesso": localidade.pontosAcesso,
      "idCliente": localidade.idCliente,
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaLocalidadeRequest = async (localidade) => await requisicaoApi.put('/localidades', 
  {
    "id": localidade.id,
      "IdLocalControlado": localidade.idLocalControlado,
      "descricao": localidade.descricao,
      "observacao": localidade.observacao,
      "ativo": localidade.ativo,
      "departamentos": localidade.departamentos,
      "funcoesPessoa": localidade.funcoes,
      "gruposPessoa": localidade.grupos,
      "localidadesPontoAcesso": localidade.pontosAcesso,
      "idCliente": localidade.idCliente,
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteLocalidadeRequest = async (id) =>
  await requisicaoApi.delete('/localidades?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaLocalidade() {
    try {
      const listagemGrid = yield call(getLocalidades);
  
      if(listagemGrid.data)
      {
        const clientes = yield call(getClientes);

        if(clientes.data) {
          const clienteFixo = localStorage.getItem('idClienteFixado');

          if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
          {
            const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
            yield put(inicioLocalidadeSucesso(listagemGrid.data, clientes.data, locais.data));
          }
          else
          {
            yield put(inicioLocalidadeSucesso(listagemGrid.data, clientes.data, null));
          }
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar localidades'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaLocaisControladosPorCliente({ payload }) {
    try {
      const cliente = payload.idCliente;
      const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
  
      if(retorno.data)
      {
        yield put(listaLocaisControladosSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }

   function* fnListaLocalidade({ payload }) {
    try {
      const cliente = payload.cliente;
      const local = payload.local;
      const localidade = payload.localidade;
      const retorno = yield call(getListaLocalidade, cliente, local, localidade);
  
      if(retorno.data)
      {
        yield put(listaLocalidadeSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de localidade'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  function* fnBuscaLocalidadeEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getLocalidadeEdicao, id);
  
      if(retorno.data)
      {
        const locais = yield call(getListaLocaisControladosPorCliente, retorno.data.localControlado.idCliente);
        const pontos = yield call(getListaPontosAcessoPorLocalControlado, retorno.data.localControlado.id);

        if(locais.data) {
            yield put(buscaLocalidadeEdicaoSucesso(retorno.data, locais.data, pontos.data));
        }
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados da localidade: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  
  function* fnListaPontosAcessoPorLocal({ payload }) {
    try {
      const local = payload.idLocal;
      const retorno = yield call(getListaPontosAcessoPorLocalControlado, local);
  
      if(retorno.data)
      {
        yield put(listaPontoAcessoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso: ' + local));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaLocalidade({ payload }) {
  try {

    const localidade = payload;
    var resultado = "";

      if(localidade.id === 0) {
        const retorno = yield call(adicionaLocalidadeRequest, localidade);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaLocalidadeRequest, localidade);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaLocalidade());
        yield put(salvaLocalidadeSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveLocalidade({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteLocalidadeRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaLocalidade());
        yield put(removeLocalidadeSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesLocalidade() {
  yield takeEvery(INICIA_LOCALIDADE, fnIniciaLocalidade);
}

export function* salvaLocalidade() {
  yield takeEvery(SALVA_LOCALIDADE, fnSalvaLocalidade);
}

export function* buscaLocalidadeEdicao() {
  yield takeEvery(BUSCA_LOCALIDADE_EDICAO, fnBuscaLocalidadeEdicao);
}

export function* removeLocalidade() {
  yield takeEvery(REMOVE_LOCALIDADE, fnRemoveLocalidade);
}

export function* listaLocaisControladosPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE, fnListaLocaisControladosPorCliente)
}

export function* listaLocalidade() {
  yield takeEvery(LISTA_LOCALIDADE, fnListaLocalidade)
}

export function* listaPontoAcessoPorLocal() {
  yield takeEvery(LISTA_PONTO_ACESSO_POR_LOCAL, fnListaPontosAcessoPorLocal)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesLocalidade), 
             fork(salvaLocalidade), 
             fork(buscaLocalidadeEdicao),
             fork(removeLocalidade),
             fork(listaLocaisControladosPorCliente),
             fork(listaPontoAcessoPorLocal),
             fork(listaLocalidade)]);
}