import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_UNIDADE, BUSCA_UNIDADE_EDICAO, REMOVE_UNIDADE, INICIA_UNIDADE, LISTA_UNIDADE, INICIO_CENARIO_UNIDADE_LOCAL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaUnidadeSucesso,
         removeUnidadeSucesso,
         iniciaUnidade, 
         inicioUnidadeSucesso, 
         buscaUnidadeEdicaoSucesso,
         inicioCenarioUnidadePorLocalControladoSucesso,
         listaUnidadeSucesso } from "actions/Unidade";
import { limpaValidacoes } from "actions/Global";      

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getUnidades = async () =>
    await requisicaoApi.get('/unidades')
    .then(resposta => resposta)
    .catch(error => error);

  const getLocaisControlados = async () =>
    await requisicaoApi.get('/locais')
    .then(resposta => resposta)
    .catch(error => error);

  const getUnidadeLocal = async (id) =>
    await requisicaoApi.get('/unidades/localControlado/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getUnidadeEdicao = async (id) =>
    await requisicaoApi.get('/unidades/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaUnidade = async (modeloFiltro, numeroFiltro, situacaoFiltro) => 
    await requisicaoApi.get('/unidades?Modelo=' +  modeloFiltro + '&Numero=' + numeroFiltro + '&Situacao=' + situacaoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaUnidadeRequest = async (unidade) => await requisicaoApi.post('/unidades', 
  {
    "id": unidade.id,
    "descricaoGrupo": unidade.descricaoGrupo,
    "descricaoUnidade": unidade.descricaoUnidade,
    "complemento": unidade.complemento,
    "situacaoUnidade": unidade.situacaoUnidade,
    "qtdeConvite": unidade.qtdeConvite === null || unidade.qtdeConvite === '' || unidade.qtdeConvite === undefined ? 0 : unidade.qtdeConvite,
    "qtdeGaragem": unidade.qtdeGaragem === null || unidade.qtdeGaragem === '' || unidade.qtdeGaragem === undefined ? 0 : unidade.qtdeGaragem,
    "idLocalControlado": unidade.idLocalControladoFixado,
    "ativo": true,
    "garagens": unidade.garagens.filter(x => x.descricao !== '').length === 0 ? [] : unidade.garagens,
    "escaninhos": unidade.escaninhos.filter(x => x.descricao !== '').length === 0 ? [] : unidade.escaninhos
  })
  .then(resposta => resposta)
  .catch(error => error);


  const atualizaUnidadeRequest = async (unidade) => await requisicaoApi.put('/unidades', 
  {
    "id": unidade.id,
    "descricaoGrupo": unidade.descricaoGrupo,
    "descricaoUnidade": unidade.descricaoUnidade,
    "complemento": unidade.complemento,
    "situacaoUnidade": unidade.situacaoUnidade,
    "qtdeConvite": unidade.qtdeConvite === null || unidade.qtdeConvite === '' || unidade.qtdeConvite === undefined ? 0 : unidade.qtdeConvite,
    "qtdeGaragem": unidade.qtdeGaragem === null || unidade.qtdeGaragem === '' || unidade.qtdeGaragem === undefined ? 0 : unidade.qtdeGaragem,
    "idLocalControlado": unidade.idLocalControladoFixado,
    "ativo": true,
    "garagens": unidade.garagens.filter(x => x.descricao !== '').length === 0 ? [] : unidade.garagens,
    "escaninhos": unidade.escaninhos.filter(x => x.descricao !== '').length === 0 ? [] : unidade.escaninhos
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteUnidadeRequest = async (id) =>
  await requisicaoApi.delete('/unidades?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------------- Functions --------------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaUnidade() {
    try {
      const listagemLocais = yield call(getLocaisControlados);

      if(!listagemLocais.isAxiosError && listagemLocais.data) {

        if(listagemLocais.data.length === 1)
        {
          const local = listagemLocais.data[0].id;
          const listagemGrid = yield call(getUnidadeLocal, local);

          if(listagemGrid.data) {
            yield put(inicioCenarioUnidadePorLocalControladoSucesso(listagemGrid.data, listagemLocais.data, local));
            yield put(limpaValidacoes());
          }
        }
        else
        {
          yield put(inicioUnidadeSucesso(listagemLocais.data));
        }
        
      }
/*       else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar unidades'));
        }
      }        */     
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnCenarioUnidadePorLocalControlado({ payload }) {
    try {
      const local = payload.idLocal;
      const listagemGrid = yield call(getUnidadeLocal, local);

      if(!listagemGrid.isAxiosError && listagemGrid.data) {
        yield put(inicioCenarioUnidadePorLocalControladoSucesso(listagemGrid.data, null, local));
      }
/*       else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar unidades'));
        }
      }        */     
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaUnidadeEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getUnidadeEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaUnidadeEdicaoSucesso(retorno.data));
        yield put(limpaValidacoes());
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do unidade: ' + id));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaUnidade({ payload }) {
    try {
      const modeloFiltro = payload.modelo;
      const numeroFiltro = payload.numero;
      const situacaoFiltro = payload.situacao;
      const retorno = yield call(getUnidades);
  
      if(retorno.data)
      {
        yield put(listaUnidadeSucesso(retorno.data));
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de unidades'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaUnidade({ payload }) {
  try {

    const unidade = payload;
    var resultado = "";
    var errosAgrupados = "";

      if(unidade.id === 0) {
        const retorno = yield call(adicionaUnidadeRequest, unidade);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaUnidadeRequest, unidade);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        const listagemLocais = yield call(getLocaisControlados);
        const listagemGrid = yield call(getUnidadeLocal, unidade.idLocalControladoFixado);
        yield put(inicioCenarioUnidadePorLocalControladoSucesso(listagemGrid.data, listagemLocais.data, unidade.idLocalControladoFixado));
        ////yield put(iniciaUnidade());
        yield put(salvaUnidadeSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveUnidade({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteUnidadeRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaUnidade());
        yield put(removeUnidadeSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesUnidade() {
  yield takeEvery(INICIA_UNIDADE, fnIniciaUnidade);
}

export function* iniciaCenarioUnidadePorLocalControlado() {
  yield takeEvery(INICIO_CENARIO_UNIDADE_LOCAL, fnCenarioUnidadePorLocalControlado);
}

export function* salvaUnidade() {
  yield takeEvery(SALVA_UNIDADE, fnSalvaUnidade);
}

export function* buscaUnidadeEdicao() {
  yield takeEvery(BUSCA_UNIDADE_EDICAO, fnBuscaUnidadeEdicao);
}

export function* removeUnidade() {
  yield takeEvery(REMOVE_UNIDADE, fnRemoveUnidade);
}

export function* listaUnidade() {
  yield takeEvery(LISTA_UNIDADE, fnListaUnidade)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesUnidade),
             fork(iniciaCenarioUnidadePorLocalControlado),
             fork(salvaUnidade), 
             fork(buscaUnidadeEdicao),
             fork(removeUnidade),
             fork(listaUnidade)]);
}