import React, { Component } from 'react';
import IntegrationContent from 'luxFw/integrations/IntegrationContent';
import IntegrationTable from 'luxFw/integrations/IntegrationTable';
import { Card, CardBody, CardFooter } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert'
import LinearProgress from '@material-ui/core/LinearProgress';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { LuxFwTextBox } from 'luxFw/palettes/Standart';
import { withStyles } from "@material-ui/core/styles";
import TextoPesquisaFiltro from 'luxFw/componentsFw/TextoPesquisaFiltro';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  container: {
      minHeight: "72vh"
  },
  input: {
      fontSize: 19,
      fontWeight: 400,
      fontFamily: 'Montserrat, sans-serif',

      "&::placeholder": {
          textOverflow: "ellipsis !important",
          color: "gray",
          fontSize: 20,
          lineHeight: 24,
          fontWeight: 400,
          fontFamily: 'Montserrat, sans-serif'
      },
  },
  correcaoBorda: {
      '& .MuiInput-underline:after': {
          borderBottomColor: '#05B59D',
      },
      '& .MuiInput-underline:before': {
          borderBottomColor: '#05B59D',
          borderBottomWidth: '2px'
      },
      '& .MuiInput-underline:hover': {
          borderBottomColor: '#05B59D',
      },
  },
  large: {
      width: theme.spacing(11),
      height: theme.spacing(11),
  },
});

function TabContainer(props) {
  return (
    <div style={{ padding: 20 }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export class LuxFwContent extends Component {

  componentDidUpdate() {
    if (this.props.showMessageSuccess || this.props.showMessageError) {

      clearTimeout(() => {
        this.props.hideMessage();
      });

      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  render() {
    const { children, loader, showMessageSuccess, showMessageError, alertMessage } = this.props;

    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <IntegrationContent title={this.props.title} match={this.props.match} />
          {
            this.props.loader &&
            <div className="loader-view">
              <LinearProgress style={{ width: '100%', paddingTop: 5 }} />
            </div>
          }
          {children}

          {this.props.showMessageSuccess && NotificationManager.success(this.props.alertMessage)}
          {this.props.showMessageError && NotificationManager.error(this.props.alertMessage)}
          <NotificationContainer />
        </div>
      </div>
    );
  }
}

export class LuxFwTabContainer extends Component {

  render() {

    const { children } = this.props;

    return (
      <TabContainer>
        <div className="row">
          {children}
        </div>
      </TabContainer>
    );

  }
}


export class LuxFwPanelEdicao extends Component {
  constructor() {
    super();
    this.state = {
      warning: false,
    };
  }

  onCancelDelete = () => {
    this.setState({
      warning: false
    })
  };

  render() {
    const { warning } = this.state;
    const { children, cancelaEdicao, cliqueSubmit, cliqueDelete, showAuthLoader, barraBotoesEdicaoCustom } = this.props;

    return (
      <Card className={`shadow border-0`}>
        <CardBody className="mb-4">
          <h3 className="card-title mb-1">Detalhes</h3>
          {children}
        </CardBody>
        <Divider variant="inset" />

        {
          barraBotoesEdicaoCustom !== undefined ? (barraBotoesEdicaoCustom) :

            <CardFooter className="text-white text-right">
{/*               <Button variant="contained" color="primary" onClick={() => cancelaEdicao()} className="jr-btn bg-orange mr-2 text-uppercase">
                <i className="zmdi zmdi-long-arrow-return zmdi-hc-fw" />
                <span>CANCELAR</span>
              </Button> */}

              <Button style={{ width: 151, marginRight: 8, padding: '14px 36px', borderRadius: 100 }} >
                <span style={{ fontStyle: 'normal', color: '#828282', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }} onClick={() => cancelaEdicao()}>CANCELAR</span>
              </Button>



{/*               {this.props.id > 0 &&
                <Button variant="contained" color="primary" onClick={() => { this.setState({ warning: true }) }} className="jr-btn bg-red mr-2 text-uppercase">
                  <i className="zmdi zmdi-delete zmdi-hc-fw" />
                  <span>EXCLUIR</span>
                </Button>} */}
              {/*               <Button type="submit" variant="contained" color="primary" onClick={() => { showAuthLoader(); cliqueSubmit(); }} className="jr-btn mr-2 text-uppercase">
                <i className="zmdi zmdi-check zmdi-hc-fw" />
                <span>SALVAR</span>
              </Button> */}

              <Button style={{ width: 151, marginRight: 8, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" onClick={() => { showAuthLoader(); cliqueSubmit(); }}>
                <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>SALVAR</span>
              </Button>

              <SweetAlert show={warning}
                danger
                showCancel
                confirmBtnText="Sim, Exclua-o"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                cancelBtnText="Cancelar"
                title="Você tem certeza?"
                onConfirm={() => { showAuthLoader(); cliqueDelete(); }}
                onCancel={this.onCancelDelete}
              >
                A remoção do registro não poderá ser revertida!
          </SweetAlert>


            </CardFooter>


        }
      </Card>
    );
  }
}

export class LuxFwPanelFiltros extends Component {
  render() {
    const { children, cliqueFiltrar, cliqueLimpar } = this.props;

    return (
      <Card className={`shadow border-0`}>
        {/* <CardHeader className="text-white text-right">{cardHeader}</CardHeader> */}
        <CardBody className="mb-4">
          <h3 className="card-title mb-1">Filtros</h3>
          {children}
        </CardBody>
        {/*         <Divider variant="inset" />
        <CardFooter className="text-white text-right">
          <Button onClick={cliqueLimpar} variant="contained" color="primary" className="jr-btn bg-grey mr-2 text-uppercase">
            <i className="zmdi zmdi-close zmdi-hc-fw" />
            <span>LIMPAR</span>
          </Button>
          <Button onClick={cliqueFiltrar} variant="contained" color="primary" className="jr-btn bg-info text-white mr-2 text-uppercase">
            <i className="zmdi zmdi-filter-list zmdi-hc-fw" />
            <span>FILTRAR</span>
          </Button>
        </CardFooter> */}
      </Card>
    );
  }
}

class GridListagem extends Component {
  render() {

    const { carregandoLista, colunasGrid, listGrid, listGridComFiltro } = this.props;
    const { classes } = this.props;

    return (
      <Card className={`shadow border-0`}>
        <CardBody>
          <div className="d-sm-flex justify-content-sm-between align-top align-items-sm-center">
            <TextField
              variant="filled"
              placeholder="Pesquisar..."
              value={this.props.textoFiltro}
              onChange={this.props.onChangeFilter}
              margin="normal"
              style={{ width: '30%', minWidth: '290px', lineHeight: '24px', backgroundColor: '#00000000' }}
              InputProps={{
                classes: {
                  input: classes.input
              },                
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Button style={{ marginTop: -20, marginRight: 8, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" onClick={this.props.cliqueNovo}>
              <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>CADASTRAR NOVO</span>
            </Button>
          </div>

          <IntegrationTable controlador={carregandoLista} colunas={colunasGrid} registros={this.props.textoFiltro && this.props.textoFiltro.length >= 3 ? listGridComFiltro : listGrid} cliqueEditar={this.props.cliqueEditar} />

        </CardBody>
      </Card>
    );
  }
}

GridListagem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const LuxFwPanelGridListagem = withStyles(styles)(GridListagem);

export { LuxFwPanelGridListagem };


