import {
  INICIA_CATEGORIA,
  SALVA_CATEGORIA,
  REMOVE_CATEGORIA,
  NOVO_CATEGORIA,
  BUSCA_CATEGORIA_EDICAO,
  INICIO_CATEGORIA_SUCESSO,
  SALVA_CATEGORIA_SUCESSO,
  REMOVE_CATEGORIA_SUCESSO,
  BUSCA_CATEGORIA_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_CATEGORIA,
  LISTA_CATEGORIA_SUCESSO,
  LIMPAR_CATEGORIA,
  INICIO_CENARIO_CATEGORIA_LOCAL,
  INICIO_CENARIO_CATEGORIA_LOCAL_SUCESSO
} from 'constants/ActionTypes';
import moment from 'moment';
import { ValidaCampoObrigatorio } from 'luxFw/transformations/Validations';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaCategoria = () => {
  return {
    type: INICIA_CATEGORIA
  };
};

export const iniciaCenarioCategoriaPorLocalControlado = (name, object, value) => {
  var idLocal = value !== null ? value.id : 0;

  return {
    type: INICIO_CENARIO_CATEGORIA_LOCAL,
    payload: { idLocal }
  };
}

export const inicioCenarioCategoriaPorLocalControladoSucesso = (categorias, locaisControlados, idLocal) => {
  return {
    type: INICIO_CENARIO_CATEGORIA_LOCAL_SUCESSO,
    payload: { categorias, locaisControlados, idLocal }
  };
}

export const salvaCategoria = (categoria) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatorios(categoria));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if(errosEncontrados.length === 0)
  {
    return {
      type: SALVA_CATEGORIA,
      payload: categoria      
    }
  }
  else 
  {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }      
    
    validacoes.push(exibeMensagem);

    return validacoes;
  }  
};

function RetornosArrayValidacoesCamposObrigatorios(categoria) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('descricaoGrupoCategoria', categoria.descricaoGrupoCategoria));
  validacoes.push(ValidaCampoObrigatorio('descricaoUnidadeCategoria', categoria.descricaoUnidadeCategoria));

  return validacoes;
}

export const removeCategoria = (id) => {
  return {
    type: REMOVE_CATEGORIA,
    payload: id
  };
};

export const listaCategoria = (modelo, numero, situacao) => {
  if (modelo == undefined) {
    modelo = "";
  }

  if (numero == undefined) {
    numero = "";
  }

  if (situacao == undefined) {
    situacao = "";
  }

  return {
    type: LISTA_CATEGORIA,
    payload: { modelo, numero, situacao }
  };
};


/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoCategoria = () => {
  return {
    type: NOVO_CATEGORIA
  };
};

export const buscaCategoriaEdicao = (id) => {
  return {
    type: BUSCA_CATEGORIA_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioCategoriaSucesso = (locaisControlados) => {
  return {
    type: INICIO_CATEGORIA_SUCESSO,
    payload: { locaisControlados }
  }
};

export const salvaCategoriaSucesso = (mensagem) => {
  return {
    type: SALVA_CATEGORIA_SUCESSO,
    payload: mensagem
  }
};

export const removeCategoriaSucesso = (mensagem) => {
  return {
    type: REMOVE_CATEGORIA_SUCESSO,
    payload: mensagem
  }
};

export const buscaCategoriaEdicaoSucesso = (categoria) => {
  return {
    type: BUSCA_CATEGORIA_EDICAO_SUCESSO,
    payload: categoria
  };
};

export const listaCategoriaSucesso = (categoria) => {
  return {
    type: LISTA_CATEGORIA_SUCESSO,
    payload: { categoria }
  };
};

export const limparFiltroCategoria = () => {
  return {
    type: LIMPAR_CATEGORIA,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};