import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import IconeSemBiometria from '../util/iconeSemBiometria';
import IconeSemBiometriaDesabilitado from '../util/iconeSemBiometriaDesabilitado';
import IconeSemBiometriaDedo from '../util/iconeSemBiometriaDedo';
import IconePossuiBiometriaFacial from '../util/iconePossuiBiometriaFacial'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Avatar from '@material-ui/core/Avatar';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PhoneIcon from '@material-ui/icons/Phone';

export default class Sidebar extends Component {


    render() {

        /*         const pessoaSelecionada = this.props.pessoaSelecionada;
                var imagemTratada = pessoaSelecionada.imagem;
                imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null; */
        const imagemPessoa = this.props.imagemAnaliseDC;

        return (
            <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                    <div>
                        <Grid style={{ height: 100, alignItems: 'center' }} item>
                                <Avatar style={{ marginLeft: 25, float: 'left', backgroundColor: 'rgba(0, 0, 0, 0.38)', color: '#FFFFFF' }}>
                                    <ArrowBackSharpIcon /> 
                                </Avatar>
                            <Typography style={{ float: 'left', marginLeft: 10, textAlign: 'left', fontWeight: 700, fontSize: 24, fontFamily: 'Montserrat, sans-serif', color: '#FFFFFF' }}>
                                ENTRADA
                            </Typography>
                        </Grid>
                    </div>

                    <Grid item style={{ marginTop: -12, width: '100%' }}>
                        <div id="divProfile" /* style={{ width: '100%', backgroundColor: '#fff', height: 370, marginBottom: 10, borderRadius: 15, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }} */>
                            <div id="divAvatar" style={{ width: '100%', position: 'relative', top: '-40px' }}>
                                <Avatar variant="rounded" style={{ width: '95%', height: 230, marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px', borderRadius: 15 }} alt="Remy Sharp" src={`data:image/jpeg;base64,${imagemPessoa}`} />
                                <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >
                                    <span style={{ lineHeight: 3, width: '100%', fontSize: '12px' }} ><LocalOfferIcon />  265451</span>
                                </div>

                                <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >
                                    <span style={{ lineHeight: 3, width: '100%', fontSize: '12px' }} ><PhoneIcon />  (62) 98524-5456(+)</span>
                                </div>

                                <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >
                                    <span style={{ lineHeight: 3, width: '100%', fontSize: '12px' }} ><DriveEtaIcon />  S. T. Esmeralda</span>
                                </div>


                            </div>


                        </div>
                    </Grid>

                </Grid>

            </Grid>)
    }

}