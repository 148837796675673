import {
    INICIA_ACESSO_BLOQUEIO,
    SALVA_ACESSO_BLOQUEIO,
    REMOVE_ACESSO_BLOQUEIO,
    NOVO_ACESSO_BLOQUEIO,
    BUSCA_ACESSO_BLOQUEIO_EDICAO,
    INICIO_ACESSO_BLOQUEIO_SUCESSO,
    LISTA_CONFIGURACAO_PONTO_BLOQUEIO,
    LISTA_CONFIGURACAO_PONTO_BLOQUEIO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_ACESSO_BLOQUEIO,
    LISTA_LOCAIS_SUCESSO_ACESSO_BLOQUEIO,
    ALTERACAO_CHECK_PONTO_ACESSO_BLOQUEIO,
    SALVA_ACESSO_BLOQUEIO_SUCESSO,
    REMOVE_ACESSO_BLOQUEIO_SUCESSO,
    BUSCA_ACESSO_BLOQUEIO_EDICAO_SUCESSO,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    CONSULTA_CPF,
    CONSULTA_CPF_SUCESSO_BLOQUEIO,
    LISTA_ACESSO_BLOQUEIO,
    LISTA_ACESSO_BLOQUEIO_SUCESSO,
    LIMPAR_ACESSO_BLOQUEIO
  } from 'constants/ActionTypes';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaAcessoBloqueio = () => {
    return {
      type: INICIA_ACESSO_BLOQUEIO
    };
  };
  
  export const salvaAcessoBloqueio = (acessoBloqueio) => {
    var listaPontosAssociados = [];

    if(acessoBloqueio.configuracaoPontoPessoa != null && acessoBloqueio.configuracaoPontoPessoa.listaAmbientesVinculados != null)
    {
      for (var i = 0; i < acessoBloqueio.configuracaoPontoPessoa.listaAmbientesVinculados.length; i++) {
        var listaPontos = acessoBloqueio.configuracaoPontoPessoa.listaAmbientesVinculados[i].pontosAcessoVinculados;
  
        for (var j = 0; j < listaPontos.length; j++) {
          var idPontoAcesso = listaPontos[j].idPontoAcesso;
          listaPontosAssociados.push({"idPontoAcesso": listaPontos[j].idPontoAcesso, "associado": listaPontos[j].associado })
        }
      }
    }
   
    return {
      type: SALVA_ACESSO_BLOQUEIO,
      payload: { acessoBloqueio, listaPontosAssociados } 
    };
  };
  
  export const removeAcessoBloqueio = (id) => {
    return {
      type: REMOVE_ACESSO_BLOQUEIO,
      payload: id
    };
  };
  
  export const listaAcessoBloqueio = (usuario, ambiente, ponto) => {
    if(usuario == undefined){
      usuario = "";
    }
  
    if(ambiente == undefined){
      ambiente = "";
    }
  
    if(ponto == undefined){
      ponto = "";
    }
  
    return {
      type: LISTA_ACESSO_BLOQUEIO,
      payload: {usuario, ambiente, ponto}
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoAcessoBloqueio = () => {
    return {
      type: NOVO_ACESSO_BLOQUEIO
    };
  };
  
  export const buscaAcessoBloqueioEdicao = (id) => {
    return {
      type: BUSCA_ACESSO_BLOQUEIO_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  

  export const listaLocaisPorCliente = (name, object, value) => {
    var idCliente = value !== null ? value.id : 0;

    return {
      type: LISTA_LOCAIS_POR_CLIENTE_ACESSO_BLOQUEIO,
      payload: { idCliente }
    }
  }

  export const consultaCpfSucesso = (correspondenciaCpf) => {
    return {
      type: CONSULTA_CPF_SUCESSO_BLOQUEIO,
      payload: correspondenciaCpf
    }
  };  

  export const listaConfiguracaoPontosPorPessoa = (idLocal, idPessoa) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_BLOQUEIO,
      payload: { idLocal, idPessoa }
    }
  }

  export const onChangeCheckPontoAcesso = (object, value) => {
    var nomeCheck = [object.target.id].toString();
    var idPtoAcesso = nomeCheck.replace(/pontoAcesso_/g, "");

    return {
        type: ALTERACAO_CHECK_PONTO_ACESSO_BLOQUEIO,
        payload: { idPontoAcessoAlterado: idPtoAcesso, valor: value }
    }   
};   
  
  export const inicioAcessoBloqueioSucesso = (acessosBloqueio, clientes, locais) => {
    return {
      type: INICIO_ACESSO_BLOQUEIO_SUCESSO,
      payload: { acessosBloqueio, clientes, locais }
    }
  };
  
  export const salvaAcessoBloqueioSucesso = (mensagem) => {
    return {
      type: SALVA_ACESSO_BLOQUEIO_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removeAcessoBloqueioSucesso = (mensagem) => {
    return {
      type: REMOVE_ACESSO_BLOQUEIO_SUCESSO,
      payload: mensagem
    }
  };  

  export const listaConfiguracaoPontoPessoaSucesso = (configuracao) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_BLOQUEIO_SUCESSO,
      payload: { configuracao }
    }
  }
  
  export const buscaAcessoBloqueioEdicaoSucesso = (acessoBloqueio, locais) => {
    return {
      type: BUSCA_ACESSO_BLOQUEIO_EDICAO_SUCESSO,
      payload: {acessoBloqueio, locais}
    };
  }; 

  export const listaLocaisSucesso = (locais) => {
    return {
      type: LISTA_LOCAIS_SUCESSO_ACESSO_BLOQUEIO,
      payload: { locais }
    };
  };

  export const listaAcessoBloqueioSucesso = (acessoBloqueio) => {
    return {
      type: LISTA_ACESSO_BLOQUEIO_SUCESSO,
      payload: { acessoBloqueio }
    };
  };
  
  export const limparFiltroAcessoBloqueio = () => {
    return {
      type: LIMPAR_ACESSO_BLOQUEIO,
    };
  };
  
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

  export const consultaCPF = (cpf) => {
    let cpfAjustado = cpf.target.value;
    cpfAjustado = cpfAjustado.replace(/-/g, "");

    return {
      type: CONSULTA_CPF,
      payload: cpfAjustado
    };
  };
  
  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };