import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_EQUIPAMENTO, BUSCA_EQUIPAMENTO_EDICAO, REMOVE_EQUIPAMENTO, INICIA_EQUIPAMENTO, LISTA_EQUIPAMENTO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaEquipamentoSucesso,
         removeEquipamentoSucesso,
         iniciaEquipamento, 
         inicioEquipamentoSucesso, 
         buscaEquipamentoEdicaoSucesso,
         listaEquipamentoSucesso } from "actions/Equipamento";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getEquipamentos = async () =>
    await requisicaoApi.get('/equipamentos')
    .then(resposta => resposta)
    .catch(error => error);

  const getModelos = async () =>
    await requisicaoApi.get('/modelos')
    .then(resposta => resposta)
    .catch(error => error);    

  const getSituacoes = async () =>
    await requisicaoApi.get('/util/situacoesEquipamento')
    .then(resposta => resposta)
    .catch(error => error);        

  const getEquipamentoEdicao = async (id) =>
    await requisicaoApi.get('/equipamentos/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

    const getListaEquipamento = async (modeloFiltro, numeroFiltro, situacaoFiltro) => 
    await requisicaoApi.get('/equipamentos?Modelo=' +  modeloFiltro + '&Numero=' + numeroFiltro + '&Situacao=' + situacaoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaEquipamentoRequest = async (equipamento) => await requisicaoApi.post('/equipamentos', 
  {
    "id": equipamento.id,
    "tipoEquipamento": equipamento.tipoEquipamento,
    "numeroLicencas": equipamento.numeroLicencas,
    "descricaoEquipamento": equipamento.descricaoEquipamento,
    "numeroSerieEquipamento": equipamento.numeroSerieEquipamento,
    "enderecoMAC": equipamento.enderecoMAC,
    "idModeloEquipamento": equipamento.idModeloEquipamento,
    "idSituacaoEquipamento": equipamento.idSituacaoEquipamento,
    "numeroPortaAutenticacao": equipamento.numeroPortaAutenticacao === '' ? null :  equipamento.numeroPortaAutenticacao 
  })
  .then(resposta => resposta)
  .catch(error => error);


  const atualizaEquipamentoRequest = async (equipamento) => await requisicaoApi.put('/equipamentos', 
  {
    "id": equipamento.id,
    "descricaoEquipamento": equipamento.descricaoEquipamento,
    "numeroSerieEquipamento": equipamento.numeroSerieEquipamento,
    "enderecoMAC": equipamento.enderecoMAC,
    "idModeloEquipamento": equipamento.idModeloEquipamento,
    "idSituacaoEquipamento": equipamento.idSituacaoEquipamento,
    "numeroPortaAutenticacao": equipamento.numeroPortaAutenticacao === '' ? null :  equipamento.numeroPortaAutenticacao,
    "tipoEquipamento": equipamento.tipoEquipamento,
    "numeroLicencas": equipamento.numeroLicencas, 
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteEquipamentoRequest = async (id) =>
  await requisicaoApi.delete('/equipamentos?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------------- Functions --------------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaEquipamento() {
    try {
      const listagemGrid = yield call(getEquipamentos);

      if(!listagemGrid.isAxiosError && listagemGrid.data) {
        const modelos = yield call(getModelos);

        if(modelos.data) {
          const situacoes = yield call(getSituacoes);

          if(situacoes.data) {
            yield put(inicioEquipamentoSucesso(listagemGrid.data, modelos.data, situacoes.data));
          }
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar equipamentos'));
        }
      }            
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaEquipamentoEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getEquipamentoEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaEquipamentoEdicaoSucesso(retorno.data));
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do equipamento: ' + id));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaEquipamento({ payload }) {
    try {
      const modeloFiltro = payload.modelo;
      const numeroFiltro = payload.numero;
      const situacaoFiltro = payload.situacao;
      const retorno = yield call(getListaEquipamento, modeloFiltro, numeroFiltro, situacaoFiltro);
  
      if(retorno.data)
      {
        yield put(listaEquipamentoSucesso(retorno.data));
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de equipamentos'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaEquipamento({ payload }) {
  try {

    const equipamento = payload;
    var resultado = "";
    var errosAgrupados = "";

      if(equipamento.id === 0) {
        const retorno = yield call(adicionaEquipamentoRequest, equipamento);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaEquipamentoRequest, equipamento);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaEquipamento());
        yield put(salvaEquipamentoSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveEquipamento({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteEquipamentoRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaEquipamento());
        yield put(removeEquipamentoSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesEquipamento() {
  yield takeEvery(INICIA_EQUIPAMENTO, fnIniciaEquipamento);
}

export function* salvaEquipamento() {
  yield takeEvery(SALVA_EQUIPAMENTO, fnSalvaEquipamento);
}

export function* buscaEquipamentoEdicao() {
  yield takeEvery(BUSCA_EQUIPAMENTO_EDICAO, fnBuscaEquipamentoEdicao);
}

export function* removeEquipamento() {
  yield takeEvery(REMOVE_EQUIPAMENTO, fnRemoveEquipamento);
}

export function* listaEquipamento() {
  yield takeEvery(LISTA_EQUIPAMENTO, fnListaEquipamento)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesEquipamento),
             fork(salvaEquipamento), 
             fork(buscaEquipamentoEdicao),
             fork(removeEquipamento),
             fork(listaEquipamento)]);
}