import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

class EnhancedTableHead extends React.Component {

  static propTypes = {
    colunas: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, colunas } = this.props;

    return (
      <TableHead>
        <TableRow>
          {colunas.map(column => {

            if (!column.chave) {
              return (
                <TableCell
                  key={column.propriedadeApi}
                  numeric={column.numerico ? 'true' : 'false'}
                  align={column.alinhamento}
                  padding={'default'}
                >
                  <Tooltip
                    title="Sort"
                    placement={column.numerico ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.propriedadeApi}
                      direction={order}
                      onClick={this.createSortHandler(column.propriedadeApi)}
                    >
                      {column.titulo}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              )
            };
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

class EnhancedTable extends React.Component {

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.props.registros.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.props.registros.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.props.registros.map(n => n.propriedadeApi) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    alert("Id" + id);
    this.props.cliqueEditar(id);
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'desc',
      orderBy: '',
      colunas: this.props.colunas,
      children: this.props.children,
      selected: [],
      data: this.props.controlador ? [] : this.props.registros,
      page: 0,
      rowsPerPage: 10,
    };
  }

  render() {
    const { order, orderBy, selected, rowsPerPage, page, colunas, carregandoThumb } = this.state;
    const { controlador, registros } = this.props;
    return (
      <div>
        <div className="flex-auto">
          <div className="table-responsive-material">
            <Table>
              <EnhancedTableHead
                colunas={colunas}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={registros !== undefined && registros.length !== 0 ? registros.length : 0}
              />
              <TableBody>
                {controlador ? 
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={1}
                >
                  <TableCell colSpan={colunas.length} padding="default" align="center"><CircularProgress size={13} /> Carregando Listagem...</TableCell>
                </TableRow>
                  : registros !== undefined && registros.length !== 0 ? registros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                    let contador = 0;
                    return (
                      <TableRow
                        hover
                        style={{ cursor: 'pointer' }}
                        onClick={() => {this.props.cliqueEditar(n.id)}}
                        onKeyDown={event => this.handleKeyDown(event, n.id)}
                        role="checkbox"
                        tabIndex={-1}
                        key={n.id}
                      >

                        {colunas.slice().map(t => {
                          contador = contador + 1;

                          var coluna = t['propriedadeApi'];
                          var contadorPontos = 0;

                          if (coluna.indexOf(".") !== -1) {
                            var valorObj = "";
                            var pontoEncontrado = 0;
                            var valorTemp = "";

                            while (coluna.indexOf(".", contadorPontos) !== -1) {
                              pontoEncontrado = coluna.indexOf(".", contadorPontos);
                              valorTemp = coluna.substring(contadorPontos, pontoEncontrado);
                              valorObj = valorObj !== "" ? valorObj[valorTemp] : n[valorTemp];
                              contadorPontos = pontoEncontrado + 1;
                            }

                            valorTemp = coluna.substring(contadorPontos);
                            valorObj = valorObj !== "" ? valorObj[valorTemp] : n[valorTemp];
                            var valor = valorObj;
                          }
                          else {
                            var valor = n[coluna];
                          }

                          var alinhamento = t['alinhamento'];
                          var campoChave = t['chave'];
                          var datetime = t['datetime'];
                          var booleano = t['booleano'];
                          var imagem = t['imagem'];

                          if (!campoChave) {
                            if (datetime) {
                              var parsedDate = moment(valor);
                              var formattedDate = parsedDate.format('DD/MM/YYYY');
                              valor = formattedDate;
                            }

                            if (booleano) {
                              valor = valor ? "Sim" : "Não";
                            }

                            if (valor === null || valor === 0) {
                              valor = "Nenhum(a)"
                            }

                            if(imagem) {
                              var imagemTratada = valor;
                              imagemTratada = imagemTratada !== null && imagemTratada !== "" && imagemTratada !== undefined ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;                              
                            }

                            return imagem ? (
                              <TableCell key={contador} style={{paddingLeft: 10}} padding="none" align="center">
                                <Avatar style={{ cursor: 'pointer' }} src={`data:image/jpeg;base64,${imagemTratada}`} />
                              </TableCell>
                            ) : (
                              <TableCell key={contador} padding={contador === 1 ? "none" : "default"} align={alinhamento}>{valor}</TableCell>
                            );
                          }
                        })
                        }
                      </TableRow>
                    );
                  }) :
                  
                  <TableCell colSpan={colunas.length} padding="default" align="center"> Nenhum registro retornado!</TableCell>

                  }

              </TableBody>
              <TableFooter>
                <TableRow>
                  {this.props.desabilitarPagicacao != true && (<TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={"Linhas por página"}
                    labelDisplayedRows={(from = page) => (`${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count}`)}
                    count={registros !== undefined ? registros.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />)}
                  
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default EnhancedTable;