import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Global from './Global';
import Modelo from './Modelo';
import Equipamento from './Equipamento';
import Cliente from './Cliente';
import LocalControlado from './LocalControlado';
import PontoAcesso from './PontoAcesso';
import Perfil from './Perfil';
import PerfilPortal from './PerfilPortal';
import UsuarioPlataforma from './UsuarioPlataforma';
import UsuarioPortal from './UsuarioPortal';
import AcessoPessoa from './AcessoPessoa';
import AcessoPerfil from './AcessoPerfil';
import AcessoGenero from './AcessoGenero';
import AcessoIdade from './AcessoIdade';
import AcessoPeriodo from './AcessoPeriodo';
import AcessoFull from './AcessoFull';
import AcessoBloqueio from './AcessoBloqueio';
import Localidade from './Localidade';
import VinculoPessoa from './VinculoPessoa';
import Inicio from './Inicio';
import Unidade from './Unidade';
import Categoria from './Categoria';
import Titularidade from './Titularidade';
import Ambiente from './Ambiente';
import PortariaInicio from './PortariaInicio';
import Portaria from './Portaria';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  global: Global,
  modelo: Modelo,
  equipamento: Equipamento,
  cliente: Cliente,
  localControlado: LocalControlado,
  pontoAcesso: PontoAcesso,
  perfil: Perfil,
  perfilPortal: PerfilPortal,
  usuarioPlataforma: UsuarioPlataforma,
  usuarioPortal: UsuarioPortal,
  acessoPessoa: AcessoPessoa,
  acessoPerfil: AcessoPerfil,
  acessoGenero: AcessoGenero,
  acessoIdade: AcessoIdade,
  acessoPeriodo: AcessoPeriodo,
  acessoFull: AcessoFull,
  acessoBloqueio: AcessoBloqueio,
  inicio: Inicio,
  localidade: Localidade,
  vinculoPessoa: VinculoPessoa,
  unidade: Unidade,
  categoria: Categoria,
  titularidade: Titularidade,
  ambiente: Ambiente,
  portariaInicio: PortariaInicio,
  portaria: Portaria
});
