import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { tiposLocal, tiposSincronizacao } from '../app/constantes/Listas';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
  INICIA_LOCAL_CONTROLADO,
  NOVO_LOCAL_CONTROLADO,
  BUSCA_LOCAL_CONTROLADO_EDICAO,
  CANCELA_EDICAO,
  ADICIONA_RESPONSABILIDADE,
  REMOVE_RESPONSABILIDADE,
  CLIQUE_CONTROLE_ABA_LOCAL,
  INICIO_LOCAL_CONTROLADO_SUCESSO,
  SALVA_LOCAL_CONTROLADO,
  SALVA_LOCAL_CONTROLADO_SUCESSO,
  REMOVE_LOCAL_CONTROLADO_SUCESSO,
  BUSCA_LOCAL_CONTROLADO_EDICAO_SUCESSO,
  SETAR_IMAGEM_LOCAL_CONTROLADO,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CHECK,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  LISTA_LOCAL_CONTROLADO,
  LISTA_LOCAL_CONTROLADO_SUCESSO,
  LIMPAR_LOCAL_CONTROLADO,
  APLICA_FILTRO,
  ATUALIZA_RESPONSABILIDADE,
  ATUALIZAR_PERFIL_AUTORIZADO_LOCAL
} from "constants/ActionTypes";

const INIT_STATE = {

  // Configuração da Grid
  colunasGrid: [
    { titulo: 'Codigo Local', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },
    { titulo: 'Descrição', propriedadeApi: 'descricaoLocalControlado', alinhamento: 'left' },
    { titulo: 'Tipo Controle', propriedadeApi: 'tipoLocalControlado', alinhamento: 'left' },
    { titulo: 'Início do Controle', propriedadeApi: 'dataInicioControle', alinhamento: 'left', datetime: true },
    { titulo: 'Fim do Controle', propriedadeApi: 'dataFimControle', alinhamento: 'left', datetime: true }
  ],

  responsabilidadesUnidadeNovo: [
    {
      id: 0,
      idTemporario: 0,
      descricaoResponsabilidade: '',
      tipoResponsabilidadeLocal: 'UNIDADE',
    }
  ],

  responsabilidadesCategoriaNovo: [
    {
      id: 0,
      idTemporario: 0,
      descricaoResponsabilidade: '',
      tipoResponsabilidadeLocal: 'CATEGORIA',
    }
  ],

  responsabilidadesTituloNovo: [
    {
      id: 0,
      idTemporario: 0,
      descricaoResponsabilidade: '',
      tipoResponsabilidadeLocal: 'TITULO',
    }
  ],

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],
  listGridComFiltro: [],
  textoFiltro: '',

  // Campos do Formulario
  id: 0,
  idCliente: 0,
  idTipoEndereco: 0,
  tipoLocalControlado: '',
  tipoComunicacao: '',
  tipoSincronizacaoBD: '',
  descricaoLocalControlado: '',
  dataEvento: null,
  dataInicioControle: null,
  dataFimControle: null,
  caminhoImagemEvento: '',
  observacoes: '',
  nomeClienteFixado: null,
  idClienteFixado: 0,
  possuiUnidade: null,
  descricaoRotuloGrupoUnidade: '',
  descricaoRotuloUnidadeUnidade: '',
  possuiCategoria: null,
  descricaoRotuloGrupoCategoria: '',
  descricaoRotuloUnidadeCategoria: '',
  possuiTitularidade: null,
  descricaoRotuloTitularidadePrincipal: '',
  descricaoRotuloTitularidadeSecundario: '',
  possuiGaragem: null,
  tipoGaragem: '',
  agrupaPorUnidade: null,
  agrupaPorCategoria: null,
  agrupaPorTitulo: null,
  habilitaLiberacaoTemporaria: null,
  habilitaLiberacaoExpress: null,
  habilitaGestaoConvite: null,
  habilitaRespUnidade: null,
  habilitaRespCategoria: null,
  habilitaRespTitulo: null,
  responsabilidadesUnidade: [],
  responsabilidadesCategoria: [],
  responsabilidadesTitulo: [],
  responsabilidades: [],
  unidades: [],
  categorias: [],
  titularidades: [],
  listaDePerfis: [],
  listaDePerfisAutorizados: [],

  // Listas Vinculadas
  ////ambientes: [],
  ////nomeAmbienteAdd: '',
  ////idAmbientePaiAdd: 0,
  ////ativoAdd: true,
  ////listaDeAmbientes: [],

  // Combos
  listaDeClientes: [],
  listaDeTiposDeLocaisControlados: [],
  listaDeTiposDeSincronizacaoBD: [],

  // Filtros
  textoFiltro: '',

  // nomeClienteFiltro: '', 
  // inicioControleFiltro: '', 
  // fimControleFiltro: '', 
  // nomeLocalFiltro: '',
  // tipoLocalFiltro: '',

  // Outros
  indiceAba: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_LOCAL_CONTROLADO: {

      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        textoFiltro: '',
      }
    }
    case NOVO_LOCAL_CONTROLADO: {
      return {
        ...state,
        edicao: true,
        indiceAba: 0,
        id: 0,
        idCliente: state.idClienteFixado !== 0 ? parseInt(state.idClienteFixado) : 0,
        idTipoEndereco: 0,
        tipoLocalControlado: 'PERMANENTE',
        tipoComunicacao: '',
        tipoSincronizacaoBD: 'ONLINE',
        descricaoLocalControlado: '',
        dataEvento: null,
        dataInicioControle: null,
        dataFimControle: null,
        caminhoImagemEvento: '',
        observacoes: '',

        possuiUnidade: false,
        descricaoRotuloGrupoUnidade: '',
        descricaoRotuloUnidadeUnidade: '',
        possuiCategoria: false,
        descricaoRotuloGrupoCategoria: '',
        descricaoRotuloUnidadeCategoria: '',
        possuiTitularidade: false,
        descricaoRotuloTitularidadePrincipal: '',
        descricaoRotuloTitularidadeSecundario: '',
        possuiGaragem: false,
        tipoGaragem: 'AMBAS',
        agrupaPorUnidade: false,
        agrupaPorCategoria: false,
        agrupaPorTitulo: false,
        habilitaLiberacaoTemporaria: false,
        habilitaLiberacaoExpress: false,
        habilitaGestaoConvite: false,
        habilitaRespUnidade: false,
        habilitaRespCategoria: false,
        habilitaRespTitulo: false,
        responsabilidadesUnidade: state.responsabilidadesUnidadeNovo,
        responsabilidadesCategoria: state.responsabilidadesCategoriaNovo,
        responsabilidadesTitulo: state.responsabilidadesTituloNovo,
        listaDePerfisAutorizados: []
      }
    }
    case BUSCA_LOCAL_CONTROLADO_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false,
        textoFiltro: '',
      }
    }
    case APLICA_FILTRO: {

      const filtroAplicar = action.payload;
      var listaFiltrada = filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.descricaoLocalControlado.toLowerCase().includes(filtroAplicar.toLowerCase())) : [];

      return {
        ...state,
        textoFiltro: filtroAplicar,
        listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
      }
    }
    case INICIO_LOCAL_CONTROLADO_SUCESSO: {


      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
        idClienteFixado: localStorage.getItem('idClienteFixado'),
        idCliente: state.idClienteFixado,
        listGrid: retorno.locaisControlados,
        listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
        listaDeTiposDeLocaisControlados: preparaListaParaCombo(tiposLocal, "id", "title"),
        listaDeTiposDeSincronizacaoBD: preparaListaParaCombo(tiposSincronizacao, "id", "title"),
        nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
        idClienteFixado: localStorage.getItem('idClienteFixado'),
        nomeClienteFiltro: localStorage.getItem('nomeClienteFixado') !== "" && localStorage.getItem('nomeClienteFixado') !== null ? state.nomeClienteFixado : "",
        listGridComFiltro: [],
        listaDePerfis: retorno.perfis,
        textoFiltro: ''
      }
    }
    case SALVA_LOCAL_CONTROLADO: {

      const retorno = action.payload;


      return {
        ...state,
        loader: true,
      }

    }
    case SALVA_LOCAL_CONTROLADO_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_LOCAL_CONTROLADO_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case LISTA_LOCAL_CONTROLADO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_LOCAL_CONTROLADO_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.locais,
      }
    }
    case LIMPAR_LOCAL_CONTROLADO: {

      var retorno = action.payload;

      return {
        ...state,
        nomeClienteFiltro: '',
        inicioControleFiltro: '',
        fimControleFiltro: '',
        nomeLocalFiltro: '',
        tipoLocalFiltro: ''
      }
    }
    case BUSCA_LOCAL_CONTROLADO_EDICAO_SUCESSO: {

      var retorno = action.payload;

      var _listaResponsabilidadesUnidade = retorno.responsabilidades.length > 0 ? retorno.responsabilidades.filter(x => x.tipoResponsabilidadeLocal === 'UNIDADE') : state.responsabilidadesUnidadeNovo;
      var _listaResponsabilidadesCategoria = retorno.responsabilidades.length > 0 ? retorno.responsabilidades.filter(x => x.tipoResponsabilidadeLocal === 'CATEGORIA') : state.responsabilidadesCategoriaNovo;
      var _listaResponsabilidadesTitulo = retorno.responsabilidades.length > 0 ? retorno.responsabilidades.filter(x => x.tipoResponsabilidadeLocal === 'TITULO') : state.responsabilidadesTituloNovo;

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        indiceAba: 0,
        idCliente: retorno.idCliente,
        idTipoEndereco: retorno.idTipoEndereco,
        tipoLocalControlado: retorno.tipoLocalControlado,
        tipoSincronizacaoBD: retorno.tipoSincronizacaoBD,
        descricaoLocalControlado: retorno.descricaoLocalControlado,
        dataInicioControle: retorno.dataInicioControle,
        dataFimControle: retorno.dataFimControle,
        dataEvento: retorno.dataEvento,
        nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
        idClienteFixado: localStorage.getItem('idClienteFixado'),
        caminhoImagemEvento: retorno.caminhoImagemEvento,
        possuiUnidade: retorno.possuiUnidade,
        descricaoRotuloGrupoUnidade: retorno.descricaoRotuloGrupoUnidade,
        descricaoRotuloUnidadeUnidade: retorno.descricaoRotuloUnidadeUnidade,
        possuiCategoria: retorno.possuiCategoria,
        descricaoRotuloGrupoCategoria: retorno.descricaoRotuloGrupoCategoria,
        descricaoRotuloUnidadeCategoria: retorno.descricaoRotuloUnidadeCategoria,
        possuiTitularidade: retorno.possuiTitularidade,
        descricaoRotuloTitularidadePrincipal: retorno.descricaoRotuloTitularidadePrincipal,
        descricaoRotuloTitularidadeSecundario: retorno.descricaoRotuloTitularidadeSecundario,
        possuiGaragem: retorno.possuiGaragem,
        tipoGaragem: retorno.tipoGaragem,
        agrupaPorUnidade: retorno.agrupaPorUnidade,
        agrupaPorCategoria: retorno.agrupaPorCategoria,
        agrupaPorTitulo: retorno.agrupaPorTitulo,
        habilitaLiberacaoTemporaria: retorno.habilitaLiberacaoTemporaria,
        habilitaLiberacaoExpress: retorno.habilitaLiberacaoExpress,
        habilitaGestaoConvite: retorno.habilitaGestaoConvite,
        habilitaRespUnidade: retorno.habilitaRespUnidade,
        habilitaRespCategoria: retorno.habilitaRespCategoria,
        habilitaRespTitulo: retorno.habilitaRespTitulo,
        responsabilidades: retorno.responsabilidades,
        responsabilidadesUnidade: _listaResponsabilidadesUnidade.length === 0 ? state.responsabilidadesUnidadeNovo : _listaResponsabilidadesUnidade,
        responsabilidadesCategoria: _listaResponsabilidadesCategoria.length === 0 ? state.responsabilidadesCategoriaNovo : _listaResponsabilidadesCategoria,
        responsabilidadesTitulo: _listaResponsabilidadesTitulo.length === 0 ? state.responsabilidadesTituloNovo : _listaResponsabilidadesTitulo,
        listaDePerfisAutorizados: retorno.perfisAutorizados,
        unidades: retorno.unidades,
        categorias: retorno.categorias,
        titularidades: retorno.titularidades,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        observacoes: retorno.observacoes,
      }
    }
    case SETAR_IMAGEM_LOCAL_CONTROLADO: {

      var retorno = action.payload;

      return {
        ...state,
        caminhoImagemEvento: retorno.imagem,
      }
    }
    case ATUALIZAR_PERFIL_AUTORIZADO_LOCAL: {
      var retorno = action.payload;
      var arrayAtualizado = [];  

      if(retorno.valor) {
        const perfilAdicionar = state.listaDePerfis.filter(x => x.id === retorno.idAtualizacao)[0];
  
        arrayAtualizado = state.listaDePerfisAutorizados;
        arrayAtualizado = [...arrayAtualizado, perfilAdicionar];
      }
      else {
        arrayAtualizado = state.listaDePerfisAutorizados.filter(x => x.id !== retorno.idAtualizacao)
      }

      return {
        ...state,
        listaDePerfisAutorizados: arrayAtualizado,
      }      
    }
    case CLIQUE_CONTROLE_ABA_LOCAL: {
      var retorno = action.payload;

      return {
        ...state,
        indiceAba: retorno.indice,
      }
    }
    case ADICIONA_RESPONSABILIDADE: {

      const maiorIdTemporarioRespUnidade = state.responsabilidadesUnidade.length > 0 ? state.responsabilidadesUnidade.reduce(
        (max, x) => (x.idTemporario > max ? x.idTemporario : max),
        state.responsabilidadesUnidade[0].idTemporario
      ) : 0;

      const maiorIdTemporarioRespCategoria = state.responsabilidadesCategoria.length > 0 ? state.responsabilidadesCategoria.reduce(
        (max, x) => (x.idTemporario > max ? x.idTemporario : max),
        state.responsabilidadesCategoria[0].idTemporario
      ) : 0;

      const maiorIdTemporarioRespTitulo = state.responsabilidadesTitulo.length > 0 ? state.responsabilidadesTitulo.reduce(
        (max, x) => (x.idTemporario > max ? x.idTemporario : max),
        state.responsabilidadesTitulo[0].idTemporario
      ) : 0;

      const idsTemporarios = [{ id: maiorIdTemporarioRespUnidade }, { id: maiorIdTemporarioRespCategoria }, { id: maiorIdTemporarioRespTitulo }];

      const novoIdTemporario = idsTemporarios.reduce(
        (max, x) => (x.id > max ? x.id : max),
        idsTemporarios[0].id
      ) + 1;

      /////const idTemporario = state.emails.length + 1;
      const tipoResponsabilidade = action.payload;

      const responsabilidadeAdd = {
        "id": 0,
        "idTemporario": novoIdTemporario,
        "descricaoResponsabilidade": '',
        "tipoResponsabilidadeLocal": tipoResponsabilidade,
      }

      return {
        ...state,
        responsabilidadesUnidade: tipoResponsabilidade === 'UNIDADE' ? [...state.responsabilidadesUnidade, responsabilidadeAdd] : state.responsabilidadesUnidade,
        responsabilidadesCategoria: tipoResponsabilidade === 'CATEGORIA' ? [...state.responsabilidadesCategoria, responsabilidadeAdd] : state.responsabilidadesCategoria,
        responsabilidadesTitulo: tipoResponsabilidade === 'TITULO' ? [...state.responsabilidadesTitulo, responsabilidadeAdd] : state.responsabilidadesTitulo,
        responsabilidades: [...state.responsabilidades, responsabilidadeAdd]
      }
    }
    case ATUALIZA_RESPONSABILIDADE: {
      var retorno = action.payload;
      var listaDeResponsabilidades = [];
      var listaDeResponsabilidadesGeral = [];
      var responsabilidadeSelecionada = null;
      var tipoResponsabilidade = '';


      if (retorno.campoAtualizar === 'respUnidade') {
        tipoResponsabilidade = 'UNIDADE';
        listaDeResponsabilidades = state.responsabilidadesUnidade.filter(x => x.idTemporario !== retorno.indice && x.tipoResponsabilidadeLocal === 'UNIDADE');
        responsabilidadeSelecionada = state.responsabilidadesUnidade.filter(x => x.idTemporario === retorno.indice && x.tipoResponsabilidadeLocal === 'UNIDADE');
      }

      if (retorno.campoAtualizar === 'respCategoria') {
        tipoResponsabilidade = 'CATEGORIA';
        listaDeResponsabilidades = state.responsabilidadesCategoria.filter(x => x.idTemporario !== retorno.indice && x.tipoResponsabilidadeLocal === 'CATEGORIA');
        responsabilidadeSelecionada = state.responsabilidadesCategoria.filter(x => x.idTemporario === retorno.indice && x.tipoResponsabilidadeLocal === 'CATEGORIA');
      }

      if (retorno.campoAtualizar === 'respTitulo') {
        tipoResponsabilidade = 'TITULO';
        listaDeResponsabilidades = state.responsabilidadesTitulo.filter(x => x.idTemporario !== retorno.indice && x.tipoResponsabilidadeLocal === 'TITULO');
        responsabilidadeSelecionada = state.responsabilidadesTitulo.filter(x => x.idTemporario === retorno.indice && x.tipoResponsabilidadeLocal === 'TITULO');
      }

      var responsabilidadeAtualizar = {
        "id": responsabilidadeSelecionada[0].id,
        "idTemporario": responsabilidadeSelecionada[0].idTemporario,
        "descricaoResponsabilidade": retorno.valor,
        "tipoResponsabilidadeLocal": responsabilidadeSelecionada[0].tipoResponsabilidadeLocal,
      };

      listaDeResponsabilidades = [...listaDeResponsabilidades, responsabilidadeAtualizar];
      listaDeResponsabilidades = listaDeResponsabilidades.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      listaDeResponsabilidadesGeral = state.responsabilidades;

      if (listaDeResponsabilidadesGeral.filter(x => x.tipoResponsabilidadeLocal === tipoResponsabilidade &&
        x.idTemporario === retorno.indice).length === 0) {
        listaDeResponsabilidadesGeral = [...listaDeResponsabilidadesGeral, responsabilidadeAtualizar]
      }
      else {
        listaDeResponsabilidadesGeral = listaDeResponsabilidadesGeral.filter(x => x.tipoResponsabilidadeLocal !== tipoResponsabilidade);

        for(var indice in listaDeResponsabilidades)
        {
          listaDeResponsabilidadesGeral = [...listaDeResponsabilidadesGeral, listaDeResponsabilidades[indice]];
        }
      }

      /*         var listaDeResponsabilidadesGeral = state.responsabilidades.filter(x => x.tipoResponsabilidadeLocal !== tipoResponsabilidade);
              responsabilidadeSelecionada = state.responsabilidades.filter(x => x.idTemporario === retorno.indice && x.tipoResponsabilidadeLocal === tipoResponsabilidade);  
              
              listaDeResponsabilidadesGeral = [...listaDeResponsabilidadesGeral, responsabilidadeAtualizar];
              listaDeResponsabilidadesGeral = listaDeResponsabilidadesGeral.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario)); */

      return {
        ...state,
        responsabilidadesUnidade: retorno.campoAtualizar === 'respUnidade' ? listaDeResponsabilidades : state.responsabilidadesUnidade,
        responsabilidadesCategoria: retorno.campoAtualizar === 'respCategoria' ? listaDeResponsabilidades : state.responsabilidadesCategoria,
        responsabilidadesTitulo: retorno.campoAtualizar === 'respTitulo' ? listaDeResponsabilidades : state.responsabilidadesTitulo,
        responsabilidades: listaDeResponsabilidadesGeral                   
      }
    }
    case REMOVE_RESPONSABILIDADE: {

      const idRemover = action.payload;

      return {
        ...state,
        responsabilidadesUnidade: state.responsabilidadesUnidade.filter((x) => x.idTemporario !== idRemover),
        responsabilidadesCategoria: state.responsabilidadesCategoria.filter((x) => x.idTemporario !== idRemover),
        responsabilidadesTitulo: state.responsabilidadesTitulo.filter((x) => x.idTemporario !== idRemover),
        responsabilidades: state.responsabilidades.filter((x) => x.idTemporario !== idRemover)
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {

      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_DATEPICKER: {

      var data = state.dataInicioControle;

      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CHECK: {

      var campo = action.payload.nomeCheck[0];
      var estaMarcado = action.payload.valor;

      return {
        ...state,
        descricaoRotuloGrupoUnidade: campo === "possuiUnidade" ? estaMarcado ? state.descricaoRotuloGrupoUnidade : '' : state.descricaoRotuloGrupoUnidade,
        descricaoRotuloUnidadeUnidade: campo === "possuiUnidade" ? estaMarcado ? state.descricaoRotuloUnidadeUnidade : '' : state.descricaoRotuloUnidadeUnidade,
        descricaoRotuloGrupoCategoria: campo === "possuiCategoria" ? estaMarcado ? state.descricaoRotuloGrupoCategoria : '' : state.descricaoRotuloGrupoCategoria,
        descricaoRotuloUnidadeCategoria: campo === "possuiCategoria" ? estaMarcado ? state.descricaoRotuloUnidadeCategoria : '' : state.descricaoRotuloUnidadeCategoria,
        descricaoRotuloTitularidadePrincipal: campo === "possuiTitularidade" ? estaMarcado ? state.descricaoRotuloTitularidadePrincipal : '' : state.descricaoRotuloTitularidadePrincipal,
        descricaoRotuloTitularidadeSecundario: campo === "possuiTitularidade" ? estaMarcado ? state.descricaoRotuloTitularidadeSecundario : '' : state.descricaoRotuloTitularidadeSecundario,        

        [action.payload.nomeCheck]: action.payload.valor,
      }
    }
    case ALTERACAO_COMBO: {
      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
