import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PhoneIcon from '@material-ui/icons/Phone';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CommentIcon from '@material-ui/icons/Comment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { UilEdit } from '@iconscout/react-unicons'

export default class Resumo extends Component {


    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <TableContainer elevation={0} className={classes.padding} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>

                            <TableRow key={1}>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                    <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                        <AssignmentIndIcon style={{ color: '#05B59D' }} />
                                    </Avatar>
                                </TableCell>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#000000DE' }}>Documento</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#00000099' }}>CPF - 432.555.234-32</div>
                                    </div>
                                </TableCell>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div style={{ float: 'left' }}>
                                        <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                            <PhoneIcon style={{ color: '#05B59D' }} />
                                        </Avatar>
                                    </div>
                                    <div style={{ float: 'left' }}>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#000000DE' }}>Telefone</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#00000099' }}>(62) 94984-3245</div>
                                    </div>
                                </TableCell>

                            </TableRow>

                            <TableRow key={2}>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                    <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                        <LockOpenIcon style={{ color: '#05B59D' }} />
                                    </Avatar>
                                </TableCell>
                                <TableCell colSpan={2} align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, paddingBottom: 5, color: '#00000099' }}>Autorizante</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, }}>

                                            <Card style={{ float: 'left', boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)', height: 97, width: '75%' }}>
                                                <CardContent style={{ display: 'flex', paddingBottom: 16 }}>
                                                    <Avatar style={{ width: 64, height: 64, marginRight: 10 }} alt="Remy Sharp" src="https://i.pravatar.cc/400?img=52" />
                                                    <div>
                                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Jussara Almeida Arantes</div>
                                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#00000099' }}>Ipê/302 (titular) (+1)</div>
                                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#00000099' }}>(62) 98573-59348 / 98573-59348</div>
                                                    </div>
                                                </CardContent>
                                            </Card>

                                            <Card style={{ float: 'left', boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)', height: 97, marginLeft: 10, textAlign: 'center' }}>
                                                <CardContent style={{ paddingBottom: 16 }}>
                                                    <AvatarGroup style={{float: 'left', width: 62, textAlign: 'center'}}>
                                                        <Avatar src="https://i.pravatar.cc/400?img=52" />
                                                        <Avatar src="https://i.pravatar.cc/400?img=52" />
                                                        <Avatar src="https://i.pravatar.cc/400?img=52" />
                                                    </AvatarGroup>
                                                    <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, paddingTop: 9, textAlign: 'center' }}>mais 6</div>
                                                </CardContent>
                                            </Card>

                                        </div>
                                    </div>
                                </TableCell>

                            </TableRow>

                            <TableRow key={3}>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                    <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                        <CommentIcon style={{ color: '#05B59D' }} />
                                    </Avatar>
                                </TableCell>
                                <TableCell colSpan={2} align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#000000DE' }}>Observação</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#00000099' }}>Nenhuma observação para esta entrada  <UilEdit size={20} style={{ color: '#00000099' }} /></div>
                                    </div>
                                </TableCell>

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        )
    }

}