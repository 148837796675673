import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    INICIA_PORTARIA, BUSCA_SOLICITACAO, ATUALIZA_MODO_PORTARIA, EXECUTA_SIMULACAO,
    BUSCA_FILA_POSICAO, DECIDIR_ATENDTO_DUPLO_CHECK, SALVAR_LIBERACAO, SINCRONIZAR_LIBERACAO,
    SELECIONA_PESSOA_PORTARIA, ADICIONA_OBSERVACAO_PESSOA_PERFIL, BUSCA_PESSOA_CADASTRADA_LIBERACAO,
    CARREGA_PESSOAS_PARA_TESTES, ENVIA_SOLICITACAO_ABERTURA_TERMINAL, EXIBE_LOTACAO_GARAGENS, 
    INICIA_PORTARIA_LIBERACAO, OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA, APLICA_ALTERACOES_GARAGENS
} from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import { userSignOut, carregaPessoasParaTestesSucesso } from "actions/Auth";
import {
    showAuthMessage,
    inicioPortariasSucesso,
    buscaSolicitacaoSucesso,
    buscaFilaPosicaoSucesso,
    atualizaModoSucesso,
    executaSimulacaoSucesso,
    decidirDuploCheckSucesso,
    exibirMensagemErroPortaria,
    salvaLiberacaoSucesso,
    sincronizaPessoaErro,
    selecionaPessoaPortariaSucesso,
    adicionaObservacaoPerfilSucesso,
    abortaOperacao,
    buscaPEssoaCadastradaLiberacaoSucesso,
    enviaSolicitacaoAberturaTerminalSucesso,
    enviaSolicitacaoAberturaTerminalErro,
    inicioPortariaLiberacaoSucesso,
    analisaResultadoSincronizacao,
    sincronizaLiberacaoSucesso,
    obtemImagemArrayPessoasPortariaSucesso,
    exibeLotacaoGaragensSucesso,
    aplicaAlteracoesGaragensSucesso
} from "actions/PortariaInicio";
import { listaCliente } from "./Cliente";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/

const getAmbientesPortaria = async () =>
    await requisicaoApi.get('/util/ambientesPortaria')
        .then(resposta => resposta)
        .catch(error => error);

const getLotacaoGaragens = async () =>
    await requisicaoApi.get('/util/lotacaoGaragens')
        .then(resposta => resposta)
        .catch(error => error);

const getThumbnailPessoa = async () =>
    await requisicaoApi.get('/usuariosPlataforma/listaThumb')
        .then(resposta => resposta)
        .catch(error => error);

const getPessoaExistentePorCpfLiberacao = async (cpf) =>
    await requisicaoApi.get('/util/pessoaExistente/liberacao/' + cpf)
        .then(resposta => resposta)
        .catch(error => error);

const getAmbientesCliente = async () =>
    await requisicaoApi.get('/util/ambientesPortariaNovo')
        .then(resposta => resposta)
        .catch(error => error);

const getAmbientesPerfilTemporario = async () =>
    await requisicaoApi.get('/util/ambientesPerfilTemporario')
        .then(resposta => resposta)
        .catch(error => error);

const getParametrosCliente = async () =>
    await requisicaoApi.get('/util/parametrosVisibilidadePortaria')
        .then(resposta => resposta)
        .catch(error => error);

const getBuscaSolicitacao = async (idPosicao) =>
    await requisicaoApi.post('/util/solictPortaria/', { "idPosicao": idPosicao })
        .then(resposta => resposta)
        .catch(error => error);

const getBuscaFilaPosicao = async (idPosicao) =>
    await requisicaoApi.get('/util/fila')
        .then(resposta => resposta)
        .catch(error => error);

const getChamadaTerminal = async (idPessoa, idPonto) =>
    await requisicaoApi.post('/seguranca/chamadaTerminal/', { "idUsuario": idPessoa, "idTerminal": idPonto })
        .then(resposta => resposta)
        .catch(error => error);

const getSolicitacaoAberturaTerminal = async (idPessoa, idPonto) =>
    await requisicaoApi.post('/util/aberturaTerminal/', { "idUsuario": idPessoa, "idTerminal": idPonto })
        .then(resposta => resposta)
        .catch(error => error);

const getUsuariosPlataforma = async () =>
    await requisicaoApi.get('/usuariosPlataforma')
        .then(resposta => resposta)
        .catch(error => error);

const getPessoasPortaria = async () =>
    await requisicaoApi.get('/util/pessoasPortaria')
        .then(resposta => resposta)
        .catch(error => error);

const getPessoasTeste = async () =>
    await requisicaoApi.get('/util/pessoasTeste')
        .then(resposta => resposta)
        .catch(error => error);

const getListaPerfisTemporarios = async () =>
    await requisicaoApi.get('/perfis/cliente/temporario')
        .then(resposta => resposta)
        .catch(error => error);

const getLiberacoes = async () =>
    await requisicaoApi.get('/liberacoes')
        .then(resposta => resposta)
        .catch(error => error);

const getObtemListasPessoaPortaria = async (idPessoa,) =>
    await requisicaoApi.get('/util/listas/pessoa/' + idPessoa)
        .then(resposta => resposta)
        .catch(error => error);

const getObtemListasPessoaPortariaComPontos = async (idPessoa, listaDePontos) =>
    await requisicaoApi.get('/util/listas/pessoa/' + idPessoa + '/' + listaDePontos)
        .then(resposta => resposta)
        .catch(error => error);


/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/

function* fnIniciaPortaria() {
    try {
        ////const ambientesPortaria = yield call(getAmbientesPortaria);
        const ambientesCliente = yield call(getAmbientesCliente);
        const ambientesPerfilTemporario = yield call(getAmbientesPerfilTemporario);

        if (ambientesCliente.data) {

            const usuariosPlataforma = yield call(getPessoasPortaria);
            const perfisTemporarios = yield call(getListaPerfisTemporarios);
            const liberacoes = yield call(getLiberacoes);
            const dadosCliente = yield call(getParametrosCliente);

            if (usuariosPlataforma.data) {
                yield put(inicioPortariasSucesso(/*ambientesPortaria.data, */ambientesCliente.data, usuariosPlataforma.data, perfisTemporarios.data, liberacoes.data, ambientesPerfilTemporario.data, dadosCliente.data));
            }
            else {
                yield put(showAuthMessage('Ocorreu um erro ao listar Pessoas'));
            }

        }
        else {
            if (ambientesCliente.response.status === 401 && ambientesCliente.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
                yield put(userSignOut());
            }
            else {
                yield put(showAuthMessage('Ocorreu um erro ao listar Pessoas'));
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnObtemImagemArrayPessoasPortaria({ payload }) {
    try {
        const array = payload.array;
        const imagensThumbs = yield call(getThumbnailPessoa);

        if (!imagensThumbs.isAxiosError && imagensThumbs.data) {
            yield put(obtemImagemArrayPessoasPortariaSucesso(imagensThumbs.data));
        }
        else {
            ////yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + id));
        }
    } catch (error) {
        ////yield put(showAuthMessage(error));
    }
}

function* fnIniciaPortariaLiberacao() {
    try {
        const usuariosPlataforma = yield call(getPessoasPortaria);
        const liberacoes = yield call(getLiberacoes);

        if (usuariosPlataforma.data) {
            yield put(inicioPortariaLiberacaoSucesso(usuariosPlataforma.data, liberacoes.data));
        }
        else {
            yield put(showAuthMessage('Ocorreu um erro ao listar Pessoas'));
        }


    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnCarregaPessoasParaTestes() {
    try {
        const ambientesPortaria = yield call(getAmbientesPortaria);
        const usuariosPlataforma = yield call(getPessoasTeste);

        if (usuariosPlataforma.data) {
            yield put(carregaPessoasParaTestesSucesso(ambientesPortaria.data, usuariosPlataforma.data));
        }
        else {
            yield put(showAuthMessage('Ocorreu um erro ao listar Pessoas'));
        }

    }
    catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnExibeLotacaoGaragens() {
    try {
        const lotacaoGaragens = yield call(getLotacaoGaragens);

        if (lotacaoGaragens.data) {
            yield put(exibeLotacaoGaragensSucesso(lotacaoGaragens.data));
        }
        else {
            yield put(showAuthMessage('Ocorreu um erro ao listar Pessoas'));
        }

    }
    catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnAplicaAlteracoesGaragens({ payload }) {
    try {
        var lotacoesGaragemDesocupar = payload.lotacoesGaragemDesocupar;
        const retorno = yield call(atualizaGaragensRequest, lotacoesGaragemDesocupar);

        if (!retorno.isAxiosError && retorno.data) {
            yield put(aplicaAlteracoesGaragensSucesso());
        }

    }
    catch (error) {
        yield put(showAuthMessage(error));
    }
}

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/

const adicionaPosicaoRequest = async (idPosicao, idAmbientePortaria, modo, pontosAcessoSelecionados) => await requisicaoApi.post('/posicoesAtendto',
    {
        "idAmbientePortaria": idAmbientePortaria,
        "situacaoPosicaoAtendimento": modo === 'Trabalho' ? "EM_ESCUTA" : "ATIVADO",
        "id": idPosicao,
        "posicoesPontosAcesso": pontosAcessoSelecionados
    })
    .then(resposta => resposta)
    .catch(error => error);

const atualizaPosicaoRequest = async (idPosicao, idAmbientePortaria, modo, pontosAcessoSelecionados) => await requisicaoApi.put('/posicoesAtendto',
    {
        "idAmbientePortaria": idAmbientePortaria,
        "situacaoPosicaoAtendimento": modo === 'Trabalho' ? "EM_ESCUTA" : "PARALISADO",
        "id": idPosicao,
        "posicoesPontosAcesso": pontosAcessoSelecionados
    })
    .then(resposta => resposta)
    .catch(error => error);

const atualizaGaragensRequest = async (lotacoesGaragemDesocupar) => await requisicaoApi.put('/util/garagens',
    {
        "idsDesocupar": lotacoesGaragemDesocupar
    })
    .then(resposta => resposta)
    .catch(error => error);    

const adicionaObservacaoPerfilRequest = async (observacao, idPessoa) => await requisicaoApi.post('/observacoes',
    {
        "observacao": observacao,
        "idPessoaObservada": idPessoa
    })
    .then(resposta => resposta)
    .catch(error => error);

const adicionaLiberacaoRequest = async (liberacao) => await requisicaoApi.post('/liberacoes',
    {
        "id": 0,
        "idPessoaLiberada": liberacao.idPessoaLiberada,
        "idPessoaAutorizLiberacao": liberacao.pessoaSelecionada.idPessoa,
        "descricaoNomeRegistroPrevio": liberacao.descricaoNomeRegistroPrevio,
        "descricaoNumeroCPFRegistroPrevio": liberacao.descricaoNumeroCPFRegistroPrevio,
        "descricaoNumeroDocumentoRegistroPrevio": liberacao.descricaoNumeroDocumentoRegistroPrevio,
        "tipoDocumentoRegistroPrevio": liberacao.tipoDocumentoRegistroPrevio,
        "sexoRegistroPrevio": liberacao.sexoRegistroPrevio,
        "DataNascimentoRegistroPrevioStr": liberacao.dataNascimentoRegistroPrevio,
        "numeroTelefoneRegistroPrevio": liberacao.numeroTelefoneRegistroPrevio,
        "imagemPessoaLiberacao": liberacao.imagemCapturadaPessoa,
        "imagemPessoaThumb": liberacao.imagemCapturadaPessoaThumb,
        "imagemDocumentoLiberacao": liberacao.imagemCapturadaDocumento,
        "idPerfilSistema": liberacao.idPerfilSistema,
        "idAmbienteOrigem": liberacao.idAmbienteOrigem,
        "idPontoAcessoOrigem": liberacao.idPontoAcessoOrigem,
        "idAmbienteDestino": liberacao.idAmbienteDestino,
        "descricaoCargo": liberacao.descricaoCargo,
        "descricaoEmpresa": liberacao.descricaoEmpresa,
        "ehFluxoIlimitado": liberacao.ehFluxoIlimitado,
        "permiteAcompanhantes": liberacao.permiteAcompanhantes,
        "periodoLiberacaoInicioStr": liberacao.periodoLiberacaoInicio,
        "periodoLiberacaoFimStr": liberacao.periodoLiberacaoFim,
        "observacoes": liberacao.observacoes,
        "statusLiberacao": 'P',
        "horarioInicial": liberacao.horarioInicial,
        "horarioFinal": liberacao.horarioFinal,
        "somenteSegunda": liberacao.periodoEspecificoSegunda,
        "somenteTerca": liberacao.periodoEspecificoTerca,
        "somenteQuarta": liberacao.periodoEspecificoQuarta,
        "somenteQuinta": liberacao.periodoEspecificoQuinta,
        "somenteSexta": liberacao.periodoEspecificoSexta,
        "somenteSabado": liberacao.periodoEspecificoSabado,
        "somenteDomingo": liberacao.periodoEspecificoDomingo,
    })
    .then(resposta => resposta)
    .catch(error => error);

const atualizaLiberacaoRequest = async (liberacao) => await requisicaoApi.put('/liberacoes',
    {
        "id": liberacao.idLiberacao,
        "idPessoaLiberada": liberacao.idPessoaLiberada,
        "idPessoaAutorizLiberacao": liberacao.idPessoaAutorizante,
        "descricaoNomeRegistroPrevio": liberacao.descricaoNomeRegistroPrevio,
        "descricaoNumeroCPFRegistroPrevio": liberacao.descricaoNumeroCPFRegistroPrevio,
        "descricaoNumeroDocumentoRegistroPrevio": liberacao.descricaoNumeroDocumentoRegistroPrevio,
        "tipoDocumentoRegistroPrevio": liberacao.tipoDocumentoRegistroPrevio,
        "sexoRegistroPrevio": liberacao.sexoRegistroPrevio,
        "DataNascimentoRegistroPrevioStr": liberacao.dataNascimentoRegistroPrevio,
        "numeroTelefoneRegistroPrevio": liberacao.numeroTelefoneRegistroPrevio,
        "imagemPessoaLiberacao": liberacao.imagemCapturadaPessoa,
        "imagemPessoaThumb": liberacao.imagemCapturadaPessoaThumb,
        "imagemDocumentoLiberacao": liberacao.imagemCapturadaDocumento,
        "idPerfilSistema": liberacao.idPerfilSistema,
        "idAmbienteOrigem": liberacao.idAmbienteOrigem,
        "idPontoAcessoOrigem": liberacao.idPontoAcessoOrigem,
        "idAmbienteDestino": liberacao.idAmbienteDestino,
        "descricaoCargo": liberacao.descricaoCargo,
        "descricaoEmpresa": liberacao.descricaoEmpresa,
        "ehFluxoIlimitado": liberacao.ehFluxoIlimitado,
        "permiteAcompanhantes": liberacao.permiteAcompanhantes,
        "periodoLiberacaoInicioStr": liberacao.periodoLiberacaoInicio,
        "periodoLiberacaoFimStr": liberacao.periodoLiberacaoFim,
        "observacoes": liberacao.observacoes,
        "statusLiberacao": 'P',
        "horarioInicial": liberacao.horarioInicial,
        "horarioFinal": liberacao.horarioFinal,
        "somenteSegunda": liberacao.periodoEspecificoSegunda,
        "somenteTerca": liberacao.periodoEspecificoTerca,
        "somenteQuarta": liberacao.periodoEspecificoQuarta,
        "somenteQuinta": liberacao.periodoEspecificoQuinta,
        "somenteSexta": liberacao.periodoEspecificoSexta,
        "somenteSabado": liberacao.periodoEspecificoSabado,
        "somenteDomingo": liberacao.periodoEspecificoDomingo,
    })
    .then(resposta => resposta)
    .catch(error => error);

const adicionaSincLiberacaoRequest = async (liberacao) => await requisicaoApi.post('/liberacoes/Sincronizacao',
    {
        "id": 0,
        "idPessoaLiberada": liberacao.idPessoaLiberada,
        "idPessoaAutorizLiberacao": liberacao.pessoaSelecionada.idPessoa,
        "descricaoNomeRegistroPrevio": liberacao.descricaoNomeRegistroPrevio,
        "descricaoNumeroCPFRegistroPrevio": liberacao.descricaoNumeroCPFRegistroPrevio,
        "descricaoNumeroDocumentoRegistroPrevio": liberacao.descricaoNumeroDocumentoRegistroPrevio,
        "tipoDocumentoRegistroPrevio": liberacao.tipoDocumentoRegistroPrevio,
        "sexoRegistroPrevio": liberacao.sexoRegistroPrevio,
        "DataNascimentoRegistroPrevioStr": liberacao.dataNascimentoRegistroPrevio,
        "numeroTelefoneRegistroPrevio": liberacao.numeroTelefoneRegistroPrevio,
        "imagemPessoaLiberacao": liberacao.imagemCapturadaPessoa,
        "imagemPessoaThumb": liberacao.imagemCapturadaPessoaThumb,
        "imagemDocumentoLiberacao": liberacao.imagemCapturadaDocumento,
        "idPerfilSistema": liberacao.idPerfilSistema,
        "idAmbienteOrigem": liberacao.idAmbienteOrigem,
        "idPontoAcessoOrigem": liberacao.idPontoAcessoOrigem,
        "idAmbienteDestino": liberacao.idAmbienteDestino,
        "descricaoCargo": liberacao.descricaoCargo,
        "descricaoEmpresa": liberacao.descricaoEmpresa,
        "ehFluxoIlimitado": liberacao.ehFluxoIlimitado,
        "permiteAcompanhantes": liberacao.permiteAcompanhantes,
        "periodoLiberacaoInicioStr": liberacao.periodoLiberacaoInicio,
        "periodoLiberacaoFimStr": liberacao.periodoLiberacaoFim,
        "observacoes": liberacao.observacoes,
        "statusLiberacao": 'S',
        "horarioInicial": liberacao.horarioInicial,
        "horarioFinal": liberacao.horarioFinal,
        "somenteSegunda": liberacao.periodoEspecificoSegunda,
        "somenteTerca": liberacao.periodoEspecificoTerca,
        "somenteQuarta": liberacao.periodoEspecificoQuarta,
        "somenteQuinta": liberacao.periodoEspecificoQuinta,
        "somenteSexta": liberacao.periodoEspecificoSexta,
        "somenteSabado": liberacao.periodoEspecificoSabado,
        "somenteDomingo": liberacao.periodoEspecificoDomingo
    })
    .then(resposta => resposta)
    .catch(error => error);

const atualizaSincLiberacaoRequest = async (liberacao) => await requisicaoApi.put('/liberacoes/Sincronizacao',
    {
        "id": liberacao.idLiberacao,
        "idPessoaLiberada": liberacao.idPessoaLiberada,
        "idPessoaAutorizLiberacao": liberacao.idPessoaAutorizante,
        "descricaoNomeRegistroPrevio": liberacao.descricaoNomeRegistroPrevio,
        "descricaoNumeroCPFRegistroPrevio": liberacao.descricaoNumeroCPFRegistroPrevio,
        "descricaoNumeroDocumentoRegistroPrevio": liberacao.descricaoNumeroDocumentoRegistroPrevio,
        "tipoDocumentoRegistroPrevio": liberacao.tipoDocumentoRegistroPrevio,
        "sexoRegistroPrevio": liberacao.sexoRegistroPrevio,
        "DataNascimentoRegistroPrevioStr": liberacao.dataNascimentoRegistroPrevio,
        "numeroTelefoneRegistroPrevio": liberacao.numeroTelefoneRegistroPrevio,
        "imagemPessoaLiberacao": liberacao.imagemCapturadaPessoa,
        "imagemPessoaThumb": liberacao.imagemCapturadaPessoaThumb,
        "imagemDocumentoLiberacao": liberacao.imagemCapturadaDocumento,
        "idPerfilSistema": liberacao.idPerfilSistema,
        "idAmbienteOrigem": liberacao.idAmbienteOrigem,
        "idPontoAcessoOrigem": liberacao.idPontoAcessoOrigem,
        "idAmbienteDestino": liberacao.idAmbienteDestino,
        "descricaoCargo": liberacao.descricaoCargo,
        "descricaoEmpresa": liberacao.descricaoEmpresa,
        "ehFluxoIlimitado": liberacao.ehFluxoIlimitado,
        "permiteAcompanhantes": liberacao.permiteAcompanhantes,
        "periodoLiberacaoInicioStr": liberacao.periodoLiberacaoInicio,
        "periodoLiberacaoFimStr": liberacao.periodoLiberacaoFim,
        "observacoes": liberacao.observacoes,
        "statusLiberacao": 'S',
        "horarioInicial": liberacao.horarioInicial,
        "horarioFinal": liberacao.horarioFinal,
        "somenteSegunda": liberacao.periodoEspecificoSegunda,
        "somenteTerca": liberacao.periodoEspecificoTerca,
        "somenteQuarta": liberacao.periodoEspecificoQuarta,
        "somenteQuinta": liberacao.periodoEspecificoQuinta,
        "somenteSexta": liberacao.periodoEspecificoSexta,
        "somenteSabado": liberacao.periodoEspecificoSabado,
        "somenteDomingo": liberacao.periodoEspecificoDomingo

    })
    .then(resposta => resposta)
    .catch(error => error);

const decidirDuploCheckRequest = async (idAtendto, permitido) => await requisicaoApi.put('/util/duploCheck',
    {
        "idAtendto": idAtendto,
        "permitido": permitido
    })
    .then(resposta => resposta)
    .catch(error => error);

function* fnAtualizaModoPortaria({ payload }) {
    try {

        const posicao = payload.idPosicaoPortaria;
        const ambientePortaria = payload.idAmbientePortaria;
        const modo = payload.modo;
        const pontosAcessoSelecionados = payload.pontosAcessoSelecionados
        var resultado = "";

        if (posicao === 0) {
            const retorno = yield call(adicionaPosicaoRequest, posicao, ambientePortaria, modo, pontosAcessoSelecionados);
            resultado = retorno;
        }
        else {
            const retorno = yield call(atualizaPosicaoRequest, posicao, ambientePortaria, modo, pontosAcessoSelecionados);
            resultado = retorno;
        }

        if (!resultado.isAxiosError && resultado.data) {
            if (resultado.data) {
                yield put(atualizaModoSucesso(modo, posicao === 0 ? resultado.data.id : posicao));
            }
            else {
                yield put(showAuthMessage(resultado.data.mensagem));
            }
        }
        else {
            showAuthMessage("Ocorreu um erro inesperado ao ativar o Modo selecionado");
        }


    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnDecidirDuploCheck({ payload }) {
    try {

        const idAtendto = payload.idAtendto;
        const permitido = payload.permitido;
        var resultado = "";

        const retorno = yield call(decidirDuploCheckRequest, idAtendto, permitido);
        resultado = retorno;

        if (!resultado.isAxiosError && resultado.data) {
            if (resultado.data) {
                yield put(decidirDuploCheckSucesso(idAtendto));
            }
            else {
                yield put(showAuthMessage(resultado.data.mensagem));
            }
        }
        else {
            showAuthMessage("Ocorreu um erro inesperado ao ativar o Modo selecionado");
        }


    } catch (error) {
        yield put(showAuthMessage(error));
    }
}



function* fnSincronizarLiberacao({ payload }) {
    try {
        const liberacao = payload;
        var resultado = "";

        if (liberacao.idLiberacao === 0) {
            const retorno = yield call(adicionaSincLiberacaoRequest, liberacao);
            resultado = retorno;
        }
        else {
            const retorno = yield call(atualizaSincLiberacaoRequest, liberacao);
            resultado = retorno;
        }

        if (!resultado.isAxiosError && resultado.data) {

            if (resultado.data && resultado.data.id === 0) {

                if (resultado.data.tentativaSincronizacaoMalSucedida) {
                    yield put(sincronizaPessoaErro('', resultado.data, null));
                }
                else {
                    yield put(sincronizaLiberacaoSucesso('', resultado.data, resultado.data.idPessoaAutorizLiberacao, null));
                }
            }
            else {
                if (resultado.data.tentativaSincronizacaoMalSucedida) {
                    yield put(sincronizaPessoaErro('', resultado.data, null));
                }
                else {
                    yield put(sincronizaLiberacaoSucesso('', resultado.data, resultado.data.idPessoaAutorizLiberacao, null));
                }
            }
        }
        else {
            yield put(sincronizaPessoaErro('', null, null));
        }

    } catch (error) {
        yield put(sincronizaPessoaErro('', null, null));
    }
}

function* fnSalvarLiberacao({ payload }) {
    /*     try { */
    const liberacao = payload;
    var resultado = "";

    if (liberacao.idLiberacao === 0) {
        const retorno = yield call(adicionaLiberacaoRequest, liberacao);
        resultado = retorno;
    }
    else {
        const retorno = yield call(atualizaLiberacaoRequest, liberacao);
        resultado = retorno;
    }

    if (!resultado.isAxiosError && resultado.data) {

        if (resultado.data && liberacao.idLiberacao === 0) {
            yield put(salvaLiberacaoSucesso('Inclusão realizada com sucesso!', resultado.data, liberacao.pessoaSelecionada.idPessoa, liberacao.pontosSelecionadosPortaria));
        }
        else {
            yield put(salvaLiberacaoSucesso(resultado.data.mensagem, liberacao, liberacao.idPessoaAutorizante, liberacao.pontosSelecionadosPortaria));
        }
    }
    else {
        if (resultado.response.data) {
            if (resultado.response.data.inconsistencias.length > 0) {
                const inconsistencia = resultado.response.data.inconsistencias[0];
                var propCorrigir = inconsistencia.propriedade;
                var indexPonto = propCorrigir.indexOf(".");
                propCorrigir = propCorrigir.substr(indexPonto + 1);

                yield put(exibirMensagemErroPortaria(inconsistencia.mensagem + " - " + propCorrigir));
            }
            else {
                yield put(exibirMensagemErroPortaria("Ocorreu um erro interno."));
            }
        }
        else {
            yield put(exibirMensagemErroPortaria("Ocorreu um erro interno."));
        }
    }

    /*     } catch (error) {
            yield put(exibirMensagemErroPortaria(error));
        } */
}

function* fnAdicionaObservacaoPerfil({ payload }) {
    try {
        const observacao = payload.observacao;
        const idPessoa = payload.idPessoa;
        var resultado = "";

        const retorno = yield call(adicionaObservacaoPerfilRequest, observacao, idPessoa);
        resultado = retorno;

        if (!resultado.isAxiosError && resultado.data) {

            if (resultado.data) {
                yield put(adicionaObservacaoPerfilSucesso(resultado.data));
            }
            else if (resultado.data.sucesso) {
                yield put(salvaLiberacaoSucesso(resultado.data.mensagem, null));
            }
            else {
                yield put(exibirMensagemErroPortaria(resultado.data.mensagem));
            }
        }
        else {
            if (resultado.response.data) {
                if (resultado.response.data.inconsistencias.length > 0) {
                    const inconsistencia = resultado.response.data.inconsistencias[0];
                    var propCorrigir = inconsistencia.propriedade;
                    var indexPonto = propCorrigir.indexOf(".");
                    propCorrigir = propCorrigir.substr(indexPonto + 1);

                    yield put(exibirMensagemErroPortaria(inconsistencia.mensagem + " - " + propCorrigir));
                }
                else {
                    yield put(exibirMensagemErroPortaria("Ocorreu um erro interno."));
                }
            }
            else {
                yield put(exibirMensagemErroPortaria("Ocorreu um erro interno."));
            }
        }

    } catch (error) {
        yield put(exibirMensagemErroPortaria(error));
    }
}

function* fnBuscaListasPessoa({ payload }) {
    try {
        const idPessoa = payload.idPessoa;
        const listaDePontos = payload.listaDePontos;
        const retorno = listaDePontos !== '' ? yield call(getObtemListasPessoaPortariaComPontos, idPessoa, listaDePontos) : yield call(getObtemListasPessoaPortaria, idPessoa);

        if (retorno.data) {
            yield put(selecionaPessoaPortariaSucesso(idPessoa, retorno.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnBuscaPEssoaCadastradaLiberacao({ payload }) {
    try {
        const cpfPessoa = payload.cpfPessoa;
        const retorno = yield call(getPessoaExistentePorCpfLiberacao, cpfPessoa);

        if (retorno.status === 204) {
            yield put(abortaOperacao());
        }

        if (retorno.data) {
            yield put(buscaPEssoaCadastradaLiberacaoSucesso(retorno.data));
        }

    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnBuscaSolicitacao({ payload }) {
    try {
        const idPosicao = payload.idPosicao;
        const retorno = yield call(getBuscaSolicitacao, idPosicao);

        if (retorno.data) {
            yield put(buscaSolicitacaoSucesso(retorno.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnBuscaFilaPosicao({ payload }) {
    try {
        const idPosicao = payload.idPosicao;
        const retorno = yield call(getBuscaFilaPosicao, idPosicao);

        if (retorno.data) {
            yield put(buscaFilaPosicaoSucesso(retorno.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnExecutaSimulacao({ payload }) {
    /*     try { */
    const idPessoa = payload.idPessoa;
    const idPonto = payload.idPonto;
    const retorno = yield call(getChamadaTerminal, idPessoa, idPonto);
    const resposta = retorno.data ? retorno.data.passagem.permitida : retorno.response.data.passagem.permitida;

    yield put(executaSimulacaoSucesso(resposta, idPonto));

    /*     } catch (error) {
            yield put(showAuthMessage(error));
        } */
}

function* fnEnviaSolicitacaoAberturaTerminal({ payload }) {
    /*     try { */
    const idPessoa = payload.idPessoa;
    const idPonto = payload.idPonto;
    const retorno = yield call(getSolicitacaoAberturaTerminal, idPessoa, idPonto);

    var resultado = retorno;

    if (!resultado.isAxiosError && resultado.data) {
        if (resultado.data) {
            yield put(enviaSolicitacaoAberturaTerminalSucesso(resultado));
        }
        else {
            yield put(showAuthMessage(resultado.data.mensagem));
        }
    }
    else {
        yield put(enviaSolicitacaoAberturaTerminalErro());
    }

    /*     } catch (error) {
            yield put(showAuthMessage(error));
        } */
}



/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/

export function* iniciaPortaria() {
    yield takeEvery(INICIA_PORTARIA, fnIniciaPortaria);
}

export function* exibeLotacaoGaragens() {
    yield takeEvery(EXIBE_LOTACAO_GARAGENS, fnExibeLotacaoGaragens)
}

export function* aplicaAlteracoesGaragens() {
    yield takeEvery(APLICA_ALTERACOES_GARAGENS, fnAplicaAlteracoesGaragens)
}

export function* iniciaPortariaLiberacao() {
    yield takeEvery(INICIA_PORTARIA_LIBERACAO, fnIniciaPortariaLiberacao);
}

export function* carregaPessoasParaTeste() {
    yield takeEvery(CARREGA_PESSOAS_PARA_TESTES, fnCarregaPessoasParaTestes)
}

export function* buscaPessoaCadastradaLiberacao() {
    yield takeEvery(BUSCA_PESSOA_CADASTRADA_LIBERACAO, fnBuscaPEssoaCadastradaLiberacao);
}


export function* buscaFilaPosicao() {
    yield takeEvery(BUSCA_FILA_POSICAO, fnBuscaFilaPosicao);
}

export function* decidirDuploCheck() {
    yield takeEvery(DECIDIR_ATENDTO_DUPLO_CHECK, fnDecidirDuploCheck)
}

export function* salvarLiberacao() {
    yield takeEvery(SALVAR_LIBERACAO, fnSalvarLiberacao)
}

export function* adicionaObservacaoPerfil() {
    yield takeEvery(ADICIONA_OBSERVACAO_PESSOA_PERFIL, fnAdicionaObservacaoPerfil)
}

export function* enviaSolicitacaoAberturaTerminal() {
    yield takeEvery(ENVIA_SOLICITACAO_ABERTURA_TERMINAL, fnEnviaSolicitacaoAberturaTerminal)
}

export function* obtemImagemArrayPessoasPortaria() {
    yield takeEvery(OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA, fnObtemImagemArrayPessoasPortaria)
}

export function* sincronizarLiberacao() {
    yield takeEvery(SINCRONIZAR_LIBERACAO, fnSincronizarLiberacao)
}

export function* atualizaModoPortaria() {
    yield takeEvery(ATUALIZA_MODO_PORTARIA, fnAtualizaModoPortaria);
}

export function* buscaSolicitacao() {
    yield takeEvery(BUSCA_SOLICITACAO, fnBuscaSolicitacao);
}

export function* executaSimulacao() {
    yield takeEvery(EXECUTA_SIMULACAO, fnExecutaSimulacao);
}

export function* buscaListasPessoa() {
    yield takeEvery(SELECIONA_PESSOA_PORTARIA, fnBuscaListasPessoa);
}




/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/

export default function* rootSaga() {
    yield all([fork(iniciaPortaria),
    fork(atualizaModoPortaria),
    fork(buscaSolicitacao),
    fork(executaSimulacao),
    fork(buscaFilaPosicao),
    fork(decidirDuploCheck),
    fork(salvarLiberacao),
    fork(sincronizarLiberacao),
    fork(buscaListasPessoa),
    fork(adicionaObservacaoPerfil),
    fork(carregaPessoasParaTeste),
    fork(buscaPessoaCadastradaLiberacao),
    fork(enviaSolicitacaoAberturaTerminal),
    fork(iniciaPortariaLiberacao),
    fork(obtemImagemArrayPessoasPortaria),
    fork(exibeLotacaoGaragens),
    fork(aplicaAlteracoesGaragens),
    ]);
}
