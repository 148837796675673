import React, { Component } from 'react';
import { connect } from 'react-redux';
import { iniciaPessoas, iniciaLiberacoes, onChangeValue, novaLiberacao, voltarInicioPortaria, selecionaLiberacao,
  adicionaObservacaoPessoaPerfil, exibeOcultaObservacoesPerfilPessoa, enviaSolicitacaoAberturaTerminal } from 'actions/PortariaInicio';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import Sidebar from './partials/_sidebar'
import Conteudo from './partials/_conteudo'
import { selecionaPessoa } from 'actions/PortariaInicio';


const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const options = ['Ações', 'Squash and merge', 'Rebase and merge'];

const handleChange = (event, newValue) => {
  //setValue(newValue);
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    ////backgroundColor: theme.palette.background.paper,
  },
  firstIcon: {
    paddingLeft: 70
  },
  labelContainer: {
    width: "auto",
  },
  labelIcon: {
    minHeight: 0
  },
  iconLabelWrapper: {
    flexDirection: "row"
  },
  iconLabelWrapper2: {
    flexDirection: "row-reverse"
  },
  padding: {
    paddingTop: 0,
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


class Perfil extends Component {
  state = {
    open: false,
    setOpen: false,
    selectedIndex: 0,
    setSelectedIndex: 0,
    anchorRef: null,
    value: 0,
    indexTab: 0,
  }

  handleChangeTab = (event, indexTab) => {
    this.setState({ indexTab });
  };

  handleClick = () => {
    console.info(`You clicked ${options[this.state.selectedIndex]}`);
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ setSelectedIndex: index })
    this.setState({ setOpen: false })
  };

  handleToggle = () => {
    this.setState({ setOpen: (prevOpen) => !prevOpen })
  };

  handleClose = (event) => {
    if (this.state.nchorRef.current && this.state.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ setOpen: false })
  };

  render() {
    const inicio = this.props;
    const pessoaSelecionada = this.props.pessoaSelecionada;
    const portariaInicio = this.props;    
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className="app-wrapper " style={{ padding: 15, width: '100%' }}>
        <div className="animated slideInUpTiny animation-duration-3">

          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Sidebar {...this.props} />
              </Grid>
              <Grid item lg={9} md={9} sm={9} xs={9}>
                <Conteudo {...this.props} />
              </Grid>
            </Grid>
          </div>

         </div>
      </div>
    );
  }
}

Perfil.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ inicio, portariaInicio, settings}) => {
  return { inicio, portariaInicio, settings }
};

export default connect(mapStateToProps, {
  selecionaPessoa,
  iniciaPessoas,
  iniciaLiberacoes,
  novaLiberacao,
  adicionaObservacaoPessoaPerfil,
  onChangeValue,
  voltarInicioPortaria,
  exibeOcultaObservacoesPerfilPessoa,
  selecionaLiberacao,
  enviaSolicitacaoAberturaTerminal
})(withStyles(styles)(Perfil));

