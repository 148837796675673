import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { LuxFwTabContainer } from 'luxFw/palettes/Structure';
import { RiKey2Fill } from "@react-icons/all-files/ri/RiKey2Fill";
import { FiUnlock } from "@react-icons/all-files/fi/FiUnlock";
import { BiHistory } from "@react-icons/all-files/bi/BiHistory";
import Acesso from './_acessos';
import Convidado from './_convidados';
import Registro from './_registros';
import { Scrollbars } from 'react-custom-scrollbars';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = theme => ({
    root: {
        flexGrow: 1,
        ////backgroundColor: theme.palette.background.paper,
    },
    firstIcon: {
        paddingLeft: 70
    },
    labelContainer: {
        width: "auto",
        textTransform: 'none'
    },
    labelIcon: {
        minHeight: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif',
        textTransform: 'none'
    },
    iconLabelWrapper2: {
        flexDirection: "row-reverse"
    },
    padding: {
        paddingTop: 0,
    }
});

class Listagens extends Component {
    constructor() {
        super();
        this.state = { indexTab: 0 };
    }

    handleChangeTab = (indexTab) => {
        this.setState({ indexTab });
    };

    render() {

        const carregandoPaginaPerfil = this.props.portariaInicio.carregandoPaginaPerfil;
        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        var imagemTratada = pessoaSelecionada.imagem;
        imagemTratada = imagemTratada !== undefined && imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;
        const { classes } = this.props;

        return carregandoPaginaPerfil ? (
            <Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: 15, borderRadius: 15 }} >
                            <AppBar elevation={0} style={{ backgroundColor: 'white' }} position="static">
                                {/* <Tabs value={0} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto"> */}
                                <Tabs value={this.state.indexTab} /* onChange={this.handleChangeTab} */ style={{ backgroundColor: '#ffffff', WebkitBoxShadow: 'none', boxShadow: 'none !important', borderBottom: '1px solid #e8e8e8' }} indicatorColor="#ffffff" textColor="primary" variant="standart" scrollButtons="auto">
                                    <Tab label={<Skeleton variant="text" width={'90%'} height={35} />} />
                                    <Tab label={<Skeleton variant="text" width={'90%'} height={35} />} />
                                    <Tab label={<Skeleton variant="text" width={'90%'} height={35} />} />
                                </Tabs>
                            </AppBar>

                            <Fragment>
                                <Scrollbars
                                    style={{ height: 280 }}>    

                                    {this.state.indexTab === 0 &&
                                        <LuxFwTabContainer>
                                            <Acesso {...this.props} />
                                        </LuxFwTabContainer>}

                                    {this.state.indexTab === 1 &&
                                        <LuxFwTabContainer>
                                            <Convidado {...this.props} />
                                        </LuxFwTabContainer>}

                                    {this.state.indexTab === 2 &&
                                        <LuxFwTabContainer>
                                            <Registro {...this.props} />
                                        </LuxFwTabContainer>}
                                </Scrollbars>
                            </Fragment>


                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        ) : 
        (
            <Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: 15, borderRadius: 15 }} >
                            <AppBar elevation={0} style={{ backgroundColor: 'white' }} position="static" color='#05B59D'>
                                {/* <Tabs value={0} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto"> */}
                                <Tabs value={this.state.indexTab} /* onChange={this.handleChangeTab} */ style={{ backgroundColor: '#ffffff', WebkitBoxShadow: 'none', boxShadow: 'none !important', borderBottom: '1px solid #e8e8e8' }} indicatorColor="primary" textColor="primary" variant="standart" scrollButtons="auto">
                                    <Tab onClick={() => this.handleChangeTab(0)}
                                        classes={{
                                            wrapper: classes.iconLabelWrapper,
                                            labelContainer: classes.labelContainer,
                                            labelIcon: classes.labelIcon,
                                        }} icon={<RiKey2Fill style={{ marginRight: 10 }} size={20} />} label="Acessos" />
                                    <Tab onClick={() => this.handleChangeTab(1)}
                                        classes={{
                                            wrapper: classes.iconLabelWrapper,
                                            labelContainer: classes.labelContainer,
                                            labelIcon: classes.labelIcon,
                                        }} icon={<FiUnlock size={20} style={{ marginRight: 10 }} />} label="Convidados" />
                                    <Tab onClick={() => this.handleChangeTab(2)}
                                        classes={{
                                            wrapper: classes.iconLabelWrapper,
                                            labelContainer: classes.labelContainer,
                                            labelIcon: classes.labelIcon,
                                        }} icon={<BiHistory size={20} style={{ marginRight: 10 }} />} label="Registros" />
                                </Tabs>
                            </AppBar>

                            <Fragment>
                                <Scrollbars
                                    style={{ height: 280 }}>    

                                    {this.state.indexTab === 0 &&
                                        <LuxFwTabContainer>
                                            <Acesso {...this.props} />
                                        </LuxFwTabContainer>}

                                    {this.state.indexTab === 1 &&
                                        <LuxFwTabContainer>
                                            <Convidado {...this.props} />
                                        </LuxFwTabContainer>}

                                    {this.state.indexTab === 2 &&
                                        <LuxFwTabContainer>
                                            <Registro {...this.props} />
                                        </LuxFwTabContainer>}
                                </Scrollbars>
                            </Fragment>


                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

}

Listagens.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Listagens);