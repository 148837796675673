import {
    INICIA_PERFIL,
    SALVA_PERFIL,
    REMOVE_PERFIL,
    REMOVE_EVENTO_PESSOA_PERFIL,
    NOVO_PERFIL,
    BUSCA_PERFIL_EDICAO,
    INICIO_PERFIL_SUCESSO,
    SALVA_PERFIL_SUCESSO,
    REMOVE_PERFIL_SUCESSO,
    BUSCA_PERFIL_EDICAO_SUCESSO,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    LISTA_PERFIL,
    LISTA_PERFIL_SUCESSO,
    LIMPAR_PERFIL,
    EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL,
    EXIBE_ESTRUTURA_LOCAL_PERFIL,
    EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL_SUCESSO,
    ATUALIZA_AMBIENTE_ORIGEM,
    ALTERACAO_CHECK_TODOS_DIAS_SEMANA
  } from 'constants/ActionTypes';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaPerfil = () => {
    return {
      type: INICIA_PERFIL
    };
  };
  
  export const salvaPerfil = (equipamento) => {
    return {
      type: SALVA_PERFIL,
      payload: equipamento
    };
  };
  
  export const removePerfil = (id) => {
    return {
      type: REMOVE_PERFIL,
      payload: id
    };
  };

  export const removeEventoPessoaPerfil = (id) => {
    return {
      type: REMOVE_EVENTO_PESSOA_PERFIL,
      payload: id
    };
  };
  
  export const listaPerfil = (nome) => {
    if(nome == undefined){
      nome = "";
    }
  
    return {
      type: LISTA_PERFIL,
      payload: {nome}
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoPerfil = () => {
    return {
      type: NOVO_PERFIL
    };
  };
  
  export const buscaPerfilEdicao = (id) => {
    return {
      type: BUSCA_PERFIL_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  
  
  export const inicioPerfilSucesso = (perfis, clientes, locais) => {
    return {
      type: INICIO_PERFIL_SUCESSO,
      payload: { perfis, clientes, locais }
    }
  };
  
  export const salvaPerfilSucesso = (mensagem) => {
    return {
      type: SALVA_PERFIL_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removePerfilSucesso = (mensagem) => {
    return {
      type: REMOVE_PERFIL_SUCESSO,
      payload: mensagem
    }
  };  
  
  export const buscaPerfilEdicaoSucesso = (equipamento) => {
    return {
      type: BUSCA_PERFIL_EDICAO_SUCESSO,
      payload: equipamento
    };
  }; 

  export const listaPerfilSucesso = (perfil) => {
    return {
      type: LISTA_PERFIL_SUCESSO,
      payload: { perfil }
    };
  };
  
  export const limparFiltroPerfil = () => {
    return {
      type: LIMPAR_PERFIL,
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 
  
  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };

  export const exibirEstruturaLocalPerfil = (idLocal) => {

    if (idLocal !== null && idLocal !== undefined && idLocal !== '' && idLocal > 0) {
      return [{
        type: EXIBE_ESTRUTURA_LOCAL_PERFIL,
        payload: null
      },
      {
        type: EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL,
        payload: { idLocal }
      }]
    }
    else {
      return {
        type: EXIBE_ESTRUTURA_LOCAL_PERFIL,
        payload: null
      }
    }
  };

  export const exibeDetalheEstruturaLocalPerfilSucesso = (arvoreLocal, local) => {

    return {
      type: EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL_SUCESSO,
      payload: { arvoreLocal, local }
    }
  };

  export const atualizaAmbienteOrigem = (idAmbiente, idLocal, acao) => {

    return {
      type: ATUALIZA_AMBIENTE_ORIGEM,
      payload: { idAmbiente, idLocal, acao }
    }
  };