export const situacoes = [
    { id: 1, title: 'Novo' },
    { id: 2, title: 'Em uso' },
    { id: 3, title: 'Danificado' },
    { id: 4, title: 'Sucata' },
  ];

export const generos = [
    { id: 'MASCULINO', title: 'Masculino' },
    { id: 'FEMININO', title: 'Feminino' },
    { id: 'NAOINFORMADO', title: 'Não Informado / Outros'}
  ];  

  export const generosV2 = [
    { id: 'M', title: 'Masculino' },
    { id: 'F', title: 'Feminino' },
    { id: 'N', title: 'Não Informado / Outros'}
  ];  
export const caracteristicasPerfis = [
  { id: 'PERMANENTE', title: 'Perfil permanente' },
  { id: 'TEMPORARIO', title: 'Perfil temporário' },
  { id: 'EVENTO', title: 'Perfil para eventos'}
];  

export const estadosCivis = [
    { id: 'SOLTEIRO_SOLTEIRA', title: 'Solteiro(a)' },
    { id: 'CASADO_CASADA', title: 'Casado(a)' },
    { id: 'SEPARADO_SEPARADA', title: 'Separado(a)' },
    { id: 'DIVORCIADO_DIVORCIADA', title: 'Divorciado(a)' },
    { id: 'VIUVO_VIUVA', title: 'Viúvo(a)' },
    { id: 'NAOINFORMADO', title: 'Não Informado' },
  ];

export const tiposControleVisibilidade = [
  { id: 'OCULTO', title: 'Ocultar totalmente' },
  { id: 'MASCARA_PARCIAL', title: 'Exibir apenas alguns caracteres' },
];

export const formascomunicacaoEquipamentoCliente = [
    { id: 'SOCKET', title: 'Socket' },
    { id: 'WEBSERVICE', title: 'Webservice' },
  ];

export const tiposLocal = [
    { id: 'PERMANENTE', title: 'Permanente' },
    { id: 'EVENTO', title: 'Evento' },
  ];

export const tiposSincronizacao = [
    { id: 'LOCAL', title: 'Somente Local' },
    { id: 'ONLINE', title: 'Somente Online' },
    { id: 'AMBAS', title: 'Ambas' },
  ];  

export const tiposCheckin = [
    { title: 'Livre (Sem exigência de checkin)', id: 'LIVRE' },
    { title: 'Checkin no Aplicativo', id: 'APLICATIVO' },
    { title: 'Validação de Terceiros', id: 'CLIENTE' },
  ];

export const tiposPontoAcesso = [
    { title: 'Somente Entrada', id: 'ENTRADA' },
    { title: 'Entrada e Saída', id: 'AMBAS' },
    { title: 'Somente Saída', id: 'SAIDA' },
  ];

export const tiposPessoa = [
    { id: 'FISICA', title: 'Pessoa Física' },
    { id: 'JURIDICA', title: 'Pessoa Jurídica' },
  ];  

export const tiposEndereco = [
    { id: 'E', title: 'Endereço de entrega' },
    { id: 'C', title: 'Endereço de cobrança' },
  ];  

export const tiposComunicacaoModelo = [
    { id: 'LEITURA_BIOMETRICA_INDIVIDUAL', title: 'Leitura Biométrica Individual' },
    { id: 'LEITURA_BIOMETRICA_MAO', title: 'Leitura Biométrica (Mão)' },
    { id: 'LEITURA_FACIAL', title: 'Leitura Facial' },
    { id: 'CARTAO', title: 'Cartão Magnético' },
    { id: 'ETIQUETA_CHIP', title: 'Etiqueta com chip' },
    { id: 'SENHA', title: 'Senha' },
  ];