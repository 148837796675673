import {
    INICIA_CLIENTE,
    SALVA_CLIENTE,
    REMOVE_CLIENTE,
    CONSULTA_CEP,
    ABORTA_OPERACAO,
    NOVO_TELEFONE,
    ATUALIZA_TELEFONE,
    REMOVE_TELEFONE,
    NOVO_EMAIL,
    ATUALIZA_EMAIL,
    REMOVE_EMAIL,    
    NOVO_CLIENTE,
    BUSCA_CLIENTE_EDICAO,
    INICIO_CLIENTE_SUCESSO,
    SALVA_CLIENTE_SUCESSO,
    REMOVE_CLIENTE_SUCESSO,
    BUSCA_CLIENTE_EDICAO_SUCESSO,
    CONSULTA_CEP_SUCESSO,
    CONSULTA_CEP_ERRO,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    LISTA_CLIENTE,
    LISTA_CLIENTE_SUCESSO,
    LIMPAR_CLIENTE,
    SETAR_IMAGEM_CLIENTE,
    VALIDACAO_CAMPO,
    CLIQUE_CONTROLE_ABA_CLIENTE,
    LIMPA_VALIDACOES,
  } from 'constants/ActionTypes';
  import moment from 'moment';
  import { ValidaCampoObrigatorio, ValidaCampoObrigatorioCombo, LimpaValidacaoEspecifica } from 'luxFw/transformations/Validations';
  import { limpaValidacoes } from "actions/Global";  

  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

  export const iniciaCliente = () => {
    return [{
      type: LIMPA_VALIDACOES,
    },
    {
      type: INICIA_CLIENTE
    }]
  };

  export const salvaCliente = (cliente) => {

    var validacoes = [];

    validacoes.push.apply(validacoes, RetornosArrayValidacoesParametrizacoes(cliente));

    var errosEncontrados = validacoes.filter(x => x.payload.erro);

    if(errosEncontrados.length === 0)
    {
      var salvaCliente = {
        type: SALVA_CLIENTE,
        payload: cliente
      }

      validacoes.push(salvaCliente);
    }
    else 
    {
      var exibeMensagem = {
        type: SHOW_MESSAGE,
        payload: 'Existem inconsistências. Verifique!'
      }      
      
      validacoes.push(exibeMensagem);
    }

    return validacoes;
  };

  export const removeCliente = (id) => {
    return {
      type: REMOVE_CLIENTE,
      payload: id
    };
  };

  export const listaCliente = (codigo, cpfCnpj, nome, tipoPessoa) => {
    if(codigo == undefined){
      codigo = "";
    }

    if(cpfCnpj == undefined){
      cpfCnpj = "";
    }

    if(nome == undefined){
      nome = "";
    }

    if(tipoPessoa == undefined){
      tipoPessoa = "";
    }

    return {
      type: LISTA_CLIENTE,
      payload: {codigo, cpfCnpj, nome, tipoPessoa}
    };
  };

  export const setarImagem = (imagem) => {

    return {
      type: SETAR_IMAGEM_CLIENTE,
      payload: { imagem }
    };
  }
  
  /*#############################################################################################*/
  /* ------------------------ Actions Complementares da Funcionalidade --------------------------*/

/*   export const consultaCEP = (cep) => {
    let cepAjustado = cep.target.value;
    cepAjustado = cepAjustado.replace(/-/g, "");

    return {
      type: CONSULTA_CEP,
      payload: cepAjustado
    };
  }; */

  export const consultaCEP = (cep) => {

    let cepAjustado = cep.target.value;
  
    if (cepAjustado !== undefined && cepAjustado !== null && cepAjustado !== '') {
      cepAjustado = cepAjustado.replace(/-/g, "");
  
      return {
        type: CONSULTA_CEP,
        payload: cepAjustado
      };
    }
    else {
      return {
        type: ABORTA_OPERACAO,
        payload: null
      };
    }
  };

  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

  export const novoCliente = () => {
    return [{
      type: LIMPA_VALIDACOES,
    },
    {
      type: NOVO_CLIENTE
    }]    
  };

  export const buscaClienteEdicao = (id) => {
    return {
      type: BUSCA_CLIENTE_EDICAO,
      payload: id
    };
  };

  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  

  export const inicioClienteSucesso = (clientes) => {
    return {
      type: INICIO_CLIENTE_SUCESSO,
      payload: clientes
    }
  };
  
  export const salvaClienteSucesso = (mensagem) => {
    return {
      type: SALVA_CLIENTE_SUCESSO,
      payload: mensagem
    }
  };

  export const removeClienteSucesso = (mensagem) => {
    return {
      type: REMOVE_CLIENTE_SUCESSO,
      payload: mensagem
    }
  };  

  export const buscaClienteEdicaoSucesso = (cliente) => {
    return {
      type: BUSCA_CLIENTE_EDICAO_SUCESSO,
      payload: cliente
    };
  }; 

  export const consultaCepSucesso = (correspondenciaCep) => {
    return {
      type: CONSULTA_CEP_SUCESSO,
      payload: correspondenciaCep
    }
  };  

  export const consultaCepError = (erro) => {
    return {
      type: CONSULTA_CEP_ERRO,
      payload: null
    }
  };   
  
  export const listaClienteSucesso = (clientes) => {
    return {
      type: LISTA_CLIENTE_SUCESSO,
      payload: { clientes }
    };
  };
  
  export const limparFiltroCliente = () => {
    return {
      type: LIMPAR_CLIENTE,
    };
  };

  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

  export const novoTelefone = () => {
    return {
      type: NOVO_TELEFONE
    };
  };

  export const atualizaTelefone = (e) => {

    var indexUnderline = e.target.name.indexOf("_");
    var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
    var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
    var valor = e.target.value;

    return {
      type: ATUALIZA_TELEFONE,
      payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
    };
  };  

  export const removeTelefone = (indice) => {
    return {
      type: REMOVE_TELEFONE,
      payload: indice
    };
  };  

  export const novoEmail = () => {
    return {
      type: NOVO_EMAIL
    };
  };
  
  export const atualizaEmail = (e) => {
    var indexUnderline = e.target.name.indexOf("_");
    var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
    var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
    var valor = e.target.value;

    return {
      type: ATUALIZA_EMAIL,
      payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
    };
  };    

  export const removeEmail = (indice) => {
    return {
      type: REMOVE_EMAIL,
      payload: indice
    };
  };    

  export const onChangeAba = (indiceAbaDestino, cliente) => {

    var validacoes = [];
    var indiceAbaOrigem = cliente.indiceAba;
    var irParaAbaEndereco = indiceAbaDestino === 1;
    var irParaAbaContato = indiceAbaDestino === 2;
    var irParaAbaParametrizacoes = indiceAbaDestino === 3;

    if (indiceAbaDestino > indiceAbaOrigem) {
      if (irParaAbaEndereco) {
        if (cliente.tipoPessoa === 'FISICA') {
          validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoaisPessoaFisica(cliente));
        }
        else {
          validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoaisPessoaJuridica(cliente));
        }
  
        validacoes.push.apply(validacoes, LimpaValidacoesAbaEndereco());
      }
  
      if (irParaAbaContato) {
        if (cliente.tipoPessoa === 'FISICA') {
          validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoaisPessoaFisica(cliente));
        }
        else {
          validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoaisPessoaJuridica(cliente));
        }
  
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(cliente));
        validacoes.push.apply(validacoes, LimpaValidacoesAbaContato(cliente))
      }
  
      if (irParaAbaParametrizacoes) {
        if (cliente.tipoPessoa === 'FISICA') {
          validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoaisPessoaFisica(cliente));
        }
        else {
          validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoaisPessoaJuridica(cliente));
        }

        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(cliente));
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosContato(cliente));
      }
    }


    var errosEncontrados = validacoes.filter(x => x.payload.erro);

    if(errosEncontrados.length === 0)
    {
      var irParaAba = {
        type: CLIQUE_CONTROLE_ABA_CLIENTE,
        payload: { indice: indiceAbaDestino }
      }

      validacoes.push(irParaAba);
    }
    else 
    {
      var exibeMensagem = {
        type: SHOW_MESSAGE,
        payload: 'Existem inconsistências. Verifique!'
      }      
      
      validacoes.push(exibeMensagem);
    }

    return validacoes;
  };  

  function RetornosArrayValidacoesDadosPessoaisPessoaFisica(cliente) {

    var validacoes = [];
  
    validacoes.push(ValidaCampoObrigatorio('numeroCpfCnpj', cliente.numeroCpfCnpj));
    validacoes.push(validaCPFCampo('numeroCpfCnpj', cliente.numeroCpfCnpj));
    validacoes.push(ValidaCampoObrigatorio('nomeRazaoSocial', cliente.nomeRazaoSocial));
    validacoes.push(ValidaCampoObrigatorio('dataNascimentoOuAbertura', cliente.dataNascimentoOuAbertura));
    validacoes.push(validaDataCampo('dataNascimentoOuAbertura', cliente.dataNascimentoOuAbertura));
    validacoes.push(ValidaCampoObrigatorio('nomeMae', cliente.nomeMae));
    validacoes.push(ValidaCampoObrigatorioCombo('sexo', cliente.sexo));
    validacoes.push(ValidaCampoObrigatorioCombo('estadoCivil', cliente.estadoCivil));
  
    return validacoes;
  }

  function RetornosArrayValidacoesDadosPessoaisPessoaJuridica(cliente) {

    var validacoes = [];
  
    validacoes.push(ValidaCampoObrigatorio('numeroCpfCnpj', cliente.numeroCpfCnpj));
    validacoes.push(validaCNPJCampo('numeroCpfCnpj', cliente.numeroCpfCnpj));
    validacoes.push(ValidaCampoObrigatorio('nomeRazaoSocial', cliente.nomeRazaoSocial));
    validacoes.push(ValidaCampoObrigatorio('nomeFanstasia', cliente.nomeFanstasia));

    if(!cliente.isentoInscricaoEstadual && cliente.inscricaoEstadual === '') {
      validacoes.push(ValidaCampoObrigatorio('inscricaoEstadual', cliente.inscricaoEstadual));
    }
    else {
      validacoes.push(LimpaValidacaoEspecifica('inscricaoEstadual'));
    }
  
    if(!cliente.isentoInscricaoMunicipal && cliente.inscricaoMunicipal === '') {
      validacoes.push(ValidaCampoObrigatorio('inscricaoMunicipal', cliente.inscricaoMunicipal));
    }
    else {
      validacoes.push(LimpaValidacaoEspecifica('inscricaoMunicipal'));
    }

    return validacoes;
  }

  function RetornosArrayValidacoesDadosEndereco(cliente) {

    var validacoes = [];
  
    validacoes.push(ValidaCampoObrigatorio('cep', cliente.cep));

    if(!cliente.semNumero && cliente.numero === '') {
      validacoes.push(ValidaCampoObrigatorio('numero', cliente.numero));
    }
    else {
      validacoes.push(LimpaValidacaoEspecifica('numero'));
    }    
  
    return validacoes;
  }

  function RetornosArrayValidacoesDadosContato(cliente) {

    var validacoes = [];
    var contador = 0;
  
    if (cliente.telefones.length > 0) {
      cliente.telefones.map(n => {
  
        validacoes.push(ValidaCampoObrigatorio(`numeroTelefone_${n.idTemporario}`, n.numeroTelefone))
        validacoes.push(ValidaCampoObrigatorio(`marcador_${n.idTemporario}`, n.marcador))
      });
    }
  
    if (cliente.emails.length > 1) {
      cliente.emails.map(n => {
        contador++;
  
        if (contador > 1) {
          validacoes.push(ValidaCampoObrigatorio(`email_${n.idTemporario}`, n.email))
          validacoes.push(validaEmailCampo(`email_${n.idTemporario}`, n.email))
          validacoes.push(ValidaCampoObrigatorio(`marcador_${n.idTemporario}`, n.marcador))
        }
      });
    }
    else
    {
        cliente.emails.map(n => {
          contador++;

            if(contador === 1 && n.email !== '') {
              validacoes.push(validaEmailCampo(`email_${n.idTemporario}`, n.email))
            }
        });
    }
  
    return validacoes;
  }

  function RetornosArrayValidacoesParametrizacoes(cliente) {

    var validacoes = [];

    validacoes.push(ValidaCampoObrigatorio('numeroLocaisFixos', cliente.numeroLocaisFixos));
    validacoes.push(ValidaCampoObrigatorio('numeroLocaisEventos', cliente.numeroLocaisEventos));
    validacoes.push(ValidaCampoObrigatorio('numeroLocaisFixosPontos', cliente.numeroLocaisFixosPontos));
    validacoes.push(ValidaCampoObrigatorio('numeroLocaisEventosPontos', cliente.numeroLocaisEventosPontos));
    validacoes.push(ValidaCampoObrigatorio('numeroLocaisFixosUsuarios', cliente.numeroLocaisFixosUsuarios));

    return validacoes;
  }

  function LimpaValidacoesAbaEndereco() {

    var validacoes = [];
  
    validacoes.push(LimpaValidacaoEspecifica('cep'));
    validacoes.push(LimpaValidacaoEspecifica('numero'));
  
    return validacoes;
  }
  
  function LimpaValidacoesAbaContato(cliente) {

    var validacoes = [];
  
    cliente.telefones.map(n => {
      validacoes.push(LimpaValidacaoEspecifica(`numeroTelefone_${n.idTemporario}`))
      validacoes.push(LimpaValidacaoEspecifica(`marcador_${n.idTemporario}`))
    });
  
    cliente.emails.map(n => {
      validacoes.push(LimpaValidacaoEspecifica(`email_${n.idTemporario}`))
      validacoes.push(LimpaValidacaoEspecifica(`marcador_${n.idTemporario}`))
    });
  
    return validacoes;
  }  

  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };

  export const validaEmailCampo = (campo, valor) => {
    var mensagem = "";
    var emailValido = false;
    
    if (valor !== undefined && valor !== null && valor !== ""){
      emailValido = ExecutaValidacaoEmail(valor);
    }
    else {
      emailValido = true;
    }

    if (!emailValido) {
      mensagem = "Email inválido";
    }
    else {
      mensagem = "";
    }

    return {
        type: VALIDACAO_CAMPO,
        payload: { erro: !emailValido, propriedadeValidada: campo, mensagemErro: mensagem }
    };
  }

  export const validaEmail = (e) => {

    var validacoes = [];

    var campo = e.target.name;
    var valor = e.target.value;

    validacoes.push(validaEmailCampo(campo, valor));

    return validacoes;
  };

  function ExecutaValidacaoEmail(email) {
    var re = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
    return re.test(email);
  }

  export const validaData = (e) => {

    var validacoes = [];

    var campo = e.target.name;
    var valor = e.target.value;

    validacoes.push(validaDataCampo(campo, valor));

    return validacoes;
  };

  export const validaDataCampo = (campo, valor) => {
    var mensagem = "";
    var dataValida = false;
    
    if (valor !== undefined && valor !== null && valor !== ""){
      dataValida = isValidDate(valor);
    }
    else {
      dataValida = true;
    }

    if (!dataValida) {
      mensagem = "Data inválida";
    }
    else {
      mensagem = "";
    }

    return {
        type: VALIDACAO_CAMPO,
        payload: { erro: !dataValida, propriedadeValidada: campo, mensagemErro: mensagem }
    };
  }

  function isValidDate(str) {
    var d = moment(str,'D/M/YYYY');
    if(d == null || !d.isValid()) return false;
  
    return str.indexOf(d.format('D/M/YYYY')) >= 0 
        || str.indexOf(d.format('DD/MM/YYYY')) >= 0
        || str.indexOf(d.format('D/M/YY')) >= 0 
        || str.indexOf(d.format('DD/MM/YY')) >= 0;
  }

  export const validaCPF = (e) => {

    var validacoes = [];

    var campo = e.target.name;
    var valor = e.target.value;

    validacoes.push(validaCPFCampo(campo, valor));

    return validacoes;
  };

  export const validaCPFCampo = (campo, valor) => {
    var mensagem = "";
    var cpfValido = false;
    
    if (valor !== undefined && valor !== null && valor !== ""){
      cpfValido = isValidCPF(valor);
    }
    else {
      cpfValido = true;
    }

    if (!cpfValido) {
      mensagem = "CPF inválido";
    }
    else {
      mensagem = "";
    }

    return {
        type: VALIDACAO_CAMPO,
        payload: { erro: !cpfValido, propriedadeValidada: campo, mensagemErro: mensagem }
    };
  }

  function isValidCPF(cpf) {	
    cpf = cpf.replace(/[^\d]+/g,'');	
    if(cpf == '') return false;	
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 || 
      cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;		
    // Valida 1o digito	
    var add = 0;
    var rev = 0;

    for (var i = 0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      rev = 11 - (add % 11);	
      if (rev == 10 || rev == 11)		
        rev = 0;	
      if (rev != parseInt(cpf.charAt(9)))		
        return false;		
    // Valida 2o digito	
    add = 0;	
    for (var i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11)	
      rev = 0;	
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    return true;   
  }

  export const validaCNPJ = (e) => {

    var validacoes = [];

    var campo = e.target.name;
    var valor = e.target.value;

    validacoes.push(validaCNPJCampo(campo, valor));

    return validacoes;
  };

  export const validaCNPJCampo = (campo, valor) => {
    var mensagem = "";
    var cnpjValido = false;
    
    if (valor !== undefined && valor !== null && valor !== ""){
      cnpjValido = isValidCNPJ(valor);
    }
    else {
      cnpjValido = true;
    }

    if (!cnpjValido) {
      mensagem = "CNPJ inválido";
    }
    else {
      mensagem = "";
    }

    return {
        type: VALIDACAO_CAMPO,
        payload: { erro: !cnpjValido, propriedadeValidada: campo, mensagemErro: mensagem }
    };
  }

  function isValidCNPJ(cnpj) {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return false;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0,tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
           
    return true;
    
}

  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };

  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };

  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };