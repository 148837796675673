import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { iniciaPessoas, iniciaLiberacoes, onChangeValue } from 'actions/PortariaInicio';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PeopleIcon from '@material-ui/icons/People';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import BuildIcon from '@material-ui/icons/Build';



import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import * as Icons from '@material-ui/icons';
import { LuxFwTabContainer } from 'luxFw/palettes/Structure';
import Sidebar from './partials/_sidebar'
import Conteudo from './partials/_conteudo'

export default class Parametrizacao extends Component {
    state = {
        value: '',
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        const localControlado = this.props;

        return (
            <Fragment>
                <div className="app-wrapper " style={{ backgroundColor: '#E5E5E5', width: '100%', padding: 0 }}>

                    <div style={{ backgroundColor: '#6FCF97', height: 200 }}>

                    </div>

                    <div style={{ flexGrow: 1, padding: 30 }}>

                        <Grid style={{ marginTop: -180 }} container spacing={2}>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Sidebar {...this.props} />
                            </Grid>
                            <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Conteudo {...this.props} />
                            </Grid>
                        </Grid>
                    </div>

                </div>

            </Fragment>
        );
    }
}