import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_ACESSO_FULL, INICIA_ACESSO_FULL, BUSCA_ACESSO_FULL_EDICAO, REMOVE_ACESSO_FULL,
         LISTA_CONFIGURACAO_PONTO_FULL, LISTA_LOCAIS_POR_CLIENTE_ACESSO_FULL, CONSULTA_CPF, LISTA_ACESSO_FULL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaAcessoFullSucesso,
         removeAcessoFullSucesso, 
         inicioAcessoFullSucesso, 
         iniciaAcessoFull,
         listaLocaisSucesso,
         consultaCpfSucesso,
         buscaAcessoFullEdicaoSucesso,
         listaConfiguracaoPontoPessoaSucesso,
         listaAcessoFullSucesso } from "actions/AcessoFull";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getAcessosFull = async () =>
    await requisicaoApi.get('/acessosFull')
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);       

  const getAcessoFullEdicao = async (id) =>
    await requisicaoApi.get('/acessosFull/' + id)
    .then(resposta => resposta)
    .catch(error => error);

  const getListaPerfisPorCliente = async (cliente) => 
    await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);   
    
  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);    
    
  const getlistaConfiguracaoPontoPessoa = async (idLocal, idPessoa) => 
    await requisicaoApi.get('/acessosFull/vinculoPontoAcesso/' + idLocal + '/' + idPessoa)
    .then(resposta => resposta)
    .catch(error => error);
    
  const getCpf = async (cpf) =>
    await requisicaoApi.get('/util/pessoaFisica/' + cpf)
    .then(resposta => resposta)
    .catch(error => error);    

    const getListaAcessoFull = async (usuarioFiltro, ambienteFiltro, pontoFiltro) => 
    await requisicaoApi.get('/acessosFull?Usuario=' +  usuarioFiltro + '&Ambiente=' + ambienteFiltro + '&PontoAcesso=' + pontoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaAcessoFullRequest = async (acessoFull, listaPontosAssociados) => await requisicaoApi.post('/acessosFull', 
  {
    "id": acessoFull.id,
    "idCliente": acessoFull.idCliente,
    "idLocalControlado": acessoFull.idLocalControlado,
    "numeroCpf": acessoFull.numeroCpf,
    "nomePessoa": acessoFull.nomePessoa,
    "idPessoa": acessoFull.idPessoa,
    "idLocalControlado": acessoFull.idLocalControlado,
    "pontosAssociados": listaPontosAssociados
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaAcessoFullRequest = async (acessoFull, listaPontosAssociados) => await requisicaoApi.put('/acessosFull', 
  {
    "id": acessoFull.id,
    "idCliente": acessoFull.idCliente,
    "idLocalControlado": acessoFull.idLocalControlado,
    "numeroCpf": acessoFull.numeroCpf,
    "nomePessoa": acessoFull.nomePessoa,
    "idPessoa": acessoFull.idPessoa,
    "idLocalControlado": acessoFull.idLocalControlado,
    "pontosAssociados": listaPontosAssociados
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteAcessoFullRequest = async (id) =>
  await requisicaoApi.delete('/acessosFull?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

function* fnIniciaAcessoFull() {
  try {
    const listagemGrid = yield call(getAcessosFull);

    if(listagemGrid.data)
    {
      const clientes = yield call(getClientes);

      if(clientes.data) {
        const clienteFixo = localStorage.getItem('idClienteFixado');

        if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
        {
          const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
          yield put(inicioAcessoFullSucesso(listagemGrid.data, clientes.data, locais.data));
        }
        else
        {
          yield put(inicioAcessoFullSucesso(listagemGrid.data, clientes.data, null));
        }
      }
    }
    else {
      if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
      {
        yield put(userSignOut());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao listar acessosFull'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaLocaisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaLocaisControladosPorCliente, cliente);

    if(retorno.data)
    {
      yield put(listaLocaisSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
}

function* fnlistaConfiguracaoPontoPessoa({ payload }) {
  try {
    const idLocal = payload.idLocal;
    const idPessoa = payload.idPessoa
    const retorno = yield call(getlistaConfiguracaoPontoPessoa, idLocal, 1);

    if(retorno.data)
    {
      yield put(listaConfiguracaoPontoPessoaSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso do local: ' + idLocal));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

function* fnConsultaCpf({ payload }) {
  try {
    const cpf = payload;
    const retorno = yield call(getCpf, cpf);

    if(retorno.data)
    {
      yield put(consultaCpfSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}  

  function* fnBuscaAcessoFullEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getAcessoFullEdicao, id);
      const locais = yield call(getListaLocaisControladosPorCliente, retorno.data.idCliente);
  
      if(retorno.data)
      {
        yield put(buscaAcessoFullEdicaoSucesso(retorno.data, locais.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do acessoFull: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaAcessoFull({ payload }) {
  try {

    const acessoFull = payload.acessoFull;
    const listaPontosAssociados = payload.listaPontosAssociados;
    var resultado = "";

      if(acessoFull.id === 0) {
        const retorno = yield call(adicionaAcessoFullRequest, acessoFull, listaPontosAssociados);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaAcessoFullRequest, acessoFull, listaPontosAssociados);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaAcessoFull());
        yield put(salvaAcessoFullSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveAcessoFull({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteAcessoFullRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaAcessoFull());
        yield put(removeAcessoFullSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaAcessoFull({ payload }) {
  try {
    const usuarioFiltro = payload.usuario;
    const ambienteFiltro = payload.ambiente;
    const pontoFiltro = payload.ponto;
    const retorno = yield call(getListaAcessoFull, usuarioFiltro, ambienteFiltro, pontoFiltro);

    if(retorno.data)
    {
      yield put(listaAcessoFullSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de acesso pessoa'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesAcessoFull() {
  yield takeEvery(INICIA_ACESSO_FULL, fnIniciaAcessoFull);
}

export function* consultarCpf() {
  yield takeEvery(CONSULTA_CPF, fnConsultaCpf);
}

export function* salvaAcessoFull() {
  yield takeEvery(SALVA_ACESSO_FULL, fnSalvaAcessoFull);
}

export function* buscaAcessoFullEdicao() {
  yield takeEvery(BUSCA_ACESSO_FULL_EDICAO, fnBuscaAcessoFullEdicao);
}

export function* removeAcessoFull() {
  yield takeEvery(REMOVE_ACESSO_FULL, fnRemoveAcessoFull);
}

export function* listaLocaisPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_ACESSO_FULL, fnListaLocaisPorCliente)
}

export function* listaConfiguracaoPontoPessoa() {
  yield takeEvery(LISTA_CONFIGURACAO_PONTO_FULL, fnlistaConfiguracaoPontoPessoa)
}

export function* listaAcessoFull() {
  yield takeEvery(LISTA_ACESSO_FULL, fnListaAcessoFull)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesAcessoFull),
             fork(consultarCpf), 
             fork(salvaAcessoFull), 
             fork(buscaAcessoFullEdicao),
             fork(removeAcessoFull),
             fork(listaLocaisPorCliente),
             fork(listaConfiguracaoPontoPessoa),
             fork(listaAcessoFull)
            ]);
}