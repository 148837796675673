import {
    INICIA_VINCULO_PESSOA,
    SALVA_VINCULO_PESSOA,
    REMOVE_VINCULO_PESSOA,
    LISTA_VINCULO_PESSOA,
    NOVO_VINCULO_PESSOA,
    BUSCA_VINCULO_PESSOA_EDICAO,
    INICIO_VINCULO_PESSOA_SUCESSO,
    SALVA_VINCULO_PESSOA_SUCESSO,
    REMOVE_VINCULO_PESSOA_SUCESSO,
    BUSCA_VINCULO_PESSOA_EDICAO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA,
    LISTA_LOCAIS_SUCESSO_VINCULO_PESSOA,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    LISTA_VINCULO_PESSOA_SUCESSO,
    LIMPAR_VINCULO_PESSOA,
    LISTA_LOCALIDADE_POR_LOCAL,
    LISTA_LOCALIDADE_POR_LOCAL_SUCESSO,
    LISTA_DEPARTAMENTO_POR_LOCALIDADE,
    LISTA_DEPARTAMENTO_POR_LOCALIDADE_SUCESSO,
    ADICIONA_VINCULO_PESSOA_LISTA_VINCULADA,
    REMOVE_VINCULO_PESSOA_LISTA_VINCULADA,
    CONSULTA_CPF_VINCULO,
    CONSULTA_CPF_SUCESSO_VINCULO     
  } from 'constants/ActionTypes';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaVinculoPessoa = () => {
    return {
      type: INICIA_VINCULO_PESSOA
    };
  };
  
  export const salvaVinculoPessoa = (vinculoPessoa) => {
    return {
      type: SALVA_VINCULO_PESSOA,
      payload: vinculoPessoa
    };
  };
  
  export const removeVinculoPessoa = (id) => {
    return {
      type: REMOVE_VINCULO_PESSOA,
      payload: id
    };
  };
  
  export const listaVinculoPessoa = (cliente, local, departamento) => {
    return {
      type: LISTA_VINCULO_PESSOA,
      payload: {cliente, local, departamento}
    };
  };
  
  /*#############################################################################################*/
  /* ------------------------ Actions Complementares da Funcionalidade --------------------------*/
  
  export const listaLocaisControladosPorCliente = (name, object, value) => {
    var idCliente = value !== null ? value.id : 0;
  
    return {
      type: LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA,
      payload: { idCliente }
    }
  }

  export const adicionaVinculoPessoaListaVinculada = (cpf, nome, responsavel, podeLiberar, funcao, grupo, idPessoa) => {
  
    return {
      type: ADICIONA_VINCULO_PESSOA_LISTA_VINCULADA,
      payload: { cpf, nome, responsavel, podeLiberar, funcao, grupo, idPessoa }
      };
  };
  
  export const removeVinculoPessoaListaVinculada = (idTemporario) => {
    return {
      type: REMOVE_VINCULO_PESSOA_LISTA_VINCULADA,
      payload: { idTemporario }
    };
  }; 
  
 
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoVinculoPessoa = () => {
    return {
      type: NOVO_VINCULO_PESSOA
    };
  };
  
  export const buscaVinculoPessoaEdicao = (id) => {
    return {
      type: BUSCA_VINCULO_PESSOA_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/
  
  export const inicioVinculoPessoaSucesso = (vinculoPessoas, clientes, locaisControlados) => {
    return {
      type: INICIO_VINCULO_PESSOA_SUCESSO,
      payload: { vinculoPessoas, clientes, locaisControlados }
    }
  };
  
  export const salvaVinculoPessoaSucesso = (mensagem) => {
    return {
      type: SALVA_VINCULO_PESSOA_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removeVinculoPessoaSucesso = (mensagem) => {
    return {
      type: REMOVE_VINCULO_PESSOA_SUCESSO,
      payload: mensagem
    }
  };
  
  export const buscaVinculoPessoaEdicaoSucesso = (vinculoPessoa, funcoes, grupos) => {
    return {
      type: BUSCA_VINCULO_PESSOA_EDICAO_SUCESSO,
      payload: { vinculoPessoa, funcoes, grupos }
    };
  };
  
  export const listaLocaisControladosSucesso = (locais) => {
    return {
      type: LISTA_LOCAIS_SUCESSO_VINCULO_PESSOA,
      payload: { locais }
    };
  };
  
  
  export const listaVinculoPessoaSucesso = (vinculoPessoas) => {
    return {
      type: LISTA_VINCULO_PESSOA_SUCESSO,
      payload: { vinculoPessoas }
    };
  };
  
  export const limparFiltroVinculoPessoa = () => {
    return {
      type: LIMPAR_VINCULO_PESSOA,
    };
  };
  
  export const listaLocalidadePorLocal = (name, object, value) => {
    var idLocal = value !== null ? value.id : 0;
  
    return {
      type: LISTA_LOCALIDADE_POR_LOCAL,
      payload: { idLocal }
    }
  }
  
  export const listaLocalidadePorLocalSucesso = (localidades) => {
    return {
      type: LISTA_LOCALIDADE_POR_LOCAL_SUCESSO,
      payload: { localidades }
    };
  };

  export const listaDepartamentoPorLocalidade = (name, object, value) => {
    var idLocal = value !== null ? value.id : 0;
  
    return {
      type: LISTA_DEPARTAMENTO_POR_LOCALIDADE,
      payload: { idLocal }
    }
  }
  
  export const listaDepartamentoPorLocalidadeSucesso = (departamentos, funcoes, grupos) => {
    return {
      type: LISTA_DEPARTAMENTO_POR_LOCALIDADE_SUCESSO,
      payload: { departamentos, funcoes, grupos }
    };
  };

  export const consultaCpfSucesso = (correspondenciaCpf) => {
    return {
      type: CONSULTA_CPF_SUCESSO_VINCULO,
      payload: correspondenciaCpf
    }
  };  
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/
  
  export const consultaCPF = (cpf) => {
    let cpfAjustado = cpf.target.value;
    cpfAjustado = cpfAjustado.replace(/-/g, "");

    return {
      type: CONSULTA_CPF_VINCULO,
      payload: cpfAjustado
    };
  };

  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };