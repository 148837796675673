import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import * as Icons from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { LuxFwTabContainer } from 'luxFw/palettes/Structure';
import Avatar from '@material-ui/core/Avatar';
import { RiKey2Fill } from "@react-icons/all-files/ri/RiKey2Fill";
import { FiUnlock } from "@react-icons/all-files/fi/FiUnlock";
import { BiHistory } from "@react-icons/all-files/bi/BiHistory";
import PersonIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import Resumo from './_resumo';
import Perfil from './_perfil';
import Observacao from './_observacao';
import CheckList from './_checklist';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Anderson da Silva Mor...', 'Esmeralda / 3004', 'Benedito Jose...'),
    createData('Joaquim Ferreira da Silv...', 'Esmeralda / 3004', 'Benedito Jose...'),
    createData('Anderson da Silva Mor...', 'Esmeralda / 3004', 'Benedito Jose...'),
    createData('Anderson da Silva Mor...', 'Esmeralda / 3004', 'Benedito Jose...'),
    /* createData('Gingerbread', 356, 16.0, 49, 3.9), */
];

const styles = theme => ({
    root: {
        flexGrow: 1,
        ////backgroundColor: theme.palette.background.paper,
    },
    firstIcon: {
        paddingLeft: 70
    },
    labelContainer: {
        width: "auto",
    },
    labelIcon: {
        minHeight: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif'
    },
    iconLabelWrapper2: {
        flexDirection: "row-reverse"
    },
    padding: {
        paddingTop: 0,
    }
});

class Listagens extends Component {
    constructor() {
        super();
        this.state = { indexTab: 0 };
      }
    
      handleChangeTab = (indexTab) => {
        this.setState({ indexTab });
      };

    render() {

        /*         const pessoaSelecionada = this.props.pessoaSelecionada;
                var imagemTratada = pessoaSelecionada.imagem;
                imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null; */
        const { classes } = this.props;
        var contador = 0;

        return (
            <Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: 15, borderRadius: 15, height: 490 }} >
                            <div style={{ width: '100%', padding: 15 }}>
                                <Avatar style={{ float: 'left', backgroundColor: '#FEF1DE', color: '#FF9933' }}>
                                    <PersonIcon />
                                </Avatar>
                                <span style={{ marginTop: 7, marginLeft: 10, float: 'left', fontWeight: 500, fontSize: 20, fontFamily: 'Montserrat, sans-serif' }}>Nome Pessoa Análise</span>

                            </div>
                            <div style={{ width: '100%' }}>
                                <Divider style={{ paddingBottom: 40, backgroundColor: 'transparent' }} />
                            </div>

                            <AppBar elevation={0} style={{ backgroundColor: 'white', color:'#05B59D' }} position="static">
                            <Tabs value={this.state.indexTab} onChange={this.handleChangeTab} style={{ backgroundColor: '#ffffff', WebkitBoxShadow: 'none', boxShadow: 'none !important', borderBottom: '1px solid #e8e8e8' }} indicatorColor="primary" textColor="primary" variant="standart" scrollButtons="auto">
                                    <Tab onClick={() => this.handleChangeTab(0)} key={1} classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        ////labelContainer: classes.labelContainer,
                                        labelIcon: classes.labelIcon,
                                    }} label="Resumo" />
                                    <Tab onClick={() => this.handleChangeTab(1)}  key={2} classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        ////labelContainer: classes.labelContainer,
                                        labelIcon: classes.labelIcon,
                                    }} label="Perfil" />
                                    <Tab onClick={() => this.handleChangeTab(2)}  key={3} classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        ////labelContainer: classes.labelContainer,
                                        labelIcon: classes.labelIcon,
                                    }} label="Observações" />
                                    <Tab onClick={() => this.handleChangeTab(3)}  key={4} classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        ////labelContainer: classes.labelContainer,
                                        labelIcon: classes.labelIcon,
                                    }} label="Checklist" />
                                </Tabs>
                            </AppBar>

                            {this.state.indexTab === 0 &&
                            <LuxFwTabContainer>
                                <Resumo {...this.props} />
                            </LuxFwTabContainer>}

                            {this.state.indexTab === 1 &&
                            <LuxFwTabContainer>
                                <Perfil {...this.props} />
                            </LuxFwTabContainer>}

                            {this.state.indexTab === 2 &&
                            <LuxFwTabContainer>
                                <Observacao {...this.props} />
                            </LuxFwTabContainer>}

                            {this.state.indexTab === 3 &&
                            <LuxFwTabContainer>
                                <CheckList {...this.props} />
                            </LuxFwTabContainer>}
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

}

Listagens.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Listagens);