import React, {Component} from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const GreenSwitch = withStyles({
  switchBase: {
    color: "gray",
    '&$checked': {
      color: "#05B59D",
    },
    '&$checked + $track': {
      backgroundColor: "#05B59D",
    },
  },
  checked: {},
  track: {},
})(Switch);

const styles = theme => ({
 
    root: {
      paddingTop: 15,
      paddingLeft: 15,
      marginLeft: 0,
      color: 'green',

      ".MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: 'yellow'
    }
    } 
  })

class IntegrationSwitch extends Component {
  state = {
    checkedA: true
  };


  render() {

/*     handleChange = (object, value)  => {
      var name = this.props.name;
      this.props.onChange(name, object, value);
    }; */

    const {classes} = this.props;

    return !this.props.semEstilizacao ? (
        <FormControlLabel style={{paddingTop: 15}} control={
/*             <Switch
                  classes={{
                  checked: 'text-primary'
                }}
                thumbSwitchedStyle={{ backgroundColor: 'grey' }}
                id={this.props.name}
                checked={this.props.valor}
                onChange={this.props.onChange}
                aria-label="checkedA"
              />
            } */

            <GreenSwitch
            
            id={this.props.name}
            checked={this.props.valor}
            onChange={this.props.onChange}
            aria-label="checkedA"            
            
            />}

            disabled={this.props.disabled}
            classes={this.props.tamanho && {root: classes.root}}
            label={this.props.labelSwitch}
            labelPlacement={this.props.posicaoLabel === 'right' ? 'end' : 'start'}
          />
    ) : (
      <FormControlLabel style={this.props.estilo} control={
/*             <Switch
                classes={{
                checked: 'text-primary'
              }}
              thumbSwitchedStyle={{ backgroundColor: 'grey' }}
              id={this.props.name}
              checked={this.props.valor}
              onChange={this.props.onChange}
              aria-label="checkedA"
            />
          } */

          <GreenSwitch
          
          id={this.props.name}
          checked={this.props.valor}
          onChange={this.props.onChange}
          aria-label="checkedA"            
          
          />}

          disabled={this.props.disabled}
          classes={this.props.tamanho && {root: classes.root}}
          label={this.props.labelSwitch}
          labelPlacement={this.props.posicaoLabel === 'right' ? 'end' : 'start'}
        />
  )
  }
}

IntegrationSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntegrationSwitch);