import React, { Component, Fragment } from 'react';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Topo from './_topo'
import Vinculos from './_vinculos'
import Observacoes from './_observacoes'
import Listagens from './_listagens'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export default class Conteudo extends Component {


    render() {

        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        var imagemTratada = pessoaSelecionada.imagem;
        imagemTratada = imagemTratada !== undefined && imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

        return (
            <Fragment>
                <Topo {...this.props} />

                <Listagens {...this.props} />


                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Vinculos {...this.props} />
                    </Grid>
                    <Grid item xs={6}>
                        <Observacoes {...this.props} />
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

}