import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_VINCULO_PESSOA,
    NOVO_VINCULO_PESSOA,
    BUSCA_VINCULO_PESSOA_EDICAO,
    CANCELA_EDICAO,
    INICIO_VINCULO_PESSOA_SUCESSO,
    SALVA_VINCULO_PESSOA_SUCESSO,
    REMOVE_VINCULO_PESSOA_SUCESSO,
    LISTA_VINCULO_PESSOA,
    LISTA_VINCULO_PESSOA_SUCESSO,
    LIMPAR_VINCULO_PESSOA,
    BUSCA_VINCULO_PESSOA_EDICAO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA,
    LISTA_LOCAIS_SUCESSO_VINCULO_PESSOA,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    LISTA_LOCALIDADE_POR_LOCAL,
    LISTA_LOCALIDADE_POR_LOCAL_SUCESSO,
    LISTA_DEPARTAMENTO_POR_LOCALIDADE,
    LISTA_DEPARTAMENTO_POR_LOCALIDADE_SUCESSO,
    ADICIONA_VINCULO_PESSOA_LISTA_VINCULADA,
    REMOVE_VINCULO_PESSOA_LISTA_VINCULADA,
    CONSULTA_CPF_VINCULO,
    CONSULTA_CPF_SUCESSO_VINCULO   
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo VinculoPessoa', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },        
      { titulo: 'Cliente', propriedadeApi: 'nomeRazaoSocialcliente', alinhamento: 'left' },  
      { titulo: 'Local Controlado',  propriedadeApi: 'descricaoLocalControlado', alinhamento: 'left' },  
      { titulo: 'Localidade',  propriedadeApi: 'descricaoLocalidade', alinhamento: 'left' },  
      { titulo: 'Departamento', propriedadeApi: 'descricaoDepartamento', alinhamento: 'left' },  
    ],    

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],

    // Campos do Formulario
    id: 0,
    idCliente: 0,
    idLocalidade: 0,
    idDepartamento: 0,
    idLocalControlado: 0,
    nomeClienteFixado: null,
    idClienteFixado: 0,
    nomeLocalControlado: '',
    nomeCliente: '',
    nomeLocalidade:'',
    nomeDepartamento: '',
    vinculosPessoas: [],
  
    // Propridades adicionar na lista
    cpfAdd: '',
    nomePessoaAdd: '',
    responsavelAdd: false,
    podeLiberarAdd: false,
    funcaoAdd: 0,
    grupoAdd: 0,
    idPessoaAdd: 0,

    // Combos
    listaDeClientes: [],
    listaDeLocaisControlados: [],
    listaDeDepartamentos: [],
    listaDeFuncoes: [],
    listaDeGrupos: [],
    listaDeLocalidades: [],

     // Filtros
     nomeClienteFiltro: '',
     nomeLocalControladoFiltro: '',
     nomeDepartamentoFiltro: '',
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_VINCULO_PESSOA: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),    
        }
      }   
      case NOVO_VINCULO_PESSOA: {
        return {
          ...state,
          edicao: true,
          id: 0,
          idLocalidade: 0,
          idDepartamento: 0,
          idLocalControlado: 0,
          nomeLocalControlado: '',
          nomeCliente: '',
          vinculosPessoas: [], 
          cpfAdd: '',
          nomePessoaAdd: '',
          responsavelAdd: false,
          podeLiberarAdd: false,
          funcaoAdd: 0,
          grupoAdd: 0,
          idPessoaAdd: 0,
          listaDeDepartamentos: [],
          listaDeFuncoes: [],
          listaDeGrupos: [],
          listaDeLocalidades: [],
          idCliente: state.idClienteFixado, 
         }
      }       
      case BUSCA_VINCULO_PESSOA_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false
        }
      }
      case INICIO_VINCULO_PESSOA_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.vinculoPessoas,
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
          listaDeLocaisControlados: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.locaisControlados, "id", "descricaoLocalControlado") : [],
      
        }
      }                     
      case SALVA_VINCULO_PESSOA_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_VINCULO_PESSOA_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_VINCULO_PESSOA: {
        const retorno = action.payload;

        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_VINCULO_PESSOA_SUCESSO: {
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listGrid: retorno.vinculoPessoas,
        }
      }
      case LIMPAR_VINCULO_PESSOA: {

        var retorno = action.payload;

        return {
          ...state,
          nomeClienteFiltro: '',
          nomeLocalControladoFiltro: '',
          nomeDepartamentoFiltro: '',
        }
      }
      case BUSCA_VINCULO_PESSOA_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.vinculoPessoa.id,
          idLocalControlado: retorno.vinculoPessoa.idLocalControlado,
          nomeLocalControlado: retorno.vinculoPessoa.nomeLocalControlado,
          nomeCliente: retorno.vinculoPessoa.nomeCliente,
          idCliente: retorno.vinculoPessoa.idCliente,
          nomeLocalidade: retorno.vinculoPessoa.nomeLocalidade,
          idLocalidade: retorno.vinculoPessoa.idLocalidade,
          idDepartamento: retorno.vinculoPessoa.idDepartamento,
          vinculosPessoas: retorno.vinculoPessoa.clientesPessoaDepartamentoUnidade,
          nomeDepartamento: retorno.vinculoPessoa.descricao,
          listaDeFuncoes: preparaListaParaCombo(retorno.funcoes, "id", "descricao"), 
          listaDeGrupos: preparaListaParaCombo(retorno.grupos, "id", "descricao"), 
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),             
        }
      }
      case LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA: {

        const retorno = action.payload;

        return {
          ...state,
          idCliente: retorno.idCliente,
          loader: true,          
        }
      }
      case LISTA_LOCAIS_SUCESSO_VINCULO_PESSOA: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          idLocalControlado: null,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"), 
        }
      }
      case LISTA_LOCALIDADE_POR_LOCAL: {

        const retorno = action.payload;

        return {
          ...state,
          idLocalControlado: retorno.idLocal,
          loader: true,          
        }
      }
      case LISTA_LOCALIDADE_POR_LOCAL_SUCESSO: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listaDeLocalidades: preparaListaParaCombo(retorno.localidades, "id", "descricao"), 
        }
      }
      case LISTA_DEPARTAMENTO_POR_LOCALIDADE: {

        const retorno = action.payload;

        return {
          ...state,
          idLocalidade: retorno.idLocal,
          loader: true,          
        }
      }
      case LISTA_DEPARTAMENTO_POR_LOCALIDADE_SUCESSO: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listaDeDepartamentos: preparaListaParaCombo(retorno.departamentos, "id", "descricao"), 
          listaDeFuncoes: preparaListaParaCombo(retorno.funcoes, "id", "descricao"), 
          listaDeGrupos: preparaListaParaCombo(retorno.grupos, "id", "descricao"), 
        }
      }
      case ADICIONA_VINCULO_PESSOA_LISTA_VINCULADA: {
       
        var retorno = action.payload;
        var registroDuplicado = state.vinculosPessoas.filter(x => x.cpfPessoa === retorno.cpf).length > 0;

        if(retorno.idPessoa !==0 && !registroDuplicado){
          var idTemporario = state.vinculosPessoas.length > 0 ? Math.max(...state.vinculosPessoas.map(x => x.idTemporario)) + 1 : 1;
          const grupoSelecionado = state.listaDeGrupos.filter(x => x.id === retorno.grupo);
          const funcaoSelecionada = state.listaDeFuncoes.filter(x => x.id === retorno.funcao);

          const pessoaAdd = {
            "idDepartamentoUnidade": state.idDepartamento,
            "cpfPessoa": retorno.cpf,
            "nomePessoa": retorno.nome,
            "ehResponsavel": retorno.responsavel,
            "podeLiberarAcesso": retorno.podeLiberar,
            "idFuncaoPessoa": retorno.funcao === 0 ? '' : retorno.funcao,
            "nomeFuncao": funcaoSelecionada.length > 0 ? funcaoSelecionada[0].title : null, 
            "nomeGrupo": grupoSelecionado.length > 0 ? grupoSelecionado[0].title : null, 
            "idGrupoPessoa": retorno.grupo === 0 ? '' : retorno.grupo,
            "idTemporario": idTemporario,
            "idPessoa": retorno.idPessoa,
            "idCliente":  state.idCliente,
            "IdClientePessoa": 0,
             "id": 0
          }
  
          return {
            ...state,
            vinculosPessoas:[...state.vinculosPessoas, pessoaAdd],
            cpfAdd: '',
            nomePessoaAdd: '',
            responsavelAdd: false,
            podeLiberarAdd: false,
            funcaoAdd: '',
            grupoAdd: '',
            idPessoaAdd: 0,
         }

        }
        else{
          return {
            ...state,
         }
        }
      }       
      case REMOVE_VINCULO_PESSOA_LISTA_VINCULADA: {
        var retorno = action.payload;

        const arrayAtualizado = state.vinculosPessoas.filter(x => x.idTemporario !== retorno.idTemporario)

        return {
          ...state,
          vinculosPessoas: arrayAtualizado,
          cpfAdd: '',
          nomeAdd: '',
          responsavelAdd: false,
          podeLiberarAdd: false,
          funcaoAdd: '',
          grupoAdd: '',
       }
      }
      case CONSULTA_CPF_VINCULO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CONSULTA_CPF_SUCESSO_VINCULO: {
        var retorno = action.payload;

        return {
          ...state,
          loader: false,
          idPessoaAdd: retorno.idPessoa,
          nomePessoaAdd: retorno.nomePessoa
        }
      }                            
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  