import {
  INICIA_ACESSO_IDADE,
  SALVA_ACESSO_IDADE,
  REMOVE_ACESSO_IDADE,
  NOVO_ACESSO_IDADE,
  BUSCA_ACESSO_IDADE_EDICAO,
  INICIO_ACESSO_IDADE_SUCESSO,
  LISTA_CONFIGURACAO_PONTO_IDADE,
  LISTA_CONFIGURACAO_PONTO_IDADE_SUCESSO,
  LISTA_LOCAIS_POR_CLIENTE_IDADE,
  LISTA_LOCAIS_SUCESSO,
  ALTERACAO_CHECK_PONTO_ACESSO_IDADE,
  SALVA_ACESSO_IDADE_SUCESSO,
  REMOVE_ACESSO_IDADE_SUCESSO,
  BUSCA_ACESSO_IDADE_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_ACESSO_IDADE,
  LISTA_ACESSO_IDADE_SUCESSO,
  LIMPAR_ACESSO_IDADE,
  ADICIONA_PERFIL_LISTA_VINCULADA_IDADE,
  REMOVE_PERFIL_LISTA_VINCULADA_IDADE
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaAcessoIdade = () => {
  return {
    type: INICIA_ACESSO_IDADE
  };
};

export const salvaAcessoIdade = (acessoIdade) => {

  var listaPontosAssociados = [];

  if(acessoIdade.configuracaoPontoIdade != null && acessoIdade.configuracaoPontoIdade.listaAmbientesVinculados != null)
  {
  for (var i = 0; i < acessoIdade.configuracaoPontoIdade.listaAmbientesVinculados.length; i++) {
    var listaPontos = acessoIdade.configuracaoPontoIdade.listaAmbientesVinculados[i].pontosAcessoVinculados;

    for (var j = 0; j < listaPontos.length; j++) {
        listaPontosAssociados.push({"idCriterio": 0, "idPontoAcesso":  listaPontos[j].idPontoAcesso, "id": 0, ativo: listaPontos[j].associado })
    }
  }
}

  return {
    type: SALVA_ACESSO_IDADE,
    payload:  { acessoIdade, listaPontosAssociados }
  };
};

export const removeAcessoIdade = (id) => {
  return {
    type: REMOVE_ACESSO_IDADE,
    payload: id
  };
};


export const adicionaPerfilListaVinculada = (idPerfil) => {

  return {
    type: ADICIONA_PERFIL_LISTA_VINCULADA_IDADE,
    payload: { idPerfil }
    };
};

export const removePerfilListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_PERFIL_LISTA_VINCULADA_IDADE,
    payload: { idTemporario }
  };
};  

export const listaAcessoIdade = (idade, ambiente, ponto) => {
  if(idade == undefined){
    idade = "";
  }

  if(ambiente == undefined){
    ambiente = "";
  }

  if(ponto == undefined){
    ponto = "";
  }

  return {
    type: LISTA_ACESSO_IDADE,
    payload: {idade, ambiente, ponto}
  };
};

/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoAcessoIdade = () => {
  return {
    type: NOVO_ACESSO_IDADE
  };
};

export const buscaAcessoIdadeEdicao = (id) => {
  return {
    type: BUSCA_ACESSO_IDADE_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/  

export const listaLocaisPorCliente = (name, object, value) => {
  var idCliente = value !== null ? value.id : 0;

  return {
    type: LISTA_LOCAIS_POR_CLIENTE_IDADE,
    payload: { idCliente }
  }
}

export const listaConfiguracaoPontosPorIdade = (idLocal, idadeMinima, idadeMaxima) => {
  return {
    type: LISTA_CONFIGURACAO_PONTO_IDADE,
    payload: { idLocal, idadeMinima, idadeMaxima }
  }
}

export const onChangeCheckPontoAcesso = (object, value) => {
  var nomeCheck = [object.target.id].toString();
  var idPtoAcesso = nomeCheck.replace(/pontoAcesso_/g, "");

  return {
      type: ALTERACAO_CHECK_PONTO_ACESSO_IDADE,
      payload: { idPontoAcessoAlterado: idPtoAcesso, valor: value }
  }   
};   

export const inicioAcessoIdadeSucesso = (acessosIdade, clientes, locais, perfis) => {
  return {
    type: INICIO_ACESSO_IDADE_SUCESSO,
    payload: { acessosIdade, clientes, locais, perfis }
  }
};

export const salvaAcessoIdadeSucesso = (mensagem) => {
  return {
    type: SALVA_ACESSO_IDADE_SUCESSO,
    payload: mensagem
  }
};

export const removeAcessoIdadeSucesso = (mensagem) => {
  return {
    type: REMOVE_ACESSO_IDADE_SUCESSO,
    payload: mensagem
  }
};  

export const listaConfiguracaoPontoIdadeSucesso = (configuracao) => {
  return {
    type: LISTA_CONFIGURACAO_PONTO_IDADE_SUCESSO,
    payload: { configuracao }
  }
}

export const buscaAcessoIdadeEdicaoSucesso = (acessoIdade) => {
  return {
    type: BUSCA_ACESSO_IDADE_EDICAO_SUCESSO,
    payload: acessoIdade
  };
}; 

export const listaLocaisSucesso = (locais, perfils) => {
  return {
    type: LISTA_LOCAIS_SUCESSO,
    payload: { locais, perfils }
  };
};

export const listaAcessoIdadeSucesso = (acessoIdade) => {
  return {
    type: LISTA_ACESSO_IDADE_SUCESSO,
    payload: { acessoIdade }
  };
};

export const limparFiltroAcessoIdade = () => {
  return {
    type: LIMPAR_ACESSO_IDADE,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};