const languageData = [
  {
    languageId: 'portuguese',
    locale: 'br',
    name: 'Português',
    icon: 'br'
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es'
  },
  {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr'
  },
];
export default languageData;
