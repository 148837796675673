
import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userSignIn
} from 'actions/Auth';

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url("../imagesLogin/bg.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a target="_blank" color="inherit" rel="noopener noreferrer" href="https://uzer.com.br/">
        Uzer Tecnologia
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      login: '',
      senha: ''
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {
      login,
      senha
    } = this.state;
    const {classes} = this.props;
    const {showMessage, loader, alertMessage} = this.props;
    return (
      <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={9} className={classes.image} />
      <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Link className="logo-lg" to="/" title="Uzer Tecnologia">
              <img src={require("assets/images/logotipo_transparente_fundoclaro.png")} width="177" height="61" alt="Uzer Tecnologia" title="Uzer Tecnologia"/>
            </Link>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              label="Usuário Uzer Pass"
              onChange={(event) => this.setState({login: event.target.value})}
              defaultValue={login}              
              name="login"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="senha"
              label="Senha"
              onChange={(event) => this.setState({senha: event.target.value})}
              defaultValue={senha}              
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button style={{width: '100%', height: 40}} onClick={() => {
                      this.props.showAuthLoader();
                      this.props.userSignIn({login, senha});
                    }}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Acessar
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>          
        </div>
      </Grid>
    </Grid>
    );
  }  

}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader
})(withStyles(styles)(SignIn));