import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  INIT_URL,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  FECHA_MODULOS_PORTARIA,
  FECHA_MODULOS,
  SELECIONA_MODULOS_PORTARIA,
  IR_PARA_MODULO,
  INICIA_CARREGAMENTO_CONFIGURACAO,
  ABORTA_OPERACAO,
  INICIA_DEFINICOES,
  DESATIVA_TIMER,
  ATUALIZA_MODO_PORTARIA,  
  INICIA_PORTARIA,
  VOLTAR_INICIO_PORTARIA
} from 'constants/ActionTypes';

export function toggleCollapsedNav(isNavCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
  return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function setThemeColor(color) {
  return {type: THEME_COLOR, color};
}

export function setDarkTheme() {
  return {type: DARK_THEME};
}

export function iniciaCarregamentoRotas(configuracao) {
  
  if (Object.keys(configuracao).length === 0) {
    return {
      type: INICIA_CARREGAMENTO_CONFIGURACAO,
      payload: {configuracao: configuracao}
    };
  }
  else {
    return {
      type: ABORTA_OPERACAO,
      payload: null
    };
  }
}

export function changeDirection() {
  return {type: CHANGE_DIRECTION};
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export const fechaModulosPortaria = () => {
  return {
    type: FECHA_MODULOS_PORTARIA,
    payload: null
  };
};

export const selecionaModulosPortaria = (configuracao, modo, idAmbientePortaria, idPosicaoPortaria, pontosAcessoSelecionados, idIntervalGerado) => {
  
  clearInterval(idIntervalGerado);
  
  return [ {
    type: SELECIONA_MODULOS_PORTARIA,
    payload: { configuracao: configuracao, loginRecente: false }
  },
  {
    type: ATUALIZA_MODO_PORTARIA,
    payload: { modo, idAmbientePortaria, idPosicaoPortaria, pontosAcessoSelecionados }
  }]
};

export const irParaModulo = (modulo, configuracao) => {

  return [{
    type: IR_PARA_MODULO,
    payload: { nomeModulo: modulo, configuracao: configuracao }
  },
  {
    type: FECHA_MODULOS_PORTARIA,
    payload: null
  },
  modulo === 'Portaria' && {
    type: INICIA_DEFINICOES,
    payload: null
  },
  {
    type: VOLTAR_INICIO_PORTARIA,
    payload: null,
  },
  {
    type: FECHA_MODULOS,
    payload: null
  },
  {
    type: INIT_URL,
    payload: '/app/inicio'
  }]
};


