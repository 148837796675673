import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Acessos extends Component {


    render() {

        const carregandoPaginaPerfil = this.props.portariaInicio.carregandoPaginaPerfil;
        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        const portariaInicio = this.props.portariaInicio;
        const { classes } = this.props;
        var listaDeAmbientesPortariaCombo = [];
        var listaDeAmbientesPortaria = [];
        var listaPontosAcessoPessoaVisivelPorteiro = this.props.portariaInicio.pontosAcessoPessoaVisivelPorteiro;
        const validacoesModoTrabalho = this.props.validacoes;

        if (this.props.settings.configuracao.uzerAcessoAmbienteCliente) {
            listaDeAmbientesPortariaCombo = this.props.portariaInicio.listaDeAmbientesPortariaCombo;
            listaDeAmbientesPortaria = this.props.portariaInicio.listaDeAmbientesPortaria;
        }
        else {
            for (var indice in this.props.portariaInicio.listaDeAmbientesPortaria) {
                var ambientePortaria = this.props.portariaInicio.listaDeAmbientesPortaria[indice];

                if (this.props.settings.configuracao.listaPontosAcessoPortaria.filter(x => x.pontoAcesso.idAmbiente === ambientePortaria.id).length > 0) {
                    listaDeAmbientesPortariaCombo.push(this.props.portariaInicio.listaDeAmbientesPortariaCombo.filter(x => x.id === ambientePortaria.id)[0]);
                    listaDeAmbientesPortaria.push(ambientePortaria);
                }
            }
        }

        var listaRendericaoPontos = [];

        for (var indice in listaDeAmbientesPortaria) {
            var ambiente = listaDeAmbientesPortaria[indice];
            var nomeAmbientePortaria = ambiente.localControlado.descricaoLocalControlado + ' / ' + ambiente.descricaoAmbiente;

            for (var indice in ambiente.pontosAcesso) {
                var pontoAcesso = ambiente.pontosAcesso[indice];
                var marcado = this.props.portariaInicio.pontosSelecionadosPortaria.filter(x => x.idPontoAcesso === pontoAcesso.id).length > 0;
                var acessoAutorizado = listaPontosAcessoPessoaVisivelPorteiro.filter(x => x.idPontoPorteiro === pontoAcesso.id && x.possuiAcesso).length > 0;

                if (marcado) {
                    listaRendericaoPontos.push({
                        idPonto: pontoAcesso.id,
                        nomeAmbientePontoAcesso: nomeAmbientePortaria + ' / ' + pontoAcesso.descricaoPontoAcesso,
                        pessoaComAcessoAutorizado: acessoAutorizado,
                        ordenacao: acessoAutorizado ? 1 : 2
                    });
                }

            }

            listaRendericaoPontos = listaRendericaoPontos.sort((a, b) => Number(a.nomeAmbientePontoAcesso) - Number(b.nomeAmbientePontoAcesso));
        }

        return carregandoPaginaPerfil ? (
            <Fragment>
                <TableContainer elevation={0} className={classes.padding} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            <TableRow key={'exemplo01'}>
                                <TableCell align="left" style={{ width: '80%', fontSize: 16, fontWeight: 400, padding: 12 }}>
                                    <Skeleton variant="text" width={'80%'} height={50} />
                                </TableCell>

                                <TableCell align="left" style={{ float: 'left', width: '80%', fontSize: 16, fontWeight: 400, padding: 12 }}>
                                    <Skeleton variant="text" width={'100%'} height={60} />
                                </TableCell>
                            </TableRow>

                            <TableRow key={'exemplo02'}>
                                <TableCell align="left" style={{ width: '80%', fontSize: 16, fontWeight: 400, padding: 12 }}>
                                    <Skeleton variant="text" width={'80%'} height={50} />
                                </TableCell>

                                <TableCell align="left" style={{ float: 'left', width: '80%', fontSize: 16, fontWeight: 400, padding: 12 }}>
                                    <Skeleton variant="text" width={'100%'} height={60} />
                                </TableCell>
                            </TableRow>

                            <TableRow key={'exemplo03'}>
                                <TableCell align="left" style={{ width: '80%', fontSize: 16, fontWeight: 400, padding: 12 }}>
                                    <Skeleton variant="text" width={'80%'} height={50} />
                                </TableCell>

                                <TableCell align="left" style={{ float: 'left', width: '80%', fontSize: 16, fontWeight: 400, padding: 12 }}>
                                    <Skeleton variant="text" width={'100%'} height={60} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        ) : (
            (
                <Fragment>

                    {
                        listaRendericaoPontos.length === 0 || listaRendericaoPontos.filter(x => x.pessoaComAcessoAutorizado === true).length === 0 ?
                            (
                                <div style={{ width: '100%', textAlign: 'center', height: 240, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <div>
                                        <img src={require("assets/images/semAcessos.PNG")} alt="Sem acessos" title="Sem acessos" />

                                    </div>

                                    <div style={{textAlign: 'center', width: 355, marginLeft: 'auto', marginRight: 'auto'}}>
                                        <span style={{ fontWeight: 400, fontSize: 14, color: 'rgba(72, 81, 84, 0,6)' }}>Não há permissões de acesso válidas para esta pessoa nos pontos selecionados nesta portaria</span>
                                    </div>
                                </div>
                            ) :
                            (
                                <TableContainer elevation={0} className={classes.padding} component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableBody>

                                            {
                                                listaRendericaoPontos.map(ponto => {

                                                    return (
                                                        <TableRow key={ponto.idPonto}>
                                                            <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, padding: 12 }}>
                                                                <span style={{ float: 'left', width: '100%', fontSize: 18, fontWeight: 500, fontFamily: 'Montserrat, sans-serif', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                                    {ponto.nomeAmbientePontoAcesso}
                                                                </span>
                                                                {/*                                                                         <span style={{ float: 'left', width: '100%', fontSize: 16, fontWeight: 400, color: 'rgba(0, 0, 0, 0.6)' }}>
                                                                                    Seg. a Sáb - das 07:00 às 21:00
                                                                                </span> */}
                                                            </TableCell>

                                                            <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, padding: 12 }}>
                                                                <span style={{ width: '100%' }}>
                                                                    <div style={{ width: '85%', textAlign: 'center', marginTop: 8, marginLeft: 'auto', marginRight: 'auto' }} >
                                                                        {
                                                                            ponto.pessoaComAcessoAutorizado ? portariaInicio.loadingLiberacaoTerminal && portariaInicio.idPontoLoadingLiberacaoTerminal == ponto.idPonto ? (
                                                                                <Button style={{ border: 'solid 1px #ced4da', width: 185, height: 48, fontSize: 16, textTransform: 'none', fontWeight: 400, borderRadius: 60, color: '#05B59D' }} variant="outlined"> <CircularProgress size={20} style={{marginRight: 10}}/>Liberando...</Button>
                                                                            ) : (<Button onClick={() => this.props.enviaSolicitacaoAberturaTerminal(ponto.idPonto, pessoaSelecionada.idPessoa)} style={{ border: 'solid 1px #ced4da', width: 185, height: 48, fontSize: 16, textTransform: 'none', fontWeight: 400, borderRadius: 60, color: '#05B59D' }} variant="outlined">Liberar terminal</Button>) :
                                                                                (
                                                                                    <div style={{ cursor: 'not-allowed' }}>
                                                                                        <Button disabled style={{ border: 'solid 1px #ced4da', width: 185, height: 48, fontSize: 16, textTransform: 'none', fontWeight: 400, borderRadius: 60 }} variant="outlined">Sem acesso</Button>
                                                                                    </div>
                                                                                )
                                                                        }

                                                                    </div>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                    }


                </Fragment>
            )
        )
    }

}