import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {  userSignOut } from "actions/Auth";
import { SALVA_ACESSO_GENERO, INICIA_ACESSO_GENERO, BUSCA_ACESSO_GENERO_EDICAO, REMOVE_ACESSO_GENERO,
         LISTA_CONFIGURACAO_PONTO_GENERO, LISTA_LOCAIS_POR_CLIENTE_GENERO, LISTA_ACESSO_GENERO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaAcessoGeneroSucesso,
         removeAcessoGeneroSucesso, 
         inicioAcessoGeneroSucesso, 
         iniciaAcessoGenero,
         listaLocaisSucesso,
         buscaAcessoGeneroEdicaoSucesso,
         listaConfiguracaoPontoGeneroSucesso,
         listaAcessoGeneroSucesso } from "actions/AcessoGenero";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getAcessosGenero = async () =>
    await requisicaoApi.get('/acessosGenero')
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error); 
    
    const getPerfils = async (cliente) =>
    await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);  

  const getAcessoGeneroEdicao = async (id) =>
    await requisicaoApi.get('/acessosGenero/' + id)
    .then(resposta => resposta)
    .catch(error => error);

  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);    
    
  const getlistaConfiguracaoPontoGenero = async (idLocal, sexo) => 
    await requisicaoApi.get('/acessosGenero/vinculoPontoAcesso/' + idLocal + '/' + sexo)
    .then(resposta => resposta)
    .catch(error => error);

    const getListaAcessoGenero = async (generoFiltro, ambienteFiltro, pontoFiltro) => 
    await requisicaoApi.get('/acessosGenero?Genero=' +  generoFiltro + '&Ambiente=' + ambienteFiltro + '&PontoAcesso=' + pontoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  
    
/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaAcessoGeneroRequest = async (acessoGenero, pontosAssociados) => await requisicaoApi.post('/acessosGenero', 
  {
    "id": acessoGenero.id,
    "idCriterio": 0,
    "criterioAcesso": {
      "tipoCriterioAcesso": "GENERO",
      "ativo": true,
      "minutosPadraoRetorno": 0,
      "pontosAcessoVinculados": pontosAssociados,
      "id": 0
    },
    "nomeSexo": acessoGenero.sexo,
    "idCliente": acessoGenero.idCliente,
    "idLocalControlado": acessoGenero.idLocalControlado,
    "idPessoa": acessoGenero.idPessoa,
    "perfils": acessoGenero.perfils
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaAcessoGeneroRequest = async (acessoGenero, pontosAssociados) => await requisicaoApi.put('/acessosGenero', 
  {
    "id": acessoGenero.id,
      "idCriterio": 0,
      "criterioAcesso": {
        "tipoCriterioAcesso": "GENERO",
        "ativo": true,
        "minutosPadraoRetorno": 0,
        "pontosAcessoVinculados": pontosAssociados,
        "id": 0
      },
      "nomeSexo": acessoGenero.sexo,
      "sexo": acessoGenero.sexo,
      "idCliente": acessoGenero.idCliente,
      "idLocalControlado": acessoGenero.idLocalControlado,
      "idPessoa": acessoGenero.idPessoa,
      "perfils": acessoGenero.perfils
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteAcessoGeneroRequest = async (id) =>
  await requisicaoApi.delete('/acessosGenero?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

function* fnIniciaAcessoGenero() {
  try {
    const listagemGrid = yield call(getAcessosGenero);

    if(listagemGrid.data)
    {
      const clientes = yield call(getClientes);

      if(clientes.data) {
        const clienteFixo = localStorage.getItem('idClienteFixado');

        if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
        {
          const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
          const perfis = yield call(getPerfils, parseInt(clienteFixo));

          yield put(inicioAcessoGeneroSucesso(listagemGrid.data, clientes.data, locais.data, perfis.data));
        }
        else
        {
          yield put(inicioAcessoGeneroSucesso(listagemGrid.data, clientes.data, null, null));
        }
      }
    }
    else {
      if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
      {
        yield put(userSignOut());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao listar acessosGenero'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaLocaisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
    const retornoPerfil = yield call(getPerfils, cliente);

    if(retorno.data)
    {
      yield put(listaLocaisSucesso(retorno.data, retornoPerfil.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
}

function* fnlistaConfiguracaoPontoGenero({ payload }) {
  try {
    const idLocal = payload.idLocal;
    const sexo = payload.sexo
    const retorno = yield call(getlistaConfiguracaoPontoGenero, idLocal, sexo);

    if(retorno.data)
    {
      yield put(listaConfiguracaoPontoGeneroSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso do local: ' + idLocal));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

  function* fnBuscaAcessoGeneroEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getAcessoGeneroEdicao, id);
  
      if(retorno.data)
      {
        yield put(buscaAcessoGeneroEdicaoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do acessoGenero: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaAcessoGenero({ payload }) {
  try {

    const acessoGenero = payload.acessoGenero;
    const pontosAssociados = payload.listaPontosAssociados;
    var resultado = "";

      if(acessoGenero.id === 0) {
        const retorno = yield call(adicionaAcessoGeneroRequest, acessoGenero, pontosAssociados);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaAcessoGeneroRequest, acessoGenero, pontosAssociados);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaAcessoGenero());
        yield put(salvaAcessoGeneroSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }         

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveAcessoGenero({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteAcessoGeneroRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaAcessoGenero());
        yield put(removeAcessoGeneroSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* fnListaAcessoGenero({ payload }) {
  try {
    const generoFiltro = payload.genero;
    const ambienteFiltro = payload.ambiente;
    const pontoFiltro = payload.ponto;
    const retorno = yield call(getListaAcessoGenero, generoFiltro, ambienteFiltro, pontoFiltro);

    if(retorno.data)
    {
      yield put(listaAcessoGeneroSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de acesso gênero'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 


/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesAcessoGenero() {
  yield takeEvery(INICIA_ACESSO_GENERO, fnIniciaAcessoGenero);
}

export function* salvaAcessoGenero() {
  yield takeEvery(SALVA_ACESSO_GENERO, fnSalvaAcessoGenero);
}

export function* buscaAcessoGeneroEdicao() {
  yield takeEvery(BUSCA_ACESSO_GENERO_EDICAO, fnBuscaAcessoGeneroEdicao);
}

export function* removeAcessoGenero() {
  yield takeEvery(REMOVE_ACESSO_GENERO, fnRemoveAcessoGenero);
}

export function* listaLocaisPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_GENERO, fnListaLocaisPorCliente)
}

export function* listaConfiguracaoPontoGenero() {
  yield takeEvery(LISTA_CONFIGURACAO_PONTO_GENERO, fnlistaConfiguracaoPontoGenero)
}

export function* listaAcessoGenero() {
  yield takeEvery(LISTA_ACESSO_GENERO, fnListaAcessoGenero)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesAcessoGenero),
             fork(salvaAcessoGenero), 
             fork(buscaAcessoGeneroEdicao),
             fork(removeAcessoGenero),
             fork(listaLocaisPorCliente),
             fork(listaConfiguracaoPontoGenero),
             fork(listaAcessoGenero)
            ]);
}