import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  USER_TOKEN_SET,
  TOKEN_INVALIDO,
  FECHA_MODULOS,
  SELECIONA_MODULOS  
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('user_id'),
  token: JSON.parse(localStorage.getItem('token')),
  perfil: '',
  invalidToken: false,
  selecionarModulo: false,
  acessaPortaria: false,
  acessaBackOffice: false,
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {

      const retorno = action.payload;
      var perfilAdministrador = retorno.perfis.filter(x => x.descricaoPerfil === 'Administrador Uzer');
      var _acessaPortaria = retorno.configuracao.visualizaDadosPortaria || retorno.configuracao.concedeAcessos || retorno.configuracao.listaPontosAcessoPortaria.length > 0
      var _acessaBackOffice = retorno.configuracao.listaConfiguracaoPortalFuncionalideAcao.length > 0;

      return {
        ...state,
        loader: false,
        authUser: retorno.authUser,
        selecionarModulo: _acessaPortaria && _acessaBackOffice,
        perfil: /* perfilAdministrador.length > 0 ?  */'Administrador'/*  : 'Usuário Comum' */,
        acessaPortaria: _acessaPortaria,
        acessaBackOffice: _acessaBackOffice,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case FECHA_MODULOS: {
      return {
        ...state,
        selecionarModulo: false,
      }
    }
    case SELECIONA_MODULOS: {
      return {
        ...state,
        selecionarModulo: true,
      }
    }    
    case SIGNOUT_USER_SUCCESS: {

      return {
        ...state,
        authUser: null,
        token: null,
        initURL: '',
        loader: false,
        selecionarModulo: false
      }
    }
    case TOKEN_INVALIDO: {
      return {
        ...state,
        invalidToken: true
      }
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}
