import {
    EXIBE_PONTOS_SELECAO,
    CONFIRMAR_SELECAO_PONTOS,
    ATIVA_DESATIVA_BUSCA_NOTIFICACOES,
    EXIBE_TELA_SELECAO_MODO
  } from 'constants/ActionTypes';

  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais ---- -----------------------------------*/

  export const exibirPontosParaSelecao = () => {
    return {
      type: EXIBE_PONTOS_SELECAO,
      payload: null
    };
  }

  export const exibeTelaSelecaoModo = () => {
    return {
      type: EXIBE_TELA_SELECAO_MODO,
      payload: null
    };
  }

  export const ativaDesativaNotificacoes = () => {
    return {
      type: ATIVA_DESATIVA_BUSCA_NOTIFICACOES,
      payload: null
    };
  }


  export const confirmarSelecaoDePontos = () => {
    return {
      type: CONFIRMAR_SELECAO_PONTOS,
      payload: null
    };
  }