import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask, horarioMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo, retornaDescricaoPorValorListaPadrao, criarPadrao } from 'luxFw/transformations/Common';
import moment from 'moment';
import {
    INICIA_PESSOAS,
    INICIA_LIBERACOES,
    ALTERACAO_CAMPO_PORTARIA,
    ALTERACAO_CAMPO_PESQUISA_PESSOA_TESTE,
    ALTERACAO_CAMPO_PESQUISA_UNIDADE_GARAGEM,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_DATEPICKER,
    ALTERACAO_CHECK,
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    ALTERA_PERIODO_VALIDADE_LIBERACAO,
    ALTERA_PERIODO_DIA_SEMANA_LIBERACAO,
    ALTERA_DIA_SEMANA_GERAL,
    ALTERA_PERIODO_HORARIO_LIBERACAO,
    INIT_URL,
    INICIA_PORTARIA,
    INICIA_PORTARIA_SUCESSO,
    INICIA_PORTARIA_LIBERACAO,
    INICIA_PORTARIA_LIBERACAO_SUCESSO,
    SELECIONA_PESSOA_PORTARIA,
    SELECIONA_PESSOA_PORTARIA_SUCESSO,
    SELECIONA_LIBERACAO_PORTARIA,
    BUSCA_SOLICITACAO_SUCESSO,
    BUSCA_FILA_POSICAO_SUCESSO,
    ATIVA_DESATIVA_BUSCA_NOTIFICACOES,
    ATUALIZAR_PONTO_REQUISICAO_PORTEIRO,
    EXIBE_TELA_SELECAO_MODO,
    ATUALIZA_MODO_PORTARIA_SUCESSO,
    SHOW_MESSAGE,
    EXECUTA_SIMULACAO,
    EXECUTA_SIMULACAO_SUCESSO,
    DECIDIR_ATENDTO_DUPLO_CHECK,
    DECIDIR_ATENDTO_DUPLO_CHECK_SUCESSO,
    ANALISAR_DETALHES_DC,
    SETAR_IMAGEM_LIBERACAO,
    CAPTURA_IMAGEM_LIBERACAO,
    SALVA_LIBERACAO_SUCESSO,
    ALTERA_VALOR_CHECK_LIBERACAO,
    EXIBE_MENSAGEM_ERRO_PORTARIA,
    OCULTA_MENSAGEM_PORTARIA,
    NOVA_LIBERACAO,
    ATUALIZA_HORARIO_LIBERACAO,
    VOLTAR_INICIO_PORTARIA,
    ADICIONA_OBSERVACAO_PESSOA_PERFIL,
    ADICIONA_OBSERVACAO_PESSOA_PERFIL_SUCESSO,
    EXIBE_OCULTA_OBSERVACOES_PERFIL_PESSOA,
    DESATIVA_TIMER,
    SETAR_ID_INTERVAL,
    ATUALIZA_DATA_PESQUISA_LIBERACAO,
    LIMPA_PESQUISA_LIBERACAO,
    BUSCA_PESSOA_CADASTRADA_LIBERACAO,
    BUSCA_PESSOA_CADASTRADA_LIBERACAO_SUCESSO,
    FECHAR_PESSOA_EXISTENTE_LIBERACAO,
    CARREGA_PESSOAS_PARA_TESTES,
    CARREGA_PESSOAS_PARA_TESTES_SUCESSO,
    ABORTA_OPERACAO,
    ENVIA_SOLICITACAO_ABERTURA_TERMINAL,
    ENVIA_SOLICITACAO_ABERTURA_TERMINAL_SUCESSO,
    ENVIA_SOLICITACAO_ABERTURA_TERMINAL_ERRO,
    SINCRONIZACAO_PESSOA_ERRO,
    SINCRONIZAR_LIBERACAO,
    CANCELA_CAPTURA_IMAGEM_PORTARIA,
    OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA_SUCESSO,
    EXIBE_LOTACAO_GARAGENS,
    EXIBE_LOTACAO_GARAGENS_SUCESSO,
    DESOCUPAR_VAGA_GARAGEM,
    APLICA_ALTERACOES_GARAGENS,
    APLICA_ALTERACOES_GARAGENS_SUCESSO
  } from "constants/ActionTypes";
  
  
  const INIT_STATE = {

    // Propriedades Globais
    visaoPessoas: true,
    exibeSumarioSincronizacao: false,
    resultadoSincronizacao: null,
    visaoLiberacoes: false,
    textoPessoaPesquisada: '',
    visaoPesquisaInicial: true,
    exibeNotificacoes: false,
    emDecisaoDuploCheck: false,
    carregandoInformacoes: false,
    exibeMensagemSucessoPortaria: false,
    exibeMensagemErroPortaria: false,
    mensagemAlertaPortaria: '',
    carregandoPaginaPerfil: false,
    exibirDrawerObservacoes: false,
    loadingBotaoAdicionarObservacao: false,
    idIntervalGerado: 0,
    dataPesquisaLiberacoes: '',
    textoPesquisaLiberacoes: '',
    loadingLiberacaoTerminal: false,
    loadingLiberacaoPessoa: false,
    idPontoLoadingLiberacaoTerminal: 0,
    parametroOcultaTelefone: false,
    parametroOcultaDocumento: false,
    exibirOcupacaoGaragens: false,
    parametroTipoControleVisibilidade: 'NENHUM',
    textoFiltroGaragemUnidade: '',

    listaDePontosSelecionados: [],
    listaDeAmbientesPortariaCombo: [],
    listaDeAmbientesPortaria: [],
    listaDeAmbientesCliente: [],
    listaDeAmbientesClienteCombo: [],
    listaPanoramaAmbientesPerfilTemporario: [],
    listaDeUsuariosPlataforma: [],
    listaDeUsuariosPlataformaFiltrada: [],
    listaDeUsuariosPlataformaTeste: [],
    listaDeUsuariosPlataformaFiltroteste: [],
    textoFiltroPessoasTeste: '',
    listaDePerfisTemporarios: [],
    listaDePerfisTemporariosCompleto: [],
    listaDePontosDeAcessoOrigem: [],
    listaDeLiberacoes: [],
    pessoaSelecionada: {},
    timerAtivado: false,
    selecionarModoTrabalho: true,
    idAmbientePortariaSelecionado: 0,
    idPontoAcessoOrigem: 0,
    idAmbienteDestino: 0,
    pontosSelecionadosPortaria: [],
    idPosicaoPortaria: 0,
    idPontoSimulado: 0,
    executandoSimulacao: false,
    exibeResultadoTemporario: false,
    acessoSimuladoPermitido: false,
    atendimentosFila: [],
    exibeAnaliseDuploCheck: false,
    idAtendtoDCAnalise: 0,
    imagemAnaliseDC: null,
    simulacoes: [],
    lotacoesGaragemDesocupar: [],
    blocoDescritivoPerfilPessoaSelecionada: '',
    possuiBiometriaMao: false,
    possuiBiometriaSimples: false,
    possuiBiometriaFacial: false,
    carregandoGaragens: false,
    garagensSalvas: false,

    // Liberações
    descricaoNumeroDocumentoRegistroPrevio: '',
    dataNascimentoRegistroPrevio: '',
    numeroTelefoneRegistroPrevio: '',
    descricaoNomeRegistroPrevio: '',
    descricaoCargo: '',
    descricaoEmpresa: '',
    imagemCapturadaPessoa: '',
    imagemCapturadaPessoaThumb: '',
    ativarCameraFotoPessoa: false,
    imagemCapturadaDocumento: '',
    ativarCameraFotoDocumento: false,
    complementosPessoaSelecionada: [],
    vinculosPessoaSelecionada: [],
    convidadosPessoaSelecionada: [],
    pontosAcessoPessoaVisivelPorteiro: [],
    registrosAcessosHistoricos: [],
    observacoesPessoaPortaria: [],
    ambientesDoAutorizante: [],
    listaAmbientesDestino: [],


    // Formulario Liberação
    idLiberacao: 0,
    idPessoaLiberada: 0,
    idPessoaAutorizante: 0,
    idUsuarioSistemaRegistro: 0,
    descricaoNomeRegistroPrevio: '',
    descricaoNumeroDocumentoRegistroPrevio: '',
    descricaoNumeroCPFRegistroPrevio: '',
    tipoDocumentoRegistroPrevio: '',
    sexoRegistroPrevio: 'NAOINFORMADO',
    dataNascimentoRegistroPrevio: '',
    numeroTelefoneRegistroPrevio: '',
    autorizante: {},
    imagemDocumentoLiberacao: '',
    imagemAutorizante: '',
    idPerfilSistema: 0,
    idAmbienteOrigem: 0,
    idPontoAcessoOrigem: 0,
    idAmbienteDestino: 0,
    descricaoCargo: '',
    descricaoEmpresa: '',
    ehFluxoIlimitado: false,
    permiteAcompanhantes: false,
    observacoes: '',
    periodoLiberacaoInicio: '',
    periodoLiberacaoFim: '',
    periodoLiberacaoInicioDP: '',
    periodoLiberacaoFimDP: '',
    horarioInicial: '',
    horarioFinal: '',
    periodoEspecificoSegunda: true,
    periodoEspecificoTerca: true,
    periodoEspecificoQuarta: true,
    periodoEspecificoQuinta: true,
    periodoEspecificoSexta: true,
    periodoEspecificoSabado: true,
    periodoEspecificoDomingo: true,
    observacaoPerfilAdicionar: '',
    statusLiberacao: '',

    /// cadastros existentes
    cadastroExistenteLiberacao: false,
    nomePessoaCadastroExistente: '',
    cpfPessoaCadastroExistente: '',
    telefonePessoaCadastroExistente: '',
    imagemPessoaCadastroExistente: '',
    possuiBiometriaMaoCadastroExistente: false,
    possuiBiometriaFacialCadastroExistente: false,
    sexoCadastroExistente: '',
    dataNascimentoCadastroExistente: ''
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }
      case INICIA_PESSOAS: {
        return {
          ...state,
          visaoPessoas: true,
          visaoLiberacoes: false,
          visaoPesquisaInicial: true,
          textoPessoaPesquisada: '',
          textoFiltroGaragemUnidade: ''
        }
      }      
      case INICIA_LIBERACOES: {
        return {
          ...state,
          visaoPessoas: false,
          visaoLiberacoes: true,
          dataPesquisaLiberacoes: moment(),
          textoPesquisaLiberacoes: ''
        }
      }
      case EXIBE_TELA_SELECAO_MODO: {
        return {
          ...state,
          selecionarModoTrabalho: true
        }
      }
      case ALTERA_VALOR_CHECK_LIBERACAO: {

        var campo = action.payload.campo;

        return {
          ...state,
          ehFluxoIlimitado: campo === 'LimiteAcesso' ? !state.ehFluxoIlimitado : state.ehFluxoIlimitado,
          permiteAcompanhantes: campo === 'Acompanhantes' ? !state.permiteAcompanhantes : state.permiteAcompanhantes,
        }
      }
      case SALVA_LIBERACAO_SUCESSO: {

        var retorno = action.payload;
        var liberacoes = state.listaDeLiberacoes;
        var convidados = state.convidadosPessoaSelecionada;
        var idLiberacao = retorno.liberacao.idLiberacao === undefined ? retorno.liberacao.id : retorno.liberacao.idLiberacao;

        if(retorno.liberacao !== null && state.listaDeLiberacoes.filter(x => x.idLiberacao === idLiberacao).length === 0)
        {
          var autorizante = state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === retorno.liberacao.idPessoaAutorizLiberacao)[0];
          var ambienteOrigem = state.listaDeAmbientesPortariaCombo.filter(x => x.id === retorno.liberacao.idAmbienteOrigem)[0];
          var ambienteOrigemFormatado = ambienteOrigem.title.substring(ambienteOrigem.title.lastIndexOf('/') + 1, ambienteOrigem.title.length);
          ambienteOrigemFormatado = ambienteOrigemFormatado + ' / ' + ambienteOrigem.title.substring(0, ambienteOrigem.title.indexOf('/') - 1);
          ambienteOrigemFormatado = ambienteOrigemFormatado.trim();

          var liberacaoIncluida = { 
            idLiberacao: retorno.liberacao.id, 
            nomePessoaLiberada: retorno.liberacao.descricaoNomeRegistroPrevio,
            nomeRazaoAutorizante: autorizante.nomeRazao,
            dataOuPeriodoLiberacao: moment(retorno.liberacao.dataDaLiberacao).format("DD/MM/YYYY"),
            origem: ambienteOrigemFormatado,
            numeroTelefoneRegistroPrevio: retorno.liberacao.numeroTelefoneRegistroPrevio,
            dataNascimentoRegistroPrevio: retorno.liberacao.dataNascimentoRegistroPrevioStr,
            imagemPessoa: retorno.liberacao.imagemPessoaLiberacao,
            idPessoa: retorno.liberacao.idPessoa,
            idPessoaAutorizante: retorno.liberacao.idPessoaAutorizLiberacao,
            idPerfilSistema: retorno.liberacao.idPerfilSistema,
            numeroDocumento: retorno.liberacao.descricaoNumeroDocumentoRegistroPrevio,
            idPontoAcessoOrigem: retorno.liberacao.idPontoAcessoOrigem,
            idAmbienteDestino: retorno.liberacao.idAmbienteDestino,
            idAmbienteOrigem: retorno.liberacao.idAmbienteOrigem,
            descricaoCargo: retorno.liberacao.descricaoCargo,
            descricaoEmpresa: retorno.liberacao.descricaoEmpresa,
            ehFluxoIlimitado: retorno.liberacao.ehFluxoIlimitado,
            permiteAcompanhantes: retorno.liberacao.permiteAcompanhantes,
            observacoes: retorno.liberacao.observacoes,
            periodoLiberacaoInicio: moment(retorno.liberacao.periodoLiberacaoInicio).format("DD/MM/YYYY"),
            periodoLiberacaoFim: moment(retorno.liberacao.periodoLiberacaoFim).format("DD/MM/YYYY"),
            sexoRegistroPrevio: retorno.liberacao.sexoRegistroPrevio,
            horarioInicial: retorno.liberacao.horarioInicial,
            horarioFinal: retorno.liberacao.horarioFinal,
            observacoes: retorno.liberacao.observacao,
            periodoEspecificoSegunda: retorno.liberacao.somenteSegunda,
            periodoEspecificoTerca: retorno.liberacao.somenteTerca,
            periodoEspecificoQuarta: retorno.liberacao.somenteQuarta,
            periodoEspecificoQuinta: retorno.liberacao.somenteQuinta,
            periodoEspecificoSexta: retorno.liberacao.somenteSexta,
            periodoEspecificoSabado: retorno.liberacao.somenteSabado,
            periodoEspecificoDomingo: retorno.liberacao.somenteDomingo
          }

          liberacoes = [...liberacoes, liberacaoIncluida];
        }
        else {
          for(var item in liberacoes) {
            var liberacaoCorrente = liberacoes[item];

            if(liberacaoCorrente.idLiberacao === state.idLiberacao) {
              liberacaoCorrente.idPessoaAutorizante = state.idPessoaAutorizante;
              liberacaoCorrente.idAmbienteOrigem = state.idAmbienteOrigem;
              liberacaoCorrente.idPerfilSistema = state.idPerfilSistema;
              liberacaoCorrente.idPontoAcessoOrigem = state.idPontoAcessoOrigem;
              liberacaoCorrente.idAmbienteDestino = state.idAmbienteDestino;
              liberacaoCorrente.imagemPessoa = state.imagemCapturadaPessoa;
              liberacaoCorrente.imagemDocumento = state.imagemCapturadaDocumento;
              liberacaoCorrente.descricaoEmpresa = state.descricaoEmpresa;
              liberacaoCorrente.descricaoCargo = state.descricaoCargo;
              liberacaoCorrente.nomePessoaLiberada = state.descricaoNomeRegistroPrevio;
              liberacaoCorrente.descricaoNumeroCPFRegistroPrevio = state.descricaoNumeroCPFRegistroPrevio;
              liberacaoCorrente.sexoRegistroPrevio = state.sexoRegistroPrevio;
              liberacaoCorrente.dataNascimentoRegistroPrevio = state.dataNascimentoRegistroPrevio;
              liberacaoCorrente.numeroTelefoneRegistroPrevio = state.numeroTelefoneRegistroPrevio;
              liberacaoCorrente.permiteAcompanhantes = state.permiteAcompanhantes;
              liberacaoCorrente.ehFluxoIlimitado = state.ehFluxoIlimitado;
              liberacaoCorrente.periodoLiberacaoInicio = state.periodoLiberacaoInicio;
              liberacaoCorrente.periodoLiberacaoFim = state.periodoLiberacaoFim;
              liberacaoCorrente.horarioInicial = state.horarioInicial;
              liberacaoCorrente.horarioFinal = state.horarioFinal;
              liberacaoCorrente.observacao = state.observacoes;
              liberacaoCorrente.somenteSegunda = state.periodoEspecificoSegunda;
              liberacaoCorrente.somenteTerca = state.periodoEspecificoTerca;
              liberacaoCorrente.somenteQuarta = state.periodoEspecificoQuarta;
              liberacaoCorrente.somenteQuinta = state.periodoEspecificoQuinta;
              liberacaoCorrente.somenteSexta = state.periodoEspecificoSexta;
              liberacaoCorrente.somenteSabado = state.periodoEspecificoSabado;
              liberacaoCorrente.somenteDomingo = state.periodoEspecificoDomingo;
            }
          }
        }

        if(retorno.liberacao !== null && state.listaDeLiberacoes.filter(x => x.idLiberacao === idLiberacao).length === 0)
        {
          var autorizante = state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === retorno.liberacao.idPessoaAutorizLiberacao)[0];
/*           var ambienteOrigem = state.listaAmbientesCliente.filter(x => x.id === retorno.liberacao.idAmbienteOrigem)[0];
          var pontoOrigem = state.listaDePontosDeAcessoOrigem.filter(x => x.id === retorno.liberacao.idPontoAcessoOrigem)[0]; */

          var convidadoIncluido = { 
            idLiberacao: retorno.liberacao.id, 
            nomeAutorizado: retorno.liberacao.descricaoNomeRegistroPrevio,
            nomeAutorizante: autorizante.nomeRazao,
            origem: ambienteOrigemFormatado,
            imagemAutorizado: retorno.liberacao.imagemPessoaLiberacao,
            idPessoa: retorno.liberacao.idPessoa
          }

          convidados = [...convidados, convidadoIncluido];
        }

        return {
          ...state,
          exibeSumarioSincronizacao: false,
          resultadoSincronizacao: null,
          loader: false,
          mensagemAlertaPortaria: 'Liberação salva com sucesso',
          exibeMensagemSucessoPortaria: true, 
          listaDeLiberacoes: retorno.liberacao !== null ? liberacoes : state.listaDeLiberacoes,
          convidadosPessoaSelecionada:  retorno.liberacao !== null ? convidados : state.convidadosPessoaSelecionada,
          loadingLiberacaoPessoa: false,  
          idPessoaAutorizante: retorno.liberacao.idPessoaAutorizLiberacao
        }
      }
      case ATUALIZA_HORARIO_LIBERACAO: {

        var retorno = action.payload;
  
        return {
          ...state,
          horarioInicial: retorno.campoAtualizar === 'horarioInicial' ? horarioMask(retorno.valor) : state.horarioInicial,
          horarioFinal: retorno.campoAtualizar === 'horarioFinal' ? horarioMask(retorno.valor) : state.horarioFinal,
        }
      }
      case ATUALIZA_DATA_PESQUISA_LIBERACAO: {
        var retorno = action.payload;
  
        return {
          ...state,
          dataPesquisaLiberacoes: moment(state.dataPesquisaLiberacoes).add(retorno,'days')
        }
      }
      case ENVIA_SOLICITACAO_ABERTURA_TERMINAL: {
        var retorno = action.payload;

        return {
          ...state,
          loadingLiberacaoTerminal: true,
          idPontoLoadingLiberacaoTerminal: retorno.idPonto
        }

      }
      case ENVIA_SOLICITACAO_ABERTURA_TERMINAL_SUCESSO: {
        var retorno = action.payload;
  
        return {
          ...state,
          loadingLiberacaoTerminal: false,
          registrosAcessosHistoricos: retorno.data
        }
      }
      case ENVIA_SOLICITACAO_ABERTURA_TERMINAL_ERRO: {

        return {
          ...state,
          loadingLiberacaoTerminal: false,
          idPontoLoadingLiberacaoTerminal: 0
        }
      }
      case SINCRONIZAR_LIBERACAO: {
        return {
          ...state,
          loadingLiberacaoPessoa: true,
        }
      }
      case EXIBE_LOTACAO_GARAGENS: {
        return {
          ...state,
          textoFiltroGaragemUnidade: '',
          carregandoGaragens: true,
          lotacoesGaragemDesocupar: [],
          exibirOcupacaoGaragens: true,
          garagensSalvas: false
        }
      }
      case EXIBE_LOTACAO_GARAGENS_SUCESSO: {

        var retorno = action.payload;

        return {
          ...state,
          carregandoGaragens: false,
          listaLotacaoGaragens: retorno.lotacaoGaragens,
          listaLotacaoGaragensFiltrada: retorno.lotacaoGaragens
        }
      }
      case CANCELA_CAPTURA_IMAGEM_PORTARIA: {

        var tipo = action.payload;

        return {
          ...state,
          ativarCameraFotoDocumento: tipo === 'Documento' ? false : state.ativarCameraFotoDocumento,
          ativarCameraFotoPessoa: tipo === 'Pessoa' ? false : state.ativarCameraFotoPessoa,
        }
      }
      case SINCRONIZACAO_PESSOA_ERRO: {

        var retorno = action.payload;
        var liberacoes = state.listaDeLiberacoes;
        var convidados = state.convidadosPessoaSelecionada;

        if(retorno.liberacao !== null && retorno.liberacao !== undefined && state.listaDeLiberacoes.filter(x => x.idLiberacao === retorno.liberacao.id).length === 0)
        {
          var autorizante = state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === retorno.liberacao.idPessoaAutorizLiberacao)[0];
          var ambienteOrigem = state.listaDeAmbientesPortariaCombo.filter(x => x.id === retorno.liberacao.idAmbienteOrigem)[0];
          var ambienteOrigemFormatado = ambienteOrigem.title.substring(ambienteOrigem.title.lastIndexOf('/') + 1, ambienteOrigem.title.length);
          ambienteOrigemFormatado = ambienteOrigemFormatado + ' / ' + ambienteOrigem.title.substring(0, ambienteOrigem.title.indexOf('/') - 1);
          ambienteOrigemFormatado = ambienteOrigemFormatado.trim();

          var liberacaoIncluida = { 
            idLiberacao: retorno.liberacao.id, 
            nomePessoaLiberada: retorno.liberacao.descricaoNomeRegistroPrevio,
            nomeRazaoAutorizante: autorizante.nomeRazao,
            dataOuPeriodoLiberacao: moment(retorno.liberacao.dataDaLiberacao).format("DD/MM/YYYY"),
            origem: ambienteOrigemFormatado,
            imagemPessoa: retorno.liberacao.imagemPessoaLiberacao,
            idPessoa: retorno.liberacao.idPessoaLiberada,
            idPessoaAutorizante: retorno.liberacao.idPessoaAutorizLiberacao,
            idPerfilSistema: retorno.liberacao.idPerfilSistema,
            numeroDocumento: retorno.liberacao.descricaoNumeroDocumentoRegistroPrevio,
            idPontoAcessoOrigem: retorno.liberacao.idPontoAcessoOrigem,
            idAmbienteDestino: retorno.liberacao.idAmbienteDestino,
            idAmbienteOrigem: retorno.liberacao.idAmbienteOrigem,
            descricaoCargo: retorno.liberacao.descricaoCargo,
            descricaoEmpresa: retorno.liberacao.descricaoEmpresa,
            ehFluxoIlimitado: retorno.liberacao.ehFluxoIlimitado,
            permiteAcompanhantes: retorno.liberacao.permiteAcompanhantes,
            observacoes: retorno.liberacao.observacoes,
            periodoLiberacaoInicio: moment(retorno.liberacao.periodoLiberacaoInicio).format("DD/MM/YYYY"),
            periodoLiberacaoFim: moment(retorno.liberacao.periodoLiberacaoFim).format("DD/MM/YYYY"),
            sexoRegistroPrevio: retorno.liberacao.sexoRegistroPrevio,
            horarioInicial: retorno.liberacao.horarioInicial,
            horarioFinal: retorno.liberacao.horarioFinal,
            observacoes: retorno.liberacao.observacao,
            periodoEspecificoSegunda: retorno.liberacao.somenteSegunda,
            periodoEspecificoTerca: retorno.liberacao.somenteTerca,
            periodoEspecificoQuarta: retorno.liberacao.somenteQuarta,
            periodoEspecificoQuinta: retorno.liberacao.somenteQuinta,
            periodoEspecificoSexta: retorno.liberacao.somenteSexta,
            periodoEspecificoSabado: retorno.liberacao.somenteSabado,
            periodoEspecificoDomingo: retorno.liberacao.somenteDomingo
          }

          liberacoes = [...liberacoes, liberacaoIncluida];
        }
        else {
          for(var item in liberacoes) {
            var liberacaoCorrente = liberacoes[item];

            if(liberacaoCorrente.idLiberacao === state.idLiberacao) {
              liberacaoCorrente.idPessoaAutorizante = state.idPessoaAutorizante
              liberacaoCorrente.idAmbienteOrigem = state.idAmbienteOrigem;
              liberacaoCorrente.idPerfilSistema = state.idPerfilSistema;
              liberacaoCorrente.idPontoAcessoOrigem = state.idPontoAcessoOrigem;
              liberacaoCorrente.idAmbienteDestino = state.idAmbienteDestino;
              liberacaoCorrente.imagemPessoa = state.imagemCapturadaPessoa;
              liberacaoCorrente.imagemDocumento = state.imagemCapturadaDocumento;
              liberacaoCorrente.descricaoEmpresa = state.descricaoEmpresa;
              liberacaoCorrente.descricaoCargo = state.descricaoCargo;
              liberacaoCorrente.nomePessoaLiberada = state.descricaoNomeRegistroPrevio;
              liberacaoCorrente.descricaoNumeroCPFRegistroPrevio = state.descricaoNumeroCPFRegistroPrevio;
              liberacaoCorrente.sexoRegistroPrevio = state.sexoRegistroPrevio;
              liberacaoCorrente.dataNascimentoRegistroPrevio = state.dataNascimentoRegistroPrevio;
              liberacaoCorrente.numeroTelefoneRegistroPrevio = state.numeroTelefoneRegistroPrevio;
              liberacaoCorrente.permiteAcompanhantes = state.permiteAcompanhantes;
              liberacaoCorrente.ehFluxoIlimitado = state.ehFluxoIlimitado;
              liberacaoCorrente.periodoLiberacaoInicio = state.periodoLiberacaoInicio;
              liberacaoCorrente.periodoLiberacaoFim = state.periodoLiberacaoFim;
              liberacaoCorrente.horarioInicial = state.horarioInicial;
              liberacaoCorrente.horarioFinal = state.horarioFinal;
              liberacaoCorrente.observacao = state.observacoes;
              liberacaoCorrente.somenteSegunda = state.periodoEspecificoSegunda;
              liberacaoCorrente.somenteTerca = state.periodoEspecificoTerca;
              liberacaoCorrente.somenteQuarta = state.periodoEspecificoQuarta;
              liberacaoCorrente.somenteQuinta = state.periodoEspecificoQuinta;
              liberacaoCorrente.somenteSexta = state.periodoEspecificoSexta;
              liberacaoCorrente.somenteSabado = state.periodoEspecificoSabado;
              liberacaoCorrente.somenteDomingo = state.periodoEspecificoDomingo;
            }
          }
        }

        if(retorno.liberacao !== null && retorno.liberacao !== undefined && state.listaDeLiberacoes.filter(x => x.idLiberacao === retorno.liberacao.id).length === 0)
        {
          var autorizante = state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === retorno.liberacao.idPessoaAutorizLiberacao)[0];
/*           var ambienteOrigem = state.listaAmbientesCliente.filter(x => x.id === retorno.liberacao.idAmbienteOrigem)[0];
          var pontoOrigem = state.listaDePontosDeAcessoOrigem.filter(x => x.id === retorno.liberacao.idPontoAcessoOrigem)[0]; */

          var convidadoIncluido = { 
            idLiberacao: retorno.liberacao.id, 
            nomeAutorizado: retorno.liberacao.descricaoNomeRegistroPrevio,
            nomeAutorizante: autorizante.nomeRazao,
            origem: ambienteOrigemFormatado,
            imagemAutorizado: retorno.liberacao.imagemPessoaLiberacao,
            idPessoa: retorno.liberacao.idPessoa
          }

          convidados = [...convidados, convidadoIncluido];
        }

        return {
          ...state,
          exibeSumarioSincronizacao: true,
          resultadoSincronizacao: retorno.liberacao.resultadoSincronizacao,
          idLiberacao: retorno.liberacao !== null && retorno.liberacao !== undefined ? retorno.liberacao.id : state.idLiberacao,
          loadingLiberacaoPessoa: false,
          listaDeLiberacoes: retorno.liberacao !== null && retorno.liberacao !== undefined ? liberacoes : state.listaDeLiberacoes,
          convidadosPessoaSelecionada:  retorno.liberacao !== null && retorno.liberacao !== undefined ? convidados : state.convidadosPessoaSelecionada,
          idPessoaLiberada: retorno.liberacao !== null && retorno.liberacao !== undefined ? retorno.liberacao.idPessoaLiberada : state.idPessoaLiberada,
          idPessoaAutorizante: retorno.liberacao !== null && retorno.liberacao !== undefined ? retorno.liberacao.idPessoaAutorizLiberacao : state.idPessoaAutorizante
        }
      }
      case LIMPA_PESQUISA_LIBERACAO: {
        return {
          ...state,
          textoPesquisaLiberacoes: ''
        }
      }
      case ALTERA_PERIODO_HORARIO_LIBERACAO: {

        var periodo = action.payload;
        var horarioIni = '';
        var horarioFim = '';

        if(periodo === 'Personalizado') {
          horarioIni = '';
          horarioFim = '';
        }

        if(periodo === 'Qualquer') {
          horarioIni = '00:00';
          horarioFim = '23:59';
        }

        if(periodo === 'Manhã') {
          horarioIni = '06:00';
          horarioFim = '11:59';
        }

        if(periodo === 'Tarde') {
          horarioIni = '12:00';
          horarioFim = '17:59';
        }

        if(periodo === 'Noite') {
          horarioIni = '18:00';
          horarioFim = '23:59';
        }

        return {
          ...state,
          horarioInicial: horarioIni,
          horarioFinal: horarioFim,
        }
      }
      case ALTERA_PERIODO_DIA_SEMANA_LIBERACAO: {
        var periodo = action.payload;
        var segunda = false;
        var terca = false;
        var quarta = false;
        var quinta = false;
        var sexta = false;
        var sabado = false;
        var domingo = false;

        if(periodo === 'Personalizado') {
          segunda = false;
          terca = false;
          quarta = false;
          quinta = false;
          sexta = false;
          sabado = false;
          domingo = false;
        }

        if (periodo === 'Qualquer') {
          segunda = true;
          terca = true;
          quarta = true;
          quinta = true;
          sexta = true;
          sabado = true;
          domingo = true;
        }

        if (periodo === 'SegSex') {
          segunda = true;
          terca = true;
          quarta = true;
          quinta = true;
          sexta = true;
          sabado = false;
          domingo = false;
        }

        return {
          ...state,
          periodoEspecificoSegunda: segunda,
          periodoEspecificoTerca: terca,
          periodoEspecificoQuarta: quarta,
          periodoEspecificoQuinta: quinta,
          periodoEspecificoSexta: sexta,
          periodoEspecificoSabado: sabado,
          periodoEspecificoDomingo: domingo,
        }
      }
      case ALTERA_DIA_SEMANA_GERAL: {

        var retorno = action.payload;

        return {
          ...state,
          periodoEspecificoSegunda: retorno !== undefined && retorno.segunda ? false : state.periodoEspecificoSegunda,
          periodoEspecificoTerca: retorno !== undefined && retorno.terca ? false : state.periodoEspecificoTerca,
          periodoEspecificoQuarta: retorno !== undefined && retorno.quarta ? false : state.periodoEspecificoQuarta,
          periodoEspecificoQuinta: retorno !== undefined && retorno.quinta ? false : state.periodoEspecificoQuinta,
          periodoEspecificoSexta: retorno !== undefined && retorno.sexta ? false : state.periodoEspecificoSexta,
          periodoEspecificoSabado: retorno !== undefined && retorno.sabado ? false : state.periodoEspecificoSabado,
          periodoEspecificoDomingo: retorno !== undefined && retorno.domingo ? false : state.periodoEspecificoDomingo,
        }
      }
      case ALTERA_PERIODO_VALIDADE_LIBERACAO: {

        var periodo = action.payload;
        var dataInicio = '';
        var dataFinal = '';

        if(periodo === 'Personalizado') {
          dataInicio = '';
          dataFinal = '';
        }

        if (periodo === 'Hoje') {
          dataInicio = moment().format("DD/MM/YYYY");
          dataFinal = moment().format("DD/MM/YYYY");
        }

        if (periodo === 'Semana') {
          dataInicio = moment().format("DD/MM/YYYY");
          dataFinal = moment(dataInicio, "DD/MM/YYYY").add(7, 'days').format("DD/MM/YYYY");
        }

        if (periodo === 'Mês') {
          dataInicio = moment().format("DD/MM/YYYY");
          dataFinal = moment(dataInicio, "DD/MM/YYYY").add(1, 'months').format("DD/MM/YYYY");
        }

        return {
          ...state,
          periodoLiberacaoInicio: dataInicio,
          periodoLiberacaoFim: dataFinal,   
        }
      }
      case VOLTAR_INICIO_PORTARIA: {
        return {
          ...state,
          visaoPessoas: true,
          visaoLiberacoes: false,
          visaoPesquisaInicial: true,
          textoPessoaPesquisada: ''
        }
      }
      case ADICIONA_OBSERVACAO_PESSOA_PERFIL_SUCESSO: {

        var retorno = action.payload;

        return {
          ...state,
          observacoesPessoaPortaria: retorno.observacao.observacoesPessoaPortaria,
          exibirDrawerObservacoes: false,
          loadingBotaoAdicionarObservacao: false
        }
      }
      case ADICIONA_OBSERVACAO_PESSOA_PERFIL: {
        return {
          ...state,
          loadingBotaoAdicionarObservacao: true
        }
      }
      case ABORTA_OPERACAO: {
        return {
          ...state,
          carregaLiberacao: false
        }
      }
      case BUSCA_PESSOA_CADASTRADA_LIBERACAO: {

        return {
          ...state,
          carregaLiberacao: true
        }
      }
      case BUSCA_PESSOA_CADASTRADA_LIBERACAO_SUCESSO: {

        var retorno = action.payload;

        var usuario = retorno.pessoaExistente;

        if (usuario.listaTelefones !== []) {
          for (var indice in usuario.listaTelefones) {
            var telefone = usuario.listaTelefones[indice];
            usuario.telefones = usuario.telefones + telefoneMask(telefone.numeroTelefone) + ' / ';
          }
        }

        usuario.telefones = usuario.telefones + '@';
        usuario.telefones = usuario.telefones.replace("/ @", "");
        usuario.telefones = usuario.telefones.replace("@", "");
        usuario.telefones = usuario.telefones.replace("null", "");
        usuario.telefones = usuario.telefones === "" ? '(Sem telefones)' : usuario.telefones;

        return {
          ...state,
          cadastroExistenteLiberacao: true,
          carregaLiberacao: false,
          nomePessoaCadastroExistente: retorno.pessoaExistente.nomePessoa,
          cpfPessoaCadastroExistente: retorno.pessoaExistente.cpf,
          telefonePessoaCadastroExistente: usuario.telefones,
          imagemPessoaCadastroExistente: retorno.pessoaExistente.fotoPerfil,
          possuiBiometriaFacialCadastroExistente: retorno.pessoaExistente.possuiBiometriaFacial,
          possuiBiometriaMaoCadastroExistente: retorno.pessoaExistente.possuiBiometriaMao,
          sexoCadastroExistente: retorno.pessoaExistente.sexo,
          dataNascimentoCadastroExistente: retorno.pessoaExistente.dataNascimento,

          idPessoaLiberada: retorno.pessoaExistente.idPessoa,
          autorizante: state.pessoaSelecionada,
          idUsuarioSistemaRegistro: 0,
          descricaoNomeRegistroPrevio: retorno.pessoaExistente.nomePessoa,
          descricaoNumeroDocumentoRegistroPrevio: '',
          descricaoNumeroCPFRegistroPrevio: retorno.pessoaExistente.cpf,
          tipoDocumentoRegistroPrevio: '',
          sexoRegistroPrevio: retorno.pessoaExistente.sexo.toUpperCase().replace("Ã", "A").replace(" ", ""),
          dataNascimentoRegistroPrevio: retorno.pessoaExistente.dataNascimento,
          numeroTelefoneRegistroPrevio: usuario.telefones !== '(Sem telefones)' && usuario.listaTelefones.length > 0 && usuario.listaTelefones[0] !== null && usuario.listaTelefones[0] !== undefined ?  telefoneMask(usuario.listaTelefones[0].numeroTelefone) : '',
          imagemDocumentoLiberacao: '',
          imagemPessoaLiberacao: retorno.pessoaExistente.fotoPerfil,
          imagemCapturadaPessoa: retorno.pessoaExistente.fotoPerfil,
          imagemCapturadaPessoaThumb: retorno.pessoaExistente.imagemThumb
        }
      }
      case FECHAR_PESSOA_EXISTENTE_LIBERACAO: {
        return {
          ...state,
          cadastroExistenteLiberacao: false,
          nomePessoaCadastroExistente: '',
          cpfPessoaCadastroExistente: '',
          telefonePessoaCadastroExistente: '',
          imagemPessoaCadastroExistente: '',
          possuiBiometriaMaoCadastroExistente: false,
          possuiBiometriaFacialCadastroExistente: false,
          sexoCadastroExistente: '',
          dataNascimentoCadastroExistente: '',
          idPessoaLiberada: 0,
          idUsuarioSistemaRegistro: 0,
          descricaoNomeRegistroPrevio: '',
          descricaoNumeroDocumentoRegistroPrevio: '',
          descricaoNumeroCPFRegistroPrevio: '',
          tipoDocumentoRegistroPrevio: '',
          sexoRegistroPrevio: '',
          dataNascimentoRegistroPrevio: '',
          numeroTelefoneRegistroPrevio: '',
          imagemDocumentoLiberacao: '',
          imagemPessoaLiberacao: '',
          imagemCapturadaPessoa: '',
          imagemCapturadaPessoaThumb: ''
        }
      }

      case EXIBE_OCULTA_OBSERVACOES_PERFIL_PESSOA: {
        return {
          ...state,
          exibirDrawerObservacoes: !state.exibirDrawerObservacoes
        }
      }
      case DESATIVA_TIMER: {
        return {
          ...state,
          timerAtivado: false
        }
      }
      case NOVA_LIBERACAO: {
        return {
          ...state,
    
          idLiberacao: 0,
          autorizante: state.pessoaSelecionada,
          descricaoNumeroDocumentoRegistroPrevio: '',
          descricaoNumeroCPFRegistroPrevio: '',
          dataNascimentoRegistroPrevio: '',
          numeroTelefoneRegistroPrevio: '',
          descricaoNomeRegistroPrevio: '',
          descricaoCargo: '',
          descricaoEmpresa: '',
          imagemCapturadaPessoa: '',
          imagemCapturadaPessoaThumb: '',
          ativarCameraFotoPessoa: false,
          imagemCapturadaDocumento: '',
          ativarCameraFotoDocumento: false,
          loadingLiberacaoPessoa: false,  
          cadastroExistenteLiberacao: false,

          // Formulario Liberação
          exibeSumarioSincronizacao: false,
          resultadoSincronizacao: null,
          idLiberacao: 0,
          idPessoaLiberada: 0,
          idPessoaAutorizante: 0,
          idUsuarioSistemaRegistro: 0,
          descricaoNomeRegistroPrevio: '',
          descricaoNumeroDocumentoRegistroPrevio: '',
          tipoDocumentoRegistroPrevio: '',
          sexoRegistroPrevio: 'NAOINFORMADO',
          dataNascimentoRegistroPrevio: '',
          numeroTelefoneRegistroPrevio: '',
          imagemPessoaLiberacao: '',
          imagemDocumentoLiberacao: '',
          idPerfilSistema: 0,
          idAmbienteOrigem: 0,
          idPontoAcessoOrigem: 0,
          idAmbienteDestino: 0,
          descricaoCargo: '',
          descricaoEmpresa: '',
          ehFluxoIlimitado: false,
          permiteAcompanhantes: false,
          observacoes: '',
          periodoLiberacaoInicio: moment().format("DD/MM/YYYY"),
          periodoLiberacaoFim: moment().format("DD/MM/YYYY"),   
          periodoLiberacaoInicioDP: moment(),
          periodoLiberacaoFimDP: moment(),
          horarioInicial: '00:00',
          horarioFinal: '23:59',
          periodoEspecificoSegunda: true,
          periodoEspecificoTerca: true,
          periodoEspecificoQuarta: true,
          periodoEspecificoQuinta: true,
          periodoEspecificoSexta: true,
          periodoEspecificoSabado: true,
          periodoEspecificoDomingo: true,
          statusLiberacao: '',

          cadastroExistenteLiberacao: false,
          nomePessoaCadastroExistente: '',
          cpfPessoaCadastroExistente: '',
          telefonePessoaCadastroExistente: '',
          imagemPessoaCadastroExistente: '',
          possuiBiometriaMaoCadastroExistente: false,
          possuiBiometriaFacialCadastroExistente: false,
          sexoCadastroExistente: '',
          dataNascimentoCadastroExistente: '',
        }
      }
      case EXIBE_MENSAGEM_ERRO_PORTARIA: {

        var retorno = action.payload;

        return {
          ...state,
          loader: false,
          mensagemAlertaPortaria: action.payload,
          exibeMensagemErroPortaria: true,    
        }
      }
      case OCULTA_MENSAGEM_PORTARIA: {
        return {
          ...state,
          loader: false,
          mensagemAlertaPortaria: '',
          exibeMensagemSucessoPortaria: false,
          exibeMensagemErroPortaria: false,    
        }
      }
      case ANALISAR_DETALHES_DC: {

        var retorno = action.payload;

        return {
          ...state,
          exibeAnaliseDuploCheck: true,
          idAtendtoDCAnalise: retorno.idAtendtoDC,
          imagemAnaliseDC: retorno.imagemAnaliseDC
        }
      }
      case SETAR_IMAGEM_LIBERACAO: {
        var retorno = action.payload;

        return {
          ...state,
          imagemCapturadaPessoa: retorno.tipo === 'Pessoa' ?  retorno.imagem : state.imagemCapturadaPessoa,
          imagemCapturadaPessoaThumb: retorno.tipo === 'Pessoa' ?  retorno.imagemThumb : state.imagemCapturadaPessoaThumb,
          ativarCameraFotoPessoa: retorno.tipo === 'Pessoa' ? false : state.ativarCameraFotoPessoa,
          imagemCapturadaDocumento: retorno.tipo === 'Documento' ? retorno.imagem : state.imagemCapturadaDocumento,
          ativarCameraFotoDocumento: retorno.tipo === 'Documento' ? false : state.ativarCameraFotoDocumento
        }
      }
      case CAPTURA_IMAGEM_LIBERACAO: {
        var retorno = action.payload;

        return {
          ...state,
          ativarCameraFotoPessoa: retorno.tipo === 'Pessoa' ? true : state.ativarCameraFotoPessoa,
          ativarCameraFotoDocumento: retorno.tipo === 'Documento' ? true : state.ativarCameraFotoDocumento
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ATUALIZA_MODO_PORTARIA_SUCESSO: {

        var retorno = action.payload;

        return {
          ...state,
          selecionarModoTrabalho: false,
          timerAtivado: retorno.modo === 'Trabalho',
          visaoPesquisaInicial: true,
          idPosicaoPortaria: retorno.idPosicaoPortaria
        }
      }
      case EXECUTA_SIMULACAO: {
        var retorno = action.payload;
        var simulacoesAtual = state.simulacoes;

        simulacoesAtual = simulacoesAtual.filter(x => x.idPonto !== retorno.idPonto);

        const simulacaoAdd = {
          "idPonto": retorno.idPonto,
          "executandoSimulacao": true,
          "exibeResultadoTemporario": false,
          "acessoSimuladoPermitido": false,
        }

        simulacoesAtual = [...simulacoesAtual, simulacaoAdd];

        return {
          ...state,
          simulacoes: simulacoesAtual,
          idPontoSimulado: retorno.idPonto,
          executandoSimulacao: true
        }
      }
      case EXECUTA_SIMULACAO_SUCESSO: {
        var retorno = action.payload;
        var simulacoesAtual = state.simulacoes;
        simulacoesAtual = simulacoesAtual.filter(x => x.idPonto !== retorno.idPonto);

        const simulacaoAdd = {
          "idPonto": retorno.idPonto,
          "executandoSimulacao": false,
          "exibeResultadoTemporario": true,
          "acessoSimuladoPermitido": retorno.resposta,
        }

        simulacoesAtual = [...simulacoesAtual, simulacaoAdd];

        return {
          ...state,
          simulacoes: simulacoesAtual,
          idPontoSimulado: retorno.idPonto,
          executandoSimulacao: false,
          acessoSimuladoPermitido: retorno.resposta,
          exibeResultadoTemporario: true,
        }
      }
      case DECIDIR_ATENDTO_DUPLO_CHECK: {
        var retorno = action.payload;

        return {
          ...state,
          atendimentosFila: state.atendimentosFila.filter(x => x.atendimentoDuploCheck.id !== retorno.idAtendto),
          emDecisaoDuploCheck: true,
          exibeAnaliseDuploCheck: false
        }
      }
      case APLICA_ALTERACOES_GARAGENS: {
        return {
          ...state,
          carregandoGaragens: true,
        }
      }
      case APLICA_ALTERACOES_GARAGENS_SUCESSO: {
        return {
          ...state,
          lotacoesGaragemDesocupar: [],
          carregandoGaragens: false,
          garagensSalvas: true,
        }
      }
      case DESOCUPAR_VAGA_GARAGEM: {
        var retorno = action.payload; 
        var listaLotacaoGar = []  
        var listaLotacaoGarFilt = []  
        
        for(var indice in state.listaLotacaoGaragens) {
          var lotacaoGaragem = state.listaLotacaoGaragens[indice];
          

          if(lotacaoGaragem.idUnidade === retorno.idUnidade) {
            lotacaoGaragem.ocupantes = lotacaoGaragem.ocupantes.filter(x => x.idLotacaoGaragem !== retorno.idLotacaoGaragem);
          }

          listaLotacaoGar = [...listaLotacaoGar, lotacaoGaragem];
        }

        for(var indice in state.listaLotacaoGaragensFiltrada) {
          var lotacaoGaragem = state.listaLotacaoGaragensFiltrada[indice];

          if(lotacaoGaragem.idUnidade === retorno.idUnidade) {
            lotacaoGaragem.ocupantes = lotacaoGaragem.ocupantes.filter(x => x.idLotacaoGaragem !== retorno.idLotacaoGaragem);
          }

          listaLotacaoGarFilt = [...listaLotacaoGarFilt, lotacaoGaragem];
        }

        return {
          ...state,
          listaLotacaoGaragens: listaLotacaoGar,
          listaLotacaoGaragensFiltrada: listaLotacaoGarFilt,
          lotacoesGaragemDesocupar: [...state.lotacoesGaragemDesocupar, retorno.idLotacaoGaragem]
        }

      }    
      case DECIDIR_ATENDTO_DUPLO_CHECK_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          atendimentosFila: state.atendimentosFila.filter(x => x.atendimentoDuploCheck.id !== retorno.idAtendto),
          emDecisaoDuploCheck: false,        
        }
      }      
      case ATUALIZAR_PONTO_REQUISICAO_PORTEIRO: {
        var retorno = action.payload;
        var arrayAtualizado = [];  
  
        if(retorno.valor) {
          const pontoAdd = {
              "idPontoAcesso": retorno.idAtualizacao,
              "IdPosicaoAtendimento": state.idPosicaoPortaria,
          }
    
          arrayAtualizado = state.pontosSelecionadosPortaria;
          arrayAtualizado = [...arrayAtualizado, pontoAdd];
        }
        else {
          arrayAtualizado = state.pontosSelecionadosPortaria.filter(x => x.idPontoAcesso !== retorno.idAtualizacao)
        }
  
        return {
          ...state,
          pontosSelecionadosPortaria: arrayAtualizado,
        }      
      }    
      case BUSCA_SOLICITACAO_SUCESSO: {

        var retorno = action.payload;

        return {
          ...state,
          ////exibeNotificacoes: retorno.buscarSolicitacao
        }
      }

      case BUSCA_FILA_POSICAO_SUCESSO: {
        var retorno = action.payload;
    
        return {
          ...state,
          atendimentosFila: retorno,
          exibeNotificacoes: retorno.length > 0
        }
      }

      case ATIVA_DESATIVA_BUSCA_NOTIFICACOES: {
        return {
          ...state,
          timerAtivado: !state.timerAtivado
        }
      }
      case SELECIONA_PESSOA_PORTARIA: {

        var retorno = action.payload;

        return {
          ...state,
          pessoaSelecionada: state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === retorno.idPessoa)[0],
          carregandoPaginaPerfil: true
        }
      }
      case SELECIONA_PESSOA_PORTARIA_SUCESSO: {

        var retorno = action.payload;

        return {
          ...state,
          complementosPessoaSelecionada: retorno.listas.complementosPessoaPortaria,
          vinculosPessoaSelecionada: retorno.listas.vinculosPessoaPortaria,
          convidadosPessoaSelecionada: retorno.listas.convidadosPessoaPortaria,
          pontosAcessoPessoaVisivelPorteiro: retorno.listas.visibilidadesPontosConcessaoAcessoPortaria,
          registrosAcessosHistoricos: retorno.listas.registrosAcessosHistoricos,
          observacoesPessoaPortaria: retorno.listas.observacoesPessoaPortaria,
          ambientesDoAutorizante: retorno.listas.ambientesPessoaAutorizante,
          blocoDescritivoPerfilPessoaSelecionada: retorno.listas.blocoDescritivoPerfil,
          possuiBiometriaMao: retorno.listas.possuiBiometriaMao,
          possuiBiometriaSimples: retorno.listas.possuiBiometriaSimples,
          possuiBiometriaFacial: retorno.listas.possuiBiometriaFacial,
          carregandoPaginaPerfil: false,
          fotoPerfilPessoaSelecionada: retorno.listas.fotoPerfil,
          loadingLiberacaoTerminal: false,
          idPontoLoadingLiberacaoTerminal: 0
        }
      }
      case SELECIONA_LIBERACAO_PORTARIA: {
        var retorno = action.payload;
        var liberacao = state.listaDeLiberacoes.filter(x => x.idLiberacao === retorno.idLiberacao)[0];
        var ambientesPortaria = state.listaDeAmbientesPortaria;
        var pontosAcessoOrigem = [];

        function PreencheListaPontosAcessoOrigemLiberacao(ambientesPortaria) {

          for (var indice in ambientesPortaria) {
            var ambientePortaria = ambientesPortaria[indice];

            if(ambientePortaria.id === liberacao.idAmbienteOrigem) {

              for(var indice in ambientePortaria.pontosAcesso) {
                var pontoAcesso = ambientePortaria.pontosAcesso[indice];
                pontosAcessoOrigem.push({id: pontoAcesso.id, title: pontoAcesso.descricaoPontoAcesso})
              }
              
            }
            else {
              if(ambientePortaria.subAmbientes !== null && ambientePortaria.subAmbientes.length > 0) {
                PreencheListaPontosAcessoOrigemLiberacao(ambientePortaria.subAmbientes);
              }
            }
          }

        }

        PreencheListaPontosAcessoOrigemLiberacao(ambientesPortaria);

        return {
          ...state,
          exibeSumarioSincronizacao: false,
          resultadoSincronizacao: false,
          pessoaSelecionada: state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === liberacao.idPessoaAutorizante)[0],
          listaDePontosDeAcessoOrigem: pontosAcessoOrigem,
          idLiberacao: liberacao.idLiberacao,
          idPessoaLiberada: liberacao.idPessoa,
          autorizante: state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === liberacao.idPessoaAutorizante)[0],
          descricaoNomeRegistroPrevio: liberacao.nomePessoaLiberada,
          descricaoNumeroDocumentoRegistroPrevio: liberacao.numeroDocumento,
          descricaoNumeroCPFRegistroPrevio: liberacao.numeroCPF !== undefined && liberacao.numeroCPF !== '' ? cpfMask(liberacao.numeroCPF.replace(/[^\d]+/g, '')) : state.descricaoNumeroCPFRegistroPrevio,
          idPerfilSistema: liberacao.idPerfilSistema,
          idAmbienteOrigem: liberacao.idAmbienteOrigem,
          idPontoAcessoOrigem: liberacao.idPontoAcessoOrigem,
          idAmbienteDestino: liberacao.idAmbienteDestino,
          tipoDocumentoRegistroPrevio: liberacao.tipoDocumento,
          imagemPessoa: liberacao.imagemPessoa,
          imagemCapturadaPessoa: liberacao.imagemPessoa,
          imagemCapturadaDocumento: liberacao.imagemDocumento,
          idPessoa: liberacao.idPessoa,
          idPessoaAutorizante: liberacao.idPessoaAutorizante,
          descricaoCargo: liberacao.descricaoCargo,
          descricaoEmpresa: liberacao.descricaoEmpresa,
          ehFluxoIlimitado: liberacao.ehFluxoIlimitado,
          permiteAcompanhantes: liberacao.permiteAcompanhantes,
          observacoes: liberacao.observacao,
          periodoEspecificoSegunda: liberacao.somenteSegunda,
          periodoEspecificoTerca: liberacao.somenteTerca,
          periodoEspecificoQuarta: liberacao.somenteQuarta,
          periodoEspecificoQuinta: liberacao.somenteQuinta,
          periodoEspecificoSexta: liberacao.somenteSexta,
          periodoEspecificoSabado: liberacao.somenteSabado,
          periodoEspecificoDomingo: liberacao.somenteDomingo,
          periodoLiberacaoInicio: dateMask(liberacao.periodoLiberacaoInicio),
          periodoLiberacaoFim: dateMask(liberacao.periodoLiberacaoFim),
          dataNascimentoRegistroPrevio: liberacao.dataNascimentoRegistroPrevio,
          sexoRegistroPrevio: liberacao.sexoRegistroPrevio,
          numeroTelefoneRegistroPrevio: liberacao.numeroTelefoneRegistroPrevio,
          statusLiberacao: liberacao.statusLiberacao,
          horarioInicial: liberacao.horarioInicial,
          horarioFinal: liberacao.horarioFinal,
          cadastroExistenteLiberacao: false,
        }
      }
      case INICIA_PORTARIA: {

        var retorno = action.payload;

        return {
          ...state,
          carregandoInformacoes: true,
        }
      }
      case SETAR_ID_INTERVAL: {
        return {
          ...state,
          idIntervalGerado: action.payload
        }
      }
      case INICIA_PORTARIA_LIBERACAO: {

        return {
          ...state,
          carregandoInformacoes: true,
        }
      }
      case INICIA_PORTARIA_LIBERACAO_SUCESSO: {
        var retorno = action.payload;
  
        var listagemAmbPortaria = [];
        var listagemUsuarios = [];
  
        if(retorno.listaAmbientesPortaria !== null && retorno.listaAmbientesPortaria.length > 0){
  
          for (var indice in retorno.listaAmbientesPortaria) {
  
            var ambientePortaria = retorno.listaAmbientesPortaria[indice];
            var nomeAmbientePortaria = ambientePortaria.localControlado.descricaoLocalControlado + ' / ' + ambientePortaria.descricaoAmbiente;
  
            listagemAmbPortaria.push({id: ambientePortaria.id, title: nomeAmbientePortaria })
          }
  
        }

        var listagemAmbCliente = [];
  
        if(retorno.listaAmbientesCliente !== null && retorno.listaAmbientesCliente.length > 0){
  
          for (var indice in retorno.listaAmbientesCliente) {
  
            var ambienteCliente = retorno.listaAmbientesCliente[indice];
            var nomeAmbienteCliente = ambienteCliente.localControlado.descricaoLocalControlado + ' / ' + ambienteCliente.descricaoAmbiente;
  
            listagemAmbCliente.push({id: ambienteCliente.id, title: nomeAmbienteCliente })
          }
  
        }

        if(retorno.usuariosPlataforma !== null && retorno.usuariosPlataforma.length > 0){
  
          for (var indice in retorno.usuarios) {
  
            var usuario = retorno.usuarios[indice];
            usuario.numeroDocumento = usuario.tipoPessoa === 'F' ? usuario.numeroCPF !== '' ? cpfMask(usuario.numeroDocumento) : usuario.numeroDocumento : usuario.numeroDocumento;
            usuario.telefones = '';
            usuario.unidades = '';

            if (usuario.listaTelefones !== []) {
              for (var indice in usuario.listaTelefones) {
                var telefone = usuario.listaTelefones[indice];
                usuario.telefones = usuario.telefones + telefoneMask(telefone.numeroTelefone) + ' / ';
              }
            }

            usuario.telefones = usuario.telefones + '@';
            usuario.telefones = usuario.telefones.replace("/ @", "");
            usuario.telefones = usuario.telefones.replace("@", "");
            usuario.telefones = usuario.telefones === "" ? '(Sem telefones)' : usuario.telefones;

            if (usuario.listaUnidades !== []) {
              for (var indice in usuario.listaUnidades) {
                var unidade = usuario.listaUnidades[indice];
                usuario.unidades = usuario.unidades + unidade.nomeUnidade + ' / ';
              }
            }

            usuario.unidades = usuario.unidades + '@';
            usuario.unidades = usuario.unidades.replace("/ @", "");
            usuario.unidades = usuario.unidades.replace("@", "");
            usuario.unidades = usuario.unidades === "" ? '(Sem complementos)' : usuario.unidades;

            listagemUsuarios.push(usuario)
          }
  
        }
  
        return {
          ...state,
          carregandoInformacoes: false,
          listaDeUsuariosPlataforma: retorno.usuarios,
          listaDeLiberacoes: retorno.liberacoes,
        }
      }
      case OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA_SUCESSO: {
        const retorno = action.payload;

        for (var indice in retorno.imagensThumbs) {
          var pessoaComImagem = retorno.imagensThumbs[indice];
          var pessoaCarregada = state.listaDeUsuariosPlataforma.filter(x => x.idPessoa === pessoaComImagem.idPessoa)[0];

          if(pessoaCarregada !== null && pessoaCarregada !== undefined){
            pessoaCarregada.imagem = pessoaComImagem.imgThumbnail;
          }
        }

        return {
          ...state,
          /////listGrid: ListaComImagensThumb,
          /////listGridComFiltro: state.listGrid
        }
      }
      case INICIA_PORTARIA_SUCESSO: {
        var retorno = action.payload;
  
        var ambientesDoCliente = retorno.listaAmbientesCliente;
        var ambientesDePortaria = ambientesDoCliente !== null && ambientesDoCliente.length > 0 ? ambientesDoCliente.filter(x => x.ehAmbientePortaria) : [];
        var listagemAmbPortaria = [];
        var listagemUsuarios = [];
  
        if(ambientesDePortaria !== null && ambientesDePortaria.length > 0){
  
          for (var indice in ambientesDePortaria) {
  
            var ambientePortaria = ambientesDePortaria[indice];
            var nomeAmbientePortaria = ambientePortaria.localControlado.descricaoLocalControlado + ' / ' + ambientePortaria.descricaoAmbiente;
  
            listagemAmbPortaria.push({id: ambientePortaria.id, title: nomeAmbientePortaria })
          }
  
        }

        var listagemAmbCliente = [];
  
        if(retorno.listaAmbientesCliente !== null && retorno.listaAmbientesCliente.length > 0){
  
          for (var indice in retorno.listaAmbientesCliente) {
  
            var ambienteCliente = retorno.listaAmbientesCliente[indice];
            var nomeAmbienteCliente = ambienteCliente.localControlado.descricaoLocalControlado + ' / ' + ambienteCliente.descricaoAmbiente;
  
            listagemAmbCliente.push({id: ambienteCliente.id, title: nomeAmbienteCliente })
          }
  
        }

        if(retorno.usuariosPlataforma !== null && retorno.usuariosPlataforma.length > 0){
  
          for (var indice in retorno.usuariosPlataforma) {
  
            var usuario = retorno.usuariosPlataforma[indice];
            console.log('idPessoa: '+ usuario.idPessoa + ' - Numero Documento: ' + usuario.numeroDocumento)
            usuario.numeroDocumento = AjustaCPF(usuario);
            usuario.telefones = '';
            usuario.unidades = '';

            if (usuario.listaTelefones !== []) {
              for (var indice in usuario.listaTelefones) {
                var telefone = usuario.listaTelefones[indice];
                usuario.telefones = usuario.telefones + telefoneMask(telefone.numeroTelefone) + ' / ';
              }
            }

            usuario.telefones = usuario.telefones + '@';
            usuario.telefones = usuario.telefones.replace("/ @", "");
            usuario.telefones = usuario.telefones.replace("@", "");
            usuario.telefones = usuario.telefones === "" ? '(Sem telefones)' : usuario.telefones;

            if (usuario.listaUnidades !== []) {
              for (var indice in usuario.listaUnidades) {
                var unidade = usuario.listaUnidades[indice];
                usuario.unidades = usuario.unidades + unidade.nomeUnidade + ' / ';
              }
            }

            usuario.unidades = usuario.unidades + '@';
            usuario.unidades = usuario.unidades.replace("/ @", "");
            usuario.unidades = usuario.unidades.replace("@", "");
            usuario.unidades = usuario.unidades === "" ? '(Sem complementos)' : usuario.unidades;

            listagemUsuarios.push(usuario)
          }
  
        }
 
        function AjustaCPF(pessoa) {

          if(pessoa.tipoPessoa === 'F' && pessoa.numeroDocumento !== null){
            if(pessoa.numeroDocumento !== undefined && pessoa.numeroDocumento !== ''){
              return cpfMask(usuario.numeroDocumento);
            }
            else if(pessoa.numeroDocumento === null) {
              return '';
            }
            else {
              return pessoa.numeroDocumento
            }
          }
          else {
            return pessoa.numeroDocumento
          }
        }

        return {
          ...state,
          carregandoInformacoes: false,
          listaDeAmbientesPortariaCombo: listagemAmbPortaria,
          listaDeAmbientesClienteCombo: listagemAmbCliente,
          listaDeAmbientesPortaria: ambientesDePortaria,
          listaDeAmbientesCliente: retorno.listaAmbientesCliente,
          listaDeUsuariosPlataforma: listagemUsuarios,
          listaDeLiberacoes: retorno.liberacoes,
          listaDePerfisTemporarios: preparaListaParaCombo(retorno.perfisTemporarios, "id", "descricaoPerfil"),
          listaDePerfisTemporariosCompleto: retorno.perfisTemporarios,
          listaPanoramaAmbientesPerfilTemporario: retorno.ambientesPerfilTemporario,
          parametroOcultaTelefone: retorno.dadosCliente.ocultaTelefone,
          parametroOcultaDocumento: retorno.dadosCliente.ocultaDocumento,
          parametroTipoControleVisibilidade: retorno.dadosCliente.tipoControleVisibilidade
        }
      }
      case CARREGA_PESSOAS_PARA_TESTES: {
        return {
          ...state,
          carregandoInformacoes: true,
        }
      }
      case CARREGA_PESSOAS_PARA_TESTES_SUCESSO: {

        var retorno = action.payload;

        return {
          ...state,
          carregandoInformacoes: false,
          listaDeAmbientesPortaria: retorno.ambientesPortaria,
          listaDeUsuariosPlataformaTeste: retorno.usuariosPlataforma,
          listaDeUsuariosPlataformaFiltroteste: retorno.usuariosPlataforma,
        }
      }  
      case ALTERACAO_CAMPO_PORTARIA: {

        var retorno = action.payload;
        var valor = action.payload.target.value;
        var apenasNumeros = /^\d+$/.test(valor.replace(".", "").replace("-", ""));
        var listaFiltrada = !apenasNumeros ? action.payload.target.value.length >= 3 ? state.listaDeUsuariosPlataforma.filter(x => x.nomeRazao !== null && x.nomeRazao.toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "").includes(valor.normalize("NFD").toLowerCase().replace(/[^a-zA-Zs]/g, ""))) : [] : action.payload.target.value.length >= 3 ? state.listaDeUsuariosPlataforma.filter(x => x.numeroDocumento !== null && x.numeroDocumento !== undefined && x.numeroDocumento.replace(".", "").replace("-", "").includes(valor.replace(".", "").replace("-", ""))) : [];

        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
          visaoPesquisaInicial: action.payload.target.value.length < 3,
          listaDeUsuariosPlataformaFiltrada: listaFiltrada
        }
      }
      case ALTERACAO_CAMPO_PESQUISA_PESSOA_TESTE: {

        var retorno = action.payload;
        var listaFiltrada = action.payload.target.value.length >= 3 ? state.listaDeUsuariosPlataformaTeste.filter(x => x.nomeRazao.toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "").includes(action.payload.target.value.normalize("NFD").toLowerCase().replace(/[^a-zA-Zs]/g, ""))) : state.listaDeUsuariosPlataformaTeste;
        
        return {
          ...state,
          listaDeUsuariosPlataformaFiltroteste: listaFiltrada,
          textoFiltroPessoasTeste: action.payload.target.value
        }
      }
      case ALTERACAO_CAMPO_PESQUISA_UNIDADE_GARAGEM: {

        var retorno = action.payload;
        var listaFiltrada = action.payload.target.value.length > 0 ? state.listaLotacaoGaragens.filter(x => x.descricaoUnidade.toLowerCase().includes(action.payload.target.value)) : state.listaLotacaoGaragens;
        
        return {
          ...state,
          garagensSalvas: false,
          listaLotacaoGaragensFiltrada: listaFiltrada,
          textoFiltroGaragemUnidade: action.payload.target.value
        }
      }
      case ALTERACAO_CAMPO: {

        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_COMBO: {

        var campo = action.payload.nomeCombo[0];
        var valor = action.payload.valor;
        var possuiOrigemPadrao = false;
        var perfilSituacaoAmbiente = {};

        if(campo === 'idPerfilSistema') {

          perfilSituacaoAmbiente = state.listaPanoramaAmbientesPerfilTemporario.filter(x => x.idPerfil === valor)[0];

          if (perfilSituacaoAmbiente.situacao === 'Padrão') {
            possuiOrigemPadrao = true;
          }

          campo = 'idAmbienteOrigem';
          valor = perfilSituacaoAmbiente.idAmbiente;

        }

        if(campo === 'idAmbienteOrigem') {
          var pontosAcessoOrigem = [];
          var ambientesDestino = [];
          var ambientesPortaria = state.listaDeAmbientesPortaria;

          function PreencheListaPontosAcessoOrigem(ambientesPortaria) {

            for (var indice in ambientesPortaria) {
              var ambientePortaria = ambientesPortaria[indice];
  
              if(ambientePortaria.id === valor) {

                for(var indice in ambientePortaria.pontosAcesso) {
                  var pontoAcesso = ambientePortaria.pontosAcesso[indice];
                  pontosAcessoOrigem.push({id: pontoAcesso.id, title: pontoAcesso.descricaoPontoAcesso})
                }
                
              }
              else {
                if(ambientePortaria.subAmbientes !== null && ambientePortaria.subAmbientes.length > 0) {

                  for (var indice in ambientePortaria.subAmbientes) {
                    var ambienteCliente = ambientePortaria.subAmbientes[indice];
                    var nomeAmbienteCliente = ambienteCliente.localControlado.descricaoLocalControlado + ' / ' + ambienteCliente.descricaoAmbiente;
                    ambientesDestino.push({id: ambienteCliente.id, title: nomeAmbienteCliente });
                  }

                  PreencheListaPontosAcessoOrigem(ambientePortaria.subAmbientes);
                }
                else {
                  ambientesDestino = [];
                }
              }
            }

          }

          PreencheListaPontosAcessoOrigem(ambientesPortaria);

        }

        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
          listaDePontosDeAcessoOrigem: campo === 'idAmbienteOrigem' ? pontosAcessoOrigem : state.listaDePontosDeAcessoOrigem,
          listaAmbientesDestino: campo === 'idAmbienteOrigem' ? ambientesDestino : state.listaAmbientesDestino,
          idAmbienteOrigem: campo === 'idPerfilSistema' && possuiOrigemPadrao ? perfilSituacaoAmbiente.idAmbiente : campo === 'idAmbienteOrigem' ? valor : state.idAmbienteOrigem
        }
      }
      case ALTERACAO_DATEPICKER: {

        var campo = action.payload.name;
        var dataInicial = "";
        var dataFinal = "";

        if (campo === "periodoLiberacaoInicioDP" && action.payload.date !== null)
        {
          dataInicial = action.payload.date.format("DD/MM/YYYY");
        }

        if (campo === "periodoLiberacaoFimDP" && action.payload.date !== null)
        {
          dataFinal = action.payload.date.format("DD/MM/YYYY");
        }

        return {
          ...state,
          periodoLiberacaoInicio: campo === "periodoLiberacaoInicioDP" ? dataInicial : state.periodoLiberacaoInicio,
          periodoLiberacaoFim: campo === "periodoLiberacaoFimDP" ? dataFinal : state.periodoLiberacaoFim,
          [action.payload.name]: action.payload.date,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }
      }
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }
      }
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),
  
        }
      }          
      default:
        return state;
    }
  }
  