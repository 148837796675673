import {
  INICIA_LOCALIDADE,
  SALVA_LOCALIDADE,
  REMOVE_LOCALIDADE,
  LISTA_LOCALIDADE,
  NOVO_LOCALIDADE,
  BUSCA_LOCALIDADE_EDICAO,
  INICIO_LOCALIDADE_SUCESSO,
  SALVA_LOCALIDADE_SUCESSO,
  REMOVE_LOCALIDADE_SUCESSO,
  BUSCA_LOCALIDADE_EDICAO_SUCESSO,
  LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE,
  LISTA_LOCAIS_SUCESSO_LOCALIDADE,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_LOCALIDADE_SUCESSO,
  LIMPAR_LOCALIDADE,
  ADICIONA_DEPARTAMENTO_LISTA_VINCULADA,
  REMOVE_DEPARTAMENTO_LISTA_VINCULADA,
  ADICIONA_FUNCAO_LISTA_VINCULADA,
  REMOVE_FUNCAO_LISTA_VINCULADA,
  ADICIONA_PONTO_ACESSO_LISTA_VINCULADA_PERIODO,
  REMOVE_PONTO_ACESSO_LISTA_VINCULADA_PERIODO,
  LISTA_PONTO_ACESSO_SUCESSO_LOCALIDADE,
  LISTA_PONTO_ACESSO_POR_LOCAL,
  ADICIONA_GRUPO_LISTA_VINCULADA,
  REMOVE_GRUPO_LISTA_VINCULADA, 
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaLocalidade = () => {
  return {
    type: INICIA_LOCALIDADE
  };
};

export const salvaLocalidade = (localidade) => {
  return {
    type: SALVA_LOCALIDADE,
    payload: localidade
  };
};

export const removeLocalidade = (id) => {
  return {
    type: REMOVE_LOCALIDADE,
    payload: id
  };
};

export const listaLocalidade = (cliente, local, localidade) => {
  return {
    type: LISTA_LOCALIDADE,
    payload: {cliente, local, localidade}
  };
};

/*#############################################################################################*/
/* ------------------------ Actions Complementares da Funcionalidade --------------------------*/

export const listaLocaisControladosPorCliente = (name, object, value) => {
  var idCliente = value !== null ? value.id : 0;

  return {
    type: LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE,
    payload: { idCliente }
  }
}

export const adicionaDepartamentoListaVinculada = (descricaoDepartamento, ativo) => {
  if(descricaoDepartamento == ""){
    descricaoDepartamento = "SEM DESCRIÇÃO";
  }

  return {
    type: ADICIONA_DEPARTAMENTO_LISTA_VINCULADA,
    payload: { descricaoDepartamento, ativo  }
    };
};

export const removeDepartamentoListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_DEPARTAMENTO_LISTA_VINCULADA,
    payload: { idTemporario }
  };
};

export const adicionaPontoAcessoListaVinculada = (idPonto) => {

  return {
    type: ADICIONA_PONTO_ACESSO_LISTA_VINCULADA_PERIODO,
    payload: { idPonto }
    };
};

export const removePontoAcessoListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_PONTO_ACESSO_LISTA_VINCULADA_PERIODO,
    payload: { idTemporario }
  };
}; 

export const adicionaFuncaoListaVinculada = (descricao, ativo) => {
  if(descricao == ""){
    descricao = "SEM NOME";
  }

  return {
    type: ADICIONA_FUNCAO_LISTA_VINCULADA,
    payload: { descricao, ativo  }
    };
};

export const removeFuncaoListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_FUNCAO_LISTA_VINCULADA,
    payload: { idTemporario }
  };
};

export const adicionaGrupoListaVinculada = (descricao, ativo) => {
  if(descricao == ""){
    descricao = "SEM NOME";
  }

  return {
    type: ADICIONA_GRUPO_LISTA_VINCULADA,
    payload: { descricao, ativo }
    };
};

export const removeGrupoListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_GRUPO_LISTA_VINCULADA,
    payload: { idTemporario }
  };
}; 
/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoLocalidade = () => {
  return {
    type: NOVO_LOCALIDADE
  };
};

export const buscaLocalidadeEdicao = (id) => {
  return {
    type: BUSCA_LOCALIDADE_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioLocalidadeSucesso = (localidades, clientes, locaisControlados) => {
  return {
    type: INICIO_LOCALIDADE_SUCESSO,
    payload: { localidades, clientes, locaisControlados }
  }
};

export const salvaLocalidadeSucesso = (mensagem) => {
  return {
    type: SALVA_LOCALIDADE_SUCESSO,
    payload: mensagem
  }
};

export const removeLocalidadeSucesso = (mensagem) => {
  return {
    type: REMOVE_LOCALIDADE_SUCESSO,
    payload: mensagem
  }
};

export const buscaLocalidadeEdicaoSucesso = (localidade, locais, pontos) => {
  return {
    type: BUSCA_LOCALIDADE_EDICAO_SUCESSO,
    payload: { localidade, locais, pontos }
  };
};

export const listaLocaisControladosSucesso = (locais) => {
  return {
    type: LISTA_LOCAIS_SUCESSO_LOCALIDADE,
    payload: { locais }
  };
};


export const listaLocalidadeSucesso = (localidades) => {
  return {
    type: LISTA_LOCALIDADE_SUCESSO,
    payload: { localidades }
  };
};

export const limparFiltroLocalidade = () => {
  return {
    type: LIMPAR_LOCALIDADE,
  };
};

export const listaPontoAcessoPorLocal = (name, object, value) => {
  var idLocal = value !== null ? value.id : 0;

  return {
    type: LISTA_PONTO_ACESSO_POR_LOCAL,
    payload: { idLocal }
  }
}

export const listaPontoAcessoSucesso = (pontos) => {
  return {
    type: LISTA_PONTO_ACESSO_SUCESSO_LOCALIDADE,
    payload: { pontos }
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};