import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_ACESSO_BLOQUEIO, INICIA_ACESSO_BLOQUEIO, BUSCA_ACESSO_BLOQUEIO_EDICAO, REMOVE_ACESSO_BLOQUEIO,
         LISTA_CONFIGURACAO_PONTO_BLOQUEIO, LISTA_LOCAIS_POR_CLIENTE_ACESSO_BLOQUEIO, CONSULTA_CPF, LISTA_ACESSO_BLOQUEIO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaAcessoBloqueioSucesso,
         removeAcessoBloqueioSucesso, 
         inicioAcessoBloqueioSucesso, 
         iniciaAcessoBloqueio,
         listaLocaisSucesso,
         consultaCpfSucesso,
         buscaAcessoBloqueioEdicaoSucesso,
         listaConfiguracaoPontoPessoaSucesso,
         listaAcessoBloqueioSucesso } from "actions/AcessoBloqueio";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getAcessosBloqueio = async () =>
    await requisicaoApi.get('/acessosBloqueio')
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);       

  const getAcessoBloqueioEdicao = async (id) =>
    await requisicaoApi.get('/acessosBloqueio/' + id)
    .then(resposta => resposta)
    .catch(error => error);

  const getListaPerfisPorCliente = async (cliente) => 
    await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);   
    
  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);    
    
  const getlistaConfiguracaoPontoPessoa = async (idLocal, idPessoa) => 
    await requisicaoApi.get('/acessosBloqueio/vinculoPontoAcesso/' + idLocal + '/' + idPessoa)
    .then(resposta => resposta)
    .catch(error => error);
    
  const getCpf = async (cpf) =>
    await requisicaoApi.get('/util/pessoaFisica/' + cpf)
    .then(resposta => resposta)
    .catch(error => error);    

    const getListaAcessoBloqueio = async (usuarioFiltro, ambienteFiltro, pontoFiltro) => 
    await requisicaoApi.get('/acessosBloqueio?Usuario=' +  usuarioFiltro + '&Ambiente=' + ambienteFiltro + '&PontoAcesso=' + pontoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaAcessoBloqueioRequest = async (acessoBloqueio, listaPontosAssociados) => await requisicaoApi.post('/acessosBloqueio', 
  {
    "id": acessoBloqueio.id,
    "idCliente": acessoBloqueio.idCliente,
    "idLocalControlado": acessoBloqueio.idLocalControlado,
    "numeroCpf": acessoBloqueio.numeroCpf,
    "nomePessoa": acessoBloqueio.nomePessoa,
    "idPessoa": acessoBloqueio.idPessoa,
    "idLocalControlado": acessoBloqueio.idLocalControlado,
    "pontosAssociados": listaPontosAssociados
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaAcessoBloqueioRequest = async (acessoBloqueio, listaPontosAssociados) => await requisicaoApi.put('/acessosBloqueio', 
  {
    "id": acessoBloqueio.id,
    "idCliente": acessoBloqueio.idCliente,
    "idLocalControlado": acessoBloqueio.idLocalControlado,
    "numeroCpf": acessoBloqueio.numeroCpf,
    "nomePessoa": acessoBloqueio.nomePessoa,
    "idPessoa": acessoBloqueio.idPessoa,
    "idLocalControlado": acessoBloqueio.idLocalControlado,
    "pontosAssociados": listaPontosAssociados
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteAcessoBloqueioRequest = async (id) =>
  await requisicaoApi.delete('/acessosBloqueio?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

function* fnIniciaAcessoBloqueio() {
  try {
    const listagemGrid = yield call(getAcessosBloqueio);

    if(listagemGrid.data)
    {
      const clientes = yield call(getClientes);

      if(clientes.data) {
        const clienteFixo = localStorage.getItem('idClienteFixado');

        if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
        {
          const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
          yield put(inicioAcessoBloqueioSucesso(listagemGrid.data, clientes.data, locais.data));
        }
        else
        {
          yield put(inicioAcessoBloqueioSucesso(listagemGrid.data, clientes.data, null));
        }
      }
    }
    else {
      if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
      {
        yield put(userSignOut());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao listar acessosBloqueio'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaLocaisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaLocaisControladosPorCliente, cliente);

    if(retorno.data)
    {
      yield put(listaLocaisSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
}

function* fnlistaConfiguracaoPontoPessoa({ payload }) {
  try {
    const idLocal = payload.idLocal;
    const idPessoa = payload.idPessoa
    const retorno = yield call(getlistaConfiguracaoPontoPessoa, idLocal, 1);

    if(retorno.data)
    {
      yield put(listaConfiguracaoPontoPessoaSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso do local: ' + idLocal));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

function* fnConsultaCpf({ payload }) {
  try {
    const cpf = payload;
    const retorno = yield call(getCpf, cpf);

    if(retorno.data)
    {
      yield put(consultaCpfSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}  

  function* fnBuscaAcessoBloqueioEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getAcessoBloqueioEdicao, id);
      const locais = yield call(getListaLocaisControladosPorCliente, retorno.data.idCliente);
  
      if(retorno.data)
      {
        yield put(buscaAcessoBloqueioEdicaoSucesso(retorno.data, locais.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do acessoBloqueio: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaAcessoBloqueio({ payload }) {
  try {

    const acessoBloqueio = payload.acessoBloqueio;
    const listaPontosAssociados = payload.listaPontosAssociados;
    var resultado = "";

      if(acessoBloqueio.id === 0) {
        const retorno = yield call(adicionaAcessoBloqueioRequest, acessoBloqueio, listaPontosAssociados);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaAcessoBloqueioRequest, acessoBloqueio, listaPontosAssociados);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaAcessoBloqueio());
        yield put(salvaAcessoBloqueioSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveAcessoBloqueio({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteAcessoBloqueioRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaAcessoBloqueio());
        yield put(removeAcessoBloqueioSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaAcessoBloqueio({ payload }) {
  try {
    const usuarioFiltro = payload.usuario;
    const ambienteFiltro = payload.ambiente;
    const pontoFiltro = payload.ponto;
    const retorno = yield call(getListaAcessoBloqueio, usuarioFiltro, ambienteFiltro, pontoFiltro);

    if(retorno.data)
    {
      yield put(listaAcessoBloqueioSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de acesso pessoa'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesAcessoBloqueio() {
  yield takeEvery(INICIA_ACESSO_BLOQUEIO, fnIniciaAcessoBloqueio);
}

export function* consultarCpf() {
  yield takeEvery(CONSULTA_CPF, fnConsultaCpf);
}

export function* salvaAcessoBloqueio() {
  yield takeEvery(SALVA_ACESSO_BLOQUEIO, fnSalvaAcessoBloqueio);
}

export function* buscaAcessoBloqueioEdicao() {
  yield takeEvery(BUSCA_ACESSO_BLOQUEIO_EDICAO, fnBuscaAcessoBloqueioEdicao);
}

export function* removeAcessoBloqueio() {
  yield takeEvery(REMOVE_ACESSO_BLOQUEIO, fnRemoveAcessoBloqueio);
}

export function* listaLocaisPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_ACESSO_BLOQUEIO, fnListaLocaisPorCliente)
}

export function* listaConfiguracaoPontoPessoa() {
  yield takeEvery(LISTA_CONFIGURACAO_PONTO_BLOQUEIO, fnlistaConfiguracaoPontoPessoa)
}

export function* listaAcessoBloqueio() {
  yield takeEvery(LISTA_ACESSO_BLOQUEIO, fnListaAcessoBloqueio)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesAcessoBloqueio),
             fork(consultarCpf), 
             fork(salvaAcessoBloqueio), 
             fork(buscaAcessoBloqueioEdicao),
             fork(removeAcessoBloqueio),
             fork(listaLocaisPorCliente),
             fork(listaConfiguracaoPontoPessoa),
             fork(listaAcessoBloqueio)
            ]);
}