import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import modeloSagas from './Modelo';
import equipamentoSagas from './Equipamento';
import clienteSagas from './Cliente';
import localControladoSagas from './LocalControlado';
import pontoAcessoSagas from './PontoAcesso';
import perfilSagas from './Perfil';
import perfilPortalSagas from './PerfilPortal';
import usuarioPlataformaSagas from './UsuarioPlataforma';
import usuarioPortalSagas from './UsuarioPortal';
import acessoPessoaSagas from './AcessoPessoa';
import acessoPerfilSagas from './AcessoPerfil';
import acessoGeneroSagas from './AcessoGenero';
import acessoIdadeSagas from './AcessoIdade';
import acessoPeriodoSagas from './AcessoPeriodo';
import acessoFullSagas from './AcessoFull';
import acessoBloqueioSagas from './AcessoBloqueio';
import localidadeSagas from './Localidade';
import vinculoPessoaSagas from './VinculoPessoa';
import inicioSagas from './Inicio';
import unidadeSagas from './Unidade';
import categoriaSagas from './Categoria';
import titularidadeSagas from './Titularidade';
import ambienteSagas from './Ambiente';
import portarioInicioSagas from './PortariaInicio';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    inicioSagas(),
    modeloSagas(),
    equipamentoSagas(),
    clienteSagas(),
    localControladoSagas(),
    pontoAcessoSagas(),
    perfilSagas(),
    perfilPortalSagas(),
    usuarioPlataformaSagas(),
    usuarioPortalSagas(),
    acessoPessoaSagas(),
    acessoPerfilSagas(),
    acessoGeneroSagas(),
    acessoIdadeSagas(),
    acessoPeriodoSagas(),
    acessoFullSagas(),
    acessoBloqueioSagas(),
    localidadeSagas(),
    vinculoPessoaSagas(),
    unidadeSagas(),
    categoriaSagas(),
    titularidadeSagas(),
    ambienteSagas(),
    portarioInicioSagas()
  ]);
}
