import {
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_DATEPICKER,
    ALTERACAO_CHECK,
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE_2,
    ALTERACAO_CAMPO_MASCARA_IP,
    CANCELA_EDICAO,
    LIMPA_VALIDACOES,
    APLICA_FILTRO,
    APLICA_FILTRO_COMPANY
} from 'constants/ActionTypes';

export const limpaValidacoes = () => {

    var teste = "teste";

    return {
        type: LIMPA_VALIDACOES
      };
    };


export const cancelaEdicao = () => {
    return {
      type: CANCELA_EDICAO
    };
  };

export const onChangeValue = (e) => {

    return {
        type: ALTERACAO_CAMPO,
        payload: e
    }
};

export const onChangeDate = (name, date, compoeIntervalo, tipo, valorComparacao) => {

    return {
        type: ALTERACAO_DATEPICKER,
        payload: { name, date }
    }    
};

export const onChangeFilter = (e) => {

    return {
        type: APLICA_FILTRO,
        payload: e.target.value
    }
};

export const onChangeFilterCompany = (e) => {

    var search = e.target.value;
    var id = e.target.name;

    return {
        type: APLICA_FILTRO_COMPANY,
        payload: { search, id }
    }
};

export const onChangeCombo = (name, object, value) => {
    var idSelecionado = value !== null && value !== undefined && value !== '' ? value.id : 0;
    var nomeCombo = name;

/*     var objTeste = object;

    if(object !== undefined)
    {
        var tipoInvocacao = [object.type].toString();

        if (tipoInvocacao !== "change") {
    
            if (tipoInvocacao === "click") {
    
                if (value !== null) {
                    var nome = [object.currentTarget.id].toString();
                    var insercaoAutomatica = nome.indexOf("-option-");
                    nomeCombo = nome.substring(0, insercaoAutomatica);
                }
                else
                {
                    return {
                        type: ALTERACAO_COMBO,
                        payload: { nomeCombo: [name], valor: 0 }
                    }                
                }            
            }
            else if (tipoInvocacao === "keydown") {
                nomeCombo = [object.target.id].toString();
            }
    
            if (nomeCombo !== "") {
                return {
                    type: ALTERACAO_COMBO,
                    payload: { nomeCombo: [nomeCombo], valor: value.id }
                }
            }
        }
    }
    else
    {
        nomeCombo = [name.target.id].toString();

        return {
            type: ALTERACAO_COMBO,
            payload: { nomeCombo: [nomeCombo], valor: '' }            
        }
    } */

    return {
        type: ALTERACAO_COMBO,
        payload: { nomeCombo: [nomeCombo], valor: idSelecionado }            
    }
};

export const onChangeComboMultipla = (name, object, valoresSelecionados, valoresLista) => {
    var nomeCombo = name;
    var retornoValores = [];

    for (var indice in valoresLista) {

        let valor = valoresLista[indice].title;

        const isInArray = valoresSelecionados.includes(valor);

        if(isInArray)
        {
            retornoValores.push(valoresLista[indice].id);
        }
    }
    return {
        type: ALTERACAO_COMBO,
        payload: { nomeCombo: [nomeCombo], valor: retornoValores }            
    }    

/*     var objTeste = object;

    if(object !== undefined)
    {
        var tipoInvocacao = [object.type].toString();

        if (tipoInvocacao !== "change") {
    
            if (tipoInvocacao === "click") {
    
                if (value !== null) {
                    var nome = [object.currentTarget.id].toString();
                    var insercaoAutomatica = nome.indexOf("-option-");
                    nomeCombo = nome.substring(0, insercaoAutomatica);
                }
                else
                {
                    return {
                        type: ALTERACAO_COMBO,
                        payload: { nomeCombo: [name], valor: 0 }
                    }                
                }            
            }
            else if (tipoInvocacao === "keydown") {
                nomeCombo = [object.target.id].toString();
            }
    
            if (nomeCombo !== "") {
                return {
                    type: ALTERACAO_COMBO,
                    payload: { nomeCombo: [nomeCombo], valor: value.id }
                }
            }
        }
    }
    else
    {
        nomeCombo = [name.target.id].toString();

        return {
            type: ALTERACAO_COMBO,
            payload: { nomeCombo: [nomeCombo], valor: '' }            
        }
    } */
};

export const onChangeCheck = (object, value) => {
    console.log(object, value)
    var teste = "teste";
    var nomeCheck = [object.target.id].toString();
    nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

    return {
        type: ALTERACAO_CHECK,
        payload: { nomeCheck: [nomeCheck], valor: value }
    }   
}; 

export const onChangeRadio = (object, value) => {

    var teste = "teste";
    var nomeCheck = [object.currentTarget.name].toString();

    return {
        type: ALTERACAO_CHECK,
        payload: { nomeCheck: [nomeCheck], valor: value }
    }   
}; 

export const onChangeValueMask = (e) => {

    if (e.target.id === "cpf") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_CPF,
            payload: e
        }
    }
    else if (e.target.id === "cnpj") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_CNPJ,
            payload: e
        }
    }
    else if (e.target.id === "telefone") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_TELEFONE,
            payload: e
        }
    }
    else if (e.target.id === "telefone2") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_TELEFONE_2,
            payload: e
        }
    }
    else if (e.target.id === "cep") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_CEP,
            payload: e
        }
    }
    else if (e.target.id === "date") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_DATE,
            payload: e
        }
    }
    else if (e.target.id === "ip") {
        return {
            type: ALTERACAO_CAMPO_MASCARA_IP,
            payload: e
        }
    }    
};

