import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  TOKEN_INVALIDO,
  FECHA_MODULOS,
  SELECIONA_MODULOS,
  SELECIONA_MODULOS_PORTARIA,
  ATIVA_TESTES,
  IR_PARA_MODULO,
  CARREGA_PESSOAS_PARA_TESTES,
  CARREGA_PESSOAS_PARA_TESTES_SUCESSO,
  SELECIONA_PESSOA_SIMULACAO,
  EXECUTA_SIMULACAO,
  INICIA_DEFINICOES,
  ATUALIZA_MODO_PORTARIA,
  VOLTAR_INICIO_PORTARIA
} from 'constants/ActionTypes';

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignOutPortaria = (modo, idAmbientePortaria, idPosicaoPortaria, pontosAcessoSelecionados, idIntervalGerado) => {

  clearInterval(idIntervalGerado);
  
  return [ 
  {
    type: ATUALIZA_MODO_PORTARIA,
    payload: { modo, idAmbientePortaria, idPosicaoPortaria, pontosAcessoSelecionados }
  },
  {
    type: SIGNOUT_USER,
    payload: null
  }
]

};

export const userSignInSuccess = (authUser, perfis, configuracao) => {

  var _acessaPortaria = configuracao.visualizaDadosPortaria || configuracao.concedeAcessos || configuracao.listaPontosAcessoPortaria.length > 0
  var _acessaBackOffice = configuracao.listaConfiguracaoPortalFuncionalideAcao.length > 0;

  if (_acessaBackOffice && _acessaPortaria) {
    return [{
      type: SIGNIN_USER_SUCCESS,
      payload: { authUser, perfis, configuracao }
    },
    {
      type: SELECIONA_MODULOS_PORTARIA,
      payload: { configuracao: configuracao, loginRecente: true }
    }]
  }
  else if (_acessaPortaria) {
    return [{
      type: SIGNIN_USER_SUCCESS,
      payload: { authUser, perfis, configuracao }
    },
    {
      type: IR_PARA_MODULO,
      payload: { nomeModulo: 'Portaria', configuracao: configuracao }
    },
    {
      type: VOLTAR_INICIO_PORTARIA,
      payload: null,
    },
    {
      type: INICIA_DEFINICOES,
      payload: null
    }]
  }
  else {
    return [{
      type: SIGNIN_USER_SUCCESS,
      payload: { authUser, perfis, configuracao }
    },
    {
      type: IR_PARA_MODULO,
      payload: { nomeModulo: 'Backoffice', configuracao: configuracao }
    }]
  }
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const fechaModulos = () => {
  return {
    type: FECHA_MODULOS,
    payload: null
  };
};

export const selecionaModulos = () => {
  return {
    type: SELECIONA_MODULOS,
    payload: null
  };
};

export const ativaTestes = () => {

  return [{
    type: CARREGA_PESSOAS_PARA_TESTES
  },
  {
    type: ATIVA_TESTES
  }]
};

export const carregaPessoasParaTestesSucesso = (ambientesPortaria, usuariosPlataforma) => {
  return {
    type: CARREGA_PESSOAS_PARA_TESTES_SUCESSO,
    payload: { ambientesPortaria, usuariosPlataforma }
  };
}

export const selecionaPessoaSimulacao = (idPessoa) => {

  return [{
    type: SELECIONA_PESSOA_SIMULACAO,
    payload: idPessoa
  }]
};

export const executaSimulacao = (idPessoa, idPonto) => {

  if (idPessoa !== 0) {
    return {
      type: EXECUTA_SIMULACAO,
      payload: { idPessoa, idPonto }
    }
  }
}


export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const exibeTokenInvalido = () => {
  return {
    type: TOKEN_INVALIDO,
  };
}
