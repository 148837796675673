import React from 'react';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const IconeBiometriaSimples = (props) => {

let _corLateral = props.tipo === 'ativado' ? '#05B59D' : props.tipo === 'desativado' ? '#BDBDBD' : props.tipo === 'alertar' ? '#EB5757' : '';
let _corIcone = props.tipo === 'ativado' ? '#222224' : props.tipo === 'desativado' ? '#828282' : props.tipo === 'alertar' ? '#EB5757' : '';

    return (
        <div style={{backgroundColor: props.alertar ? '#FFECEC' : 'transparent', width: 36, height: 39}}>
            <div style={{float: 'left', backgroundColor: _corLateral, width:11, height: 3}}></div>
            <div style={{float: 'left', backgroundColor: 'transparent', width:14, height: 3}}></div>
            <div style={{float: 'left', backgroundColor: _corLateral, width:11, height: 3}}></div>
            <div style={{float: 'left', backgroundColor: _corLateral, width:3, height: 10}}></div>
            <div style={{float: 'right', backgroundColor: _corLateral, width:3, height: 10}}></div>
            <div style={{clear: 'both'}}></div>
            <div style={{float: 'left', backgroundColor: 'transparent', width:3, height: 13}}></div>
            <div style={{textAlign: 'center', marginLeft: 'auto', marginTop: -5, marginBottom: -20, marginRight: 'auto'}}><FingerprintIcon style={{marginLeft: -2, color: _corIcone}} /> </div>
            <div style={{float: 'right', backgroundColor: 'transparent', width:3, height: 13}}></div>
            <div style={{clear: 'both'}}></div>
            <div style={{float: 'left', backgroundColor: _corLateral, width:3, height: 10}}></div>
            <div style={{float: 'right', backgroundColor: _corLateral, width:3, height: 10}}></div>     
            <div style={{clear: 'both'}}></div>  
            <div style={{float: 'left', backgroundColor: _corLateral, width:11, height: 3}}></div>
            <div style={{float: 'left', backgroundColor: 'transparent', width:14, height: 3}}></div>
            <div style={{float: 'left', marginBottom: -3, backgroundColor: _corLateral, width:11, height: 3}}></div>              
      </div>
    );
  }
;

export default IconeBiometriaSimples;