import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { Card, CardBody, CardFooter } from 'reactstrap';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { onChangeValueFiltroPessoaTestes, desocuparVagaGaragem, exibeLotacaoGaragem, onChangeValueFiltroUnidadeGaragem, aplicaAlteracoesGaragens } from 'actions/PortariaInicio';
import { LuxFwTextBox, LuxFwComboBox, LuxFwSwitchCheck, LuxFwRow } from 'luxFw/palettes/Standart';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import { setDrawerType } from 'actions/index';
import { AiFillAppstore } from "@react-icons/all-files/ai/AiFillAppstore";
import { RiNotification3Fill } from "@react-icons/all-files/ri/RiNotification3Fill";
import { IoIosHelpCircle } from "@react-icons/all-files/io/IoIosHelpCircle";
import { RiTestTubeFill } from "@react-icons/all-files/ri/RiTestTubeFill";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { FaConciergeBell } from "@react-icons/all-files/fa/FaConciergeBell";
import { FaPlayCircle } from "@react-icons/all-files/fa/FaPlayCircle";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SpinnerDotted } from 'spinners-react';
import * as Unicons from "@iconscout/react-unicons";

function ObtemImagemUsuario() {
  return localStorage.getItem('user_img');
}

const GreenCheckbox = withStyles({
  root: {
    color: '#05B59D',
    '&$checked': {
      color: '#05B59D',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const styles = theme => ({
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "gray",
      fontSize: 35
    },
  },
  inputPesquisaTestes: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "gray",
      fontSize: 20
    },
  },
  inputPesqGar: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "gray",
      fontSize: 20
    },
  },
  correcaoBorda: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '#05B59D',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#05B59D',
      borderBottomWidth: '2px'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#05B59D',
    },
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class Header extends React.Component {

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };

  setCollapsedDrawer = () => {
    this.props.setDrawerType(COLLAPSED_DRAWER);
  };

  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };
  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link onClick={this.setCollapsedDrawer.bind(this)} className="jr-list-link" to="/app/portaria">
            <i className="zmdi zmdi-calendar zmdi-hc-fw" />
            <span className="jr-list-text">{/* <IntlMessages id="sidebar.calendar.basic"/> */} Portaria</span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw" />
            <span className="jr-list-text text-center">{/* <IntlMessages id="sidebar.appModule.toDo"/> */} Auxílio Registro</span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw" />
            <span className="jr-list-text text-center">{/* <IntlMessages id="sidebar.appModule.mail"/> */} Outro Módulo</span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw" />
            <span className="jr-list-text text-center">{/* <IntlMessages id="sidebar.appModule.mail"/> */} Outro Módulo</span>
          </Link>
        </li>
      </ul>)
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      exibirOcupacaoGaragens: false,
      exibirOcupantes: false,
      idUnidadeExpandir: 0
    }
  }

  handleexibeOcultaGaragens() {

    var exibir = !this.state.exibirOcupacaoGaragens;

    if (exibir) {
      this.props.exibeLotacaoGaragem();

      this.setState({
        idUnidadeExpandir: 0,
      });
    }

    this.setState({
      exibirOcupacaoGaragens: !this.state.exibirOcupacaoGaragens,
    });
  }

  handleexibeOcultaOcupantes(idUnidade) {
    this.setState({
      exibirOcupantes: !this.state.exibirOcupantes,
      idUnidadeExpandir: idUnidade
    });
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  render() {
    var imagemTratada = ObtemImagemUsuario();;
    imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;
    var exibePontosParaSelecao = this.props.exibePontosParaSelecao;
    var listaDeAmbientesPortaria = this.props.listaDeAmbientesPortaria;
    var listaPessoas = this.props.listaDeUsuariosPlataformaFiltroteste;
    let adminUzer = this.props.authUser === '1';
    const { classes } = this.props;

    const { drawerType, locale, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

    return (
      <Fragment>
        <Drawer anchor="top" open={exibePontosParaSelecao} onClose={() => { this.props.ativaTestes(); }} >

          <div >
            <div style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, height: 72, fontSize: 20, fontFamily: 'Montserrat, sans-serif', fontWeight: 500, letterSpacing: '0.15px' }} className="shadow border-2 mb-2" >
              Escolha uma pessoa e clique em um ponto para simular resposta da API
            </div>

            {this.props.carregandoInformacoes ?
              (<Fragment>
                <div style={{ marginTop: 130, marginBottom: 50, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                  <SpinnerDotted size={90} thickness={180} speed={90} color="rgba(5, 181, 157, 1)" />
                  <h1 style={{ fontFamily: 'Montserrat, sans-serif', color: 'gray', marginTop: 20 }}>Carregando informações para teste...</h1>
                </div>
              </Fragment>) : (

                <div style={{ paddingLeft: 20, paddingTop: 20, backgroundColor: '#FAFAFA' }}>

                  <div style={{ clear: 'both', backgroundColor: '#FAFAFA', paddingRight: 18 }}>


                    <Card className={`shadow border-0`} style={{ marginBottom: 35, paddingBottom: 15, borderRadius: '8px', backgroundColor: '#fafafa' }}>

                      <CardBody style={{ paddingBottom: 5 }}>
                        <Scrollbars renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                          style={{ height: 318, width: '100%' }}>

                          <TextField
                            name="textoFiltroPessoasTeste"
                            variant="filled"
                            placeholder="Pesquisar..."
                            value={this.props.textoFiltroPessoasTeste}
                            onChange={this.props.onChangeValueFiltroPessoaTestes}
                            margin="normal"
                            style={{ width: '25%', minWidth: '290px', lineHeight: '24px', backgroundColor: '#00000000', marginTop: 0, marginBottom: 20 }}
                            InputProps={{
                              classes: {
                                input: classes.inputPesquisaTestes
                              },
                              endAdornment: (
                                <InputAdornment>
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                          />

                          <Grid container spacing={1} >

                            {
                              listaPessoas.map(pessoa => {

                                var imagemTratada = pessoa.imagem;
                                imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

                                return (
                                  <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Paper onClick={() => { this.props.selecionaPessoaSimulacao(pessoa.idPessoa); }} style={{ cursor: 'pointer', backgroundColor: pessoa.idPessoa === this.props.idPessoaSelecionadaSimulacao ? '#dcf8f4' : '#fff', border: pessoa.idPessoa === this.props.idPessoaSelecionadaSimulacao ? '2px solid #05B59D' : '1px solid #F4F4F7' }} className={`${classes.paper}`}>
                                      <div style={{ cursor: 'pointer' }}>
                                        <Grid container spacing={1}>
                                          <Grid item>
                                            {
                                              imagemTratada !== null ?
                                                <Avatar className={classes.large} src={`data:image/jpeg;base64,${imagemTratada}`} /> :
                                                <Avatar className={classes.large} src={require("assets/images/no-image.jpg")} />
                                            }

                                          </Grid>
                                          <Grid item xs={12} sm container>
                                            <Grid item container spacing={1}>
                                              <Grid item>
                                                <Typography style={{ textAlign: 'left', fontWeight: 600, fontSize: 14, paddingTop: 8, fontFamily: 'Montserrat, sans-serif', color: '#222224' }}>
                                                  {pessoa.nomeRazao}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Paper>
                                  </Grid>
                                );
                              })
                            }


                          </Grid>
                        </Scrollbars>
                      </CardBody>
                    </Card>

                  </div>



                  {this.props.idPessoaSelecionadaSimulacao !== 0 &&

                    <div style={{ clear: 'both', backgroundColor: '#FAFAFA', paddingRight: 20 }}>
                      <Card className={`shadow border-0`} style={{ marginBottom: 35, border: '1px solid #F4F4F7', borderRadius: '8px' }}>
                        <CardBody style={{ paddingBottom: 5 }}>

                          {
                            listaDeAmbientesPortaria.map(ambiente => {

                              var nomeAmbientePortaria = ambiente.localControlado.descricaoLocalControlado + ' / ' + ambiente.descricaoAmbiente;

                              return (
                                <Fragment>
                                  <FormControl style={{ marginBottom: 15 }} className={`col-md-4 col-12`} component="fieldset">
                                    <FormLabel style={{ fontWeight: 700, fontFamily: 'Montserrat, sans-serif', color: '#333333' }} component="legend">{nomeAmbientePortaria}</FormLabel>
                                    <FormGroup>

                                      {
                                        ambiente.pontosAcesso.map(ponto => {

                                          var pontoEmSimulacao = this.props.simulacoes.filter(x => x.idPonto === ponto.id && x.executandoSimulacao === true).length > 0;
                                          var idPontoSimulado = this.props.simulacoes.filter(x => x.idPonto === ponto.id).length > 0 ? ponto.id : 0;
                                          var acessoPermitido = this.props.simulacoes.filter(x => x.idPonto === ponto.id && x.acessoSimuladoPermitido === true).length > 0;
                                          var exibeResultadoTemporario = this.props.simulacoes.filter(x => x.idPonto === ponto.id && x.exibeResultadoTemporario === true).length > 0;

                                          return (
                                            <FormControlLabel style={{ height: 35 }}
                                              control={

                                                pontoEmSimulacao ?
                                                  (
                                                    <Fragment>
                                                      <IconButton onClick={() => { this.props.executaSimulacao(this.props.idPessoaSelecionadaSimulacao, ponto.id); }} className="icon-btn">
                                                        <FaPlayCircle size={30} />
                                                      </IconButton>
                                                      <CircularProgress style={{ marginLeft: -44, marginRight: 9 }} size={33}></CircularProgress>
                                                    </Fragment>
                                                  ) :
                                                  (
                                                    <Fragment>
                                                      <IconButton onClick={() => { this.props.executaSimulacao(this.props.idPessoaSelecionadaSimulacao, ponto.id); }} className="icon-btn">

                                                        {idPontoSimulado !== ponto.id && (<FaPlayCircle size={30} />)}
                                                        {(exibeResultadoTemporario && acessoPermitido && idPontoSimulado === ponto.id) && (<FaPlayCircle size={30} style={{ color: 'green' }} />)}
                                                        {(exibeResultadoTemporario && !acessoPermitido && idPontoSimulado === ponto.id) && (<FaPlayCircle size={30} style={{ color: 'red' }} />)}

                                                      </IconButton>
                                                    </Fragment>
                                                  )

                                              }
                                              label={<div style={{ fontSize: 14 }}>{ponto.descricaoPontoAcesso}</div>}
                                            />
                                          );
                                        })
                                      }

                                    </FormGroup>
                                  </FormControl>


                                </Fragment>)
                            })}

                        </CardBody>
                      </Card>

                    </div>}

                </div>)
            }

          </div>

        </Drawer>


        <Drawer anchor="right" open={this.state.exibirOcupacaoGaragens} onClose={() => this.handleexibeOcultaGaragens()}>
          <div style={{ width: '700px' }}>
            <div style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20, height: 72, fontSize: 20, fontFamily: 'Montserrat, sans-serif', fontWeight: 500, letterSpacing: '0.15px' }} className="shadow border-2 mb-2" >
              Ocupação de Garagens em tempo real
            </div>

            <div style={{ paddingLeft: 22, paddingTop: 20, backgroundColor: '#FAFAFA' }}>


              <div style={{ clear: 'both', backgroundColor: '#FAFAFA', paddingRight: 20 }}>
                <Card className={`shadow border-0`} style={{ border: '1px solid #F4F4F7', borderRadius: '8px' }}>

                  {
                    this.props.carregandoGaragens ? (
                      <CardBody style={{ paddingBottom: 5, textAlign: 'center', height: 500 }}>

                        <div style={{ marginTop: 130, marginBottom: 50 }}>
                          <SpinnerDotted size={90} thickness={180} speed={90} color="rgba(5, 181, 157, 1)" />
                          <h1 style={{ fontFamily: 'Montserrat, sans-serif', color: 'gray', marginTop: 20 }}>Processando informações de Garagens...</h1>
                        </div>

                      </CardBody>
                    ) : (
                      <Scrollbars
                        style={{ height: 580 }}>
                        <CardBody style={{ paddingBottom: 5 }}>


                          <TextField
                            variant="filled"
                            placeholder="Pesquise o nome da Unidade"
                            value={this.props.textoFiltroClienteAlternar}
                            onChange={this.props.onChangeValueFiltroUnidadeGaragem}
                            margin="normal"
                            fullWidth={true}
                            style={{ lineHeight: '24px', marginTop: '10px', marginBottom: '10px', backgroundColor: '#00000000' }}
                            InputProps={{
                              classes: {
                                input: classes.inputPesqGar
                              },
                              endAdornment: (
                                <InputAdornment>
                                  <SearchIcon />
                                </InputAdornment>
                              )
                            }}
                          />

                          {
                            this.props.garagensSalvas && (
                              <div style={{ marginTop: 130, marginBottom: 50, textAlign: 'center' }}>
                                <Unicons.UilCheckCircle size={130}  color="rgba(5, 181, 157, 1)" />
                                <h1 style={{ fontFamily: 'Montserrat, sans-serif', color: 'gray', marginTop: 20 }}>Informações de garagens salvas</h1>
                              </div>
                            )
                          }

                          {
                            !this.props.garagensSalvas && this.props.listaLotacaoGaragensFiltrada !== undefined && this.props.listaLotacaoGaragensFiltrada.map(lotgaragem => {

                              return (
                                <Fragment>
                                  <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                                    <div><span style={{ fontWeight: 600 }}>Unidade:</span> {lotgaragem.descricaoUnidade}</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div><span style={{ fontStyle: 'italic' }}>{lotgaragem.qtdeGaragem} vaga(s) de garagem </span></div>
                                      <div onClick={() => this.handleexibeOcultaOcupantes(lotgaragem.idUnidade)} style={{ paddingLeft: 20, color: '#05B59D', fontSize: '14px', cursor: 'pointer', textDecorationLine: 'underline' }}>
                                        {lotgaragem !== undefined ? lotgaragem.ocupantes.length : 0} Ocupada(s)
                                      </div>
                                    </div>

                                    {
                                      this.state.exibirOcupantes && this.state.idUnidadeExpandir === lotgaragem.idUnidade && (
                                        <div style={{ marginTop: 10, backgroundColor: '#effcfa', paddingTop: 13, paddingBottom: 9, paddingLeft: 8, paddingRight: 8, border: 'solid 1px #156a15' }}>

                                          {
                                            lotgaragem.ocupantes !== undefined && lotgaragem.ocupantes.length > 0 && lotgaragem.ocupantes.map(ocupante => {

                                              return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                  <div style={{ fontSize: 12 }}>{ocupante.nomePessoa} (entrou em {ocupante.dataHoraEntradaStr})</div>
                                                  <div onClick={() => this.props.desocuparVagaGaragem(lotgaragem.idUnidade, ocupante.idLotacaoGaragem)} style={{ display: 'flex' }}>
                                                    <div>
                                                      <Unicons.UilCornerUpLeftAlt size={20} style={{ color: 'red', marginRight: 2 }} />
                                                    </div>
                                                    <div style={{ fontSize: 12, cursor: 'pointer' }}>Desocupar</div>
                                                  </div>

                                                </div>
                                              )
                                            })
                                          }

                                          {
                                            lotgaragem.ocupantes !== undefined && lotgaragem.ocupantes.length === 0 && (
                                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex' }}>
                                                  <div>
                                                    <Unicons.UilExclamationTriangle size={20} style={{ color: 'orange', marginRight: 2 }} />
                                                  </div>
                                                  <div style={{ fontSize: 12, cursor: 'pointer', paddingTop: 2 }}>Nenhuma garagem ocupada!</div>
                                                </div>

                                              </div>
                                            )
                                          }

                                        </div>
                                      )
                                    }

                                  </div>

                                  <Divider style={{ marginLeft: -25, marginRight: -25 }} />

                                </Fragment>
                              )

                            })
                          }

                        </CardBody>
                      </Scrollbars>
                    )
                  }



                </Card>
              </div>

              {
                this.props.lotacoesGaragemDesocupar !== undefined && this.props.lotacoesGaragemDesocupar.length > 0 ? (
                  <Button onClick={() => this.props.aplicaAlteracoesGaragens(this.props.lotacoesGaragemDesocupar)} style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100, marginBottom: 10 }} variant="contained" >
                    <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Aplicar alterações</span>
                  </Button>
                ) :
                  (<Button disabled style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', padding: '14px 36px', borderRadius: 100, marginBottom: 10 }} variant="contained" >
                    <span style={{ fontStyle: 'normal', color: '#gray', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Aplicar alterações</span>
                  </Button>)
              }



            </div>



          </div>
        </Drawer>


        <AppBar
          className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
          <Toolbar className="app-toolbar" disableGutters={false}>

            {/* <h4 className="mb-0 mr-auto text-white">Uzer</h4> */}

            {navigationStyle === HORIZONTAL_NAVIGATION ?
              <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                <span className="jr-menu-icon">
                  <span className="menu-icon" />
                </span>
              </div>
              :

              this.props.selecionaModulo ?
                <div style={{ textAlign: 'right' }}>
                  <a className="app-logo " style={{ marginLeft: 'auto', marginRight: 'auto' }} target="_blank" color="inherit" rel="noopener noreferrer" href="https://uzer.com.br/">
                    <img src={require("assets/images/logo.png")} alt="Uzer Tecnologia" title="Uzer Tecnologia" />
                  </a>
                </div> :


                <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                  onClick={this.onToggleCollapsedNav}>
                  <span className="menu-icon" />
                </IconButton>
            }

            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
              <Menu />}

            <ul className="header-notifications list-inline ml-auto">

              {this.props.moduloSelecionado === 'Backoffice' && adminUzer &&
                <li className="list-inline-item">
                  <div style={{ cursor: 'pointer' }} onClick={() => { this.props.ativaTestes(); }}>
                    <span className="app-notification">
                      <RiTestTubeFill size={20} style={{ color: 'yellow', marginLeft: 6, marginRight: 6 }} />
                    </span>
                  </div>
                </li>}

              <li className="list-inline-item">
                <span className="app-notification">
                  <IoIosHelpCircle size={20} style={{ color: 'silver', marginLeft: 6, marginRight: 6 }} />
                </span>
              </li>
{/*               <li className="list-inline-item">

                <span className="app-notification">
                  <RiNotification3Fill size={20} style={{ color: 'silver', marginLeft: 6, marginRight: 6 }} />
                </span>
              </li> */}

              {
               this.props.configuracao.usuarioMantemGaragem && (
                  <li className="list-inline-item app-tour">


                    <div style={{ cursor: 'pointer' }} onClick={() => this.handleexibeOcultaGaragens()}>
                      <span className="app-notification">
                        <IconButton className="icon-btn">
                          <i style={{ color: 'silver' }} className="zmdi zmdi-car" />
                        </IconButton>
                      </span>
                    </div>

                  </li>
                )
              }

              {
                !this.props.selecionarModulo &&
                <li className="list-inline-item">
                  <div style={{ cursor: 'pointer' }} onClick={() => { this.props.selecionaModulos(); }}>
                    <span className="app-notification">
                      <AiFillAppstore size={20} style={{ color: 'silver', marginLeft: 6, marginRight: 6 }} />
                    </span>
                  </div>

                </li>
              }

              <li className="list-inline-item">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.langSwitcher}
                  toggle={this.onLangSwitcherSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn">
                      <i className={`flag flag-24 flag-${locale.icon}`} />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right className="w-50">
                    <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                      handleRequestClose={this.handleRequestClose} />
                  </DropdownMenu>
                </Dropdown>


              </li>
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt='...'
                        src={`data:image/jpeg;base64,${imagemTratada}`}
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup />
                  </DropdownMenu>
                </Dropdown>
              </li>{/* } */}
            </ul>

            {/* <div className="ellipse-shape"></div> */}
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }

}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ settings, portaria, portariaInicio, auth }) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition, moduloSelecionado, loginRecente, configuracao } = settings;
  const { exibePontosParaSelecao, idPessoaSelecionadaSimulacao } = portaria;
  const { authUser } = auth;
  const { listaDeAmbientesPortariaCombo, carregandoInformacoes, listaDeAmbientesPortaria, exibeNotificacoes,
    atendimentosFila, listaDeUsuariosPlataforma, listaDeUsuariosPlataformaFiltroteste, idPontoSimulado,
    executandoSimulacao, exibeResultadoTemporario, acessoSimuladoPermitido, simulacoes, textoFiltroPessoasTeste,
    carregandoGaragens, listaLotacaoGaragensFiltrada, lotacoesGaragemDesocupar, controlaGaragem, garagensSalvas } = portariaInicio;
  return {
    drawerType, locale, navigationStyle, exibePontosParaSelecao, horizontalNavPosition,
    listaDeAmbientesPortariaCombo, configuracao, carregandoInformacoes, listaDeAmbientesPortaria, listaDeUsuariosPlataforma, listaDeUsuariosPlataformaFiltroteste, exibeNotificacoes, atendimentosFila,
    idPessoaSelecionadaSimulacao, idPontoSimulado, executandoSimulacao, exibeResultadoTemporario, acessoSimuladoPermitido, moduloSelecionado, simulacoes, loginRecente, authUser,
    textoFiltroPessoasTeste, carregandoGaragens, listaLotacaoGaragensFiltrada, lotacoesGaragemDesocupar, garagensSalvas, controlaGaragem
  }
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, exibeLotacaoGaragem, desocuparVagaGaragem, switchLanguage, setDrawerType, onChangeValueFiltroPessoaTestes, onChangeValueFiltroUnidadeGaragem, aplicaAlteracoesGaragens })(withStyles(styles)(Header)));