import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_ACESSO_PERFIL, INICIA_ACESSO_PERFIL, BUSCA_ACESSO_PERFIL_EDICAO, REMOVE_ACESSO_PERFIL,
         LISTA_LOCAIS_PERFIS_POR_CLIENTE, LISTA_CONFIGURACAO_PONTO_PERFIL, LISTA_ACESSO_PERFIL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaAcessoPerfilSucesso,
         removeAcessoPerfilSucesso, 
         inicioAcessoPerfilSucesso, 
         iniciaAcessoPerfil,
         buscaAcessoPerfilEdicaoSucesso,
         listaLocaisPerfisSucesso,
         listaConfiguracaoPontoPerfilSucesso,
         listaAcessoPerfilSucesso } from "actions/AcessoPerfil";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getAcessosPerfil = async () =>
    await requisicaoApi.get('/acessosPerfil')
    .then(resposta => resposta)
    .catch(error => error);

    const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);     

  const getAcessoPerfilEdicao = async (id) =>
    await requisicaoApi.get('/acessosPerfil/' + id)
    .then(resposta => resposta)
    .catch(error => error); 

  const getListaPerfisPorCliente = async (cliente) => 
    await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);    
    
    const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);

    const getlistaConfiguracaoPontoPerfil = async (idLocal, idPerfil) => 
    await requisicaoApi.get('/acessosPerfil/vinculoPontoAcesso/' + idLocal + '/' + idPerfil)
    .then(resposta => resposta)
    .catch(error => error);    

    const getListaAcessoPerfil = async (perfilFiltro, ambienteFiltro, pontoFiltro) => 
    await requisicaoApi.get('/acessosPerfil?Perfil=' +  perfilFiltro + '&Ambiente=' + ambienteFiltro + '&PontoAcesso=' + pontoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  
    
/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaAcessoPerfilRequest = async (acessoPerfil, pontosAssociados) => await requisicaoApi.post('/acessosPerfil', 
  {
    "id": acessoPerfil.id,
      "idCriterio": 0,
      "criterioAcesso": {
        "tipoCriterioAcesso": "PERFIL",
        "ativo": true,
        "minutosPadraoRetorno": 0,
        "pontosAcessoVinculados": pontosAssociados,
        "id": 0
      },
      "idPerfil": acessoPerfil.idPerfil,
      "idCliente": acessoPerfil.idCliente,
      "idLocalControlado": acessoPerfil.idLocalControlado,
      "idPessoa": acessoPerfil.idPessoa,
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaAcessoPerfilRequest = async (acessoPerfil, pontosAssociados) => await requisicaoApi.put('/acessosPerfil', 
  {
    "id": acessoPerfil.id,
      "idCriterio": 0,
      "criterioAcesso": {
        "tipoCriterioAcesso": "PERFIL",
        "ativo": true,
        "minutosPadraoRetorno": 0,
        "pontosAcessoVinculados": pontosAssociados,
        "id": 0
      },
      "idPerfil": acessoPerfil.idPerfil,
      "idCliente": acessoPerfil.idCliente,
      "idLocalControlado": acessoPerfil.idLocalControlado,
      "idPessoa": acessoPerfil.idPessoa,
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteAcessoPerfilRequest = async (id) =>
  await requisicaoApi.delete('/acessosPerfil?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaAcessoPerfil() {
    try {
      const listagemGrid = yield call(getAcessosPerfil);
  
      if(listagemGrid.data)
      {
        const clientes = yield call(getClientes);

        if(clientes.data) {
          const clienteFixo = localStorage.getItem('idClienteFixado');

          if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
          {
            const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
            const perfis = yield call(getListaPerfisPorCliente, parseInt(clienteFixo));

            yield put(inicioAcessoPerfilSucesso(listagemGrid.data, clientes.data, locais.data, perfis.data));
          }
          else
          {
            yield put(inicioAcessoPerfilSucesso(listagemGrid.data, clientes.data, null, null));
          }
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar acessosPerfil'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaLocaisPerfisPorCliente({ payload }) {
    try {
      const cliente = payload.idCliente;
      const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
  
      if(retorno.data)
      {
        const perfis = yield call(getListaPerfisPorCliente, cliente);

        if(perfis.data) {
          yield put(listaLocaisPerfisSucesso(retorno.data, perfis.data));
        }
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }

  function* fnlistaConfiguracaoPontoPerfil({ payload }) {
    try {
      const idLocal = payload.idLocal;
      const idPerfil = payload.idPerfil
      const retorno = yield call(getlistaConfiguracaoPontoPerfil, idLocal, idPerfil);
  
      if(retorno.data)
      {
        yield put(listaConfiguracaoPontoPerfilSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso do local: ' + idLocal));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  function* fnBuscaAcessoPerfilEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getAcessoPerfilEdicao, id);
  
      if(retorno.data)
      {
        yield put(buscaAcessoPerfilEdicaoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do acessoPerfil: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaAcessoPerfil({ payload }) {
    try {
      const perfilFiltro = payload.perfil;
      const ambienteFiltro = payload.ambiente;
      const pontoFiltro = payload.ponto;
      const retorno = yield call(getListaAcessoPerfil, perfilFiltro, ambienteFiltro, pontoFiltro );
  
      if(retorno.data)
      {
        yield put(listaAcessoPerfilSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de acesso perfil'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaAcessoPerfil({ payload }) {
  try {

    const acessoPerfil = payload.acessoPerfil;
    const pontosAssociados = payload.listaPontosAssociados;
    var resultado = "";

      if(acessoPerfil.id === 0) {
        const retorno = yield call(adicionaAcessoPerfilRequest, acessoPerfil, pontosAssociados);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaAcessoPerfilRequest, acessoPerfil, pontosAssociados);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaAcessoPerfil());
        yield put(salvaAcessoPerfilSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }        

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveAcessoPerfil({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteAcessoPerfilRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaAcessoPerfil());
        yield put(removeAcessoPerfilSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesAcessoPerfil() {
  yield takeEvery(INICIA_ACESSO_PERFIL, fnIniciaAcessoPerfil);
}

export function* salvaAcessoPerfil() {
  yield takeEvery(SALVA_ACESSO_PERFIL, fnSalvaAcessoPerfil);
}

export function* buscaAcessoPerfilEdicao() {
  yield takeEvery(BUSCA_ACESSO_PERFIL_EDICAO, fnBuscaAcessoPerfilEdicao);
}

export function* removeAcessoPerfil() {
  yield takeEvery(REMOVE_ACESSO_PERFIL, fnRemoveAcessoPerfil);
}

export function* listaLocaisPerfisPorCliente() {
  yield takeEvery(LISTA_LOCAIS_PERFIS_POR_CLIENTE, fnListaLocaisPerfisPorCliente)
}

export function* listaConfiguracaoPontoPerfil() {
  yield takeEvery(LISTA_CONFIGURACAO_PONTO_PERFIL, fnlistaConfiguracaoPontoPerfil)
}

export function* listaAcessoPerfil() {
  yield takeEvery(LISTA_ACESSO_PERFIL, fnListaAcessoPerfil)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesAcessoPerfil), 
             fork(salvaAcessoPerfil), 
             fork(buscaAcessoPerfilEdicao),
             fork(removeAcessoPerfil),
             fork(listaLocaisPerfisPorCliente),
             fork(listaConfiguracaoPontoPerfil),
             fork(listaAcessoPerfil)]);
}