import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { generos } from '../app/constantes/Listas';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_ACESSO_GENERO,
    NOVO_ACESSO_GENERO,
    BUSCA_ACESSO_GENERO_EDICAO,
    CANCELA_EDICAO,
    INICIO_ACESSO_GENERO_SUCESSO,
    SALVA_ACESSO_GENERO_SUCESSO,
    REMOVE_ACESSO_GENERO_SUCESSO,
    BUSCA_ACESSO_GENERO_EDICAO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_GENERO,
    LISTA_LOCAIS_SUCESSO,
    LISTA_CONFIGURACAO_PONTO_GENERO,
    LISTA_CONFIGURACAO_PONTO_GENERO_SUCESSO,
    ALTERACAO_CHECK_PONTO_ACESSO_GENERO,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    LIMPAR_ACESSO_GENERO,
    LISTA_ACESSO_GENERO_SUCESSO,
    LISTA_ACESSO_GENERO,
    ADICIONA_PERFIL_LISTA_VINCULADA_GENERO,
    REMOVE_PERFIL_LISTA_VINCULADA_GENERO          
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    colunasGrid: [
      { titulo: 'Codigo', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
      { titulo: 'Cliente', propriedadeApi: 'nomeCliente', alinhamento: 'left' },
      { titulo: 'Local Controlado', propriedadeApi: 'localControlado', alinhamento: 'left' },
      { titulo: 'Gênero', propriedadeApi: 'nomeDestino', alinhamento: 'left' },      
    ],

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],

    // Campos do Formulario
    id: 0,
    idCliente: 0,
    idLocalControlado: 0,
    idAmbiente: 0,
    sexo: '',
    nomeGenero: '',
    numeroCpf: '',
    configuracaoPontoGenero: null,
    configuracaoAtualizada: null,
    nomeClienteFixado: null,
    idClienteFixado: 0,

    // Combos
    listaDeClientes: [],
    listaDeSexos: [],
    listaDeLocaisControlados: [],
    listaDeAmbientes: [],
    listaDeEquipamentos: [],
    listaDePerfils: [],

      // Filtros
      generoAcessoFiltro: '', 
      ambienteAcessoFiltro: '', 
      pontoAcessoFiltro: '', 

   // Listas Vinculadas
      perfils: [],
      idPerfilAdd: ''
   
    };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_ACESSO_GENERO: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado')              
        }
      }
      case NOVO_ACESSO_GENERO: {
        return {
          ...state,
          edicao: true,
          id: 0,
          idCliente: state.idClienteFixado !== 0 ? parseInt(state.idClienteFixado) : 0,
          idLocalControlado: 0,
          idAmbiente: 0,
          sexo: '',
          nomeGenero: '',
          configuracaoPontoGenero: null,
          configuracaoAtualizada: null,
          perfils: [],
          idPerfilAdd: ''
        }
      }       
      case BUSCA_ACESSO_GENERO_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false
        }
      }
      case INICIO_ACESSO_GENERO_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.acessosGenero,
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
          listaDeSexos: preparaListaParaCombo(generos, "id", "title"),  
          listaDeLocaisControlados: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado") : [],
          listaDePerfils: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil") : [],          
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),            
        }
      }                     
      case SALVA_ACESSO_GENERO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_ACESSO_GENERO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_ACESSO_GENERO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_ACESSO_GENERO_SUCESSO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          listGrid: retorno.acessoGenero,
        }
      }
      case LIMPAR_ACESSO_GENERO: {
  
        var retorno = action.payload;
  
        return {
          ...state,
          generoAcessoFiltro: '', 
          ambienteAcessoFiltro: '', 
          pontoAcessoFiltro: '', 
        }
      }                     
      case BUSCA_ACESSO_GENERO_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.id,
          idCliente: retorno.idCliente,
          idLocalControlado: retorno.idLocalControlado,
          sexo: retorno.sexo,
          nomeSexo: retorno.nomeSexo,
          nomeLocalControlado: retorno.nomeLocalControlado,
          nomeCliente: retorno.nomeCliente,
          configuracaoPontoGenero: retorno.vinculoAcessoGenero,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),  
          perfils: retorno.perfils,
          listaDePerfils:    preparaListaParaCombo(retorno.perfilsCarregamento, "id", "descricaoPerfil")          
        }
      }
      case ADICIONA_PERFIL_LISTA_VINCULADA_GENERO: {
        var retorno = action.payload;
        var registroDuplicado = state.perfils.filter(x => x.idPerfil === retorno.idPerfil).length > 0;

        if(retorno.idPerfil != "" && !registroDuplicado){
          var idTemporario = state.perfils.length > 0 ? Math.max(...state.perfils.map(x => x.idTemporario)) + 1 : 1;
          const perfil = state.listaDePerfils.filter(x => x.id === retorno.idPerfil);
          const nomePerfil = perfil[0].title;
  
          const perfilAdd = {
            "criterioAcesso": null,
            "idCriterioAcesso": state.id,
            "nomePerfil": nomePerfil ,
            "idPerfil": retorno.idPerfil,
            "idTemporario": idTemporario,
            "id": 0
          }
  
          return {
            ...state,
            perfils:[...state.perfils, perfilAdd],
            idPerfilAdd: 0,
         }
        }
        else{
          return {
            ...state,
            idPerfilAdd: 0,
         }
        }
      }
      case REMOVE_PERFIL_LISTA_VINCULADA_GENERO: {
        var retorno = action.payload;

        const arrayAtualizado = state.perfils.filter(x => x.idTemporario !== retorno.idTemporario)

        return {
          ...state,
          perfils: arrayAtualizado,
       }
      }    
      case LISTA_LOCAIS_POR_CLIENTE_GENERO: {

        const retorno = action.payload;
  
        return {
          ...state,
          idCliente: retorno.idCliente,
          loader: true,
        }
      }
      case LISTA_LOCAIS_SUCESSO: {

        const retorno = action.payload;
  
        return {
          ...state,
          idLocalControlado: null,
          loader: false,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"),
          listaDePerfils: preparaListaParaCombo(retorno.perfils, "id", "descricaoPerfil")
       }
      }
      case LISTA_CONFIGURACAO_PONTO_GENERO: {
        return {
          ...state,
          idLocalControlado: state.idLocalControlado,
          loader: true
        }
      }
      case LISTA_CONFIGURACAO_PONTO_GENERO_SUCESSO: {
  
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          configuracaoPontoGenero: retorno.configuracao
        }
      }
      case ALTERACAO_CHECK_PONTO_ACESSO_GENERO: {

        const retorno = action.payload;
        var idPontoAlterado = retorno.idPontoAcessoAlterado;
  
        const listaAmbientes = state.configuracaoPontoGenero.listaAmbientesVinculados;
  
        for (var i = 0; i < listaAmbientes.length; i++) {
          var listaPontos = listaAmbientes[i].pontosAcessoVinculados;
  
          for (var j = 0; j < listaPontos.length; j++) {
            var idPontoAcesso = listaPontos[j].idPontoAcesso;
  
            if (idPontoAcesso.toString() === idPontoAlterado) {
              listaPontos[j].associado = retorno.valor;
            }
          }
  
        }
  
        const configuracaoAtualizada2 = state.configuracaoPontoGenero;
        configuracaoAtualizada2.listaAmbientesVinculados = listaAmbientes;
  
        return {
          ...state,
          configuracaoPontoGenero: {
            ...state.configuracaoPontoGenero,
            listaAmbientesVinculados: configuracaoAtualizada2.listaAmbientesVinculados,
          },
        }
      }                                
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  