import {
    INICIA_PERFIL_PORTAL,
    SALVA_PERFIL_PORTAL,
    REMOVE_PERFIL_PORTAL,
    NOVO_PERFIL_PORTAL,
    BUSCA_PERFIL_PORTAL_EDICAO,
    INICIO_PERFIL_PORTAL_SUCESSO,
    SALVA_PERFIL_PORTAL_SUCESSO,
    REMOVE_PERFIL_PORTAL_SUCESSO,
    BUSCA_PERFIL_PORTAL_EDICAO_SUCESSO,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    LISTA_PERFIL_PORTAL,
    LISTA_PERFIL_PORTAL_SUCESSO,
    LIMPAR_PERFIL_PORTAL,
    EXIBE_PONTOS_REQUISICAO,
    ATUALIZAR_PONTO_REQUISICAO,
    ATUALIZAR_PERMISSAO_FUNCIONALIDADE,
    SELECIONAR_TUDO_FUNCIONALIDADE_PERFIL,
    SELECIONAR_TUDO_PORTARIA_PERFIL,
    ALTERACAO_CHECK_PERFIL       
  } from 'constants/ActionTypes';
  import { ValidaCampoObrigatorio } from 'luxFw/transformations/Validations';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaPerfil = () => {
    return {
      type: INICIA_PERFIL_PORTAL
    };
  };
  
  export const salvaPerfil = (perfil) => {
    var validacoes = [];

    validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatorios(perfil));
  
    var errosEncontrados = validacoes.filter(x => x.payload.erro);
  
    if(errosEncontrados.length === 0)
    {
      return {
        type: SALVA_PERFIL_PORTAL,
        payload: perfil
      };
    }
    else 
    {
      var exibeMensagem = {
        type: SHOW_MESSAGE,
        payload: 'Existem inconsistências. Verifique!'
      }      
      
      validacoes.push(exibeMensagem);
  
      return validacoes;
    }  
  };

  function RetornosArrayValidacoesCamposObrigatorios(perfil) {

    var validacoes = [];
  
    validacoes.push(ValidaCampoObrigatorio('descricaoPerfil', perfil.descricaoPerfil));
  
    return validacoes;
  }
  
  export const removePerfil = (id) => {
    return {
      type: REMOVE_PERFIL_PORTAL,
      payload: id
    };
  };

  export const listaPerfil = (nome) => {
    if(nome == undefined){
      nome = "";
    }
  
    return {
      type: LISTA_PERFIL_PORTAL,
      payload: {nome}
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoPerfil = () => {
    return {
      type: NOVO_PERFIL_PORTAL
    };
  };
  
  export const buscaPerfilEdicao = (id) => {
    return {
      type: BUSCA_PERFIL_PORTAL_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  
  
  export const inicioPerfilSucesso = (perfis, ambientesPortaria, blocosFuncionalidade, funcionalidadesAcao) => {
    return {
      type: INICIO_PERFIL_PORTAL_SUCESSO,
      payload: { perfis, ambientesPortaria, blocosFuncionalidade, funcionalidadesAcao }
    }
  };
  
  export const salvaPerfilSucesso = (mensagem) => {
    return {
      type: SALVA_PERFIL_PORTAL_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removePerfilSucesso = (mensagem) => {
    return {
      type: REMOVE_PERFIL_PORTAL_SUCESSO,
      payload: mensagem
    }
  };  
  
  export const buscaPerfilEdicaoSucesso = (equipamento) => {
    return {
      type: BUSCA_PERFIL_PORTAL_EDICAO_SUCESSO,
      payload: equipamento
    };
  }; 

  export const listaPerfilSucesso = (perfil) => {
    return {
      type: LISTA_PERFIL_PORTAL_SUCESSO,
      payload: { perfil }
    };
  };
  
  export const limparFiltroPerfil = () => {
    return {
      type: LIMPAR_PERFIL_PORTAL,
    };
  };

  export const exibirPontosRequisicao = (idAmbienteSelecionado) => {
    return {
      type: EXIBE_PONTOS_REQUISICAO,
      payload: idAmbienteSelecionado
    };
  };  
  
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 
  
  export const atualizaPontoRequisicao = (object, value) => {

    var nomeCheck = [object.target.id].toString();
    nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;
  
    var indexUnderline = nomeCheck.indexOf("_");
    var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
    var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
    var valor = value;
  
    return {
      type: ATUALIZAR_PONTO_REQUISICAO,
      payload: { idAtualizacao, valor }
    }
  }

  export const selecionarTudoFuncionalidadePerfil = (idBloco) => {

    return {
      type: SELECIONAR_TUDO_FUNCIONALIDADE_PERFIL,
      payload: idBloco
    };
  };

  export const selecionarTudoPortariaPerfil = () => {

    return {
      type: SELECIONAR_TUDO_PORTARIA_PERFIL,
      payload: null
    };
  };

  export const atualizaPermissaoFuncionalidade = (object, value) => {

    var nomeCheck = [object.target.id].toString();
    nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;
  
    var indexUnderline = nomeCheck.indexOf("_");
    var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
    var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
    var valor = value;
  
    return {
      type: ATUALIZAR_PERMISSAO_FUNCIONALIDADE,
      payload: { idAtualizacao, valor }
    }
  }  

  export const onChangeCheckPerfil = (object, value) => {

    var nomeCheck = [object.target.id].toString();
    nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

    return {
        type: ALTERACAO_CHECK_PERFIL,
        payload: { nomeCheck: [nomeCheck], valor: value }
    }   
}; 

  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };