import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SALVA_USUARIO_PORTAL, INICIA_USUARIO_PORTAL,
  BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL,
  BUSCA_USUARIO_PORTAL_EDICAO,
  REMOVE_USUARIO_PORTAL,
  LISTA_PERFIS_POR_CLIENTE,
  LISTA_USUARIO_PORTAL
} from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import { userSignOut } from "actions/Auth";
import {
  showAuthMessage,
  exibirMensagemErro,
  salvaUsuarioPortalSucesso,
  removeUsuarioPortalSucesso,
  inicioUsuarioPortalSucesso,
  iniciaUsuarioPortal,
  buscaUsuarioPortalEdicaoSucesso,
  listaPerfisSucesso,
  listaUsuarioPortalSucesso,
  abortaOperacao
} from "actions/UsuarioPortal";
import { limpaValidacoes } from "actions/Global";  
import md5 from "md5";
  

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/

const getUsuariosPortal = async () =>
  await requisicaoApi.get('/usuariosPortal')
    .then(resposta => resposta)
    .catch(error => error);

const getUsuarioPortalEdicao = async (id) =>
  await requisicaoApi.get('/usuariosPortal/' + id)
    .then(resposta => resposta)
    .catch(error => error);

const getUsuarioPorCpf = async (cpf) =>
  await requisicaoApi.get('/usuariosPortal/cpf/' + cpf)
    .then(resposta => resposta)
    .catch(error => error);

const getClientes = async () =>
  await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);

const getListaPerfisPorCliente = async (cliente) =>
  await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);

const getPerfisPortal = async (cliente) =>
  await requisicaoApi.get('/perfisPortal')
    .then(resposta => resposta)
    .catch(error => error);

const getListaUsuarioPortal = async (codigoFiltro, cpfFiltro, nomeFiltro) =>
  await requisicaoApi.get('/usuariosPortal?Codigo=' + codigoFiltro + '&Cpf=' + cpfFiltro + '&Nome=' + nomeFiltro)
    .then(resposta => resposta)
    .catch(error => error);

const getAmbientesPortaria = async () =>
  await requisicaoApi.get('/util/ambientesPortaria')
    .then(resposta => resposta)
    .catch(error => error);

const getBlocosFuncionalidade = async () =>
  await requisicaoApi.get('/util/blocosFuncionalidade')
    .then(resposta => resposta)
    .catch(error => error);

const getFuncionalidadesAcao = async () =>
  await requisicaoApi.get('/util/funcionalidadesAcao')
    .then(resposta => resposta)
    .catch(error => error);

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/

const adicionaUsuarioPortalRequest = async (usuarioPortal) => await requisicaoApi.post('/usuariosPortal',
  {
    "dataNascimentoStr": usuarioPortal.dataNascimentoStr,
    "tipoPessoa": "FISICA",
    "nomePessoa": usuarioPortal.nomePessoa,
    "numeroCpf": usuarioPortal.numeroCpf,
    "nomeMae": usuarioPortal.nomeMae,
    "sexo": usuarioPortal.sexo,
    "imagem": usuarioPortal.imagem,
    "estadoCivil": usuarioPortal.estadoCivil,
    "tipoUsuario": "PORTAL_UZERPASS",
    "login": usuarioPortal.login,
    "senha": usuarioPortal.senha,
    "senhaCript": usuarioPortal.senhaCript,
    "ativo": true,
    "id": usuarioPortal.id,
    "telefoneUm": usuarioPortal.telefoneUm,
    "telefoneDois": usuarioPortal.telefoneDois,
    "email": usuarioPortal.email,
    "site": usuarioPortal.site,
    "cep": usuarioPortal.cep,
    "logradouro": usuarioPortal.logradouro,
    "numero": usuarioPortal.numero,
    "complemento": usuarioPortal.complemento,
    "semNumero": usuarioPortal.semNumero,
    "bairro": usuarioPortal.bairro,
    "uf": usuarioPortal.uf,
    "nomeUf": usuarioPortal.nomeUf,
    "idMunicipio": usuarioPortal.idMunicipio,
    "codigoIbge": usuarioPortal.codigoIbge,
    "nomeMunicipio": usuarioPortal.nomeMunicipio,
    "idPerfilDoUsuario": usuarioPortal.idPerfilDoUsuario,
    "pontoReferencia": usuarioPortal.pontoReferencia,
    "perfisVinculados": usuarioPortal.listaDePerfisVinculados,
    "clientesVinculados": usuarioPortal.listaDeClientesVinculados,
    "telefones": usuarioPortal.telefones,
    "emails": usuarioPortal.emails,
    "configuracaoAcessoPortalUsuario": {
      "id": usuarioPortal.configuracaoAcessoPortalUsuario.id,
      "tipoConfiguracaoAcessoPortal": "USUARIO",
      "idUsuarioSistema": usuarioPortal.configuracaoAcessoPortalUsuario.idUsuarioSistema,
      "idPerfilSistema": usuarioPortal.configuracaoAcessoPortalUsuario.idPerfilSistema,
      "visualizaDadosPortaria": usuarioPortal.configuracaoAcessoPortalUsuario.visualizaDadosPortaria,
      "concedeAcessos": usuarioPortal.configuracaoAcessoPortalUsuario.concedeAcessos,
      "usuarioMantemGaragem": usuarioPortal.configuracaoAcessoPortalUsuario.usuarioMantemGaragem,
      "uzerAcessoPainelAdministrativo": usuarioPortal.configuracaoAcessoPortalUsuario.uzerAcessoPainelAdministrativo,
      "uzerAcessoAmbienteCliente": usuarioPortal.configuracaoAcessoPortalUsuario.uzerAcessoAmbienteCliente,
      "listaPontosAcessoPortaria": usuarioPortal.listaPontosAcessoPortaria,
      "listaConfiguracaoPortalFuncionalideAcao": usuarioPortal.listaConfiguracaoPortalFuncionalideAcao
    },
  })
  .then(resposta => resposta)
  .catch(error => error);

const atualizaUsuarioPortalRequest = async (usuarioPortal) => await requisicaoApi.put('/usuariosPortal',
  {
    "dataNascimentoStr": usuarioPortal.dataNascimentoStr,
    "tipoPessoa": "FISICA",
    "nomePessoa": usuarioPortal.nomePessoa,
    "numeroCpf": usuarioPortal.numeroCpf,
    "nomeMae": usuarioPortal.nomeMae,
    "sexo": usuarioPortal.sexo,
    "imagem": usuarioPortal.imagem,
    "estadoCivil": usuarioPortal.estadoCivil,
    "tipoUsuario": "PORTAL_UZERPASS",
    "login": usuarioPortal.login,
    "senha": usuarioPortal.senha,
    "senhaCript": usuarioPortal.senhaCript,
    "ativo": true,
    "id": usuarioPortal.id,
    "telefoneUm": usuarioPortal.telefoneUm,
    "telefoneDois": usuarioPortal.telefoneDois,
    "email": usuarioPortal.email,
    "site": usuarioPortal.site,
    "cep": usuarioPortal.cep,
    "logradouro": usuarioPortal.logradouro,
    "numero": usuarioPortal.numero,
    "complemento": usuarioPortal.complemento,
    "semNumero": usuarioPortal.semNumero,
    "bairro": usuarioPortal.bairro,
    "uf": usuarioPortal.uf,
    "idClienteFixado": usuarioPortal.idClienteFixado,
    "nomeUf": usuarioPortal.nomeUf,
    "idMunicipio": usuarioPortal.idMunicipio,
    "codigoIbge": usuarioPortal.codigoIbge,
    "nomeMunicipio": usuarioPortal.nomeMunicipio,
    "pontoReferencia": usuarioPortal.pontoReferencia,
    "perfisVinculados": usuarioPortal.listaDePerfisVinculados,
    "clientesVinculados": usuarioPortal.listaDeClientesVinculados,
    "idPerfilDoUsuario": usuarioPortal.idPerfilDoUsuario,
    "telefones": usuarioPortal.telefones,
    "emails": usuarioPortal.emails,
    "configuracaoAcessoPortalUsuario": {
      "id": usuarioPortal.configuracaoAcessoPortalUsuario.id,
      "idClienteUsuarioSistema": usuarioPortal.configuracaoAcessoPortalUsuario.idClienteUsuarioSistema,
      "tipoConfiguracaoAcessoPortal": "USUARIO",
      "idUsuarioSistema": usuarioPortal.configuracaoAcessoPortalUsuario.idUsuarioSistema,
      "idPerfilSistema": usuarioPortal.configuracaoAcessoPortalUsuario.idPerfilSistema,
      "visualizaDadosPortaria": usuarioPortal.configuracaoAcessoPortalUsuario.visualizaDadosPortaria,
      "concedeAcessos": usuarioPortal.configuracaoAcessoPortalUsuario.concedeAcessos,
      "usuarioMantemGaragem": usuarioPortal.configuracaoAcessoPortalUsuario.usuarioMantemGaragem,
      "uzerAcessoPainelAdministrativo": usuarioPortal.configuracaoAcessoPortalUsuario.uzerAcessoPainelAdministrativo,
      "uzerAcessoAmbienteCliente": usuarioPortal.configuracaoAcessoPortalUsuario.uzerAcessoAmbienteCliente,
      "listaPontosAcessoPortaria": usuarioPortal.listaPontosAcessoPortaria,
      "listaConfiguracaoPortalFuncionalideAcao": usuarioPortal.listaConfiguracaoPortalFuncionalideAcao
    },
  })
  .then(resposta => resposta)
  .catch(error => error);

const deleteUsuarioPortalRequest = async (id) =>
  await requisicaoApi.delete('/usuariosPortal?id=' + id)
    .then(resposta => resposta)
    .catch(error => error);

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/

function* fnIniciaUsuarioPortal() {
  try {
    const listagemGrid = yield call(getUsuariosPortal);

    if (!listagemGrid.isAxiosError && listagemGrid.data) {
      const clientes = yield call(getClientes);

      if (clientes.data) {
        const perfis = yield call(getPerfisPortal);

        if (perfis.data) {

          const ambientesPortaria = yield call(getAmbientesPortaria);

          if (ambientesPortaria.data) {

            const blocosFuncionalidade = yield call(getBlocosFuncionalidade);

            if (blocosFuncionalidade.data) {

              const funcionalidadesAcao = yield call(getFuncionalidadesAcao);

              if (funcionalidadesAcao.data) {
                yield put(inicioUsuarioPortalSucesso(listagemGrid.data, clientes.data, perfis.data, ambientesPortaria.data, blocosFuncionalidade.data, funcionalidadesAcao.data));
                yield put(limpaValidacoes());
              }
            }
          }
        }
      }
    }
    else {
      if (listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
        yield put(userSignOut());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao listar usuariosPortal'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnBuscaUsuarioPortalEdicao({ payload }) {
  try {
    const id = payload;
    const retorno = yield call(getUsuarioPortalEdicao, id);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(buscaUsuarioPortalEdicaoSucesso(retorno.data));
      yield put(limpaValidacoes());
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnBuscaPessoaBaseUzerUsuarioPortal({ payload }) {
  try {
    const cpf = payload;
    const usuario = yield call(getUsuarioPorCpf, cpf);

    if (!usuario.isAxiosError && usuario.data) {
      yield put(buscaUsuarioPortalEdicaoSucesso(usuario.data));
      yield put(limpaValidacoes());

    }
    else {
      if (usuario.status === 204) {
        yield put(abortaOperacao());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do usuario: ' + cpf));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaPerfisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaPerfisPorCliente, cliente);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(listaPerfisSucesso(retorno.data));
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de perfis do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaUsuarioPortal({ payload }) {
  try {
    const codigoFiltro = payload.codigo;
    const cpfFiltro = payload.cpf;
    const nomeFiltro = payload.nome;
    const retorno = yield call(getListaUsuarioPortal, codigoFiltro, cpfFiltro, nomeFiltro);

    if (!retorno.isAxiosError && retorno.data) {
      yield put(listaUsuarioPortalSucesso(retorno.data));
    }
    else {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de usuário portal'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/


function* fnSalvaUsuarioPortal({ payload }) {
  try {

    const usuarioPortal = payload;
    var resultado = "";
    var errosAgrupados = "";
    if (usuarioPortal.senhaCript === "" || usuarioPortal.senhaCript === null) {
      usuarioPortal.senhaCript = md5(usuarioPortal.senha);
    }
    if (usuarioPortal.id === 0) {
      usuarioPortal.senha = md5(usuarioPortal.senha);
      const retorno = yield call(adicionaUsuarioPortalRequest, usuarioPortal);
      resultado = retorno;
    }
    else {
      const retorno = yield call(atualizaUsuarioPortalRequest, usuarioPortal);
      resultado = retorno;
    }

    if(!resultado.isAxiosError) {
    if (resultado.data) {
      if (resultado.data.sucesso) {
        yield put(iniciaUsuarioPortal());
        yield put(salvaUsuarioPortalSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else {
      if (resultado.response.data) {
        if (resultado.response.data.inconsistencias.length > 0) {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);

          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }
  }
  else {
    yield put(exibirMensagemErro("Operação não realizada!. Ocorreu um erro interno."));
  }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveUsuarioPortal({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteUsuarioPortalRequest, id);

    if (!retorno.isAxiosError && retorno.data) {
      if (retorno.data.sucesso) {
        yield put(iniciaUsuarioPortal());
        yield put(removeUsuarioPortalSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else {
      if (retorno.response.data.mensagem) {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/

export function* iniciaAcoesUsuarioPortal() {
  yield takeEvery(INICIA_USUARIO_PORTAL, fnIniciaUsuarioPortal);
}

export function* buscaPessoaBaseUzerUsuarioPortal() {
  yield takeEvery(BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL, fnBuscaPessoaBaseUzerUsuarioPortal)
}

export function* salvaUsuarioPortal() {
  yield takeEvery(SALVA_USUARIO_PORTAL, fnSalvaUsuarioPortal);
}

export function* buscaUsuarioPortalEdicao() {
  yield takeEvery(BUSCA_USUARIO_PORTAL_EDICAO, fnBuscaUsuarioPortalEdicao);
}

export function* removeUsuarioPortal() {
  yield takeEvery(REMOVE_USUARIO_PORTAL, fnRemoveUsuarioPortal);
}

export function* listaPerfisPorCliente() {
  yield takeEvery(LISTA_PERFIS_POR_CLIENTE, fnListaPerfisPorCliente);
}

export function* listaUsuarioPortal() {
  yield takeEvery(LISTA_USUARIO_PORTAL, fnListaUsuarioPortal)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/

export default function* rootSaga() {
  yield all([fork(iniciaAcoesUsuarioPortal),
  fork(buscaPessoaBaseUzerUsuarioPortal),
  fork(salvaUsuarioPortal),
  fork(buscaUsuarioPortalEdicao),
  fork(removeUsuarioPortal),
  fork(listaPerfisPorCliente),
  fork(listaUsuarioPortal)]);
}