import {
    INICIA_ACESSO_PESSOA,
    SALVA_ACESSO_PESSOA,
    REMOVE_ACESSO_PESSOA,
    NOVO_ACESSO_PESSOA,
    BUSCA_ACESSO_PESSOA_EDICAO,
    INICIO_ACESSO_PESSOA_SUCESSO,
    LISTA_CONFIGURACAO_PONTO_PESSOA,
    LISTA_CONFIGURACAO_PONTO_PESSOA_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_ACESSO_PESSOA,
    LISTA_LOCAIS_SUCESSO_ACESSO_PESSOA,
    ALTERACAO_CHECK_PONTO_ACESSO_PESSOA,
    SALVA_ACESSO_PESSOA_SUCESSO,
    REMOVE_ACESSO_PESSOA_SUCESSO,
    BUSCA_ACESSO_PESSOA_EDICAO_SUCESSO,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    CONSULTA_CPF,
    CONSULTA_CPF_SUCESSO,
    LISTA_ACESSO_PESSOA,
    LISTA_ACESSO_PESSOA_SUCESSO,
    LIMPAR_ACESSO_PESSOA
  } from 'constants/ActionTypes';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaAcessoPessoa = () => {
    return {
      type: INICIA_ACESSO_PESSOA
    };
  };
  
  export const salvaAcessoPessoa = (acessoPessoa) => {
    var listaPontosAssociados = [];

    if(acessoPessoa.configuracaoPontoPessoa != null && acessoPessoa.configuracaoPontoPessoa.listaAmbientesVinculados != null)
    {
      for (var i = 0; i < acessoPessoa.configuracaoPontoPessoa.listaAmbientesVinculados.length; i++) {
        var listaPontos = acessoPessoa.configuracaoPontoPessoa.listaAmbientesVinculados[i].pontosAcessoVinculados;
  
        for (var j = 0; j < listaPontos.length; j++) {
          var idPontoAcesso = listaPontos[j].idPontoAcesso;
          listaPontosAssociados.push({"idPontoAcesso": listaPontos[j].idPontoAcesso, "associado": listaPontos[j].associado })
        }
      }
    }
   
    return {
      type: SALVA_ACESSO_PESSOA,
      payload: { acessoPessoa, listaPontosAssociados } 
    };
  };
  
  export const removeAcessoPessoa = (id) => {
    return {
      type: REMOVE_ACESSO_PESSOA,
      payload: id
    };
  };
  
  export const listaAcessoPessoa = (usuario, ambiente, ponto) => {
    if(usuario == undefined){
      usuario = "";
    }
  
    if(ambiente == undefined){
      ambiente = "";
    }
  
    if(ponto == undefined){
      ponto = "";
    }
  
    return {
      type: LISTA_ACESSO_PESSOA,
      payload: {usuario, ambiente, ponto}
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoAcessoPessoa = () => {
    return {
      type: NOVO_ACESSO_PESSOA
    };
  };
  
  export const buscaAcessoPessoaEdicao = (id) => {
    return {
      type: BUSCA_ACESSO_PESSOA_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  

  export const listaLocaisPorCliente = (name, object, value) => {
    var idCliente = value !== null ? value.id : 0;

    return {
      type: LISTA_LOCAIS_POR_CLIENTE_ACESSO_PESSOA,
      payload: { idCliente }
    }
  }

  export const consultaCpfSucesso = (correspondenciaCpf) => {
    return {
      type: CONSULTA_CPF_SUCESSO,
      payload: correspondenciaCpf
    }
  };  

  export const listaConfiguracaoPontosPorPessoa = (idLocal, idPessoa) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_PESSOA,
      payload: { idLocal, idPessoa }
    }
  }

  export const onChangeCheckPontoAcesso = (object, value) => {
    var nomeCheck = [object.target.id].toString();
    var idPtoAcesso = nomeCheck.replace(/pontoAcesso_/g, "");

    return {
        type: ALTERACAO_CHECK_PONTO_ACESSO_PESSOA,
        payload: { idPontoAcessoAlterado: idPtoAcesso, valor: value }
    }   
};   
  
  export const inicioAcessoPessoaSucesso = (acessosPessoa, clientes, locais) => {
    return {
      type: INICIO_ACESSO_PESSOA_SUCESSO,
      payload: { acessosPessoa, clientes, locais }
    }
  };
  
  export const salvaAcessoPessoaSucesso = (mensagem) => {
    return {
      type: SALVA_ACESSO_PESSOA_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removeAcessoPessoaSucesso = (mensagem) => {
    return {
      type: REMOVE_ACESSO_PESSOA_SUCESSO,
      payload: mensagem
    }
  };  

  export const listaConfiguracaoPontoPessoaSucesso = (configuracao) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_PESSOA_SUCESSO,
      payload: { configuracao }
    }
  }
  
  export const buscaAcessoPessoaEdicaoSucesso = (acessoPessoa, locais) => {
    return {
      type: BUSCA_ACESSO_PESSOA_EDICAO_SUCESSO,
      payload: {acessoPessoa, locais}
    };
  }; 

  export const listaLocaisSucesso = (locais) => {
    return {
      type: LISTA_LOCAIS_SUCESSO_ACESSO_PESSOA,
      payload: { locais }
    };
  };

  export const listaAcessoPessoaSucesso = (acessoPessoa) => {
    return {
      type: LISTA_ACESSO_PESSOA_SUCESSO,
      payload: { acessoPessoa }
    };
  };
  
  export const limparFiltroAcessoPessoa = () => {
    return {
      type: LIMPAR_ACESSO_PESSOA,
    };
  };
  
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

  export const consultaCPF = (cpf) => {
    let cpfAjustado = cpf.target.value;
    cpfAjustado = cpfAjustado.replace(/-/g, "");

    return {
      type: CONSULTA_CPF,
      payload: cpfAjustado
    };
  };
  
  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };