import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { LuxFwTextBox, LuxFwPasswordTextBox, LuxFwComboBox, LuxFwMaskTextBox } from 'luxFw/palettes/Standart';
import { Scrollbars } from 'react-custom-scrollbars';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import TextField from '@material-ui/core/TextField';


export default class CheckList extends Component {


    render() {

        const { classes } = this.props;

        return (
            <Fragment>

                <TableContainer elevation={0} className={classes.padding} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>

                            <Scrollbars
                                style={{ height: 300 }}>
                                <TableRow>
                                    <TableCell style={{ width: '70%', paddingLeft: 0, paddingTop: 0 }}>
                                            <TextField style={{width: '100%'}} id="filled-basic" label="Item" variant="filled" value="Canivet" />
                                    </TableCell>
                                    <TableCell style={{ width: '20%', paddingLeft: 0, paddingTop: 0 }}>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <RemoveCircleIcon style={{color: '#05B59D'}} />
                                            <TextField style={{width: '90%', marginLeft: 5, marginRight: 5, textAlign: 'center'}} id="filled-basic" label="Qtde" variant="filled" />
                                            <AddCircleIcon style={{color: '#05B59D'}}/>
                                        </div>

                                    </TableCell>
                                    <TableCell style={{ width: '10%',paddingLeft: 0, paddingTop: 0 }}>
                                        <Button style={{ width: 147, float: 'left', marginTop: 13, marginLeft: 8, marginRight: 8, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 90 }} variant="contained" >
                                            <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>INSERIR</span>
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{ width: '70%', paddingLeft: 0, paddingTop: 0 }}>
                                    <div style={{ paddingLeft: 5, float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Serrote</div>
                                    </TableCell>
                                    <TableCell style={{ width: '20%', paddingLeft: 0, paddingTop: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <RemoveCircleIcon style={{color: '#05B59D'}} />
                                            <TextField style={{width: '90%', marginLeft: 5, marginRight: 5, textAlign: 'center'}} id="filled-basic" label="Qtde" variant="filled" />
                                            <AddCircleIcon style={{color: '#05B59D'}}/>
                                        </div>

                                    </TableCell>
                                    <TableCell style={{ width: '10%',paddingLeft: 0, paddingTop: 10 }}>
                                        <Button variant="outlined" style={{ width: 147, float: 'right', marginRight: 8, padding: '14px 36px', borderRadius: 90 }} >
                                            <span style={{ fontStyle: 'normal', color: '#828282', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>REMOVER</span>
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{ width: '70%', paddingLeft: 0, paddingTop: 0 }}>
                                    <div style={{ paddingLeft: 5, float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Moto-serra</div>
                                    </TableCell>
                                    <TableCell style={{ width: '20%', paddingLeft: 0, paddingTop: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <RemoveCircleIcon style={{color: '#05B59D'}} />
                                            <TextField style={{width: '90%', marginLeft: 5, marginRight: 5, textAlign: 'center'}} id="filled-basic" label="Qtde" variant="filled" />
                                            <AddCircleIcon style={{color: '#05B59D'}}/>
                                        </div>

                                    </TableCell>
                                    <TableCell style={{ width: '10%',paddingLeft: 0, paddingTop: 10 }}>
                                    <Button variant="outlined" style={{ width: 147, float: 'right', marginRight: 8, padding: '14px 36px', borderRadius: 90 }} >
                                            <span style={{ fontStyle: 'normal', color: '#828282', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>REMOVER</span>
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{ width: '70%', paddingLeft: 0, paddingTop: 0 }}>
                                    <div style={{ paddingLeft: 5, float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#000000DE' }}>Carrinho de mão</div>
                                    </TableCell>
                                    <TableCell style={{ width: '20%', paddingLeft: 0, paddingTop: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <RemoveCircleIcon style={{color: '#05B59D'}} />
                                            <TextField style={{width: '90%', marginLeft: 5, marginRight: 5, textAlign: 'center'}} id="filled-basic" label="Qtde" variant="filled" />
                                            <AddCircleIcon style={{color: '#05B59D'}}/>
                                        </div>

                                    </TableCell>
                                    <TableCell style={{ width: '10%',paddingLeft: 0, paddingTop: 10 }}>
                                    <Button variant="outlined" style={{ width: 147, float: 'right', marginRight: 8, padding: '14px 36px', borderRadius: 90 }} >
                                            <span style={{ fontStyle: 'normal', color: '#828282', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px' }}>REMOVER</span>
                                        </Button>
                                    </TableCell>
                                </TableRow>

                            </Scrollbars>
                        </TableBody>
                    </Table>
                </TableContainer>

            </Fragment>
        )
    }

}