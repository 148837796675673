import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import requisicaoApiV2 from 'util/ApiV2';
import {showAuthMessage, userSignInSuccess, userSignOutSuccess } from "actions/Auth";
import md5 from 'md5';

const signInUserWithEmailPasswordRequest = async (login, senha) =>
  await requisicaoApi.post('/seguranca/auth', { "login": login, "senha": senha })
    .then(authUser => authUser)
    .catch(error => error);

function* signInUserWithEmailPassword({payload}) {
  const {login, senha} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, login, md5(senha));
    if (signInUser.message) {
      if (signInUser.response)
      {
        if(signInUser.response.status === 401)
        {
          yield put(showAuthMessage(JSON.stringify(signInUser.response.data.mensagem)));
        }
        else
        {
          yield put(showAuthMessage(JSON.stringify(signInUser.message)));
        }
      }
      else
      {
        yield put(showAuthMessage(JSON.stringify(signInUser.message)));
      }
      
    } else {
      const autorizacao = signInUser.data.configuracao.listaConfiguracaoPortalFuncionalideAcao
          .filter(x => x.funcionalidadeAcaoUsuarioPortal.funcionalidadePortal.codigoFuncionalidadePortal == "CADSIMPLIFPERFIL")
          .length === 1;

      const cad_simplif_insert = signInUser.data.configuracao.listaConfiguracaoPortalFuncionalideAcao
        .filter(x => x.funcionalidadeAcaoUsuarioPortal.funcionalidadePortal.codigoFuncionalidadePortal == "PESSCONTTEND")
        .filter(x => x.funcionalidadeAcaoUsuarioPortal.id == 2)
        .length === 1;

      const cad_simplif_remove = signInUser.data.configuracao.listaConfiguracaoPortalFuncionalideAcao
        .filter(x => x.funcionalidadeAcaoUsuarioPortal.funcionalidadePortal.codigoFuncionalidadePortal == "PESSCONTTEND")
        .filter(x => x.funcionalidadeAcaoUsuarioPortal.id == 4)
        .length === 1;
          
      localStorage.setItem("token", JSON.stringify(signInUser.data.accessToken));
      requisicaoApi.defaults.headers.common['authorization'] = "Bearer " + signInUser.data.accessToken;
      requisicaoApiV2.defaults.headers.common['authorization'] = "Bearer " + signInUser.data.accessToken;
      localStorage.setItem('user_id', signInUser.data.idUsuario);
      localStorage.setItem('user', signInUser.data.nomeExibUsuario)  
      localStorage.setItem('user_img', signInUser.data.pathImg) 
      localStorage.setItem('cfg', signInUser.data.configuracao)     
      localStorage.setItem('cad_simplif_perfil', autorizacao)
      localStorage.setItem('cad_simplif_insert', cad_simplif_insert)
      localStorage.setItem('cad_simplif_remove', cad_simplif_remove)
      yield put(userSignInSuccess(signInUser.data.idUsuario, signInUser.data.perfis, signInUser.data.configuracao));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
      localStorage.removeItem('user_id');
      localStorage.removeItem('token');
      localStorage.removeItem('user')  
      localStorage.removeItem('user_img')         
      localStorage.removeItem('cad_simplif_perfil')
      localStorage.removeItem('cad_simplif_insert')
      localStorage.removeItem('cad_simplif_remove')
      yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(signOutUser)]);
}