import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_MODELO, INICIA_MODELO, BUSCA_MODELO_EDICAO, REMOVE_MODELO, LISTA_MODELO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaModeloSucesso,
         removeModeloSucesso, 
         inicioModeloSucesso, 
         iniciaModelo,
         buscaModeloEdicaoSucesso,
         listaModeloSucesso } from "actions/Modelo";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getModelos = async () =>
    await requisicaoApi.get('/modelos')
    .then(resposta => resposta)
    .catch(error => error);

    const getFabricantes = async () =>
    await requisicaoApi.get('/fabricantes')
    .then(resposta => resposta)
    .catch(error => error);    

  const getModeloEdicao = async (id) =>
    await requisicaoApi.get('/modelos/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaModelo = async (nomeFiltro, fabricanteFiltro) => 
    await requisicaoApi.get('/modelos?Nome=' +  nomeFiltro + '&Fabricante=' + fabricanteFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaModeloRequest = async (modelo) => await requisicaoApi.post('/modelos', 
  {
    "id": modelo.id,
    "descricaoModelo": modelo.descricaoModelo,
    "idFabricante": modelo.idFabricante,
    "tipoComunicacao": modelo.tipoComunicacao
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaModeloRequest = async (modelo) => await requisicaoApi.put('/modelos', 
  {
    "id": modelo.id,
    "descricaoModelo": modelo.descricaoModelo,
    "idFabricante": modelo.idFabricante,
    "tipoComunicacao": modelo.tipoComunicacao    
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteModeloRequest = async (id) =>
  await requisicaoApi.delete('/modelos?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaModelo() {
    try {
      const listagemGrid = yield call(getModelos);
  
      if(listagemGrid.data)
      {
        const fabricantes = yield call(getFabricantes);

        if(fabricantes.data){
          yield put(inicioModeloSucesso(listagemGrid.data, fabricantes.data));
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar modelos'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaModeloEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getModeloEdicao, id);
  
      if(retorno.data)
      {
        yield put(buscaModeloEdicaoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do modelo: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaModelo({ payload }) {
    try {
      const nomeFiltro = payload.nome;
      const fabricanteFiltro = payload.fabricante;
      const retorno = yield call(getListaModelo, nomeFiltro, fabricanteFiltro);
  
      if(retorno.data)
      {
        yield put(listaModeloSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de modelos'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaModelo({ payload }) {
  try {

    const modelo = payload;
    var resultado = "";
    var errosAgrupados = "";

      if(modelo.id === 0) {
        const retorno = yield call(adicionaModeloRequest, modelo);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaModeloRequest, modelo);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaModelo());
        yield put(salvaModeloSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveModelo({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteModeloRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaModelo());
        yield put(removeModeloSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesModelo() {
  yield takeEvery(INICIA_MODELO, fnIniciaModelo);
}

export function* salvaModelo() {
  yield takeEvery(SALVA_MODELO, fnSalvaModelo);
}

export function* buscaModeloEdicao() {
  yield takeEvery(BUSCA_MODELO_EDICAO, fnBuscaModeloEdicao);
}

export function* removeModelo() {
  yield takeEvery(REMOVE_MODELO, fnRemoveModelo);
}

export function* listaModelo() {
  yield takeEvery(LISTA_MODELO, fnListaModelo)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesModelo), 
             fork(salvaModelo), 
             fork(buscaModeloEdicao),
             fork(removeModelo),
             fork(listaModelo)]);
}