import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import moment from 'moment';
import {
    INICIA_DEFINICOES,
    INICIO_DEFINICOES_SUCESSO,
    INICIA_RELATORIO_ACESSO,
    INICIO_RELATORIO_ACESSO_SUCESSO,
    INICIA_RELATORIO_PESSOAS_UNIDADE,
    INICIA_RELATORIO_PESSOAS_UNIDADE_SUCESSO,
    ALTERAR_CLIENTE_EM_USO,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_COMBO_INICIO,
    APLICA_FILTRO_CLIENTE_ALTERNAR,
    ALTERNA_CLIENTE,
    ALTERNA_CLIENTE_SUCESSO,
    FECHA_DRAWER_ALTERNACAO,
    GERA_RELATORIO_ACESSO,
    GERA_RELATORIO_ACESSO_SUCESSO,
    ALTERACAO_DATEPICKER,

  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    alternarCliente: false,
    carregandoRelatorio: false,
    habilitaGeracaoPDF: false,
    nomeClienteRelatorio: "",
    nomeAmbienteRelatorio: "",

    // Filtros relatorio de acesso
    dataInicio: moment(),
    dataFim: moment(),

    // Campos
    imgClienteFixado: null,

    // combos
    listaDeClientes: [],

    // listas Uteis
    listaDeClientesCompleta: [],
    listaResultadosPesquisaClientes: [],
    listaDeClientesDoUsuario: [],
    listaDeAmbientesPortariaCombo: [],
    listaDeUnidades: [],
    listagemRelatorioAcessos: [],
    listagemRelatorioPessoasUnidade: []
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_DEFINICOES: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true
        }
      }   
      case INICIO_DEFINICOES_SUCESSO: {

        const retorno = action.payload;
        var clienteFixo = retorno.clientes.filter(x => x.id === retorno.clienteFixado);

        if(clienteFixo.length > 0)
        {
          localStorage.setItem("idClienteFixado", clienteFixo[0].id);
          localStorage.setItem("nomeClienteFixado", clienteFixo[0].nomeRazaoSocial);
          localStorage.setItem("imgClienteFixado", clienteFixo[0].imagem);
        }

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          imgClienteFixado: retorno.clienteFixado === 0 ? null : clienteFixo[0].imagem,
          nomeClienteFixado: retorno.clienteFixado === 0 ? null : clienteFixo[0].nomeRazaoSocial,
          idClienteFixado: retorno.clienteFixado === 0 ? null : retorno.clienteFixado,
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
          listaDeClientesCompleta: retorno.clientes,
          listaDeClientesDoUsuario: retorno.clientesUsuario,
          listaResultadosPesquisaClientes: retorno.clientes     
        }
      }
      case INICIA_RELATORIO_ACESSO: {
        return {
          ...state,
          loader: true,
        }
      }
      case INICIA_RELATORIO_PESSOAS_UNIDADE: {
        return {
          ...state,
          loader: true,
        }
      }      
      case INICIO_RELATORIO_ACESSO_SUCESSO: {

        const retorno = action.payload;
        var listagemAmbPortaria = [];

        if(retorno !== null && retorno.length > 0){
  
          for (var indice in retorno) {
  
            var ambientePortaria = retorno[indice];
            var nomeAmbientePortaria = ambientePortaria.descricaoAmbiente;
  
            listagemAmbPortaria.push({id: ambientePortaria.id, title: nomeAmbientePortaria })
          }
  
        }

        return {
          ...state,
          loader: false,
          dataInicio: moment(),
          dataFim: moment(),
          listagemRelatorioAcessos: [],
          idAmbiente: listagemAmbPortaria.length > 0 ? listagemAmbPortaria[0].id : state.idAmbiente,
          listaDeAmbientesPortariaCombo: listagemAmbPortaria,
        }
      }
      case INICIA_RELATORIO_PESSOAS_UNIDADE: {
        return {
          ...state,
          loader: true,
        }
      }      
      case INICIA_RELATORIO_PESSOAS_UNIDADE_SUCESSO: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          dataInicio: moment(),
          dataFim: moment(),
          listagemRelatorioPessoasUnidade: [],
          listaDeUnidades: retorno,
        }
      }      
      case ALTERAR_CLIENTE_EM_USO: {

        localStorage.removeItem('idClienteFixado');
        localStorage.removeItem('nomeClienteFixado');
        localStorage.removeItem("imgClienteFixado");

        return {
          ...state,
          idClienteFixado: null,// 48,
          nomeClienteFixado: null,
          imgClienteFixado: null
        }        
      }
      case APLICA_FILTRO_CLIENTE_ALTERNAR: {

        const filtroAplicar = action.payload;
        var listaFiltrada = filtroAplicar.length >= 0 ? state.listaDeClientesCompleta.filter(x => x.nomeRazaoSocial.toLowerCase().includes(filtroAplicar.toLowerCase())) : [];
  
        return {
          ...state,
          textoFiltroClienteAlternar: filtroAplicar,
          listaResultadosPesquisaClientes: filtroAplicar.length >= 0 ? listaFiltrada : state.listaResultadosPesquisaClientes
        }    

      }                     
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case ALTERNA_CLIENTE: {
        return {
          ...state,
          alternarCliente: true
        }
      }    
      case GERA_RELATORIO_ACESSO: {

        const retorno = action.payload;

        return {
          ...state,
          carregandoRelatorio: true
        }
      }      
      case GERA_RELATORIO_ACESSO_SUCESSO: {

        const retorno = action.payload;

        return {
          ...state,
          listagemRelatorioAcessos: retorno.listagem,
          carregandoRelatorio: false,
          habilitaGeracaoPDF: retorno.listagem.length > 0,
          nomeClienteRelatorio: retorno.nomeCliente,
          nomeAmbienteRelatorio: retorno.nomeAmbiente
        }
      }
      case ALTERNA_CLIENTE_SUCESSO: {
        return {
          ...state,
          alternarCliente: false
        }
      }
      case FECHA_DRAWER_ALTERNACAO: {
        return {
          ...state,
          alternarCliente: false
        }
      }          
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_COMBO_INICIO: {

        const cliente = state.listaDeClientes.filter(x => x.id === action.payload.valor);
        const clienteImagem = state.listaDeClientesCompleta.filter(x => x.id === action.payload.valor);
        const nome = cliente[0].title;
        const imagem = clienteImagem[0].imagem;

        localStorage.setItem("idClienteFixado", JSON.stringify(action.payload.valor));
        localStorage.setItem("nomeClienteFixado", nome);
        localStorage.setItem("imgClienteFixado", imagem);

        return {
          ...state,
          /////redirecionaInicio: true,
          nomeClienteFixado: nome,
          imgClienteFixado: imagem,
          idClienteFixado: action.payload.valor,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      default:
        return state;
    }
  }
  