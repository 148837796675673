import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { caracteristicasPerfis } from '../app/constantes/Listas';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_PERFIL,
    NOVO_PERFIL,
    BUSCA_PERFIL_EDICAO,
    CANCELA_EDICAO,
    INICIO_PERFIL_SUCESSO,
    SALVA_PERFIL_SUCESSO,
    REMOVE_PERFIL_SUCESSO,
    BUSCA_PERFIL_EDICAO_SUCESSO,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    LIMPAR_PERFIL,
    LISTA_PERFIL_SUCESSO,
    LISTA_PERFIL,
    APLICA_FILTRO,
    EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL,    
    EXIBE_ESTRUTURA_LOCAL_PERFIL,
    EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL_SUCESSO,
    ATUALIZA_AMBIENTE_ORIGEM
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo Perfil', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true }, 
      { titulo: 'Perfil de Acesso', propriedadeApi: 'descricaoPerfil', alinhamento: 'left' },  
      { titulo: 'Característica', propriedadeApi: 'caracteristicaPerfilGrid', alinhamento: 'left'  }, 
      { titulo: 'Dupla checagem?', propriedadeApi: 'ehDuploCheck', alinhamento: 'left', booleano: true  }, 
      { titulo: 'Ativo?', propriedadeApi: 'ativo', alinhamento: 'left', booleano: true  }, 
    ],    

    listaTeste: [

      {
        idAmbiente: 138,
        idLocal: 46,
        situacaoOrigem: 'P'
      },
      {
        idAmbiente: 140,
        idLocal: 46,
        situacaoOrigem: 'B'
      },
      {
        idAmbiente: 141,
        idLocal: 46,
        situacaoOrigem: 'B'
      },
      {
        idAmbiente: 142,
        idLocal: 46,
        situacaoOrigem: 'B'
      },                        
    ],

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],
    listGridComFiltro: [],
    caracteristicaPerfil: '',
    ehDuploCheck: false,
    exibeEstruturaLocalPerfil: false,
    exibeEmpresaCargoPortaria: false,
    textoFiltro: '',

    // Campos do Formulario

    id: 0,
    idCliente: 0,
    descricaoPerfil: '',
    ativo: true,
    nomeClienteFixado: null,
    idClienteFixado: 0,
    localControladoEstrutura: 0,
    
    // Combos
    listaDeClientes: [],
    listaDeLocaisControlados: [],
    listaDeCaracteristicas: [],

    // Filtros
    nomeFiltro: '', 

    // Outros
    exibeEskeleton: false,
    listaIdsTreeviewExpandidos: [],
    idLocalExibidoEstrutura: 0,
    arvoreLocal: [],
    listaAmbientesOrigem: []
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_PERFIL: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          textoFiltro: '',
        }
      }   
      case NOVO_PERFIL: {
        return {
          ...state,
          edicao: true,
          id: 0,
          idCliente: state.idClienteFixado !== 0 ? parseInt(state.idClienteFixado) : 0,
          descricaoPerfil: '',
          ativo: true,
          tipoPerfil: '',  
          caracteristicaPerfil: '',
          ehDuploCheck: true,
          exibeEmpresaCargoPortaria: false
        }
      }       
      case BUSCA_PERFIL_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false,
          textoFiltro: '',
        }
      }
      case APLICA_FILTRO: {

        const filtroAplicar = action.payload;
        var listaFiltrada = filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.descricaoPerfil.toLowerCase().includes(filtroAplicar.toLowerCase())) : [];
  
        return {
          ...state,
          textoFiltro: filtroAplicar,
          listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
        }        
      }    
      case INICIO_PERFIL_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.perfis,
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"), 
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),   
          listaDeLocaisControlados: retorno.locais,
          listaDeCaracteristicas: preparaListaParaCombo(caracteristicasPerfis, "id", "title"),
        }
      }                     
      case SALVA_PERFIL_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_PERFIL_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_PERFIL: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_PERFIL_SUCESSO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          listGrid: retorno.perfil,
        }
      }
      case LIMPAR_PERFIL: {
  
        var retorno = action.payload;
  
        return {
          ...state,
          nomeFiltro: '', 
        }
      }                     
      case BUSCA_PERFIL_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.id,
          idCliente: retorno.idCliente,
          descricaoPerfil: retorno.descricaoPerfil,
          observacao: retorno.observacao,
          ehDuploCheck: retorno.ehDuploCheck,
          exibeEmpresaCargoPortaria: retorno.exibeEmpresaCargoPortaria,
          ativo: retorno.ativo,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),
          caracteristicaPerfil: retorno.caracteristicaPerfil,          
          listaAmbientesOrigem: retorno.listaPerfisAmbientesOrigem,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,             
        }
      }       
      case EXIBE_ESTRUTURA_LOCAL_PERFIL: {
        return {
          ...state,
          exibeEstruturaLocalPerfil: !state.exibeEstruturaLocalPerfil
        }
      }
      case EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL: {
        return {
          ...state,
          exibeEskeleton: true,
        }
      }
      case ATUALIZA_AMBIENTE_ORIGEM: {

        var retorno = action.payload;
        var listaProcessada = [];
        var listaDoLocal = [];

        if (retorno.acao === 'BloquearOrigem')
        {
          listaProcessada = [...state.listaAmbientesOrigem, { id: 0, idAmbiente: retorno.idAmbiente, ambiente:{ idLocalControlado: retorno.idLocal }, situacaoOrigem: 'BLOQUEADA'}]
        }

        if (retorno.acao === 'DefinirOrigem')
        {
          listaDoLocal = state.listaAmbientesOrigem.filter(x => x.ambiente.idLocalControlado === retorno.idLocal && x.situacaoOrigem !== 'PADRAO');
          listaDoLocal = [...listaDoLocal, { id: 0, idAmbiente: retorno.idAmbiente, ambiente:{ idLocalControlado: retorno.idLocal }, situacaoOrigem: 'PADRAO'}];
          listaProcessada = state.listaAmbientesOrigem.filter(x => x.ambiente.idLocalControlado !== retorno.idLocal);

          for(var indice in listaDoLocal) {
            var ambienteOrigem = listaDoLocal[indice];
            listaProcessada = [...listaProcessada, ambienteOrigem]
          }
        }

        if (retorno.acao === 'RemoverBloqueio')
        {
          listaProcessada = state.listaAmbientesOrigem.filter(x => x.idAmbiente !== retorno.idAmbiente);
        }

        return {
          ...state,
          listaAmbientesOrigem: listaProcessada
        }
      }
      case EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL_SUCESSO: {
  
        var retorno = action.payload;
        var localEstrutura = state.listaDeLocaisControlados.filter(x => x.id === retorno.local)[0].descricaoLocalControlado;
        var idsExpandidos = [];
        var ambiente = {};
  
        function RecolheIdsAmbienteSubAmbiente(ambientes) {
  
          for (var indice in ambientes) {
            ambiente = ambientes[indice];
/*             ambiente.usuarioPodeLiberar = state.listaAmbientesPessoa.filter(x => x.idAmbiente === ambiente.id).length > 0; */
  
            if (ambiente.subAmbientes.length > 0) {
              idsExpandidos.push(ambiente.idTemporario);
              RecolheIdsAmbienteSubAmbiente(ambiente.subAmbientes);
            }
            else {
              idsExpandidos.push(ambiente.idTemporario);
            }
          }
  
        }
  
        RecolheIdsAmbienteSubAmbiente(retorno.arvoreLocal);
  
        return {
          ...state,
          arvoreLocal: retorno.arvoreLocal,
          localControladoEstrutura: localEstrutura,
          idLocalExibidoEstrutura: retorno.local,
          exibeEskeleton: false,
          listaIdsTreeviewExpandidos: idsExpandidos,
        }
      }      
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  