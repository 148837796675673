import {
  INICIA_USUARIO_PORTAL,
  SALVA_USUARIO_PORTAL,
  REMOVE_USUARIO_PORTAL,
  NOVO_USUARIO_PORTAL,
  CONSULTA_CEP,
  BUSCA_USUARIO_PORTAL_EDICAO,
  INICIO_USUARIO_PORTAL_SUCESSO,
  SALVA_USUARIO_PORTAL_SUCESSO,
  REMOVE_USUARIO_PORTAL_SUCESSO,
  BUSCA_USUARIO_PORTAL_EDICAO_SUCESSO,
  CONSULTA_CEP_SUCESSO,
  CONSULTA_CEP_ERRO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  NOVO_TELEFONE_USUARIO_PORTAL,
  ATUALIZA_TELEFONE_USUARIO_PORTAL,
  REMOVE_TELEFONE_USUARIO_PORTAL,
  NOVO_EMAIL_USUARIO_PORTAL,
  ATUALIZA_EMAIL_USUARIO_PORTAL,
  REMOVE_EMAIL_USUARIO_PORTAL,
  LISTA_PERFIS_POR_CLIENTE,
  LISTA_PERFIS_SUCESSO,
  LISTA_USUARIO_PORTAL,
  LISTA_USUARIO_PORTAL_SUCESSO,
  LIMPAR_USUARIO_PORTAL,
  ADICIONA_CLIENTE_LISTA_VINCULADA,
  REMOVE_CLIENTE_LISTA_VINCULADA,
  ADICIONA_PERFIL_LISTA_VINCULADA,
  REMOVE_PERFIL_LISTA_VINCULADA,
  SETAR_IMAGEM_USUARO,
  IR_PARA_CADASTRO_PERFIL,
  ATUALIZAR_PONTO_REQUISICAO_USUARIO,
  ATUALIZAR_PERMISSAO_FUNCIONALIDADE_USUARIO,
  EXIBE_PONTOS_REQUISICAO,
  BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL,
  ABORTA_OPERACAO,
  SELECIONAR_TUDO_FUNCIONALIDADE,
  SELECIONAR_TUDO_PORTARIA,
  CLIQUE_CONTROLE_ABA_USUARIO_PORTAL,
  ALTERACAO_CHECK,
  ALTERACAO_CHECK_USUARIO,
  VALIDACAO_CAMPO,
  LIMPA_VALIDACOES
} from 'constants/ActionTypes';
import { ValidaCampoObrigatorio, ValidaCampoObrigatorioCombo, LimpaValidacaoEspecifica } from 'luxFw/transformations/Validations';
import { limpaValidacoes } from "actions/Global";
import moment from 'moment';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaUsuarioPortal = () => {
  return {
    type: INICIA_USUARIO_PORTAL
  };
};

export const salvaUsuarioPortal = (equipamento) => {
  return {
    type: SALVA_USUARIO_PORTAL,
    payload: equipamento
  };
};

export const atualizaPontoRequisicaUsuario = (object, value) => {

  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZAR_PONTO_REQUISICAO_USUARIO,
    payload: { idAtualizacao, valor }
  }
}

export const atualizaPermissaoUsuario = (object, value) => {
  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZAR_PERMISSAO_FUNCIONALIDADE_USUARIO,
    payload: { idAtualizacao, valor }
  }
};

export const removeUsuarioPortal = (id) => {
  return {
    type: REMOVE_USUARIO_PORTAL,
    payload: id
  };
};

export const selecionarTudoFuncionalidade = (idBloco) => {

  return {
    type: SELECIONAR_TUDO_FUNCIONALIDADE,
    payload: idBloco
  };
};

export const selecionarTudoPortaria = () => {

  return {
    type: SELECIONAR_TUDO_PORTARIA,
    payload: null
  };
};

export const listaUsuarioPortal = (codigo, cpf, nome) => {
  if (codigo == undefined) {
    codigo = "";
  }

  if (cpf == undefined) {
    cpf = "";
  }

  if (nome == undefined) {
    nome = "";
  }

  return {
    type: LISTA_USUARIO_PORTAL,
    payload: { codigo, cpf, nome }
  };
};

export const adicionaClienteListaVinculada = (idClienteAdd) => {

  return {
    type: ADICIONA_CLIENTE_LISTA_VINCULADA,
    payload: { idClienteAdd }
  };
};

export const removeClienteListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_CLIENTE_LISTA_VINCULADA,
    payload: { idTemporario }
  };
};

export const adicionaPerfilListaVinculada = (idPerfilAdd) => {

  return {
    type: ADICIONA_PERFIL_LISTA_VINCULADA,
    payload: { idPerfilAdd }
  };
};

export const removePerfilListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_PERFIL_LISTA_VINCULADA,
    payload: { idTemporario }
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const listaPerfisSucesso = (perfis) => {
  return {
    type: LISTA_PERFIS_SUCESSO,
    payload: { perfis }
  };
};

export const listaUsuarioPortalSucesso = (usuariosPortal) => {
  return {
    type: LISTA_USUARIO_PORTAL_SUCESSO,
    payload: { usuariosPortal }
  };
};

export const limparFiltroUsuarioPortal = () => {
  return {
    type: LIMPAR_USUARIO_PORTAL,
  };
};

export const irParaCadastroPerfis = () => {
  return {
    type: IR_PARA_CADASTRO_PERFIL,
    payload: null
  };
}

/*#############################################################################################*/
/* ------------------------ Actions Complementares da Funcionalidade --------------------------*/

export const setarImagemUsuario = (imagem) => {

  return {
    type: SETAR_IMAGEM_USUARO,
    payload: { imagem }
  };
}

export const listaPerfisPorCliente = (name, object, value) => {
  var idCliente = value !== null ? value.id : 0;

  return {
    type: LISTA_PERFIS_POR_CLIENTE,
    payload: { idCliente }
  }
}

export const consultaCEP = (cep) => {

  let cepAjustado = cep.target.value;

  if (cepAjustado !== undefined && cepAjustado !== null && cepAjustado !== '') {
    cepAjustado = cepAjustado.replace(/-/g, "");

    return {
      type: CONSULTA_CEP,
      payload: cepAjustado
    };
  }
  else {
    return {
      type: ABORTA_OPERACAO,
      payload: null
    };
  }
};

export const abortaOperacao = () => {
  return {
    type: ABORTA_OPERACAO,
    payload: null
  };
};

export const consultaCadastroExistenteUsuarioPortal = (usuarioPortal) => {

  var validacoes = [];

  if (isValidCPF(usuarioPortal.numeroCpf)) {
    let cpfAjustado = usuarioPortal.numeroCpf;

    if (cpfAjustado !== undefined && cpfAjustado !== null && cpfAjustado !== '') {
      cpfAjustado = cpfAjustado.replace(/[^0-9]/g, "");
  
      let usuariosRetornados = usuarioPortal.listGrid.filter(x => x.numeroCpf.replace(/[^0-9]/g, "") === cpfAjustado);
  
      if (usuariosRetornados.length > 0) {
        return {
          type: BUSCA_USUARIO_PORTAL_EDICAO,
          payload: usuariosRetornados[0].id
        };
      }
      else {
        return {
          type: BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL,
          payload: cpfAjustado
        };
      }
    }
    else {
      return {
        type: ABORTA_OPERACAO,
        payload: null
      };
    }
  }
  else 
  {
    validacoes.push(validaCPFCampo('numeroCpf', usuarioPortal.numeroCpf));
  }

  return validacoes;
};

export const exibirPontosRequisicaoUsuario = (idAmbienteSelecionado) => {
  return {
    type: EXIBE_PONTOS_REQUISICAO,
    payload: idAmbienteSelecionado
  };
};

export const onChangeCheckUsuario = (object, value) => {

  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  return {
      type: ALTERACAO_CHECK_USUARIO,
      payload: { nomeCheck: [nomeCheck], valor: value }
  }   
}; 

/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoUsuarioPortal = () => {
  return [{
    type: LIMPA_VALIDACOES,
  },
  {
    type: NOVO_USUARIO_PORTAL
  }]    
};

export const buscaUsuarioPortalEdicao = (id) => {
  return {
    type: BUSCA_USUARIO_PORTAL_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/



export const inicioUsuarioPortalSucesso = (usuariosPortal, clientes, perfis, ambientesPortaria, blocosFuncionalidade, funcionalidadesAcao) => {
  return {
    type: INICIO_USUARIO_PORTAL_SUCESSO,
    payload: { usuariosPortal, clientes, perfis, ambientesPortaria, blocosFuncionalidade, funcionalidadesAcao }
  }
};

export const salvaUsuarioPortalSucesso = (mensagem) => {
  return {
    type: SALVA_USUARIO_PORTAL_SUCESSO,
    payload: mensagem
  }
};

export const removeUsuarioPortalSucesso = (mensagem) => {
  return {
    type: REMOVE_USUARIO_PORTAL_SUCESSO,
    payload: mensagem
  }
};

export const buscaUsuarioPortalEdicaoSucesso = (usuarioPortal) => {

  return {
    type: BUSCA_USUARIO_PORTAL_EDICAO_SUCESSO,
    payload: usuarioPortal
  };
};

export const consultaCepSucesso = (correspondenciaCep) => {
  return {
    type: CONSULTA_CEP_SUCESSO,
    payload: correspondenciaCep
  }
};

export const consultaCepError = (erro) => {
  return {
    type: CONSULTA_CEP_ERRO,
    payload: erro
  }
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const onChangeAba = (indiceAbaDestino, usuarioPortal) => {

  var validacoes = [];
  var indiceAbaOrigem = usuarioPortal.indiceAba;
  var irParaAbaEndereco = indiceAbaDestino === 1;
  var irParaAbaContato = indiceAbaDestino === 2;
  var irParaAbaPermissoes = indiceAbaDestino === 3;

  if (indiceAbaDestino > indiceAbaOrigem) {
    if (irParaAbaEndereco) {
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPortal));
      validacoes.push.apply(validacoes, LimpaValidacoesAbaEndereco());
    }

    if (irParaAbaContato) {
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPortal));
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(usuarioPortal));
      validacoes.push.apply(validacoes, LimpaValidacoesAbaContato(usuarioPortal))
    }

    if (irParaAbaPermissoes) {
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPortal));
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(usuarioPortal));
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosContato(usuarioPortal));
    }
  }

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    var irParaAba = {
      type: CLIQUE_CONTROLE_ABA_USUARIO_PORTAL,
      payload: { indice: indiceAbaDestino }
    }

    validacoes.push(irParaAba);
  }
  else {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);
  }

  return validacoes;
};


export const novoTelefone = (exclusivo) => {
  return {
    type: NOVO_TELEFONE_USUARIO_PORTAL,
    payload: exclusivo
  };
};

export const atualizaTelefone = (e) => {

  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_TELEFONE_USUARIO_PORTAL,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const removeTelefone = (indice) => {
  return {
    type: REMOVE_TELEFONE_USUARIO_PORTAL,
    payload: indice
  };
};

export const novoEmail = (exclusivo) => {
  return {
    type: NOVO_EMAIL_USUARIO_PORTAL,
    payload: exclusivo
  };
};

export const atualizaEmail = (e) => {
  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_EMAIL_USUARIO_PORTAL,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const removeEmail = (indice) => {
  return {
    type: REMOVE_EMAIL_USUARIO_PORTAL,
    payload: indice
  };
};

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

function RetornosArrayValidacoesDadosPessoais(usuarioPortal) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('numeroCpf', usuarioPortal.numeroCpf));
  validacoes.push(validaCPFCampo('numeroCpf', usuarioPortal.numeroCpf));
  validacoes.push(ValidaCampoObrigatorio('nomePessoa', usuarioPortal.nomePessoa));
  validacoes.push(ValidaCampoObrigatorio('dataNascimentoStr', usuarioPortal.dataNascimentoStr));
  validacoes.push(validaDataCampo('dataNascimentoStr', usuarioPortal.dataNascimentoStr));
  validacoes.push(ValidaCampoObrigatorio('nomeMae', usuarioPortal.nomeMae));
  validacoes.push(ValidaCampoObrigatorioCombo('login', usuarioPortal.login));
  validacoes.push(ValidaCampoObrigatorioCombo('senha', usuarioPortal.senha));

  return validacoes;
}

function RetornosArrayValidacoesDadosEndereco(usuarioPlataforma) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('cep', usuarioPlataforma.cep));
  !usuarioPlataforma.semNumero && validacoes.push(ValidaCampoObrigatorio('numero', usuarioPlataforma.numero));

  return validacoes;
}

function LimpaValidacoesAbaEndereco() {

  var validacoes = [];

  validacoes.push(LimpaValidacaoEspecifica('cep'));
  validacoes.push(LimpaValidacaoEspecifica('numero'));

  return validacoes;
}

function RetornosArrayValidacoesDadosContato(usuarioPlataforma) {

  var validacoes = [];
  var contador = 0;

  if (usuarioPlataforma.telefones.filter(x => x.exclusivoDoCliente === false).length > 0) {
    usuarioPlataforma.telefones.filter(x => x.exclusivoDoCliente === false).map(n => {

      validacoes.push(ValidaCampoObrigatorio(`numeroTelefone_${n.idTemporario}`, n.numeroTelefone))
      validacoes.push(ValidaCampoObrigatorio(`marcador_${n.idTemporario}`, n.marcador))
    });
  }

  if (usuarioPlataforma.emails.filter(x => x.exclusivoDoCliente === false).length > 1) {
    usuarioPlataforma.emails.filter(x => x.exclusivoDoCliente === false).map(n => {
      contador++;

      if (contador > 1) {
        validacoes.push(ValidaCampoObrigatorio(`email_${n.idTemporario}`, n.email))
        validacoes.push(validaEmailCampo(`email_${n.idTemporario}`, n.email))
        validacoes.push(ValidaCampoObrigatorio(`marcador_${n.idTemporario}`, n.marcador))
      }
    });
  }
  else
  {
    usuarioPlataforma.emails.map(n => {
        contador++;

          if(contador === 1 && n.email !== '') {
            validacoes.push(validaEmailCampo(`email_${n.idTemporario}`, n.email))
          }
      });
  }  

  return validacoes;
}

export const validaData = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaDataCampo(campo, valor));

  return validacoes;
};

export const validaDataCampo = (campo, valor) => {
  var mensagem = "";
  var dataValida = false;
  
  if (valor !== undefined && valor !== null && valor !== ""){
    dataValida = isValidDate(valor);
  }

  if (!dataValida) {
    mensagem = "Data inválida";
  }
  else {
    mensagem = "";
  }

  return {
      type: VALIDACAO_CAMPO,
      payload: { erro: !dataValida, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

function isValidDate(str) {
  var d = moment(str,'D/M/YYYY');
  if(d == null || !d.isValid()) return false;

  return str.indexOf(d.format('D/M/YYYY')) >= 0 
      || str.indexOf(d.format('DD/MM/YYYY')) >= 0
      || str.indexOf(d.format('D/M/YY')) >= 0 
      || str.indexOf(d.format('DD/MM/YY')) >= 0;
}

function LimpaValidacoesAbaContato(usuarioPlataforma) {

  var validacoes = [];

  usuarioPlataforma.telefones.map(n => {
    validacoes.push(LimpaValidacaoEspecifica(`numeroTelefone_${n.idTemporario}`))
    validacoes.push(LimpaValidacaoEspecifica(`marcador_${n.idTemporario}`))
  });

  usuarioPlataforma.emails.map(n => {
    validacoes.push(LimpaValidacaoEspecifica(`email_${n.idTemporario}`))
    validacoes.push(LimpaValidacaoEspecifica(`marcador_${n.idTemporario}`))
  });

  return validacoes;
}

export const validaEmailCampo = (campo, valor) => {
  var mensagem = "";
  var emailValido = false;
  
  if (valor !== undefined && valor !== null && valor !== ""){
    emailValido = ExecutaValidacaoEmail(valor);
  }

  if (valor != '' && !emailValido) {
    mensagem = "Email inválido";
  }
  else {
    emailValido = true;
    mensagem = "";
  }

  return {
      type: VALIDACAO_CAMPO,
      payload: { erro: !emailValido, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

export const validaEmail = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaEmailCampo(campo, valor));

  return validacoes;
};

function ExecutaValidacaoEmail(email) {
  var re = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
  return re.test(email);
}

export const validaCPF = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaCPFCampo(campo, valor));

  return validacoes;
};

export const validaCPFCampo = (campo, valor) => {
  var mensagem = "";
  var cpfValido = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    cpfValido = isValidCPF(valor);
  }

  if (!cpfValido) {
    mensagem = "CPF inválido";
  }
  else {
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !cpfValido, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

function isValidCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito	
  var add = 0;
  var rev = 0;

  for (var i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (var i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
}