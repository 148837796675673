import {
  INICIA_DEFINICOES,
  INICIO_DEFINICOES_SUCESSO,
  INICIA_RELATORIO_ACESSO,
  INICIO_RELATORIO_ACESSO_SUCESSO,
  GERA_RELATORIO_ACESSO,
  GERA_RELATORIO_ACESSO_SUCESSO,
  SALVAR_CLIENTE_EM_USO,
  ALTERAR_CLIENTE_EM_USO,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  ON_SHOW_LOADER,
  ALTERACAO_COMBO_INICIO,
  APLICA_FILTRO_CLIENTE_ALTERNAR,
  ALTERNA_CLIENTE,
  ALTERNA_CLIENTE_SUCESSO,
  IR_PARA_MODULO,
  FECHA_DRAWER_ALTERNACAO,
  INICIA_RELATORIO_PESSOAS_UNIDADE,
  INICIA_RELATORIO_PESSOAS_UNIDADE_SUCESSO,
  GERA_RELATORIO_PESSOAS_UNIDADE,
  GERA_RELATORIO_PESSOAS_UNIDADEO_SUCESSO
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais ---- -----------------------------------*/

export const iniciaDefinicoes = () => {

  return {
    type: INICIA_DEFINICOES
  };

};

export const iniciaRelatorioAcesso = () => {
  return {
    type: INICIA_RELATORIO_ACESSO
  };
};

export const iniciaRelatorioPessoasUnidade = () => {
  return {
    type: INICIA_RELATORIO_PESSOAS_UNIDADE
  };
};

export const geraRelatorio = (idAmbiente, dataInicio, dataFim) => {
  return {
    type: GERA_RELATORIO_ACESSO,
    payload: { idAmbiente, dataInicio, dataFim }
  };
}

export const geraRelatorioPessoasUnidade = (idAmbiente, dataInicio, dataFim) => {
  return {
    type: GERA_RELATORIO_PESSOAS_UNIDADE,
    payload: { idAmbiente, dataInicio, dataFim }
  };
}

export const inicioRelatorioAcessoSucesso = (ambientesPortaria) => {
  return {
    type: INICIO_RELATORIO_ACESSO_SUCESSO,
    payload: ambientesPortaria
  };
}

export const inicioRelatorioPessoasUnidadeSucesso = (unidades) => {
  return {
    type: INICIA_RELATORIO_PESSOAS_UNIDADE_SUCESSO,
    payload: unidades
  };
}


export const geraRelatorioAcessoSucesso = (resultado) => {
  return {
    type: GERA_RELATORIO_ACESSO_SUCESSO,
    payload: resultado
  };
}

export const geraRelatorioPessoasUnidadeSucesso = (resultado) => {
  return {
    type: GERA_RELATORIO_PESSOAS_UNIDADEO_SUCESSO,
    payload: resultado
  };
}

export const salvaClienteEmUso = (cliente) => {

  return {
    type: SALVAR_CLIENTE_EM_USO,
    payload: cliente
  };
};

export const alteraClienteEmUso = () => {
  return {
    type: ALTERAR_CLIENTE_EM_USO,
    payload: null
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const alternaCliente = () => {
  return {
    type: ALTERNA_CLIENTE,
    payload: null
  };
};

export const alternaClienteSucesso = (configuracao) => {

  return [{
    type: ALTERNA_CLIENTE_SUCESSO,
    payload: null
  },
  {
    type: IR_PARA_MODULO,
    payload: { nomeModulo: 'Backoffice', configuracao: configuracao, loginRecente: false }
  }]
};

export const fechaDrawerAlteranacao = () => {
  return {
    type: FECHA_DRAWER_ALTERNACAO,
    payload: null
  };
}


export const inicioDefinicoesSucesso = (clientes, clienteFixado, clientesUsuario) => {

  var teste = "teste";

  return [{
    type: INICIO_DEFINICOES_SUCESSO,
    payload: { clientes, clienteFixado, clientesUsuario }
  },
/*   {
    type: ALTERNA_CLIENTE_SUCESSO,
    payload: { redirecionaInicio: false }
  } */]

}

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const alternarCliente = (idCliente) => {

  var nomeCombo = "idClienteFixado";
  
  return {
    type: ALTERACAO_COMBO_INICIO,
    payload: { nomeCombo: [nomeCombo], valor: idCliente }
  }
}

export const onChangeFilterClienteAlternar = (e) => {

  return {
      type: APLICA_FILTRO_CLIENTE_ALTERNAR,
      payload: e.target.value
  }
};

export const onChangeComboInicio = (name, object, value) => {
  var nomeCombo = "";

  var objTeste = object;

  if (object !== undefined) {
    var tipoInvocacao = [object.type].toString();

    if (tipoInvocacao !== "change") {

      if (tipoInvocacao === "click") {

        if (value !== null) {
          var nome = [object.currentTarget.id].toString();
          var insercaoAutomatica = nome.indexOf("-option-");
          nomeCombo = nome.substring(0, insercaoAutomatica);
        }
        else {
          return {
            type: ALTERACAO_COMBO_INICIO,
            payload: { nomeCombo: [name], valor: 0 }
          }
        }
      }
      else if (tipoInvocacao === "keydown") {
        nomeCombo = [object.target.id].toString();
      }

      if (nomeCombo !== "") {
        return {
          type: ALTERACAO_COMBO_INICIO,
          payload: { nomeCombo: [nomeCombo], valor: value.id }
        }
      }
    }
  }
  else {
    nomeCombo = [name.target.id].toString();

    return {
      type: ALTERACAO_COMBO_INICIO,
      payload: { nomeCombo: [nomeCombo], valor: '' }
    }
  }
};