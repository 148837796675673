import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_ACESSO_FULL,
    NOVO_ACESSO_FULL,
    BUSCA_ACESSO_FULL_EDICAO,
    CANCELA_EDICAO,
    INICIO_ACESSO_FULL_SUCESSO,
    SALVA_ACESSO_FULL_SUCESSO,
    REMOVE_ACESSO_FULL_SUCESSO,
    BUSCA_ACESSO_FULL_EDICAO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_ACESSO_FULL,
    LISTA_LOCAIS_SUCESSO_ACESSO_FULL,
    LISTA_CONFIGURACAO_PONTO_FULL,
    LISTA_CONFIGURACAO_PONTO_FULL_SUCESSO,
    ALTERACAO_CHECK_PONTO_ACESSO_FULL,
    CONSULTA_CPF,
    CONSULTA_CPF_SUCESSO_FULL,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    LIMPAR_ACESSO_FULL,
    LISTA_ACESSO_FULL_SUCESSO,
    LISTA_ACESSO_FULL      
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
      { titulo: 'Cliente', propriedadeApi: 'nomeCliente', alinhamento: 'left' },
      { titulo: 'Local Controlado', propriedadeApi: 'localControlado', alinhamento: 'left' },
      { titulo: 'Pessoa', propriedadeApi: 'nomeDestino', alinhamento: 'left' },      
    ],

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],

    // Campos do Formulario
    id: 0,
    idCliente: 0,
    idLocalControlado: 0,
    idAmbiente: 0,
    idPessoa: 0,
    nomePessoa: '',
    numeroCpf: '',
    configuracaoPontoPessoa: null,
    configuracaoAtualizada: null,
    nomeClienteFixado: null,
    idClienteFixado: 0,

    // Combos
    listaDeClientes: [],
    listaDeLocaisControlados: [],
    listaDeAmbientes: [],
    listaDeEquipamentos: [],

    // Filtros
    usuarioFiltro: '', 
    ambienteFiltro: '', 
    pontoFiltro: '', 
    };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_ACESSO_FULL: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado')             
        }
      }
      case CONSULTA_CPF: {
        return {
          ...state,
          loader: true,
        }
      }
      case CONSULTA_CPF_SUCESSO_FULL: {
        var retorno = action.payload;

        return {
          ...state,
          loader: false,
          idPessoa: retorno.idPessoa,
          nomePessoa: retorno.nomePessoa
        }
      }                 
      case NOVO_ACESSO_FULL: {
        return {
          ...state,
          edicao: true,
          id: 0,
          idCliente: state.idClienteFixado !== 0 ? parseInt(state.idClienteFixado) : 0,
          idLocalControlado: 0,
          idAmbiente: 0,
          idPessoa: 0,
          nomePessoa: '',
          numeroCpf: '',
          configuracaoPontoPessoa: null,
          configuracaoAtualizada: null,
        }
      }       
      case BUSCA_ACESSO_FULL_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false
        }
      }
      case INICIO_ACESSO_FULL_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.acessosFull,
          listaDeLocaisControlados: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado") : [],
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),             
        }
      }                     
      case SALVA_ACESSO_FULL_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_ACESSO_FULL_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_ACESSO_FULL: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_ACESSO_FULL_SUCESSO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          listGrid: retorno.acessoPessoa,
        }
      }
      case LIMPAR_ACESSO_FULL: {
  
        var retorno = action.payload;
  
        return {
          ...state,
          usuarioFiltro: '', 
          ambienteFiltro: '', 
          pontoFiltro: '', 
        }
      }              
      case BUSCA_ACESSO_FULL_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.acessoFull.id,
          idCliente: retorno.acessoFull.idCliente,
          idLocalControlado: retorno.acessoFull.idLocalControlado,
          idPessoa: retorno.acessoFull.idPessoa,
          numeroCpf: retorno.acessoFull.numeroCpf,
          nomePessoa: retorno.acessoFull.nomePessoa,
          nomeCliente:  retorno.acessoFull.nomeCliente,
          nomeLocalControlado:  retorno.acessoFull.nomeLocalControlado,
          configuracaoPontoPessoa: retorno.acessoFull.vinculoAcessoPessoa,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"),
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),                                    
        }
      }
      case LISTA_LOCAIS_POR_CLIENTE_ACESSO_FULL: {

        const retorno = action.payload;
  
        return {
          ...state,
          idCliente: retorno.idCliente,
          loader: true,
        }
      }
      case LISTA_LOCAIS_SUCESSO_ACESSO_FULL: {

        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          idLocalControlado: null,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado")
       }
      }
      case LISTA_CONFIGURACAO_PONTO_FULL: {
        return {
          ...state,
          idLocalControlado: state.idLocalControlado,
          loader: true
        }
      }
      case LISTA_CONFIGURACAO_PONTO_FULL_SUCESSO: {
  
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          configuracaoPontoPessoa: retorno.configuracao
        }
      }
      case ALTERACAO_CHECK_PONTO_ACESSO_FULL: {

        const retorno = action.payload;
        var idPontoAlterado = retorno.idPontoAcessoAlterado;
  
        const listaAmbientes = state.configuracaoPontoPessoa.listaAmbientesVinculados;
  
        for (var i = 0; i < listaAmbientes.length; i++) {
          var listaPontos = listaAmbientes[i].pontosAcessoVinculados;
  
          for (var j = 0; j < listaPontos.length; j++) {
            var idPontoAcesso = listaPontos[j].idPontoAcesso;
  
            if (idPontoAcesso.toString() === idPontoAlterado) {
              listaPontos[j].associado = retorno.valor;
            }
          }
  
        }
  
        const configuracaoAtualizada2 = state.configuracaoPontoPessoa;
        configuracaoAtualizada2.listaAmbientesVinculados = listaAmbientes;
  
        return {
          ...state,
          configuracaoPontoPessoa: {
            ...state.configuracaoPontoPessoa,
            listaAmbientesVinculados: configuracaoAtualizada2.listaAmbientesVinculados,
          },
        }
      }                                
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  