import React, { Component, Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import { Scrollbars } from 'react-custom-scrollbars';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Skeleton from '@material-ui/lab/Skeleton';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { LuxFwTextArea } from 'luxFw/palettes/Standart';
import portaria from 'app/portaria';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        flexGrow: 1,
        ////backgroundColor: theme.palette.background.paper,
    },
    firstIcon: {
        paddingLeft: 70
    },
    labelContainer: {
        width: "auto",
    },
    labelIcon: {
        minHeight: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif'
    },
    iconLabelWrapper2: {
        flexDirection: "row-reverse"
    },
    padding: {
        paddingTop: 0,
    }
});

class Observacoes extends Component {
    state = {
        exibeObservacoes: false,
    }

    render() {

        const portariaInicio = this.props.portariaInicio;
        const carregandoPaginaPerfil = this.props.portariaInicio.carregandoPaginaPerfil;
        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        var imagemTratada = pessoaSelecionada.imagem;
        imagemTratada = imagemTratada !== undefined && imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;
        const { classes } = this.props;

        var ultimaObservacao = this.props.portariaInicio.observacoesPessoaPortaria !== undefined && this.props.portariaInicio.observacoesPessoaPortaria.length > 0 ? this.props.portariaInicio.observacoesPessoaPortaria[0] : null;

        return carregandoPaginaPerfil ? (
            <Fragment>

                <Paper style={{ padding: 15, borderRadius: 15 }} >
                    <AppBar elevation={0} style={{ backgroundColor: 'white', borderBottom: '2px solid #e8e8e8' }} position="static" color='#05B59D'>
                        <Skeleton variant="text" width={'40%'} height={45} />
                    </AppBar>

                    <Fragment>
                        <Scrollbars
                            style={{ height: 240 }}>

                            <div style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex', height: 235 }}>
                                <div style={{ width: '100%' }}>

                                    <Skeleton variant="rect" width={'90%'} height={100} />

                                </div>

                                <Skeleton variant="text" width={'60%'} height={45} />
                            </div>

                        </Scrollbars>
                    </Fragment>
                </Paper>



            </Fragment>
        ) :
            (
                <Fragment>

                    <Paper style={{ padding: 15, borderRadius: 15 }} >
                        <AppBar elevation={0} style={{ backgroundColor: 'white', borderBottom: '2px solid #e8e8e8' }} position="static" color='#05B59D'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 47, alignItems: 'center' }}>
                                <div style={{ fontWeight: 600, fontSize: 18, fontFamily: 'Montserrat, sans-serif' }}>
                                    Observações
                                </div>
                                <div>
                                    <AddIcon onClick={() => this.props.exibeOcultaObservacoesPerfilPessoa()} style={{ color: '#05B59D', cursor: 'pointer' }} />
                                </div>
                            </div>

                        </AppBar>

                        <Fragment>
                            <Scrollbars
                                style={{ height: 240 }}>

                                <div style={{ justifyContent: 'center', flexDirection: 'column', display: 'flex', height: 235 }}>

                                    {ultimaObservacao !== null ? (<Fragment>

                                        <div style={{ width: '100%' }}>

                                            <p className="mb-0">{ultimaObservacao.observacao}</p>

                                        </div>

                                        <p style={{ fontWeight: 600, marginTop: 10 }} className="mb-0">Por {ultimaObservacao.nomeOperador} em {ultimaObservacao.dataObservacao} as {ultimaObservacao.horarioObservacao}</p>

                                    </Fragment>
                                    ) : (
                                        <Fragment>

                                            <div style={{ width: '100%', textAlign: 'center', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <div>
                                                    <img src={require("assets/images/semObservacoes.PNG")} alt="Sem observações" title="Sem observações" />

                                                </div>

                                                <div style={{ textAlign: 'center', width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <span style={{ fontWeight: 400, fontSize: 14, color: 'rgba(72, 81, 84, 0,6)' }}>Nenhuma observação ainda</span>
                                                </div>

                                                <div>
                                                    <div onClick={() => this.props.exibeOcultaObservacoesPerfilPessoa()} style={{ width: '100%', textAlign: 'center', cursor: 'pointer', color: '#05B59D', fontWeight: 400, fontSize: 14, textDecoration: 'underline' }}> Nova observação</div>
                                                </div>
                                            </div>

                                        </Fragment>
                                    )



                                    }


                                </div>

                            </Scrollbars>
                        </Fragment>
                    </Paper>

                    <Drawer anchor="right" open={this.props.portariaInicio.exibirDrawerObservacoes} onClose={() => this.props.exibeOcultaObservacoesPerfilPessoa()}>
                        <div style={{ width: '500px', padding: 15, textAlign: 'center' }}>

                            <Typography style={{ paddingLeft: 15, textAlign: 'left', fontWeight: 700, fontSize: 16, fontFamily: 'Roboto', color: '#000000DE' }}>
                                Observação da Pessoa
                            </Typography>

                            <LuxFwTextArea numCaracteres={500} name="observacaoPerfilAdicionar" label="Observações" tamanho="12" numeroLinhas="6" valor={portaria.observacaoPerfilAdicionar} onChange={this.props.onChangeValue} />

                            <Button onClick={() => this.props.adicionaObservacaoPessoaPerfil(portariaInicio.observacaoPerfilAdicionar, portariaInicio.pessoaSelecionada.idPessoa)} style={{ width: '95%', marginTop: 20, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" >
                                {portariaInicio.loadingBotaoAdicionarObservacao ?
                                    (
                                        <Fragment>
                                            <CircularProgress style={{ color: '#ffffff' }} size={14} />
                                            <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}> Adicionando Comentário...</span>
                                        </Fragment>

                                    ) :
                                    (
                                        <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Inserir</span>
                                    )}

                            </Button>

                        </div>
                    </Drawer>

                </Fragment>
            )
    }

}

Observacoes.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Observacoes);