import React, { Component, Fragment, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import IconeBiometriaSimples from '../util/iconeBiometriaSimples';
import IconeBiometriaMao from '../util/iconeBiometriaMao';
import IconeBiometriaFacial from '../util/iconeBiometriaFacial';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Avatar from '@material-ui/core/Avatar';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PhoneIcon from '@material-ui/icons/Phone';
import { NavLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

export default class Sidebar extends Component {

    handleTamanhoTela = () => {
        return window.screen.availWidth;
    }


    render() {

        var larguraTela = this.handleTamanhoTela();
        const carregandoPaginaPerfil = this.props.portariaInicio.carregandoPaginaPerfil;
        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        const blocoDescritivoPerfilPessoaSelecionada = this.props.portariaInicio.blocoDescritivoPerfilPessoaSelecionada;
        const possuiBiometriaSimples = this.props.portariaInicio.possuiBiometriaSimples;
        const possuiBiometriaMao = this.props.portariaInicio.possuiBiometriaMao;
        const possuiBiometriaFacial = this.props.portariaInicio.possuiBiometriaFacial;
        var imagemTratada = this.props.portariaInicio.fotoPerfilPessoaSelecionada;
        imagemTratada = imagemTratada !== undefined && imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

        return (
            <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                    <Grid style={{ height: 100, alignItems: 'center' }} item>

                        {carregandoPaginaPerfil ? (
                            <Skeleton variant="text" width={'80%'} height={45} />
                        ) : (
                            <NavLink onClick={() => this.props.voltarInicioPortaria()} style={{ textDecoration: 'none', color: '#000' }} className="prepend-icon" to={{ pathname: '/app/inicio', state: [{ id: 1, name: 'teste', color: 'red' }] }}>
                                <Typography style={{ cursor: 'pointer', textAlign: 'left', fontWeight: 500, fontSize: 20, fontFamily: 'Montserrat, sans-serif', color: '#828282' }}>
                                    <ArrowBackSharpIcon /> Voltar
                                </Typography>
                            </NavLink>)}
                    </Grid>
                    <Grid item style={{ marginTop: -12, width: '100%', backgroundColor: '#fff', marginBottom: 10, borderRadius: 15, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }}>
                        {carregandoPaginaPerfil ? (
                            <div id="divProfile" /* style={{ width: '100%', backgroundColor: '#fff', height: 370, marginBottom: 10, borderRadius: 15, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }} */>
                                <div id="divAvatar" style={{ width: '100%', position: 'relative', top: '-40px' }}>
                                    <Skeleton variant="rect" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px', borderRadius: 15 }} width={larguraTela > 1024 ? '80%' : '90%'} height={larguraTela > 1024 ? 310 : 250} />
                                    <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }} >
                                        <Skeleton variant="text" width={'90%'} height={45} />
                                    </div>

                                    <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >
                                        <Skeleton variant="text" width={'90%'} height={45} />
                                    </div>

                                    <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >
                                        <Skeleton variant="text" width={'90%'} height={45} />
                                    </div>

                                    {/*                                 <div style={{ width: '85%', textAlign: 'center', marginTop: 8, marginLeft: 'auto', marginRight: 'auto' }} >
                                    <Button style={{ border: 'solid 1px #828282', width: '85%', height: '55px', fontSize: 14, fontFamily: 'Montserrat, sans-serif', textTransform: 'none', fontWeight: 600, borderRadius: 60, color: '#828282' }} variant="outlined">Todos os dados</Button>
                                </div> */}


                                </div>

                            </div>
                        ) :
                            (
                                <div id="divProfile" /* style={{ width: '100%', backgroundColor: '#fff', height: 370, marginBottom: 10, borderRadius: 15, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }} */>
                                    <div id="divAvatar" style={{ width: '100%', position: 'relative', top: '-40px' }}>
                                        <Avatar variant="rounded" style={{ width: larguraTela > 1024 ? '80%' : '90%', height: larguraTela > 1024 ? 310 : 250, marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px', borderRadius: 15 }} alt="Remy Sharp" src={`data:image/jpeg;base64,${imagemTratada}`} />
                                        <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }} >
                                            <span style={{ lineHeight: larguraTela > 1024 ? 3 : 1.8, width: '100%', fontSize: 14, fontWeight: 400 }} ><LocalOfferIcon />  {pessoaSelecionada.unidades}</span>
                                        </div>

                                        <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >

                                            {
                                                this.props.portariaInicio.parametroOcultaTelefone ?
                                                    <span style={{ lineHeight: larguraTela > 1024 ? 3 : 1.8, width: '100%', fontSize: 14, fontWeight: 400 }} ><PhoneIcon />
                                                        {this.props.portariaInicio.parametroTipoControleVisibilidade === 'MASCARA_PARCIAL' ? "(**) *****" + pessoaSelecionada.telefones.slice(-5) : ''}
                                                    </span> :
                                                    <span style={{ lineHeight: larguraTela > 1024 ? 3 : 1.8, width: '100%', fontSize: 14, fontWeight: 400 }} ><PhoneIcon />
                                                        {pessoaSelecionada.telefones}
                                                    </span>
                                            }
                                        </div>

                                        <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }} >
                                            <span style={{ lineHeight: larguraTela > 1024 ? 3 : 1.8, width: '100%', fontSize: 14, fontWeight: 400 }} ><DriveEtaIcon />  {blocoDescritivoPerfilPessoaSelecionada}</span>
                                        </div>



                                        {/*                                 <div style={{ width: '85%', textAlign: 'center', marginTop: 8, marginLeft: 'auto', marginRight: 'auto' }} >
                                    <Button style={{ border: 'solid 1px #828282', width: '85%', height: '55px', fontSize: 14, fontFamily: 'Montserrat, sans-serif', textTransform: 'none', fontWeight: 600, borderRadius: 60, color: '#828282' }} variant="outlined">Todos os dados</Button>
                                </div> */}


                                    </div>

                                </div>)}
                    </Grid>

                    {
                        pessoaSelecionada.tipoPessoa === 'F' && (
                            <Grid item style={{ backgroundColor: '#ffffff', padding: 10, borderRadius: 15, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }}>

                                {carregandoPaginaPerfil ? (
                                    <Skeleton variant="text" width={'90%'} height={45} />
                                ) : (
                                    <Typography style={{ cursor: 'pointer', textAlign: 'left', fontWeight: 600, fontSize: 16, fontFamily: 'Montserrat, sans-serif', color: '#828282' }}>
                                        <ArrowBackSharpIcon style={{ color: '#05B59D' }} /> Biometrias
                                    </Typography>
                                )

                                }


                                {
                                    carregandoPaginaPerfil ? (
                                        <div style={{ marginTop: 22 }}>
                                            <div style={{ margin: 10, display: 'flex' }}>
                                                <div>
                                                    <Skeleton variant="rect" width={50} height={50} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                    <span style={{ color: '#222224', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                        <Skeleton variant="text" width={200} height={35} />
                                                    </span>
                                                    <span style={{ color: '#828282', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                        <Skeleton variant="text" width={200} height={35} />
                                                    </span>
                                                </div>
                                            </div>

                                            <div style={{ margin: 10, display: 'flex' }}>
                                                <div>
                                                    <Skeleton variant="rect" width={50} height={50} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                    <span style={{ color: '#222224', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                        <Skeleton variant="text" width={200} height={35} />
                                                    </span>
                                                    <span style={{ color: '#828282', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                        <Skeleton variant="text" width={200} height={35} />
                                                    </span>
                                                </div>
                                            </div>

                                            <div style={{ margin: 10, display: 'flex' }}>
                                                <div>
                                                    <Skeleton variant="rect" width={50} height={50} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                    <span style={{ color: '#222224', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                        <Skeleton variant="text" width={200} height={35} />
                                                    </span>
                                                    <span style={{ color: '#828282', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                        <Skeleton variant="text" width={200} height={35} />
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    ) : (<div style={{ marginTop: 22 }}>
                                        <div style={{ margin: 10, display: 'flex' }}>
                                            <div>
                                                <IconeBiometriaFacial tipo={possuiBiometriaFacial ? 'ativado' : 'desativado'} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                <span style={{ color: '#222224', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                    Biometria Facial
                                                </span>
                                                <span style={{ color: '#828282', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                    {possuiBiometriaFacial ? 'Habilitada' : 'Desabilitada'}
                                                </span>
                                            </div>
                                        </div>

                                        <div style={{ margin: 10, display: 'flex' }}>
                                            <div>
                                                <IconeBiometriaSimples tipo={possuiBiometriaSimples ? 'ativado' : 'desativado'} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                <span style={{ color: '#222224', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                    Digital Comum
                                                </span>
                                                <span style={{ color: '#828282', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                    {possuiBiometriaSimples ? 'Habilitada' : 'Desabilitada'}
                                                </span>
                                            </div>
                                        </div>

                                        <div style={{ margin: 10, display: 'flex' }}>
                                            <div>
                                                <IconeBiometriaMao tipo={possuiBiometriaMao ? 'ativado' : 'desativado'} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                                <span style={{ color: '#222224', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                    Digital 3D
                                                </span>
                                                <span style={{ color: '#828282', fontSize: 14, fontWeight: 400, fontFamily: 'Montserrat, sans-serif' }}>
                                                    {possuiBiometriaMao ? 'Habilitada' : 'Desabilitada'}
                                                </span>
                                            </div>
                                        </div>

                                    </div>)
                                }





                            </Grid>
                        )
                    }



                </Grid>

            </Grid >)
    }

}