import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_VINCULO_PESSOA, INICIA_VINCULO_PESSOA, BUSCA_VINCULO_PESSOA_EDICAO, CONSULTA_CPF_VINCULO,
         REMOVE_VINCULO_PESSOA, LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA, LISTA_VINCULO_PESSOA, LISTA_LOCALIDADE_POR_LOCAL, LISTA_DEPARTAMENTO_POR_LOCALIDADE } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaVinculoPessoaSucesso,
         removeVinculoPessoaSucesso, 
         inicioVinculoPessoaSucesso, 
         iniciaVinculoPessoa,
         buscaVinculoPessoaEdicaoSucesso,
         listaLocaisControladosSucesso,
         listaVinculoPessoaSucesso,
         listaDepartamentoPorLocalidadeSucesso,
         listaLocalidadePorLocalSucesso,
         consultaCpfSucesso
         } from "actions/VinculoPessoa";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getVinculoPessoas = async () =>
    await requisicaoApi.get('/departamentosUnidades/vinculospessoas')
    .then(resposta => resposta)
    .catch(error => error);

    const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);    

  const getVinculoPessoaEdicao = async (id) =>
    await requisicaoApi.get('/departamentosUnidades/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaLocalidadePorLocalControlado = async (idLocalControlado) => 
    await requisicaoApi.get('/localidades/localControlado/' + idLocalControlado)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaDepartamentosPorLocalidade = async (idLocalidade) => 
    await requisicaoApi.get('/departamentosUnidades/localidade/' + idLocalidade)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaFuncoesPorLocalidade = async (idLocalidade) => 
    await requisicaoApi.get('/funcoespessoas/localidade/' + idLocalidade)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaGruposPorLocalidade = async (idLocalidade) => 
    await requisicaoApi.get('/gruposPessoas/localidade/' + idLocalidade)
    .then(resposta => resposta)
    .catch(error => error);  

    const getListaVinculoPessoa = async (cliente, local, departamento) => 
    await requisicaoApi.get('/departamentosUnidades/vinculospessoas?Cliente=' +  cliente + '&Local=' + local + '&departamento=' + departamento)
    .then(resposta => resposta)
    .catch(error => error);  
    
    const getCpf = async (cpf) =>
    await requisicaoApi.get('/util/pessoaFisica/' + cpf)
    .then(resposta => resposta)
    .catch(error => error);    


/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const atualizaVinculoPessoaRequest = async (vinculoPessoa) => await requisicaoApi.put('/departamentosUnidades/vinculospessoas', 
  {
    "id": vinculoPessoa.idDepartamento,
    "idDepartamento": vinculoPessoa.idDepartamento,
    "idCliente": vinculoPessoa.idCliente,
    "IdLocalControlado": vinculoPessoa.idLocalControlado,
    "IdLocalidade": vinculoPessoa.idLocalidade,
    "clientesPessoaDepartamentoUnidade": vinculoPessoa.vinculosPessoas,
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteVinculoPessoaRequest = async (id) =>
  await requisicaoApi.delete('/departamentosUnidades/vinculospessoas?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaVinculoPessoa() {
    try {
      const listagemGrid = yield call(getVinculoPessoas);
  
      if(listagemGrid.data)
      {
        const clientes = yield call(getClientes);

        if(clientes.data) {
          const clienteFixo = localStorage.getItem('idClienteFixado');

          if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
          {
            const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
            yield put(inicioVinculoPessoaSucesso(listagemGrid.data, clientes.data, locais.data));
          }
          else
          {
            yield put(inicioVinculoPessoaSucesso(listagemGrid.data, clientes.data, null));
          }
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar vinculosPessoa'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaLocaisControladosPorCliente({ payload }) {
    try {
      const cliente = payload.idCliente;
      const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
  
      if(retorno.data)
      {
        yield put(listaLocaisControladosSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }

   function* fnListaVinculoPessoa({ payload }) {
    try {
      const cliente = payload.cliente;
      const local = payload.local;
      const departamento = payload.departamento;
      const retorno = yield call(getListaVinculoPessoa, cliente, local, departamento);
  
      if(retorno.data)
      {
        yield put(listaVinculoPessoaSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de departamento'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  function* fnBuscaVinculoPessoaEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getVinculoPessoaEdicao, id);
  
      if(retorno.data)
      {
        const funcoes = yield call(getListaFuncoesPorLocalidade, retorno.data.idLocalidade);
        const grupos = yield call(getListaGruposPorLocalidade, retorno.data.idLocalidade);
        yield put(buscaVinculoPessoaEdicaoSucesso(retorno.data, funcoes.data, grupos.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados da grupo: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  
  function* fnListaLocalidadePorLocal({ payload }) {
    try {
      const local = payload.idLocal;
      const retorno = yield call(getListaLocalidadePorLocalControlado, local);
  
      if(retorno.data)
      {
        yield put(listaLocalidadePorLocalSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de localidade: ' + local));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  
    
  function* fnListaDepartamentoPorLocalidade({ payload }) {
    try {
      const local = payload.idLocal;
      const departamentos = yield call(getListaDepartamentosPorLocalidade, local);
      const funcoes = yield call(getListaFuncoesPorLocalidade, local);
      const grupos = yield call(getListaGruposPorLocalidade, local);
  
      if(departamentos.data)
      {
        yield put(listaDepartamentoPorLocalidadeSucesso(departamentos.data, funcoes.data, grupos.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de departamento: ' + local));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  

  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaVinculoPessoa({ payload }) {
  try {

    const grupo = payload;
    var resultado = "";
    const retorno = yield call(atualizaVinculoPessoaRequest, grupo);
    resultado = retorno;

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaVinculoPessoa());
        yield put(salvaVinculoPessoaSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveVinculoPessoa({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteVinculoPessoaRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaVinculoPessoa());
        yield put(removeVinculoPessoaSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnConsultaCpf({ payload }) {
  try {
    const cpf = payload;
    const retorno = yield call(getCpf, cpf);

    if(retorno.data)
    {
      yield put(consultaCpfSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}  

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesVinculoPessoa() {
  yield takeEvery(INICIA_VINCULO_PESSOA, fnIniciaVinculoPessoa);
}

export function* salvaVinculoPessoa() {
  yield takeEvery(SALVA_VINCULO_PESSOA, fnSalvaVinculoPessoa);
}

export function* buscaVinculoPessoaEdicao() {
  yield takeEvery(BUSCA_VINCULO_PESSOA_EDICAO, fnBuscaVinculoPessoaEdicao);
}

export function* removeVinculoPessoa() {
  yield takeEvery(REMOVE_VINCULO_PESSOA, fnRemoveVinculoPessoa);
}

export function* listaLocaisControladosPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA, fnListaLocaisControladosPorCliente)
}

export function* listaVinculoPessoa() {
  yield takeEvery(LISTA_VINCULO_PESSOA, fnListaVinculoPessoa)
}

export function* listaLocalidadePorLocal() {
  yield takeEvery(LISTA_LOCALIDADE_POR_LOCAL, fnListaLocalidadePorLocal)
}

export function* listaDepartamentoPorLocalidade() {
  yield takeEvery(LISTA_DEPARTAMENTO_POR_LOCALIDADE, fnListaDepartamentoPorLocalidade)
}

export function* consultarCpf() {
  yield takeEvery(CONSULTA_CPF_VINCULO, fnConsultaCpf);
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesVinculoPessoa), 
             fork(salvaVinculoPessoa), 
             fork(buscaVinculoPessoaEdicao),
             fork(removeVinculoPessoa),
             fork(listaLocaisControladosPorCliente),
             fork(listaLocalidadePorLocal),
             fork(listaDepartamentoPorLocalidade),
             fork(listaVinculoPessoa),
             fork(consultarCpf)]);
}