import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
  INICIA_TITULARIDADE,
  NOVO_TITULARIDADE,
  BUSCA_TITULARIDADE_EDICAO,
  CANCELA_EDICAO,
  INICIO_TITULARIDADE_SUCESSO,
  SALVA_TITULARIDADE_SUCESSO,
  REMOVE_TITULARIDADE_SUCESSO,
  BUSCA_TITULARIDADE_EDICAO_SUCESSO,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CHECK,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  LIMPAR_TITULARIDADE,
  LISTA_TITULARIDADE_SUCESSO,
  LISTA_TITULARIDADE,
  INICIO_CENARIO_TITULARIDADE_LOCAL,
  INICIO_CENARIO_TITULARIDADE_LOCAL_SUCESSO,
  APLICA_FILTRO
} from "constants/ActionTypes";

const INIT_STATE = {

  // Configuração da Grid
  colunasGrid: [
    { titulo: 'Codigo Titularidade', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },
    { titulo: 'Principal', propriedadeApi: 'principal', alinhamento: 'left' },
    { titulo: 'Secundário', propriedadeApi: 'secundario', alinhamento: 'left' },
  ],

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],
  listGridComFiltro: [],
  rotuloGrupoTitularidade: '',
  rotuloUnidadeTitularidade: '',
  listaLocaisCompleta: [],
  textoFiltro: '',

  // Campos do Formulario
  id: 0,
  principal: '',
  secundario: '',
  idLocalControladoFixado: 0,
  nomeLocalControladoFixado: '',  

  // Combos
  listaDeLocaisControlados: [],
  listaDeLocaisControladosGeral: [],

  // Filtros
  modeloFiltro: '',
  numeroFiltro: '',
  situacaoFiltro: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_TITULARIDADE: {
      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        textoFiltro: '',
      }
    }
    case NOVO_TITULARIDADE: {
      return {
        ...state,
        edicao: true,
        id: 0,
        descricaoGrupoTitularidade: '',
        descricaoUnidadeTitularidade: '',
      }
    }
    case BUSCA_TITULARIDADE_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false,
        textoFiltro: '',
      }
    }
    case APLICA_FILTRO: {

      const filtroAplicar = action.payload;
      var listaFiltrada = filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.principal.toLowerCase().includes(filtroAplicar.toLowerCase()) || x.secundario.toLowerCase().includes(filtroAplicar.toLowerCase())) : [];

      return {
        ...state,
        textoFiltro: filtroAplicar,
        listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
      }
    }      
    case INICIO_TITULARIDADE_SUCESSO: {

      const retorno = action.payload;
      var teste = retorno.locaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== '');
      var teste2 = retorno.locaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== '');

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        listaDeLocaisControladosGeral: retorno.locaisControlados,
        listaLocaisCompleta: retorno.locaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== ''),
        idLocalControladoFixado: 0,
        listaDeLocaisControlados: preparaListaParaCombo(retorno.locaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== ''), "id", "descricaoLocalControlado"),
      }
    }
    case INICIO_CENARIO_TITULARIDADE_LOCAL: {
      const retorno = action.payload;

      return {
        ...state,
        idLocalControladoFixado: retorno.idLocal,
        carregandoLista: true,
        loader: true,
      }
    }
    case INICIO_CENARIO_TITULARIDADE_LOCAL_SUCESSO: {
      const retorno = action.payload;

      if (retorno.idLocal !== 0) {
        var localControlado = retorno.locaisControlados === null ? state.listaLocaisCompleta.filter(x => x.id === retorno.idLocal) : retorno.locaisControlados.filter(x => x.id === retorno.idLocal);
        state.rotuloGrupoTitularidade = localControlado[0].descricaoRotuloTitularidadePrincipal;
        state.rotuloUnidadeTitularidade = localControlado[0].descricaoRotuloTitularidadeSecundario;
        state.nomeLocalControladoFixado = localControlado[0].descricaoLocalControlado;
      }

      let data = [...state.colunasGrid],
        indexPrincipal = data.findIndex(r => r.propriedadeApi === "principal"),
        indexSecundario = data.findIndex(r => r.propriedadeApi === "secundario") //finding which index should get the udpate

      data[indexPrincipal].titulo = state.rotuloGrupoTitularidade;
      data[indexSecundario].titulo = state.rotuloUnidadeTitularidade;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        idLocalControladoFixado: retorno.idLocal,
        nomeLocalControladoFixado: state.nomeLocalControladoFixado,
        colunasGrid: data,
        listGrid: retorno.titularidades,
        listaLocaisCompleta: retorno.locaisControlados === null ? state.listaLocaisCompleta.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== '') : retorno.locaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== ''),
        listaDeLocaisControlados: retorno.locaisControlados === null ? state.listaDeLocaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== '') : preparaListaParaCombo(retorno.locaisControlados.filter(x => x.descricaoRotuloTitularidadePrincipal !== '' && x.descricaoRotuloTitularidadeSecundario !== ''), "id", "descricaoLocalControlado")        
      }
    }

    case SALVA_TITULARIDADE_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false,
        idLocalControladoFixado: state.idLocalControladoFixado
      }
    }
    case REMOVE_TITULARIDADE_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case LISTA_TITULARIDADE: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_TITULARIDADE_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.titularidade,
      }
    }
    case LIMPAR_TITULARIDADE: {

      var retorno = action.payload;

      return {
        ...state,
        modeloFiltro: '',
        numeroFiltro: '',
        situacaoFiltro: '',
      }
    }
    case BUSCA_TITULARIDADE_EDICAO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        descricaoGrupoTitularidade: retorno.principal,
        descricaoUnidadeTitularidade: retorno.secundario,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_COMBO: {
      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CHECK: {
      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
