import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_CHECK,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  EXIBE_PONTOS_SELECAO,
  ATIVA_TESTES,
  SELECIONA_PESSOA_SIMULACAO
} from "constants/ActionTypes";

const INIT_STATE = {

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],

  // Campos do Formulario

  idAmbientePortariaSelecionado: 0,


  // outros
  exibePontosParaSelecao: false,
  idAmbienteSelecionadoRequisicao: 0,
  idPessoaSelecionadaSimulacao: 0,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXIBE_PONTOS_SELECAO: {
      var retorno = action.payload;
      return {
        ...state,
        exibePontosParaSelecao: !state.exibePontosParaSelecao,
      }
    }

    case ATIVA_TESTES: {
      var retorno = action.payload;
      return {
        ...state,
        exibePontosParaSelecao: !state.exibePontosParaSelecao,
      }
    }
    case SELECIONA_PESSOA_SIMULACAO: {
      var retorno = action.payload;
      return {
        ...state,
        idPessoaSelecionadaSimulacao: retorno,
      }
    }
 
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_CHECK: {

      var campo = action.payload.nomeCheck[0];
      var configuracao = state.configuracaoAcessoPortalPerfil;

      if(campo === "visualizaDadosPortaria") {
        configuracao.visualizaDadosPortaria = action.payload.valor;
      }

      if(campo === "concedeAcessos") {
        configuracao.concedeAcessos = action.payload.valor;
      }

      if(campo === "usuarioMantemGaragem") {
        configuracao.usuarioMantemGaragem = action.payload.valor;
      }

      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
        configuracaoAcessoPortalPerfil: configuracao
      }
    }
    case ALTERACAO_COMBO: {
      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
