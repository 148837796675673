import { VALIDACAO_CAMPO } from 'constants/ActionTypes';

  export const ValidaCampoObrigatorio = (propriedade, valor) => {

    if(valor === undefined || valor === null || valor === '')
    {
      return {
        type: VALIDACAO_CAMPO,
        payload: { erro: true, propriedadeValidada: propriedade, mensagemErro: 'Campo Obrigatório' }
      }
    }
    else {
      return {
        type: VALIDACAO_CAMPO,
        payload: { erro: false, propriedadeValidada: propriedade, mensagemErro: '' }        
      }
    }
  }

  export const ValidaCampoObrigatorioCombo = (propriedade, valor) => {

    if(valor === undefined || valor === null || valor === '' || valor === 0 || valor === '0')
    {
      return {
        type: VALIDACAO_CAMPO,
        payload: { erro: true, propriedadeValidada: propriedade, mensagemErro: 'Campo Obrigatório' }
      }
    }
    else {
      return {
        type: VALIDACAO_CAMPO,
        payload: { erro: false, propriedadeValidada: propriedade, mensagemErro: '' }        
      }
    }
  }  

  export const LimpaValidacaoEspecifica = (propriedade) => {

      return {
        type: VALIDACAO_CAMPO,
        payload: { erro: false, propriedadeValidada: propriedade, mensagemErro: '' }        
      }
  }