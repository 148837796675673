import {
  INICIA_USUARIO_PLATAFORMA,
  SALVA_USUARIO_PLATAFORMA,
  SALVA_USUARIO_PLATAFORMA_LITE,
  REMOVE_USUARIO_PLATAFORMA,
  REMOVE_CLIENTE_PESSOA_PLATAFORMA,
  REMOVE_EVENTO_PESSOA_PERFIL,
  NOVO_USUARIO_PLATAFORMA,
  CONSULTA_CEP,
  NOVO_TELEFONE_PESSOA,
  NOVO_PERFIL_PESSOA_LITE,
  ATUALIZA_PERFIL_PESSOA_LITE,
  ATUALIZA_TELEFONE_PESSOA,
  REMOVE_TELEFONE_PESSOA,
  REMOVE_PERFIL_PESSOA_LITE,
  NOVO_EMAIL_PESSOA,
  ATUALIZA_EMAIL_PESSOA,
  REMOVE_EMAIL_PESSOA,
  NOVO_COMPLEMENTO,
  ATUALIZA_COMPLEMENTO,
  REMOVE_COMPLEMENTO,
  BUSCA_USUARIO_PLATAFORMA_EDICAO,
  BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO,
  BUSCA_PESSOA_BASE_UZER,
  BUSCA_PESSOA_BASE_UZER_LITE,
  INICIO_USUARIO_PLATAFORMA_SUCESSO,
  INICIO_PESSOAS_EVENTO,
  INICIO_PESSOAS_EVENTO_SUCESSO,
  SALVA_USUARIO_PLATAFORMA_SUCESSO,
  REMOVE_USUARIO_PLATAFORMA_SUCESSO,
  REMOVE_EVENTO_PESSOA_PERFIL_SUCESSO,
  BUSCA_USUARIO_PLATAFORMA_EDICAO_SUCESSO,
  BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO_SUCESSO,
  ALTERA_STATUS_PESSOA,
  CONSULTA_CEP_SUCESSO,
  CONSULTA_CEP_ERRO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_PERFIS_POR_CLIENTE,
  LISTA_PERFIS_SUCESSO,
  LISTA_USUARIO_PLATAFORMA,
  LISTA_USUARIO_PLATAFORMA_SUCESSO,
  LIMPAR_USUARIO_PLATAFORMA,
  ADICIONA_PERFIL_LISTA_VINCULADA,
  REMOVE_PERFIL_LISTA_VINCULADA,
  SETAR_IMAGEM_PESSOA,
  ABORTA_OPERACAO,
  CLIQUE_CONTROLE_ABA_USUARIO_PLATAFORMA,
  ALTERA_STATUS_PESSOA_LOCAL,
  EXIBE_ESTRUTURA_LOCAL,
  EXIBE_DETALHE_ESTRUTURA_LOCAL,
  EXIBE_DETALHE_ESTRUTURA_LOCAL_SUCESSO,
  ADICIONA_VINCULO_PESSOA,
  ATUALIZAR_VINCULO_PESSOA,
  REMOVER_VINCULO_PESSOA,
  ATUALIZAR_AMBIENTE_PESSOA,
  VALIDACAO_CAMPO,
  ATIVA_CAPTURA_IMAGEM,
  OBTEM_IMAGEM_ARRAY_PESSOAS,
  OBTEM_IMAGEM_ARRAY_PESSOAS_SUCESSO,
  CANCELA_CAPTURA_IMAGEM,
  LIMPA_VALIDACOES,
  APLICAR_FILTRO_SUCESSO,
  ENVIAR_SMS_PESSOA_EVENTO,
  ENVIAR_SMS_PESSOA_EVENTO_SUCESSO
} from 'constants/ActionTypes';
import { ValidaCampoObrigatorio, ValidaCampoObrigatorioCombo, LimpaValidacaoEspecifica } from 'luxFw/transformations/Validations';
import { limpaValidacoes } from "actions/Global";
import moment from 'moment';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaUsuarioPlataforma = () => {
  return {
    type: INICIA_USUARIO_PLATAFORMA
  };
};

export const salvaUsuarioPlataforma = (usuario) => {
  return {
    type: SALVA_USUARIO_PLATAFORMA,
    payload: usuario
  };
};

export const salvaUsuarioPlataformaLite = (usuario) => {
  return {
    type: SALVA_USUARIO_PLATAFORMA_LITE,
    payload: usuario
  };
};

export const removeUsuarioPlataforma = (id) => {
  return {
    type: REMOVE_USUARIO_PLATAFORMA,
    payload: id
  };
};

export const removeClientePessoaPlataforma = (id) => {
  return {
    type: REMOVE_CLIENTE_PESSOA_PLATAFORMA,
    payload: id
  };
}

export const removeEventoPessoaPerfil = (id) => {
  return {
    type: REMOVE_EVENTO_PESSOA_PERFIL,
    payload: id
  };
};

export const listaUsuarioPlataforma = (codigo, cpf, nome) => {
  if (codigo == undefined) {
    codigo = "";
  }

  if (cpf == undefined) {
    cpf = "";
  }

  if (nome == undefined) {
    nome = "";
  }

  return {
    type: LISTA_USUARIO_PLATAFORMA,
    payload: { codigo, cpf, nome }
  };
};

export const adicionaPerfilListaVinculada = (idClienteAdd, idPerfilAdd) => {

  var teste = "teste";

  return {
    type: ADICIONA_PERFIL_LISTA_VINCULADA,
    payload: { idClienteAdd, idPerfilAdd }
  };
};

export const removePerfilListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_PERFIL_LISTA_VINCULADA,
    payload: { idTemporario }
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const listaPerfisSucesso = (perfis) => {
  return {
    type: LISTA_PERFIS_SUCESSO,
    payload: { perfis }
  };
};

export const listaUsuarioPlataformaSucesso = (usuariosPlataforma) => {

  return [{
    type: LISTA_USUARIO_PLATAFORMA_SUCESSO,
    payload: { usuariosPlataforma }
  }];
};

export const obtemImagemArrayPessoasSucesso = (imagensThumbs) => {


  return [
    {
      type: OBTEM_IMAGEM_ARRAY_PESSOAS_SUCESSO,
      payload: { imagensThumbs: imagensThumbs }
    },
  ];
}

export const limparFiltroUsuarioPlataforma = () => {
  return {
    type: LIMPAR_USUARIO_PLATAFORMA,
  };
};

export const iniciaPessoasEventos = (name, object, value) => {
  var idLocal = value !== null ? value.id : 0;

  return {
    type: INICIO_PESSOAS_EVENTO,
    payload: { idLocal }
  };
}

export const iniciaPessoasEventosSucesso = (pessoas, idLocal) => {
  return {
    type: INICIO_PESSOAS_EVENTO_SUCESSO,
    payload: { pessoas, idLocal }
  };
}

/*#############################################################################################*/
/* ------------------------ Actions Complementares da Funcionalidade --------------------------*/

export const setarImagemPessoa = (imagemPessoa, imagemThumbnail) => {

  return {
    type: SETAR_IMAGEM_PESSOA,
    payload: { imagemPessoa, imagemThumbnail }
  };
}

export const listaPerfisPorCliente = (name, object, value) => {
  var idCliente = value !== null ? value.id : 0;

  return {
    type: LISTA_PERFIS_POR_CLIENTE,
    payload: { idCliente }
  }
}

export const consultaCEP = (cep) => {

  let cepAjustado = cep.target.value;

  if (cepAjustado !== undefined && cepAjustado !== null && cepAjustado !== '') {
    cepAjustado = cepAjustado.replace(/-/g, "");

    return {
      type: CONSULTA_CEP,
      payload: cepAjustado
    };
  }
  else {
    return {
      type: ABORTA_OPERACAO,
      payload: null
    };
  }
};

export const abortaOperacao = () => {
  return {
    type: ABORTA_OPERACAO,
    payload: null
  };
};

export const consultaCadastroExistente = (usuarioPlataforma) => {

  var validacoes = [];

  if (isValidCPF(usuarioPlataforma.numeroCpf)) {

    let cpfAjustado = usuarioPlataforma.numeroCpf;

    if (cpfAjustado !== undefined && cpfAjustado !== null && cpfAjustado !== '') {
      cpfAjustado = cpfAjustado.replace(/[^0-9]/g, "");

      let usuariosRetornados = usuarioPlataforma.listGrid.filter(x => x.numeroCpf !== null && x.numeroCpf.replace(/[^0-9]/g, "") === cpfAjustado);

      if (usuariosRetornados.length > 0) {
        return {
          type: BUSCA_USUARIO_PLATAFORMA_EDICAO,
          payload: usuariosRetornados[0].id
        };
      }
      else {
        return {
          type: BUSCA_PESSOA_BASE_UZER,
          payload: cpfAjustado
        };
      }
    }
    else {
      return {
        type: ABORTA_OPERACAO,
        payload: null
      };
    }
  }
  else {
    validacoes.push(validaCPFCampo('numeroCpf', usuarioPlataforma.numeroCpf));
  }

  return validacoes;

};

export const consultaCadastroExistenteLite = (usuarioPlataforma, idEvento) => {

  var validacoes = [];
  if (usuarioPlataforma.estrangeiro) {
    return {
      type: BUSCA_PESSOA_BASE_UZER_LITE,
      payload: {cpf: usuarioPlataforma.documentoEstrangeiro, idEvento}
    };
  } else {
    if (isValidCPF(usuarioPlataforma.numeroCpf)) {

      let cpfAjustado = usuarioPlataforma.numeroCpf;
  
      if (cpfAjustado !== undefined && cpfAjustado !== null && cpfAjustado !== '') {
        cpfAjustado = cpfAjustado.replace(/[^0-9]/g, "");
  
        return {
          type: BUSCA_PESSOA_BASE_UZER_LITE,
          payload: {cpf: cpfAjustado, idEvento}
        };
      }
      else {
        return {
          type: ABORTA_OPERACAO,
          payload: null
        };
      }
    }
    else {
      validacoes.push(validaCPFCampo('numeroCpf', usuarioPlataforma.numeroCpf));
    }
  
    return validacoes;
  }
};



/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoUsuarioPlataforma = () => {

  return [{
    type: LIMPA_VALIDACOES,
  },
  {
    type: NOVO_USUARIO_PLATAFORMA
  }]
};

export const buscaUsuarioPlataformaEdicao = (id) => {
  return {
    type: BUSCA_USUARIO_PLATAFORMA_EDICAO,
    payload: id
  };
};

export const buscaUsuarioPlataformaLiteEdicao = (id) => {
  return {
    type: BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioUsuarioPlataformaSucesso = (usuariosPlataforma, clientes, perfis, locais, paises, paisesTiposDocumentoEstrangeiro, locaisVinculosNovos, ambientesPerfilTemporario) => {

  return [{
    type: INICIO_USUARIO_PLATAFORMA_SUCESSO,
    payload: { usuariosPlataforma, clientes, perfis, locais, paises, paisesTiposDocumentoEstrangeiro, locaisVinculosNovos, ambientesPerfilTemporario }
  },
    /*   usuariosPlataforma.length > 0 && {
        type: OBTEM_IMAGEM_ARRAY_PESSOAS,
        payload: { array: usuariosPlataforma }
      } */
  ]
};
export const enviarSmsParaEventoPessoa = (idLocal) => {
  console.log('action', idLocal)
  return {
    type: ENVIAR_SMS_PESSOA_EVENTO,
    payload: {idLocal}
  }
}

export const enviarSmsParaEventoPessoaSucesso = (mensagem) => {
  return {
    type: ENVIAR_SMS_PESSOA_EVENTO_SUCESSO,
    payload: mensagem
  }
};

export const salvaUsuarioPlataformaSucesso = (mensagem) => {
  return {
    type: SALVA_USUARIO_PLATAFORMA_SUCESSO,
    payload: mensagem
  }
};

export const removeUsuarioPlataformaSucesso = (mensagem) => {
  return {
    type: REMOVE_USUARIO_PLATAFORMA_SUCESSO,
    payload: mensagem
  }
};

export const removeEventoPessoaPerfilSucesso = (mensagem) => {
  return {
    type: REMOVE_EVENTO_PESSOA_PERFIL_SUCESSO,
    payload: mensagem
  }
};

export const buscaUsuarioPlataformaEdicaoSucesso = (usuarioPlataforma) => {

  return {
    type: BUSCA_USUARIO_PLATAFORMA_EDICAO_SUCESSO,
    payload: { usuarioPlataforma }
  };
};

export const buscaUsuarioPlataformaLiteEdicaoSucesso = (usuarioPlataforma) => {
  return {
    type: BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO_SUCESSO,
    payload: { usuarioPlataforma }
  };
};

export const consultaCepSucesso = (correspondenciaCep) => {
  return {
    type: CONSULTA_CEP_SUCESSO,
    payload: correspondenciaCep
  }
};

export const consultaCepError = (erro) => {
  return {
    type: CONSULTA_CEP_ERRO,
    payload: erro
  }
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const novoTelefone = (exclusivo) => {
  return {
    type: NOVO_TELEFONE_PESSOA,
    payload: exclusivo
  };
};

export const novoPerfil= (exclusivo) => {
  return {
    type: NOVO_PERFIL_PESSOA_LITE,
    payload: exclusivo
  };
};

export const atualizaPerfil = (e) => {

  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;
  return {
    type: ATUALIZA_PERFIL_PESSOA_LITE,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const atualizaTelefone = (e) => {

  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_TELEFONE_PESSOA,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const novoComplemento = (tipo, local) => {
  return {
    type: NOVO_COMPLEMENTO,
    payload: { tipoComplemento: tipo, local: local }
  };
};

export const atualizaComplemento = (name, object, value) => {
  var nomeCombo = "";
  var valor = "";

  if (object !== undefined) {
    var tipoInvocacao = [object.type].toString();

    if (tipoInvocacao !== "change") {

      if (tipoInvocacao === "click") {

        if (value !== null) {
          var nome = [object.currentTarget.id].toString();
          var insercaoAutomatica = nome.indexOf("-option-");
          nomeCombo = nome.substring(0, insercaoAutomatica);
          valor = value.id
        }
        else {
          nomeCombo = name;
          valor = 0;
        }
      }
      else if (tipoInvocacao === "keydown") {
        nomeCombo = [object.target.id].toString();
      }

      if (nomeCombo !== "" && valor !== "" && valor !== 0) {
        valor = value.id
      }
    }
  }
  else {
    nomeCombo = [name.target.id].toString();
    valor = 0;
  }

  var indexUnderline = nomeCombo.indexOf("_");
  var indiceAtualizacao = parseInt(nomeCombo.substring(indexUnderline + 1));
  var campo = nomeCombo.replace(nomeCombo.substring(indexUnderline), "");
  var valor = valor;

  return {
    type: ATUALIZA_COMPLEMENTO,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const alteraStatusPessoaLocal = (statusAtual, idAlterar, direcao) => {
  return {
    type: ALTERA_STATUS_PESSOA_LOCAL,
    payload: { statusAtual, idAlterar, direcao }
  };
};

export const exibirEstruturaLocal = (idLocal, status, idAlterar) => {

  if (idLocal !== null && idLocal !== undefined && idLocal !== '' && idLocal > 0) {
    return [{
      type: EXIBE_ESTRUTURA_LOCAL,
      payload: null
    },
    {
      type: EXIBE_DETALHE_ESTRUTURA_LOCAL,
      payload: { idLocal, status, idAlterar }
    }]
  }
  else {
    return {
      type: EXIBE_ESTRUTURA_LOCAL,
      payload: null
    }
  }
};

export const exibeDetalheEstruturaLocalSucesso = (arvoreLocal, local, status, idAlterar) => {

  return {
    type: EXIBE_DETALHE_ESTRUTURA_LOCAL_SUCESSO,
    payload: { arvoreLocal, local, status, idAlterar }
  }
};

export const aplicarFiltroSucesso = (usuarios) => {

  return {
    type: APLICAR_FILTRO_SUCESSO,
    payload: { usuarios }
  }
};


export const removeComplemento = (indice) => {
  return {
    type: REMOVE_COMPLEMENTO,
    payload: indice
  };
};

export const removeTelefone = (indice) => {
  return {
    type: REMOVE_TELEFONE_PESSOA,
    payload: indice
  };
};

export const removePerfil = (indice) => {
  return {
    type: REMOVE_PERFIL_PESSOA_LITE,
    payload: indice
  };
};


export const novoEmail = (exclusivo) => {
  return {
    type: NOVO_EMAIL_PESSOA,
    payload: exclusivo
  };
};

export const atualizaEmail = (e) => {
  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;

  return {
    type: ATUALIZA_EMAIL_PESSOA,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const removeEmail = (indice) => {
  return {
    type: REMOVE_EMAIL_PESSOA,
    payload: indice
  };
};

export const alteraStatusPessoa = (novoStatus) => {


  return {
    type: ALTERA_STATUS_PESSOA,
    payload: novoStatus
  };
};

export const adicionaNovoVinculo = (usuarioPlataforma, tipo) => {

  let idPessoaAdicionar = tipo === 'R' ? usuarioPlataforma.idResponsavelAdicionar : usuarioPlataforma.idDependenteAdicionar;

  if (idPessoaAdicionar !== 0 && idPessoaAdicionar !== '' && idPessoaAdicionar !== undefined && idPessoaAdicionar !== null) {

    if (usuarioPlataforma.responsaveis.filter(x => x.idPessoa === idPessoaAdicionar).length > 0) {
      if (tipo === 'R') {
        return exibirMensagemErro('Essa registro já está adicionado como responsável. Verifique!');
      }
      else {
        return exibirMensagemErro('Esse registro não pode ser adicionado como Responsável pois o mesmo já consta vinculado como Dependente!');
      }
    }

    if (usuarioPlataforma.dependentes.filter(x => x.idPessoa === idPessoaAdicionar).length > 0) {
      if (tipo === 'D') {
        return exibirMensagemErro('Essa registro já está adicionado como dependente. Verifique!');
      }
      else {
        return exibirMensagemErro('Esse registro não pode ser adicionado como Dependente pois o mesmo já consta vinculado como Responsável!');
      }
    }

    if (idPessoaAdicionar === usuarioPlataforma.id) {
      return exibirMensagemErro('Não é possível víncular um registro a si próprio. Verifique!');
    }

    return {
      type: ADICIONA_VINCULO_PESSOA,
      payload: { idPessoaAdicionar, tipo }
    };
  }
  else {
    return exibirMensagemErro(tipo === 'D' ? 'Nenhum dependente foi selecionado para adição' : 'Nenhum responsável foi selecionado para adição');
  }

};

export const removeVinculoPessoa = (idRemover, tipo) => {
  return {
    type: REMOVER_VINCULO_PESSOA,
    payload: { idRemover, tipo }
  }
}

export const ativaCapturaImagem = () => {
  return {
    type: ATIVA_CAPTURA_IMAGEM,
    payload: null
  }
}

export const cancelaCapturaImagem = () => {
  return {
    type: CANCELA_CAPTURA_IMAGEM,
    payload: null
  }
}

export const atualizaVinculoPessoa = (object, value) => {

  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZAR_VINCULO_PESSOA,
    payload: { idAtualizacao, valor }
  }
}

export const atualizaAmbientePessoa = (object, value) => {

  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZAR_AMBIENTE_PESSOA,
    payload: { idAtualizacao, valor }
  }
}

export const onChangeAba = (indiceAbaDestino, usuarioPlataforma) => {

  var validacoes = [];
  var indiceAbaOrigem = usuarioPlataforma.indiceAba;
  var irParaAbaEndereco = indiceAbaDestino === 1;
  var irParaAbaContato = indiceAbaDestino === 2;
  var irParaAbaAcesso = indiceAbaDestino === 3;
  var irParaAbaComplemento = indiceAbaDestino === 4;
  var irParaAbaVinculo = indiceAbaDestino === 5;

  if (indiceAbaDestino > indiceAbaOrigem) {
    if (irParaAbaEndereco) {
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPlataforma));

      validacoes.push.apply(validacoes, LimpaValidacoesAbaEndereco());
    }

    if (irParaAbaContato) {
      if (usuarioPlataforma.tipoPessoa === 'FISICA') {
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPlataforma));
      }

      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(usuarioPlataforma));
      validacoes.push.apply(validacoes, LimpaValidacoesAbaContato(usuarioPlataforma))
    }

    if (irParaAbaAcesso) {
      if (usuarioPlataforma.tipoPessoa === 'FISICA') {
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPlataforma));
      }

      validacoes.push.apply(validacoes, LimpaValidacoesAbaAcesso());

      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(usuarioPlataforma));
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosContato(usuarioPlataforma));
    }
    if (irParaAbaComplemento) {
      if (usuarioPlataforma.tipoPessoa === 'FISICA') {
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPlataforma));
      }
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(usuarioPlataforma));
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosContato(usuarioPlataforma));

      if (usuarioPlataforma.tipoPessoa === 'FISICA') {
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosAcesso(usuarioPlataforma));
      }
    }
    if (irParaAbaVinculo) {
      if (usuarioPlataforma.tipoPessoa === 'FISICA') {
        validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosPessoais(usuarioPlataforma));
      }
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosEndereco(usuarioPlataforma));
      validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosContato(usuarioPlataforma));

      /*       if (usuarioPlataforma.tipoPessoa === 'FISICA') {
              validacoes.push.apply(validacoes, RetornosArrayValidacoesDadosAcesso(usuarioPlataforma));
            }      */
    }
  }

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    var irParaAba = {
      type: CLIQUE_CONTROLE_ABA_USUARIO_PLATAFORMA,
      payload: { indice: indiceAbaDestino }
    }

    validacoes.push(irParaAba);
  }
  else {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);
  }

  return validacoes;
};

function RetornosArrayValidacoesDadosPessoais(usuarioPlataforma) {

  var validacoes = [];

  if (usuarioPlataforma.tipoPessoa === 'FISICA') {
    if (usuarioPlataforma.indiDocumentoEstrangeiro) {
      validacoes.push(ValidaCampoObrigatorio('numeroDocumentoEstrangeiro', usuarioPlataforma.numeroDocumentoEstrangeiro));
      validacoes.push(ValidaCampoObrigatorioCombo('idPais', usuarioPlataforma.idPais));
      validacoes.push(ValidaCampoObrigatorioCombo('idPaisTipoDocumento', usuarioPlataforma.idPaisTipoDocumento));
    }
    else {
      validacoes.push(validaCPFCampo('numeroCpf', usuarioPlataforma.numeroCpf));
      validacoes.push(ValidaCampoObrigatorio('numeroCpf', usuarioPlataforma.numeroCpf));
      validacoes.push(ValidaCampoObrigatorio('nomePessoa', usuarioPlataforma.nomePessoa));
      validacoes.push(ValidaCampoObrigatorio('dataNascimentoStr', usuarioPlataforma.dataNascimentoStr));
      validacoes.push(validaDataCampo('dataNascimentoStr', usuarioPlataforma.dataNascimentoStr));
      validacoes.push(ValidaCampoObrigatorio('nomeMae', usuarioPlataforma.nomeMae));
      validacoes.push(ValidaCampoObrigatorioCombo('sexo', usuarioPlataforma.sexo));
      validacoes.push(ValidaCampoObrigatorioCombo('estadoCivil', usuarioPlataforma.estadoCivil));
    }
  }
  else {
    validacoes.push(validaCNPJCampo('numeroCnpj', usuarioPlataforma.numeroCnpj));
    validacoes.push(ValidaCampoObrigatorio('numeroCnpj', usuarioPlataforma.numeroCnpj));
    validacoes.push(ValidaCampoObrigatorio('razaoSocial', usuarioPlataforma.razaoSocial));
    validacoes.push(ValidaCampoObrigatorio('nomeFanstasia', usuarioPlataforma.nomeFanstasia));

    if (!usuarioPlataforma.isentoInscricaoEstadual && usuarioPlataforma.inscricaoEstadual === '') {
      validacoes.push(ValidaCampoObrigatorio('inscricaoEstadual', usuarioPlataforma.inscricaoEstadual));
    }
    else {
      validacoes.push(LimpaValidacaoEspecifica('inscricaoEstadual'));
    }

    if (!usuarioPlataforma.isentoInscricaoMunicipal && usuarioPlataforma.inscricaoMunicipal === '') {
      validacoes.push(ValidaCampoObrigatorio('inscricaoMunicipal', usuarioPlataforma.inscricaoMunicipal));
    }
    else {
      validacoes.push(LimpaValidacaoEspecifica('inscricaoMunicipal'));
    }

  }

  return validacoes;
}

function RetornosArrayValidacoesDadosEndereco(usuarioPlataforma) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorio('cep', usuarioPlataforma.cep));
  !usuarioPlataforma.semNumero && validacoes.push(ValidaCampoObrigatorio('numero', usuarioPlataforma.numero));

  return validacoes;
}

function LimpaValidacoesAbaEndereco() {

  var validacoes = [];

  validacoes.push(LimpaValidacaoEspecifica('cep'));
  validacoes.push(LimpaValidacaoEspecifica('numero'));

  return validacoes;
}

function RetornosArrayValidacoesDadosContato(usuarioPlataforma) {

  var validacoes = [];
  var contador = 0;

  if (usuarioPlataforma.telefones.filter(x => x.exclusivoDoCliente === false).length > 0) {
    usuarioPlataforma.telefones.filter(x => x.exclusivoDoCliente === false).map(n => {

      validacoes.push(ValidaCampoObrigatorio(`numeroTelefone_${n.idTemporario}`, n.numeroTelefone))
      validacoes.push(ValidaCampoObrigatorio(`marcador_${n.idTemporario}`, n.marcador))
    });
  }

  if (usuarioPlataforma.emails.filter(x => x.exclusivoDoCliente === false).length > 1) {
    usuarioPlataforma.emails.filter(x => x.exclusivoDoCliente === false).map(n => {
      contador++;

      if (contador > 1) {
        validacoes.push(ValidaCampoObrigatorio(`email_${n.idTemporario}`, n.email))
        validacoes.push(validaEmailCampo(`email_${n.idTemporario}`, n.email))
        validacoes.push(ValidaCampoObrigatorio(`marcador_${n.idTemporario}`, n.marcador))
      }
    });
  }
  else {
    usuarioPlataforma.emails.map(n => {
      contador++;

      if (contador === 1 && n.email !== '') {
        validacoes.push(validaEmailCampo(`email_${n.idTemporario}`, n.email))
      }
    });
  }

  return validacoes;
}

export const validaEmailCampo = (campo, valor) => {
  var mensagem = "";
  var emailValido = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    emailValido = ExecutaValidacaoEmail(valor);
  }

  if (valor != '' && !emailValido) {
    mensagem = "Email inválido";
  }
  else {
    emailValido = true;
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !emailValido, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

export const validaEmail = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaEmailCampo(campo, valor));

  return validacoes;
};

function ExecutaValidacaoEmail(email) {
  var re = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
  return re.test(email);
}

export const validaData = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaDataCampo(campo, valor));

  return validacoes;
};

export const validaDataCampo = (campo, valor) => {
  var mensagem = "";
  var dataValida = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    dataValida = isValidDate(valor);
  }
  else {
    dataValida = true;
  }

  if (!dataValida) {
    mensagem = "Data inválida";
  }
  else {
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !dataValida, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

function isValidDate(str) {
  var d = moment(str, 'D/M/YYYY');
  if (d == null || !d.isValid()) return false;

  return str.indexOf(d.format('D/M/YYYY')) >= 0
    || str.indexOf(d.format('DD/MM/YYYY')) >= 0
    || str.indexOf(d.format('D/M/YY')) >= 0
    || str.indexOf(d.format('DD/MM/YY')) >= 0;
}

export const validaCPF = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaCPFCampo(campo, valor));

  return validacoes;
};

export const validaCPFCampo = (campo, valor) => {
  var mensagem = "";
  var cpfValido = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    cpfValido = isValidCPF(valor);
  }
  else {
    cpfValido = true;
  }

  if (!cpfValido) {
    mensagem = "CPF inválido";
  }
  else {
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !cpfValido, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

function isValidCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito	
  var add = 0;
  var rev = 0;

  for (var i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (var i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
}

export const validaCNPJ = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaCNPJCampo(campo, valor));

  return validacoes;
};

export const validaCNPJCampo = (campo, valor) => {
  var mensagem = "";
  var cnpjValido = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    cnpjValido = isValidCNPJ(valor);
  }
  else {
    cnpjValido = true;
  }

  if (!cnpjValido) {
    mensagem = "CNPJ inválido";
  }
  else {
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !cnpjValido, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

function isValidCNPJ(cnpj) {

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14)
    return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999")
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
    return false;

  return true;

}

function LimpaValidacoesAbaContato(usuarioPlataforma) {

  var validacoes = [];

  usuarioPlataforma.telefones.map(n => {
    validacoes.push(LimpaValidacaoEspecifica(`numeroTelefone_${n.idTemporario}`))
    validacoes.push(LimpaValidacaoEspecifica(`marcador_${n.idTemporario}`))
  });

  usuarioPlataforma.emails.map(n => {
    validacoes.push(LimpaValidacaoEspecifica(`email_${n.idTemporario}`))
    validacoes.push(LimpaValidacaoEspecifica(`marcador_${n.idTemporario}`))
  });

  return validacoes;
}

function LimpaValidacoesAbaAcesso(usuarioPlataforma) {

  var validacoes = [];

  validacoes.push(LimpaValidacaoEspecifica('codigosPerfis'))

  return validacoes;
}

function RetornosArrayValidacoesDadosAcesso(usuarioPlataforma) {

  var validacoes = [];

  if (usuarioPlataforma.listaDePerfis.length > 0 && usuarioPlataforma.codigosPerfis.length === 0) {
    validacoes.push(ValidaCampoObrigatorioCombo('codigosPerfis', usuarioPlataforma.codigosPerfis !== null && usuarioPlataforma.codigosPerfis.length === 0 ? '' : 'preenchido'));
  }

  return validacoes;
}

export const eviaSmsSucesso = (message) => {

}

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


