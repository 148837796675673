import React from 'react';
import PanToolIcon from '@material-ui/icons/PanTool';

const IconeBiometriaMao = (props) => {

  let _corLateral = props.tipo === 'ativado' ? '#05B59D' : props.tipo === 'desativado' ? '#BDBDBD' : props.tipo === 'alertar' ? '#EB5757' : '';
  let _corIcone = props.tipo === 'ativado' ? '#222224' : props.tipo === 'desativado' ? '#828282' : props.tipo === 'alertar' ? '#EB5757' : '';
  let _tamanho = props.tamanho;

  return _tamanho !== 'Grande' ? (
    <div style={{ backgroundColor: props.alertar ? '#FFECEC' : 'transparent', width: 36, height: 39 }}>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 11, height: 3 }}></div>
      <div style={{ float: 'left', backgroundColor: 'transparent', width: 14, height: 3 }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 11, height: 3 }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 3, height: 10 }}></div>
      <div style={{ float: 'right', backgroundColor: _corLateral, width: 3, height: 10 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ float: 'left', backgroundColor: 'transparent', width: 3, height: 13 }}></div>
      <div style={{ textAlign: 'center', marginLeft: 'auto', marginTop: -5, marginBottom: -20, marginRight: 'auto' }}><PanToolIcon style={{ marginLeft: -2, color: _corIcone }} /> </div>
      <div style={{ float: 'right', backgroundColor: 'transparent', width: 3, height: 13 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 3, height: 10 }}></div>
      <div style={{ float: 'right', backgroundColor: _corLateral, width: 3, height: 10 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 11, height: 3 }}></div>
      <div style={{ float: 'left', backgroundColor: 'transparent', width: 14, height: 3 }}></div>
      <div style={{ float: 'left', marginBottom: -3, backgroundColor: _corLateral, width: 11, height: 3 }}></div>
    </div>
  ) : (
    <div style={{ backgroundColor: props.alertar ? '#FFECEC' : 'transparent', width: 64, height: 39 }}>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 20, height: 5 }}></div>
      <div style={{ float: 'left', backgroundColor: 'transparent', width: 24, height: 3 }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 20, height: 5 }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 5, height: 16 }}></div>
      <div style={{ float: 'right', backgroundColor: _corLateral, width: 5, height: 16 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ float: 'left', backgroundColor: 'transparent', width: 3, height: 16 }}></div>
      <div style={{ textAlign: 'center', marginLeft: 'auto', marginTop: -9, marginBottom: -26, marginRight: 'auto' }}><PanToolIcon style={{ color: _corIcone, width: 36, height: 36 }} /> </div>
      <div style={{ float: 'right', backgroundColor: 'transparent', width: 3, height: 20 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 5, height: 16 }}></div>
      <div style={{ float: 'right', backgroundColor: _corLateral, width: 5, height: 16 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ float: 'left', backgroundColor: _corLateral, width: 20, height: 5 }}></div>
      <div style={{ float: 'left', backgroundColor: 'transparent', width: 24, height: 3 }}></div>
      <div style={{ float: 'left', marginBottom: -3, backgroundColor: _corLateral, width: 20, height: 5 }}></div>
    </div>
  )
}
  ;

export default IconeBiometriaMao;