import {
  INICIA_PONTO_ACESSO,
  SALVA_PONTO_ACESSO,
  REMOVE_PONTO_ACESSO,
  LISTA_PONTO_ACESSO,
  NOVO_PONTO_ACESSO,
  BUSCA_PONTO_ACESSO_EDICAO,
  INICIO_PONTO_ACESSO_SUCESSO,
  SALVA_PONTO_ACESSO_SUCESSO,
  REMOVE_PONTO_ACESSO_SUCESSO,
  BUSCA_PONTO_ACESSO_EDICAO_SUCESSO,
  LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO,
  LISTA_LOCAIS_SUCESSO_PONTO_ACESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_AMBIENTES_SUCESSO,
  LISTA_AMBIENTES_POR_LOCAL,
  LISTA_PONTO_ACESSO_SUCESSO,
  LIMPAR_PONTO_ACESSO
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaPontoAcesso = () => {
  return {
    type: INICIA_PONTO_ACESSO
  };
};

export const salvaPontoAcesso = (pontoAcesso) => {
  return {
    type: SALVA_PONTO_ACESSO,
    payload: pontoAcesso
  };
};

export const removePontoAcesso = (id) => {
  return {
    type: REMOVE_PONTO_ACESSO,
    payload: id
  };
};

export const listaPontoAcesso = (cliente, local, ambiente) => {
  return {
    type: LISTA_PONTO_ACESSO,
    payload: {cliente, local, ambiente}
  };
};

/*#############################################################################################*/
/* ------------------------ Actions Complementares da Funcionalidade --------------------------*/

export const listaLocaisControladosPorCliente = (name, object, value) => {
  var idCliente = value !== null ? value.id : 0;

  return {
    type: LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO,
    payload: { idCliente }
  }
}

export const listaAmbientesPorLocal = (name, object, value) => {
  var idLocal = value !== null ? value.id : 0;

  return {
    type: LISTA_AMBIENTES_POR_LOCAL,
    payload: { idLocal }
  }
}

/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoPontoAcesso = () => {
  return {
    type: NOVO_PONTO_ACESSO
  };
};

export const buscaPontoAcessoEdicao = (id) => {
  return {
    type: BUSCA_PONTO_ACESSO_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioPontoAcessoSucesso = (pontosAcesso, clientes, equipamentos, locais) => {
  return {
    type: INICIO_PONTO_ACESSO_SUCESSO,
    payload: { pontosAcesso, clientes, equipamentos, locais }
  }
};

export const salvaPontoAcessoSucesso = (mensagem) => {
  return {
    type: SALVA_PONTO_ACESSO_SUCESSO,
    payload: mensagem
  }
};

export const removePontoAcessoSucesso = (mensagem) => {
  return {
    type: REMOVE_PONTO_ACESSO_SUCESSO,
    payload: mensagem
  }
};

export const buscaPontoAcessoEdicaoSucesso = (pontoAcesso, locais, ambientes) => {
  return {
    type: BUSCA_PONTO_ACESSO_EDICAO_SUCESSO,
    payload: { pontoAcesso, locais, ambientes }
  };
};

export const listaLocaisControladosSucesso = (locais) => {
  return {
    type: LISTA_LOCAIS_SUCESSO_PONTO_ACESSO,
    payload: { locais }
  };
};

export const listaAmbientesSucesso = (ambientes) => {
  return {
    type: LISTA_AMBIENTES_SUCESSO,
    payload: { ambientes }
  };
};

export const listaPontoAcessoSucesso = (pontosAcesso) => {
  return {
    type: LISTA_PONTO_ACESSO_SUCESSO,
    payload: { pontosAcesso }
  };
};

export const limparFiltroPontoAcesso = () => {
  return {
    type: LIMPAR_PONTO_ACESSO,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};