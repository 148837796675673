import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import RoomIcon from '@material-ui/icons/Room';
import { CgArrowLeftR } from "@react-icons/all-files/cg/CgArrowLeftR";
import { CgArrowRightR } from "@react-icons/all-files/cg/CgArrowRightR";
import { BiTimeFive } from "@react-icons/all-files/bi/BiTimeFive";
import { MdTagFaces } from "@react-icons/all-files/md/MdTagFaces";


export default class Registros extends Component {


    render() {

        const { classes } = this.props;
        const portariaInicio = this.props.portariaInicio;
        const registrosAcessosHistoricos = portariaInicio.registrosAcessosHistoricos;
        let datasProcessadas = [];
        let dataAnterior = null;
        let processarPrimeiraVez = false;


        return (
            <Fragment>
{
registrosAcessosHistoricos === null || registrosAcessosHistoricos === undefined || registrosAcessosHistoricos.length === 0 ?
(
    <div style={{ width: '100%', textAlign: 'center', height: 240, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <div>
        <img src={require("assets/images/semRegistros.PNG")} alt="Sem registros" title="Sem registros" />

    </div>

    <div style={{textAlign: 'center', width: 355, marginLeft: 'auto', marginRight: 'auto'}}>
        <span style={{ fontWeight: 400, fontSize: 14, color: 'rgba(72, 81, 84, 0,6)' }}>Nenhum registro de acesso ainda</span>
    </div>
</div>
):
(
    <TableContainer elevation={0} className={classes.padding} component={Paper}>
    <Table className={classes.table} aria-label="simple table">
        <TableBody>

            {

                registrosAcessosHistoricos.map(registro => {

                    return datasProcessadas.filter(x => x.data === registro.dataAcesso).length === 0 && (
                        <TableRow key={1}>
                            <TableCell style={{ verticalAlign: 'top' }} align="left">
                                <span style={{ float: 'left', width: '100%', fontSize: 14, fontWeight: 700, color: '#222224' }}>
                                    {registro.dataAcesso}
                                </span>
                            </TableCell>

                            <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>

                                {
                                    registrosAcessosHistoricos.filter(x => x.dataAcesso === registro.dataAcesso).map(registroInterno => {

                                        datasProcessadas = [...datasProcessadas, { data: registro.dataAcesso }];

                                        return (<div style={{ float: 'left', display: 'flex', width: '100%' }}>
                                            {
                                                registroInterno.direcao === 'E' ? (
                                                    <Fragment>
                                                        <CgArrowRightR style={{ color: '#16AA09', width: 80, height: 22 }} />
                                                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#16AA09' }}>
                                                            Entrou
                                                        </span>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <CgArrowLeftR style={{ color: '#E63737', width: 80, height: 22 }} />
                                                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#E63737' }}>
                                                            Saiu
                                                        </span>
                                                    </Fragment>
                                                )
                                            }
                                            <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                            <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                                as {registroInterno.horarioAcesso}
                                            </span>
                                            <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                            <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                                Via facial
                                            </span>
                                            <RoomIcon />
                                            <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                                {registroInterno.nomeAmbiente}
                                            </span>
                                        </div>
                                        )

                                    })
                                }

{/*                                             <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                                <CgArrowRightR style={{ color: '#16AA09', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#16AA09' }}>
                                    Entrou
                                </span>
                                <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    as 18:32
                                </span>
                                <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    Via facial
                                </span>
                                <RoomIcon />
                                <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    Portaria Sul
                                </span>
                            </div>

                            <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                                <CgArrowLeftR style={{ color: '#E63737', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#E63737' }}>
                                    Saiu
                                </span>
                                <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    as 18:32
                                </span>
                                <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    Via facial
                                </span>
                                <RoomIcon />
                                <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    Portaria Sul
                                </span>
                            </div>

                            <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                                <CgArrowRightR style={{ color: '#16AA09', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#16AA09' }}>
                                    Entrou
                                </span>
                                <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    as 18:32
                                </span>
                                <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                                <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    Via facial
                                </span>
                                <RoomIcon />
                                <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                                    Portaria Sul
                                </span>
                            </div> */}
                            </TableCell>
                        </TableRow>
        )


                })
            }


        {/*                             <TableRow key={1}>
                <TableCell style={{ verticalAlign: 'top' }} align="left">
                    <span style={{ float: 'left', width: '100%', fontSize: 14, fontWeight: 700, color: '#222224' }}>
                        10/10/2021
                    </span>
                </TableCell>

                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                    <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                        <CgArrowLeftR style={{ color: '#E63737', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#E63737' }}>
                            Saiu
                        </span>
                        <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            as 18:32
                        </span>
                        <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Via facial
                        </span>
                        <RoomIcon />
                        <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Portaria Sul
                        </span>
                    </div>

                    <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                        <CgArrowRightR style={{ color: '#16AA09', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#16AA09' }}>
                            Entrou
                        </span>
                        <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            as 18:32
                        </span>
                        <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Via facial
                        </span>
                        <RoomIcon />
                        <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Portaria Sul
                        </span>
                    </div>

                    <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                        <CgArrowLeftR style={{ color: '#E63737', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#E63737' }}>
                            Saiu
                        </span>
                        <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            as 18:32
                        </span>
                        <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Via facial
                        </span>
                        <RoomIcon />
                        <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Portaria Sul
                        </span>
                    </div>

                    <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                        <CgArrowRightR style={{ color: '#16AA09', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#16AA09' }}>
                            Entrou
                        </span>
                        <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            as 18:32
                        </span>
                        <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Via facial
                        </span>
                        <RoomIcon />
                        <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Portaria Sul
                        </span>
                    </div>
                </TableCell>
            </TableRow> */}

        {/*                            <TableRow key={2}>
                <TableCell style={{ verticalAlign: 'top' }} align="left">
                    <span style={{ float: 'left', width: '100%', fontSize: 14, fontWeight: 700, color: '#222224' }}>
                        10/10/2021
                    </span>
                </TableCell>

                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                    <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                        <CgArrowLeftR style={{ color: '#E63737', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#E63737' }}>
                            Saiu
                        </span>
                        <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            as 18:32
                        </span>
                        <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Via facial
                        </span>
                        <RoomIcon />
                        <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Portaria Sul
                        </span>
                    </div>

                    <div style={{ float: 'left', display: 'flex', width: '100%' }}>
                        <CgArrowRightR style={{ color: '#16AA09', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 700, color: '#16AA09' }}>
                            Entrou
                        </span>
                        <BiTimeFive style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            as 18:32
                        </span>
                        <MdTagFaces style={{ color: 'rgba(0, 0, 0, 0.38)', width: 80, height: 22 }} />
                        <span style={{ paddingLeft: 5, width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Via facial
                        </span>
                        <RoomIcon />
                        <span style={{ width: '100%', fontSize: 14, fontWeight: 400, color: '#222224' }}>
                            Portaria Sul
                        </span>
                    </div>
                </TableCell>
            </TableRow>
*/}


        </TableBody>
    </Table>
</TableContainer>
)

    }


            </Fragment >
        )
    }

}