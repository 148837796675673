import { cpfMask, cnpjMask, telefoneMask, telefoneMask2, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { estadosCivis, generos, generosV2, tiposPessoa } from '../app/constantes/Listas';
import { preparaListaParaCombo, retornaDescricaoPorValorListaPadrao, trataTelefone } from 'luxFw/transformations/Common';
import {
  INICIA_USUARIO_PLATAFORMA,
  CONSULTA_CEP,
  ABORTA_OPERACAO,
  NOVO_USUARIO_PLATAFORMA,
  BUSCA_USUARIO_PLATAFORMA_EDICAO,
  BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO,
  CANCELA_EDICAO,
  INICIO_USUARIO_PLATAFORMA_SUCESSO,
  SALVA_USUARIO_PLATAFORMA,
  SALVA_USUARIO_PLATAFORMA_LITE,
  SALVA_USUARIO_PLATAFORMA_SUCESSO,
  REMOVE_USUARIO_PLATAFORMA_SUCESSO,
  REMOVE_EVENTO_PESSOA_PERFIL_SUCESSO,
  REMOVE_CLIENTE_PESSOA_PLATAFORMA,
  BUSCA_USUARIO_PLATAFORMA_EDICAO_SUCESSO,
  BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO_SUCESSO,
  ALTERA_STATUS_PESSOA,
  ADICIONA_PERFIL_LISTA_VINCULADA,
  REMOVE_PERFIL_LISTA_VINCULADA,
  LISTA_PERFIS_POR_CLIENTE,
  LISTA_PERFIS_SUCESSO,
  CONSULTA_CEP_SUCESSO,
  NOVO_TELEFONE_PESSOA,
  NOVO_PERFIL_PESSOA_LITE,
  ATUALIZA_PERFIL_PESSOA_LITE,
  REMOVE_PERFIL_PESSOA_LITE,
  ATUALIZA_TELEFONE_PESSOA,
  REMOVE_TELEFONE_PESSOA,
  NOVO_EMAIL_PESSOA,
  ATUALIZA_EMAIL_PESSOA,
  REMOVE_EMAIL_PESSOA,
  NOVO_COMPLEMENTO,
  ATUALIZA_COMPLEMENTO,
  REMOVE_COMPLEMENTO,
  ADICIONA_VINCULO_PESSOA,
  REMOVER_VINCULO_PESSOA,
  ATUALIZAR_VINCULO_PESSOA,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_CHECK,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE_2,
  LIMPAR_USUARIO_PLATAFORMA,
  LISTA_USUARIO_PLATAFORMA_SUCESSO,
  LISTA_USUARIO_PLATAFORMA,
  SETAR_IMAGEM_PESSOA,
  APLICA_FILTRO,
  APLICAR_FILTRO_SUCESSO,
  CLIQUE_CONTROLE_ABA_USUARIO_PLATAFORMA,
  BUSCA_PESSOA_BASE_UZER,
  BUSCA_PESSOA_BASE_UZER_LITE,
  ALTERA_STATUS_PESSOA_LOCAL,
  EXIBE_ESTRUTURA_LOCAL,
  EXIBE_DETALHE_ESTRUTURA_LOCAL,
  EXIBE_DETALHE_ESTRUTURA_LOCAL_SUCESSO,
  ATUALIZAR_AMBIENTE_PESSOA,
  ATIVA_CAPTURA_IMAGEM,  
  CANCELA_CAPTURA_IMAGEM,
  OBTEM_IMAGEM_ARRAY_PESSOAS_SUCESSO,
  INICIO_PESSOAS_EVENTO_SUCESSO,
  ENVIAR_SMS_PESSOA_EVENTO,
  ENVIAR_SMS_PESSOA_EVENTO_SUCESSO
} from "constants/ActionTypes";
import {
  showAuthMessage,
  exibirMensagemErro
} from "actions/UsuarioPlataforma";
import perfil from 'app/portaria/routes/perfil';

const INIT_STATE = {

  // Configuração da Grid
  colunasGrid: [
    { titulo: 'Codigo Usuario', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },    
    /* { titulo: 'Foto', propriedadeApi: 'imagem', alinhamento: 'left', imagem: true }, */
    { titulo: 'Id', propriedadeApi: 'id', alinhamento: 'left', numerico: true },
    { titulo: 'Nome Pessoa', propriedadeApi: 'nomePessoa', alinhamento: 'left' },
    { titulo: 'Nº Documento', propriedadeApi: 'numeroDocumento', alinhamento: 'left' },
    { titulo: 'Gênero', propriedadeApi: 'sexo', alinhamento: 'left' },
    { titulo: 'Data Nascimento', propriedadeApi: 'dataNascimentoStr', alinhamento: 'left' },
  ],

  colunasGridPessoasEventos: [
    { titulo: 'Codigo Evento', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },    
    { titulo: 'Id', propriedadeApi: 'id', alinhamento: 'left', numerico: true },
    { titulo: 'Nome Pessoa', propriedadeApi: 'nomePessoa', alinhamento: 'left' },
    { titulo: 'Nº Documento', propriedadeApi: 'documento', alinhamento: 'left' },
    { titulo: 'Estrangeiro?', propriedadeApi: 'estrangeiro', alinhamento: 'left' },
    { titulo: 'Data Nascimento', propriedadeApi: 'dataNascimentoFormatada', alinhamento: 'left' },
    { titulo: 'Perfil', propriedadeApi: 'perfis', alinhamento: 'left' },
  ],

  arvoreNovo: null,

  telefonesNovo: [

    {
      id: 0,
      idTemporario: 0,
      numeroTelefone: '',
      marcador: 'Principal',
      exclusivoDoCliente: true
    },

    {
      id: 0,
      idTemporario: 1,
      numeroTelefone: '',
      marcador: 'Principal',
      exclusivoDoCliente: false
    }
  ],

  emailsNovo: [

    {
      id: 0,
      idTemporario: 0,
      email: '',
      marcador: 'Principal',
      exclusivoDoCliente: true
    },

    {
      id: 0,
      idTemporario: 1,
      email: '',
      marcador: 'Principal',
      exclusivoDoCliente: false
    }
  ],

  complementoUnidadeNovo: [

    {
      id: 0,
      idTemporario: 0,
      tipoComplemento: 'UNIDADE',
      idReferencia: 0,
      idResponsabilidade: 0,
      idLocalControlado: 0
    }
  ],

  listaStatusVinculoLocalControlado: [
    { id: 0, status: 'ATIVO' },
    { id: 1, status: 'PAUSADO' },
    { id: 2, status: 'INATIVO' },
    { id: 3, status: 'BLOQUEADO' }
  ],

  dependentes: [],
  responsaveis: [],

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],
  listGridComFiltro: [],
  listGridEventos: [],
  codigoIbge: null,
  statusGlobal: 'ATIVO',
  exibeEstruturaLocal: false,
  exibeEskeleton: false,
  listaIdsTreeviewExpandidos: [],
  textoFiltro: '',
  telefoneCadastroRapido: '',
  codigoValidacaoVoucher: '',
  idPerfilEvento: '',

  // Campos do Formulario

  tipoPessoa: 'FISICA',

  // Ambos os Tipos de Pessoa
  id: 0,
  idCliente: localStorage.getItem('idClienteFixado'),
  imagem: '',
  thumbnail: '',
  cep: '',
  logradouro: '',
  semNumero: false,
  numero: '',
  complemento: '',
  bairro: '',
  uf: '',
  nomeUf: '',
  idMunicipio: 0,
  nomeMunicipio: '',
  situacaoPessoaCliente: '',
  telefones: [],
  emails: [],
  site: '',
  complementosPessoa: [],
  idDependenteAdicionar: 0,
  idResponsavelAdicionar: 0,
  ativarCapturaImagem: false,


  // ====> Pessoa Física
  nomePessoa: '', 
  numeroCpf: '',
  dataNascimentoStr: '',
  nomeMae: '',
  sexo: '',
  estadoCivil: '',
  codigosPerfis: [],
  arvoreLocal: [],
  arvoreClientePessoa: '',
  idPais: 0,
  idPaisTipoDocumento: 0,
  idLocalControladoFixado: 0,
  numeroDocumentoEstrangeiro: '',  
  indiDocumentoEstrangeiro: false,
  localControladoEstrutura: 0,
  houveAlteracaoFoto: false,
  indiDuploCheck: false,


  // ====> Pessoa Jurídica
  numrCnpj: '',
  razaoSocial: '',
  nomeFantasia: '',
  inscricaoEstadual: '',
  isentoInscricaoEstadual: false,
  inscricaoMunicipal: '',
  isentoInscricaoMunicipal: false,

  // Listas Vinculadas
  listaDeClientes: [],
  listaDePerfis: [],
  listaDePerfisVinculados: [],
  listaDeLocaisControlados: [],
  listaDeResponsabilidadesUnidade: [],
  listaDeResponsabilidadesCategoria: [],
  listaDeResponsabilidadesTitulo: [],
  listaVinculosLocalPessoa: [],
  listaVinculosLocalPessoaNovo: [],
  listaAmbientesPessoa: [],
  listaDePaises: [],
  listaAmbientesPerfilTemporario: [],
  listaDeDocumentosDePaisesCompleto: [],
  listaDeDocumentosDePaises: [],
  idClienteAdd: 0,
  idPerfilAdd: 0,

  addObjItemListaPerfil: {
    idCliente: 0,
    nomeCliente: ''
  },

  // combos
  listaDeSexos: [],
  listaDeEstadosCivis: [],
  listaDeEventos: [],

  // Filtros
  codigoFiltro: '',
  cpfFiltro: '',
  nomeFiltro: '',

  // Outros
  indiceAba: 0,
  listaDePessoasDoClienteCompleto: [],
  listaDePessoasDoCliente: [],
  idLocalExibidoEstrutura: 0,
  statusLocalExibidoEstrutura: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_USUARIO_PLATAFORMA: {
      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        textoFiltro: '',
      }
    }
    case NOVO_USUARIO_PLATAFORMA: {

      const objListComplementos = [];
      var contador = 0;

      for (var indice in state.listaDeLocaisControlados) {
        var local = state.listaDeLocaisControlados[indice];

        if (local.possuiUnidade) {

          const objItemUnidadeListaComplementos = {
            "id": 0,
            "idTemporario": contador,
            "tipoComplemento": 'UNIDADE',
            "idReferencia": 0,
            "idResponsabilidade": 0,
            "idLocalControlado": local.id
          }

          objListComplementos.push(objItemUnidadeListaComplementos);
          contador = contador + 1;
        }

        if (local.possuiCategoria) {

          const objItemCategoriaListaComplementos = {
            "id": 0,
            "idTemporario": contador,
            "tipoComplemento": 'CATEGORIA',
            "idReferencia": 0,
            "idResponsabilidade": 0,
            "idLocalControlado": local.id
          }

          objListComplementos.push(objItemCategoriaListaComplementos);
          contador = contador + 1;
        }

        if (local.possuiTitularidade) {

          const objItemTituloListaComplementos = {
            "id": 0,
            "idTemporario": contador,
            "tipoComplemento": 'TITULO',
            "idReferencia": 0,
            "idResponsabilidade": 0,
            "idLocalControlado": local.id
          }

          objListComplementos.push(objItemTituloListaComplementos);
          contador = contador + 1;
        }
      }

      return {
        ...state,
        tipoPessoa: 'FISICA',
        edicao: true,
        id: 0,
        indiceAba: 0,
        nomePessoa: '',
        numeroCpf: '',
        dataNascimentoStr: '',
        nomeMae: '',
        sexo: '',
        estadoCivil: '',
        imagem: '',
        thumbnail: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        uf: '',
        nomeUf: '',
        idMunicipio: 0,
        nomeMunicipio: '',
        pontoReferencia: '',
        telefoneUm: '',
        telefoneDois: '',
        email: '',
        site: '',
        semNumero: false,
        situacaoPessoaCliente: 'ATIVO',
        listaDePerfisVinculados: [],
        idClienteAdd: 0,
        idPerfilAdd: 0,
        telefones: state.telefonesNovo,
        emails: state.emailsNovo,
        ////arvoreClientePessoa: state.arvoreNovo,
        codigosPerfis: [],
        complementosPessoa: objListComplementos,
        indiDocumentoEstrangeiro: false,
        idPais: 0,
        idPaisTipoDocumento: 0,
        numeroDocumentoEstrangeiro: '',
        numrCnpj: '',
        razaoSocial: '',
        nomeFantasia: '',
        inscricaoEstadual: '',
        isentoInscricaoEstadual: false,
        inscricaoMunicipal: '',
        isentoInscricaoMunicipal: false,
        listaVinculosLocalPessoa: state.listaVinculosLocalPessoaNovo,
        listaAmbientesPessoa: [],  
        dependentes: [],
        responsaveis: [],  
        houveAlteracaoFoto: false,     
        indiDuploCheck: false,
        ativarCapturaImagem: false,  
        codigoValidacaoVoucher: '',
        idPerfilEvento: '',
        telefoneCadastroRapido: '',
        cpf: '',
        dataNascimento: '',
        idEvento: 0,
        idPessoa: 0,
        idEventoPessoa: 0,
        documentoEstrangeiro: '',
	      estrangeiro: false,
        imagem: '',
        thumbnail: '',
        nomePessoa: '',
        sexo: '',
        telefone: '',
        perfis: [{
          "idPerfil": 0,
          "nomePerfil": '',
          "voucher": '',
          "idTemporario": 0
        }],
        codigoPerfis: []
      }
    }
    case BUSCA_USUARIO_PLATAFORMA_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case INICIO_PESSOAS_EVENTO_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        idLocalControladoFixado: retorno.idLocal,
        nomeLocalControladoFixado: state.nomeLocalControladoFixado,
        listGridEventos: retorno.pessoas,
      }
    }
    case BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }    
    case BUSCA_PESSOA_BASE_UZER: {
      return {
        ...state,
        loader: true,
      }
    }
    case BUSCA_PESSOA_BASE_UZER_LITE: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false,
        textoFiltro: '',
      }
    }
    case APLICA_FILTRO: {
      const filtroAplicar = action.payload;

      var apenasNumeros = /^\d+$/.test(filtroAplicar.replace(".", "").replace("-", ""));
      
      var listaFiltrada = [];
      
      if (!apenasNumeros) {
        if (filtroAplicar.length >= 3 && state.listGrid && state.listGrid.eventos && Array.isArray(state.listGrid.eventos)) {
          listaFiltrada = state.listGrid.eventos.filter(x =>
            x.nomePessoa !== null &&
            x.nomePessoa.toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "").includes(filtroAplicar.normalize("NFD").toLowerCase().replace(/[^a-zA-Zs]/g, ""))
          );
        }
      } else {
        if (filtroAplicar.length >= 3 && state.listGrid && Array.isArray(state.listGrid)) {
          listaFiltrada = state.listGrid.filter(x =>
            x.numeroDocumento !== null &&
            x.numeroDocumento.replace(".", "").replace("-", "").includes(filtroAplicar.replace(".", "").replace("-", ""))
          );
        }
      }
      
      return {
        ...state,
        textoFiltro: filtroAplicar,
        listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
      };
    }
    case APLICAR_FILTRO_SUCESSO: {
      const usuarios = action.payload.usuarios;

      return {
        ...state,
        ////textoFiltro: filtroAplicar,
        listGrid: usuarios,
        listGridComFiltro: usuarios,
      }
    }
    case CONSULTA_CEP: {
      return {
        ...state,
        loader: true,
      }
    }
    case ABORTA_OPERACAO: {
      return {
        ...state,
        loader: false,
      }
    }
    case INICIO_USUARIO_PLATAFORMA_SUCESSO: {

      const retorno = action.payload;

      var listaComboPessoas = [];
      var usuario = null;

      for (var indice in retorno.usuariosPlataforma) {
        usuario = retorno.usuariosPlataforma[indice];

        if(usuario.nomePessoa !== null){
          var documento = usuario.dataNascimento === null ? cnpjMask(usuario.numeroDocumento) : usuario.numeroCpf === '' ? usuario.numeroDocumento : cpfMask(usuario.numeroCpf);
          listaComboPessoas.push({ idPessoa: usuario.id, nomePessoa: usuario.nomePessoa + " - " + documento });
        }
      }

      return {
        ...state,
        loader: false,
        ////arvoreNovo: retorno.arvore,
        carregandoLista: false,
        listGrid: retorno.usuariosPlataforma,
        listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
        listaDePerfis: preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil"),
        listaDeSexos: preparaListaParaCombo(generos, "id", "title"),
        listaDeSexosV2: preparaListaParaCombo(generosV2, "id", "title"),
        listaDeEstadosCivis: preparaListaParaCombo(estadosCivis, "id", "title"),
        listaDeTiposDePessoa: preparaListaParaCombo(tiposPessoa, "id", "title"),
        listaDePessoasDoCliente: preparaListaParaCombo(listaComboPessoas, "idPessoa", "nomePessoa"),
        listaDePaises: preparaListaParaCombo(retorno.paises.filter(x => x.id !== 1), "id", "nomePais"),
        listaDeDocumentosDePaisesCompleto: retorno.paisesTiposDocumentoEstrangeiro,
        listaDeLocaisControlados: retorno.locais,
        listaDeEventos: preparaListaParaCombo(retorno.locais.filter(x => x.tipoLocalControlado === 'EVENTO'), "id", "descricaoLocalControlado"),
        listaVinculosLocalPessoa: retorno.locaisVinculosNovos,
        listaVinculosLocalPessoaNovo: retorno.locaisVinculosNovos,
        listaAmbientesPerfilTemporario: retorno.ambientesPerfilTemporario
      }
    }
    case SALVA_USUARIO_PLATAFORMA: {
      return {
        ...state,
        loader: true,
      }
    }
    case SALVA_USUARIO_PLATAFORMA_LITE: {
      return {
        ...state,
        loader: true,
      }
    }
    case ENVIAR_SMS_PESSOA_EVENTO: {
      return {
        ...state,
        loader: true,
      }
    }
    case ENVIAR_SMS_PESSOA_EVENTO_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case SALVA_USUARIO_PLATAFORMA_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_USUARIO_PLATAFORMA_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_EVENTO_PESSOA_PERFIL_SUCESSO: {
      return {
        ...state,
        loader: true,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_CLIENTE_PESSOA_PLATAFORMA: {
      return {
        ...state,
        loader: true,
        edicao: false
      }
    }
    case LISTA_USUARIO_PLATAFORMA: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_USUARIO_PLATAFORMA_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.usuariosPlataforma,
      }
    }
    case OBTEM_IMAGEM_ARRAY_PESSOAS_SUCESSO: {

      const retorno = action.payload;

/*       for (var indice in retorno.imagensThumbs) {
        var pessoaComImagem = retorno.imagensThumbs[indice];
        var pessoaCarregada = state.listGrid.filter(x => x.id === pessoaComImagem.idPessoa)[0];

        if(pessoaCarregada !== null && pessoaCarregada !== undefined){
          pessoaCarregada.imagem = pessoaComImagem.imgThumbnail;
          pessoaCarregada.carregandoThumb = false;
        }
      } */

      return {
        ...state,
        /////listGrid: ListaComImagensThumb,
        /////listGridComFiltro: state.listGrid
      }
    }
    case LIMPAR_USUARIO_PLATAFORMA: {

      var retorno = action.payload;

      return {
        ...state,
        codigoFiltro: '',
        cpfFiltro: '',
        nomeFiltro: '',
      }
    }
    case BUSCA_USUARIO_PLATAFORMA_EDICAO_SUCESSO: {
      var retornoGeral = action.payload;
      var retorno = retornoGeral.usuarioPlataforma;
      var perfilVinculado = "";
      const objListPerfil = [];

      const objListComplementos = retorno.complementosPessoa;

      const maiorIdTemporario = retorno.complementosPessoa.length > 0 ? retorno.complementosPessoa.reduce(
        (max, x) => (x.idTemporario > max ? x.idTemporario : max),
        retorno.complementosPessoa[0].idTemporario
      ) : 0;

      var contador = maiorIdTemporario + 1;

      for (var indice in state.listaDeLocaisControlados) {
        var local = state.listaDeLocaisControlados[indice];

        if (local.possuiUnidade &&
          retorno.complementosPessoa.filter(x => x.tipoComplemento === 'UNIDADE' && x.idLocalControlado === local.id).length === 0) {

          const objItemUnidadeListaComplementos = {
            "id": 0,
            "idTemporario": contador,
            "tipoComplemento": 'UNIDADE',
            "idReferencia": 0,
            "idResponsabilidade": 0,
            "idLocalControlado": local.id
          }

          objListComplementos.push(objItemUnidadeListaComplementos);
          contador = contador + 1;
        }

        if (local.possuiCategoria &&
          retorno.complementosPessoa.filter(x => x.tipoComplemento === 'CATEGORIA' && x.idLocalControlado === local.id).length === 0) {

          const objItemCategoriaListaComplementos = {
            "id": 0,
            "idTemporario": contador,
            "tipoComplemento": 'CATEGORIA',
            "idReferencia": 0,
            "idResponsabilidade": 0,
            "idLocalControlado": local.id
          }

          objListComplementos.push(objItemCategoriaListaComplementos);
          contador = contador + 1;
        }

        if (local.possuiTitularidade &&
          retorno.complementosPessoa.filter(x => x.tipoComplemento === 'TITULO' && x.idLocalControlado === local.id).length === 0) {

          const objItemTituloListaComplementos = {
            "id": 0,
            "idTemporario": contador,
            "tipoComplemento": 'TITULO',
            "idReferencia": 0,
            "idResponsabilidade": 0,
            "idLocalControlado": local.id
          }

          objListComplementos.push(objItemTituloListaComplementos);
          contador = contador + 1;
        }
      }

      var telefonesComMascara = retorno.telefones;

      for (var indice in telefonesComMascara) {
        telefonesComMascara[indice].numeroTelefone = telefoneMask(telefonesComMascara[indice].numeroTelefone);
      }

      for (var k in retorno.perfisVinculados) {

        perfilVinculado = retorno.perfisVinculados[k];

        const objItemListaPerfil = {
          "idCliente": perfilVinculado.idCliente,
          "idTemporario": perfilVinculado.idTemporario,
          "nomeCliente": retornaDescricaoPorValorListaPadrao(state.listaDeClientes, perfilVinculado.perfilSistema.idCliente),
          "idPerfilSistema": perfilVinculado.idPerfilSistema,
          "nomePerfil": retornaDescricaoPorValorListaPadrao(state.listaDePerfis, perfilVinculado.idPerfilSistema),
          "id": 0
        }

        objListPerfil.push(objItemListaPerfil);
      }

      if(retorno.indiDocumentoEstrangeiro)
      {
        var listagemTiposDocumentosPaises = [];
        var idPais = state.listaDeDocumentosDePaisesCompleto.filter(x => x.id === retorno.idPaisTipoDocumento)[0].idPais;
        var listaRetorno = state.listaDeDocumentosDePaisesCompleto.filter(x => x.idPais === idPais)
  
        for (var indice in listaRetorno) {
          listagemTiposDocumentosPaises.push({ id: listaRetorno[indice].id, nomeTipoDocumento: listaRetorno[indice].tipoDocumentoEstrangeiro.nomeTipoDocumentoEstrangeiro });
        }
      }

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        indiceAba: 0,
        nomePessoa: retorno.nomePessoa,
        numeroCpf: retorno.tipoPessoa === 'FISICA' ? retorno.indiDocumentoEstrangeiro ? 'Nenhum' : cpfMask(retorno.numeroCpf) : '',
        numeroCnpj: retorno.tipoPessoa === 'JURIDICA' ? cnpjMask(retorno.numeroCnpj) : '',
        razaoSocial: retorno.tipoPessoa === 'JURIDICA' ? retorno.razaoSocial : '',
        nomeFanstasia: retorno.tipoPessoa === 'JURIDICA' ? retorno.nomeFanstasia : '',
        inscricaoEstadual: retorno.tipoPessoa === 'JURIDICA' ? retorno.inscricaoEstadual : '',
        inscricaoMunicipal: retorno.tipoPessoa === 'JURIDICA' ? retorno.inscricaoMunicipal : '',
        isentoInscricaoEstadual: retorno.tipoPessoa === 'JURIDICA' ? retorno.isentoInscricaoEstadual : false,
        isentoInscricaoMunicipal: retorno.tipoPessoa === 'JURIDICA' ? retorno.isentoInscricaoMunicipal : false,
        dataNascimentoStr: retorno.tipoPessoa === 'FISICA' ? dateMask(retorno.dataNascimentoStr) : '',
        nomeMae: retorno.tipoPessoa === 'FISICA' ? retorno.nomeMae : '',
        sexo: retorno.tipoPessoa === 'FISICA' ? retorno.sexo : '',
        estadoCivil: retorno.tipoPessoa === 'FISICA' ? retorno.estadoCivil : '',
        imagem: retorno.imagem,
        thumbnail: retorno.thumbnail,
        cep: /* cepMask( */retorno.cep/* ) */,
        logradouro: retorno.logradouro,
        numero: retorno.numero,
        complemento: retorno.complemento,
        bairro: retorno.bairro,
        uf: retorno.uf,
        nomeUf: retorno.nomeUf,
        idMunicipio: retorno.idMunicipio,
        nomeMunicipio: retorno.nomeMunicipio,
        pontoReferencia: retorno.pontoReferencia,
        telefoneUm: retorno.telefoneUm,
        telefoneDois: retorno.telefoneDois,
        email: retorno.email,
        site: retorno.site,
        situacaoPessoaCliente: retorno.situacaoPessoaCliente,
        listaDePerfisVinculados: retorno.tipoPessoa === 'FISICA' ? objListPerfil : [],
        idClienteAdd: 0,
        idPerfilAdd: 0,
        telefones: telefonesComMascara,
        emails: retorno.emails,
        codigosPerfis: retorno.tipoPessoa === 'FISICA' ? retorno.codigosPerfis : [],
        complementosPessoa: objListComplementos,
        listaVinculosLocalPessoa: retorno.listaVinculosLocalPessoa,
        listaAmbientesPessoa: retorno.tipoPessoa === 'FISICA' ? retorno.listaAmbientesPessoa : [],
        dependentes: retorno.vinculosPessoa.dependentes,
        responsaveis: retorno.vinculosPessoa.responsaveis,
        indiDocumentoEstrangeiro: retorno.indiDocumentoEstrangeiro,
        listaDeDocumentosDePaises: retorno.indiDocumentoEstrangeiro ? preparaListaParaCombo(listagemTiposDocumentosPaises, "id", "nomeTipoDocumento") : [],
        idPaisTipoDocumento: retorno.indiDocumentoEstrangeiro ? retorno.idPaisTipoDocumento : 0,
        numeroDocumentoEstrangeiro: retorno.indiDocumentoEstrangeiro ? retorno.numeroDocumentoEstrangeiro : '',
        idPais: retorno.indiDocumentoEstrangeiro ? idPais : state.idPais,
        tipoPessoa: retorno.tipoPessoa,
        semNumero: retorno.semNumero,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false, 
        houveAlteracaoFoto: false,
        indiDuploCheck: retorno.tipoPessoa === 'FISICA' ? retorno.indiDuploCheck : state.indiDuploCheck,   
        ativarCapturaImagem: false  
      }
    } 
    case BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO_SUCESSO: {
      let retornoGeral = action.payload;
      let retorno = retornoGeral.usuarioPlataforma;
      return {
        ...state,
        edicao: true,
        loader: false,
        id: retorno.idEventoPessoa,
        numeroCpf: retorno.cpf,
        dataNascimentoStr: retorno.dataNascimentoFormatada,
        idEvento: retorno.idEvento,
        idEventoPessoa: retorno.idEventoPessoa,
        idPessoa: retorno.idPessoa,
        imagem: retorno.imagem,
        thumbnail: retorno.imagemThumbnail,
        nomePessoa: retorno.nomePessoa,
        documentoEstrangeiro: retorno.documentoEstrangeiro,
	      estrangeiro: retorno.estrangeiro,
        sexo: retorno.sexo,
        telefoneCadastroRapido: retorno.telefone !== null ? telefoneMask2(trataTelefone(retorno.telefone)) : '',
        codigoPerfis: retorno.perfis.map(v => v.idPerfil),
        perfis: retorno.perfis.map((t, i) => ({...t, idTemporario: i}))
      }
    }

    case ADICIONA_PERFIL_LISTA_VINCULADA: {

      var retorno = action.payload;
      const addObjItemListaPerfil = {
        "idCliente": retorno.idClienteAdd,
        "nomeCliente": retornaDescricaoPorValorListaPadrao(state.listaDeClientes, retorno.idClienteAdd),
        "idPerfilSistema": retorno.idPerfilAdd,
        "nomePerfil": retornaDescricaoPorValorListaPadrao(state.listaDePerfis, retorno.idPerfilAdd),
        "idTemporario": state.listaDePerfisVinculados.length > 0 ? Math.max(...state.listaDePerfisVinculados.map(x => x.idTemporario)) + 1 : 1,
        "id": 0
      }

      return {
        ...state,
        listaDePerfisVinculados: [...state.listaDePerfisVinculados, addObjItemListaPerfil],
        idClienteAdd: action.payload.idCliente,
      }
    }
    case ALTERA_STATUS_PESSOA_LOCAL: {
      var retorno = action.payload;

      var proximoIndice = 0;
      var indiceAtual = state.listaStatusVinculoLocalControlado.filter(x => x.status === retorno.statusAtual)[0].id;
      var direcao = retorno.direcao;

      if (direcao === 'PROXIMO') {
        if (indiceAtual === 3) {
          proximoIndice = 0;
        }
        else {
          proximoIndice = indiceAtual + 1;
        }
      }
      else {
        if (indiceAtual === 0) {
          proximoIndice = 3;
        }
        else {
          proximoIndice = indiceAtual - 1;
        }
      }

      var proximoStatus = state.listaStatusVinculoLocalControlado.filter(x => x.id === proximoIndice)[0].status;

      var arrayLocaisPessoas = state.listaVinculosLocalPessoa;
      var arrayAtualizado = [];

      for (var indice in arrayLocaisPessoas) {
        if (arrayLocaisPessoas[indice].idTemporario === retorno.idAlterar) {
          arrayLocaisPessoas[indice].situacaoPessoaLocalControlado = proximoStatus;
        }

        arrayAtualizado.push(arrayLocaisPessoas[indice]);
      }

      var statusInicial = "";
      var ehStatusCustomizado = false;

      for (var indice in arrayAtualizado) {

        if (statusInicial === "") {
          statusInicial = arrayAtualizado[indice].situacaoPessoaLocalControlado;
        }
        else if (statusInicial !== arrayAtualizado[indice].situacaoPessoaLocalControlado) {
          ehStatusCustomizado = true;
          break;
        }
      }

      return {
        ...state,
        statusGlobal: proximoStatus,
        listaVinculosLocalPessoa: arrayAtualizado,
        situacaoPessoaCliente: ehStatusCustomizado ? 'CUSTOMIZADO' : statusInicial,
        statusLocalExibidoEstrutura: proximoStatus,
      }
    }
    case EXIBE_ESTRUTURA_LOCAL: {
      return {
        ...state,
        exibeEstruturaLocal: !state.exibeEstruturaLocal
      }
    }
    case ATIVA_CAPTURA_IMAGEM: {
      return {
        ...state,
        ativarCapturaImagem: true
      }
    }
    case CANCELA_CAPTURA_IMAGEM: {
      return {
        ...state,
        ativarCapturaImagem: false
      }
    }
    case REMOVE_PERFIL_LISTA_VINCULADA: {
      var retorno = action.payload;

      const arrayAtualizado = state.listaDePerfisVinculados.filter(x => x.idTemporario !== retorno.idTemporario)

      return {
        ...state,
        listaDePerfisVinculados: arrayAtualizado
      }
    }
    case LISTA_PERFIS_POR_CLIENTE: {
      return {
        ...state,
        idClienteAdd: action.payload.idCliente,
        loader: true,
      }
    }
    case LISTA_PERFIS_SUCESSO: {

      const retorno = action.payload;
      return {
        ...state,
        loader: false,
        listaDePerfis: preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil"),
      }
    }
    case ALTERA_STATUS_PESSOA: {

      const novoStatus = action.payload;

      var arrayVinculosLocais = state.listaVinculosLocalPessoa;
      var arrayVinculosLocaisAtualizado = [];

      for (var indice in arrayVinculosLocais) {

        arrayVinculosLocais[indice].situacaoPessoaLocalControlado = novoStatus;
        arrayVinculosLocaisAtualizado.push(arrayVinculosLocais[indice]);
      }

      return {
        ...state,
        situacaoPessoaCliente: novoStatus,
        listaVinculosLocalPessoa: arrayVinculosLocaisAtualizado
      }
    }
    case CONSULTA_CEP_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        cepConsultado: retorno,
        logradouro: retorno.logradouro,
        bairro: retorno.bairro,
        nomeMunicipio: retorno.localidade,
        nomeUf: retorno.uf,
        codigoIbge: retorno.ibge
      }
    }
    case CLIQUE_CONTROLE_ABA_USUARIO_PLATAFORMA: {
      var retorno = action.payload;

      return {
        ...state,
        indiceAba: retorno.indice,
      }
    }
    case SETAR_IMAGEM_PESSOA: {
      var retorno = action.payload;

      return {
        ...state,
        imagem: retorno.imagemPessoa,
        thumbnail: retorno.imagemThumbnail,
        ativarCapturaImagem: false,
        houveAlteracaoFoto: true
      }
    }
    case ATUALIZAR_AMBIENTE_PESSOA: {
      var retorno = action.payload;
      var arrayAtualizado = [];
      var arvoreTeste = state.arvoreLocal;
      var vinculosAmbientes = state.listaAmbientesPessoa;

      function AtualizaAutorizacaoAmbiente(ambientes) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (ambiente.id === retorno.idAtualizacao) {
            ambiente.usuarioPodeLiberar = retorno.valor;

            if (retorno.valor) {
              if (vinculosAmbientes.filter(x => x.idAmbiente === retorno.idAtualizacao).length === 0) {
                vinculosAmbientes.push({ idAmbiente: retorno.idAtualizacao, podeAutorizar: true });
              }
              else {
                vinculosAmbientes = vinculosAmbientes.filter(x => x.idAmbiente !== retorno.idAtualizacao);
                vinculosAmbientes.push({ idAmbiente: retorno.idAtualizacao, podeAutorizar: true });
              }
            }
            else {
              vinculosAmbientes = vinculosAmbientes.filter(x => x.idAmbiente !== retorno.idAtualizacao);
            }
          }

          if (ambiente.subAmbientes.length > 0) {
            AtualizaAutorizacaoAmbiente(ambiente.subAmbientes);
          }
        }

      }

      AtualizaAutorizacaoAmbiente(arvoreTeste);

      return {
        ...state,
        arvoreLocal: arvoreTeste,
        listaAmbientesPessoa: vinculosAmbientes
      }
    }

    case NOVO_PERFIL_PESSOA_LITE: {
      var retorno = action.payload;
      const idTemporario = state.perfis.length;
      const telefoneAdd = {
        "idPerfil": 0,
        "nomePerfil": '',
        "voucher": '',
        "idTemporario": idTemporario
      }

      return {
        ...state,
        perfis: [...state.perfis, telefoneAdd],
      }
    }

    case NOVO_TELEFONE_PESSOA: {
      
      var retorno = action.payload;
      
      const idTemporario = state.telefones.length + 1;

      const telefoneAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "numeroTelefone": '',
        "marcador": '',
        "exclusivoDoCliente": retorno
      }

      return {
        ...state,
        telefones: [...state.telefones, telefoneAdd],
      }
    }

    case ATUALIZA_PERFIL_PESSOA_LITE: {
      var retorno = action.payload;
      var listaperfis = state.perfis.filter(x => x.idTemporario !== retorno.indice);
      var perfilSelecionado = state.perfis.find(x => x.idTemporario === retorno.indice);

      var perfilAtualizar = {
        "idPerfil": perfilSelecionado.idPerfil,
        "idTemporario": perfilSelecionado.idTemporario,
        "nomePerfil": retorno.campoAtualizar === 'nomePerfil' ? retorno.valor : perfilSelecionado.nomePerfil,
        "voucher": retorno.campoAtualizar === 'voucher' ? retorno.valor : perfilSelecionado.voucher
      };
      listaperfis = [...listaperfis, perfilAtualizar];
      listaperfis = listaperfis.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));
      return {
        ...state,
        perfis: listaperfis,
      }
    }

    case ATUALIZA_TELEFONE_PESSOA: {

      var retorno = action.payload;

      var listaDetelefones = state.telefones.filter(x => x.idTemporario !== retorno.indice);
      var telefoneSelecionado = state.telefones.filter(x => x.idTemporario === retorno.indice);

      var telefoneAtualizar = {
        "id": telefoneSelecionado[0].id,
        "idTemporario": telefoneSelecionado[0].idTemporario,
        "numeroTelefone": retorno.campoAtualizar === 'numeroTelefone' ? telefoneMask(retorno.valor) : telefoneSelecionado[0].numeroTelefone,
        "marcador": retorno.campoAtualizar === 'marcador' ? retorno.valor : telefoneSelecionado[0].marcador,
        "exclusivoDoCliente": telefoneSelecionado[0].exclusivoDoCliente
      };

      listaDetelefones = [...listaDetelefones, telefoneAtualizar];
      listaDetelefones = listaDetelefones.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        telefones: listaDetelefones,
      }
    }

    case REMOVE_PERFIL_PESSOA_LITE: {
      return {
        ...state,
        perfis: state.perfis.filter((perfil) => perfil.idTemporario !== action.payload),
      }
    }
    case REMOVE_TELEFONE_PESSOA: {

      return {
        ...state,
        telefones: state.telefones.filter((telefone) => telefone.idTemporario !== action.payload),
      }
    }
    case NOVO_EMAIL_PESSOA: {

      var retorno = action.payload;

      const idTemporario = state.emails.length + 1;

      const emailAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "email": '',
        "marcador": '',
        "exclusivoDoCliente": retorno
      }

      return {
        ...state,
        emails: [...state.emails, emailAdd],
      }
    }
    case ATUALIZA_EMAIL_PESSOA: {
      var retorno = action.payload;

      var listaDeEmails = state.emails.filter(x => x.idTemporario !== retorno.indice);
      var emailSelecionado = state.emails.filter(x => x.idTemporario === retorno.indice);

      var emailAtualizar = {
        "id": emailSelecionado[0].id,
        "idTemporario": emailSelecionado[0].idTemporario,
        "email": retorno.campoAtualizar === 'email' ? retorno.valor : emailSelecionado[0].email,
        "marcador": retorno.campoAtualizar === 'marcador' ? retorno.valor : emailSelecionado[0].marcador,
        "exclusivoDoCliente": emailSelecionado[0].exclusivoDoCliente
      };

      listaDeEmails = [...listaDeEmails, emailAtualizar];
      listaDeEmails = listaDeEmails.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        emails: listaDeEmails,
      }
    }
    case REMOVE_EMAIL_PESSOA: {

      return {
        ...state,
        emails: state.emails.filter((email) => email.idTemporario !== action.payload),
      }
    }
    case NOVO_COMPLEMENTO: {
      var retorno = action.payload;
      const idTemporario = state.complementosPessoa.length + 1;

      const complementoAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "tipoComplemento": retorno.tipoComplemento,
        "idReferencia": 0,
        "idResponsabilidade": 0,
        "idLocalControlado": retorno.local,
      }

      return {
        ...state,
        complementosPessoa: [...state.complementosPessoa, complementoAdd],
      }
    }
    case ATUALIZA_COMPLEMENTO: {
      var retorno = action.payload;

      var listaDeComplementos = state.complementosPessoa.filter(x => x.idTemporario !== retorno.indice);
      var complementoSelecionado = state.complementosPessoa.filter(x => x.idTemporario === retorno.indice);

      var complementoAtualizar = {
        "id": complementoSelecionado[0].id,
        "idTemporario": complementoSelecionado[0].idTemporario,
        "tipoComplemento": complementoSelecionado[0].tipoComplemento,
        "idReferencia": retorno.campoAtualizar === 'idReferencia' ? retorno.valor : complementoSelecionado[0].idReferencia,
        "idResponsabilidade": retorno.campoAtualizar === 'idResponsabilidade' ? retorno.valor : complementoSelecionado[0].idResponsabilidade,
        "idLocalControlado": complementoSelecionado[0].idLocalControlado,
      };

      listaDeComplementos = [...listaDeComplementos, complementoAtualizar];
      listaDeComplementos = listaDeComplementos.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        complementosPessoa: listaDeComplementos,
      }
    }
    case REMOVE_COMPLEMENTO: {

      return {
        ...state,
        complementosPessoa: state.complementosPessoa.filter((complemento) => complemento.idTemporario !== action.payload),
      }
    }
    case ADICIONA_VINCULO_PESSOA: {

      let dependenteAdd = null;
      let responsavelAdd = null;

      var retorno = action.payload;

      var pessoaCompleto = state.listGrid.filter(x => x.id === retorno.idPessoaAdicionar)[0];

      if (retorno.tipo === 'D') {
        const idTemporarioDependente = state.dependentes.length + 1;

        dependenteAdd = {
          "id": 0,
          "idPessoa": pessoaCompleto.id,
          "idTemporario": idTemporarioDependente,
          "nomeDependente": pessoaCompleto.nomePessoa,
          "cpfDependente": pessoaCompleto.dataNascimento === null ? cnpjMask(pessoaCompleto.numeroDocumento) : pessoaCompleto.numeroCpf === '' ? pessoaCompleto.numeroDocumento : cpfMask(pessoaCompleto.numeroCpf),
          "imagemDependente": pessoaCompleto.imagem,
          "visivelParaResponsavel": false,
        };
      }
      else {

        const idTemporarioResponsavel = state.responsaveis.length + 1;

        responsavelAdd = {
          "id": 0,
          "idPessoa": pessoaCompleto.id,
          "idTemporario": idTemporarioResponsavel,
          "nomeResponsavel": pessoaCompleto.nomePessoa,
          "cpfResponsavel": pessoaCompleto.dataNascimento === null ? cnpjMask(pessoaCompleto.numeroDocumento) : pessoaCompleto.numeroCpf === '' ? pessoaCompleto.numeroDocumento : cpfMask(pessoaCompleto.numeroCpf),
          "imagemResponsavel": pessoaCompleto.imagem,
          "VisivelParaResponsavel": false,
        };
      }

      return {
        ...state,
        dependentes: retorno.tipo === 'D' ? [...state.dependentes, dependenteAdd] : state.dependentes,
        responsaveis: retorno.tipo === 'R' ? [...state.responsaveis, responsavelAdd] : state.responsaveis,
        idDependenteAdicionar: 0,
        idResponsavelAdicionar: 0
      }
    }
    case REMOVER_VINCULO_PESSOA: {
      var retorno = action.payload;

      return {
        ...state,
        dependentes: retorno.tipo === 'D' ? state.dependentes.filter(x => x.idTemporario !== retorno.idRemover) : state.dependentes,
        responsaveis: retorno.tipo === 'R' ? state.responsaveis.filter(x => x.idTemporario !== retorno.idRemover) : state.responsaveis
      }
    }
    case ATUALIZAR_VINCULO_PESSOA: {
      var retorno = action.payload;
      var arrayAtualizado = [];
      var dependentesAtualizar = state.dependentes;


      for (var indice in dependentesAtualizar) {

        var indiceNumero = parseInt(indice);
        var dependente = dependentesAtualizar[indiceNumero];

        if (dependente.idTemporario === retorno.idAtualizacao) {
          dependente.visivelParaResponsavel = retorno.valor;
        }

        arrayAtualizado.push(dependente);
        arrayAtualizado = arrayAtualizado.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));
      }

      return {
        ...state,
        dependentes: arrayAtualizado,
      }
    }
    case EXIBE_DETALHE_ESTRUTURA_LOCAL: {
      return {
        ...state,
        exibeEskeleton: true,
      }
    }


    case EXIBE_DETALHE_ESTRUTURA_LOCAL_SUCESSO: {

      var retorno = action.payload;
      var localEstrutura = state.listaDeLocaisControlados.filter(x => x.id === retorno.local)[0].descricaoLocalControlado;
      var idsExpandidos = [];
      var ambiente = {};

      function RecolheIdsAmbienteSubAmbiente(ambientes) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];
          ambiente.usuarioPodeLiberar = state.listaAmbientesPessoa.filter(x => x.idAmbiente === ambiente.id).length > 0;

          if (ambiente.subAmbientes.length > 0) {
            idsExpandidos.push(ambiente.idTemporario);
            RecolheIdsAmbienteSubAmbiente(ambiente.subAmbientes);
          }
          else {
            idsExpandidos.push(ambiente.idTemporario);
          }
        }

      }

      RecolheIdsAmbienteSubAmbiente(retorno.arvoreLocal);

      return {
        ...state,
        arvoreLocal: retorno.arvoreLocal,
        localControladoEstrutura: localEstrutura,
        idLocalExibidoEstrutura: retorno.idAlterar,
        statusLocalExibidoEstrutura: retorno.status,
        exibeEskeleton: false,
        listaIdsTreeviewExpandidos: idsExpandidos,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {

      var campo = action.payload.target.name;
      var desmarcaIsencaoInscricaoEstadual = false;
      var desmarcaIsencaoInscricaoMunicipal = false;
      var desmarcaNumero = false;

      if (campo === "inscricaoEstadual")
      {
        if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
        {
          desmarcaIsencaoInscricaoEstadual = true;
        }
      }

      if (campo === "inscricaoMunicipal")
      {
        if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
        {
          desmarcaIsencaoInscricaoMunicipal = true;
        }
      }
      
      if (campo === "numero")
      {
        if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
        {
          desmarcaNumero = true;
        }
      }    

      return {
        ...state,
        isentoInscricaoEstadual: desmarcaIsencaoInscricaoEstadual === true ? false : state.isentoInscricaoEstadual,
        isentoInscricaoMunicipal: desmarcaIsencaoInscricaoMunicipal === true ? false : state.isentoInscricaoMunicipal,
        semNumero: desmarcaNumero === true ? false : state.semNumero,           
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_CHECK: {

      var check = action.payload.nomeCheck[0];
      var valor = action.payload.valor;
      var limpaInscricaoEstadual = false;
      var limpaInscricaoMunicipal = false;
      var limpaNumero = false;
      var iniciaCadastro = false;

      var campocpfAtualizado = '';

      if (check === "tipoPessoa")
      {
          iniciaCadastro = true;
      }      

      if (check === 'indiDocumentoEstrangeiro') {
        if (valor) {
          campocpfAtualizado = 'Nenhum';
        }
        else {
          campocpfAtualizado = '';
        }
      }

      if (check === "isentoInscricaoEstadual")
      {
        if(action.payload.valor === true)
        {
          limpaInscricaoEstadual = true;
        }
      }

      if (check === "isentoInscricaoMunicipal")
      {
        if(action.payload.valor === true)
        {
          limpaInscricaoMunicipal = true;
        }
      }   
      
      if (check === "semNumero")
      {
        if(action.payload.valor === true)
        {
          limpaNumero = true;
        }
      }    

      return {
        ...state,
        numeroCpf: check === 'indiDocumentoEstrangeiro' && campocpfAtualizado,
        idPais: check === 'indiDocumentoEstrangeiro' && !valor ? 0 : state.idPais,
        idPaisTipoDocumento: check === 'indiDocumentoEstrangeiro' && !valor ? 0 : state.idPaisTipoDocumento,
        numeroDocumentoEstrangeiro: check === 'indiDocumentoEstrangeiro' && !valor ? '' : state.numeroDocumentoEstrangeiro,        
        
        inscricaoEstadual: iniciaCadastro === true ? '' : limpaInscricaoEstadual === true ? '' : state.inscricaoEstadual,
        inscricaoMunicipal: iniciaCadastro === true ? '' : limpaInscricaoMunicipal === true ? '' : state.inscricaoMunicipal,
        numero: iniciaCadastro === true ? '' : limpaNumero === true ? '' : state.numero,

        nomePessoa: iniciaCadastro === true ? '' : state.nomePessoa,
        razaoSocial: iniciaCadastro === true ? '' : state.razaoSocial,
        nomeFanstasia: iniciaCadastro === true ? '' : state.nomeFanstasia,
        nomeContato: iniciaCadastro === true ? '' : state.nomeContato,      
        numeroCnpj: iniciaCadastro === true ? '' : state.numeroCnpj, 
        numeroCpf: iniciaCadastro === true ? '' : state.numeroCpf, 
        dataNascimentoOuAbertura: iniciaCadastro === true ? '' : state.dataNascimentoOuAbertura, 
        nomeMae: iniciaCadastro === true ? '' : state.nomeMae,
        sexo: iniciaCadastro === true ? '' : state.sexo,
        estadoCivil: iniciaCadastro === true ? '' : state.estadoCivil, 
        imagem: iniciaCadastro === true ? '' : state.imagem,
        cep: iniciaCadastro === true ? '' : state.cep,
        logradouro: iniciaCadastro === true ? '' : state.logradouro,
        complemento: iniciaCadastro === true ? '' : state.complemento,
        bairro: iniciaCadastro === true ? '' : state.bairro,
        uf: iniciaCadastro === true ? '' : state.uf,
        nomeUf: iniciaCadastro === true ? '' : state.nomeUf,
        idMunicipio: iniciaCadastro === true ? 0 : state.idMunicipio,
        nomeMunicipio: iniciaCadastro === true ? '' : state.nomeMunicipio,
        pontoReferencia: iniciaCadastro === true ? '' : state.pontoReferencia,
        telefoneUm: iniciaCadastro === true ? '' : state.telefoneUm,
        telefoneDois: iniciaCadastro === true ? '' : state.telefoneDois,
        email: iniciaCadastro === true ? '' : state.email,
        site: iniciaCadastro === true ? '' : state.site,
        telefones: iniciaCadastro === true ? state.telefonesNovo : state.telefones,
        emails: iniciaCadastro === true ? state.emailsNovo : state.emails,
        listaDeSexos: iniciaCadastro === true ? preparaListaParaCombo(generos, "id", "title") : state.listaDeSexos,    
        listaDeEstadosCivis: iniciaCadastro === true ? preparaListaParaCombo(estadosCivis, "id", "title") : state.listaDeEstadosCivis,         

        [action.payload.nomeCheck]: action.payload.valor,
      }
    }
    case ALTERACAO_COMBO: {

      var campoAlterado = action.payload.nomeCombo[0];
      var valor = action.payload.valor;
      var listagem = [];

      if (campoAlterado.includes('idPerfil_')) {
        let indexUnderline = campoAlterado.indexOf("_");
        let indiceAtualizacao = parseInt(campoAlterado.substring(indexUnderline + 1));
        let perfil = state.perfis[indiceAtualizacao]
        let listaperfis = state.perfis.filter(x => x.idTemporario != perfil.idTemporario)
        
        perfil.idPerfil = valor;
        perfil.nomePerfil = state.listaDePerfis.find(x => x.id == valor).title;

        listaperfis = [...listaperfis, perfil];
        listaperfis = listaperfis.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));
        return {
          ...state,
          perfis: listaperfis,
        }
      }

      if (campoAlterado === 'idPais') {

        var listaRetorno = state.listaDeDocumentosDePaisesCompleto.filter(x => x.idPais === valor);

        for (var indice in listaRetorno) {
          listagem.push({ id: listaRetorno[indice].id, nomeTipoDocumento: listaRetorno[indice].tipoDocumentoEstrangeiro.nomeTipoDocumentoEstrangeiro });
        }
      }

      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
        listaDeDocumentosDePaises: campoAlterado === 'idPais' ? preparaListaParaCombo(listagem, "id", "nomeTipoDocumento") : state.listaDeDocumentosDePaises
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE_2: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask2(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
