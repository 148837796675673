import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  FECHA_MODULOS_PORTARIA,
  SELECIONA_MODULOS_PORTARIA,
  IR_PARA_MODULO    
} from 'constants/ActionTypes';
import {GREEN} from 'constants/ThemeColors';

const rltLocale = ['ar'];
const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: GREEN,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  redirecionaInicio: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  selecionarModuloPortaria: false, 
  loginRecente: false,
  moduloSelecionado: '',
  locale: {
    languageId: 'portuguese',
    locale: 'pt',
    name: 'Português',
    icon: 'br'
  },
  configuracao: {}///JSON.parse(localStorage.getItem('cfg')),
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      };
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme
      };
    case SWITCH_LANGUAGE:

      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)

      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL

      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };


    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

      case FECHA_MODULOS_PORTARIA: {
        return {
          ...state,
          selecionarModuloPortaria: false,
        }
      }
      case SELECIONA_MODULOS_PORTARIA: {

        return {
          ...state,
          selecionarModuloPortaria: true,
          configuracao: action.payload.configuracao,
          loginRecente: action.payload.loginRecente
        }
      }        
      case IR_PARA_MODULO: {

        return {
          ...state,
          moduloSelecionado: action.payload.nomeModulo,
          selecionarModuloPortaria: false,
          redirecionaInicio: true,
          configuracao: action.payload.configuracao !== undefined ? action.payload.configuracao : state.configuracao,
          loginRecente: false
        }
      }

    default:
      return state;
  }
};

export default settings;
