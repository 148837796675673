import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
  INICIA_UNIDADE,
  NOVO_UNIDADE,
  BUSCA_UNIDADE_EDICAO,
  CANCELA_EDICAO,
  INICIO_UNIDADE_SUCESSO,
  SALVA_UNIDADE_SUCESSO,
  REMOVE_UNIDADE_SUCESSO,
  BUSCA_UNIDADE_EDICAO_SUCESSO,
  NOVO_ESCANINHO,
  ATUALIZA_ESCANINHO,
  REMOVE_ESCANINHO,
  NOVA_GARAGEM,
  ATUALIZA_GARAGEM,
  REMOVE_GARAGEM,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CHECK,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  LIMPAR_UNIDADE,
  LISTA_UNIDADE_SUCESSO,
  LISTA_UNIDADE,
  INICIO_CENARIO_UNIDADE_LOCAL,
  INICIO_CENARIO_UNIDADE_LOCAL_SUCESSO,
  APLICA_FILTRO
} from "constants/ActionTypes";
    
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo Unidade', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
      { titulo: 'Bloco', propriedadeApi: 'descricaoGrupo', alinhamento: 'left' },
      { titulo: 'Sala', propriedadeApi: 'descricaoUnidade', alinhamento: 'left' },
      { titulo: 'Complemento', propriedadeApi: 'complemento', alinhamento: 'left' },
      { titulo: 'Situação', propriedadeApi: 'situacaoUnidade', alinhamento: 'left' },
      { titulo: 'Garagens', propriedadeApi: 'qtdeGaragem', alinhamento: 'left' },
      { titulo: 'Escaninhos', propriedadeApi: 'descricaoEscaninho', alinhamento: 'left' },            
    ],    


  escaninhosNovo: [
    {
      id: 0,
      idTemporario: 0,
      descricao: '',
      ativo: true,
      }
  ],    

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],
  listGridComFiltro: [],
  rotuloGrupoUnidade: '',
  rotuloUnidadeUnidade: '',

  listaLocaisCompleta: [],

  // Campos do Formulario
  id: 0,
  descricaoGrupo: '',
  descricaoUnidade: '',
  complemento: '',
  situacaoUnidade: '',
  qtdeConvite: null,
  qtdeGaragem: null,
  idLocalControladoFixado: 0,
  nomeLocalControladoFixado: '',  
  escaninhos: [],
  garagens: [],  
  textoFiltro: '',

  // Combos
  listaDeLocaisControlados: [],
  listaDeLocaisControladosGeral: [],

  // Filtros
  modeloFiltro: '',
  numeroFiltro: '',
  situacaoFiltro: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_UNIDADE: {
      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        textoFiltro: '',
      }
    }
    case NOVO_UNIDADE: {
      return {
        ...state,
        edicao: true,
        id: 0,
        descricaoGrupo: '',
        descricaoUnidade: '',
        complemento: '',
        situacaoUnidade: '',
        qtdeConvite: null,
        qtdeGaragem: null,
        escaninhos: state.escaninhosNovo,
        garagens: state.garagens        
      }
    }
    case BUSCA_UNIDADE_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false,
        textoFiltro: '',
      }
    }
    case INICIO_UNIDADE_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        listaLocaisCompleta: retorno.locaisControlados.filter(x => x.descricaoRotuloGrupoUnidade !== '' && x.descricaoRotuloUnidadeUnidade !== ''),
        idLocalControladoFixado: 0,
        listaDeLocaisControladosGeral: retorno.locaisControlados,
        listaDeLocaisControlados: preparaListaParaCombo(retorno.locaisControlados.filter(x => x.descricaoRotuloGrupoUnidade !== '' && x.descricaoRotuloUnidadeUnidade !== ''), "id", "descricaoLocalControlado"),
      }
    }
    case APLICA_FILTRO: {

      const filtroAplicar = action.payload;
      var listaFiltrada = filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.descricaoGrupo.toLowerCase().includes(filtroAplicar.toLowerCase()) || x.descricaoUnidade.toLowerCase().includes(filtroAplicar.toLowerCase())) : [];

      return {
        ...state,
        textoFiltro: filtroAplicar,
        listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
      }
    }    
    case INICIO_CENARIO_UNIDADE_LOCAL: {
      const retorno = action.payload;

      return {
        ...state,
        idLocalControladoFixado: retorno.idLocal,
        carregandoLista: true,
        loader: true,
      }
    }
    case INICIO_CENARIO_UNIDADE_LOCAL_SUCESSO: {
      const retorno = action.payload;

      if (retorno.idLocal !== 0) {
        var localControlado = retorno.locaisControlados === null ? state.listaLocaisCompleta.filter(x => x.id === retorno.idLocal) : retorno.locaisControlados.filter(x => x.id === retorno.idLocal);
        state.rotuloGrupoUnidade = localControlado[0].descricaoRotuloGrupoUnidade;
        state.rotuloUnidadeUnidade = localControlado[0].descricaoRotuloUnidadeUnidade;
        state.nomeLocalControladoFixado = localControlado[0].descricaoLocalControlado;
      }

      let data = [...state.colunasGrid],
        indexGrupo = data.findIndex(r => r.propriedadeApi === "descricaoGrupo"),
        indexUnidade = data.findIndex(r => r.propriedadeApi === "descricaoUnidade") //finding which index should get the udpate

      data[indexGrupo].titulo = state.rotuloGrupoUnidade;
      data[indexUnidade].titulo = state.rotuloUnidadeUnidade;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        idLocalControladoFixado: retorno.idLocal,
        nomeLocalControladoFixado: state.nomeLocalControladoFixado,
        colunasGrid: data,
        listGrid: retorno.unidades,
        listaLocaisCompleta: retorno.locaisControlados === null ? state.listaLocaisCompleta.filter(x => x.descricaoRotuloGrupoUnidade !== '' && x.descricaoRotuloUnidadeUnidade !== '') : retorno.locaisControlados.filter(x => x.descricaoRotuloGrupoUnidade !== '' && x.descricaoRotuloUnidadeUnidade !== ''),
        listaDeLocaisControlados: retorno.locaisControlados === null ? state.listaDeLocaisControlados.filter(x => x.descricaoRotuloGrupoUnidade !== '' && x.descricaoRotuloUnidadeUnidade !== '') : preparaListaParaCombo(retorno.locaisControlados.filter(x => x.descricaoRotuloGrupoUnidade !== '' && x.descricaoRotuloUnidadeUnidade !== ''), "id", "descricaoLocalControlado")        
      }
    }

    case SALVA_UNIDADE_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false,
        idLocalControladoFixado: state.idLocalControladoFixado
      }
    }
    case REMOVE_UNIDADE_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case LISTA_UNIDADE: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_UNIDADE_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.unidade,
      }
    }
    case LIMPAR_UNIDADE: {

      var retorno = action.payload;

      return {
        ...state,
        modeloFiltro: '',
        numeroFiltro: '',
        situacaoFiltro: '',
      }
    }
    case BUSCA_UNIDADE_EDICAO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        descricaoGrupo: retorno.descricaoGrupo,
        descricaoUnidade: retorno.descricaoUnidade,
        complemento: retorno.complemento,
        situacaoUnidade: retorno.situacaoUnidade,
        qtdeConvite: retorno.qtdeConvite,
        qtdeGaragem: retorno.qtdeGaragem,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
      }
    }
    case NOVA_GARAGEM: {
      const idTemporario = state.garagens.length + 1;

      const garagemAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "descricao": '',
        "ativo": true,
      }        

      return {
        ...state,
        garagens: [...state.garagens, garagemAdd],
      }      
    }
    case ATUALIZA_GARAGEM: {
      var retorno = action.payload;

      var listaDeGaragens = state.garagens.filter(x => x.idTemporario !== retorno.indice);
      var garagemSelecionada = state.garagens.filter(x => x.idTemporario === retorno.indice);

      var garagemAtualizar = {
        "id": garagemSelecionada[0].id,
        "idTemporario": garagemSelecionada[0].idTemporario,
        "descricao": retorno.campoAtualizar === 'garagem' ? retorno.valor : garagemSelecionada[0].descricao,
        "ativo": true
      };           

      listaDeGaragens = [...listaDeGaragens, garagemAtualizar];
      listaDeGaragens = listaDeGaragens.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        garagens: listaDeGaragens,
      }
    }         
    case REMOVE_GARAGEM: {

      return {
        ...state,
        garagens: state.garagens.filter((garagem) => garagem.idTemporario !== action.payload),
      }
    }    
    case NOVO_ESCANINHO: {
      const idTemporario = state.escaninhos.length + 1;

      const escaninhoAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "descricao": '',
        "ativo": true,
      }        

      return {
        ...state,
        escaninhos: [...state.escaninhos, escaninhoAdd],
      }
    }
    case ATUALIZA_ESCANINHO: {
      var retorno = action.payload;

      var listaDeEscaninhos = state.escaninhos.filter(x => x.idTemporario !== retorno.indice);
      var escaninhoSelecionado = state.escaninhos.filter(x => x.idTemporario === retorno.indice);

      var escaninhoAtualizar = {
        "id": escaninhoSelecionado[0].id,
        "idTemporario": escaninhoSelecionado[0].idTemporario,
        "descricao": retorno.campoAtualizar === 'escaninho' ? retorno.valor : escaninhoSelecionado[0].descricao,
        "ativo": true
      };           

      listaDeEscaninhos = [...listaDeEscaninhos, escaninhoAtualizar];
      listaDeEscaninhos = listaDeEscaninhos.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        escaninhos: listaDeEscaninhos,
      }
    }    
    case REMOVE_ESCANINHO: {

      return {
        ...state,
        escaninhos: state.escaninhos.filter((escaninho) => escaninho.idTemporario !== action.payload),
      }
    }          
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_COMBO: {
      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CHECK: {
      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
  