import React, { Component, Fragment, useState } from 'react';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ListItemText from '@material-ui/core/ListItemText';
import Teste from '../util/botaoMenu';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import Avatar from '@material-ui/core/Avatar';

export default class Topo extends Component {

    render() {

/*         const pessoaSelecionada = this.props.pessoaSelecionada;
        var imagemTratada = pessoaSelecionada.imagem;
        imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null; */

        return (
            <Fragment>
                <Grid style={{marginTop: -25}} container spacing={3}>
                    <Grid item xs={9}>
                    <Avatar style={{ marginLeft: 25, float: 'left', backgroundColor: '#FFFFFF', color: '#05B59D' }}>
                                    <DesktopMacIcon /> 
                                </Avatar>
                            <Typography style={{ float: 'left', marginLeft: 10, textAlign: 'left', fontWeight: 700, fontSize: 24, fontFamily: 'Montserrat, sans-serif', color: '#FFFFFF' }}>
                                Nome do ponto de acesso
                            </Typography>
                    </Grid>
                </Grid>

            </Fragment>
        )
    }

}