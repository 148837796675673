import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { INICIA_DEFINICOES, INICIA_RELATORIO_ACESSO, INICIA_RELATORIO_PESSOAS_UNIDADE, GERA_RELATORIO_ACESSO, GERA_RELATORIO_PESSOAS_UNIDADE, ALTERACAO_COMBO_INICIO, ALTERAR_CLIENTE_EM_USO, INICIA_CARREGAMENTO_CONFIGURACAO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import { userSignOut } from "actions/Auth";
import {
  showAuthMessage,
  inicioDefinicoesSucesso, exibirMensagemErro, alternaClienteSucesso, inicioRelatorioAcessoSucesso, inicioRelatorioPessoasUnidadeSucesso, geraRelatorioAcessoSucesso, geraRelatorioPessoasUnidadeSucesso
} from "actions/Inicio";
import { listaCliente } from "./Cliente";
import moment from 'moment';


/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/

const getClientes = async () =>
  await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);

const getAmbientesPortaria = async () =>
await requisicaoApi.get('/util/ambientesPortaria')
    .then(resposta => resposta)
    .catch(error => error);

const getUnidades = async () =>
await requisicaoApi.get('/util/unidades')
    .then(resposta => resposta)
    .catch(error => error);    

const getRelatorioAcessos = async (idAmbiente, dataInicio, dataFim) =>
await requisicaoApi.get('/util/relatorios/acesso/' + idAmbiente + '/' + dataInicio + '/' + dataFim)
    .then(resposta => resposta)
    .catch(error => error);

const getClientesDoUsuario = async () =>
    await requisicaoApi.get('/util/clientesUsuario')
      .then(resposta => resposta)
      .catch(error => error);    

const getConfiguracao = async () =>
await requisicaoApi.get('/util/configUser')
  .then(resposta => resposta)
  .catch(error => error);    

const getClienteFixado = async () =>
  await requisicaoApi.get('/usuariosPortal/ClienteFixado')
    .then(resposta => resposta)
    .catch(error => error);

const atualizarCliente = async (idCliente, idUsuario) => await requisicaoApi.put('/usuariosPortal/ClienteFixado',
  {
    "idUsuario": idUsuario,
    "IdCliente": idCliente
  })
  .then(resposta => resposta)
  .catch(error => error);

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/

function* fnIniciaDefinicoes() {
  try {
    const clientes = yield call(getClientes);
    const clientesUsuario = yield call(getClientesDoUsuario);

    if (clientes.data) {
      const clienteFixado = yield call(getClienteFixado);

      if (clienteFixado.status === 200) {

        var teste = "teste";

        yield put(inicioDefinicoesSucesso(clientes.data, clienteFixado.data, clientesUsuario.data));
      }
    }
    else {
      if (clientes.response.status === 401 && clientes.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
        yield put(userSignOut());
      }
      else {
        yield put(showAuthMessage('Ocorreu um erro ao listar clientes'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnIniciaConfiguracao () {
  try {
    const configuracao = yield call(getConfiguracao);

    if (configuracao.data) {
      var teste = "teste";
/*       const clienteFixado = yield call(getClienteFixado);

      if (clienteFixado.status === 200) {

        var teste = "teste";

        yield put(inicioDefinicoesSucesso(clientes.data, clienteFixado.data));
      } */
    }
    else {
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnIniciaRelatorioAcesso () {
  try {
    const ambientesPortaria = yield call(getAmbientesPortaria);

    if (ambientesPortaria.data) {
        yield put(inicioRelatorioAcessoSucesso(ambientesPortaria.data));
    }
    else {
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnIniciaRelatorioPessoasUnidade () {
  try {
    const unidades = yield call(getUnidades);

    if (unidades.data) {
        yield put(inicioRelatorioPessoasUnidadeSucesso(unidades.data));
    }
    else {
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnGeraRelatorioAcesso ({ payload }) {

  const idAmbiente = payload.idAmbiente;
  var dataInicio = payload.dataInicio;
  var dataFim = payload.dataFim;
  
  try {
    const resultado = yield call(getRelatorioAcessos, idAmbiente, moment(dataInicio).format("YYYY-MM-DD"), moment(dataFim).format("YYYY-MM-DD"));

    if (resultado.data) {
        yield put(geraRelatorioAcessoSucesso(resultado.data));
    }
    else {
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnGeraRelatorioPessoasUnidade ({ payload }) {

  const idAmbiente = payload.idAmbiente;
  var dataInicio = payload.dataInicio;
  var dataFim = payload.dataFim;
  
  try {
    const resultado = yield call(getRelatorioAcessos, idAmbiente, moment(dataInicio).format("YYYY-MM-DD"), moment(dataFim).format("YYYY-MM-DD"));

    if (resultado.data) {
        yield put(geraRelatorioPessoasUnidadeSucesso(resultado.data));
    }
    else {
      yield put(userSignOut());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnAlterarCliente({ payload }) {
  try {
    const idCliente = payload.valor;
    const idUsuario = localStorage.getItem('user_id');

    var resultado = "";

    if (idCliente !== 0) {
      const retorno = yield call(atualizarCliente, idCliente, idUsuario);
      resultado = retorno;
    }

    if (!resultado.data) {
      yield put(exibirMensagemErro("Ocorreu um erro interno."));
    } else {
      yield put(alternaClienteSucesso(resultado.data.configuracaoAcessoPortalUsuario));
    }


  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnLimpaCliente() {
  try {
    const idCliente = "0";
    const idUsuario = localStorage.getItem('user_id');

    var resultado = "";

    if (idCliente !== 0) {
      const retorno = yield call(atualizarCliente, idCliente, idUsuario);

      if (retorno.status === 200) {
        localStorage.removeItem('imgClienteFixado');
      }

      resultado = retorno;
    }

    if (!resultado.data) {
      yield put(exibirMensagemErro("Ocorreu um erro interno."));
    }

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/

export function* iniciaDefinicoes() {
  yield takeEvery(INICIA_DEFINICOES, fnIniciaDefinicoes);
}

export function* alterarCliente() {
  yield takeEvery(ALTERACAO_COMBO_INICIO, fnAlterarCliente);
}

export function* limpaCliente() {
  yield takeEvery(ALTERAR_CLIENTE_EM_USO, fnLimpaCliente);
}

export function* iniciaConfiguracao() {
  yield takeEvery(INICIA_CARREGAMENTO_CONFIGURACAO, fnIniciaConfiguracao);
}

export function* iniciaRelatorioAcesso() {
  yield takeEvery(INICIA_RELATORIO_ACESSO, fnIniciaRelatorioAcesso);
}

export function* iniciaRelatorioPessoasUnidade() {
  yield takeEvery(INICIA_RELATORIO_PESSOAS_UNIDADE, fnIniciaRelatorioPessoasUnidade);
}

export function* geraRelatorioAcesso() {
  yield takeEvery(GERA_RELATORIO_ACESSO, fnGeraRelatorioAcesso);
}

export function* geraRelatorioPessoasUnidade() {
  yield takeEvery(GERA_RELATORIO_PESSOAS_UNIDADE, fnGeraRelatorioPessoasUnidade);
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/

export default function* rootSaga() {
  yield all([fork(iniciaDefinicoes), fork(alterarCliente), fork(limpaCliente), 
    fork(iniciaConfiguracao), fork(iniciaRelatorioAcesso), fork(geraRelatorioAcesso), fork(iniciaRelatorioPessoasUnidade), fork(geraRelatorioPessoasUnidade)]);
}
