import {
    INICIA_ACESSO_GENERO,
    SALVA_ACESSO_GENERO,
    REMOVE_ACESSO_GENERO,
    NOVO_ACESSO_GENERO,
    BUSCA_ACESSO_GENERO_EDICAO,
    INICIO_ACESSO_GENERO_SUCESSO,
    LISTA_CONFIGURACAO_PONTO_GENERO,
    LISTA_CONFIGURACAO_PONTO_GENERO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_GENERO,
    LISTA_LOCAIS_SUCESSO,
    ALTERACAO_CHECK_PONTO_ACESSO_GENERO,
    SALVA_ACESSO_GENERO_SUCESSO,
    REMOVE_ACESSO_GENERO_SUCESSO,
    BUSCA_ACESSO_GENERO_EDICAO_SUCESSO,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    LISTA_ACESSO_GENERO,
    LISTA_ACESSO_GENERO_SUCESSO,
    LIMPAR_ACESSO_GENERO,
    ADICIONA_PERFIL_LISTA_VINCULADA_GENERO,
    REMOVE_PERFIL_LISTA_VINCULADA_GENERO
  } from 'constants/ActionTypes';
  
  /*#############################################################################################*/
  /* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/
  
  export const iniciaAcessoGenero = () => {
    return {
      type: INICIA_ACESSO_GENERO
    };
  };
  
  export const salvaAcessoGenero = (acessoGenero) => {
    var listaPontosAssociados = [];

    if(acessoGenero.configuracaoPontoGenero != null && acessoGenero.configuracaoPontoGenero.listaAmbientesVinculados != null)
    {
    for (var i = 0; i < acessoGenero.configuracaoPontoGenero.listaAmbientesVinculados.length; i++) {
      var listaPontos = acessoGenero.configuracaoPontoGenero.listaAmbientesVinculados[i].pontosAcessoVinculados;

      for (var j = 0; j < listaPontos.length; j++) {
          listaPontosAssociados.push({"idCriterio": 0, "idPontoAcesso":  listaPontos[j].idPontoAcesso, "id": 0, ativo: listaPontos[j].associado })
      }
    }
   }

    return {
      type: SALVA_ACESSO_GENERO,
      payload: { acessoGenero, listaPontosAssociados } 
    };
  };
  
  export const removeAcessoGenero = (id) => {
    return {
      type: REMOVE_ACESSO_GENERO,
      payload: id
    };
  };

  export const listaAcessoGenero = (genero, ambiente, ponto) => {
    if(genero == undefined){
      genero = "";
    }
  
    if(ambiente == undefined){
      ambiente = "";
    }
  
    if(ponto == undefined){
      ponto = "";
    }
  
    return {
      type: LISTA_ACESSO_GENERO,
      payload: {genero, ambiente, ponto}
    };
  };

  export const adicionaPerfilListaVinculada = (idPerfil) => {

    return {
      type: ADICIONA_PERFIL_LISTA_VINCULADA_GENERO,
      payload: { idPerfil }
      };
  };
  
  export const removePerfilListaVinculada = (idTemporario) => {
    return {
      type: REMOVE_PERFIL_LISTA_VINCULADA_GENERO,
      payload: { idTemporario }
    };
  };  
  
  /*#############################################################################################*/
  /* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/
  
  export const novoAcessoGenero = () => {
    return {
      type: NOVO_ACESSO_GENERO
    };
  };
  
  export const buscaAcessoGeneroEdicao = (id) => {
    return {
      type: BUSCA_ACESSO_GENERO_EDICAO,
      payload: id
    };
  };
  
  /*#############################################################################################*/
  /* --------------------------- Actions de respostas actions anteriores ------------------------*/  

  export const listaLocaisPorCliente = (name, object, value) => {
    var idCliente = value !== null ? value.id : 0;

    return {
      type: LISTA_LOCAIS_POR_CLIENTE_GENERO,
      payload: { idCliente }
    }
  }

  export const listaConfiguracaoPontosPorGenero = (idLocal, sexo) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_GENERO,
      payload: { idLocal, sexo }
    }
  }

  export const onChangeCheckPontoAcesso = (object, value) => {
    var nomeCheck = [object.target.id].toString();
    var idPtoAcesso = nomeCheck.replace(/pontoAcesso_/g, "");

    return {
        type: ALTERACAO_CHECK_PONTO_ACESSO_GENERO,
        payload: { idPontoAcessoAlterado: idPtoAcesso, valor: value }
    }   
};   
  
  export const inicioAcessoGeneroSucesso = (acessosGenero, clientes, locais, perfis) => {
    return {
      type: INICIO_ACESSO_GENERO_SUCESSO,
      payload: { acessosGenero, clientes, locais, perfis }
    }
  };
  
  export const salvaAcessoGeneroSucesso = (mensagem) => {
    return {
      type: SALVA_ACESSO_GENERO_SUCESSO,
      payload: mensagem
    }
  };
  
  export const removeAcessoGeneroSucesso = (mensagem) => {
    return {
      type: REMOVE_ACESSO_GENERO_SUCESSO,
      payload: mensagem
    }
  };  

  export const listaConfiguracaoPontoGeneroSucesso = (configuracao) => {
    return {
      type: LISTA_CONFIGURACAO_PONTO_GENERO_SUCESSO,
      payload: { configuracao }
    }
  }
  
  export const buscaAcessoGeneroEdicaoSucesso = (acessoGenero) => {
    return {
      type: BUSCA_ACESSO_GENERO_EDICAO_SUCESSO,
      payload: acessoGenero
    };
  }; 

  export const listaLocaisSucesso = (locais, perfils) => {
    return {
      type: LISTA_LOCAIS_SUCESSO,
      payload: { locais, perfils }
    };
  };

  export const listaAcessoGeneroSucesso = (acessoGenero) => {
    return {
      type: LISTA_ACESSO_GENERO_SUCESSO,
      payload: { acessoGenero }
    };
  };
  
  export const limparFiltroAcessoGenero = () => {
    return {
      type: LIMPAR_ACESSO_GENERO,
    };
  };
  
  /*#############################################################################################*/
  /* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

  export const exibirMensagemErro = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };
  
  export const showAuthLoader = () => {
    return {
      type: ON_SHOW_LOADER,
    };
  };
  
  export const hideMessage = () => {
    return {
      type: HIDE_MESSAGE,
    };
  };
  
  export const showAuthMessage = (message) => {
    return {
      type: SHOW_MESSAGE,
      payload: message
    };
  };