import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

export default class AlternaModulos extends Component {

    render() {

        let configuracao = this.props.configuracao;
        let exibeFechar = this.props.exibeFechar;

        return (<Fragment>
            <div style={{ flexGrow: 1 }}>
                <Grid style={{ marginTop: 10, textAlign: 'right' }} container spacing={3}>
                    <Grid item xs={12}>
                        {
                            exibeFechar &&
                            <Button startIcon={<CloseIcon style={{ color: '#05B59D' }} />} color="primary" style={{ marginRight: 8, padding: '14px 36px', borderRadius: 100, borderWidth: 2 }} variant="outlined" onClick={() => { this.props.funcaoFechar(); }}>
                                <span style={{ fontStyle: 'normal', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold', fontSize: '14px', letterSpacing: '1.25px', textTransform: 'none' }}>Fechar</span>
                            </Button>
                        }

                    </Grid>
                </Grid>
            </div>

            <Grid style={{ minHeight: 330, marginTop: 10, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%' }} container spacing={3}>
                <Grid item>
                    <div style={{ cursor: 'pointer' }} onClick={() => { this.props.irParaModulo('Portaria', configuracao); }}>
                        <NavLink className="prepend-icon" to="/app/inicio">
                            <div style={{ height: '82%', width: 175, margin: 14 }} className="jr-card bg-gradient-primary text-white text-center ripple-effect">
                                <div className=" mt-xl-1">
                                    <i className="zmdi zmdi-account-box-o zmdi-hc-2x"></i>
                                </div>
                                <h5 className="text mb-xl-1">Portaria</h5>
                            </div>
                        </NavLink>
                    </div>
                </Grid>
                <Grid item>
                    <div style={{ cursor: 'pointer' }} onClick={() => { this.props.irParaModulo('Backoffice', configuracao) }}>
                        <NavLink className="prepend-icon" to="/app/inicio">
                            <div style={{ height: '82%', width: 175, margin: 14 }} className="jr-card bg-gradient-primary text-white text-center ripple-effect">
                                <div className=" mt-xl-1">
                                    <i className="zmdi zmdi-account-box-o zmdi-hc-2x"></i>
                                </div>
                                <h5 className="text mb-xl-1">Backoffice</h5>
                            </div>
                        </NavLink>
                    </div>
                </Grid>
                <Grid item>
                    <div style={{ cursor: 'pointer' }} >
                        <div style={{ height: '82%', width: 175, margin: 14 }} className="jr-card bg-gradient-primary text-white text-center ripple-effect">
                            <div className=" mt-xl-1">
                                <i className="zmdi zmdi-account-box-o zmdi-hc-2x"></i>
                            </div>
                            <h5 className="text mb-xl-1">Venda</h5>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
        );
    }
}