import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { tiposComunicacaoModelo } from '../app/constantes/Listas';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_MODELO,
    NOVO_MODELO,
    BUSCA_MODELO_EDICAO,
    CANCELA_EDICAO,
    INICIO_MODELO_SUCESSO,
    SALVA_MODELO_SUCESSO,
    REMOVE_MODELO_SUCESSO,
    BUSCA_MODELO_EDICAO_SUCESSO,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    LIMPAR_MODELO,
    LISTA_MODELO_SUCESSO,
    LISTA_MODELO   
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo Modelo', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
      { titulo: 'Descrição do Modelo', propriedadeApi: 'descricaoModelo', alinhamento: 'left' },
      { titulo: 'Nome do Fabricante', propriedadeApi: 'fabricante.nome', alinhamento: 'left' },
      { titulo: 'Tipos de Comunicação', propriedadeApi: 'tipoComunicacaoStr', alinhamento: 'left' },
    ],

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],

    // Campos do Formulario
    id: 0,
    descricaoModelo: '',
    idFabricante: 0,
    tipoComunicacao: '',
    
    // Combos
    listaDeFabricantes: [],
    listaDeTiposDeComunicacao: [],

     // Filtros
     nomeFiltro: '', 
     fabricanteFiltro: '', 
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_MODELO: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
        }
      }   
      case NOVO_MODELO: {
        return {
          ...state,
          edicao: true,
          id: 0,
          descricaoModelo: '',
          idFabricante: 0,
          tipoComunicacao: '',          
        }
      }       
      case BUSCA_MODELO_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false
        }
      }
      case INICIO_MODELO_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.modelos,
          listaDeFabricantes: preparaListaParaCombo(retorno.fabricantes, "id", "nome"),
          listaDeTiposDeComunicacao: preparaListaParaCombo(tiposComunicacaoModelo, "id", "title"),
        }
      }                     
      case SALVA_MODELO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_MODELO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_MODELO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_MODELO_SUCESSO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          listGrid: retorno.modelo,
        }
      }
      case LIMPAR_MODELO: {
  
        var retorno = action.payload;
  
        return {
          ...state,
          nomeFiltro: '', 
          fabricanteFiltro: '', 
        }
      }              
      case BUSCA_MODELO_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.id,
          descricaoModelo: retorno.descricaoModelo,
          idFabricante: retorno.idFabricante,
          tipoComunicacao: retorno.tipoComunicacao                      
        }
      }       
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  