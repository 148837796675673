import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PhoneIcon from '@material-ui/icons/Phone';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CommentIcon from '@material-ui/icons/Comment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { UilTruck } from '@iconscout/react-unicons'

export default class Perfil extends Component {


    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <TableContainer elevation={0} className={classes.padding} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>

                            <TableRow key={1}>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                    <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                        <AssignmentIndIcon style={{ color: '#05B59D' }} />
                                    </Avatar>
                                </TableCell>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#000000DE' }}>Documento</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#00000099' }}>CPF - 432.555.234-32</div>
                                    </div>
                                </TableCell>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div style={{ float: 'left' }}>
                                        <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                            <PhoneIcon style={{ color: '#05B59D' }} />
                                        </Avatar>
                                    </div>
                                    <div style={{ float: 'left' }}>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#000000DE' }}>Telefone</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#00000099' }}>(62) 94984-3245</div>
                                    </div>
                                </TableCell>

                            </TableRow>

                            <TableRow key={1}>
                                <TableCell align="left" style={{ fontSize: 16, fontWeight: 400, width: 60 }}>
                                    <Avatar style={{ width: 40, height: 40, backgroundColor: '#F2F2F2', marginRight: 5 }}>
                                        <UilTruck size={20} style={{ color: '#05B59D' }} />
                                    </Avatar>
                                </TableCell>
                                <TableCell colspan={2} align="left" style={{ fontSize: 16, fontWeight: 400 }}>
                                    <div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 14, color: '#000000DE' }}>Veículo</div>
                                        <div style={{ float: 'left', width: '100%', fontWeight: 400, fontSize: 16, color: '#00000099' }}>KHF-1234 (Volkswagen Gol Branco)</div>
                                    </div>
                                </TableCell>

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        )
    }

}