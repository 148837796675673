import {
  INICIA_PESSOAS,
  INICIA_LIBERACOES,
  INICIA_PORTARIA,
  INICIA_PORTARIA_LIBERACAO,
  INICIA_PORTARIA_LIBERACAO_SUCESSO,
  INICIA_PORTARIA_SUCESSO,
  SELECIONA_PESSOA_PORTARIA,
  SELECIONA_PESSOA_PORTARIA_SUCESSO,
  SELECIONA_LIBERACAO_PORTARIA,
  ALTERACAO_CAMPO,
  ALTERACAO_CAMPO_PORTARIA,
  SHOW_MESSAGE,
  BUSCA_SOLICITACAO,
  BUSCA_SOLICITACAO_SUCESSO,
  ATUALIZAR_PONTO_REQUISICAO_PORTEIRO,
  ATUALIZA_MODO_PORTARIA,
  ATUALIZA_MODO_PORTARIA_SUCESSO,
  EXECUTA_SIMULACAO_SUCESSO,
  DECIDIR_ATENDTO_DUPLO_CHECK,
  DECIDIR_ATENDTO_DUPLO_CHECK_SUCESSO,
  BUSCA_FILA_POSICAO,
  BUSCA_FILA_POSICAO_SUCESSO,
  ANALISAR_DETALHES_DC,
  SETAR_IMAGEM_LIBERACAO,
  CAPTURA_IMAGEM_LIBERACAO,
  SALVAR_LIBERACAO,
  SALVA_LIBERACAO_SUCESSO,
  ALTERA_VALOR_CHECK_LIBERACAO,
  ALTERA_DIA_SEMANA_GERAL,
  EXIBE_MENSAGEM_ERRO_PORTARIA,
  OCULTA_MENSAGEM_PORTARIA,
  NOVA_LIBERACAO,
  VOLTAR_INICIO_PORTARIA,
  ALTERA_PERIODO_VALIDADE_LIBERACAO,
  ALTERA_PERIODO_DIA_SEMANA_LIBERACAO,
  ALTERA_PERIODO_HORARIO_LIBERACAO,
  ATUALIZA_HORARIO_LIBERACAO,
  VALIDACAO_CAMPO,
  SINCRONIZAR_LIBERACAO,
  ADICIONA_OBSERVACAO_PESSOA_PERFIL,
  ADICIONA_OBSERVACAO_PESSOA_PERFIL_SUCESSO,
  EXIBE_OCULTA_OBSERVACOES_PERFIL_PESSOA,
  SETAR_ID_INTERVAL,
  LIMPA_VALIDACOES,
  ATUALIZA_DATA_PESQUISA_LIBERACAO,
  LIMPA_PESQUISA_LIBERACAO,
  ALTERACAO_CAMPO_PESQUISA_PESSOA_TESTE,
  BUSCA_PESSOA_CADASTRADA_LIBERACAO,
  BUSCA_PESSOA_CADASTRADA_LIBERACAO_SUCESSO,
  ABORTA_OPERACAO,
  FECHAR_PESSOA_EXISTENTE_LIBERACAO,
  ENVIA_SOLICITACAO_ABERTURA_TERMINAL,
  ENVIA_SOLICITACAO_ABERTURA_TERMINAL_SUCESSO,
  ENVIA_SOLICITACAO_ABERTURA_TERMINAL_ERRO,
  SINCRONIZACAO_PESSOA_ERRO,
  CANCELA_CAPTURA_IMAGEM_PORTARIA,
  OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA,
  OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA_SUCESSO,
  EXIBE_LOTACAO_GARAGENS,
  EXIBE_LOTACAO_GARAGENS_SUCESSO,
  ALTERACAO_CAMPO_PESQUISA_UNIDADE_GARAGEM,
  DESOCUPAR_VAGA_GARAGEM,
  APLICA_ALTERACOES_GARAGENS,
  APLICA_ALTERACOES_GARAGENS_SUCESSO
} from 'constants/ActionTypes';
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import React, { Fragment } from 'react';
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaTimesCircle } from "@react-icons/all-files/fa/FaTimesCircle";
import { ValidaCampoObrigatorio, ValidaCampoObrigatorioCombo } from 'luxFw/transformations/Validations';
import moment from 'moment';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais ---- -----------------------------------*/

export const iniciaPortaria = () => {
  return [
    {
      type: INICIA_PORTARIA
    }]
}

export const setarIdInterval = (idInterval) => {
  return [
    {
      type: SETAR_ID_INTERVAL,
      payload: idInterval
    }]
}

export const iniciaPessoas = () => {
  return [{
    type: INICIA_PESSOAS
  },
  {
    type: INICIA_PORTARIA,
    payload: { selecionaModoTrabalho: false }
  }]
}

export const obtemImagemArrayPessoasPortariaSucesso = (imagensThumbs) => {

  return [
    {
      type: OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA_SUCESSO,
      payload: { imagensThumbs: imagensThumbs }
    },
  ];
}

export const selecionaPessoa = (idPessoa, pontos) => {

  var listaDePontos = '';

  for (var indice in pontos) {
    var pontoAcesso = pontos[indice];

    listaDePontos = listaDePontos + "," + pontoAcesso.idPontoAcesso;
  }

  listaDePontos = "@" + listaDePontos;
  listaDePontos = listaDePontos.replace("@,", "");
  listaDePontos = listaDePontos.replace("@", "");

  return {
    type: SELECIONA_PESSOA_PORTARIA,
    payload: { idPessoa, listaDePontos }
  };
}

export const selecionaLiberacao = (idLiberacao) => {
  return {
    type: SELECIONA_LIBERACAO_PORTARIA,
    payload: { idLiberacao }
  };
}

export const exibeOcultaObservacoesPerfilPessoa = () => {
  return {
    type: EXIBE_OCULTA_OBSERVACOES_PERFIL_PESSOA,
    payload: null
  };
}

export const adicionaObservacaoPessoaPerfil = (observacao, idPessoa) => {

  if (observacao === '') {
    return {
      type: EXIBE_OCULTA_OBSERVACOES_PERFIL_PESSOA,
      payload: null
    };
  }
  else {
    return {
      type: ADICIONA_OBSERVACAO_PESSOA_PERFIL,
      payload: { observacao, idPessoa }
    }
  }
}

export const enviaSolicitacaoAberturaTerminal = (idPonto, idPessoa) => {
  return {
    type: ENVIA_SOLICITACAO_ABERTURA_TERMINAL,
    payload: { idPonto, idPessoa }
  }
}

export const buscaSolicitacao = (idPosicao) => {

  return {
    type: BUSCA_SOLICITACAO,
    payload: { idPosicao }
  };
}

export const exibeLotacaoGaragem = () => {
  return {
    type: EXIBE_LOTACAO_GARAGENS,
    payload: null
  };
}

export const aplicaAlteracoesGaragens = (lotacoesGaragemDesocupar) => {
  return {
    type: APLICA_ALTERACOES_GARAGENS,
    payload: { lotacoesGaragemDesocupar }
  };
}

export const aplicaAlteracoesGaragensSucesso = () => {

  toast.success(<ShowMessage titulo="Sucesso" mensagem="Alterações realizadas com sucesso!" Icone={<FaCheckCircle size={26} />} />, { transition: Bounce, position: toast.POSITION.BOTTOM_LEFT });

  return {
    type: APLICA_ALTERACOES_GARAGENS_SUCESSO,
    payload: null
  };
}

export const atualizaPontoRequisicaoPorteiro = (object, value) => {

  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZAR_PONTO_REQUISICAO_PORTEIRO,
    payload: { idAtualizacao, valor }
  }
}

export const buscaFilaPosicaoSucesso = (retorno) => {
  return {
    type: BUSCA_FILA_POSICAO_SUCESSO,
    payload: retorno
  }
}

export const buscaSolicitacaoSucesso = (retorno) => {

  if (retorno.buscarSolicitacao) {
    return [
      {
        type: BUSCA_FILA_POSICAO,
        payload: retorno
      },
      {
        type: BUSCA_SOLICITACAO_SUCESSO,
        payload: retorno
      }];
  }
  else {
    return {
      type: BUSCA_SOLICITACAO_SUCESSO,
      payload: retorno
    };
  }
}

export const selecionaPessoaPortariaSucesso = (idPessoa, listas) => {

  return {
    type: SELECIONA_PESSOA_PORTARIA_SUCESSO,
    payload: { idPessoa, listas }
  };

}

export const buscaPEssoaCadastradaLiberacaoSucesso = (pessoaExistente) => {

  return {
    type: BUSCA_PESSOA_CADASTRADA_LIBERACAO_SUCESSO,
    payload: { pessoaExistente }
  };

}

export const enviaSolicitacaoAberturaTerminalSucesso = (resultado) => {

  toast.success(<ShowMessage titulo="Sucesso" mensagem="Terminal liberado com sucesso" Icone={<FaCheckCircle size={26} />} />, { transition: Bounce });

  return {
    type: ENVIA_SOLICITACAO_ABERTURA_TERMINAL_SUCESSO,
    payload: resultado
  };

}

export const enviaSolicitacaoAberturaTerminalErro = () => {

  toast.error(<ShowMessage titulo="Erro" mensagem="Não foi possível concluir a liberação do terminal!" icone={<FaTimesCircle size={26} />} />, { transition: Bounce });

  return {
    type: ENVIA_SOLICITACAO_ABERTURA_TERMINAL_ERRO,
    payload: null
  };

}

export const inicioPortariaLiberacaoSucesso = (usuarios, liberacoes) => {

  return {
    type: INICIA_PORTARIA_LIBERACAO_SUCESSO,
    payload: { usuarios, liberacoes }
  };
}

export const abortaOperacao = () => {
  return {
    type: ABORTA_OPERACAO,
    payload: null
  };
};

export const fecharPessoaExistente = () => {
  return {
    type: FECHAR_PESSOA_EXISTENTE_LIBERACAO,
    payload: null
  }
}

export const cancelaCapturaImagemPortaria = (tipo) => {
  return {
    type: CANCELA_CAPTURA_IMAGEM_PORTARIA,
    payload: tipo
  }
}

export const adicionaObservacaoPerfilSucesso = (observacao) => {
  return {
    type: ADICIONA_OBSERVACAO_PESSOA_PERFIL_SUCESSO,
    payload: { observacao }
  };
}

export const executaSimulacaoSucesso = (resposta, idPonto) => {

  resposta = resposta === 'true';

  return {
    type: EXECUTA_SIMULACAO_SUCESSO,
    payload: { resposta, idPonto }
  };
}

export const decidirDuploCheck = (idAtendto, permitido) => {
  return {
    type: DECIDIR_ATENDTO_DUPLO_CHECK,
    payload: { idAtendto, permitido }
  };
}

export const desocuparVagaGaragem = (idUnidade, idLotacaoGaragem) => {
  return {
    type: DESOCUPAR_VAGA_GARAGEM,
    payload: { idUnidade, idLotacaoGaragem }
  };
}

export const setarImagemLiberacao = (tipo, imagem, imagemThumb) => {
  return {
    type: SETAR_IMAGEM_LIBERACAO,
    payload: { tipo, imagem, imagemThumb }
  };
}

export const capturaImagemLiberacao = (tipo) => {
  return {
    type: CAPTURA_IMAGEM_LIBERACAO,
    payload: { tipo }
  };
}

export const salvarLiberacao = (liberacao) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatoriosLiberacao(liberacao));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    return {
      type: SALVAR_LIBERACAO,
      payload: liberacao
    }
  }
  else {

    toast.error(<ShowMessage titulo="Erro" mensagem="Existem inconsistências. Verifique!" icone={<FaTimesCircle size={26} />} />, { transition: Bounce });

    var exibeMensagem = {
      type: EXIBE_MENSAGEM_ERRO_PORTARIA,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);

    return validacoes;
  }
}

export const sincronizarLiberacao = (liberacao) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatoriosSincronizacaoLiberacao(liberacao));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    return {
      type: SINCRONIZAR_LIBERACAO,
      payload: liberacao
    }
  }
  else {

    toast.error(<ShowMessage titulo="Erro" mensagem="Existem inconsistências. Verifique!" icone={<FaTimesCircle size={26} />} />, { transition: Bounce });

    var exibeMensagem = {
      type: EXIBE_MENSAGEM_ERRO_PORTARIA,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);

    return validacoes;
  }
}

function RetornosArrayValidacoesCamposObrigatoriosLiberacao(liberacao) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorioCombo('descricaoNomeRegistroPrevio', liberacao.descricaoNomeRegistroPrevio));
  validacoes.push(ValidaCampoObrigatorioCombo('idPerfilSistema', liberacao.idPerfilSistema));
  validacoes.push(ValidaCampoObrigatorioCombo('idAmbienteOrigem', liberacao.idAmbienteOrigem));

  if (liberacao.listaAmbientesDestino.length > 0) {
    validacoes.push(ValidaCampoObrigatorioCombo('idAmbienteDestino', liberacao.idAmbienteDestino));
  }

  return validacoes;
}

function RetornosArrayValidacoesCamposObrigatoriosSincronizacaoLiberacao(liberacao) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorioCombo('descricaoNomeRegistroPrevio', liberacao.descricaoNomeRegistroPrevio));
  validacoes.push(ValidaCampoObrigatorioCombo('idPerfilSistema', liberacao.idPerfilSistema));
  validacoes.push(ValidaCampoObrigatorioCombo('idAmbienteOrigem', liberacao.idAmbienteOrigem));
  validacoes.push(ValidaCampoObrigatorioCombo('imagemCapturadaPessoa', liberacao.imagemCapturadaPessoa));

  if (liberacao.listaAmbientesDestino.length > 0) {
    validacoes.push(ValidaCampoObrigatorioCombo('idAmbienteDestino', liberacao.idAmbienteDestino));
  }

  return validacoes;
}

export const alteraPeriodoValidadeLiberacao = (periodo) => {
  return {
    type: ALTERA_PERIODO_VALIDADE_LIBERACAO,
    payload: periodo
  };
}

export const alteraPeriodoDiaSemanaLiberacao = (periodo, visibilidadeDias) => {
  return {
    type: ALTERA_PERIODO_DIA_SEMANA_LIBERACAO,
    payload: periodo, visibilidadeDias
  };
}

export const alteraPeriodoHorarioLiberacao = (periodo) => {
  return {
    type: ALTERA_PERIODO_HORARIO_LIBERACAO,
    payload: periodo
  };
}

export const atualizaHorario = (e) => {
  var key = e.which || e.keyCode || e.charCode;
  var campo = e.target.name;
  var valor = e.target.value;
  var removeDoisPontos = e.target.defaultValue.replace(e.target.value, '') === ':';

  if (!removeDoisPontos && valor.length === 2) {
    valor = valor + ":";
  }

  return {
    type: ATUALIZA_HORARIO_LIBERACAO,
    payload: { campoAtualizar: campo, valor: valor }
  };
};

export const novaLiberacao = () => {
  return [{
    type: NOVA_LIBERACAO,
    payload: null
  },
  {
    type: LIMPA_VALIDACOES,
    payload: null
  }];
}

export const voltarInicioPortaria = () => {
  return {
    type: VOLTAR_INICIO_PORTARIA,
    payload: null
  };
}

export const atualizaDataPesquisaLiberacao = (incrementador) => {
  return {
    type: ATUALIZA_DATA_PESQUISA_LIBERACAO,
    payload: incrementador
  };
}

export const limpaPesquisaLiberacao = () => {
  return {
    type: LIMPA_PESQUISA_LIBERACAO,
    payload: null
  };
}

export const analisarDetalhesDC = (idAtendtoDC, imagemAnaliseDC) => {
  return {
    type: ANALISAR_DETALHES_DC,
    payload: { idAtendtoDC, imagemAnaliseDC }
  };
}

export const atualizaModoPortaria = (modo, idAmbientePortaria, idPosicaoPortaria, pontosAcessoSelecionados) => {

  var validacoes = [];

  validacoes.push.apply(validacoes, RetornosArrayValidacoesCamposObrigatorios(idAmbientePortaria));

  var errosEncontrados = validacoes.filter(x => x.payload.erro);

  if (errosEncontrados.length === 0) {
    var _atualizaModoPortaria = {
      type: ATUALIZA_MODO_PORTARIA,
      payload: { modo, idAmbientePortaria, idPosicaoPortaria, pontosAcessoSelecionados }
    }

    validacoes.push(_atualizaModoPortaria);
  }
  else {
    var exibeMensagem = {
      type: SHOW_MESSAGE,
      payload: 'Existem inconsistências. Verifique!'
    }

    validacoes.push(exibeMensagem);
  }

  return validacoes;
}

function RetornosArrayValidacoesCamposObrigatorios(idAmbientePortariaSelecionado) {

  var validacoes = [];

  validacoes.push(ValidaCampoObrigatorioCombo('idAmbientePortariaSelecionado', idAmbientePortariaSelecionado));

  return validacoes;
}

export const atualizaModoSucesso = (modo, idPosicaoPortaria) => {


  return {
    type: ATUALIZA_MODO_PORTARIA_SUCESSO,
    payload: { modo, idPosicaoPortaria }
  }

}

const ShowMessage = (props) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ width: '15%', float: 'left' }}>
      {props.icone}
    </div>
    <div style={{ width: '85%', float: 'left' }}>
      <div style={{ fontWeight: 600 }}> {props.titulo}</div>
      <div style={{ fontWeight: 400 }}> {props.mensagem} </div>
    </div>
  </div>
)

export const salvaLiberacaoSucesso = (mensagem, liberacao, idPessoa, pontos) => {

  var listaDePontos = '';

  for (var indice in pontos) {
    var pontoAcesso = pontos[indice];

    listaDePontos = listaDePontos + "," + pontoAcesso.idPontoAcesso;
  }

  listaDePontos = "@" + listaDePontos;
  listaDePontos = listaDePontos.replace("@,", "");
  listaDePontos = listaDePontos.replace("@", "");

  toast.success(<ShowMessage titulo="Sucesso" mensagem="Dados salvos com sucesso!" icone={<FaCheckCircle size={26} />} />, { transition: Bounce });

  if (idPessoa !== null && idPessoa !== undefined && idPessoa !== "") {

    return [{
      type: SALVA_LIBERACAO_SUCESSO,
      payload: { mensagem, liberacao }
    },
    {
      type: SELECIONA_PESSOA_PORTARIA,
      payload: { idPessoa, listaDePontos }
    }
    ]
  }
  else {
    return [{
      type: SALVA_LIBERACAO_SUCESSO,
      payload: { mensagem, liberacao }
    },
    {
      type: SELECIONA_PESSOA_PORTARIA,
      payload: { idPessoa, listaDePontos }
    }
    ]
  }
};

export const analisaResultadoSincronizacao = (mensagem, liberacao, idPessoa, pontos) => {

  if (liberacao.tentativaSincronizacaoMalSucedida) {
    sincronizaPessoaErro(mensagem, liberacao, idPessoa, pontos);
  }
  else {
    sincronizaLiberacaoSucesso(mensagem, liberacao, idPessoa, pontos);
  }

}

export const exibeLotacaoGaragensSucesso = (lotacaoGaragens) => {

  return {
    type: EXIBE_LOTACAO_GARAGENS_SUCESSO,
    payload: { lotacaoGaragens }
  }
}

export const sincronizaLiberacaoSucesso = (mensagem, liberacao, idPessoa, pontos) => {

  var listaDePontos = '';

  for (var indice in pontos) {
    var pontoAcesso = pontos[indice];

    listaDePontos = listaDePontos + "," + pontoAcesso.idPontoAcesso;
  }

  listaDePontos = "@" + listaDePontos;
  listaDePontos = listaDePontos.replace("@,", "");
  listaDePontos = listaDePontos.replace("@", "");

  toast.success(<ShowMessage titulo="Sucesso" mensagem="Sincronização realizada com sucesso!" icone={<FaCheckCircle size={26} />} />, { transition: Bounce });

  if (idPessoa !== null && idPessoa !== undefined && idPessoa !== "") {

    return [{
      type: SALVA_LIBERACAO_SUCESSO,
      payload: { mensagem, liberacao }
    },
    {
      type: SELECIONA_PESSOA_PORTARIA,
      payload: { idPessoa, listaDePontos }
    }
    ]
  }
  else {
    return [{
      type: SALVA_LIBERACAO_SUCESSO,
      payload: { mensagem, liberacao }
    },
    {
      type: SELECIONA_PESSOA_PORTARIA,
      payload: { idPessoa, listaDePontos }
    }
    ]
  }
};

export const sincronizaPessoaErro = (mensagem, liberacao, idPessoa, pontos) => {

  if (liberacao !== null && liberacao !== undefined && liberacao.resultadoSincronizacao.canceladaPeloUzerpass && !liberacao.resultadoSincronizacao.solicitacaoLida) {
    toast.error(<ShowMessage titulo="Erro" mensagem="Sincronização não concluída! Destino inacessível" icone={<FaTimesCircle size={26} />} />, { transition: Bounce });
  }
  else if (liberacao !== null && liberacao !== undefined && liberacao.resultadoSincronizacao.canceladaPeloUzerpass && liberacao.resultadoSincronizacao.solicitacaoLida) {
    toast.error(<ShowMessage titulo="Erro" mensagem="Sincronização não concluída! Tempo limite atingido" icone={<FaTimesCircle size={26} />} />, { transition: Bounce, autoClose: 10000 });
  }
  else if (liberacao !== null && liberacao !== undefined &&
    liberacao.resultadoSincronizacao.listaExecucoesTerminais.filter(x => x.executadoComSucesso).length > 0 &&
    liberacao.resultadoSincronizacao.listaExecucoesTerminais.filter(x => !x.executadoComSucesso).length > 0) {
      toast.success(<ShowMessage titulo="Liberação" mensagem="A liberação foi salva com sucesso" icone={<FaTimesCircle size={26} />} />, { transition: Bounce, autoClose: 10000 });
    toast.warning(<ShowMessage titulo="Sincronização Parcial" mensagem="Apenas alguns aparelhos foram sincronizados" icone={<FaTimesCircle size={26} />} />, { transition: Bounce, autoClose: 10000 });
  }
  else if (liberacao !== null && liberacao !== undefined &&
    liberacao.resultadoSincronizacao.listaExecucoesTerminais.filter(x => x.executadoComSucesso).length == 0 &&
    liberacao.resultadoSincronizacao.listaExecucoesTerminais.filter(x => !x.executadoComSucesso).length > 0) {
    toast.error(<ShowMessage titulo="Falha na sincronização" mensagem="Não foi possível sincronizar em nenhum ponto. Verifique a foto e caso o problema persita acione o Suporte da Uzer" icone={<FaTimesCircle size={26} />} />, { transition: Bounce, autoClose: 10000 });
  }
  else {
    toast.error(<ShowMessage titulo="Erro" mensagem="Sincronização não concluída! Ocorreu um erro não esperado" icone={<FaTimesCircle size={26} />} />, { transition: Bounce, autoClose: 10000 });
  }

  return {
    type: SINCRONIZACAO_PESSOA_ERRO,
    payload: { liberacao }
  }
}

export const alteraValorCheckLiberacao = (campo) => {
  return {
    type: ALTERA_VALOR_CHECK_LIBERACAO,
    payload: { campo }
  }
}

export const alteraDiaSemanaGeral = (diasOcultosDesmarcar) => {
  return {
    type: ALTERA_DIA_SEMANA_GERAL,
    payload: diasOcultosDesmarcar
  }
}

export const consultaCadastroExistenteLiberacao = (props) => {

  var validacoes = [];

  var cpfValido = isValidCPF(props.descricaoNumeroCPFRegistroPrevio);

  if (cpfValido) {
    let cpfAjustado = props.descricaoNumeroCPFRegistroPrevio;

    if (cpfAjustado !== undefined && cpfAjustado !== null && cpfAjustado !== '') {
      cpfAjustado = cpfAjustado.replace(/[^0-9]/g, "");

      return {
        type: BUSCA_PESSOA_CADASTRADA_LIBERACAO,
        payload: { cpfPessoa: cpfAjustado }
      };

      /*  
            if (usuariosRetornados.length > 0) {
              return {
                type: BUSCA_USUARIO_PORTAL_EDICAO,
                payload: usuariosRetornados[0].id
              };
            }
            else {
              return {
                type: BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL,
                payload: cpfAjustado
              };
            }
          }
          else {
            return {
              type: ABORTA_OPERACAO,
              payload: null
            };*/
    }
  }
  else {
    validacoes.push(validaCPFCampo('descricaoNumeroCPFRegistroPrevio', props.descricaoNumeroCPFRegistroPrevio));
  }

  return validacoes;
}


export const decidirDuploCheckSucesso = (idAtendto) => {
  return {
    type: DECIDIR_ATENDTO_DUPLO_CHECK_SUCESSO,
    payload: { idAtendto }
  }
}

export const inicioPortariasSucesso = (/*listaAmbientesPortaria, */listaAmbientesCliente, usuariosPlataforma, perfisTemporarios, liberacoes, ambientesPerfilTemporario, dadosCliente) => {
  return [{
    type: INICIA_PORTARIA_SUCESSO,
    payload: { /*listaAmbientesPortaria,*/ listaAmbientesCliente, usuariosPlataforma, perfisTemporarios, liberacoes, ambientesPerfilTemporario, dadosCliente }
  },
/*   usuariosPlataforma.length > 0 && {
    type: OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA,
    payload: { array: usuariosPlataforma }
  } */
  ];
}

export const iniciaLiberacoes = () => {
  return [{
    type: INICIA_LIBERACOES
  },
  {
    type: INICIA_PORTARIA_LIBERACAO
  }]
}

export const onChangeValue = (e) => {
  return {
    type: ALTERACAO_CAMPO_PORTARIA,
    payload: e
  }
}

export const onChangeValueFiltroPessoaTestes = (e) => {
  return {
    type: ALTERACAO_CAMPO_PESQUISA_PESSOA_TESTE,
    payload: e
  }
}

export const onChangeValueFiltroUnidadeGaragem = (e) => {
  return {
    type: ALTERACAO_CAMPO_PESQUISA_UNIDADE_GARAGEM,
    payload: e
  }
}

export const onChangeValueClassico = (e) => {
  return {
    type: ALTERACAO_CAMPO,
    payload: e
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const exibirMensagemErroPortaria = (message) => {
  return {
    type: EXIBE_MENSAGEM_ERRO_PORTARIA,
    payload: message
  };
};

export const OcultaMensagemPortaria = (message) => {
  return {
    type: OCULTA_MENSAGEM_PORTARIA,
    payload: null
  };
};

export const validaData = (e) => {

  var validacoes = [];

  var campo = e.target.name;
  var valor = e.target.value;

  validacoes.push(validaDataCampo(campo, valor));

  return validacoes;
};

export const validaDataCampo = (campo, valor) => {
  var mensagem = "";
  var dataValida = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    dataValida = isValidDate(valor);
  }
  else {
    dataValida = true;
  }

  if (!dataValida) {
    mensagem = "Data inválida";
  }
  else {
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !dataValida, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}

function isValidDate(str) {
  var d = moment(str, 'D/M/YYYY');
  if (d == null || !d.isValid()) return false;

  return str.indexOf(d.format('D/M/YYYY')) >= 0
    || str.indexOf(d.format('DD/MM/YYYY')) >= 0
    || str.indexOf(d.format('D/M/YY')) >= 0
    || str.indexOf(d.format('DD/MM/YY')) >= 0;
}

function isValidCPF(cpf) {

  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return true;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito	
  var add = 0;
  var rev = 0;

  for (var i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (var i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
}

export const validaCPFCampo = (campo, valor) => {
  var mensagem = "";
  var cpfValido = false;

  if (valor !== undefined && valor !== null && valor !== "") {
    cpfValido = isValidCPF(valor);
  }
  else {
    cpfValido = true;
  }

  if (!cpfValido) {
    mensagem = "CPF inválido";
  }
  else {
    mensagem = "";
  }

  return {
    type: VALIDACAO_CAMPO,
    payload: { erro: !cpfValido, propriedadeValidada: campo, mensagemErro: mensagem }
  };
}
