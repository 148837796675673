import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_ACESSO_PERIODO, INICIA_ACESSO_PERIODO, BUSCA_ACESSO_PERIODO_EDICAO, REMOVE_ACESSO_PERIODO,
         LISTA_CONFIGURACAO_PONTO_PERIODO, LISTA_LOCAIS_POR_CLIENTE_ACESSO_PERIODO, LISTA_ACESSO_PERIODO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaAcessoPeriodoSucesso,
         removeAcessoPeriodoSucesso, 
         inicioAcessoPeriodoSucesso, 
         iniciaAcessoPeriodo,
         listaLocaisSucesso,
         buscaAcessoPeriodoEdicaoSucesso,
         listaConfiguracaoPontoPeriodoSucesso,
         listaAcessoPeriodoSucesso } from "actions/AcessoPeriodo";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getAcessosPeriodo = async () =>
    await requisicaoApi.get('/acessosPeriodo')
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);
    
    const getPerfils = async (cliente) =>
    await requisicaoApi.get('/perfis/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);  

  const getAcessoPeriodoEdicao = async (id) =>
    await requisicaoApi.get('/acessosPeriodo/' + id)
    .then(resposta => resposta)
    .catch(error => error);

  const getListaLocaisControladosPorCliente = async (cliente) => 
    await requisicaoApi.get('/locais/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);    
    
  const getlistaConfiguracaoPontoPeriodo = async (idLocal, idPeriodo) => 
    await requisicaoApi.get('/acessosPeriodo/vinculoPontoAcesso/' + idLocal + '/' + idPeriodo)
    .then(resposta => resposta)
    .catch(error => error);

    const getListaAcessoPeriodo = async (periodoFiltro, ambienteFiltro, pontoFiltro) => 
    await requisicaoApi.get('/acessosPeriodo?Periodo=' +  periodoFiltro + '&Ambiente=' + ambienteFiltro + '&PontoAcesso=' + pontoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  
    
/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaAcessoPeriodoRequest = async (acessoPeriodo, pontosAssociados) => await requisicaoApi.post('/acessosPeriodo', 
  {
    "id": acessoPeriodo.id,
    "idCriterio": 0,
    "criterioAcesso": {
      "tipoCriterioAcesso": "PERIODO",
      "ativo": true,
      "minutosPadraoRetorno": 0,
      "pontosAcessoVinculados": pontosAssociados,
      "id": 0
    },
    "idCliente": acessoPeriodo.idCliente,
    "segundaAtivo": acessoPeriodo.segundaAtivo,
    "tercaAtivo": acessoPeriodo.tercaAtivo,
    "quartaAtivo": acessoPeriodo.quartaAtivo,
    "quintaAtivo": acessoPeriodo.quintaAtivo,
    "sextaAtivo": acessoPeriodo.sextaAtivo,
    "sabadoAtivo": acessoPeriodo.sabadoAtivo,
    "domingoAtivo": acessoPeriodo.domingoAtivo,
    "horarioInicio": acessoPeriodo.horarioInicio,
    "horarioFim": acessoPeriodo.horarioFim,  
    "horarioInicioSegundo": acessoPeriodo.horarioInicioSegundo,
    "horarioFimSegundo": acessoPeriodo.horarioFimSegundo,
    "horarioInicioTerceiro": acessoPeriodo.horarioInicioTerceiro,
    "horarioFimTerceiro": acessoPeriodo.horarioFimTerceiro,
    "idPessoa": acessoPeriodo.idPessoa,
    "idLocalControlado": acessoPeriodo.idLocalControlado,
    "perfils": acessoPeriodo.perfils
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaAcessoPeriodoRequest = async (acessoPeriodo, pontosAssociados) => await requisicaoApi.put('/acessosPeriodo', 
  {
    "id": acessoPeriodo.id,
    "idCriterio": 0,
    "criterioAcesso": {
      "tipoCriterioAcesso": "PERIODO",
      "ativo": true,
      "minutosPadraoRetorno": 0,
      "pontosAcessoVinculados": pontosAssociados,
      "id": 0
    },
    "idCliente": acessoPeriodo.idCliente,
    "segundaAtivo": acessoPeriodo.segundaAtivo,
    "tercaAtivo": acessoPeriodo.tercaAtivo,
    "quartaAtivo": acessoPeriodo.quartaAtivo,
    "quintaAtivo": acessoPeriodo.quintaAtivo,
    "sextaAtivo": acessoPeriodo.sextaAtivo,
    "sabadoAtivo": acessoPeriodo.sabadoAtivo,
    "domingoAtivo": acessoPeriodo.domingoAtivo,
    "horarioInicio": acessoPeriodo.horarioInicio,
    "horarioFim": acessoPeriodo.horarioFim,
    "horarioInicioSegundo": acessoPeriodo.horarioInicioSegundo,
    "horarioFimSegundo": acessoPeriodo.horarioFimSegundo,
    "horarioInicioTerceiro": acessoPeriodo.horarioInicioTerceiro,
    "horarioFimTerceiro": acessoPeriodo.horarioFimTerceiro,  
    "idPessoa": acessoPeriodo.idPessoa,
    "idLocalControlado": acessoPeriodo.idLocalControlado,
    "perfils": acessoPeriodo.perfils
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteAcessoPeriodoRequest = async (id) =>
  await requisicaoApi.delete('/acessosPeriodo?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

function* fnIniciaAcessoPeriodo() {
  try {
    const listagemGrid = yield call(getAcessosPeriodo);

    if(listagemGrid.data)
    {
      const clientes = yield call(getClientes);

      if(clientes.data) {
        const clienteFixo = localStorage.getItem('idClienteFixado');

        if(clienteFixo !== "" && clienteFixo !== "0" && clienteFixo !== undefined)
        {
          const locais = yield call(getListaLocaisControladosPorCliente, parseInt(clienteFixo));
          const perfis = yield call(getPerfils, parseInt(clienteFixo));

          yield put(inicioAcessoPeriodoSucesso(listagemGrid.data, clientes.data, locais.data, perfis.data));
        }
        else
        {
          yield put(inicioAcessoPeriodoSucesso(listagemGrid.data, clientes.data, null, null));
        }
      }
    }
    else {
      if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
      {
        yield put(userSignOut());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao listar acessosPeriodo'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnListaLocaisPorCliente({ payload }) {
  try {
    const cliente = payload.idCliente;
    const retorno = yield call(getListaLocaisControladosPorCliente, cliente);
    const retornoPerfil = yield call(getPerfils, cliente);

    if(retorno.data)
    {
      yield put(listaLocaisSucesso(retorno.data, retornoPerfil.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de locais controlados do cliente: ' + cliente));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
}

function* fnlistaConfiguracaoPontoPeriodo({ payload }) {
  try {
    const idLocal = payload.idLocal;
    const idPeriodo = payload.idPeriodo;
    const retorno = yield call(getlistaConfiguracaoPontoPeriodo, idLocal, idPeriodo);

    if(retorno.data)
    {
      yield put(listaConfiguracaoPontoPeriodoSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de pontos de acesso do local: ' + idLocal));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

  function* fnBuscaAcessoPeriodoEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getAcessoPeriodoEdicao, id);
  
      if(retorno.data)
      {
        yield put(buscaAcessoPeriodoEdicaoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do acessoPeriodo: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaAcessoPeriodo({ payload }) {
  try {

    const acessoPeriodo = payload.acessoPeriodo;
    const pontosAssociados = payload.listaPontosAssociados;

    var resultado = "";

      if(acessoPeriodo.id === 0) {
        const retorno = yield call(adicionaAcessoPeriodoRequest, acessoPeriodo, pontosAssociados );
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaAcessoPeriodoRequest, acessoPeriodo, pontosAssociados);
        resultado = retorno;
      }

    if(resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaAcessoPeriodo());
        yield put(salvaAcessoPeriodoSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveAcessoPeriodo({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteAcessoPeriodoRequest, id);
    
    if(retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaAcessoPeriodo());
        yield put(removeAcessoPeriodoSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* fnListaAcessoPeriodo({ payload }) {
  try {
    const periodoFiltro = payload.periodo;
    const ambienteFiltro = payload.ambiente;
    const pontoFiltro = payload.ponto;
    const retorno = yield call(getListaAcessoPeriodo, periodoFiltro, ambienteFiltro, pontoFiltro);

    if(retorno.data)
    {
      yield put(listaAcessoPeriodoSucesso(retorno.data));
    }
    else
    {
      yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de acesso periodo'));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }    
} 

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesAcessoPeriodo() {
  yield takeEvery(INICIA_ACESSO_PERIODO, fnIniciaAcessoPeriodo);
}

export function* salvaAcessoPeriodo() {
  yield takeEvery(SALVA_ACESSO_PERIODO, fnSalvaAcessoPeriodo);
}

export function* buscaAcessoPeriodoEdicao() {
  yield takeEvery(BUSCA_ACESSO_PERIODO_EDICAO, fnBuscaAcessoPeriodoEdicao);
}

export function* removeAcessoPeriodo() {
  yield takeEvery(REMOVE_ACESSO_PERIODO, fnRemoveAcessoPeriodo);
}

export function* listaLocaisPorCliente() {
  yield takeEvery(LISTA_LOCAIS_POR_CLIENTE_ACESSO_PERIODO, fnListaLocaisPorCliente)
}

export function* listaConfiguracaoPontoPeriodo() {
  yield takeEvery(LISTA_CONFIGURACAO_PONTO_PERIODO, fnlistaConfiguracaoPontoPeriodo)
}

export function* listaAcessoPeriodo() {
  yield takeEvery(LISTA_ACESSO_PERIODO, fnListaAcessoPeriodo)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesAcessoPeriodo),
             fork(salvaAcessoPeriodo), 
             fork(buscaAcessoPeriodoEdicao),
             fork(removeAcessoPeriodo),
             fork(listaLocaisPorCliente),
             fork(listaConfiguracaoPontoPeriodo),
             fork(listaAcessoPeriodo)
            ]);
}