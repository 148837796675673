import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { estadosCivis, generos, tiposPessoa } from '../app/constantes/Listas';
import { preparaListaParaCombo, retornaDescricaoPorValorListaPadrao } from 'luxFw/transformations/Common';
import {
  INICIA_USUARIO_PORTAL,
  CONSULTA_CEP,
  NOVO_USUARIO_PORTAL,
  BUSCA_USUARIO_PORTAL_EDICAO,
  CANCELA_EDICAO,
  INICIO_USUARIO_PORTAL_SUCESSO,
  SALVA_USUARIO_PORTAL,
  SALVA_USUARIO_PORTAL_SUCESSO,
  REMOVE_USUARIO_PORTAL_SUCESSO,
  BUSCA_USUARIO_PORTAL_EDICAO_SUCESSO,
  ADICIONA_PERFIL_LISTA_VINCULADA,
  ADICIONA_CLIENTE_LISTA_VINCULADA,
  REMOVE_PERFIL_LISTA_VINCULADA,
  REMOVE_CLIENTE_LISTA_VINCULADA,
  BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL,
  NOVO_TELEFONE_USUARIO_PORTAL,
  ATUALIZA_TELEFONE_USUARIO_PORTAL,
  REMOVE_TELEFONE_USUARIO_PORTAL,
  NOVO_EMAIL_USUARIO_PORTAL,
  ATUALIZA_EMAIL_USUARIO_PORTAL,
  REMOVE_EMAIL_USUARIO_PORTAL,   
  ABORTA_OPERACAO,
  IR_PARA_CADASTRO_PERFIL,
  LISTA_PERFIS_POR_CLIENTE,
  LISTA_PERFIS_SUCESSO,
  CONSULTA_CEP_SUCESSO,
  CONSULTA_CEP_ERRO,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_CHECK,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  LIMPAR_USUARIO_PORTAL,
  LISTA_USUARIO_PORTAL_SUCESSO,
  LISTA_USUARIO_PORTAL,
  SETAR_IMAGEM_USUARO,
  EXIBE_PONTOS_REQUISICAO,
  ATUALIZAR_PONTO_REQUISICAO_USUARIO,
  ATUALIZAR_PERMISSAO_FUNCIONALIDADE_USUARIO,
  SELECIONAR_TUDO_FUNCIONALIDADE,
  SELECIONAR_TUDO_PORTARIA,
  APLICA_FILTRO,
  CLIQUE_CONTROLE_ABA_USUARIO_PORTAL,
  ALTERACAO_CHECK_USUARIO    
} from "constants/ActionTypes";
import { showAuthMessage, 
  exibirMensagemErro } from "actions/UsuarioPortal";

const INIT_STATE = {

  // Configuração da Grid
  colunasGrid: [
    { titulo: 'Codigo Usuario', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },
    { titulo: 'Foto', propriedadeApi: 'imagem', alinhamento: 'left', imagem: true },
    { titulo: 'Nome Pessoa', propriedadeApi: 'nomePessoa', alinhamento: 'left' },
    { titulo: 'CPF', propriedadeApi: 'numeroCpf', alinhamento: 'left' },
    { titulo: 'Gênero', propriedadeApi: 'sexo', alinhamento: 'left' },
    { titulo: 'Data Nascimento', propriedadeApi: 'dataNascimento', datetime: true, alinhamento: 'left' },
  ],

  novaConfiguracao: {
    "id": 0,
    "idClienteUsuarioSistema": 0,
    "tipoConfiguracaoAcessoPortal": "USUARIO",
    "idUsuarioSistema": 0,
    "idPerfilSistema": 0,
    "visualizaDadosPortariaUsuario": false,
    "concedeAcessosUsuario": false,
    "usuarioMantemGaragemUsuario": false,
    "uzerAcessoPainelAdministrativo": false,
    "uzerAcessoAmbienteCliente": false,
    "listaPontosAcessoPortaria": [],
    "listaDeAmbientesPortariaDoCliente": [],
    "listaBlocosFuncionalidade": [],
    "listaConfiguracaoPortalFuncionalideAcao": []
  },     

  telefonesNovo: [

    {
      id: 0,
      idTemporario: 0,
      numeroTelefone: '',
      marcador: 'Principal',
      exclusivoDoCliente: true
    },

    {
      id: 0,
      idTemporario: 1,
      numeroTelefone: '',
      marcador: 'Principal',
      exclusivoDoCliente: false
    }
  ],

  emailsNovo: [

    {
      id: 0,
      idTemporario: 0,
      email: '',
      marcador: 'Principal',
      exclusivoDoCliente: true
    },

    {
      id: 0,
      idTemporario: 1,
      email: '',
      marcador: 'Principal',
      exclusivoDoCliente: false
    }
  ],

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],
  listGridComFiltro: [],
  cadastroAtivo: 'USUARIO',
  textoFiltro: '',

  // Campos do Formulario
  id: 0,
  nomePessoa: '',
  numeroCpf: '',
  dataNascimentoStr: '',
  nomeMae: '',
  sexo: '',
  estadoCivil: '',
  imagem: '',
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  uf: '',
  nomeUf: '',
  idMunicipio: 0,
  nomeMunicipio: '',
  pontoReferencia: '',
  telefoneUm: '',
  telefoneDois: '',
  semNumero: false,
  email: '',
  site: '',
  login: '',
  senha: '',
  senhaCript: '',
  idClienteFixado: 0,
  idAmbienteSelecionadoRequisicao: 0,
  exibePontosRequisicaoUsuario: false,
  codigoIbge: null,
  idPerfilDoUsuario: 0,

  // Listas Vinculadas
  listaDeClientes: [],
  listaDeClientesVinculados: [],
  listaDePerfis: [],
  listaDePerfisVinculados: [],
  listaDePerfisCompleto: [],
  idClienteAdd: 0,
  idPerfilAdd: 0,
  telefones: [],
  emails: [],

  addObjItemListaPerfil: {
    idCliente: 0,
    nomeCliente: ''
  },

  // outros
  exibePontosRequisicao: false,
  indiceAba: 0,

  // combos
  listaDeSexos: [],
  listaDeEstadosCivis: [],

   // Filtros
   codigoFiltro: '', 
   cpfFiltro: '', 
   nomeFiltro: '', 

   configuracaoAcessoPortalUsuario: {},
   listaFuncionalidadesAcao: [],
   listaConfiguracaoPortalFuncionalideAcao: [],
   listaPontosAcessoPortaria: [],
   listaDeAmbientesPortariaDoClienteNovo: [],
   listaBlocosFuncionalidadeNovo: [],
   listaFuncionalidadesAcaoNovo: [],   
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_USUARIO_PORTAL: {
      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        cadastroAtivo: 'USUARIO',
        textoFiltro: '',
      }
    }
    case NOVO_USUARIO_PORTAL: {

      var configuracao = state.novaConfiguracao;
      configuracao.concedeAcessosUsuario = false;
      configuracao.visualizaDadosPortariaUsuario = false;
      configuracao.usuarioMantemGaragem = false;
      configuracao.listaDeAmbientesPortariaDoCliente = state.listaDeAmbientesPortariaDoClienteNovo;
      configuracao.listaBlocosFuncionalidade = state.listaBlocosFuncionalidadeNovo;
      configuracao.listaFuncionalidadesAcao = state.listaFuncionalidadesAcaoNovo;
      configuracao.listaPontosAcessoPortaria = [];
      configuracao.listaConfiguracaoPortalFuncionalideAcao = [];

      return {
        ...state,
        edicao: true,
        id: 0,
        nomePessoa: '',
        numeroCpf: '',
        dataNascimentoStr: '',
        nomeMae: '',
        sexo: '',
        estadoCivil: '',
        imagem: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        uf: '',
        nomeUf: '',
        idMunicipio: 0,
        nomeMunicipio: '',
        pontoReferencia: '',
        telefoneUm: '',
        telefoneDois: '',
        email: '',
        site: '',
        listaDePerfisVinculados: [],
        listaDeClientesVinculados: [],
        telefones: state.telefonesNovo,
        emails: state.emailsNovo,
        configuracaoAcessoPortalUsuario: configuracao,
        listaConfiguracaoPortalFuncionalideAcao: [],
        login: '',
        senha: '',
        idPerfilDoUsuario: 0,
        indiceAba: 0,
        semNumero: false,
        senhaCript: '',
      }
    }
    case BUSCA_USUARIO_PORTAL_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false,
        textoFiltro: '',
      }
    }
    case CONSULTA_CEP: {
      return {
        ...state,
        loader: true,
      }
    }
    case ABORTA_OPERACAO: {
      return {
        ...state,
        loader: false,
      }
    } 
    case APLICA_FILTRO: {

/*       const filtroAplicar = action.payload;
      var listaFiltrada = filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.nomePessoa.toLowerCase().includes(filtroAplicar.toLowerCase())) : []; */

      const filtroAplicar = action.payload;
      var apenasNumeros = /^\d+$/.test(filtroAplicar.replace(".", "").replace("-", ""));
      var listaFiltrada = !apenasNumeros ? filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.nomePessoa.toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "").includes(filtroAplicar.normalize("NFD").toLowerCase().replace(/[^a-zA-Zs]/g, ""))) : [] : filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.numeroCpf.replace(".", "").replace("-", "").includes(filtroAplicar.replace(".", "").replace("-", ""))) : [];

      return {
        ...state,
        textoFiltro: filtroAplicar,
        listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
      }
    }       
    case INICIO_USUARIO_PORTAL_SUCESSO: {

      const retorno = action.payload;

      var perfisOrigem = preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil");
      var perfisProcessado = [];

      perfisProcessado.push({id: 0, title: 'Customizado para esse(a) Usuário(a)'});

      for (var indice in perfisOrigem) {
        perfisProcessado.push({id: perfisOrigem[indice].id, title: perfisOrigem[indice].title});
      }

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        listGrid: retorno.usuariosPortal,
        listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
        listaDePerfis: perfisProcessado,
        listaDePerfisCompleto: retorno.perfis,
        listaDeSexos: preparaListaParaCombo(generos, "id", "title"),
        listaDeEstadosCivis: preparaListaParaCombo(estadosCivis, "id", "title"),
        listaDeTiposDePessoa: preparaListaParaCombo(tiposPessoa, "id", "title"),
        listaDeAmbientesPortariaDoClienteNovo: retorno.ambientesPortaria,
        listaBlocosFuncionalidadeNovo: retorno.blocosFuncionalidade,
        listaFuncionalidadesAcaoNovo: retorno.funcionalidadesAcao        
      }
    }
    case SALVA_USUARIO_PORTAL: {
      return {
        ...state,
        loader: true,
      }
    }    
    case SALVA_USUARIO_PORTAL_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_USUARIO_PORTAL_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case LISTA_USUARIO_PORTAL: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_USUARIO_PORTAL_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.usuariosPortal,
      }
    }
    case LIMPAR_USUARIO_PORTAL: {

      var retorno = action.payload;

      return {
        ...state,
        codigoFiltro: '', 
        cpfFiltro: '', 
        nomeFiltro: '', 
      }
    }
    
    case NOVO_TELEFONE_USUARIO_PORTAL: {
      var retorno = action.payload;
      const idTemporario = state.telefones.length + 1;

      const telefoneAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "numeroTelefone": '',
        "marcador": '',
        "exclusivoDoCliente": retorno
      }

      return {
        ...state,
        telefones: [...state.telefones, telefoneAdd],
      }
    }
    case ATUALIZA_TELEFONE_USUARIO_PORTAL: {

      var retorno = action.payload;

      var listaDetelefones = state.telefones.filter(x => x.idTemporario !== retorno.indice);
      var telefoneSelecionado = state.telefones.filter(x => x.idTemporario === retorno.indice);

      var telefoneAtualizar = {
        "id": telefoneSelecionado[0].id,
        "idTemporario": telefoneSelecionado[0].idTemporario,
        "numeroTelefone": retorno.campoAtualizar === 'numeroTelefone' ? telefoneMask(retorno.valor) : telefoneSelecionado[0].numeroTelefone,
        "marcador": retorno.campoAtualizar === 'marcador' ? retorno.valor : telefoneSelecionado[0].marcador,
        "exclusivoDoCliente": telefoneSelecionado[0].exclusivoDoCliente
      };

      listaDetelefones = [...listaDetelefones, telefoneAtualizar];
      listaDetelefones = listaDetelefones.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        telefones: listaDetelefones,
      }
    }
    case REMOVE_TELEFONE_USUARIO_PORTAL: {

      return {
        ...state,
        telefones: state.telefones.filter((telefone) => telefone.idTemporario !== action.payload),
      }
    }
    case NOVO_EMAIL_USUARIO_PORTAL: {
      var retorno = action.payload;
      const idTemporario = state.emails.length + 1;

      const emailAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "email": '',
        "marcador": '',
        "exclusivoDoCliente": retorno
      }

      return {
        ...state,
        emails: [...state.emails, emailAdd],
      }
    }
    case ATUALIZA_EMAIL_USUARIO_PORTAL: {
      var retorno = action.payload;

      var listaDeEmails = state.emails.filter(x => x.idTemporario !== retorno.indice);
      var emailSelecionado = state.emails.filter(x => x.idTemporario === retorno.indice);

      var emailAtualizar = {
        "id": emailSelecionado[0].id,
        "idTemporario": emailSelecionado[0].idTemporario,
        "email": retorno.campoAtualizar === 'email' ? retorno.valor : emailSelecionado[0].email,
        "marcador": retorno.campoAtualizar === 'marcador' ? retorno.valor : emailSelecionado[0].marcador,
        "exclusivoDoCliente": emailSelecionado[0].exclusivoDoCliente
      };

      listaDeEmails = [...listaDeEmails, emailAtualizar];
      listaDeEmails = listaDeEmails.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        emails: listaDeEmails,
      }
    }
    case REMOVE_EMAIL_USUARIO_PORTAL: {

      return {
        ...state,
        emails: state.emails.filter((email) => email.idTemporario !== action.payload),
      }
    }


    case EXIBE_PONTOS_REQUISICAO: {
      var retorno = action.payload;
      return {
        ...state,
        exibePontosRequisicaoUsuario: !state.exibePontosRequisicaoUsuario,
        idAmbienteSelecionadoRequisicao: retorno,
      }
    }    
    case BUSCA_USUARIO_PORTAL_EDICAO_SUCESSO: {
      var retorno = action.payload;
      var perfilVinculado = "";
      var clienteVinculado = "";
      const objListPerfil = [];
      const objListCliente = [];

      var telefonesComMascara = retorno.telefones;

      for (var indice in telefonesComMascara) {
        telefonesComMascara[indice].numeroTelefone = telefoneMask(telefonesComMascara[indice].numeroTelefone);
      }

      for (var k in retorno.perfisVinculados) {

        perfilVinculado = retorno.perfisVinculados[k];

        const objItemListaPerfil = {
          "idTemporario": perfilVinculado.idTemporario,
          "idPerfilSistema": perfilVinculado.idPerfilSistema,
          "nomePerfil": retornaDescricaoPorValorListaPadrao(state.listaDePerfis, perfilVinculado.idPerfilSistema),
          "id": 0
        }

        objListPerfil.push(objItemListaPerfil);
      }

      for (var k in retorno.clientesVinculados) {

        clienteVinculado = retorno.clientesVinculados[k];

        const objItemListaCliente = {
          "idCliente": clienteVinculado.idCliente,
          "idTemporario": clienteVinculado.idTemporario,
          "nomeCliente": retornaDescricaoPorValorListaPadrao(state.listaDeClientes, clienteVinculado.idCliente),
          "id": 0
        }

        objListCliente.push(objItemListaCliente);
      }     
      
      var configuracaoNova = state.novaConfiguracao;
      configuracaoNova.listaDeAmbientesPortariaDoCliente = state.listaDeAmbientesPortariaDoClienteNovo;
      configuracaoNova.listaBlocosFuncionalidade = state.listaBlocosFuncionalidadeNovo;
      configuracaoNova.listaFuncionalidadesAcao = state.listaFuncionalidadesAcaoNovo;

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        nomePessoa: retorno.nomePessoa,
        numeroCpf: cpfMask(retorno.numeroCpf),
        dataNascimentoStr: dateMask(retorno.dataNascimentoStr),
        nomeMae: retorno.nomeMae,
        sexo: retorno.sexo,
        estadoCivil: retorno.estadoCivil,
        imagem: retorno.imagem,
        cep: retorno.cep,
        logradouro: retorno.logradouro,
        numero: retorno.numero,
        complemento: retorno.complemento,
        bairro: retorno.bairro,
        uf: retorno.uf,
        nomeUf: retorno.nomeUf,
        idMunicipio: retorno.idMunicipio,
        nomeMunicipio: retorno.nomeMunicipio,
        pontoReferencia: retorno.pontoReferencia,
        telefoneUm: retorno.telefoneUm,
        telefoneDois: retorno.telefoneDois,
        email: retorno.email,
        emails: retorno.emails,
        telefones: telefonesComMascara,
        site: retorno.site,
        login: retorno.login,
        senha: retorno.senha,
        senhaCript: retorno.senhaCript,
        idPerfilDoUsuario: retorno.idPerfilDoUsuario === null ? 0 : retorno.idPerfilDoUsuario,
        idClienteFixado: retorno.idClienteFixado,
        listaDePerfisVinculados: objListPerfil,
        listaDeClientesVinculados: objListCliente,
        configuracaoAcessoPortalUsuario: retorno.id !== 0 ? retorno.configuracaoAcessoPortalUsuario : configuracaoNova,
        listaPontosAcessoPortaria: retorno.id !== 0 ? retorno.configuracaoAcessoPortalUsuario.listaPontosAcessoPortaria : configuracaoNova.listaPontosAcessoPortaria,
        listaFuncionalidadesAcao: retorno.id !== 0 ? retorno.configuracaoAcessoPortalUsuario.listaFuncionalidadesAcao : configuracaoNova.listaFuncionalidadesAcao,
        listaConfiguracaoPortalFuncionalideAcao: retorno.id !== 0 ? retorno.configuracaoAcessoPortalUsuario.listaConfiguracaoPortalFuncionalideAcao : configuracaoNova.listaConfiguracaoPortalFuncionalideAcao,
        indiceAba: 0,      
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,       
        semNumero: retorno.semNumero, 
      }
    }
    case CLIQUE_CONTROLE_ABA_USUARIO_PORTAL: {
      var retorno = action.payload;

      return {
        ...state,
        indiceAba: retorno.indice,
      }
    }    
    case ADICIONA_PERFIL_LISTA_VINCULADA: {

      var retorno = action.payload;
      const addObjItemListaPerfil = {
        "idPerfilSistema": retorno.idPerfilAdd,
        "nomePerfil": retornaDescricaoPorValorListaPadrao(state.listaDePerfis, retorno.idPerfilAdd),
        "idTemporario": state.listaDePerfisVinculados.length > 0 ? Math.max(...state.listaDePerfisVinculados.map(x => x.idTemporario)) + 1 : 1,
        "id": 0
      }

      return {
        ...state,
        listaDePerfisVinculados: [...state.listaDePerfisVinculados, addObjItemListaPerfil]
      }
    }
    case ADICIONA_CLIENTE_LISTA_VINCULADA: {
      var retorno = action.payload;
      const addObjItemListaCliente = {
        "idCliente": retorno.idClienteAdd,
        "nomeCliente": retornaDescricaoPorValorListaPadrao(state.listaDeClientes, retorno.idClienteAdd),
        "idTemporario": state.listaDeClientesVinculados.length > 0 ? Math.max(...state.listaDeClientesVinculados.map(x => x.idTemporario)) + 1 : 1,
        "id": 0
      }

      return {
        ...state,
        listaDeClientesVinculados: [...state.listaDeClientesVinculados, addObjItemListaCliente],
      }
    }
    case REMOVE_PERFIL_LISTA_VINCULADA: {
      var retorno = action.payload;

      const arrayAtualizado = state.listaDePerfisVinculados.filter(x => x.idTemporario !== retorno.idTemporario)

      return {
        ...state,
        listaDePerfisVinculados: arrayAtualizado
     }
    }      
    case LISTA_PERFIS_POR_CLIENTE: {
      return {
        ...state,
        idClienteAdd: action.payload.idCliente,
        loader: true,
      }
    }
    case LISTA_PERFIS_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listaDePerfis: preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil"),
      }
    }
    case IR_PARA_CADASTRO_PERFIL: {
      return {
        ...state,
        loader: false,
        cadastroAtivo: 'PERFIL',
      }
    }

    case SELECIONAR_TUDO_PORTARIA: {

      var configuracao = state.configuracaoAcessoPortalUsuario;
      configuracao.visualizaDadosPortaria = true;
      configuracao.concedeAcessos = true;
      configuracao.usuarioMantemGaragem = true;

      return {
        ...state,
        configuracaoAcessoPortalUsuario: configuracao,
        idPerfilDoUsuario: 0
      }
    }    

    case SELECIONAR_TUDO_FUNCIONALIDADE: {
      
      var retorno = action.payload;
      var arrayAtualizado = [];  
      var configuracao = state.configuracaoAcessoPortalUsuario;

      if(retorno) {

        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao;

        for(var indice in state.configuracaoAcessoPortalUsuario.listaFuncionalidadesAcao) {
          var funcionalidadeAcao = state.configuracaoAcessoPortalUsuario.listaFuncionalidadesAcao[indice];

          if(funcionalidadeAcao.funcionalidadePortal.idBlocoFuncionalidadePortal === retorno) {

            var funcAdd = {
              "funcionalidadeAcaoUsuarioPortal": null,
              "id": 0,
              "idConfiguracaoAcessoPortal": state.configuracaoAcessoPortalUsuario.id,
              "idFuncionalidadeAcaoUsuarioPortal": funcionalidadeAcao.id     
            }
              
            arrayAtualizado = [...arrayAtualizado, funcAdd];

          }

        }
      }
      else {
        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.idFuncionalidadeAcaoUsuarioPortal !== retorno.idAtualizacao)
      }

      configuracao.listaConfiguracaoPortalFuncionalideAcao = arrayAtualizado.listaConfiguracaoPortalFuncionalideAcao; 

      return {
        ...state,
        listaConfiguracaoPortalFuncionalideAcao: arrayAtualizado,
        configuracaoAcessoPortalUsuario: configuracao,
        idPerfilDoUsuario: 0        
      }
    }
    case ATUALIZAR_PERMISSAO_FUNCIONALIDADE_USUARIO: {

      var retorno = action.payload;
      var arrayAtualizado = [];  
      var configuracao = state.configuracaoAcessoPortalUsuario;  
      
      if(retorno.valor) {

        const funcAdd = {
          "funcionalidadeAcaoUsuarioPortal": null,
          "id": 0,
          "idConfiguracaoAcessoPortal": state.configuracaoAcessoPortalUsuario.id,
          "idFuncionalidadeAcaoUsuarioPortal": retorno.idAtualizacao       
        }

        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao;
        arrayAtualizado = [...arrayAtualizado, funcAdd];
      }
      else {
        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.idFuncionalidadeAcaoUsuarioPortal !== retorno.idAtualizacao)
      }

      configuracao.listaConfiguracaoPortalFuncionalideAcao = arrayAtualizado.listaConfiguracaoPortalFuncionalideAcao;      

      return {
        ...state,
        listaConfiguracaoPortalFuncionalideAcao: arrayAtualizado,
        configuracaoAcessoPortalUsuario: configuracao,
        idPerfilDoUsuario: 0
      }         
    }

    case ATUALIZAR_PONTO_REQUISICAO_USUARIO: {
      var retorno = action.payload;
      var arrayAtualizado = [];  
      var configuracao = state.configuracaoAcessoPortalUsuario;

      if(retorno.valor) {
        const pontoAdd = {
          "configuracaoAcessoPortal": null,
          "idConfiguracaoAcessoPortal": state.configuracaoAcessoPortalUsuario.id,
          "pontoAcesso": {
            "id": retorno.idAtualizacao,
            "idAmbiente": state.idAmbienteSelecionadoRequisicao
          },
          "idPontoAcesso": retorno.idAtualizacao,
          "id": 0,
        }
  
        arrayAtualizado = state.listaPontosAcessoPortaria;
        arrayAtualizado = [...arrayAtualizado, pontoAdd];
      }
      else {
        arrayAtualizado = state.listaPontosAcessoPortaria.filter(x => x.idPontoAcesso !== retorno.idAtualizacao)
      }

      configuracao.listaPontosAcessoPortaria = arrayAtualizado.listaPontosAcessoPortaria;

      return {
        ...state,
        listaPontosAcessoPortaria: arrayAtualizado,
        configuracaoAcessoPortalUsuario: configuracao,
        idPerfilDoUsuario: 0
      }      
    }    
    case CONSULTA_CEP_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        cepConsultado: retorno,
        logradouro: retorno.logradouro,
        bairro: retorno.bairro,
        nomeMunicipio: retorno.localidade,
        nomeUf: retorno.uf,
        codigoIbge: retorno.ibge
      }
    }
    case CONSULTA_CEP_ERRO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        cepConsultado: '',
        logradouro: '',
        bairro: '',
        nomeMunicipio: '',
        nomeUf: '',
        codigoIbge: '',
        complemento: '',
        numero: '',
        cep: ''
      }        
    }
    case ALTERACAO_CHECK_USUARIO: {

      var campo = action.payload.nomeCheck[0];
      var configuracao = state.configuracaoAcessoPortalUsuario;

      if(campo === "visualizaDadosPortariaUsuario") {
        configuracao.visualizaDadosPortaria = action.payload.valor;
      }

      if(campo === "concedeAcessosUsuario") {
        configuracao.concedeAcessos = action.payload.valor;
      }   
      
      if(campo === "usuarioMantemGaragemUsuario") {
        configuracao.usuarioMantemGaragem = action.payload.valor;
      }

      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
        configuracaoAcessoPortalUsuario: configuracao,
        idPerfilDoUsuario: 0
      }
    }            
    case SETAR_IMAGEM_USUARO: {
      var retorno = action.payload;

      return {
        ...state,
        imagem: retorno.imagem,
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL: {
      return {
        ...state,
        loader: true
      }
    }
    case ALTERACAO_CAMPO: {

      var campo = action.payload.target.name;
      var desmarcaNumero = false;

      if (campo === "numero")
      {
        if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
        {
          desmarcaNumero = true;
        }
      } 

      return {
        ...state,
        semNumero: desmarcaNumero === true ? false : state.semNumero, 
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_CHECK: {

      var campo = action.payload.nomeCheck[0];
      var configuracao = state.configuracaoAcessoPortalUsuario;
      var limpaNumero = false;

      if(campo === "visualizaDadosPortariaUsuario") {
        configuracao.visualizaDadosPortariaUsuario = action.payload.valor;
      }

      if(campo === "concedeAcessosUsuario") {
        configuracao.concedeAcessosUsuario = action.payload.valor;
      }   
      
      if(campo === "usuarioMantemGaragem") {
        configuracao.usuarioMantemGaragem = action.payload.valor;
      }
      
      if (campo === "semNumero")
      {
        if(action.payload.valor === true)
        {
          limpaNumero = true;
        }
      }        

      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
        numero: limpaNumero === true ? '' : state.numero,
        idPerfilDoUsuario: 0,
        configuracaoAcessoPortalUsuario: configuracao
      }
    }
    case ALTERACAO_COMBO: {

      var campo = action.payload.nomeCombo[0];
      var valor = action.payload.valor;
      var alteraPerfil = false;
      var configuracaoNova = {};

      if(campo === "idPerfilDoUsuario") {
        if(valor === null || valor === 0) {
          valor = 0;

          if (state.idPerfilDoUsuario > 0 && valor === 0){
            alteraPerfil = true;
            configuracaoNova = {
              visualizaDadosPortariaUsuario: false,
              concedeAcessosUsuario: false,
              usuarioMantemGaragem: false,
              listaConfiguracaoPortalFuncionalideAcao: [],
              listaPontosAcessoPortaria: [],
              listaFuncionalidadesAcao: state.listaFuncionalidadesAcao,
              listaDeAmbientesPortariaDoCliente: state.listaDeAmbientesPortariaDoClienteNovo,
              listaBlocosFuncionalidade: state.listaBlocosFuncionalidadeNovo
            }
          }
        }
        else {
          var perfilSelecionado = state.listaDePerfisCompleto.filter(x => x.id === valor)[0];
          configuracaoNova = perfilSelecionado.listaConfiguracoesAcessoPortal[0];
          alteraPerfil = true;
        }
      }

      return {
        ...state,
        configuracaoAcessoPortalUsuario: alteraPerfil ? configuracaoNova : state.configuracaoAcessoPortalUsuario,
        listaPontosAcessoPortaria: alteraPerfil ? configuracaoNova.listaPontosAcessoPortaria : state.listaPontosAcessoPortaria,
        listaFuncionalidadesAcao: alteraPerfil ? configuracaoNova.listaFuncionalidadesAcao : state.listaFuncionalidadesAcao,
        listaConfiguracaoPortalFuncionalideAcao: alteraPerfil ? configuracaoNova.listaConfiguracaoPortalFuncionalideAcao : state.listaConfiguracaoPortalFuncionalideAcao,
        listaDeAmbientesPortariaDoCliente: alteraPerfil ? configuracaoNova.listaDeAmbientesPortariaDoCliente : state.listaDeAmbientesPortariaDoCliente,
        listaBlocosFuncionalidade: alteraPerfil ? configuracaoNova.listaBlocosFuncionalidade : state.listaBlocosFuncionalidade,
        [action.payload.nomeCombo]: valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
