import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/HeaderPortaria/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import AlternaModulos from 'components/AlternaModulos';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../../util/asyncComponent';
import TopNav from 'components/TopNav';
import { fechaModulosPortaria, selecionaModulosPortaria, irParaModulo } from 'actions/Setting';


class App extends React.Component {

  render() {

    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const selecionaModuloPortaria = this.props.selecionarModuloPortaria;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container collapsible-drawer`}>
        {/* <Sidebar/> */}
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header {...this.props} selecionaModulo={selecionaModuloPortaria} />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">

              {
                selecionaModuloPortaria ?
                  <AlternaModulos {...this.props} exibeFechar={!this.props.loginRecente} funcaoFechar={() => { this.props.fechaModulosPortaria(); }} /> :

                  <Switch>
                    <Route path={`${match.url}/inicio`} component={asyncComponent(() => import('./routes/inicio/index'))} />
                    <Route path={`${match.url}/perfil`} component={asyncComponent(() => import('./routes/perfil/index'))} />
                    <Route path={`${match.url}/nlibera`} component={asyncComponent(() => import('./routes/novaLiberacao/index'))} />
                  </Switch>
              }

            </div>
            <Footer />
          </main>
        </div>
        {/* <ColorOption/> */}
      </div>
    );
  }
}


const mapStateToProps = ({ settings, cliente, portariaInicio }) => {
  const { drawerType, navigationStyle, horizontalNavPosition, selecionarModuloPortaria, loginRecente } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition, selecionarModuloPortaria, loginRecente }
};
export default withRouter(connect(mapStateToProps, { fechaModulosPortaria, selecionaModulosPortaria, irParaModulo })(App));