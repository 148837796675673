import {
  INICIA_MODELO,
  SALVA_MODELO,
  REMOVE_MODELO,
  NOVO_MODELO,
  BUSCA_MODELO_EDICAO,
  INICIO_MODELO_SUCESSO,
  SALVA_MODELO_SUCESSO,
  REMOVE_MODELO_SUCESSO,
  BUSCA_MODELO_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_MODELO,
  LISTA_MODELO_SUCESSO,
  LIMPAR_MODELO
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaModelo = () => {
  return {
    type: INICIA_MODELO
  };
};

export const salvaModelo = (equipamento) => {
  return {
    type: SALVA_MODELO,
    payload: equipamento
  };
};

export const removeModelo = (id) => {
  return {
    type: REMOVE_MODELO,
    payload: id
  };
};

export const listaModelo = (nome, fabricante) => {
  if(nome == undefined){
    nome = "";
  }

  if(fabricante == undefined){
    fabricante = "";
  }

  return {
    type: LISTA_MODELO,
    payload: {nome, fabricante}
  };
};


/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoModelo = () => {
  return {
    type: NOVO_MODELO
  };
};

export const buscaModeloEdicao = (id) => {
  return {
    type: BUSCA_MODELO_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/  

export const inicioModeloSucesso = (modelos, fabricantes) => {
  return {
    type: INICIO_MODELO_SUCESSO,
    payload: { modelos, fabricantes }
  }
};

export const salvaModeloSucesso = (mensagem) => {
  return {
    type: SALVA_MODELO_SUCESSO,
    payload: mensagem
  }
};

export const removeModeloSucesso = (mensagem) => {
  return {
    type: REMOVE_MODELO_SUCESSO,
    payload: mensagem
  }
};  

export const buscaModeloEdicaoSucesso = (equipamento) => {
  return {
    type: BUSCA_MODELO_EDICAO_SUCESSO,
    payload: equipamento
  };
}; 

export const listaModeloSucesso = (modelo) => {
  return {
    type: LISTA_MODELO_SUCESSO,
    payload: { modelo }
  };
};

export const limparFiltroModelo = () => {
  return {
    type: LIMPAR_MODELO,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};