import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_CATEGORIA, BUSCA_CATEGORIA_EDICAO, REMOVE_CATEGORIA, INICIA_CATEGORIA, LISTA_CATEGORIA, INICIO_CENARIO_CATEGORIA_LOCAL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaCategoriaSucesso,
         removeCategoriaSucesso,
         iniciaCategoria, 
         inicioCategoriaSucesso, 
         buscaCategoriaEdicaoSucesso,
         inicioCenarioCategoriaPorLocalControladoSucesso,
         listaCategoriaSucesso } from "actions/Categoria";
import { limpaValidacoes } from "actions/Global";      

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getCategorias = async () =>
    await requisicaoApi.get('/categorias')
    .then(resposta => resposta)
    .catch(error => error);

  const getLocaisControlados = async () =>
    await requisicaoApi.get('/locais')
    .then(resposta => resposta)
    .catch(error => error);

  const getCategoriaLocal = async (id) =>
    await requisicaoApi.get('/categorias/localControlado/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getCategoriaEdicao = async (id) =>
    await requisicaoApi.get('/categorias/' + id)
    .then(resposta => resposta)
    .catch(error => error);    

  const getListaCategoria = async (modeloFiltro, numeroFiltro, situacaoFiltro) => 
    await requisicaoApi.get('/categorias?Modelo=' +  modeloFiltro + '&Numero=' + numeroFiltro + '&Situacao=' + situacaoFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaCategoriaRequest = async (categoria) => await requisicaoApi.post('/categorias', 
  {
    "id": categoria.id,
    "descricaoGrupoCategoria": categoria.descricaoGrupoCategoria,
    "descricaoUnidadeCategoria": categoria.descricaoUnidadeCategoria,
    "idLocalControlado": categoria.idLocalControladoFixado,
    "ativo": true
  })
  .then(resposta => resposta)
  .catch(error => error);


  const atualizaCategoriaRequest = async (categoria) => await requisicaoApi.put('/categorias', 
  {
    "id": categoria.id,
    "descricaoGrupoCategoria": categoria.descricaoGrupoCategoria,
    "descricaoUnidadeCategoria": categoria.descricaoUnidadeCategoria,
    "idLocalControlado": categoria.idLocalControladoFixado,
    "ativo": true
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteCategoriaRequest = async (id) =>
  await requisicaoApi.delete('/categorias?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------------- Functions --------------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaCategoria() {
    try {
      const listagemLocais = yield call(getLocaisControlados);

      if(!listagemLocais.isAxiosError && listagemLocais.data) {

        if(listagemLocais.data.length === 1)
        {
          const local = listagemLocais.data[0].id;
          const listagemGrid = yield call(getCategoriaLocal, local);

          if(listagemGrid.data) {
            yield put(inicioCenarioCategoriaPorLocalControladoSucesso(listagemGrid.data, listagemLocais.data, local));
            yield put(limpaValidacoes());
          }
        }
        else
        {
          yield put(inicioCategoriaSucesso(listagemLocais.data));
        }
        
      }
/*       else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar categorias'));
        }
      }        */     
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnCenarioCategoriaPorLocalControlado({ payload }) {
    try {
      const local = payload.idLocal;
      const listagemGrid = yield call(getCategoriaLocal, local);

      if(!listagemGrid.isAxiosError && listagemGrid.data) {
        yield put(inicioCenarioCategoriaPorLocalControladoSucesso(listagemGrid.data, null, local));
      }
/*       else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar categorias'));
        }
      }        */     
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaCategoriaEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getCategoriaEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaCategoriaEdicaoSucesso(retorno.data));
        yield put(limpaValidacoes());
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do categoria: ' + id));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaCategoria({ payload }) {
    try {
      const modeloFiltro = payload.modelo;
      const numeroFiltro = payload.numero;
      const situacaoFiltro = payload.situacao;
      const retorno = yield call(getCategorias);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(listaCategoriaSucesso(retorno.data));
      }
      else
      {
        if(retorno.response.status === 401 && retorno.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de categorias'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaCategoria({ payload }) {
  try {

    const categoria = payload;
    var resultado = "";
    var errosAgrupados = "";

      if(categoria.id === 0) {
        const retorno = yield call(adicionaCategoriaRequest, categoria);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaCategoriaRequest, categoria);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        const listagemLocais = yield call(getLocaisControlados);
        const listagemGrid = yield call(getCategoriaLocal, categoria.idLocalControladoFixado);
        yield put(inicioCenarioCategoriaPorLocalControladoSucesso(listagemGrid.data, listagemLocais.data, categoria.idLocalControladoFixado));
        ////yield put(iniciaCategoria());
        yield put(salvaCategoriaSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveCategoria({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteCategoriaRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaCategoria());
        yield put(removeCategoriaSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesCategoria() {
  yield takeEvery(INICIA_CATEGORIA, fnIniciaCategoria);
}

export function* iniciaCenarioCategoriaPorLocalControlado() {
  yield takeEvery(INICIO_CENARIO_CATEGORIA_LOCAL, fnCenarioCategoriaPorLocalControlado);
}

export function* salvaCategoria() {
  yield takeEvery(SALVA_CATEGORIA, fnSalvaCategoria);
}

export function* buscaCategoriaEdicao() {
  yield takeEvery(BUSCA_CATEGORIA_EDICAO, fnBuscaCategoriaEdicao);
}

export function* removeCategoria() {
  yield takeEvery(REMOVE_CATEGORIA, fnRemoveCategoria);
}

export function* listaCategoria() {
  yield takeEvery(LISTA_CATEGORIA, fnListaCategoria)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesCategoria),
             fork(iniciaCenarioCategoriaPorLocalControlado),
             fork(salvaCategoria), 
             fork(buscaCategoriaEdicao),
             fork(removeCategoria),
             fork(listaCategoria)]);
}