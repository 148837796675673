import {
  INICIA_ACESSO_PERIODO,
  SALVA_ACESSO_PERIODO,
  REMOVE_ACESSO_PERIODO,
  NOVO_ACESSO_PERIODO,
  BUSCA_ACESSO_PERIODO_EDICAO,
  INICIO_ACESSO_PERIODO_SUCESSO,
  LISTA_CONFIGURACAO_PONTO_PERIODO,
  LISTA_CONFIGURACAO_PONTO_PERIODO_SUCESSO,
  LISTA_LOCAIS_POR_CLIENTE_ACESSO_PERIODO,
  LISTA_LOCAIS_SUCESSO,
  ALTERACAO_CHECK_PONTO_ACESSO,
  SALVA_ACESSO_PERIODO_SUCESSO,
  REMOVE_ACESSO_PERIODO_SUCESSO,
  BUSCA_ACESSO_PERIODO_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_ACESSO_PERIODO,
  LISTA_ACESSO_PERIODO_SUCESSO,
  LIMPAR_ACESSO_PERIODO,
  ADICIONA_PERFIL_LISTA_VINCULADA_PERIODO,
  REMOVE_PERFIL_LISTA_VINCULADA_PERIODO
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaAcessoPeriodo = () => {
  return {
    type: INICIA_ACESSO_PERIODO
  };
};

export const salvaAcessoPeriodo = (acessoPeriodo) => {
  var listaPontosAssociados = [];

  if(acessoPeriodo.configuracaoPontoPeriodo != null && acessoPeriodo.configuracaoPontoPeriodo.listaAmbientesVinculados != null)
  {
  for (var i = 0; i < acessoPeriodo.configuracaoPontoPeriodo.listaAmbientesVinculados.length; i++) {
    var listaPontos = acessoPeriodo.configuracaoPontoPeriodo.listaAmbientesVinculados[i].pontosAcessoVinculados;

    for (var j = 0; j < listaPontos.length; j++) {
        listaPontosAssociados.push({"idCriterio": 0, "idPontoAcesso":  listaPontos[j].idPontoAcesso, "id": 0, ativo: listaPontos[j].associado })
    }
  }
}

  return {
    type: SALVA_ACESSO_PERIODO,
    payload:  { acessoPeriodo, listaPontosAssociados }
  };
};

export const removeAcessoPeriodo = (id) => {
  return {
    type: REMOVE_ACESSO_PERIODO,
    payload: id
  };
};

export const listaAcessoPeriodo = (periodo, ambiente, ponto) => {
  if(periodo == undefined){
    periodo = "";
  }

  if(ambiente == undefined){
    ambiente = "";
  }

  if(ponto == undefined){
    ponto = "";
  }

  return {
    type: LISTA_ACESSO_PERIODO,
    payload: {periodo, ambiente, ponto}
  };
};

export const adicionaPerfilListaVinculada = (idPerfil) => {

  return {
    type: ADICIONA_PERFIL_LISTA_VINCULADA_PERIODO,
    payload: { idPerfil }
    };
};

export const removePerfilListaVinculada = (idTemporario) => {
  return {
    type: REMOVE_PERFIL_LISTA_VINCULADA_PERIODO,
    payload: { idTemporario }
  };
};  

/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoAcessoPeriodo = () => {
  return {
    type: NOVO_ACESSO_PERIODO
  };
};

export const buscaAcessoPeriodoEdicao = (id) => {
  return {
    type: BUSCA_ACESSO_PERIODO_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/  

export const listaLocaisPorCliente = (name, object, value) => {
  var idCliente = value !== null ? value.id : 0;

  return {
    type: LISTA_LOCAIS_POR_CLIENTE_ACESSO_PERIODO,
    payload: { idCliente }
  }
}

export const listaConfiguracaoPontosPorPeriodo = (idLocal, idPeriodo) => {
  return {
    type: LISTA_CONFIGURACAO_PONTO_PERIODO,
    payload: { idLocal, idPeriodo }
  }
}

export const onChangeCheckPontoAcesso = (object, value) => {
  var nomeCheck = [object.target.id].toString();
  var idPtoAcesso = nomeCheck.replace(/pontoAcesso_/g, "");

  return {
      type: ALTERACAO_CHECK_PONTO_ACESSO,
      payload: { idPontoAcessoAlterado: idPtoAcesso, valor: value }
  }   
};   

export const inicioAcessoPeriodoSucesso = (acessosPeriodo, clientes, locais, perfis) => {
  return {
    type: INICIO_ACESSO_PERIODO_SUCESSO,
    payload: { acessosPeriodo, clientes, locais, perfis }
  }
};

export const salvaAcessoPeriodoSucesso = (mensagem) => {
  return {
    type: SALVA_ACESSO_PERIODO_SUCESSO,
    payload: mensagem
  }
};

export const removeAcessoPeriodoSucesso = (mensagem) => {
  return {
    type: REMOVE_ACESSO_PERIODO_SUCESSO,
    payload: mensagem
  }
};  

export const listaConfiguracaoPontoPeriodoSucesso = (configuracao) => {
  return {
    type: LISTA_CONFIGURACAO_PONTO_PERIODO_SUCESSO,
    payload: { configuracao }
  }
}

export const buscaAcessoPeriodoEdicaoSucesso = (acessoPeriodo) => {
  return {
    type: BUSCA_ACESSO_PERIODO_EDICAO_SUCESSO,
    payload: acessoPeriodo
  };
}; 

export const listaLocaisSucesso = (locais, perfils) => {
  return {
    type: LISTA_LOCAIS_SUCESSO,
    payload: { locais, perfils }
  };
};

export const listaAcessoPeriodoSucesso = (acessoPeriodo) => {
  return {
    type: LISTA_ACESSO_PERIODO_SUCESSO,
    payload: { acessoPeriodo }
  };
};

export const limparFiltroAcessoPeriodo = () => {
  return {
    type: LIMPAR_ACESSO_PERIODO,
  };
};


/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};