import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import IntegrationSwitch from 'luxFw/integrations/IntegrationSwitch';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';

const GreenRadio = withStyles({
  root: {
    color: '#05B59D',
    '&$checked': {
      color: '#05B59D',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  root: {

    '& .MuiInput-underline:after': {
      borderBottomColor: '#05B59D',
      borderBottomColor: '#05B59D',
      borderBottomWidth: '2px'
    },
    /*     '& .MuiInput-underline:before': {
          borderBottomColor: '#05B59D',
          borderBottomWidth: '2px'
        }, */
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#05B59D',
    },
    borderRadius: '8px 8px 0px 0px',
    ////backgroundColor: '#F3F3F3',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#3A3A3D'
  },
  label: {
    paddingLeft: '5px',
    paddingTop: '2px',

    "&::after": {
      color: '#05B59D',
      fontWeight: 'bold',
    }
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontFamily: 'Montserrat, sans-serif',
      color: "#666666",
      fontSize: '16px'
    },
  },
  correcaoBorda: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '#05B59D',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#05B59D',
      borderBottomWidth: '2px'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#05B59D',
    },
  },

}));

const paddingRow = {
  paddingLeft: 0,
  paddingRight: 0
};

function TextFieldChild(props) {
  const classes = useStyles();
  var validarErro = false;
  var mensagemErro = ""

  if(props.validador && props.validador.length > 0) {
    var inconsistencia = props.validador.filter(x => x.propriedadeValidada === props.name && x.erro);
    validarErro = inconsistencia.length > 0;
    mensagemErro = inconsistencia.length > 0 ? inconsistencia[0].mensagemErro : "";
  }

  return (
    <TextField
      error ={validarErro ? true : false }
      className={classes.root}
      id="teste"
      autoComplete='off'
      label={props.label}
      margin="normal"
      fullWidth
      InputLabelProps={{
        className: classes.label,
        ///shrink: true
      }}
      //defaultValue="Hello World"
      type={props.tipo}

      inputProps={{
        disableUnderline: true,
        maxLength: props.numCaracteres
      }}

      placeholder={props.textoplaceholder}
      disabled={props.desabilitado === "true" ? true : false}
      size="small"
      value={props.valor}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      helperText={validarErro ? mensagemErro : props.textoAjuda}
      variant="filled"
      style={{ backgroundColor: '#00000000' }}
    />
  );
}

function TextFieldChildPesquisa(props) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id="teste"
      autoComplete='off'
      label={props.label}
      margin="normal"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        )
      }}
      size="small"
      value={props.valor}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      variant="filled"
      style={{ backgroundColor: '#00000000' }}
    />
  );
}

function TextFieldChildPassword(props) {
  const classes = useStyles();
  var validarErro = false;
  var mensagemErro = ""

  if(props.validador && props.validador.length > 0) {
    var inconsistencia = props.validador.filter(x => x.propriedadeValidada === props.name && x.erro);
    validarErro = inconsistencia.length > 0;
    mensagemErro = inconsistencia.length > 0 ? inconsistencia[0].mensagemErro : "";
  }  

  return (
    <TextField
      error ={validarErro ? true : false }
      className={classes.root}
      id="teste"
      label={props.label}
      autoComplete="new-password"
      margin="normal"
      fullWidth
      InputLabelProps={{
        className: classes.label,
        ///shrink: true
      }}
      ////variant="filled"
      //defaultValue="Hello World"
      type="password"

      inputProps={{
        disableUnderline: true,
        maxLength: props.numCaracteres
      }}

      placeholder={props.textoplaceholder}
      disabled={props.desabilitado === "true" ? true : false}
      size="small"
      value={props.valor}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      helperText={validarErro ? mensagemErro : props.textoAjuda}
      variant="filled"
      style={{ backgroundColor: '#00000000' }}
    />
  );
}

function TextFieldMaskChild(props) {
  const classes = useStyles();
  var validarErro = false;
  var mensagemErro = ""
  if(props.validador && props.validador.length > 0) {
    var inconsistencia = props.validador.filter(x => x.propriedadeValidada === props.name && x.erro);
    validarErro = inconsistencia.length > 0;
    mensagemErro = inconsistencia.length > 0 ? inconsistencia[0].mensagemErro : "";
  }  

  return (
    <TextField
      error ={validarErro ? true : false }
      className={classes.root}
      id={props.tipoMascara}
      label={props.label}
      autoComplete='off'
      margin="normal"
      fullWidth
      InputLabelProps={{
        className: classes.label,
        ///shrink: true
      }}
      ////variant="filled"
      //defaultValue="Hello World"

      inputProps={{
        disableUnderline: true,
        maxLength: props.numCaracteres,
      }}      

      type={props.tipo}
      placeholder={props.textoplaceholder}
      disabled={props.desabilitado === "true" ? true : false}
      size="small"
      value={props.valor}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      helperText={validarErro ? mensagemErro : props.textoAjuda}
      variant="filled"
      style={{ backgroundColor: '#00000000', fontWeight: 'bold' }}
    />
  );
}

function TextAreaChild(props) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id="teste"
      label={props.label}
      margin="normal"
      fullWidth
      InputLabelProps={{
        className: classes.label,
        ///shrink: true
      }}
      ////variant="filled"
      inputProps={{
        maxLength: props.numCaracteres,
      }}
      multiline
      rows={props.numeroLinhas}
      type={props.tipo}
      placeholder={props.textoplaceholder}
      size="small"
      value={props.valor}
      onChange={props.onChange}
      name={props.name}
      helperText={props.textoAjuda}
      variant="filled"
      style={{ backgroundColor: '#00000000' }}
    />
  );
}

function TextFieldComboChild(params, props) {
  const classes = useStyles();

  var validarErro = false;
  var mensagemErro = ""

  if(params.validador && params.validador.length > 0) {
    var inconsistencia = params.validador.filter(x => x.propriedadeValidada === params.name && x.erro);
    validarErro = inconsistencia.length > 0;
    mensagemErro = inconsistencia.length > 0 ? inconsistencia[0].mensagemErro : "";
  }  

  return (
    <TextField {...params}
      error ={validarErro ? true : false }
      className= { !params.estiloGrid && classes.root}
      id="Fixo"
      label={params.label}
      margin="normal"
      fullWidth
      InputLabelProps={{
        className: classes.label,
        ////shrink: true
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {params.esperar === 'true' ? <CircularProgress size={15} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
      ///variant="filled"
      type="Fixo"
      placeholder={params.textoplaceholder}
      ////value={params.valor}
      defaultValue={params.valor}
      onChange={params.onChange}
      onBlur={params.onBlur}
      size="small"
      helperText={validarErro ? mensagemErro : params.textoAjuda}
      variant="filled" 
      style={{ backgroundColor: '#00000000' }}
    />
  );
}

function TextFieldComboMultiploChild(params, props) {
  const classes = useStyles();

  return (
    <TextField {...params}
      className={classes.root}
      id="Fixo"
      label={params.label}
      margin="normal"
      fullWidth
      InputLabelProps={{
        className: classes.label,
        ////shrink: true
      }}

      placeholder={params.textoplaceholder}
      defaultValue={params.valor}
      onChange={params.onChange}
      onBlur={params.onBlur}
      size="small"
      helperText={params.textoAjuda}
    />
  );
}

export class LuxFwRow extends Component {

  render() {

    const { children } = this.props;

    return (
      <div className={`col-md-12 col-12`} style={paddingRow}>
        {children}
      </div>
    );
  }

}

export class LuxFwTextBox extends Component {
  render() {
    return this.props.tamanho !== "" && this.props.tamanho !== null && this.props.tamanho !== undefined ? (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <TextFieldChild {...this.props} />
      </div>
    ) : (<TextFieldChild {...this.props} />);
  }
}

export class LuxFwTextBoxPesquisa extends Component {
  render() {
    return (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <TextFieldChildPesquisa {...this.props} />
      </div>
    );
  }
}

export class LuxFwPasswordTextBox extends Component {
  render() {
    return (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <TextFieldChildPassword {...this.props} />
      </div>
    );
  }
}

export class LuxFwMaskTextBox extends Component {
  render() {


    return this.props.tamanho !== "" && this.props.tamanho !== null && this.props.tamanho !== undefined ? (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <TextFieldMaskChild {...this.props} />
      </div>
    ) : (<TextFieldMaskChild {...this.props} />);
  }
}

export class LuxFwTextArea extends Component {
  render() {
    return (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <TextAreaChild {...this.props} />
      </div>
    );
  }
}

export class LuxFwComboBoxMultiplo extends Component {

  handleChange = (object, value) => {
    var name = this.props.name;
    var listaOpcoes = this.props.lista;
    this.props.onChange(name, object, value, listaOpcoes);
  };

  handlePreencheDefault = () => {
    var valores = this.props.valor;
    var valoresDisponiveis = this.props.lista;
    var listaRetorno = [];

    for (var indice in valores) {

      let valor = valoresDisponiveis.filter(x => x.id == valores[indice]);
      listaRetorno.push(valor[0]?.title);
    }

    return listaRetorno;

  }

  render() {

    return (
      <div className={`col-md-${this.props.tamanho} col-12`}>

      <Autocomplete
        multiple
        id="tags-filled"
        options={this.props.lista.map((option) => option.title)}
        defaultValue={this.handlePreencheDefault}
        freeSolo
        onChange={this.handleChange} 
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={params => (
          <TextFieldComboChild {...params} {...this.props} />
        )}
      />

      </div>
    );
  }
}  

export class LuxFwComboBox extends Component {

  handleChange = (object, value) => {
    var name = this.props.name;
    this.props.onChange(name, object, value);
  };

  render() {

    return !this.props.estiloGrid ? (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <Autocomplete
          id={this.props.name}
          noOptionsText={this.props.mensagemListaVazia === '' ? 'Nenhum registro' : this.props.mensagemListaVazia}
          disableClearable={this.props.desabilitaLimpar}
          key={`${this.props.name}-${this.props.valor}`}
          options={this.props.lista}
          getOptionLabel={option => option.title}
          defaultValue={this.props.lista.find((x) => x.id === this.props.valor)}
          multiple={this.props.multiplaescolha === "true" ? true : false}
          size="small"
          disabled={this.props.desabilitado === "true" ? true : false}
          onChange={this.handleChange}
          onBlur={this.props.onBlur}
          renderInput={params => (
            <TextFieldComboChild {...params} {...this.props} />
          )}

          renderOption={(option, { inputValue }) => {
            const matches = match(option.title, inputValue);
            const parts = parse(option.title, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />

      </div>) : (
                <Autocomplete
                id={this.props.name}
                noOptionsText={this.props.mensagemListaVazia === '' ? 'Nenhum registro' : this.props.mensagemListaVazia}
                disableClearable={this.props.desabilitaLimpar}
                key={`${this.props.name}-${this.props.valor}`}
                options={this.props.lista}
                getOptionLabel={option => option.title}
                fullWidth
                defaultValue={this.props.lista.find((x) => x.id === this.props.valor)}
                multiple={this.props.multiplaescolha === "true" ? true : false}
                size="small"
                disabled={this.props.desabilitado === "true" ? true : false}
                onChange={this.handleChange}
                onBlur={this.props.onBlur}
                renderInput={params => (
                  <TextFieldComboChild {...params} {...this.props} />
                )}
      
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.title, inputValue);
                  const parts = parse(option.title, matches);
      
                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
              />
      );
  }
}

export class LuxFwSwitchCheck extends Component {
  render() {
    return (
      this.props.tamanho ?
        <div style={ this.props.removePadding && {paddingLeft: 0}} className={`col-md-${this.props.tamanho} col-12`}>
          <IntegrationSwitch disabled={this.props.disabled} estilo={this.props.estilo} semEstilizacao={this.props.semEstilizacao} posicaoLabel={this.props.posicaoLabel} name={this.props.name} valor={this.props.valor} labelSwitch={this.props.label} onChange={this.props.onChange} />
        </div>

        :

        <IntegrationSwitch disabled={this.props.disabled} estilo={this.props.estilo} semEstilizacao={this.props.semEstilizacao} posicaoLabel={this.props.posicaoLabel} name={this.props.name} valor={this.props.valor} labelSwitch={this.props.label} onChange={this.props.onChange} />
    );
  }
}

export class LuxFwOptionButton extends Component {
  render() {
    return (
      <FormControlLabel
        style={{ marginLeft: 0, paddingLeft: 0, fontFamily: 'Roboto', fontSize: '16px', color: '#3A3A3D' }}
        value="end"
        control={<GreenRadio
          value={this.props.valorOpcao}
          id={this.props.name}
          inputProps={{ 'aria-label': 'perm' }}
        />}
        label={this.props.label}
        labelPlacement="end"
      />
    );
  }
}

export class LuxFwRadioGroup extends Component {
  render() {

    const { children } = this.props;

    return (
      <div className={`col-md-${this.props.tamanho} col-12`}>
        <FormControl component="fieldset">
          <FormLabel style={{ fontFamily: 'Roboto', fontSize: '16px' }} component="legend">{this.props.label}</FormLabel>

          {this.props.orientacao === "horizontal" ?
            <RadioGroup id={this.props.name} row name={this.props.name} value={this.props.valor} onChange={this.props.onChange} defaultValue={this.props.valorPadrao}>
              {children}
            </RadioGroup> :

            <RadioGroup id={this.props.name} name={this.props.name} value={this.props.valor} onChange={this.props.onChange} defaultValue={this.props.valorPadrao}>
              {children}
            </RadioGroup>
          }
        </FormControl>
      </div>
    );
  }
}