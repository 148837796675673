import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_LOCALIDADE,
    NOVO_LOCALIDADE,
    BUSCA_LOCALIDADE_EDICAO,
    CANCELA_EDICAO,
    INICIO_LOCALIDADE_SUCESSO,
    SALVA_LOCALIDADE_SUCESSO,
    REMOVE_LOCALIDADE_SUCESSO,
    LISTA_LOCALIDADE,
    LISTA_LOCALIDADE_SUCESSO,
    LIMPAR_LOCALIDADE,
    BUSCA_LOCALIDADE_EDICAO_SUCESSO,
    LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE,
    LISTA_LOCAIS_SUCESSO_LOCALIDADE,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CHECK,
    ALTERACAO_DATEPICKER, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    ADICIONA_DEPARTAMENTO_LISTA_VINCULADA,
    REMOVE_DEPARTAMENTO_LISTA_VINCULADA,
    ADICIONA_PONTO_ACESSO_LISTA_VINCULADA_PERIODO,
    REMOVE_PONTO_ACESSO_LISTA_VINCULADA_PERIODO,
    LISTA_PONTO_ACESSO_SUCESSO_LOCALIDADE,
    LISTA_PONTO_ACESSO_POR_LOCAL,
    ADICIONA_FUNCAO_LISTA_VINCULADA,
    REMOVE_FUNCAO_LISTA_VINCULADA,
    ADICIONA_GRUPO_LISTA_VINCULADA,
    REMOVE_GRUPO_LISTA_VINCULADA,    
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo Localidade', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },        
      { titulo: 'Cliente', propriedadeApi: 'nomeRazaoSocialcliente', alinhamento: 'left' },  
      { titulo: 'Local Controlado',  propriedadeApi: 'descricaoLocalControlado', alinhamento: 'left' },  
      { titulo: 'Localidade', propriedadeApi: 'descricaoLocalidade', alinhamento: 'left' },  
    ],    

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],

    // Campos do Formulario
    id: 0,
    idCliente: 0,
    idLocalControlado: 0,
    descricao: '',
    observacao: '',
    ativo: true,
    departamentos: [],
    pontosAcesso: [],
    funcoes: [],
    grupos: [],
    nomeClienteFixado: null,
    idClienteFixado: 0,
    nomeLocalControlado: '',
    nomeCliente: '',
    idCliente: 0,

    // Campos formulario adicionar lista
    nomeDepartamentoAdd: '',
    ativoAdd: true,
    idPontoAcessoAdd: '',
    nomeFuncaoAdd: '',
    funcaoAtivoAdd: true,
    nomeGrupoAdd: '',
    grupoAtivoAdd: true,
  
    // Combos
    listaDeClientes: [],
    listaDeLocaisControlados: [],
    listaDePontosAcesso: [],

     // Filtros
     nomeClienteFiltro: '',
     nomeLocalControladoFiltro: '',
     nomeLocalidadeFiltro: '',
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_LOCALIDADE: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),    
        }
      }   
      case NOVO_LOCALIDADE: {
        return {
          ...state,
          edicao: true,
          id: 0,
          idLocalControlado: 0,
          descricao: '',
          observacao: '',
          ativo: true,
          departamentos: [],
          pontosAcesso: [], 
          funcoes: [],
          grupos: [],          
          nomeLocalControlado: '',
          nomeCliente: '',
          idCliente: state.idClienteFixado,  
        }
      }       
      case BUSCA_LOCALIDADE_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false
        }
      }
      case INICIO_LOCALIDADE_SUCESSO: {
        
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.localidades,
          listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
          listaDeLocaisControlados: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.locaisControlados, "id", "descricaoLocalControlado") : [],
      
        }
      }                     
      case SALVA_LOCALIDADE_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_LOCALIDADE_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_LOCALIDADE: {
        const retorno = action.payload;

        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_LOCALIDADE_SUCESSO: {
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listGrid: retorno.localidades,
        }
      }
      case LIMPAR_LOCALIDADE: {

        var retorno = action.payload;

        return {
          ...state,
          nomeClienteFiltro: '',
          nomeLocalControladoFiltro: '',
          nomeLocalidadeFiltro: '',
        }
      }
      case BUSCA_LOCALIDADE_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.localidade.id,
          idLocalControlado: retorno.localidade.idLocalControlado,
          nomeLocalControlado: retorno.localidade.nomeLocalControlado,
          descricao: retorno.localidade.descricao,
          observacao: retorno.localidade.observacao,
          ativo: retorno.localidade.ativo,
          nomeCliente: retorno.localidade.nomeCliente,
          idCliente: retorno.localidade.idCliente,
          departamentos: retorno.localidade.departamentos,
          pontosAcesso: retorno.localidade.localidadesPontoAcesso,
          funcoes: retorno.localidade.funcoesPessoa,  
          grupos: retorno.localidade.gruposPessoa,  
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"), 
          listaDePontosAcesso: preparaListaParaCombo(retorno.pontos, "id", "descricaoPontoAcesso"), 
          nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
          idClienteFixado: localStorage.getItem('idClienteFixado'),
        }
      }
      case LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE: {

        const retorno = action.payload;

        return {
          ...state,
          idCliente: retorno.idCliente,
          loader: true,          
        }
      }
      case LISTA_LOCAIS_SUCESSO_LOCALIDADE: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          idLocalControlado: null,
          listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"), 
        }
      }
      case LISTA_PONTO_ACESSO_POR_LOCAL: {

        const retorno = action.payload;

        return {
          ...state,
          idLocalControlado: retorno.idLocal,
          loader: true,          
        }
      }
      case LISTA_PONTO_ACESSO_SUCESSO_LOCALIDADE: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listaDePontosAcesso: preparaListaParaCombo(retorno.pontos, "id", "descricaoPontoAcesso"), 
        }
      }      
      case ADICIONA_DEPARTAMENTO_LISTA_VINCULADA: {
        var retorno = action.payload;
        var registroDuplicado = state.departamentos.filter(x => x.descricao === retorno.descricaoDepartamento).length > 0;

        if(!registroDuplicado){
          var idTemporario = state.departamentos.length > 0 ? Math.max(...state.departamentos.map(x => x.idTemporario)) + 1 : 1;

          const departamentoAdd = {
            "idLocalidade": state.id,
            "descricao": retorno.descricaoDepartamento,
            "ativo": retorno.ativo,
            "idTemporario": idTemporario,
             "id": 0
          }
  
          return {
            ...state,
            departamentos:[...state.departamentos, departamentoAdd],
            nomeDepartamentoAdd: '',
            ativoAdd: true,
         }

        }
        else{
          return {
            ...state,
            nomeDepartamentoAdd: '',
         }
        }
      }       
      case REMOVE_DEPARTAMENTO_LISTA_VINCULADA: {
        var retorno = action.payload;

        const arrayAtualizado = state.departamentos.filter(x => x.idTemporario !== retorno.idTemporario)

        return {
          ...state,
          departamentos: arrayAtualizado,
          nomeDepartamentoAdd: '',
          ativoAdd: true,
       }
      }
      case ADICIONA_PONTO_ACESSO_LISTA_VINCULADA_PERIODO: {
        var retorno = action.payload;
        var registroDuplicado = state.pontosAcesso.filter(x => x.idPontoAcesso === retorno.idPonto).length > 0;
        

        if(retorno.idPonto != "" && !registroDuplicado){
          var idTemporario = state.pontosAcesso.length > 0 ? Math.max(...state.pontosAcesso.map(x => x.idTemporario)) + 1 : 1;
          const ponto = state.listaDePontosAcesso.filter(x => x.id === retorno.idPonto);
          const nomePonto = ponto[0].title;
  
          const pontoAdd = {
            "idLocalidade": state.id,
            "nomePontoAcesso": nomePonto,
            "idPontoAcesso": retorno.idPonto,
            "idTemporario": idTemporario,
            "id": 0
          }
  
          return {
            ...state,
            pontosAcesso:[...state.pontosAcesso, pontoAdd],
            idPontoAcessoAdd: 0,
         }
        }
        else{
          return {
            ...state,
            idPontoAcessoAdd: 0,
         }
        }
      }
      case REMOVE_PONTO_ACESSO_LISTA_VINCULADA_PERIODO: {
        var retorno = action.payload;

        const arrayAtualizado = state.pontosAcesso.filter(x => x.idTemporario !== retorno.idTemporario)

        return {
          ...state,
          pontosAcesso: arrayAtualizado,
       }
      }
      case ADICIONA_GRUPO_LISTA_VINCULADA: {
        var retorno = action.payload;
        var registroDuplicado = state.grupos.filter(x => x.descricao === retorno.descricao).length > 0;

        if(!registroDuplicado){
          var idTemporario = state.grupos.length > 0 ? Math.max(...state.grupos.map(x => x.idTemporario)) + 1 : 1;

          const grupoAdd = {
            "idLocalidade": state.id,
            "descricao": retorno.descricao,
            "ativo": retorno.ativo,
            "idTemporario": idTemporario,
             "id": 0
          }

          return {
            ...state,
            grupos:[...state.grupos, grupoAdd],
            nomeGrupoAdd: '',
            grupoAtivoAdd: true,
         }

        }
        else{
          return {
            ...state,
            nomeGrupoAdd: '',
         }
        }
      }       
      case REMOVE_GRUPO_LISTA_VINCULADA: {
        var retorno = action.payload;

        const arrayAtualizado = state.grupos.filter(x => x.idTemporario !== retorno.idTemporario)

        return {
          ...state,
          grupos: arrayAtualizado,
          nomeGrupoAdd: '',
          grupoAtivoAdd: true,
       }
      }
      case ADICIONA_FUNCAO_LISTA_VINCULADA: {
        var retorno = action.payload;
        var registroDuplicado = state.funcoes.filter(x => x.descricao === retorno.descricao).length > 0;

        if(!registroDuplicado){
          var idTemporario = state.funcoes.length > 0 ? Math.max(...state.funcoes.map(x => x.idTemporario)) + 1 : 1;

          const funcaoAdd = {
            "idLocalidade": state.id,
            "descricao": retorno.descricao,
            "ativo": retorno.ativo,
            "idTemporario": idTemporario,
             "id": 0
          }

          return {
            ...state,
            funcoes:[...state.funcoes, funcaoAdd],
            nomeFuncaoAdd: '',
            funcaoAtivoAdd: true,
         }

        }
        else{
          return {
            ...state,
            nomeFuncaoAdd: '',
         }
        }
      }       
      case REMOVE_FUNCAO_LISTA_VINCULADA: {
        var retorno = action.payload;

        const arrayAtualizado = state.funcoes.filter(x => x.idTemporario !== retorno.idTemporario)

        return {
          ...state,
          funcoes: arrayAtualizado,
          nomeFuncaoAdd: '',
          funcaoAtivoAdd: true,
       }
      }                   
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }       
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }   
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  