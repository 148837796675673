import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import {
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    VALIDACAO_CAMPO,
    LIMPA_VALIDACOES    
  } from "constants/ActionTypes";
  
  const INIT_STATE = {
/*     loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],
    cepConsultado: null,
    nomeRazaoSocial: '',
    nomeFanstasia: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    nomeContato: '',      
    tipoPessoa: 'FISICA',
    numeroCpfCnpj: '', 
    dataNascimentoOuAbertura: '', 
    nomeMae: '',
    sexo: '',
    estadoCivil: '', 
    imagem: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    uf: '',
    nomeUf: '',
    idMunicipio: 0,
    nomeMunicipio: '',
    pontoReferencia: ''     */

    validacoes: [],
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }
      case LIMPA_VALIDACOES: {

        var retorno = action.payload;

        return {
          ...state,
          validacoes: []
        }        
      }
      case VALIDACAO_CAMPO: {

        var retorno = action.payload;

        const validacaoAdd = {
          "erro": retorno.erro,
          "propriedadeValidada": retorno.propriedadeValidada,
          "mensagemErro": retorno.mensagemErro
        }

        return {
          ...state,
          validacoes: !retorno.erro ? state.validacoes.filter(x => x.propriedadeValidada !== retorno.propriedadeValidada) : [...state.validacoes, validacaoAdd],
        }           
      }          
      default:
        return state;
    }
  }
  