import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
  INICIA_ACESSO_PERFIL,
  NOVO_ACESSO_PERFIL,
  BUSCA_ACESSO_PERFIL_EDICAO,
  CANCELA_EDICAO,
  INICIO_ACESSO_PERFIL_SUCESSO,
  SALVA_ACESSO_PERFIL_SUCESSO,
  REMOVE_ACESSO_PERFIL_SUCESSO,
  BUSCA_ACESSO_PERFIL_EDICAO_SUCESSO,
  LISTA_LOCAIS_PERFIS_POR_CLIENTE,
  LISTA_LOCAIS_PERFIS_SUCESSO,
  LISTA_AMBIENTES_POR_LOCAL,
  LISTA_AMBIENTES_SUCESSO,
  LISTA_CONFIGURACAO_PONTO_PERFIL,
  LISTA_CONFIGURACAO_PONTO_PERFIL_SUCESSO,
  ALTERACAO_CHECK_PONTO_ACESSO_PERFIL,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_CHECK,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  LIMPAR_ACESSO_PERFIL,
  LISTA_ACESSO_PERFIL_SUCESSO,
  LISTA_ACESSO_PERFIL   
} from "constants/ActionTypes";

const INIT_STATE = {

  // Configuração da Grid
  colunasGrid: [
    { titulo: 'Codigo', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
    { titulo: 'Cliente', propriedadeApi: 'nomeCliente', alinhamento: 'left' },
    { titulo: 'Local Controlado', propriedadeApi: 'localControlado', alinhamento: 'left' },
    { titulo: 'Perfil', propriedadeApi: 'nomeDestino', alinhamento: 'left' },   
  ],

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],

  // Campos do Formulario
  id: 0,
  idCliente: 0,
  idLocalControlado: 0,
  idAmbiente: 0,
  idPerfil: 0,
  configuracaoPontoPerfil: null,
  configuracaoAtualizada: null,
  nomeClienteFixado: null,
  idClienteFixado: 0,

  // Combos
  listaDeClientes: [],
  listaDeLocaisControlados: [],
  listaDeAmbientes: [],
  listaDeEquipamentos: [],
  listaDePerfis: [],

   // Filtros
   perfilFiltro: '', 
   ambienteFiltro: '', 
   pontoFiltro: '', 
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_ACESSO_PERFIL: {
      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
        idClienteFixado: localStorage.getItem('idClienteFixado')           
      }
    }
    case NOVO_ACESSO_PERFIL: {
      return {
        ...state,
        edicao: true,
        id: 0,
        idCliente: state.idClienteFixado !== 0 ? parseInt(state.idClienteFixado) : 0,
        idLocalControlado: 0,
        idAmbiente: 0,
        idPerfil: 0,
        configuracaoPontoPerfil: null
      }
    }
    case BUSCA_ACESSO_PERFIL_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false
      }
    }
    case INICIO_ACESSO_PERFIL_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        listGrid: retorno.acessosPerfil,
        listaDeLocaisControlados: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado") : [],
        listaDePerfis: state.idClienteFixado !== 0 ? preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil") : [],
        listaDeClientes: preparaListaParaCombo(retorno.clientes, "id", "nomeRazaoSocial"),
        nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
        idClienteFixado: localStorage.getItem('idClienteFixado'),           
      }
    }
    case SALVA_ACESSO_PERFIL_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_ACESSO_PERFIL_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case LISTA_ACESSO_PERFIL: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_ACESSO_PERFIL_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.acessoPerfil,
      }
    }
    case LIMPAR_ACESSO_PERFIL: {

      var retorno = action.payload;

      return {
        ...state,
        perfilFiltro: '', 
        ambienteFiltro: '', 
        pontoFiltro: '', 
      }
    }              
    case BUSCA_ACESSO_PERFIL_EDICAO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        idCliente: retorno.idCliente,
        idLocalControlado: retorno.idLocalControlado,
        idPerfil: retorno.idPerfil,
        nomePerfil: retorno.nomePerfil,
        nomeLocalControlado: retorno.nomeLocalControlado,
        nomeCliente: retorno.nomeCliente,
        configuracaoPontoPerfil: retorno.vinculoAcessoPerfil,
        nomeClienteFixado: localStorage.getItem('nomeClienteFixado'),
        idClienteFixado: localStorage.getItem('idClienteFixado'),           
      }
    }
    case LISTA_LOCAIS_PERFIS_POR_CLIENTE: {

      const retorno = action.payload;

      return {
        ...state,
        idCliente: retorno.idCliente,
        loader: true,
      }
    }
    case LISTA_AMBIENTES_POR_LOCAL: {
      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_LOCAIS_PERFIS_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        idLocalControlado: null,
        listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"),
        listaDePerfis: preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil")
      }
    }
    case LISTA_CONFIGURACAO_PONTO_PERFIL: {
      return {
        ...state,
        idLocalControlado: state.idLocalControlado,
        loader: true
      }
    }
    case LISTA_CONFIGURACAO_PONTO_PERFIL_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        configuracaoPontoPerfil: retorno.configuracao
      }
    }
    case ALTERACAO_CHECK_PONTO_ACESSO_PERFIL: {

      const retorno = action.payload;
      var idPontoAlterado = retorno.idPontoAcessoAlterado;

      const listaAmbientes = state.configuracaoPontoPerfil.listaAmbientesVinculados;

      for (var i = 0; i < listaAmbientes.length; i++) {
        var listaPontos = listaAmbientes[i].pontosAcessoVinculados;

        for (var j = 0; j < listaPontos.length; j++) {
          var idPontoAcesso = listaPontos[j].idPontoAcesso;

          if (idPontoAcesso.toString() === idPontoAlterado) {
            listaPontos[j].associado = retorno.valor;
          }
        }

      }

      const configuracaoAtualizada2 = state.configuracaoPontoPerfil;
      configuracaoAtualizada2.listaAmbientesVinculados = listaAmbientes;

      return {
        ...state,
        configuracaoPontoPerfil: {
          ...state.configuracaoPontoPerfil,
          listaAmbientesVinculados: configuracaoAtualizada2.listaAmbientesVinculados,
        },
      }
    }
    case LISTA_AMBIENTES_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listaDeAmbientes: preparaListaParaCombo(retorno.ambientes, "id", "descricaoAmbiente"),
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_CHECK: {
      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
      }
    }
    case ALTERACAO_COMBO: {
      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
