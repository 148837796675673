import React, { Component, Fragment, useState } from 'react';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

export default class Topo extends Component {

    render() {

        const carregandoPaginaPerfil = this.props.portariaInicio.carregandoPaginaPerfil;
        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        const ambientesDoAutorizante = this.props.portariaInicio.ambientesDoAutorizante;
        var imagemTratada = pessoaSelecionada.imagem;
        imagemTratada = imagemTratada !== undefined && imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

        return carregandoPaginaPerfil ? (
            <Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <div style={{ maxWidth: '400px', height: '75px', lineHeight: '75px', float: 'left', minWidth: '55%' }}>
                            <div style={{ float: 'left', fontSize: '18px', height: '45.5px', display: 'inline-block', lineHeight: '50.0px', verticalAlign: 'bottom', width: '100%', fontWeight: 600 }}>
                                <Skeleton variant="text" width={'80%'} height={55} />
                            </div>
                            <div style={{ float: 'left', height: '0px', lineHeight: '25.0px', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                <Skeleton variant="text" width={'30%'} height={35} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton variant="text" width={'90%'} height={90} />
                    </Grid>
                </Grid>


            </Fragment>
        ) :
            (
                <Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <div style={{ maxWidth: '400px', height: '75px', lineHeight: '75px', float: 'left', minWidth: '55%' }}>
                                <div style={{ float: 'left', fontSize: this.props.portariaInicio.parametroOcultaDocumento ? '24px' : '18px', height: '45.5px', display: 'inline-block', lineHeight: '50.0px', verticalAlign: 'bottom', width: '100%', fontWeight: 600 }}>
                                    {pessoaSelecionada.nomeRazao}
                                </div>

                                {
                                    this.props.portariaInicio.parametroOcultaDocumento ?
                                    <div style={{ float: 'left', height: '0px', lineHeight: '25.0px', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                            {this.props.portariaInicio.parametroTipoControleVisibilidade === 'MASCARA_PARCIAL' ? pessoaSelecionada.numeroDocumento.substr(0, 4) + "***.***-**" : ''}
                                        </div> :
                                        <div style={{ float: 'left', height: '0px', lineHeight: '25.0px', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                            {pessoaSelecionada.numeroDocumento}
                                        </div>
                                }


                                {/*                             <div style={{ float: 'left', height: '0px', lineHeight: '25.0px', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                <PermContactCalendarIcon />  {pessoaSelecionada.numeroDocumento}
                            </div> */}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            {
                                ambientesDoAutorizante.length > 0 ? (
                                    <NavLink onClick={() => { this.props.novaLiberacao(); }} style={{ textDecoration: 'none', color: '#000' }} className="prepend-icon" to={{ pathname: '/app/nlibera', state: [{ id: 1, name: 'teste', color: 'red' }] }}>
                                        <Button style={{ width: '95%', marginTop: 8, padding: '14px 36px', backgroundColor: '#05B59D', borderRadius: 100 }} variant="contained" >
                                            <span style={{ fontStyle: 'normal', color: '#ffffff', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Novo Convite</span>
                                        </Button>
                                    </NavLink>
                                ) :
                                    (<div style={{ cursor: 'not-allowed' }}>
                                        <Button disabled style={{ width: '95%', marginTop: 8, padding: '14px 36px', borderRadius: 100 }} variant="contained" >
                                            <span style={{ fontStyle: 'normal', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: 16, letterSpacing: '1.25px', textTransform: 'none' }}>Novo Convite</span>
                                        </Button>
                                    </div>

                                    )
                            }
                        </Grid>
                    </Grid>


                </Fragment>
            )
    }

}