import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_PERFIL_PORTAL, INICIA_PERFIL_PORTAL, BUSCA_PERFIL_PORTAL_EDICAO, REMOVE_PERFIL_PORTAL, LISTA_PERFIL_PORTAL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaPerfilSucesso,
         removePerfilSucesso, 
         inicioPerfilSucesso, 
         iniciaPerfil,
         buscaPerfilEdicaoSucesso,
         listaPerfilSucesso } from "actions/PerfilPortal";
import { limpaValidacoes } from "actions/Global";           

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getPerfis = async () =>
    await requisicaoApi.get('/perfisPortal')
    .then(resposta => resposta)
    .catch(error => error);

    const getAmbientesPortaria = async () =>
    await requisicaoApi.get('/util/ambientesPortaria')
    .then(resposta => resposta)
    .catch(error => error);

    const getBlocosFuncionalidade = async () =>
    await requisicaoApi.get('/util/blocosFuncionalidade')
    .then(resposta => resposta)
    .catch(error => error);    

    const getFuncionalidadesAcao = async () =>
    await requisicaoApi.get('/util/funcionalidadesAcao')
    .then(resposta => resposta)
    .catch(error => error);     

  const getPerfilEdicao = async (id) =>
    await requisicaoApi.get('/perfisPortal/' + id)
    .then(resposta => resposta)
    .catch(error => error);  
    
    const getListaPerfil = async (nomeFiltro) => 
    await requisicaoApi.get('/perfisPortal?Nome=' +  nomeFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaPerfilRequest = async (perfil) => await requisicaoApi.post('/perfisPortal', 
  {
    "id": perfil.id,
    "descricaoPerfil": perfil.descricaoPerfil,
    "ativo": perfil.ativo,
    "tipoPerfil": "PORTAL",
    "configuracaoAcessoPortalPerfil": {
      "id": perfil.configuracaoAcessoPortalPerfil.id,
      "idClienteUsuarioSistema": perfil.configuracaoAcessoPortalPerfil.idClienteUsuarioSistema,
      "tipoConfiguracaoAcessoPortal": "PERFIL",
      "idUsuarioSistema": perfil.configuracaoAcessoPortalPerfil.idUsuarioSistema,
      "idPerfilSistema": perfil.configuracaoAcessoPortalPerfil.idPerfilSistema,
      "visualizaDadosPortaria": perfil.configuracaoAcessoPortalPerfil.visualizaDadosPortaria,
      "concedeAcessos": perfil.configuracaoAcessoPortalPerfil.concedeAcessos,
      "usuarioMantemGaragem": perfil.configuracaoAcessoPortalPerfil.usuarioMantemGaragem,
      "uzerAcessoPainelAdministrativo": perfil.configuracaoAcessoPortalPerfil.uzerAcessoPainelAdministrativo,
      "uzerAcessoAmbienteCliente": perfil.configuracaoAcessoPortalPerfil.uzerAcessoAmbienteCliente,
      "listaPontosAcessoPortaria": perfil.listaPontosAcessoPortaria,
      "listaConfiguracaoPortalFuncionalideAcao": perfil.listaConfiguracaoPortalFuncionalideAcao
    },    
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaPerfilRequest = async (perfil) => await requisicaoApi.put('/perfisPortal', 
  {
    "id": perfil.id,
    "descricaoPerfil": perfil.descricaoPerfil,
    "observacao": perfil.observacao,
    "ativo": perfil.ativo,
    "tipoPerfil": "PORTAL",
    "configuracaoAcessoPortalPerfil": {
      "id": perfil.configuracaoAcessoPortalPerfil.id,
      "idClienteUsuarioSistema": perfil.configuracaoAcessoPortalPerfil.idClienteUsuarioSistema,
      "tipoConfiguracaoAcessoPortal": "PERFIL",
      "idUsuarioSistema": perfil.configuracaoAcessoPortalPerfil.idUsuarioSistema,
      "idPerfilSistema": perfil.configuracaoAcessoPortalPerfil.idPerfilSistema,
      "visualizaDadosPortaria": perfil.configuracaoAcessoPortalPerfil.visualizaDadosPortaria,
      "concedeAcessos": perfil.configuracaoAcessoPortalPerfil.concedeAcessos,
      "usuarioMantemGaragem": perfil.configuracaoAcessoPortalPerfil.usuarioMantemGaragem,
      "uzerAcessoPainelAdministrativo": perfil.configuracaoAcessoPortalPerfil.uzerAcessoPainelAdministrativo,
      "uzerAcessoAmbienteCliente": perfil.configuracaoAcessoPortalPerfil.uzerAcessoAmbienteCliente,
      "listaPontosAcessoPortaria": perfil.listaPontosAcessoPortaria,
      "listaConfiguracaoPortalFuncionalideAcao": perfil.listaConfiguracaoPortalFuncionalideAcao
    },

  })
  .then(resposta => resposta)
  .catch(error => error);

  const deletePerfilRequest = async (id) =>
  await requisicaoApi.delete('/perfisPortal?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaPerfil() {
    try {
      const listagemGrid = yield call(getPerfis);
  
      if(!listagemGrid.isAxiosError && listagemGrid.data)
      {
        const ambientesPortaria = yield call(getAmbientesPortaria);

        if(ambientesPortaria.data) {

          const blocosFuncionalidade = yield call(getBlocosFuncionalidade);

          if(blocosFuncionalidade.data) {

            const funcionalidadesAcao = yield call(getFuncionalidadesAcao);

            if(funcionalidadesAcao.data) {
              yield put(inicioPerfilSucesso(listagemGrid.data, ambientesPortaria.data, blocosFuncionalidade.data, funcionalidadesAcao.data));
              yield put(limpaValidacoes());
            }
            
          }
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar perfis'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaPerfilEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getPerfilEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaPerfilEdicaoSucesso(retorno.data));
        yield put(limpaValidacoes());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do perfil: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaPerfil({ payload }) {
    try {
      const nomeFiltro = payload.nome;
      const retorno = yield call(getListaPerfil, nomeFiltro);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(listaPerfilSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de perfil'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaPerfil({ payload }) {
  try {

    const perfil = payload;
    var resultado = "";

      if(perfil.id === 0) {
        const retorno = yield call(adicionaPerfilRequest, perfil);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaPerfilRequest, perfil);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaPerfil());
        yield put(salvaPerfilSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }         

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemovePerfil({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deletePerfilRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaPerfil());
        yield put(removePerfilSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesPerfil() {
  yield takeEvery(INICIA_PERFIL_PORTAL, fnIniciaPerfil);
}

export function* salvaPerfil() {
  yield takeEvery(SALVA_PERFIL_PORTAL, fnSalvaPerfil);
}

export function* buscaPerfilEdicao() {
  yield takeEvery(BUSCA_PERFIL_PORTAL_EDICAO, fnBuscaPerfilEdicao);
}

export function* removePerfil() {
  yield takeEvery(REMOVE_PERFIL_PORTAL, fnRemovePerfil);
}

export function* listaPerfil() {
  yield takeEvery(LISTA_PERFIL_PORTAL, fnListaPerfil)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesPerfil), 
             fork(salvaPerfil), 
             fork(buscaPerfilEdicao),
             fork(removePerfil),
             fork(listaPerfil)]);
}