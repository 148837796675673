import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
  INICIA_PERFIL_PORTAL,
  NOVO_PERFIL_PORTAL,
  BUSCA_PERFIL_PORTAL_EDICAO,
  CANCELA_EDICAO,
  INICIO_PERFIL_PORTAL_SUCESSO,
  SALVA_PERFIL_PORTAL_SUCESSO,
  REMOVE_PERFIL_PORTAL_SUCESSO,
  BUSCA_PERFIL_PORTAL_EDICAO_SUCESSO,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_CHECK,
  ALTERACAO_CHECK_PERFIL,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  LIMPAR_PERFIL_PORTAL,
  LISTA_PERFIL_PORTAL_SUCESSO,
  LISTA_PERFIL_PORTAL,
  EXIBE_PONTOS_REQUISICAO,
  ATUALIZAR_PONTO_REQUISICAO,
  ATUALIZAR_PERMISSAO_FUNCIONALIDADE,
  SELECIONAR_TUDO_FUNCIONALIDADE_PERFIL,
  SELECIONAR_TUDO_PORTARIA_PERFIL,
} from "constants/ActionTypes";

const INIT_STATE = {

  // Configuração da Grid
  colunasGrid: [
    { titulo: 'Codigo Perfil', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },
    { titulo: 'Perfil do Portal', propriedadeApi: 'descricaoPerfil', alinhamento: 'left' },
    { titulo: 'Ativo', propriedadeApi: 'ativo', alinhamento: 'left', booleano: true },
  ],

  novaConfiguracao: {
    "id": 0,
    "tipoConfiguracaoAcessoPortal": "PERFIL",
    "idPerfilSistema": 0,
    "visualizaDadosPortaria": false,
    "concedeAcessos": false,
    "uzerAcessoPainelAdministrativo": false,
    "uzerAcessoAmbienteCliente": false,
    "listaPontosAcessoPortaria": [],
    "listaDeAmbientesPortariaDoCliente": [],
    "listaBlocosFuncionalidade": [],
    "listaConfiguracaoPortalFuncionalideAcao": []
  },   

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  carregandoLista: true,
  listGrid: [],
  textoFiltro: '',

  // Campos do Formulario

  id: 0,
  descricaoPerfil: '',
  ativo: true,

  // outros
  exibePontosRequisicaoPerfil: false,
  idAmbienteSelecionadoRequisicao: 0,

  // Filtros
  nomeFiltro: '',
  configuracaoAcessoPortalPerfil: {},
  listaFuncionalidadesAcao: [],
  listaConfiguracaoPortalFuncionalideAcao: [],
  listaPontosAcessoPortaria: [],
  listaDeAmbientesPortariaDoClienteNovo: [],
  listaBlocosFuncionalidadeNovo: [],
  listaFuncionalidadesAcaoNovo: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INICIA_PERFIL_PORTAL: {
      return {
        ...state,
        edicao: false,
        loader: true,
        carregandoLista: true,
        textoFiltro: '',
      }
    }
    case NOVO_PERFIL_PORTAL: {

      var configuracao = state.novaConfiguracao;
      configuracao.concedeAcessos = false;
      configuracao.visualizaDadosPortaria = false;
      configuracao.usuarioMantemGaragem = false;
      configuracao.listaDeAmbientesPortariaDoCliente = state.listaDeAmbientesPortariaDoClienteNovo;
      configuracao.listaBlocosFuncionalidade = state.listaBlocosFuncionalidadeNovo;
      configuracao.listaFuncionalidadesAcao = state.listaFuncionalidadesAcaoNovo;
      configuracao.listaPontosAcessoPortaria = [];
      configuracao.listaConfiguracaoPortalFuncionalideAcao = [];

      return {
        ...state,
        edicao: true,
        id: 0,
        descricaoPerfil: '',
        ativo: true,
        tipoPerfil: '',
        configuracaoAcessoPortalPerfil: configuracao,
        listaConfiguracaoPortalFuncionalideAcao: []
      }
    }
    case BUSCA_PERFIL_PORTAL_EDICAO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CANCELA_EDICAO: {
      return {
        ...state,
        edicao: false,
        textoFiltro: '',
      }
    }
    case INICIO_PERFIL_PORTAL_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        listGrid: retorno.perfis,
        listaDeAmbientesPortariaDoClienteNovo: retorno.ambientesPortaria,
        listaBlocosFuncionalidadeNovo: retorno.blocosFuncionalidade,
        listaFuncionalidadesAcaoNovo: retorno.funcionalidadesAcao
      }
    }
    case SALVA_PERFIL_PORTAL_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case REMOVE_PERFIL_PORTAL_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        edicao: false
      }
    }
    case LISTA_PERFIL_PORTAL: {
      const retorno = action.payload;

      return {
        ...state,
        loader: true,
      }
    }
    case LISTA_PERFIL_PORTAL_SUCESSO: {
      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        listGrid: retorno.perfil,
      }
    }
    case LIMPAR_PERFIL_PORTAL: {

      var retorno = action.payload;

      return {
        ...state,
        nomeFiltro: '',
      }
    }
    case BUSCA_PERFIL_PORTAL_EDICAO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        edicao: true,
        loader: false,

        id: retorno.id,
        descricaoPerfil: retorno.descricaoPerfil,
        ativo: retorno.ativo,
        configuracaoAcessoPortalPerfil: retorno.configuracaoAcessoPortalPerfil,
        listaPontosAcessoPortaria: retorno.configuracaoAcessoPortalPerfil.listaPontosAcessoPortaria,
        listaFuncionalidadesAcao: retorno.configuracaoAcessoPortalPerfil.listaFuncionalidadesAcao,
        listaConfiguracaoPortalFuncionalideAcao: retorno.configuracaoAcessoPortalPerfil.listaConfiguracaoPortalFuncionalideAcao,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
      }
    }
    case EXIBE_PONTOS_REQUISICAO: {
      var retorno = action.payload;
      return {
        ...state,
        exibePontosRequisicaoPerfil: !state.exibePontosRequisicaoPerfil,
        idAmbienteSelecionadoRequisicao: retorno,
      }
    }
    case ATUALIZAR_PERMISSAO_FUNCIONALIDADE: {

      var retorno = action.payload;
      var arrayAtualizado = [];  
      var configuracao = state.configuracaoAcessoPortalPerfil;  
      
      if(retorno.valor) {

        const funcAdd = {
          "funcionalidadeAcaoUsuarioPortal": null,
          "id": 0,
          "idConfiguracaoAcessoPortal": state.configuracaoAcessoPortalPerfil.id,
          "idFuncionalidadeAcaoUsuarioPortal": retorno.idAtualizacao       
        }

        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao;
        arrayAtualizado = [...arrayAtualizado, funcAdd];
      }
      else {
        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.idFuncionalidadeAcaoUsuarioPortal !== retorno.idAtualizacao)
      }

      configuracao.listaConfiguracaoPortalFuncionalideAcao = arrayAtualizado.listaConfiguracaoPortalFuncionalideAcao;      

      return {
        ...state,
        listaConfiguracaoPortalFuncionalideAcao: arrayAtualizado,
        configuracaoAcessoPortalPerfil: configuracao
      }         
    }

    case SELECIONAR_TUDO_PORTARIA_PERFIL: {

      var configuracao = state.configuracaoAcessoPortalPerfil;
      configuracao.visualizaDadosPortaria = true;
      configuracao.concedeAcessos = true;
      configuracao.usuarioMantemGaragem = true;

      return {
        ...state,
        configuracaoAcessoPortalPerfil: configuracao
      }
    }    

    case SELECIONAR_TUDO_FUNCIONALIDADE_PERFIL: {
      
      var retorno = action.payload;
      var arrayAtualizado = [];  
      var configuracao = state.configuracaoAcessoPortalPerfil;

      if(retorno) {

        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao;

        for(var indice in state.configuracaoAcessoPortalPerfil.listaFuncionalidadesAcao) {
          var funcionalidadeAcao = state.configuracaoAcessoPortalPerfil.listaFuncionalidadesAcao[indice];

          if(funcionalidadeAcao.funcionalidadePortal.idBlocoFuncionalidadePortal === retorno) {

            var funcAdd = {
              "funcionalidadeAcaoUsuarioPortal": null,
              "id": 0,
              "idConfiguracaoAcessoPortal": state.configuracaoAcessoPortalPerfil.id,
              "idFuncionalidadeAcaoUsuarioPortal": funcionalidadeAcao.id     
            }
              
            arrayAtualizado = [...arrayAtualizado, funcAdd];

          }

        }
      }
      else {
        arrayAtualizado = state.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.idFuncionalidadeAcaoUsuarioPortal !== retorno.idAtualizacao)
      }

      configuracao.listaConfiguracaoPortalFuncionalideAcao = arrayAtualizado.listaConfiguracaoPortalFuncionalideAcao; 

      return {
        ...state,
        listaConfiguracaoPortalFuncionalideAcao: arrayAtualizado,
        configuracaoAcessoPortalPerfil: configuracao,
        idPerfilDoUsuario: 0        
      }
    }    

    case ATUALIZAR_PONTO_REQUISICAO: {
      var retorno = action.payload;
      var arrayAtualizado = [];  
      var configuracao = state.configuracaoAcessoPortalPerfil;

      if(retorno.valor) {
        const pontoAdd = {
          "configuracaoAcessoPortal": null,
          "idConfiguracaoAcessoPortal": state.configuracaoAcessoPortalPerfil.id,
          "pontoAcesso": {
            "id": retorno.idAtualizacao,
            "idAmbiente": state.idAmbienteSelecionadoRequisicao
          },
          "idPontoAcesso": retorno.idAtualizacao,
          "id": 0,
        }
  
        arrayAtualizado = state.listaPontosAcessoPortaria;
        arrayAtualizado = [...arrayAtualizado, pontoAdd];
      }
      else {
        arrayAtualizado = state.listaPontosAcessoPortaria.filter(x => x.idPontoAcesso !== retorno.idAtualizacao)
      }

      configuracao.listaPontosAcessoPortaria = arrayAtualizado.listaPontosAcessoPortaria;

      return {
        ...state,
        listaPontosAcessoPortaria: arrayAtualizado,
        configuracaoAcessoPortalPerfil: configuracao
      }      
/*       var retorno = action.payload;
      var arrayAtualizado = state.configuracaoAcessoPortalPerfil;

      if(retorno.valor) {
        const pontoAdd = {
          "configuracaoAcessoPortal": null,
          "idConfiguracaoAcessoPortal": 1,
          "pontoAcesso": null,
          "idPontoAcesso": retorno.idAtualizacao,
          "id": 0,
        }
  
        arrayAtualizado.listaPontosAcessoPortaria.push(pontoAdd);
      }
      else {
        arrayAtualizado.listaPontosAcessoPortaria = arrayAtualizado.listaPontosAcessoPortaria.filter(x => x.idPontoAcesso !== retorno.idAtualizacao);
      }

      return {
        ...state,
        configuracaoAcessoPortalPerfil: state.configuracaoAcessoPortalPerfil
      } */
    }        
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_CHECK_PERFIL: {

      var campo = action.payload.nomeCheck[0];
      var configuracao = state.configuracaoAcessoPortalPerfil;

      if(campo === "visualizaDadosPortaria") {
        configuracao.visualizaDadosPortaria = action.payload.valor;
      }

      if(campo === "concedeAcessos") {
        configuracao.concedeAcessos = action.payload.valor;
      }  
      
      if(campo === "usuarioMantemGaragem") {
        configuracao.usuarioMantemGaragem = action.payload.valor;
      }

      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
        configuracaoAcessoPortalPerfil: configuracao
      }
    }    
    case ALTERACAO_CHECK: {

      var campo = action.payload.nomeCheck[0];
      var configuracao = state.configuracaoAcessoPortalPerfil;

      if(campo === "visualizaDadosPortaria") {
        configuracao.visualizaDadosPortaria = action.payload.valor;
      }

      if(campo === "concedeAcessos") {
        configuracao.concedeAcessos = action.payload.valor;
      }         
      
      if(campo === "usuarioMantemGaragem") {
        configuracao.usuarioMantemGaragem = action.payload.valor;
      }    

/*       var campo = action.payload.nomeCheck[0];
      var configuracao = state.configuracaoAcessoPortalPerfil;

      if(campo === "visualizaDadosPortaria") {
        configuracao.visualizaDadosPortaria = action.payload.valor;
      }

      if(campo === "concedeAcessos") {
        configuracao.concedeAcessos = action.payload.valor;
      } */

      return {
        ...state,
        [action.payload.nomeCheck]: action.payload.valor,
        configuracaoAcessoPortalPerfil: configuracao
      }
    }
    case ALTERACAO_COMBO: {
      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    default:
      return state;
  }
}
