import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import AlternaModulos from 'components/AlternaModulos';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
/* import ColorOption from 'containers/Customizer/ColorOption'; */
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import { Fragment } from 'react';
import { fechaModulos, selecionaModulos, ativaTestes, selecionaPessoaSimulacao, executaSimulacao } from 'actions/Auth';
import { irParaModulo } from 'actions/Setting';
import HeaderPortaria from '../components/HeaderPortaria/';

class App extends React.Component {

  ObtemRotasUsuario = (configuracao) => {
    var rotasAutorizadas = [];
    
    var ehUzer = configuracao.uzerAcessoAmbienteCliente || configuracao.uzerAcessoPainelAdministrativo || configuracao.idPerfilSistema === 1;

    for (var indice in configuracao.menus) {
      var menu = configuracao.menus[indice];

      for (var indice in menu.itensDoMenu) {
        var item = menu.itensDoMenu[indice];
        var autorizado = configuracao.listaConfiguracaoPortalFuncionalideAcao.filter(x => x.funcionalidadeAcaoUsuarioPortal.funcionalidadePortal.idItemMenu === item.id).length > 0;

        if (autorizado) {

          if (!ehUzer && item.descricaoItem === 'Clientes') {
            item.descricaoItem = 'Meu negócio';
            item.rota = 'routes/clientes/indexClienteFixo'
          }

          rotasAutorizadas.push(item);
        }
      }
    }

    return rotasAutorizadas;
  }

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition, perfil, selecionarModulo, acessaBackOffice, acessaPortaria, configuracao } = this.props;
    const selecionaModulo = selecionarModulo;
    const rotasAutorizadas = configuracao !== undefined ? this.ObtemRotasUsuario(configuracao) : [];
    const drawerStyle = selecionaModulo ? 'collapsible-drawer' : drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>

        <Fragment>
          <Sidebar acessaPortaria={acessaPortaria} selecionaModulo={selecionaModulo} />
          <div className="app-main-container">

            <div
              className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
              {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                <TopNav styleName="app-top-header" />}

              <Header acessaPortaria={acessaPortaria} {...this.props} selecionaModulo={selecionaModulo} />

              {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                <TopNav />}
            </div>

            <main className="app-main-content-wrapper">
              <div className="app-main-content">

                {
                  selecionaModulo ?
                    <AlternaModulos {...this.props} exibeFechar={!this.props.loginRecente} funcaoFechar={() => { this.props.fechaModulos(); }} /> :
                    <Switch>
                      <Route path={`${match.url}/inicio`} component={asyncComponent(() => import('./routes/inicio/index'))} />


                      {
                        rotasAutorizadas.map(rota => {
                          return (
                            <Route key={`key${rota.id}`} path={`${match.url}/${rota.path}`} component={asyncComponent(() => import(`./${rota.rota}`))} />
                          )
                        })
                      }

                      {/*                         <Route path={`${match.url}/inicio`} component={asyncComponent(() => import('./routes/inicio/index'))} />
                        <Route path={`${match.url}/importar`} component={asyncComponent(() => import('./routes/construcao/index'))} />
                        <Route path={`${match.url}/equipamentos`} component={asyncComponent(() => import('./routes/equipamentos/index'))} />
                        <Route path={`${match.url}/usuariosPlataforma`} component={asyncComponent(() => import('./routes/usuariosPlataforma/index'))} />
                        <Route path={`${match.url}/usuariosPortal`} component={asyncComponent(() => import('./routes/usuariosPortal/index'))} />
                        <Route path={`${match.url}/dadosExternos`} component={asyncComponent(() => import('./routes/dadosExternos/index'))} />
                        <Route path={`${match.url}/modelos`} component={asyncComponent(() => import('./routes/modelos/index'))} />
                        <Route path={`${match.url}/clientes`} component={asyncComponent(() => import('./routes/clientes/index'))} />
                        <Route path={`${match.url}/locais`} component={asyncComponent(() => import('./routes/locais/index'))} />
                        <Route path={`${match.url}/pontosAcesso`} component={asyncComponent(() => import('./routes/pontosAcesso/index'))} />
                        <Route path={`${match.url}/perfis`} component={asyncComponent(() => import('./routes/perfis/index'))} />
                        <Route path={`${match.url}/perfisPortal`} component={asyncComponent(() => import('./routes/perfisPortal/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/perfil`} component={asyncComponent(() => import('./routes/concessoesAcesso/perfil/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/genero`} component={asyncComponent(() => import('./routes/concessoesAcesso/genero/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/idade`} component={asyncComponent(() => import('./routes/concessoesAcesso/idade/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/periodo`} component={asyncComponent(() => import('./routes/concessoesAcesso/periodo/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/usuario`} component={asyncComponent(() => import('./routes/concessoesAcesso/usuario/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/full`} component={asyncComponent(() => import('./routes/concessoesAcesso/full/index'))} />
                        <Route path={`${match.url}/concessoesAcesso/bloqueio`} component={asyncComponent(() => import('./routes/concessoesAcesso/bloqueio/index'))} />
                        <Route path={`${match.url}/localidades`} component={asyncComponent(() => import('./routes/localidades/index'))} />
                        <Route path={`${match.url}/vinculosPessoa`} component={asyncComponent(() => import('./routes/vinculosPessoa/index'))} />
                        <Route path={`${match.url}/unidades`} component={asyncComponent(() => import('./routes/unidades/index'))} />
                        <Route path={`${match.url}/categorias`} component={asyncComponent(() => import('./routes/categorias/index'))} />
                        <Route path={`${match.url}/titularidades`} component={asyncComponent(() => import('./routes/titularidades/index'))} />
                        <Route path={`${match.url}/ambientes`} component={asyncComponent(() => import('./routes/ambientes/index'))} />
                        <Route path={`${match.url}/gestaoAcesso`} component={asyncComponent(() => import('./routes/gestaoAcesso/index'))} />
                        <Route path={`${match.url}/gestaoAcessoTemporario`} component={asyncComponent(() => import('./routes/gestaoAcessoTemporario/index'))} />
                        <Route path={`${match.url}/portaria/inicio`} component={asyncComponent(() => import('./portaria/index'))} /> */}
                    </Switch>
                }


              </div>
              <Footer />
            </main>
          </div>
        </Fragment>

      </div>
    );
  }
}


const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition, configuracao, loginRecente } = settings;
  const { perfil, selecionarModulo, acessaPortaria, acessaBackOffice } = auth
  return { drawerType, navigationStyle, horizontalNavPosition, perfil, selecionarModulo, acessaPortaria, acessaBackOffice, configuracao, loginRecente }
};
export default withRouter(connect(mapStateToProps, { fechaModulos, selecionaModulos, ativaTestes, executaSimulacao, selecionaPessoaSimulacao, irParaModulo })(App));