import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { estadosCivis, generos, tiposPessoa, tiposControleVisibilidade } from '../app/constantes/Listas';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_CLIENTE,
    CONSULTA_CEP,
    NOVO_CLIENTE,
    BUSCA_CLIENTE_EDICAO,
    CANCELA_EDICAO,
    INICIO_CLIENTE_SUCESSO,
    SALVA_CLIENTE,
    SALVA_CLIENTE_SUCESSO,
    REMOVE_CLIENTE_SUCESSO,
    LISTA_CLIENTE,
    LISTA_CLIENTE_SUCESSO,
    LIMPAR_CLIENTE,
    NOVO_TELEFONE,
    ATUALIZA_TELEFONE,
    REMOVE_TELEFONE,
    NOVO_EMAIL,
    ATUALIZA_EMAIL,
    REMOVE_EMAIL,        
    BUSCA_CLIENTE_EDICAO_SUCESSO,
    CONSULTA_CEP_SUCESSO,
    CONSULTA_CEP_ERRO,
    SETAR_IMAGEM_CLIENTE,    
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_DATEPICKER,
    ALTERACAO_CHECK, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    CLIQUE_CONTROLE_ABA_CLIENTE,    
    APLICA_FILTRO
  } from "constants/ActionTypes";
  
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo Cliente', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
      { titulo: 'Foto', propriedadeApi: 'imagem', alinhamento: 'left', imagem: true },      
      { titulo: 'Tipo Pessoa', propriedadeApi: 'tipoPessoaStr', alinhamento: 'left' },
      { titulo: 'CPF / CNPJ', propriedadeApi: 'numeroCpfCnpj', alinhamento: 'left' },
      { titulo: 'Nome / Razão Social', propriedadeApi: 'nomeRazaoSocial', alinhamento: 'left' },
    ],

    telefonesNovo: [

      {
        id: 0,
        idTemporario: 0,
        numeroTelefone: '',
        marcador: 'Principal',
      }      
    ],

    emailsNovo: [

      {
        id: 0,
        idTemporario: 0,
        email: '',
        marcador: 'Principal',
      }      
    ],
    
    // Propriedades Globais
    loader: false,
    alertMessage: '',
    textoFiltro: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],
    listGridComFiltro: [],
    cepConsultado: null,
    codigoIbge: null,

    // Campos do Formulario
    id: 0,
    nomeRazaoSocial: '',
    nomeFanstasia: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    nomeContato: '',      
    tipoPessoa: 'JURIDICA',
    numeroCpfCnpj: '', 
    dataNascimentoOuAbertura: '', 
    nomeMae: '',
    sexo: '',
    estadoCivil: '', 
    imagem: '',
    cep: '',
    logradouro: '',
    numero: '',
    semNumero: false,
    complemento: '',
    bairro: '',
    uf: '',
    nomeUf: '',
    idMunicipio: 0,
    nomeMunicipio: '',
    pontoReferencia: '',
    telefoneUm: '',
    telefoneDois: '',
    email: '',
    site: '',
    telefones: [],
    emails: [],
    isentoInscricaoEstadual: false,
    isentoInscricaoMunicipal: false,
    obrigaValidacaoApp: false,
    numeroLocaisFixos: 0,
    numeroLocaisEventos: 0,
    numeroLocaisFixosPontos: 0,
    numeroLocaisEventosPontos: 0,
    numeroLocaisFixosUsuarios: 0,
    ocultaDocumento: false,
    ocultaTelefone: false,
    enviaPulsoAbertura: true,
    tipoControleVisibilidade: 0,
    
    // combos
    listaDeSexos: [],
    listaDeEstadosCivis: [],
    listaDeTiposDePessoa: [],
    listaTiposControleVisibilidade: [],

    // Filtros
    codigoClienteFiltro: '', 
    cpfCnpjFiltro: '', 
    nomeFiltro: '', 
    tipoFiltro: '',

    // Outros
    indiceAba: 0
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_CLIENTE: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          textoFiltro: '',
        }
      }   
      case CONSULTA_CEP: {
        return {
          ...state,
          loader: true,
        }
      }   
      case NOVO_CLIENTE: {

        return {
          ...state,
          edicao: true,
          id: 0,
          nomeRazaoSocial: '',
          nomeFanstasia: '',
          inscricaoEstadual: '',
          inscricaoMunicipal: '',
          nomeContato: '',      
          tipoPessoa: 'JURIDICA',
          numeroCpfCnpj: '', 
          dataNascimentoOuAbertura: '', 
          nomeMae: '',
          sexo: '',
          estadoCivil: '', 
          imagem: '',
          cep: '',
          logradouro: '',
          numero: '',
          complemento: '',
          bairro: '',
          uf: '',
          nomeUf: '',
          semNumero: false,
          idMunicipio: 0,
          nomeMunicipio: '',
          pontoReferencia: '',
          telefoneUm: '',
          telefoneDois: '',
          email: '',
          site: '',
          telefones: state.telefonesNovo,
          emails: state.emailsNovo,
          isentoInscricaoEstadual: true,
          isentoInscricaoMunicipal: true,
          listaDeSexos: preparaListaParaCombo(generos, "id", "title"),    
          listaDeEstadosCivis: preparaListaParaCombo(estadosCivis, "id", "title"),
          numeroLocaisFixos: 0,
          numeroLocaisEventos: 0,
          numeroLocaisFixosPontos: 0,
          numeroLocaisEventosPontos: 0,
          numeroLocaisFixosUsuarios: 0,  
          ocultaDocumento: false,
          enviaPulsoAbertura: true,
          ocultaTelefone: false,
          obrigaValidacaoApp: true,
          tipoControleVisibilidade: 0,
          indiceAba: 0,        
          listaTiposControleVisibilidade: preparaListaParaCombo(tiposControleVisibilidade, "id", "title"),    
        }
      }       
      case BUSCA_CLIENTE_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false,
          textoFiltro: '',
        }
      }
      case APLICA_FILTRO: {

/*         const filtroAplicar = action.payload;
        var listaFiltrada = filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.nomeRazaoSocial.toLowerCase().includes(filtroAplicar.toLowerCase())) : []; */

        const filtroAplicar = action.payload;
        var apenasNumeros = /^\d+$/.test(filtroAplicar.replace(".", "").replace("-", ""));
        var listaFiltrada = !apenasNumeros ? filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.nomeRazaoSocial.toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "").includes(filtroAplicar.normalize("NFD").toLowerCase().replace(/[^a-zA-Zs]/g, ""))) : [] : filtroAplicar.length >= 3 ? state.listGrid.filter(x => x.numeroCpfCnpj.replace(".", "").replace("-", "").replace("/", "").includes(filtroAplicar.replace(".", "").replace("-", "").replace("/", ""))) : [];
  
        return {
          ...state,
          textoFiltro: filtroAplicar,
          listGridComFiltro: filtroAplicar.length >= 3 ? listaFiltrada : state.listGridComFiltro
        }
      }      
      case INICIO_CLIENTE_SUCESSO: {
        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: action.payload.filter(x => x.id != 1), // Não mostrar a Uzer em uma grid
          listaDeSexos: preparaListaParaCombo(generos, "id", "title"),    
          listaDeEstadosCivis: preparaListaParaCombo(estadosCivis, "id", "title"),
          listaDeTiposDePessoa: preparaListaParaCombo(tiposPessoa, "id", "title"),
          listaTiposControleVisibilidade: preparaListaParaCombo(tiposControleVisibilidade, "id", "title"),              
        }
      }
      case SALVA_CLIENTE: {
        return {
          ...state,
          loader: true,
        }        
      }                           
      case SALVA_CLIENTE_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_CLIENTE_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      }
      case LISTA_CLIENTE: {
        const retorno = action.payload;

        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_CLIENTE_SUCESSO: {
        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          listGrid: retorno.clientes,
        }
      }
      case LIMPAR_CLIENTE: {

        var retorno = action.payload;

        return {
          ...state,
          codigoClienteFiltro: '', 
          cpfCnpjFiltro: '', 
          nomeFiltro: '', 
          tipoFiltro: '',
        }
      }
      case SETAR_IMAGEM_CLIENTE: {

        var retorno = action.payload;

        return {
          ...state,
          imagem: retorno.imagem,
        }
      }       
      case BUSCA_CLIENTE_EDICAO_SUCESSO: {
        var retorno = action.payload;

        var telefonesComMascara = retorno.telefones;

        for (var indice in telefonesComMascara) {
          telefonesComMascara[indice].numeroTelefone = telefoneMask(telefonesComMascara[indice].numeroTelefone);
        }

        return {
          ...state,
          id: retorno.id,
          nomeRazaoSocial: retorno.nomeRazaoSocial,
          nomeFanstasia: retorno.nomeFantasia,
          inscricaoEstadual: retorno.inscricaoEstadual,
          inscricaoMunicipal: retorno.inscricaoMunicipal,
          nomeContato: retorno.nomeContato,      
          tipoPessoa: retorno.tipoPessoa,
          numeroCpfCnpj: retorno.tipoPessoa === 'FISICA' ? cpfMask(retorno.numeroCpfCnpj) : cnpjMask(retorno.numeroCpfCnpj), 
          dataNascimentoOuAbertura: retorno.dataNascimentoOuAbertura !== null ? dateMask(retorno.dataNascimentoOuAbertura) : null, 
          nomeMae: retorno.nomeMae,
          sexo: retorno.sexo,
          estadoCivil: retorno.estadoCivil, 
          imagem: retorno.imagem,
          cep: retorno.cep !== null ? cepMask(retorno.cep) : null,
          logradouro: retorno.logradouro,
          numero: retorno.numero,
          complemento: retorno.complemento,
          bairro: retorno.bairro,
          uf: retorno.uf,
          nomeUf: retorno.nomeUf,
          idMunicipio: retorno.idMunicipio,
          nomeMunicipio: retorno.nomeMunicipio,
          pontoReferencia: retorno.pontoReferencia,  
          telefoneUm: retorno.telefoneUm,
          telefoneDois: retorno.telefoneDois,
          email: retorno.email,
          semNumero: retorno.semNumero,
          site: retorno.site,
          edicao: true,
          loader: false,
          telefones: telefonesComMascara,
          emails: retorno.emails,
          isentoInscricaoEstadual: retorno.isentoInscricaoEstadual,
          isentoInscricaoMunicipal: retorno.isentoInscricaoMunicipal,
          numeroLocaisFixos: retorno.numeroLocaisFixos,
          numeroLocaisEventos: retorno.numeroLocaisEventos,
          numeroLocaisFixosPontos: retorno.numeroLocaisFixosPontos,
          numeroLocaisEventosPontos: retorno.numeroLocaisEventosPontos,
          numeroLocaisFixosUsuarios: retorno.numeroLocaisFixosUsuarios,    
          indiceAba: 0,    
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          ocultaDocumento: retorno.ocultaDocumento,
          ocultaTelefone: retorno.ocultaTelefone,
          enviaPulsoAbertura: retorno.enviaPulsoAbertura,
          tipoControleVisibilidade: retorno.tipoControleVisibilidade,  
          obrigaValidacaoApp: retorno.obrigaValidacaoApp,   
          listaTiposControleVisibilidade: preparaListaParaCombo(tiposControleVisibilidade, "id", "title"),                         
        }
      }       
      case CONSULTA_CEP_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          loader: false,
          cepConsultado: retorno,
          logradouro: retorno.logradouro,
          bairro: retorno.bairro,
          nomeMunicipio: retorno.localidade,
          nomeUf: retorno.uf,
          codigoIbge: retorno.ibge
        }
      }
      case CONSULTA_CEP_ERRO: {
        var retorno = action.payload;

        return {
          ...state,
          loader: false,
          cepConsultado: '',
          logradouro: '',
          bairro: '',
          nomeMunicipio: '',
          nomeUf: '',
          codigoIbge: '',
          complemento: '',
          numero: '',
          cep: ''
        }        
      }
      case NOVO_TELEFONE: {
        const idTemporario = state.telefones.length + 1;

        const telefoneAdd = {
          "id": 0,
          "idTemporario": idTemporario,
          "numeroTelefone": '',
          "marcador": '',
        }        

        return {
          ...state,
          telefones: [...state.telefones, telefoneAdd],
        }
      }
      case ATUALIZA_TELEFONE: {

        var retorno = action.payload;

        var listaDetelefones = state.telefones.filter(x => x.idTemporario !== retorno.indice);
        var telefoneSelecionado = state.telefones.filter(x => x.idTemporario === retorno.indice);

        var telefoneAtualizar = {
          "id": telefoneSelecionado[0].id,
          "idTemporario": telefoneSelecionado[0].idTemporario,
          "numeroTelefone": retorno.campoAtualizar === 'numeroTelefone' ? telefoneMask(retorno.valor) : telefoneSelecionado[0].numeroTelefone,
          "marcador": retorno.campoAtualizar === 'marcador' ? retorno.valor : telefoneSelecionado[0].marcador,
        };           

        listaDetelefones = [...listaDetelefones, telefoneAtualizar];
        listaDetelefones = listaDetelefones.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

        return {
          ...state,
          telefones: listaDetelefones,
        }
      }              
      case REMOVE_TELEFONE: {

        return {
          ...state,
          telefones: state.telefones.filter((telefone) => telefone.idTemporario !== action.payload),
        }
      }            
      case NOVO_EMAIL: {
        const idTemporario = state.emails.length + 1;

        const emailAdd = {
          "id": 0,
          "idTemporario": idTemporario,
          "email": '',
          "marcador": '',
        }        

        return {
          ...state,
          emails: [...state.emails, emailAdd],
        }
      }
      case ATUALIZA_EMAIL: {
        var retorno = action.payload;

        var listaDeEmails = state.emails.filter(x => x.idTemporario !== retorno.indice);
        var emailSelecionado = state.emails.filter(x => x.idTemporario === retorno.indice);

        var emailAtualizar = {
          "id": emailSelecionado[0].id,
          "idTemporario": emailSelecionado[0].idTemporario,
          "email": retorno.campoAtualizar === 'email' ? retorno.valor : emailSelecionado[0].email,
          "marcador": retorno.campoAtualizar === 'marcador' ? retorno.valor : emailSelecionado[0].marcador,
        };           

        listaDeEmails = [...listaDeEmails, emailAtualizar];
        listaDeEmails = listaDeEmails.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

        return {
          ...state,
          emails: listaDeEmails,
        }
      }              
      case REMOVE_EMAIL: {

        return {
          ...state,
          emails: state.emails.filter((email) => email.idTemporario !== action.payload),
        }
      }
      case CLIQUE_CONTROLE_ABA_CLIENTE: {
        var retorno = action.payload;
  
        return {
          ...state,
          indiceAba: retorno.indice,
        }
      }                           
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {

        var campo = action.payload.target.name;
        var desmarcaIsencaoInscricaoEstadual = false;
        var desmarcaIsencaoInscricaoMunicipal = false;
        var desmarcaNumero = false;

        if (campo === "inscricaoEstadual")
        {
          if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
          {
            desmarcaIsencaoInscricaoEstadual = true;
          }
        }

        if (campo === "inscricaoMunicipal")
        {
          if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
          {
            desmarcaIsencaoInscricaoMunicipal = true;
          }
        }
        
        if (campo === "numero")
        {
          if(action.payload.target.value !== undefined && action.payload.target.value !== '' && action.payload.target.value !== null)
          {
            desmarcaNumero = true;
          }
        }          

        return {
          ...state,
          isentoInscricaoEstadual: desmarcaIsencaoInscricaoEstadual === true ? false : state.isentoInscricaoEstadual,
          isentoInscricaoMunicipal: desmarcaIsencaoInscricaoMunicipal === true ? false : state.isentoInscricaoMunicipal,
          semNumero: desmarcaNumero === true ? false : state.semNumero,          
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_COMBO: {

        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_CHECK: {

        var check = action.payload.nomeCheck[0];
        var limpaInscricaoEstadual = false;
        var limpaInscricaoMunicipal = false;
        var limpaNumero = false;
        var iniciaCadastro = false;

        if (check === "tipoPessoa")
        {
            iniciaCadastro = true;
        }        

        if (check === "isentoInscricaoEstadual")
        {
          if(action.payload.valor === true)
          {
            limpaInscricaoEstadual = true;
          }
        }

        if (check === "isentoInscricaoMunicipal")
        {
          if(action.payload.valor === true)
          {
            limpaInscricaoMunicipal = true;
          }
        }   
        
        if (check === "semNumero")
        {
          if(action.payload.valor === true)
          {
            limpaNumero = true;
          }
        }           

        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
          inscricaoEstadual: iniciaCadastro === true ? '' : limpaInscricaoEstadual === true ? '' : state.inscricaoEstadual,
          inscricaoMunicipal: iniciaCadastro === true ? '' : limpaInscricaoMunicipal === true ? '' : state.inscricaoMunicipal,
          numero: iniciaCadastro === true ? '' : limpaNumero === true ? '' : state.numero,

          nomeRazaoSocial: iniciaCadastro === true ? '' : state.nomeRazaoSocial,
          nomeFanstasia: iniciaCadastro === true ? '' : state.nomeFanstasia,
          nomeContato: iniciaCadastro === true ? '' : state.nomeContato,      
          numeroCpfCnpj: iniciaCadastro === true ? '' : state.numeroCpfCnpj, 
          dataNascimentoOuAbertura: iniciaCadastro === true ? '' : state.dataNascimentoOuAbertura, 
          nomeMae: iniciaCadastro === true ? '' : state.nomeMae,
          sexo: iniciaCadastro === true ? '' : state.sexo,
          estadoCivil: iniciaCadastro === true ? '' : state.estadoCivil, 
          imagem: iniciaCadastro === true ? '' : state.imagem,
          cep: iniciaCadastro === true ? '' : state.cep,
          logradouro: iniciaCadastro === true ? '' : state.logradouro,
          complemento: iniciaCadastro === true ? '' : state.complemento,
          bairro: iniciaCadastro === true ? '' : state.bairro,
          uf: iniciaCadastro === true ? '' : state.uf,
          nomeUf: iniciaCadastro === true ? '' : state.nomeUf,
          idMunicipio: iniciaCadastro === true ? 0 : state.idMunicipio,
          nomeMunicipio: iniciaCadastro === true ? '' : state.nomeMunicipio,
          pontoReferencia: iniciaCadastro === true ? '' : state.pontoReferencia,
          telefoneUm: iniciaCadastro === true ? '' : state.telefoneUm,
          telefoneDois: iniciaCadastro === true ? '' : state.telefoneDois,
          email: iniciaCadastro === true ? '' : state.email,
          site: iniciaCadastro === true ? '' : state.site,
          telefones: iniciaCadastro === true ? state.telefonesNovo : state.telefones,
          emails: iniciaCadastro === true ? state.emailsNovo : state.emails,
          listaDeSexos: iniciaCadastro === true ? preparaListaParaCombo(generos, "id", "title") : state.listaDeSexos,    
          listaDeEstadosCivis: iniciaCadastro === true ? preparaListaParaCombo(estadosCivis, "id", "title") : state.listaDeEstadosCivis, 
        }
      }       
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }         
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  