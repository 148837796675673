import {
  INICIA_EQUIPAMENTO,
  SALVA_EQUIPAMENTO,
  REMOVE_EQUIPAMENTO,
  NOVO_EQUIPAMENTO,
  BUSCA_EQUIPAMENTO_EDICAO,
  INICIO_EQUIPAMENTO_SUCESSO,
  SALVA_EQUIPAMENTO_SUCESSO,
  REMOVE_EQUIPAMENTO_SUCESSO,
  BUSCA_EQUIPAMENTO_EDICAO_SUCESSO,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LISTA_EQUIPAMENTO,
  LISTA_EQUIPAMENTO_SUCESSO,
  LIMPAR_EQUIPAMENTO
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaEquipamento = () => {
  return {
    type: INICIA_EQUIPAMENTO
  };
};

export const salvaEquipamento = (equipamento) => {
  return {
    type: SALVA_EQUIPAMENTO,
    payload: equipamento
  };
};

export const removeEquipamento = (id) => {
  return {
    type: REMOVE_EQUIPAMENTO,
    payload: id
  };
};

export const listaEquipamento = (modelo, numero, situacao) => {
  if(modelo == undefined){
    modelo = "";
  }

  if(numero == undefined){
    numero = "";
  }

  if(situacao == undefined){
    situacao = "";
  }

  return {
    type: LISTA_EQUIPAMENTO,
    payload: {modelo, numero, situacao}
  };
};


/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const novoEquipamento = () => {
  return {
    type: NOVO_EQUIPAMENTO
  };
};

export const buscaEquipamentoEdicao = (id) => {
  return {
    type: BUSCA_EQUIPAMENTO_EDICAO,
    payload: id
  };
};

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/  

export const inicioEquipamentoSucesso = (equipamentos, modelos, situacoes) => {
  return {
    type: INICIO_EQUIPAMENTO_SUCESSO,
    payload: { equipamentos, modelos, situacoes }
  }
};

export const salvaEquipamentoSucesso = (mensagem) => {
  return {
    type: SALVA_EQUIPAMENTO_SUCESSO,
    payload: mensagem
  }
};

export const removeEquipamentoSucesso = (mensagem) => {
  return {
    type: REMOVE_EQUIPAMENTO_SUCESSO,
    payload: mensagem
  }
};  

export const buscaEquipamentoEdicaoSucesso = (equipamento) => {
  return {
    type: BUSCA_EQUIPAMENTO_EDICAO_SUCESSO,
    payload: equipamento
  };
}; 

export const listaEquipamentoSucesso = (equipamento) => {
  return {
    type: LISTA_EQUIPAMENTO_SUCESSO,
    payload: { equipamento }
  };
};

export const limparFiltroEquipamento = () => {
  return {
    type: LIMPAR_EQUIPAMENTO,
  };
};

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/ 

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};