import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_LOCAL_CONTROLADO, INICIA_LOCAL_CONTROLADO, BUSCA_LOCAL_CONTROLADO_EDICAO, REMOVE_LOCAL_CONTROLADO, LISTA_LOCAL_CONTROLADO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaLocalControladoSucesso,
         removeLocalControladoSucesso, 
         inicioLocalControladoSucesso, 
         iniciaLocalControlado,
         buscaLocalControladoEdicaoSucesso,
         listaLocalControladoSucesso } from "actions/LocalControlado";
import { limpaValidacoes } from "actions/Global";         

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getLocaisControlados = async () =>
    await requisicaoApi.get('/locais')
    .then(resposta => resposta)
    .catch(error => error);

    const getPerfisPermanentes = async (cliente) =>
  await requisicaoApi.get('/perfis/permanente/cliente/' + cliente)
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);    

  const getLocalControladoEdicao = async (id) =>
    await requisicaoApi.get('/locais/' + id)
    .then(resposta => resposta)
    .catch(error => error);  
    
    const getListaLocalControlado = async (nomeClienteFiltro, inicioControleFiltro, fimControleFiltro, nomeLocalFiltro, tipoLocalFiltro) => 
    await requisicaoApi.get('/locais?Cliente=' +  nomeClienteFiltro + '&DataInicioControle=' + inicioControleFiltro + '&DataFimControle=' + fimControleFiltro + '&NomeLocalControlado=' + nomeLocalFiltro+ '&TipoLocal=' + tipoLocalFiltro)
    .then(resposta => resposta)
    .catch(error => error);    

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaLocalControladoRequest = async (localControlado) => await requisicaoApi.post('/locais', 
  {
    "id": localControlado.id,
    "idTipoEndereco": localControlado.idTipoEndereco,
    "idCliente": localControlado.idCliente,
    "descricaoLocalControlado": localControlado.descricaoLocalControlado,
    "tipoLocalControlado": localControlado.tipoLocalControlado,
    "tipoSincronizacaoBD": localControlado.tipoSincronizacaoBD,
    "dataEvento": localControlado.dataEvento,
    "dataInicioControle": localControlado.dataInicioControle,
    "dataFimControle": localControlado.dataFimControle,
    "caminhoImagemEvento": localControlado.caminhoImagemEvento,
    "observacoes": localControlado.observacoes,
    "possuiUnidade": localControlado.possuiUnidade,
    "descricaoRotuloGrupoUnidade": localControlado.descricaoRotuloGrupoUnidade,
    "descricaoRotuloUnidadeUnidade": localControlado.descricaoRotuloUnidadeUnidade,
    "possuiCategoria": localControlado.possuiCategoria,
    "descricaoRotuloGrupoCategoria": localControlado.descricaoRotuloGrupoCategoria,
    "descricaoRotuloUnidadeCategoria": localControlado.descricaoRotuloUnidadeCategoria,
    "possuiTitularidade": localControlado.possuiTitularidade,
    "descricaoRotuloTitularidadePrincipal": localControlado.descricaoRotuloTitularidadePrincipal,
    "descricaoRotuloTitularidadeSecundario": localControlado.descricaoRotuloTitularidadeSecundario,
    "possuiGaragem": localControlado.possuiGaragem,
    "agrupaPorUnidade": localControlado.agrupaPorUnidade,
    "agrupaPorCategoria": localControlado.agrupaPorCategoria,
    "agrupaPorTitulo": localControlado.agrupaPorTitulo,
    "habilitaLiberacaoTemporaria": localControlado.habilitaLiberacaoTemporaria,
    "habilitaLiberacaoExpress": localControlado.habilitaLiberacaoExpress,
    "habilitaGestaoConvite": localControlado.habilitaGestaoConvite, 
    "habilitaRespUnidade": localControlado.habilitaRespUnidade,
    "habilitaRespCategoria": localControlado.habilitaRespCategoria,
    "habilitaRespTitulo": localControlado.habilitaRespTitulo,       
    "tipoGaragem": localControlado.tipoGaragem,
    "responsabilidades": localControlado.responsabilidades.filter(x => x.descricaoResponsabilidade !== ''),
    "perfisAutorizados": localControlado.listaDePerfisAutorizados.length === 0 ? [] : localControlado.listaDePerfisAutorizados
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaLocalControladoRequest = async (localControlado) => await requisicaoApi.put('/locais', 
  {
    "id": localControlado.id,
    "idTipoEndereco": localControlado.idTipoEndereco,
    "idCliente": localControlado.idCliente,
    "descricaoLocalControlado": localControlado.descricaoLocalControlado,
    "tipoLocalControlado": localControlado.tipoLocalControlado,
    "tipoSincronizacaoBD": localControlado.tipoSincronizacaoBD,
    "dataEvento": localControlado.dataEvento,
    "dataInicioControle": localControlado.dataInicioControle,
    "dataFimControle": localControlado.dataFimControle,
    "caminhoImagemEvento": localControlado.caminhoImagemEvento,
    "observacoes": localControlado.observacoes,
    "possuiUnidade": localControlado.possuiUnidade,
    "descricaoRotuloGrupoUnidade": localControlado.descricaoRotuloGrupoUnidade,
    "descricaoRotuloUnidadeUnidade": localControlado.descricaoRotuloUnidadeUnidade,
    "possuiCategoria": localControlado.possuiCategoria,
    "descricaoRotuloGrupoCategoria": localControlado.descricaoRotuloGrupoCategoria,
    "descricaoRotuloUnidadeCategoria": localControlado.descricaoRotuloUnidadeCategoria,
    "possuiTitularidade": localControlado.possuiTitularidade,
    "descricaoRotuloTitularidadePrincipal": localControlado.descricaoRotuloTitularidadePrincipal,
    "descricaoRotuloTitularidadeSecundario": localControlado.descricaoRotuloTitularidadeSecundario,
    "possuiGaragem": localControlado.possuiGaragem,
    "agrupaPorUnidade": localControlado.agrupaPorUnidade,
    "agrupaPorCategoria": localControlado.agrupaPorCategoria,
    "agrupaPorTitulo": localControlado.agrupaPorTitulo,
    "habilitaLiberacaoTemporaria": localControlado.habilitaLiberacaoTemporaria,
    "habilitaLiberacaoExpress": localControlado.habilitaLiberacaoExpress,
    "habilitaGestaoConvite": localControlado.habilitaGestaoConvite, 
    "habilitaRespUnidade": localControlado.habilitaRespUnidade,
    "habilitaRespCategoria": localControlado.habilitaRespCategoria,
    "habilitaRespTitulo": localControlado.habilitaRespTitulo,        
    "tipoGaragem": localControlado.tipoGaragem,    
    "responsabilidades": localControlado.responsabilidades.filter(x => x.descricaoResponsabilidade !== ''),
    "perfisAutorizados": localControlado.listaDePerfisAutorizados.length === 0 ? [] : localControlado.listaDePerfisAutorizados
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deleteLocalControladoRequest = async (id) =>
  await requisicaoApi.delete('/locais?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaLocalControlado() {
    try {
      const listagemGrid = yield call(getLocaisControlados);
      const clienteFixo = localStorage.getItem('idClienteFixado');
        
      if(!listagemGrid.isAxiosError && listagemGrid.data) {

        const clientes = yield call(getClientes);

        if(clientes.data) {

          const perfis = yield call(getPerfisPermanentes, clienteFixo);
          yield put(inicioLocalControladoSucesso(listagemGrid.data, clientes.data, perfis.data));
          yield put(limpaValidacoes());
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar locais controlado'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaLocalControladoEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getLocalControladoEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaLocalControladoEdicaoSucesso(retorno.data));
        yield put(limpaValidacoes());
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do local: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaLocalControlado({ payload }) {

    try {
      const nomeClienteFiltro = payload.nomeCliente;
      const inicioControleFiltro = payload.inicioControle;
      const fimControleFiltro = payload.fimControle;
      const nomeLocalFiltro = payload.nomeLocal;
      const tipoLocalFiltro = payload.tipoLocal;
      const retorno = yield call(getListaLocalControlado, nomeClienteFiltro, inicioControleFiltro, fimControleFiltro, nomeLocalFiltro, tipoLocalFiltro );
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(listaLocalControladoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de local controlado'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  }  
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaLocalControlado({ payload }) {
  try {

    const localControlado = payload;
    var resultado = "";

      if(localControlado.id === 0) {
        const retorno = yield call(adicionaLocalControladoRequest, localControlado);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaLocalControladoRequest, localControlado);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaLocalControlado());
        yield put(salvaLocalControladoSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }          

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnRemoveLocalControlado({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deleteLocalControladoRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaLocalControlado());
        yield put(removeLocalControladoSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesLocalControlado() {
  yield takeEvery(INICIA_LOCAL_CONTROLADO, fnIniciaLocalControlado);
}

export function* salvaLocalControlado() {
  yield takeEvery(SALVA_LOCAL_CONTROLADO, fnSalvaLocalControlado);
}

export function* buscaLocalControladoEdicao() {
  yield takeEvery(BUSCA_LOCAL_CONTROLADO_EDICAO, fnBuscaLocalControladoEdicao);
}

export function* removeLocalControlado() {
  yield takeEvery(REMOVE_LOCAL_CONTROLADO, fnRemoveLocalControlado);
}

export function* listaLocalControlado() {
  yield takeEvery(LISTA_LOCAL_CONTROLADO, fnListaLocalControlado)
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesLocalControlado), 
             fork(salvaLocalControlado), 
             fork(buscaLocalControladoEdicao),
             fork(removeLocalControlado),
             fork(listaLocalControlado)]);
}