import React, { Component, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { LuxFwTabContainer } from 'luxFw/palettes/Structure';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Scrollbars } from 'react-custom-scrollbars';
import Skeleton from '@material-ui/lab/Skeleton';

const countries = [
    {
        title: "Brasil (+55)",
        id: "BR"
    },
];

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
        height: 5, // a number of your choice
    },
    firstIcon: {
        paddingLeft: 70
    },
    labelContainer: {
        width: "auto",
        textTransform: 'none'
    },
    labelIcon: {
        minHeight: 0,
        textTransform: 'none'
    },
    iconLabelWrapper: {
        flexDirection: "row",
        textTransform: 'none'
    },
    iconLabelWrapper2: {
        flexDirection: "row-reverse",
        textTransform: 'none'
    },
    padding: {
        paddingTop: 0,
    }
})

class Vinculos extends Component {
    state = {
        open: false,
        setOpen: false,
        selectedIndex: 0,
        setSelectedIndex: 0,
        anchorRef: null,
        value: 0,
        indexTab: 0,
    }

    handleChangeTab = (indexTab) => {
        this.setState({ indexTab });
    };

    render() {

        const carregandoPaginaPerfil = this.props.portariaInicio.carregandoPaginaPerfil;
        const portariaInicio = this.props.portariaInicio;
        const { classes } = this.props;
        const listaGrupos = portariaInicio.complementosPessoaSelecionada;
        const listaResponsaveis = portariaInicio.vinculosPessoaSelecionada.filter(x => x.tipoVinculo === 'Responsável');
        const listaDependentes = portariaInicio.vinculosPessoaSelecionada.filter(x => x.tipoVinculo === 'Dependente');
        var gruposProcessados = [];

        return carregandoPaginaPerfil ? (
            <Fragment>

                <Paper style={{ padding: 15, borderRadius: 15 }} >
                    <AppBar elevation={0} style={{ backgroundColor: 'white' }} position="static" color='#05B59D'>
                        <Tabs value={this.state.indexTab} /* onChange={this.handleChangeTab} */ style={{ backgroundColor: '#ffffff', WebkitBoxShadow: 'none', boxShadow: 'none !important', borderBottom: '1px solid #e8e8e8' }} indicatorColor="#ffffff" textColor="primary" variant="standart" scrollButtons="auto">
                            <Tab label={<Skeleton variant="text" width={'90%'} height={35} />} />
                            <Tab label={<Skeleton variant="text" width={'90%'} height={35} />} />
                        </Tabs>
                    </AppBar>

                    <Fragment>
                        <Scrollbars
                            style={{ height: 240 }}>

                            <LuxFwTabContainer>
                                <Fragment>

                                    <div style={{ fontSize: 12, width: '100%', fontWeight: 400, color: '#00000099' }}>
                                        <Skeleton variant="text" width={'30%'} height={35} />
                                    </div>

                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                                        <Skeleton variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                    </div>

                                    <div style={{ fontSize: 12, width: '100%', fontWeight: 400, color: '#00000099' }}>
                                        <Skeleton variant="text" width={'30%'} height={35} />
                                    </div>

                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                                        <Skeleton variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                        <Skeleton style={{ marginLeft: 10 }} variant="circle" width={56} height={56} />
                                    </div>

                                </Fragment>
                            </LuxFwTabContainer>

                        </Scrollbars>
                    </Fragment>
                </Paper>



            </Fragment>
        ) : (
            <Fragment>

                <Paper style={{ padding: 15, borderRadius: 15 }} >
                    <AppBar elevation={0} style={{ backgroundColor: 'white' }} position="static" color='#05B59D'>
                        <Tabs value={this.state.indexTab} /* onChange={this.handleChangeTab} */ style={{ backgroundColor: '#ffffff', WebkitBoxShadow: 'none', boxShadow: 'none !important', borderBottom: '1px solid #e8e8e8' }} indicatorColor="primary" textColor="primary" variant="standart" scrollButtons="auto">
                            <Tab fullWidth onClick={() => this.handleChangeTab(0)} style={{ fontFamily: 'MontSerrat, sans-serif', fontWeight: 600, fontSize: 16, borderBottom: '2px solid #e8e8e8' }} classes={{
                                wrapper: classes.iconLabelWrapper,
                                labelContainer: classes.labelContainer,
                                labelIcon: classes.labelIcon,
                            }} label="Grupos" />
                            <Tab fullWidth onClick={() => this.handleChangeTab(1)} style={{ fontFamily: 'MontSerrat, sans-serif', fontWeight: 600, fontSize: 16, borderBottom: '2px solid #e8e8e8' }} classes={{
                                wrapper: classes.iconLabelWrapper,
                                labelContainer: classes.labelContainer,
                                labelIcon: classes.labelIcon,
                            }} label="Vínculos" />
                        </Tabs>
                    </AppBar>

                    <Fragment>
                        <Scrollbars
                            style={{ height: 240 }}>

                            {this.state.indexTab === 0 &&
                                /*#############################################################################################*/
                                /* ----------------------------------------- Grupos --------------------------------------*/

                                <LuxFwTabContainer>

                                    {
                                        listaGrupos === null || listaGrupos === undefined || listaGrupos.length === 0 ?
                                            (
                                                <div style={{ width: '100%', textAlign: 'center', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div>
                                                        <img src={require("assets/images/semAgrupamentos.PNG")} alt="Sem agrupamentos" title="Sem agrupamentos" />

                                                    </div>

                                                    <div style={{ textAlign: 'center', width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                                                        <span style={{ fontWeight: 400, fontSize: 14, color: 'rgba(72, 81, 84, 0,6)' }}>Ninguém no(s) mesmo(s) agrupamento(s) desta pessoa</span>
                                                    </div>
                                                </div>
                                            ) :
                                            (
                                                <Fragment>

                                                    <div style={{ padding: 15 }}>

                                                        {
                                                            listaGrupos.map(grupo => {

                                                                if (gruposProcessados.filter(x => x.idTipoVinculo === grupo.idTipoVinculo && x.tipoVinculo === grupo.tipoVinculo).length === 0) {

                                                                    gruposProcessados.push(grupo);

                                                                    return (<Fragment>
                                                                        <div style={{ fontSize: 12, width: '100%', fontWeight: 400, color: '#00000099' }}>
                                                                            {grupo.nomeTipoVinculo}
                                                                        </div>

                                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                                                                            {
                                                                                listaGrupos.filter(x => x.idTipoVinculo === grupo.idTipoVinculo && x.tipoVinculo === grupo.tipoVinculo).map(grupoDetalhe => {

                                                                                    var imagemTratada = grupoDetalhe.imagemPessoa;
                                                                                    imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;

                                                                                    return (<div onClick={() => { this.props.selecionaPessoa(grupoDetalhe.idPessoa, portariaInicio.pontosSelecionadosPortaria); }} style={{ cursor: 'pointer' }}>
                                                                                        <NavLink style={{ textDecoration: 'none' }} className="prepend-icon" to={{ pathname: '/app/perfil' }}>
                                                                                            <Avatar style={{ height: 56, width: 56, margin: 5 }} alt={grupoDetalhe.nomePessoa} src={`data:image/jpeg;base64,${imagemTratada}`} />
                                                                                        </NavLink>
                                                                                    </div>
                                                                                    )

                                                                                })
                                                                            }

                                                                        </div>

                                                                    </Fragment>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </div>

                                                </Fragment>
                                            )
                                    }


                                </LuxFwTabContainer>}

                            {
                                this.state.indexTab === 1 &&
                                /*#############################################################################################*/
                                /* ----------------------------------------- Vinculos -------------------------------------*/
                                <LuxFwTabContainer>

                                    {
                                        listaResponsaveis.length === 0 && listaDependentes.length === 0 ?
                                            (
                                                <div style={{ width: '100%', textAlign: 'center', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div>
                                                        <img src={require("assets/images/semVinculos.PNG")} alt="Sem vínculos" title="Sem vínculos" />

                                                    </div>

                                                    <div style={{ textAlign: 'center', width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                                                        <span style={{ fontWeight: 400, fontSize: 14, color: 'rgba(72, 81, 84, 0,6)' }}>Ninguém está vinculado com esta pessoa</span>
                                                    </div>
                                                </div>
                                            ) :
                                            (
                                                <Fragment>
                                                    <Fragment>

                                                        <div style={{ padding: 15 }}>

                                                            {
                                                                listaResponsaveis.length > 0 &&
                                                                (<Fragment>
                                                                    <div style={{ fontSize: 12, width: '100%', fontWeight: 400, color: '#00000099' }}>
                                                                        Responsáveis
                                                                    </div>

                                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                                                                        {
                                                                            listaResponsaveis.map(responsavel => {

                                                                                var imagemTratadaResponsavel = responsavel.imagemPessoa;
                                                                                imagemTratadaResponsavel = imagemTratadaResponsavel !== null && imagemTratadaResponsavel !== "" ? imagemTratadaResponsavel.substr(imagemTratadaResponsavel.indexOf(",") + 1) : null;

                                                                                return (
                                                                                    <div onClick={() => { this.props.selecionaPessoa(responsavel.idPessoa, portariaInicio.pontosSelecionadosPortaria); }} style={{ cursor: 'pointer' }}>
                                                                                        <NavLink style={{ textDecoration: 'none' }} className="prepend-icon" to={{ pathname: '/app/perfil' }}>
                                                                                            <Avatar style={{ height: 56, width: 56, margin: 5 }} alt={responsavel.nomePessoa} src={`data:image/jpeg;base64,${imagemTratadaResponsavel}`} />
                                                                                        </NavLink>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                </Fragment>)

                                                            }

                                                            {
                                                                listaDependentes.length > 0 &&
                                                                (<Fragment>
                                                                    <div style={{ fontSize: 12, width: '100%', fontWeight: 400, color: '#00000099' }}>
                                                                        Dependentes
                                                                    </div>

                                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                                                                        {
                                                                            listaDependentes.map(dependente => {

                                                                                var imagemTratadaDependente = dependente.imagemPessoa;
                                                                                imagemTratadaDependente = imagemTratadaDependente !== null && imagemTratadaDependente !== "" ? imagemTratadaDependente.substr(imagemTratadaDependente.indexOf(",") + 1) : null;

                                                                                return (
                                                                                    <div onClick={() => { this.props.selecionaPessoa(dependente.idPessoa, portariaInicio.pontosSelecionadosPortaria); }} style={{ cursor: 'pointer' }}>
                                                                                        <NavLink style={{ textDecoration: 'none' }} className="prepend-icon" to={{ pathname: '/app/perfil' }}>
                                                                                            <Avatar style={{ height: 56, width: 56, margin: 5 }} alt={dependente.nomePessoa} src={`data:image/jpeg;base64,${imagemTratadaDependente}`} />
                                                                                        </NavLink>
                                                                                    </div>

                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                </Fragment>)

                                                            }



                                                            {/* 
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <Avatar style={{ height: 56, width: 56, margin: 5 }} alt="Travis Howard" src="https://i.pravatar.cc/400?img=36" />
                                                <Avatar style={{ height: 56, width: 56, margin: 5 }} alt="Travis Howard" src="https://i.pravatar.cc/400?img=52" />
                                            </div> */}

                                                            {/*                                         <div style={{ fontSize: 12, width: '100%', fontWeight: 400, color: '#00000099' }}>
                                                Dependentes
                                            </div>
    
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <Avatar style={{ height: 56, width: 56, margin: 5 }} alt="Travis Howard" src="https://i.pravatar.cc/400?img=36" />
                                                <Avatar style={{ height: 56, width: 56, margin: 5 }} alt="Travis Howard" src="https://i.pravatar.cc/400?img=52" />
                                                <Avatar style={{ height: 56, width: 56, margin: 5 }} alt="Travis Howard" src="https://i.pravatar.cc/400?img=49" />
                                                <Avatar style={{ height: 56, width: 56, margin: 5 }} alt="Travis Howard" src="https://i.pravatar.cc/400?img=44" />
                                            </div> */}
                                                        </div>

                                                    </Fragment>
                                                </Fragment>
                                            )
                                    }


                                </LuxFwTabContainer>}

                        </Scrollbars>
                    </Fragment>
                </Paper>



            </Fragment>
        );
    }
}

Vinculos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vinculos);

/* import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import * as Icons from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { LuxFwTabContainer } from 'luxFw/palettes/Structure';
import Avatar from '@material-ui/core/Avatar';
import { RiKey2Fill } from "@react-icons/all-files/ri/RiKey2Fill";
import { FiUnlock } from "@react-icons/all-files/fi/FiUnlock";
import { BiHistory } from "@react-icons/all-files/bi/BiHistory";
import Badge from '@material-ui/core/Badge';
import { Card, CardBody, CardText, CardHeader, CardFooter } from 'reactstrap';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Anderson da Silva Mor...', 'Esmeralda / 3004', 'Benedito Jose...'),
    createData('Joaquim Ferreira da Silv...', 'Esmeralda / 3004', 'Benedito Jose...'),
    createData('Anderson da Silva Mor...', 'Esmeralda / 3004', 'Benedito Jose...'),
    createData('Anderson da Silva Mor...', 'Esmeralda / 3004', 'Benedito Jose...'),
];

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    firstIcon: {
        paddingLeft: 70
    },
    labelContainer: {
        width: "auto",
    },
    labelIcon: {
        minHeight: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Montserrat, sans-serif'
    },
    iconLabelWrapper2: {
        flexDirection: "row-reverse"
    },
    padding: {
        paddingTop: 0,
    }
});

class Vinculos extends Component {

    render() {

        const pessoaSelecionada = this.props.portariaInicio.pessoaSelecionada;
        var imagemTratada = pessoaSelecionada.imagem;
        imagemTratada = imagemTratada !== null && imagemTratada !== "" ? imagemTratada.substr(imagemTratada.indexOf(",") + 1) : null;
        const { classes } = this.props;

        return (
            <Fragment>
                <Card style={{ borderRadius: 15, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }} >
                    <CardHeader style={{ padding: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, fontWeight: 600, fontSize: 18, fontFamily: 'Montserrat, sans-serif', backgroundColor: '#fff' }}>
                        Pessoas
                    </CardHeader>
                    <CardBody style={{minHeight: 125}}>

                        <p style={{ paddingLeft: 5, paddingRight: 5 }} className="mb-0">Esmeralda / 3004</p>

                        <div style={{ width: '100%', height: 20, marginTop: 15 }}>



                            <Badge style={{ float: 'left', paddingLeft: 5, backgroundColor: 'white' }}
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                badgeContent={<i style={{ backgroundColor: 'white', color: 'orange' }} className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-lg" />}
                            >
                                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/400?img=36" />
                            </Badge>

                            <Badge style={{ float: 'left', paddingLeft: 5, backgroundColor: 'white' }}
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                badgeContent={<i style={{ backgroundColor: 'white', color: 'orange' }} className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-lg" />}
                            >
                                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/400?img=52" />
                            </Badge>

                            <Badge style={{ float: 'left', paddingLeft: 5, backgroundColor: 'white' }}
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                badgeContent={<i style={{ backgroundColor: 'white', color: 'orange' }} className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-lg" />}
                            >
                                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/400?img=49" />
                            </Badge>

                            <Badge style={{ float: 'left', paddingLeft: 5, backgroundColor: 'white' }}
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                badgeContent={<i style={{ backgroundColor: 'white', color: 'orange' }} className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-lg" />}
                            >
                                <Avatar alt="Travis Howard" src="https://i.pravatar.cc/400?img=44" />
                            </Badge>

                        </div>
                    </CardBody>
                </Card>
            </Fragment>
        )
    }

}

Vinculos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vinculos); */