import { cpfMask, cnpjMask, telefoneMask, cepMask, dateMask } from 'luxFw/transformations/Mascaras';
import { preparaListaParaCombo } from 'luxFw/transformations/Common';
import {
    INICIA_EQUIPAMENTO,
    NOVO_EQUIPAMENTO,
    BUSCA_EQUIPAMENTO_EDICAO,
    CANCELA_EDICAO,
    INICIO_EQUIPAMENTO_SUCESSO,
    SALVA_EQUIPAMENTO_SUCESSO,
    REMOVE_EQUIPAMENTO_SUCESSO,
    BUSCA_EQUIPAMENTO_EDICAO_SUCESSO,
    INIT_URL,
    HIDE_MESSAGE,    
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    ON_SHOW_ERROR_MESSAGE,
    SHOW_MESSAGE,
    ALTERACAO_CAMPO,
    ALTERACAO_COMBO,
    ALTERACAO_DATEPICKER,
    ALTERACAO_CHECK, 
    ALTERACAO_CAMPO_MASCARA_CEP,
    ALTERACAO_CAMPO_MASCARA_CNPJ,
    ALTERACAO_CAMPO_MASCARA_CPF,
    ALTERACAO_CAMPO_MASCARA_DATE,
    ALTERACAO_CAMPO_MASCARA_TELEFONE,
    LIMPAR_EQUIPAMENTO,
    LISTA_EQUIPAMENTO_SUCESSO,
    LISTA_EQUIPAMENTO    
  } from "constants/ActionTypes";
    
  const INIT_STATE = {

    // Configuração da Grid
    colunasGrid: [
      { titulo: 'Codigo Equipamento', propriedadeApi: 'id', alinhamento: 'left', chave: true, numerico: true },  
      { titulo: 'Tipo', propriedadeApi: 'tipoEquipamento', alinhamento: 'left' },
      { titulo: 'Número de Série', propriedadeApi: 'numeroSerieEquipamento', alinhamento: 'left' },
      { titulo: 'Licenças', propriedadeApi: 'numeroLicencas', alinhamento: 'left' },
      { titulo: 'End. MAC', propriedadeApi: 'enderecoMAC', alinhamento: 'left' },
      { titulo: 'Porta Autenticação', propriedadeApi: 'numeroPortaAutenticacao', alinhamento: 'left'},
      { titulo: 'Situação', propriedadeApi: 'descricaoSituacao', alinhamento: 'left' },
    ],    

    // Propriedades Globais
    loader: false,
    alertMessage: '',
    showMessageSuccess: false,
    showMessageError: false,
    edicao: false,
    carregandoLista: true,
    listGrid: [],
    textoFiltro: '',

    // Campos do Formulario
    id: 0,
    descricaoEquipamento: '',
    tipoEquipamento: 'TERMINAL',
    numeroSerieEquipamento: '',
    enderecoMAC: '',
    idModeloEquipamento: 0,
    idSituacaoEquipamento: 0,
    numeroLicencas: '',
    numeroPortaAutenticacao: '',     
    
    // Combos
    listaDeModelos: [], 
    listaDeSituacoes: [], 
  
     // Filtros
     modeloFiltro: '', 
     numeroFiltro: '', 
     situacaoFiltro: '', 
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case INICIA_EQUIPAMENTO: {
        return {
          ...state,
          edicao: false,
          loader: true,
          carregandoLista: true,
          textoFiltro: '',
        }
      }   
      case NOVO_EQUIPAMENTO: {
        return {
          ...state,
          edicao: true,
          tipoEquipamento: 'TERMINAL',
          id: 0,
          descricaoEquipamento: '',
          numeroSerieEquipamento: '',
          enderecoMAC: '',
          idModeloEquipamento: 0,
          idSituacaoEquipamento: 0,
          numeroPortaAutenticacao: '',
          numeroLicencas: '',         
        }
      }       
      case BUSCA_EQUIPAMENTO_EDICAO: {
        return {
          ...state,
          loader: true,
        }
      }
      case CANCELA_EDICAO: {
        return {
          ...state,
          edicao: false,
          textoFiltro: '',
        }
      }
      case INICIO_EQUIPAMENTO_SUCESSO: {

        const retorno = action.payload;

        return {
          ...state,
          loader: false,
          carregandoLista: false,
          listGrid: retorno.equipamentos,
          listaDeModelos: preparaListaParaCombo(retorno.modelos, "id", "descricaoModelo"),
          listaDeSituacoes: preparaListaParaCombo(retorno.situacoes, "id", "descricaoSituacao")
        }
      }                     
      case SALVA_EQUIPAMENTO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false      
        }
      }
      case REMOVE_EQUIPAMENTO_SUCESSO: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageSuccess: true,    
          edicao: false    
        }
      } 
      case LISTA_EQUIPAMENTO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: true,
        }
      }
      case LISTA_EQUIPAMENTO_SUCESSO: {
        const retorno = action.payload;
  
        return {
          ...state,
          loader: false,
          listGrid: retorno.equipamento,
        }
      }
      case LIMPAR_EQUIPAMENTO: {
  
        var retorno = action.payload;
  
        return {
          ...state,
          modeloFiltro: '', 
          numeroFiltro: '', 
          situacaoFiltro: '', 
        }
      }       
      case BUSCA_EQUIPAMENTO_EDICAO_SUCESSO: {
        var retorno = action.payload;

        return {
          ...state,
          edicao: true,
          loader: false,

          id: retorno.id,
          descricaoEquipamento: retorno.descricaoEquipamento,
          numeroSerieEquipamento: retorno.numeroSerieEquipamento,
          enderecoMAC: retorno.enderecoMAC,
          idModeloEquipamento: retorno.idModeloEquipamento,
          idSituacaoEquipamento: retorno.idSituacaoEquipamento,
          numeroPortaAutenticacao: retorno.numeroPortaAutenticacao,
          tipoEquipamento: retorno.tipoEquipamento,
          numeroLicencas: retorno.numeroLicencas,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,                     
        }
      }       
      case INIT_URL: {
        return {
          ...state,
          initURL: action.payload
        }
      }      
      case HIDE_MESSAGE: {
        return {
          ...state,
          alertMessage: '',
          showMessageSuccess: false,
          showMessageError: false,
          loader: false
        }
      }
      case ON_HIDE_LOADER: {
        return {
          ...state,
          loader: false
        }
      }      
      case ON_SHOW_LOADER: {
        return {
          ...state,
          loader: true
        }
      }      
      case ON_SHOW_ERROR_MESSAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload,
          showMessageError: true,    
          edicao: false      
        }
      }
      case SHOW_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessageError: true,
          loader: false
        }
      }
      case ALTERACAO_CAMPO: {
        return {
          ...state,
          [action.payload.target.name]: action.payload.target.value,
        }
      }
      case ALTERACAO_COMBO: {
        return {
          ...state,
          [action.payload.nomeCombo]: action.payload.valor,
        }        
      }
      case ALTERACAO_DATEPICKER: {
        return {
          ...state,
          [action.payload.name]: action.payload.date,
        }
      }
      case ALTERACAO_CHECK: {
        return {
          ...state,
          [action.payload.nomeCheck]: action.payload.valor,
        }
      }                
      case ALTERACAO_CAMPO_MASCARA_CPF: {
        return {
          ...state,
          [action.payload.target.name]: cpfMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_CNPJ: {
        return {
          ...state,
          [action.payload.target.name]: cnpjMask(action.payload.target.value)
        }        
      }
      case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
        return {
          ...state,
          [action.payload.target.name]: telefoneMask(action.payload.target.value)
        }        
      }          
      case ALTERACAO_CAMPO_MASCARA_CEP: {
        return {
          ...state,
          [action.payload.target.name]: cepMask(action.payload.target.value)
        }        
      }               
      case ALTERACAO_CAMPO_MASCARA_DATE: {
        return {
          ...state,
          [action.payload.target.name]: dateMask(action.payload.target.value),

        }        
      }       
      default:
        return state;
    }
  }
  